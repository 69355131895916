import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class MonitoraggioProgettoPartnerService {

  constructor(private http: HttpClient) {
  }

  public getProgettiFromRendicontaDB(organizzazioneId: number): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetti/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
  }

  public getProgettiFromRendicontaDBByPartener(organizzazioneId: number): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetti/get-by-partner');
  }

  public getCurrentStackHolder(): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetti/currentstackHolder');
  }

}
