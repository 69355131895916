import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GestioneBandiService {

  constructor(private http: HttpClient) {}

  public saveBando(bando): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'bandi/save-bando', bando);
  }

  public resetBando(nomeBando, idOrganizzazione): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'bandi/reset-bando?nomeBando='+encodeURIComponent(nomeBando)+'&idOrganizzazione='+idOrganizzazione, null);
  }

  public deleteBando(nomeBando, idOrganizzazione): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'bandi/delete-bando?nomeBando='+encodeURIComponent(nomeBando)+'&idOrganizzazione='+idOrganizzazione, null);
  }

  public deletePercentualiTipoFinanziamento(idBando): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'percentuali-tipi-finanziamento/delete-all-percentuali?idBando=' + idBando, null);
  }

  public getAllByOrganizzazione(organizzazioneId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'bandi/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
  }

  public getBandoByBandoName(nomeBando,organizzazioneId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'bandi/get-by-bando-name?nomeBando='+nomeBando+'&idOrganizzazione='+organizzazioneId);
  }

  public getVociDiCostoBando(idBando,idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'voci-di-costo/bando-voce-di-costo/get-all-objects-by-bando-id-and-organizzazione-id?bandoId='+idBando+'&organizzazioneId='+idOrganizzazione);
  }

  public getTipiAttivitaCofinanziabiliBando(idBando,idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'bandi-tipi-di-attivita-cofinanziabile/get-all-by-bando-id-and-organizzazione-id?bandoId='+idBando+'&organizzazioneId='+idOrganizzazione);
  }

  public getTipiFinanziamentoBando(idBando,idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'bandi-tipi-di-finanziamento/get-all-by-bando-id-and-organizzazione-id?bandoId='+idBando+'&organizzazioneId='+idOrganizzazione);
  }

  public getPercentualiTipiFinanziamentoBando(idBando,idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'bandi-tipi-di-finanziamento/get-percentuale-by-bando-id-and-organizzazione-id?bandoId='+idBando+'&organizzazioneId='+idOrganizzazione);
  }
  public getVociDiCosto(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'voci-di-costo/get-by-organizzazione-id?organizzazioneId='+idOrganizzazione);
  }

  public getTipiAttivitaCofinanziabili(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'tipi-di-attivita-cofinanziabile/get-by-organizzazione-id?organizzazioneId='+idOrganizzazione);
  }

  public getTipiFinanziamento(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'tipidifinanziamento/get-all-by-organizzazione?organizzazioneId='+idOrganizzazione);
  }

  public getFasceStandard(idBando): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'fasce-costo-personale-standard/get-all-by-bando-id?bandoId='+idBando);
  }

  public getFasciaBando(idFasciaCostoPersonaleStandard, idTipologiaStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'fasce-costo-personale-standard-tipologia-stakeholder/get-by-fascia-costo-personale-id-and-tipologia-stakeholder-id?idFasciaCostoPersonaleStandard='+idFasciaCostoPersonaleStandard+'&idTipologiaStakeholder='+idTipologiaStakeholder);
  }

  public getPercentualiTipiFinanziamento(idBando): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'percentuali-tipi-finanziamento/get-all-by-bando?idBando='+idBando);
  }

  public saveBandoVoceDiCosto(bandoVoceDiCosto): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'voci-di-costo/bando-voce-di-costo/save-bando-voce-di-costo', bandoVoceDiCosto);
  }

  public saveBandoTipoDiFinanziamento(bandoTipoDiFinanziamento): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'bandi-tipi-di-finanziamento/save-bando-tipo-finanziamento', bandoTipoDiFinanziamento);
  }

  public saveBandoTipoDiAttivitaCofinanziabile(bandoTipoDiAttivitaCofinanziabile): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'bandi-tipi-di-attivita-cofinanziabile/save-bando-tipo-attivita-cofinanziabile', bandoTipoDiAttivitaCofinanziabile);
  }

  public saveFasciaStandard(fasciaStandard): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'fasce-costo-personale-standard/save-fascia-costo-personale-standard', fasciaStandard);
  }

  public saveFasciaStandardBando(fasciaStandardBando): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'fasce-costo-personale-standard-tipologia-stakeholder/save-fascia-costo-personale-standard-tipologia-stakeholder', fasciaStandardBando);
  }

  public savePercentualeTipoFinanziamento(percentualeTipoFinanziamento): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'percentuali-tipi-finanziamento/save-percentuale-tipo-finanziamento', percentualeTipoFinanziamento);
  }

  public updatePercentualeTipoFinanziamento(percentualeTipoFinanziamento): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'percentuali-tipi-finanziamento/update-percentuale-tipo-finanziamento', percentualeTipoFinanziamento);
  }

}
