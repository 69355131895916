import { Injectable } from '@angular/core';

const EMAIL = 'Email';
const ROLE = 'Role';
const ORGANIZATION = 'Organization';
const CLIENTI_ATTIVI_ORGANIZZAZIONE = 'Active clients of Organization';

@Injectable()
export class UserStorage {

  constructor() { }

  signOut() {
    window.sessionStorage.removeItem(EMAIL);
    window.sessionStorage.removeItem(ROLE);
    window.sessionStorage.removeItem(ORGANIZATION);
    window.sessionStorage.clear();
  }

  public saveUserInSession(email,role) {
    window.sessionStorage.removeItem(EMAIL);
    window.sessionStorage.removeItem(ROLE);
    window.sessionStorage.setItem(EMAIL, email);
    window.sessionStorage.setItem(ROLE, role);
  }

  public saveOrganizzazione(organizzazione) {
    window.sessionStorage.removeItem(ORGANIZATION);
    window.sessionStorage.setItem(ORGANIZATION, organizzazione);
  }

  public saveClientiAttiviOrganizzazione(clientiAttiviList) {
    window.sessionStorage.removeItem(CLIENTI_ATTIVI_ORGANIZZAZIONE);
    window.sessionStorage.setItem(CLIENTI_ATTIVI_ORGANIZZAZIONE, JSON.stringify(clientiAttiviList));
  }

  public getEmail(): string {
    return window.sessionStorage.getItem(EMAIL);
  }

  public getRole(): string {
    return window.sessionStorage.getItem(ROLE);
  }

  public getOrganizzazione(): string {
    return window.sessionStorage.getItem(ORGANIZATION);
  }

  public getClientiAttiviOrganizzazione(): any[] {
    return JSON.parse(window.sessionStorage.getItem(CLIENTI_ATTIVI_ORGANIZZAZIONE));
  }
}