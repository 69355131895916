import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from 'src/app/app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class CreazioneDatiProgettoService {

  public static subUrlImportaProgetti: string = "/anagrafica_progetti.php";

  constructor(private http: HttpClient) {}

  public getProgettiFromGestori(bodyMessaggio : any): Observable<any> {
    //Chiamata gestita tramite proxy configurato lato server su proxy.config.json
    return this.http.post<any>(AppSettings.GESTORI_URL + CreazioneDatiProgettoService.subUrlImportaProgetti , bodyMessaggio, {headers:
        {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' } });
  }

  public getIdBandoRendicontaFromNameBandoGestori(bodyMessaggio : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'bandi/get-by-bando-name',bodyMessaggio);
  }

  public getProgettoById(id) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'progetti/get-progetto-by-id?id='+id);
  }

  public updateProgetto(bodyMessaggio : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'progetti/update-progetto', bodyMessaggio);
  }

  public saveProgetto(bodyMessaggio : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'progetti/save-progetto', bodyMessaggio);
  }

}
