import {Component, OnInit} from '@angular/core';
import {GestioneStakeholdersService} from "../../../gestione-stakeholders/gestione-stakeholders.service";
import {ApiKeyElement} from "../../api-key.component";
import {ApiKeyService} from "../../api_key.service";
import {MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-add-api',
  templateUrl: './add-api.component.html',
  styleUrls: ['./add-api.component.css']
})
export class AddApiComponent implements OnInit {
  loading = false;
  uuid;
  api_key;
  selectedStakeholder = undefined;
  stakeholders;

  constructor(private gestioneStakeholdersService: GestioneStakeholdersService,
              private apiKeyService: ApiKeyService,
              public dialogRef: MatDialogRef<AddApiComponent>) {
  }

  ngOnInit() {
    this.loadStakeholders();
  }

  onSave() {
    console.log("salvo " + this.selectedStakeholder.id + "," + this.api_key + "," + this.uuid);
    let apikey = new ApiKeyElement();
    apikey.stakeholder = this.selectedStakeholder.id;
    apikey.uid = this.uuid;
    apikey.api_key = this.api_key;
    this.apiKeyService.add(apikey).subscribe(
      data => {
        this.dialogRef.close();
      },
      err => {
      }
    );
  }

  generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  creaChiavi() {
    this.uuid = this.generateGuid();
    this.api_key = this.generateGuid();
  }

  loadStakeholders(): void {
    this.gestioneStakeholdersService.getStakeholders(1).subscribe(
      data => {
        console.log("loadStakeholders " + data);
        this.stakeholders = data;
      },
      err => {
      }
    );
  }
}
