import { Component, OnInit } from '@angular/core';
import { CreaProgettoService } from '../../../crea-progetto/crea-progetto.service';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { OrganizzazioniService } from '../../../organizzazioni/organizzazioni.service';
import { ConfirmMessageComponent } from '../../../modals/confirm-message/confirm-message.component';
import { WarningMessageComponent } from '../../../modals/warning-message/warning-message.component';
import { ConfiguraCostiPersonaleModalService } from './configura-costi-personale-modal.service';
import { OuterNode } from '../../gestione-piani-dei-costi.component';

@Component({
  selector: 'app-configura-costi-personale-modal',
  templateUrl: './configura-costi-personale-modal.component.html',
  styleUrls: ['./configura-costi-personale-modal.component.css']
})
export class ConfiguraCostiPersonaleModalComponent implements OnInit {

  loadingComponent: boolean = true;
  displayedColumns = ['nome', 'costo', 'remove'];

  progetto = undefined;
  outerNode: OuterNode = undefined;
  fasce = [];

  dataSource = [];

  constructor(
    private organizzazioniService: OrganizzazioniService,
    private creaProgettoService: CreaProgettoService,
    public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private confirmDialogRef : MatDialogRef<ConfirmMessageComponent>,
    private configuraCostiPersonaleModalService : ConfiguraCostiPersonaleModalService,
    public thisDialogRef: MatDialogRef<ConfiguraCostiPersonaleModalComponent>,
  ) {}

  loadFasce(idOrganizzazione, _callback) : void {
    this.configuraCostiPersonaleModalService.getFascePersonaleNonStandard(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  saveFascia(fascia, index, _callback) : void {
    this.configuraCostiPersonaleModalService.saveFasciaPersonaleNonStandardProgettoStakeholderRelation(fascia).subscribe(
      data => { _callback(data, index); },
      err => { this.showError(err.error.message); }
    );
  }

  onRemove(element) : void {
    const index = this.dataSource.indexOf(this.dataSource.find(found => found.fasciaCostoPersonaleNonStandard.id === element.fasciaCostoPersonaleNonStandard.id));
    this.dataSource.splice(index, 1);
    this.dataSource = this.dataSource.concat([]);
  }

  onSaveAll() : void {
    const msg = this.checkForErrors();
    if(msg != undefined) this.showError(msg);
    else {
      for(var i=0; i<this.dataSource.length; i++) {
        this.saveFascia(this.dataSource[i], i, (data, index) => {
          if(index === this.dataSource.length - 1) this.thisDialogRef.close(true);
        });
      }
    }
  }

  checkForErrors() : string {
    if(this.dataSource == undefined || this.dataSource.length === 0) return "Impossibile memorizzare una configurazione vuota.";
    if(this.dataSource.find(function(found) { return found.costoOrario == undefined || +found.costoOrario < 0 }) != undefined) return "Impossibile memorizzare un costo orario assente o negativo.";
    return undefined;
  }

  initDataSource() : void {
    this.fasce.forEach(fascia => {
      this.dataSource.push({ id: undefined, fasciaCostoPersonaleNonStandard: fascia, sedeStakeholder: this.outerNode.sedeStakeholder,
        progetto: this.progetto, costoOrario: 0 });
    });
  }

  initDataFromDatabase(_callback) : void {
    this.loadFasce(this.progetto.organizzazione.id, (fasceFromDatabase) => {
      this.fasce = fasceFromDatabase;
      _callback();
    });
  }

  initDataFromParent() : void {
    const item = sessionStorage.getItem("configura_costi_progetto");
    if(item == undefined) {
      this.showError("Errore nell'inizializzazione del progetto di riferimento.");
      return;
    }
    this.progetto = JSON.parse(item);
    sessionStorage.removeItem("progetto");
    const element = sessionStorage.getItem("configura_costi_node");
    if(element == undefined) {
      this.showError("Errore nell'inizializzazione del progetto di riferimento.");
      return;
    }
    this.outerNode = JSON.parse(element);
    sessionStorage.removeItem("configura_costi_node");
  }

  showError(err: string) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: err
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.initDataFromParent();
    this.initDataFromDatabase(() => {
      this.initDataSource();
      this.loadingComponent = false;
    });
  }

}
