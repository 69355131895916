import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class ReportisticaProgettoService {

  constructor(private http: HttpClient) {
  }

  public generateProgettoReport(idProgetto): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'reportcosti/generate-report?idProgetto=' + idProgetto, null);
  }

  public getCostiProgettoReportsList(): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'reportcosti/get-reports-list');
  }

  public downloadProgettoReport(filename, _callback) {
    fetch(AppSettings.BASE_URL + 'reportcosti/download-report?filename=' + filename)
    .then(response => { return response.blob() })
    .then((responseBlob) => {
        _callback(responseBlob);
    })
  }

  public generateCostiAmmessiReport(idProgetto): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'reportcostiammessi/generate-report?idProgetto=' + idProgetto, null);
  }

  public getCostiAmmessiReportsList(): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'reportcostiammessi/get-reports-list');
  }

  public downloadCostiAmmessiReport(filename, _callback) {
    fetch(AppSettings.BASE_URL + 'reportcostiammessi/download-report?filename=' + filename)
    .then(response => { return response.blob() })
    .then((responseBlob) => {
        _callback(responseBlob);
    })
  }

  public generateCostiSalReport(idProgetto): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'reportcostisal/generate-report?idProgetto=' + idProgetto, null);
  }

  public getCostiSalReportsList(): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'reportcostisal/get-reports-list');
  }

  public downloadCostiSalReport(filename, _callback) {
    fetch(AppSettings.BASE_URL + 'reportcostisal/download-report?filename=' + filename)
    .then(response => { return response.blob() })
    .then((responseBlob) => {
        _callback(responseBlob);
    })
  }

}