import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestione-valori-modal',
  templateUrl: './gestione-valori-modal.component.html',
  styleUrls: ['./gestione-valori-modal.component.css']
})
export class GestioneValoriModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
