import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChatService } from './prompt-generator-component.component.service';

@Component({
  selector: 'prompt-generator-component',
  templateUrl: './prompt-generator-component.component.html',
  styleUrls: ['./prompt-generator-component.component.css']
})
export class PromptGeneratorComponentComponent implements OnInit {

  context = undefined;
  query = undefined;
  prompt = undefined;

  constructor(
    private thisDialogRef: MatDialogRef<PromptGeneratorComponentComponent>,
    private chatService: ChatService,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  generatePromptRendicontazione(idSal, idSedeStakeholder, tipoVersione, _callback): void {
    this.chatService.generatePromptRendicontazione(idSal, idSedeStakeholder, tipoVersione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        console.log(err);
        _callback({prompt: ""});
      }
    );
  }

  generatePromptWbs(nomeProgetto, idOrganizzazione, _callback): void {
    this.chatService.generatePromptWbs(nomeProgetto, idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        console.log(err);
        _callback({prompt: ""});
      }
    );
  }

  copyPromptToClipboard() {
    const textArea = this.renderer.createElement('textarea');
    textArea.value = this.prompt;
    this.renderer.appendChild(document.body, textArea);
    textArea.select();
    document.execCommand('copy');
    this.renderer.removeChild(document.body, textArea);
  }

  sendMessage(newQuery: string) {
    this.query = newQuery;
    this.prompt = undefined;
    switch (this.context) {
      case 'rendicontazione':
        this.generatePromptRendicontazione(this.data.idSal, this.data.idSedeStakeholder, this.data.tipoVersione, (result) => {
          this.prompt = result.prompt + this.query;
        });
        break;
      case 'wbs':
        this.generatePromptWbs(this.data.nomeProgetto, this.data.idOrganizzazione, (result) => {
          this.prompt = result.prompt + this.query;
        });
        break;
      default:
        this.prompt = undefined;
    }
  }

  onCloseChat() {
    this.thisDialogRef.close();
  }

  ngOnInit() {
    this.context = this.data.context;
  }

}
