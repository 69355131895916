import {Rendicontazione} from "./rendicontazione.model";
import {DocumentoBando} from "./documento_bando.model";

export class DocumentoRendicontazione {
  id_documento_bando: number | undefined
  id_rendicontazione: number | undefined
  presente: number | undefined
  note: string | ""
  rendicontazione: Rendicontazione
  documentoBando: DocumentoBando
}
