import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, DateAdapter } from '@angular/material';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { GestionePianiDeiCostiComponent } from '../gestione-piani-dei-costi/gestione-piani-dei-costi.component';
import { GestioneBeniService } from '../common-services/gestione-beni.service';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';

@Component({
  selector: 'app-gestione-beni',
  templateUrl: './gestione-beni.component.html',
  styleUrls: ['./gestione-beni.component.css']
})
export class GestioneBeniComponent implements OnInit {

  @Input() isChild: boolean;
  @Input() data: any;

  isLoading: boolean = true;

  columns = ['nome', 'costo', 'annotazioni', 'ammortizzabile', 'data', 'aliquota',  'percentuale', 'add/remove'];

  NOME_MAX_LENGTH = 200;
  ANNOTAZIONI_MAX_LENGTH = 250;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private adapter: DateAdapter<any>,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private gestioneBeniService: GestioneBeniService,
    private thisDialogRef: MatDialogRef<GestionePianiDeiCostiComponent>,
    public confirmDialog: MatDialog
  ) {
    this.adapter.setLocale("it");
  }

  progetto = undefined;
  sedeStakeholder = undefined;

  beni = [];

  flags = [];
  deleted = [];

  loadBeneUtilizzatoFlag(idBene, _callback) : void {
    this.gestioneBeniService.getBeneUtilizzatoFlag(idBene).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadBeni(idProgetto, idSedeStakeholder, _callback) : void {
    this.gestioneBeniService.getBeni(idProgetto, idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  saveBene(bene, index, _callback) : void {
    this.gestioneBeniService.saveBene(bene).subscribe(
      data => { _callback(data, index); },
      err => { this.showError(err.error.message); }
    );
  }

  updateBene(bene, index, _callback) : void {
    this.gestioneBeniService.updateBene(bene).subscribe(
      data => { _callback(data, index); },
      err => { this.showError(err.error.message); }
    );
  }

  deleteBene(idBene, index, _callback) : void {
    this.gestioneBeniService.deleteBene(idBene).subscribe(
      data => { _callback(data, index); },
      err => { this.showError(err.error.message); }
    );
  }

  onSaveAll() : void {
    const msg = this.checkForErrors();
    if(msg != undefined) this.showError(msg);
    else {
      this.askConfirm("Vuoi davvero salvare i beni?", "Eventuali dati precedenti saranno sovrascritti.", () => {
        this.deleteAll(() => {
          this.saveAll(() => {
            this.thisDialogRef.close();
          });
        });
      });
    }
  }

  saveAllBeni(_callback) : void {
    const msg = this.checkForErrors();
    if(msg != undefined) this.showError(msg);
    else {
      this.deleteAll(() => {
        this.saveAll(() => {
          _callback();
        });
      });
    }
  }

  private deleteAll(_callback) : void {
    if(this.deleted == undefined || this.deleted.length === 0) _callback();
    else {
      this.deleted.forEach((bene, i) => {
        this.deleteBene(bene.id, i, (ref, index) => {
          if(index === this.deleted.length - 1) _callback();
        });
      });
    }
  }

  private saveAll(_callback) : void {
    this.beni.forEach((bene, i) => {
      if(bene.id != undefined) {
        this.updateBene(bene, i, (upRef, upIndex) => {
          if(upIndex === this.beni.length - 1) _callback();
        });
      }
      else {
        this.saveBene(bene, i, (sRef, sIndex) => {
          if(sIndex === this.beni.length - 1) _callback();
        });
      }
    });
  }

  onAddBene() : void {
    this.beni.push({
      nome: undefined, ammortizzabile: true, dataInizioAmmortamento: new Date(), aliquota: 0, costoAcquisto: 0,
      percentualeUtilizzoGenerale: 0, annotazioni: undefined, progetto: this.progetto, sedeStakeholder: this.sedeStakeholder
    });
    this.beni = this.beni.concat([]);
  }

  onRemoveBene(bene) : void {
    this.beni.splice(this.beni.indexOf(bene), 1);
    if(bene.id != undefined) this.deleted.push(bene);
    this.beni = this.beni.concat([]);
  }

  onChangeBeneType(bene) : void {
    bene.dataInizioAmmortamento = bene.ammortizzabile === false ? undefined : new Date();
    bene.aliquota = bene.ammortizzabile === false ? undefined : 0;
    bene.percentualeUtilizzoGenerale = bene.ammortizzabile === false ? undefined : 0;
  }

  checkForErrors() : string {
    if(this.beni == undefined || this.beni.length === 0) return "Impossibile memorizzare una lista di beni vuota.";
    var msg = undefined;
    this.beni.forEach(bene => {
      if(bene.nome == undefined || bene.nome.length === 0) { msg = "Impossibile memorizzare beni privi di nome."; return; }
      if(bene.nome.length > this.NOME_MAX_LENGTH) { msg = "I nomi indicati sono troppo lunghi."; return; }
      if(bene.locazioneFinanziaria === false && bene.dataInizioAmmortamento == undefined) {
        msg = "Impossibile memorizzare un bene privo di data di inizio ammortamento."; return;
      }
      if(bene.costoAcquisto == undefined || isNaN(bene.costoAcquisto) || bene.costoAcquisto < 0) {
        msg = "Impossibile memorizzare un bene con costo di acquisto negativo o assente."; return;
      }
      if(bene.annotazioni != undefined && bene.annotazioni.length > this.ANNOTAZIONI_MAX_LENGTH) {
        msg = "Le annotazioni indicate sono troppo lunghe."; return;
      }
    });
    return msg;
  }

  isUtilizzato(bene) : boolean {
    if(bene.id == undefined) return false;
    const ref = this.flags.find(found => found.id === bene.id);
    return ref != undefined && ref.flag === true;
  }

  initDataFromDatabase(_callback) : void {
    this.loadBeni(this.progetto.id, this.sedeStakeholder.id, (beniRef) => {
      this.beni = beniRef != undefined ? beniRef : [];
      this.flags = [];
      this.deleted = [];
      this.beni.forEach(bene => {
        bene.dataInizioAmmortamento = new Date(bene.dataInizioAmmortamento);
        this.loadBeneUtilizzatoFlag(bene.id, (flag) => { this.flags.push({id: bene.id, flag: flag}); });
      });
      _callback();
    });
  }

  initData() : void {
    if(this.isChild) {
      if(this.data == undefined) this.showError("Errore nell'inizializzazione dei dati.");
      else this.initChildData();
    }
    else this.initDialogData();
  }

  private initChildData() : void {
    const ref = JSON.parse(this.data);
    if(ref.progetto == undefined || ref.sedeStakeholder == undefined)
      this.showError("Errore nell'inizializzazione dei dati.");
    else { this.progetto = ref.progetto; this.sedeStakeholder = ref.sedeStakeholder; }
  }

  private initDialogData() : void {
    const ref = sessionStorage.getItem("gestione_beni_data");
    if(ref == undefined) this.showError("Errore nell'inizializzazione dei dati.");
    else {
      const json = JSON.parse(ref);
      if(json == undefined || json.progetto == undefined || json.sedeStakeholder == undefined)
        this.showError("Errore nell'inizializzazione dei dati.");
      else { this.progetto = json.progetto; this.sedeStakeholder = json.sedeStakeholder; }
      sessionStorage.removeItem("gestione_beni_data");
    }
  }

  askConfirm(message: string, submessage: string, _callback) : void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if(res) _callback();
      }
    );
  }

  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.initData();
    this.initDataFromDatabase(() => {
      this.isLoading = false;
    });
  }

}
