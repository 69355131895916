import {Component, Input, OnInit} from '@angular/core';
import {OrganizzazioniService} from "../organizzazioni/organizzazioni.service";
import {UserStorage} from "../core_modules/user.storage";
import {GestioneBandiService} from "../gestione-bandi/gestione-bandi.service";
import {AuthService} from "../core_modules/auth.service";
import {GestionePartnerProgettoService} from "../gestione-partner-progetto/gestione-partner-progetto.service";
import {GestioneProgettoService} from "../gestione-progetto/gestione-progetto.service";
import {GestioneWbsService} from "../gestione-wbs/gestione-wbs.service";
import {ConfirmMessageComponent} from "../modals/confirm-message/confirm-message.component";
import {AppSettings} from "../app.settings";

export class ReportElement {
  name: string;
  position: number;
  linkdownload: string;
}

const ELEMENT_DATA: ReportElement[] = [
  {position: 1, name: 'Costi Ammissibili', linkdownload: AppSettings.BASE_URL + '/reportcosti/progetto/'},
  {position: 2, name: 'Riepilogo Costi Per Sal', linkdownload: AppSettings.BASE_URL + '/reportcostisal/reportcostisal/'}
];


@Component({
  selector: 'app-reportcosti',
  templateUrl: './reportcosti.component.html',
  styleUrls: ['./reportcosti.component.css']
})

export class ReportcostiComponent {


  public progetto = sessionStorage.getItem("onMonitoraCosti.idProgetto");

  constructor() {
    console.log("costruttore " + sessionStorage.getItem("onMonitoraCosti.idProgetto"));
    this.progetto = sessionStorage.getItem("onMonitoraCosti.idProgetto");
  }

  displayedColumns: string[] = ['position', 'name', 'linkdownload'];
  dataSource = ELEMENT_DATA;


  download(type): void {
    //window.location.replace(window.location.origin + '/progetti/importa');
    var href = "";
    if (type == "Costi Ammissibili") {
      href =AppSettings.BASE_URL + "/reportcosti/progetto/" + this.progetto;
    }
    if (type == "Riepilogo Costi Per Sal") {
      href =AppSettings.BASE_URL + "/reportcostisal/reportcostisal/" + this.progetto;
    }
    var link = document.createElement("a");
    link.href = href;
    link.click();
  }
}
