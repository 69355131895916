import { Component, OnInit } from '@angular/core';
import { TipoDiFinanziamentoService } from '../tipo-di-finanziamento/tipo-di-finanziamento.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { UserStorage } from '../core_modules/user.storage';
import { OrganizzazioniService } from '../organizzazioni/organizzazioni.service';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { AppErrors } from '../app.errors';

const NOME_MAX_LENGTH=250;
const DESCRIZIONE_MAX_LENGTH=500;

@Component({
  selector: 'app-tipo-di-finanziamento',
  templateUrl: './tipo-di-finanziamento.component.html',
  styleUrls: ['./tipo-di-finanziamento.component.css']
})
export class TipoDiFinanziamentoComponent implements OnInit {

  gestione_tipi_di_finanziamento_section_title="Gestione Tipi di Finanziamento"
  organizzazioneName = undefined;
  organizzazioneObj = undefined;

  constructor(
    private http: HttpClient,
    private organizzazioniService: OrganizzazioniService,
    private TipoDiFinanziamentoService: TipoDiFinanziamentoService,
    private userStorage: UserStorage,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>
    ) { }

  tipi_di_finanziamento_table_data;

  tipi_di_finanziamento_table_settings = {
    noDataMessage: "Nessun dato da visualizzare",
    columns: {
      nome: {
        title: 'Nome'
      },
      descrizione: {
        title: 'Descrizione'
      }
    },
    actions: {
      edit: true,
      delete: false,
      columnTitle: ""
    },
    edit: {
      editButtonContent: "Modifica",
      saveButtonContent: "Aggiorna",
      cancelButtonContent: "Annulla",
      confirmSave: true
    },
    delete: {
      deleteButtonContent: "Elimina",
      confirmDelete: true
    },
    add: {
      addButtonContent: "Aggiungi nuova",
      createButtonContent: "Aggiungi",
      cancelButtonContent: "Annulla",
      confirmCreate: true
    },
    attr: {
      class: 'table table-bordered tipi_di_finanziamento_table'
    },
    pager: {
      display: false
    },
    mode: "inline",
  };

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  loadTipiDiFinanziamentoPerOrganizzazione(organizzazione){
    this.TipoDiFinanziamentoService.getAllByOrganizzazione(organizzazione.id).subscribe(
      data => { 
        this.tipi_di_finanziamento_table_data = data;
        this.tipi_di_finanziamento_table_data.forEach(element => {
          if(element.attivo==false)
          {
            element.attivo="No";
          }
          else
          {
            element.attivo="Sì";
          } 
         });
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...")
        console.log(err);
        console.log("*************************************************");
      }
    );
  }

  loadOrganizzazione() {
    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        this.organizzazioneObj = data;
        this.loadTipiDiFinanziamentoPerOrganizzazione(this.organizzazioneObj);
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...");
        console.log(err);
        console.log("*************************************************");
      }
    );
  }

  checkIfDataIsValid(oldData, newData) {
    if (!oldData) { 
      if (!newData || newData.nome.length<=0 || newData.nome.length>NOME_MAX_LENGTH) {
        //il nome della voce di costo non può esssere maggiore di VARCHAR(45)
        return AppErrors.NOME_VOCE_DI_COSTO_LENGTH_ERROR; 
      }
      if (!newData || newData.descrizione.length>DESCRIZIONE_MAX_LENGTH) {
        //la descrizione della voce di costo non può essere maggiore di VARCHAR(100)
        return AppErrors.DESCRIZIONE_VOCE_DI_COSTO_LENGTH_ERROR; 
      }
      return undefined;
    }
    if(!newData || newData.nome.length<=0 || newData.nome.length>NOME_MAX_LENGTH) {
      //il nome della voce di costo non può essere vuoto o maggiore di VARCHAR(45)
      return AppErrors.NOME_VOCE_DI_COSTO_LENGTH_ERROR; 
    }
    if(newData && newData.descrizione) {
      if(newData.descrizione.length>DESCRIZIONE_MAX_LENGTH) {
        //la descrizione della voce di costo non può essere maggiore di VARCHAR(100)
        return AppErrors.DESCRIZIONE_VOCE_DI_COSTO_LENGTH_ERROR; 
      }
    }
    return undefined;
  }

  saveTipoDiFinanziamento(event, tipoDiFinanziamento) {
    if(tipoDiFinanziamento.attivo=="No") {
      tipoDiFinanziamento.attivo=false;
    }
    else {
      tipoDiFinanziamento.attivo=true;
    }
    this.TipoDiFinanziamentoService.saveTipoDiFinanziamento(tipoDiFinanziamento).subscribe(
      data => {
        if(data.attivo==false) {
          tipoDiFinanziamento.attivo="No";
        }
        else {
          tipoDiFinanziamento.attivo="Sì";
        }
        event.confirm.resolve(event.newData);
        this.loadTipiDiFinanziamentoPerOrganizzazione(this.organizzazioneObj);
        this.openSnackBar("Dati memorizzati con successo", "Chiudi");
      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        event.confirm.reject();
      }
    );
  }

  onSaveConfirm(event) {
    if(!event.newData.organizzazione)
    {
      event.newData.organizzazione = this.organizzazioneObj;
    }
    let result = this.checkIfDataIsValid(event.data,event.newData);
    if(result)
    {
      this.dialog.open(WarningMessageComponent,{
        data: {
          message: result
        },
        panelClass: 'custom-warning-container'
      });
      event.confirm.reject();
    }
    else
    {
      this.saveTipoDiFinanziamento(event,event.newData)
    }
  }

  updateTipoDiFinanziamento(event, tipoDiFinanziamento) {
    if(tipoDiFinanziamento.attivo=="No") {
      tipoDiFinanziamento.attivo=false;
    }
    else {
      tipoDiFinanziamento.attivo=true;
    }
    this.TipoDiFinanziamentoService.updateTipoDiFinanziamento(tipoDiFinanziamento).subscribe(
      data => {
        if(data.attivo==false) {
          tipoDiFinanziamento.attivo="No";
        }
        else {
          tipoDiFinanziamento.attivo="Sì";
        }
        event.confirm.resolve(event.newData);
        this.loadTipiDiFinanziamentoPerOrganizzazione(this.organizzazioneObj);
        this.openSnackBar("Dati memorizzati con successo", "Chiudi");
      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        event.confirm.reject();
      }
    );
  }

  onUpdateConfirm(event) {
    if(!event.newData.organizzazione)
    {
      event.newData.organizzazione = this.organizzazioneObj;
    }
    let result = this.checkIfDataIsValid(event.data,event.newData);
    if(result)
    {
      this.dialog.open(WarningMessageComponent,{
        data: {
          message: result
        },
        panelClass: 'custom-warning-container'
      });
      event.confirm.reject();
    }
    else
    {
      this.updateTipoDiFinanziamento(event,event.newData)
    }
  }

  onDeleteConfirm(event) {
    this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: "Vuoi davvero archiviare questo Tipo di Finanziamento?",
        submessage: "Comporterà la sua disattivazione e la rimozione/archiviazione di alcuni elementi collegati."
      },
      panelClass: 'custom-confirm-container'
    });
    console.log(event.data);
    if(event.data.attivo=="No") {
      event.data.attivo=false;
    }
    else {
      event.data.attivo=true;
    }

    this.dialogRef.afterClosed().subscribe(
      res => {
        if(res==true)
        {      
          this.TipoDiFinanziamentoService.deleteTipoDiFinanziamento(event.data.nome, this.organizzazioneObj.id).subscribe(
            data =>{
              event.confirm.resolve(event.data);
              this.openSnackBar("Dati eliminati con successo", "Chiudi");
              this.loadTipiDiFinanziamentoPerOrganizzazione(this.organizzazioneObj);
            },
            err =>{
              console.log("*************************************************");
              console.log("ERROR...")
              console.log(err);
              console.log("*************************************************");
              this.dialog.open(WarningMessageComponent,{
                data: {
                  message: err
                },
                panelClass: 'custom-warning-container'
              });
              event.confirm.reject();
            }
          )
        }
        else
        {
          event.confirm.reject();
        }
      }
    );    

  }

  ngOnInit() {
    this.organizzazioneName=this.userStorage.getOrganizzazione();
    this.loadOrganizzazione();  
  }

}
