import {Component, OnInit, ViewChild, QueryList, ViewChildren} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoadingDialogService} from '../modals/loading-dialog/loading-dialog.service';
import {WarningMessageComponent} from '../modals/warning-message/warning-message.component';
import {RendicontazioneProgettoService} from './rendicontazione-progetto.service';
import {GestionePianiDeiCostiService} from '../gestione-piani-dei-costi/gestione-piani-dei-costi.service';
import {GestioneCostiPersonaModalComponent} from './modals/gestione-costi-persona-modal/gestione-costi-persona-modal.component';
import {RendicontazionePersonaModalComponent} from './modals/rendicontazione-persona-modal/rendicontazione-persona-modal.component';
import {ConfirmMessageComponent} from '../modals/confirm-message/confirm-message.component';
import {GestioneBandiService} from '../gestione-bandi/gestione-bandi.service';
import {GestioneBeniComponent} from '../gestione-beni/gestione-beni.component';
import {DettaglioCostiConsulenzeModalService} from '../gestione-piani-dei-costi/modals/dettaglio-costi-consulenze-modal/dettaglio-costi-consulenze-modal.service';
import {AuthService} from '../core_modules/auth.service';
import {GestioneStakeholdersService} from '../gestione-stakeholders/gestione-stakeholders.service';
import {GestionePersonaModalComponent} from '../gestione-stakeholders/modals/gestione-persona-modal/gestione-persona-modal.component';
import {RotellinaService} from '../rotellina-dialog/rotellina.service';
import {PromptGeneratorComponentComponent} from '../chat/modals/prompt-generator-component/prompt-generator-component.component';

export interface PersonaWrapper {
  persona: any;
  contratti: any[];
  costiOrari: any[];
  bustePaga: any[];
  rendicontazioni: any [];
}

@Component({
  selector: 'app-rendicontazione-progetto',
  templateUrl: './rendicontazione-progetto.component.html',
  styleUrls: ['./rendicontazione-progetto.component.css']
})
export class RendicontazioneProgettoComponent implements OnInit {

  DESCRIZIONE_MAX_LENGTH = 250;
  RISULTATI_CONSULENZA_MAX_LENGTH = 500;

  NOMI_VDC_BENI = ['Strumentazioni e attrezzature', 'Locazione finanziaria', 'Immobili e terreni', 'Immobili e impianti', 'Terreni', 'Beni immateriali'];
  NOMI_VDC_BENI_NON_AMMORTIZZABILI = ['Locazione finanziaria'];
  NOME_VDC_PERSONALE = "Spese di personale";
  NOME_VDC_GENERALI = "Spese generali";
  NOMI_VDC_CONSULENZE = ["Servizi di consulenza", "Ric. contrattuale, conoscenza e brevetti"];

  progetti = [];
  stakeholders = [];
  sedi = [];
  vociDiCosto = [];
  listaSal = [];

  vociDiCostoBando = [];

  recordWbs = [];
  personale = [];

  consulenti = [];
  consulentiFiltered = [];

  elementi = [];
  personaleWrappers: PersonaWrapper[] = [];

  organizzazione = undefined;
  selectedProgetto = undefined;
  selectedStakeholder = undefined;
  selectedSedeStakeholder = undefined;
  selectedVoceDiCosto = undefined;
  selectedSal = undefined;

  statoSelectedSal = undefined;
  rendicontazioneAttivitaFuoriPeriodoFlag: boolean = false;
  visualizzazionePersoneRendicontateFlag: boolean = false;

  pianoCostiAmmissibili = undefined;

  rendicontazione = undefined;
  rendicontazioniPrecedenti = [];
  tipoUltimoImport: string = 'N';

  firstSectionDisplayedColumns: string[] = ['attivita', 'import_sal_corrente', 'importi_sal_precedenti', 'totale_preventivato', 'rimanenza_da_rendicontare', 'calcola'];
  secondSectionDisplayedColumns: string[] = [];
  thirdSectionDisplayedColumns: string[] = [];

  dettaglioColumns: string[] = ['record', 'elemento'];

  tipo = 0;
  tipi = ['B', 'P', 'C'];

  coloriWbs = [];
  mesi = [];

  isChatOpen = false;
  chatDialogRef = undefined;
  showChat = false;

  @ViewChildren(GestioneBeniComponent) gestoreBeni: QueryList<GestioneBeniComponent>;

  constructor(
    public loadingDialogService: LoadingDialogService,
    public dialog: MatDialog,
    public confirmDialog: MatDialog,
    private rendicontazionePersonaDialog: MatDialog,
    private authService: AuthService,
    private gestionePianiDeiCostiService: GestionePianiDeiCostiService,
    private dettaglioCostiConsulenzeModalService: DettaglioCostiConsulenzeModalService,
    private gestioneBandiService: GestioneBandiService,
    public snackBar: MatSnackBar,
    private rendicontazioneProgettoService: RendicontazioneProgettoService,
    public gestionePersonaModalDialog: MatDialog,
    public promptGeneratorModalDialog: MatDialog,
    private gestioneStakeholdersService: GestioneStakeholdersService,
    private rotella: RotellinaService
  ) {
  }

  /**
   * Carica l'organizzazione dal database.
   * @param nome Nome dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadOrganizzazione(nome, _callback): void {
    this.rendicontazioneProgettoService.getOrganizzazione(nome).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica le sedi stakeholder dal database.
   * @param idStakeholder Id dello stakeholder.
   * @param _callback Azione successiva.
   */
  loadSediStakeholder(idStakeholder, _callback): void {
    this.rendicontazioneProgettoService.getSedi(idStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica le voci di costo rendicontabili dal database, ovvero quelle il cui importo totale nel piano dei costi ammissibili è maggiore di 0.
   * @param idProgetto Id del progetto.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param _callback Azione successiva.
   */
  loadVociDiCostoRendicontabili(idProgetto, idSedeStakeholder, _callback): void {
    this.rendicontazioneProgettoService.getVociDiCostoRendicontabili(idProgetto, idSedeStakeholder).subscribe(
      data => {  /*alert("data "+data);*/
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica gli stakeholder partner di progetto dal database.
   * @param idProgetto Id del progetto.
   * @param idOrganizzazione Id dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadStakeholders(idProgetto, idOrganizzazione, _callback): void {
    this.rendicontazioneProgettoService.getStakeholders(idProgetto, idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica i progetti rendicontabili dal database, ovvero quelli che hanno almeno una versione di piano dei costi ammissibili e
   * almeno una versione di WBS.
   * @param idOrganizzazione Id dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadProgettiRendicontabili(idOrganizzazione, _callback): void {
    this.rendicontazioneProgettoService.getProgettiRendicontabili(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica la lista dei SAL dal database.
   * @param idProgetto Id del progetto.
   * @param _callback Azione successiva.
   */
  loadListaSal(idProgetto, _callback): void {
    this.rendicontazioneProgettoService.getSal(idProgetto).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError("Errore nell'inizializzazione del piano dei SAL. Verificare che almeno un piano sia stato indicato per il progetto selezionato e riprovare.");
      }
    );
  }

  /**
   * Carica una determianta rendicontazione dal database.
   * @param idSal Id del SAL.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param tipoVersione Tipo della rendicontazione (B, P, C).
   * @param _callback Azione successiva.
   */
  loadRendicontazione(idSal, idSedeStakeholder, tipoVersione, _callback): void {
    this.rendicontazioneProgettoService.getRendicontazione(idSal, idSedeStakeholder, tipoVersione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        _callback(undefined);
      }
    );
  }

  /**
   * Carica i record WBS di primo livello dal database.
   * @param idProgetto Id del progetto.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param _callback Azione successiva.
   */
  loadOrWp(idProgetto, idSedeStakeholder, _callback): void {
    this.rendicontazioneProgettoService.getOrWP(idProgetto, idSedeStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica i record WBS di secondo livello dal database.
   * @param idProgetto Id del progetto.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param _callback Azione successiva.
   */
  loadAttivita(idProgetto, idSedeStakeholder, _callback): void {
    this.rendicontazioneProgettoService.getAttivita(idProgetto, idSedeStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica il personale dal database.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param idOrganizzazione Id dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadPersonale(idSedeStakeholder, idOrganizzazione, _callback): void {
    this.rendicontazioneProgettoService.getPersone(idSedeStakeholder, idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica il personale per le rendicontazione dal database.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param idOrganizzazione Id dell'organizzazione.
   * @param _callback Azione successiva.
   */

  loadPersonaleRendicontazione(idSedeStakeholder, idOrganizzazione, _callback): void {
    this.rendicontazioneProgettoService.getPersoneNonFittizione(idSedeStakeholder, idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica i contratti di collaborazione dal database.
   * @param idProgetto Id del progetto.
   * @param idPersona Id della persona.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param _callback Azione successiva.
   */
  loadContratti(idProgetto, idPersona, idSedeStakeholder, _callback): void {
    this.rendicontazioneProgettoService.getContratti(idProgetto, idPersona, idSedeStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica i costi orari dal database.
   * @param idProgetto Id del progetto.
   * @param idPersona Id della persona.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param _callback Azione successiva.
   */
  loadCostiOrari(idProgetto, idPersona, idSedeStakeholder, _callback): void {
    this.rendicontazioneProgettoService.getCostiOrari(idProgetto, idPersona, idSedeStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica l'ultima versione del piano dei costi ammissibili dal database.
   * @param idProgetto Id del progetto.
   * @param idWbs Id della WBS.
   * @param _callback Azione successiva.
   */
  loadPianoCostiAmmissibili(idProgetto, idWbs, _callback): void {
    this.gestionePianiDeiCostiService.getUltimaVersionePianoByProgettoIdAndWbsId(idProgetto, idWbs).subscribe(
      data => {
        _callback(data);
      },
      err => {
        _callback(undefined);
      }
    );
  }

  /**
   * Carica le buste paga dal database.
   * @param idPersona Id della persona.
   * @param _callback Azione successiva.
   */
  loadBustePaga(idPersona, _callback): void {
    this.rendicontazioneProgettoService.getBustePaga(idPersona).subscribe(
      data => {
        _callback(data);
      },
      err => {
        _callback(undefined);
      }
    );
  }

  /**
   * Carica i costi orari pianificati dal database.
   * @param idProgetto Id del progetto.
   * @param idPersona Id della persona.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param _callback Azione successiva.
   */
  loadCostiOrariPianificati(idProgetto, idPersona, idSedeStakeholder, _callback): void {
    this.rendicontazioneProgettoService.getCostiOrariPianificati(idProgetto, idPersona, idSedeStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica i contratti pianificati dal database.
   * @param idProgetto Id del progetto.
   * @param idPersona Id della persona.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param _callback Azione successiva.
   */
  loadContrattiPianificati(idProgetto, idPersona, idSedeStakeholder, _callback): void {
    this.rendicontazioneProgettoService.getContrattiPianificati(idProgetto, idPersona, idSedeStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica il wrapper di pianificazione mensile dal database.
   * @param idProgetto Id del progetto.
   * @param idPersona Id della persona.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param anno Anno di pianificazione.
   * @param mese Mese di pianificazione.
   * @param idRecordWbs Id del record WBS.
   * @param _callback Azione successiva.
   */
  loadMensilePianificato(idProgetto, idPersona, idSedeStakeholder, anno, mese, idRecordWbs, _callback): void {
    this.rendicontazioneProgettoService.getMensilePianificato(idProgetto, idPersona, idSedeStakeholder, anno, mese, idRecordWbs).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica lo stato del SAL (B, P, C).
   * @param idSal Id del SAL.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param _callback Azione successiva.
   */
  loadStatoSal(idSal, idSedeStakeholder, _callback): void {
    this.rendicontazioneProgettoService.getStatoSal(idSal, idSedeStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica le relazioni bando-voce di costo dal database.
   * @param idBando Id del bando.
   * @param idOrganizzazione Id dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadVociDiCostoBando(idBando, idOrganizzazione, _callback): void {
    this.gestioneBandiService.getVociDiCostoBando(idBando, idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica tutte le sedi stakeholder per le possibili consulenze.
   * @param idOrganizzazione Id dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadSediPossibiliConsulenti(idOrganizzazione, _callback): void {
    this.dettaglioCostiConsulenzeModalService.getSedi(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Salva una nuova rendicontazione sul database tramite wrapper.
   * @param wrapper Wrapper di rendicontazione da salvare.
   * @param _callback Azione successiva.
   */
  saveRendicontazione(wrapper, _callback): void {
    this.rendicontazioneProgettoService.saveRendicontazione(wrapper).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Allinea la pianificazione dei costi ai dati di rendicontazione.
   * @param idProgetto Id del progetto.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param _callback Azione successiva
   */
  allineaPianificazione(idProgetto, idSedeStakeholder, _callback): void {
    this.rendicontazioneProgettoService.allineaPianificazione(idProgetto, idSedeStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Salva una nuova persona sul database.
   * @param persona Persona da salvare.
   * @param _callback Azione successiva.
   */
  savePersona(persona, _callback): void {
    this.gestioneStakeholdersService.savePersona(persona).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Gestisce l'evento di aggiunta di una nuova persona.
   */
  onAddPersona(): void {
    const gestionePersonaModalRef = this.gestionePersonaModalDialog.open(GestionePersonaModalComponent);
    gestionePersonaModalRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        result.sedeStakeholder = this.selectedSedeStakeholder;
        result.organizzazione = this.organizzazione;
        var errorMessage = undefined;
        if (result.fittizia === true && this.personale != undefined) {
          this.personale.forEach(element => {
            if (element.fittizia === true && element.cognome === result.cognome && element.nome === result.nome) {
              errorMessage = "Impossibile memorizzare una persona con nome e cognome fittizi già usati.";
              return;
            }
          });
        }
        if (errorMessage != undefined) this.showError(errorMessage);
        else {
          this.askConfirm("Vuoi davvero salvare la persona?", "È consigliabile salvare prima di procedere. Il salvataggio comporterà l'inserimento della persona nel personale della sede selezionata.", () => {
            this.savePersona(result, (personaRef) => {
              this.openSnackBar(personaRef.cognome + " " + personaRef.nome + " aggiunto/a al personale della sede selezionata.", "Chiudi");
              this.updateAfterSavingPersona(personaRef);
            });
          });
        }
      }
    });
  }

  /**
   * Gestisce l'evento di selezione progetto.
   * Inizializza lista stakeholders partner di progetto e lista SAL di progetto.
   */
  onSelectProgetto(): void {
    this.selectedStakeholder = undefined;
    this.selectedSedeStakeholder = undefined;
    this.selectedVoceDiCosto = undefined;
    this.selectedSal = undefined;
    this.rendicontazione = undefined;
    this.loadStakeholders(this.selectedProgetto.id, this.organizzazione.id, (stakeholdersRef) => {
      this.stakeholders = stakeholdersRef != undefined ? stakeholdersRef : [];
      this.loadListaSal(this.selectedProgetto.id, (salRef) => {
        this.listaSal = salRef != undefined ? salRef : [];
      });
    });
  }

  /**
   * Gestisce l'evento di selezione stakeholder.
   * Inizializza le sedi associate allo stakeholder.
   */
  onSelectStakeholder(): void {
    this.selectedSedeStakeholder = undefined;
    this.selectedVoceDiCosto = undefined;
    this.selectedSal = undefined;
    this.rendicontazione = undefined;
    this.loadSediStakeholder(this.selectedStakeholder.id, (sediRef) => {
      this.sedi = sediRef != undefined ? sediRef : [];
    });
    this.loadSediPossibiliConsulenti(this.organizzazione.id, (spc) => {
      this.consulenti = spc != undefined ? spc.filter(filtered => filtered.stakeholder.id != this.selectedStakeholder.id) : [];
      this.consulentiFiltered = this.consulenti.concat([]);
    });
  }

  /**
   * Gestisce l'evento di selezione sede stakeholder.
   * Inizializza le voci di costo rendicontabili, i record di WBS da rendicontare e il personale da rendicontare.
   */
  onSelectSedeStakeholder(): void {
    this.selectedVoceDiCosto = undefined;
    this.selectedSal = undefined;
    this.rendicontazione = undefined;
    this.loadVociDiCostoRendicontabili(this.selectedProgetto.id, this.selectedSedeStakeholder.id, (vociDiCostoRef) => {
      // alert("vociDiCostoRef "+vociDiCostoRef);
      this.vociDiCosto = vociDiCostoRef != undefined ? vociDiCostoRef : [];
      this.vociDiCostoBando = [];
      this.loadVociDiCostoBando(this.selectedProgetto.bando.id, this.selectedProgetto.bando.organizzazione.id, (vdcb) => {
        if (vdcb != undefined && vdcb.length > 0) {
          vdcb.forEach(voceBando => {
            if (this.vociDiCosto.find(found => found.id === voceBando.voceDiCosto.id) != undefined) this.vociDiCostoBando.push(voceBando);
          });
        }
      });
    });
    this.recordWbs = [];
    if (this.selectedProgetto.bando.confLivelloCostiInWbs === false) this.loadOrWp(this.selectedProgetto.id, this.selectedSedeStakeholder.id, (orWpRef) => {
      this.recordWbs = orWpRef != undefined ? orWpRef : [];
    });
    else this.loadAttivita(this.selectedProgetto.id, this.selectedSedeStakeholder.id, (attivitaRef) => {
      this.recordWbs = attivitaRef != undefined ? attivitaRef : [];
    });
    this.loadPersonaleRendicontazione(this.selectedSedeStakeholder.id, this.organizzazione.id, (personaleRef) => {
      this.personale = personaleRef != undefined ? personaleRef : [];
    });
  }

  /**
   * Gestisce l'evento di selezione SAL.
   * Inizializza le tabelle front-end, i dati di rendicontazione, il piano dei costi ammissibili e lo stato del SAL.
   */
  onSelectSal(): void {
    this.showChat = false;
    //alert("onSelectSal " + this.selectedSal.id + ",stack" + this.selectedStakeholder.id);
    this.rendicontazioneProgettoService.saldatepartner(this.selectedSal.id, this.selectedStakeholder.id).subscribe(
      data => {
        console.log(data);
        //alert("data " + JSON.stringify(data));
        this.selectedSal.dataInizio = new Date(data.dataInizio);
        this.selectedSal.dataFine = new Date(data.dataFine);
        this.rendicontazione = undefined;
        this.initTabelle();
        this.initDataFromRendicontazione();
        this.loadPianoCostiAmmissibili(this.selectedProgetto.id, this.recordWbs[0].wbs != undefined ? this.recordWbs[0].wbs.id : this.recordWbs[0].recordWbsPrimoLivello.wbs.id, (pianoRef) => {
          if (pianoRef != undefined) this.pianoCostiAmmissibili = pianoRef;
        });
        this.initStatoSal();
      },
      err => {
        console.error("erorre a onselectsal")
        this.showError(err.error.message);
      }
    )

  }

  /**
   * Gestisce l'evento di selezione voce di costo.
   * Inizializza tutti i dati dipendenti dalla specifica voce di costo selezionata e relativi componenti.
   */
  onSelectVoceDiCosto(): void {
    if (this.isVoceDiCostoRiferitaBeni(this.selectedVoceDiCosto)) {
      this.gestoreBeni.changes.subscribe((comps: QueryList<GestioneBeniComponent>) => {
        this.elementi.filter(filtered => filtered.elemento.voceDiCosto.nome === this.selectedVoceDiCosto.nome).forEach(e => {
          e.dettagli.forEach(d => {
            if (comps != undefined && comps.first != undefined && comps.first.beni != undefined && d.bene != undefined) {
              const beneRef = comps.first.beni.find(found => found.id === d.bene.id);
              if (beneRef != undefined) d.bene = beneRef;
            }
          });
        });
      });
    }
  }

  /**
   * Gestisce l'evento di import di una versione di rendicontazione.
   * Chiede conferma, dopodiché carica la rendicontazione ed inizializza in base ai dati.
   * @param tipo Tipo di rendicontazione da importare (B, P, C).
   */
  onImportaUltimaVersioneTipo(tipo): void {
    this.askConfirm("Vuoi davvero importare una nuova versione di rendicontazione?", "Eventuali progressi non salvati andranno persi.", () => {
      this.tipo = tipo;
      this.showChat = true;
      this.rotella.openDialog();
      this.loadRendicontazione(this.selectedSal.id, this.selectedSedeStakeholder.id, this.tipi[this.tipo], (rendicontazioneRef) => {
        this.rendicontazione = rendicontazioneRef;
        this.tipoUltimoImport = 'N';
        if (this.rendicontazione != undefined && this.rendicontazione.rendicontazione != undefined) {
          this.tipoUltimoImport = (this.rendicontazione.rendicontazione.tipoVersione != undefined) ? this.rendicontazione.rendicontazione.tipoVersione : 'N';
          this.rendicontazioneAttivitaFuoriPeriodoFlag = this.rendicontazione.rendicontazione.confRendicontazioneRecordWbsFuoriPeriodo != undefined ? this.rendicontazione.rendicontazione.confRendicontazioneRecordWbsFuoriPeriodo : false;
        }
        this.rotella.closeDialog();

        this.openSnackBar(this.rendicontazione != undefined ? "Ultima versione di rendicontazione per il tipo indicato importata con successo." : "Nessuna versione disponibile per il tipo indicato. I dati sono inizializzati di default.", "Chiudi");
        this.initDataFromRendicontazione();
      });
    });
  }

  /**
   * Gestisce l'evento di import da ultima pianificazione effettuata.
   * Chiede conferma, dopodiché carica la pianificazione sottoforma di wrapper di rendicontazione ed inizializza in base ai dati.
   */
  onImportaPianificazione(): void {
    this.askConfirm("Vuoi davvero importare una nuova versione di rendicontazione?", "Eventuali progressi non salvati andranno persi.", () => {
      this.tipo = 0;
      this.tipoUltimoImport = 'F';
      this.initRendicontazioniPrecedenti();
      this.rendicontazione = {tipoVersione: 'B', sal: this.selectedSal, sedeStakeholder: this.selectedSedeStakeholder};
      this.rendicontazioneAttivitaFuoriPeriodoFlag = true;
      this.initFromPianificazione();
      this.openSnackBar("Rendicontazione con dati di pianificazione più recenti importata con successo.", "Chiudi");
    });
  }

  /**
   * Gestisce l'evento di gestione persona da rendicontare.
   * Apre il dialog di gestione dei costi di una persona.
   * @param persona Wrapper di persona da gestire.
   */
  onGestisciPersona(persona: PersonaWrapper): void {
    sessionStorage.setItem("gestione_costi_persona_data", JSON.stringify({
      contratti: persona.contratti,
      costiOrari: persona.costiOrari,
      persona: persona.persona,
      sal: this.selectedSal,
      recordWbs: this.recordWbs,
      stakeholder: this.selectedStakeholder
    }));
    var dialogRef = this.confirmDialog.open(GestioneCostiPersonaModalComponent);
    dialogRef.afterClosed().subscribe(
      res => {
        if (res != undefined) {
          persona.contratti = res.contratti.concat([]);
          persona.costiOrari = res.costiOrari.concat([]);
          this.onRefreshCostiOrariMensili(persona);
          if (persona.costiOrari != undefined) {
            persona.costiOrari.forEach(costoOrario => {
              costoOrario.progetto = this.selectedProgetto;
              costoOrario.persona = persona.persona;
              costoOrario.sedeStakeholder = this.selectedSedeStakeholder;
            });
          }
          if (persona.contratti != undefined) {
            persona.contratti.forEach(contrattoWrapper => {
              contrattoWrapper.contratto.progetto = this.selectedProgetto;
              contrattoWrapper.contratto.persona = persona.persona;
              contrattoWrapper.contratto.sedeStakeholder = this.selectedSedeStakeholder;
            });
          }
        }
      }
    );
  }

  /**
   * Gestisce l'evento di rendicontazione persona.
   * Apre il dialog di rendicontazione persona.
   * @param persona Wrapper di persona da rendicontare.
   */
  onRendicontaPersona(persona: PersonaWrapper): void {

    // console.log("ou "+JSON.stringify({ rendicontazionePersonaWrapper: { persona: persona.persona, rendicontazioniMensili: persona.rendicontazioni }, mode: false,
    //  recordWbs: this.recordWbs, coloriWbs: this.coloriWbs, indicatori: this.mesi, contratti: persona.contratti, rendicontazioneAttivitaFuoriPeriodoFlag: this.rendicontazioneAttivitaFuoriPeriodoFlag }));


    sessionStorage.setItem("rendicontazione_persona_data", JSON.stringify({
      rendicontazionePersonaWrapper: {persona: persona.persona, rendicontazioniMensili: persona.rendicontazioni},
      mode: false,
      recordWbs: this.recordWbs,
      coloriWbs: this.coloriWbs,
      indicatori: this.mesi,
      contratti: persona.contratti,
      rendicontazioneAttivitaFuoriPeriodoFlag: this.rendicontazioneAttivitaFuoriPeriodoFlag
    }));


    var dialogRef = this.rendicontazionePersonaDialog.open(RendicontazionePersonaModalComponent);
    dialogRef.afterClosed().subscribe(
      res => {
        if (res != undefined) {
          persona.rendicontazioni = res.rendicontazioniMensili.concat([]);
          this.openSnackBar("Rendicontazione per " + persona.persona.cognome + " " + persona.persona.nome + " salvata con successo.", "Chiudi");
        }
      }
    );
  }

  /**
   * Gestisce l'evento di salvataggio nuova versione di rendicontazione.
   * @param tipo Tipo da salvare (B, P, C).
   */
  onSaveAll(tipo): void {
    const errorMessage = this.checkErrors(this);
    if (errorMessage != undefined) this.showError(errorMessage);
    else {
      this.askConfirm("Vuoi davvero salvare i dati come nuova rendicontazione " + (tipo === 0 ? "bozza" : tipo === 1 ? "presentata" : "certificata") + "?",
        "Eventuali dati precedentemente salvati verranno sovrascritti definitivamente.", () => {
          this.openSnackBar("Salvataggio nuova rendicontazione in corso...", "Chiudi");
          this.rotella.openDialog();
          this.saveRendicontazioneImpl(tipo);
        });
    }
  }

  /**
   * Gestisce l'evento di salvataggio dei beni rendicontabili.
   */
  onSaveBeni(): void {
    if (this.gestoreBeni != undefined && this.gestoreBeni.first != undefined) {
      this.gestoreBeni.first.saveAllBeni(() => {
        this.gestoreBeni.first.initDataFromDatabase(() => {
          this.openSnackBar("Beni salvati con successo.", "Chiudi");
        })
      });
    }
  }

  /**
   * Gestisce l'evento di refresh costi orari mensili di una persona, in base a costi orari annuali o contratti
   * di collaborazione.
   * @param persona Wrapper di persona.
   */
  onRefreshCostiOrariMensili(persona: PersonaWrapper): void {
    this.refreshCostiOrariMensili(persona);
    this.openSnackBar("Dati di " + persona.persona.cognome + " " + persona.persona.nome + " aggiornati con successo.", "Chiudi");
  }

  /**
   * Gestisce l'evento di calcolo dell'importo SAL corrente  riferito ad un record WBS in base alle attività rendicontate e ai
   * costi orari mensili.
   * @param recordWbs Record di WBS di cui calcolare l'importo.
   */
  onComputeImportoSalCorrente(recordWbs): void {
    console.log(recordWbs);
    //calcolo
    let value = 0;
    if (this.selectedVoceDiCosto.nome === this.NOME_VDC_PERSONALE) {
      this.personaleWrappers.forEach(persona => {
        value += this.getCostoRendicontatoAttivita(persona, recordWbs);
      });
    } else if (this.selectedVoceDiCosto.nome === this.NOME_VDC_GENERALI) {
      value = this.getImportoSalCorrenteSpeseGenerali(recordWbs);
    } else {
      value = this.getImportoSalCorrente(this.selectedVoceDiCosto, recordWbs);
    }
    this.setCostoElemento({target: {value: +(value.toFixed(2))}}, recordWbs, this.selectedVoceDiCosto);
    this.openSnackBar(this.getAggiornamentoImportoSalCorrenteMessage(recordWbs), "Chiudi");
  }

  /**
   * Gestisce l'evento di rimozione di un dettaglio di rendicontazione.
   * @param record Record di WBS.
   * @param voceDiCosto Voce di costo.
   * @param dettaglio Dettaglio da rimuovere.
   */
  onRemoveDettaglio(record, voceDiCosto, dettaglio): void {
    var ref = this.getElementoWrapper(record, voceDiCosto);
    if (ref != undefined && ref.dettagli != undefined) {
      ref.dettagli.splice(ref.dettagli.indexOf(dettaglio), 1);
      ref.dettagli = ref.dettagli.concat([]);
    }
  }

  /**
   * Gestisce l'evento di aggiunta di un dettaglio di rendicontazione.
   * @param record Record di WBS.
   * @param voceDiCosto Voce di costo.
   */
  onAddDettaglio(record, voceDiCosto): void {
    var ref = this.getElementoWrapper(record, voceDiCosto)
    if (ref != undefined && ref.dettagli != undefined) {
      ref.dettagli.push({
        descrizione: undefined,
        costo: 0,
        sedeStakeholderConsulente: undefined,
        risultatiConsulenza: undefined,
        dataInizioUtilizzoBene: undefined,
        dataFineUtilizzoBene: undefined,
        percentualeUtilizzoBene: undefined,
        bene: undefined,
        elementoDiCostoRendicontato: ref.elemento
      });
    }
  }

  /**
   * Gestisce l'evento di selezione del bene nel dettaglio di rendicontazione.
   * @param record Record di WBS.
   * @param dettaglio Dettaglio di rendicontazione bene.
   */
  onSelectBeneDettaglio(record, dettaglio): void {
    if (dettaglio.bene != undefined && dettaglio.bene.ammortizzabile != undefined) {
      if (dettaglio.bene.ammortizzabile === true) {
        dettaglio.dataInizioUtilizzoBene = record.dataInizio != undefined ? new Date(record.dataInizio) : new Date();
        dettaglio.dataFineUtilizzoBene = record.dataFine != undefined ? new Date(record.dataFine) : new Date();
        dettaglio.percentualeUtilizzoBene = dettaglio.bene.percentualeUtilizzoGenerale != undefined ? dettaglio.bene.percentualeUtilizzoGenerale : 100;
      } else {
        dettaglio.dataInizioUtilizzoBene = undefined;
        dettaglio.dataFineUtilizzoBene = undefined;
        dettaglio.percentualeUtilizzoBene = undefined;
      }
    }
  }

  /**
   * Gestisce l'evento di calcolo automatico del costo totale per dettaglio riferito a bene.
   * Si applica solo se il bene è ammortizzabile.
   * @param dettaglio Dettaglio di rendicontazione.
   */
  onComputeCostoDettaglioBene(dettaglio): void {
    if (dettaglio.bene != undefined && dettaglio.bene.ammortizzabile != undefined && dettaglio.bene.ammortizzabile === true && dettaglio.bene.costoAcquisto != undefined &&
      dettaglio.bene.aliquota != undefined && dettaglio.dataInizioUtilizzoBene != undefined && dettaglio.dataFineUtilizzoBene != undefined &&
      dettaglio.percentualeUtilizzoBene != undefined) {
      const dataInizio = new Date(dettaglio.dataInizioUtilizzoBene);
      const dataFine = new Date(dettaglio.dataFineUtilizzoBene);
      const fattoreConversioneMillisecondiGiorni = 86400000;
      const numeroGiorni = Math.ceil(((dataFine.getTime() - dataInizio.getTime()) / fattoreConversioneMillisecondiGiorni));
      const giorniAnnoSolare = 360;
      const costoTotale = (dettaglio.bene.costoAcquisto) * (dettaglio.bene.aliquota / 100) * (numeroGiorni / giorniAnnoSolare) * (dettaglio.percentualeUtilizzoBene / 100);
      if (costoTotale == undefined || costoTotale < 0) this.openSnackBar("Impossibile calcolare automaticamente il costo totale.", "Chiudi");
      else {
        dettaglio.costo = +(costoTotale.toFixed(2));
        this.openSnackBar("Costo totale calcolato automaticamente.", "Chiudi");
      }
    } else {
      this.openSnackBar("Impossibile calcolare automaticamente il costo totale.", "Chiudi");
    }
  }

  /**
   * Gestisce l'evento di immissione di testo nel filtro per la combo degli stakeholder consulenti.
   * @param event Evento da gestire.
   */
  onFilterConsulenti(event): void {
    if (event.target.value != undefined) {
      this.consulentiFiltered = this.consulenti.filter(filtered => filtered.stakeholder.denominazione.trim().toUpperCase().includes(event.target.value.trim().toUpperCase()));
      if (this.consulentiFiltered == undefined || this.consulentiFiltered.length === 0) this.consulentiFiltered = this.consulenti.concat([]);
    } else this.consulentiFiltered = this.consulenti.concat([]);
  }

  /**
   * Ripristina la lista filtrata dei consulenti inizializzandola con la lista generale dei consulenti.
   */
  onRestoreConsulentiFiltered(): void {
    this.consulentiFiltered = this.consulenti.concat([]);
  }

  /**
   * Gestisce l'evento di allineamento dell'ultima versione di rendicontazione per tipo alla pianificazione costi.
   */
  onAllineaPianificazione(): void {
    this.askConfirm("Vuoi allineare la pianificazione all'ultima rendicontazione disponibile per tutti i SAL?",
      "Gli elementi di pianificazione e rendicontazione verrano allineati. Eventuali dati di pianificazione precedenti saranno sovrascritti.", () => {
        this.rotella.openDialog()

        this.allineaPianificazione(this.selectedProgetto.id, this.selectedSedeStakeholder.id, (allineamentoResult) => {
          this.rotella.closeDialog()

          if (allineamentoResult != undefined && allineamentoResult === true)
            this.openSnackBar("Pianificazione allineata con successo.", "Chiudi");
        });
    });
  }

  /**
   * Gestisce l'evento di cambiamento flag rendicontazione record WBS fuori periodo.
   * @param event Evento da gestire.
   */
  onChangeFlagRendicontazioneAttivitaFuoriPeriodo(event): void {
    event.preventDefault();
    if (this.rendicontazioneAttivitaFuoriPeriodoFlag === false) {
      this.rendicontazioneAttivitaFuoriPeriodoFlag = true;
    } else {
      this.askConfirm("Vuoi disabilitare la possibilità di rendicontare le attività fuori periodo?", "Tutti i dati di rendicontazione relativi alle attività fuori periodo saranno elimianati.", () => {
        this.deleteDatiAttivitaFuoriPeriodo();
        this.rendicontazioneAttivitaFuoriPeriodoFlag = false;
        this.openSnackBar("Dati relativi ad attività fuori periodo eliminati.", "Chiudi");
      });
    }
  }

  /**
   * Gestisce l'evento di azzeramento di una persona con eliminazione di tutti i dati di rendicontazione.
   * @param personaWrapper Wrapper della persona da azzerare.
   */
  onAzzeraPersona(personaWrapper: PersonaWrapper): void {
    this.askConfirm("Vuoi davvero eliminare tutti i dati di rendicontazione della persona?", "Le ore definite saranno eliminate e potranno essere ripristinate solo se la rendicontazione è stata precedentemente salvata.", () => {
      personaWrapper.rendicontazioni.forEach(rendicontazione => {
        rendicontazione.dettagli = [];
        rendicontazione.timesheetGiornaliero = [];
      });
      this.openSnackBar("Dati di rendicontazione di " + personaWrapper.persona.cognome + " " + personaWrapper.persona.nome + " eliminati con successo.", "Chiudi");
    });
  }

  onOpenChat(): void {
    if (this.isChatOpen) {
      this.chatDialogRef.close();
    } else {
      this.isChatOpen = true;
      this.chatDialogRef = this.promptGeneratorModalDialog.open(PromptGeneratorComponentComponent, {
        data: {
          context: "rendicontazione",
          idSal: this.selectedSal.id,
          idSedeStakeholder: this.selectedSedeStakeholder.id,
          tipoVersione: this.tipi[this.tipo]
        },
        panelClass: 'custom-chat-container',
        hasBackdrop: false,
        disableClose: false,
        position: {
          bottom: `80px`,
          right: `10px`,
        },
      });
    }

    this.chatDialogRef.afterClosed().subscribe(result => {
      this.isChatOpen = false;
    })
  }

  /**
   * Inizializza le colonne della tabella di rendicontazione personale in base alle attività in WBS.
   */
  initPersonaleColumns(): void {
    this.thirdSectionDisplayedColumns = ['numero', 'denominazione', 'attivita'];
    this.thirdSectionDisplayedColumns = this.thirdSectionDisplayedColumns.concat(["ore_rendicontate", "ore_lavorate", "ore_disponibili", "costo_rendicontato", "costo_rendicontabile", "rendiconta", "azzera"]);
  }

  /**
   * Inizializza le colonne della tabella di gestione personale da rendicontare e la lista di indicatori mese-anno
   * in base al periodo di definizione del SAL.
   */
  initColonne(): void {
    this.secondSectionDisplayedColumns = [];
    this.mesi = [];
    this.secondSectionDisplayedColumns = ["numero", "denominazione", "fittizia", "stato"];
    for (var i = this.selectedSal.dataInizio.getFullYear(); i <= this.selectedSal.dataFine.getFullYear(); i++) {
      s: switch (i) {
        case this.selectedSal.dataInizio.getFullYear():
          for (var j = this.selectedSal.dataInizio.getMonth() + 1; j <= (+this.selectedSal.dataInizio.getFullYear() == +this.selectedSal.dataFine.getFullYear() ? this.selectedSal.dataFine.getMonth() + 1 : 12); j++) {
            this.mesi.push(j + "-" + i);
            this.secondSectionDisplayedColumns.push(j + "-" + i);
          }
          break s;
        case this.selectedSal.dataFine.getFullYear():
          for (var z = 1; z <= this.selectedSal.dataFine.getMonth() + 1; z++) {
            this.mesi.push(z + "-" + i);
            this.secondSectionDisplayedColumns.push(z + "-" + i);
          }
          break s;
        default:
          for (var y = 1; y <= 12; y++){
            this.mesi.push(y + "-" + i);
            this.secondSectionDisplayedColumns.push(y + "-" + i);
          }
          break s;
      }
    }
    this.secondSectionDisplayedColumns = this.secondSectionDisplayedColumns.concat(["ricarica", "gestisci"]);
  }

  /**
   * Inizializza la lista dei colori associati alle attività in WBS, in base alla legenda definita.
   * Si rispetta l'ordine posizionale, dunque il record WBS in posizione i nella lista dei record avrà colore dato
   * da colore in posizione i nella lista colori.
   */
  initColoriAttivita(): void {
    this.coloriWbs = [];
    this.recordWbs.forEach(record => {
      const dataInizioRecord: Date = new Date(record.dataInizio);
      const dataFineRecord: Date = new Date(record.dataFine);
      this.coloriWbs.push(
        (dataFineRecord < this.selectedSal.dataInizio) ? 'red' : (dataInizioRecord > this.selectedSal.dataFine) ? 'white' :
          (dataFineRecord < this.selectedSal.dataFine) ? 'pink' : (dataFineRecord > this.selectedSal.dataFine) ? 'limegreen' : 'white'
      );
    });
  }

  /**
   * Inizializza i wrapper delle persone da rendicontare. Per ogni persona, carica contratti di collaborazione, costi orari annuali
   * e rendicontazioni.
   */
  initPersonaleWrappers(): void {
    this.personaleWrappers = [];
    this.personale.forEach(persona => {
      this.loadContratti(this.selectedProgetto.id, persona.id, this.selectedSedeStakeholder.id, (contrattiRef) => {
        this.loadCostiOrari(this.selectedProgetto.id, persona.id, this.selectedSedeStakeholder.id, (costiOrariRef) => {
          this.initPersonaWrapper(persona, contrattiRef, costiOrariRef, this.selectedSal);
        });
      });
    });
  }

  /**
   * Inizializza gli elementi di costo rendicontato, corrispondenti agli importi SAL correnti nella tabella delle
   * attività da rendicontare.
   */
  initElementi(): any {
    if (this.rendicontazione != undefined) {
      this.elementi = this.rendicontazione.elementi;
      console.log("elementi presi 1");
      console.log(this.elementi);
      this.initDettagli();
    } else {
      this.elementi = [];
      this.vociDiCosto.forEach(voceDiCosto => {
        this.recordWbs.forEach(record => {
          this.elementi.push({
            elemento: {
              costo: 0, voceDiCosto: voceDiCosto,
              recordWbsPrimoLivello: this.selectedProgetto.bando.confLivelloCostiInWbs === false ? record : undefined,
              recordWbsSecondoLivello: this.selectedProgetto.bando.confLivelloCostiInWbs === false ? undefined : record
            },
            dettagli: []
          })
        });
      });
      console.log("elementi presi 2");
      console.log(this.elementi);

      this.elementi = this.elementi.concat([]);
    }
  }

  /**
   * Inizializza i dettagli di rendicontazione provenienti da una rendicontazione importata.
   */
  initDettagli(): any {
    if (this.elementi != undefined && this.elementi.length > 0) {
      this.elementi.forEach(e => {
        e.dettagli.forEach(d => {
          if (d.bene != undefined) {
            d.dataInizioUtilizzoBene = new Date(d.dataInizioUtilizzoBene);
            d.dataFineUtilizzoBene = new Date(d.dataFineUtilizzoBene);
          } else if (d.sedeStakeholderConsulente != undefined)
            d.sedeStakeholderConsulente = this.consulenti.find(found => found.id === d.sedeStakeholderConsulente.id);
        })
      });
    }
  }

  /**
   * Inizializza le rendicontazioni precedenti dello stesso tipo di quella importata riferite ai SAL precedenti a
   * quello selezionato.
   */
  initRendicontazioniPrecedenti(): any {
    this.rendicontazioniPrecedenti = [];
    const index = this.listaSal.indexOf(this.selectedSal);
    if (index === 0) return;
    this.loadStatoSal(this.selectedSal.id, this.selectedSedeStakeholder.id, (stato) => {
      for (var i = 0; i < index; i++) {
        const salRef = this.listaSal[i];
        if (!stato) {
          stato = 'P';
        }
        console.log("initRendicontazioniPrecedenti " + salRef.id + " | " + this.selectedSedeStakeholder.id + "| " + stato);
        this.loadRendicontazione(salRef.id, this.selectedSedeStakeholder.id, stato, (rendicontazioneRef) => {
          if (rendicontazioneRef != undefined) this.rendicontazioniPrecedenti.push(rendicontazioneRef);
        });
      }
    });

  }

  /**
   * Inizializza lo stato del SAL selezionato.
   */
  initStatoSal(): void {
    this.loadStatoSal(this.selectedSal.id, this.selectedSedeStakeholder.id, (stato) => {
      if (stato == undefined) this.statoSelectedSal = undefined;
      else this.statoSelectedSal = stato === this.tipi[2] ? 'Certificato' : stato === this.tipi[1] ? 'Presentato' : 'Bozza';
      console.log(stato)
      if (stato == 'B')
        this.onImportaUltimaVersioneTipo(0)
      if (stato == 'P')
        this.onImportaUltimaVersioneTipo(1)
      if (stato == 'C')
        this.onImportaUltimaVersioneTipo(2)
    });
  }

  /**
   * Inizializza i dati tramite il wrapper di rendicontazione importato coi dati dell'ultima pianificazione.
   */
  private initFromPianificazione(): void {
    this.personaleWrappers.forEach(personaWrapper => {
      this.initCostiOrariAnnualiFromPianificazione(personaWrapper);
      this.initContrattiFromPianificazione(personaWrapper, this.selectedSal);
      this.initRendicontazioniFromPianificazione(personaWrapper);
    });
  }

  /**
   * Inizializza i costi orari annuali dal wrapper coi dati di pianificazione.
   * @param personaWrapper Wrapper della persona da rendicontare.
   */
  private initCostiOrariAnnualiFromPianificazione(personaWrapper: PersonaWrapper): void {
    this.loadCostiOrariPianificati(this.selectedProgetto.id, personaWrapper.persona.id, this.selectedSedeStakeholder.id, (costiOrariRef) => {
      personaWrapper.costiOrari = costiOrariRef != undefined ? costiOrariRef : [];
      personaWrapper.costiOrari.forEach(costoOrario => {
        costoOrario.progetto = this.selectedProgetto;
        costoOrario.persona = personaWrapper.persona;
        costoOrario.sedeStakeholder = this.selectedSedeStakeholder;
      });
      if (costiOrariRef != undefined) this.refreshCostiOrariMensili(personaWrapper);
    });
  }

  /**
   * Inizializza i contratti pianificati dal wrapper coi dati di pianificazione.
   * @param personaWrapper Wrapper della persona da rendicontare.
   * @param selectedSal SAL selezionato.
   */
  private initContrattiFromPianificazione(personaWrapper: PersonaWrapper, selectedSal): void {
    this.loadContrattiPianificati(this.selectedProgetto.id, personaWrapper.persona.id, this.selectedSedeStakeholder.id, (contrattiRef) => {
      personaWrapper.contratti = contrattiRef != undefined ? contrattiRef : [];
      this.refreshCostiOrariMensili(personaWrapper);
    });
  }

  /**
   * Inizializza i dati di rendicontazioni mensili dal wrapper coi dati di pianificazione.
   * @param personaWrapper Wrapper della persona da rendicontare.
   */
  private initRendicontazioniFromPianificazione(personaWrapper: PersonaWrapper): void {
    personaWrapper.rendicontazioni = [];
    this.mesi.forEach(meseAnno => {
      const mese = +(meseAnno.substring(0, meseAnno.indexOf('-')));
      const anno = +(meseAnno.substring(meseAnno.indexOf('-') + 1));
      var rendicontazionePersonaRef = {
        rendicontazionePersona: {
          rendicontazione: this.rendicontazione,
          persona: personaWrapper.persona,
          anno: anno,
          mese: mese,
          costoOrario: 0
        }, dettagli: [], timesheetGiornaliero: []
      };
      personaWrapper.rendicontazioni.push(rendicontazionePersonaRef);
    });
    personaWrapper.rendicontazioni.forEach(rendicontazioneWrapper => {
      this.recordWbs.forEach((record, i) => {
        this.loadMensilePianificato(this.selectedProgetto.id, personaWrapper.persona.id, this.selectedSedeStakeholder.id, rendicontazioneWrapper.rendicontazionePersona.anno, rendicontazioneWrapper.rendicontazionePersona.mese, record.id, (ref) => {
          if (ref != undefined) {
            ref.rendicontazionePersona = rendicontazioneWrapper.rendicontazionePersona;
            console.log(ref.rendicontazionePersona.persona.cognome, ref.rendicontazionePersona.mese, ref.rendicontazionePersona.anno, ref.ore);
            if (this.isDataInizioSalBetweenMonths() &&
            this.isMeseAnnoInizioSal(ref.rendicontazionePersona.mese, ref.rendicontazionePersona.anno)) {
              ref.ore = 0;
            }
            rendicontazioneWrapper.dettagli.push(ref);
          }
        });
      });
    });
  }

  /**
   * Inizializza le tabelle front-end. Inizializza in serie i colori associati ai record WBS e le colonne di seconda
   * e terza tabella.
   */
  private initTabelle(): void {
    this.initColoriAttivita();
    this.initColonne();
    this.initPersonaleColumns();
  }

  /**
   * Inizializza i dati da wrapper di rendicontazione. Inizializza in serie i wrapper del personale,
   * le rendicontazioni precedenti e gli elementi di costo rendicontato.
   */
  private initDataFromRendicontazione(): void {
    this.initPersonaleWrappers();
    this.initRendicontazioniPrecedenti();
    this.initElementi();
  }

  /**
   * Inizializza il wrapper di una persona da rendicontare.
   * @param persona Persona da rendicontare.
   * @param contrattiRef Contratti importati dal database.
   * @param costiOrariRef Costi orari importati dal database.
   * @param selectedSal SAL selezionato.
   */
  private initPersonaWrapper(persona, contrattiRef, costiOrariRef, selectedSal): void {
    const contratti = contrattiRef != undefined ? contrattiRef : [];
    const costiOrari = costiOrariRef != undefined ? costiOrariRef.filter(function (filtered) {
      return filtered.anno >= selectedSal.dataInizio.getFullYear() && filtered.anno <= selectedSal.dataFine.getFullYear();
    }) : [];
    var rendicontazioni = undefined;
    if (this.rendicontazione != undefined) {
      var rendicontazionePersonaRef = this.rendicontazione.rendicontazioniPersonale.find(found => found.persona.id === persona.id);
      if (rendicontazionePersonaRef != undefined && rendicontazionePersonaRef.rendicontazioniMensili != undefined) {
        rendicontazioni = rendicontazionePersonaRef.rendicontazioniMensili;
      }
    }
    if (rendicontazioni == undefined || rendicontazioni.length === 0) {
      rendicontazioni = [];
      this.mesi.forEach(mese => {
        rendicontazioni.push({
          rendicontazionePersona: {
            persona: persona,
            anno: +(mese.substring(mese.indexOf('-') + 1)),
            mese: +(mese.substring(0, mese.indexOf('-'))),
            costoOrario: 0
          }, dettagli: [], timesheetGiornaliero: []
        });
      });
    }
    this.loadBustePaga(persona.id, (bustePagaRef) => {
      this.personaleWrappers.push({
        persona: persona,
        contratti: contratti,
        costiOrari: costiOrari,
        bustePaga: bustePagaRef != undefined ? bustePagaRef : [],
        rendicontazioni: rendicontazioni
      });
      this.personaleWrappers = this.personaleWrappers.concat([]);
    });
  }

  /**
   * Aggiorna il personale della sede stakeholder e i dati di rendicontazione. Da utilizzare quando si aggiunge una nuova persona al personale.
   */
  updateAfterSavingPersona(persona): void {
    console.log(persona);
    this.personale.push(persona);
    this.loadContratti(this.selectedProgetto.id, persona.id, this.selectedSedeStakeholder.id, (contrattiRef) => {
      this.loadCostiOrari(this.selectedProgetto.id, persona.id, this.selectedSedeStakeholder.id, (costiOrariRef) => {
        this.initPersonaWrapper(persona, contrattiRef, costiOrariRef, this.selectedSal);
      });
    });
  }


  /**
   * Controlla che non vi siano errori nei dati immessi.
   * Restituisce UNDEFINED se non vi sono errori, altrimenti il messaggio di errore da mostare all'utente.
   * @param ref Riferimento al chiamante (this). Necessario per invocare funzioni o campi del chiamante all'interno di funzioni implicite.
   */
  private checkErrors(ref): string {
    if (this.elementi == undefined || this.elementi.length === 0) return "Impossibile salvare una rendicontazione priva di importi totali";
    var msg = undefined;
    this.elementi.forEach(elementoWrapper => {
      if (elementoWrapper.elemento.costo == undefined || isNaN(elementoWrapper.elemento.costo)) {
        msg = "Almeno un importo totale è nullo o malformato.";
        return;
      }
      if (this.getNumberOfDecimalDigits(elementoWrapper.elemento.costo) > 2) {
        msg = "Almeno un importo totale ha un numero di cifre decimali maggiore di due.";
        return;
      }
      const de = elementoWrapper.dettagli.find(function (found) {
        return (found.descrizione != undefined && found.descrizione.length > ref.DESCRIZIONE_MAX_LENGTH) || isNaN(found.costo) || found.costo < 0 || ref.getNumberOfDecimalDigits(found.costo) > 2 ||
          (found.risultatiConsulenza != undefined && (found.risultatiConsulenza.length > ref.RISULTATI_CONSULENZA_MAX_LENGTH)) ||
          (elementoWrapper.elemento.voceDiCosto.nome === ref.NOME_VDC_CONSULENZE && found.sedeStakeholderConsulente == undefined) ||
          (ref.isVoceDiCostoNonAmmortizzabile(elementoWrapper.elemento.voceDiCosto) && (found.bene == undefined || found.bene.ammortizzabile === true)) ||
          (ref.isVoceDiCostoRiferitaBeni(elementoWrapper.elemento.voceDiCosto) && (found.bene == undefined || (found.bene.ammortizzabile === true && (found.dataInizioUtilizzoBene == undefined || found.dataFineUtilizzoBene == undefined || found.percentualeUtilizzoBene == undefined))))
      });
      if (de != undefined) {
        msg = "I dati di dettaglio delle rendicontazioni sono malformati.";
        return;
      }
    });
    if (msg != undefined) return msg;
    if (this.personaleWrappers != undefined && this.personaleWrappers.length > 0) {
      this.personaleWrappers.forEach(personaWrapper => {
        msg = this.checkErrorsForRendicontazionePersonale(personaWrapper, ref);
        if (msg != undefined) return;
      });
    }
    return msg;
  }

  /**
   * Controlla che non vi siano errori nei dati di rendicontazione di una persona da rendicontare.
   * Restituisce UNDEFINED se non vi sono errori, altrimenti il messaggio di errore da mostare all'utente.
   * @param personaWrapper Wrapper di persona da rendicontare.
   * @param ref Riferimento al chiamante (this). Necessario per invocare funzioni o campi del chiamante all'interno di funzioni implicite.
   */
  private checkErrorsForRendicontazionePersonale(personaWrapper: PersonaWrapper, ref): string {
    var msg = undefined;
    if (personaWrapper.persona == undefined || personaWrapper.persona.id == undefined) {
      msg = "I riferimenti alle persone sono malformati.";
      return;
    }
    if (personaWrapper.costiOrari != undefined) {
      if (personaWrapper.costiOrari.find(function (costoFound) {
        return costoFound.costoOrario == undefined && costoFound.fasciaCostoPersonaleStandardTipologiaStakeholder == undefined;
      }) != undefined) {
        msg = "Almeno un costo orario annuale è privo di riferimento a costo o fascia standard.";
        return;
      }
      if (personaWrapper.costiOrari.find(function (costoFound) {
        return costoFound.costoOrario != undefined && (isNaN(costoFound.costoOrario) || costoFound.costoOrario < 0 || ref.getNumberOfDecimalDigits(costoFound.costoOrario) > 2);
      }) != undefined) {
        msg = "Almeno un costo orario annuale ha costo orario malformato o con numero di cifre decimali maggiore di due.";
        return;
      }
    }
    if (personaWrapper.rendicontazioni != null) {
      personaWrapper.rendicontazioni.forEach(rendicontazione => {
        if (rendicontazione.rendicontazionePersona.costoOrario == undefined || isNaN(rendicontazione.rendicontazionePersona.costoOrario) || ref.getNumberOfDecimalDigits(rendicontazione.rendicontazionePersona.costoOrario) > 2) {
          msg = "Almeno un costo orario mensile è malformato o con numero di cifre decimali maggiore di due.";
          return;
        }
        if (rendicontazione.dettagli != undefined && rendicontazione.dettagli.find(function (dettFound) {
          return dettFound.ore == undefined || isNaN(dettFound.ore) || ref.getNumberOfDecimalDigits(dettFound.ore) > 2;
        }) != undefined) {
          msg = "Almeno una pianificazione mensile è malformata.";
          return;
        }
        if (rendicontazione.timesheetGiornaliero != undefined && rendicontazione.timesheetGiornaliero.find(function (tgFound) {
          return tgFound.ore == undefined || isNaN(tgFound.ore) || ref.getNumberOfDecimalDigits(tgFound.ore) > 2;
        }) != undefined) {
          msg = "Almeno un timesheet giornaliero è malformato.";
          return;
        }
      });
    }
    return msg;
  }

  /**
   * Restituisce il numero di cifre decimali dopo la virgola.
   * @param value Valore con cifre decimali.
   */
  private getNumberOfDecimalDigits(value): number {
    return value % 1 !== 0 ? value.toString().split(".")[1].length : 0;
  }

  isMeseAnnoInizioSal(mese, anno) : boolean {
    const dataInizioSal = new Date(this.selectedSal.dataInizio);
    return dataInizioSal.getMonth() + 1 == mese && dataInizioSal.getFullYear() == anno;
  }

  isDataInizioSalBetweenMonths() : boolean {
    const dataInizioSal = new Date(this.selectedSal.dataInizio);
    dataInizioSal.setHours(0, 0, 0, 0);

    const firstSal = this.listaSal[0];
    const isFirstSal = this.selectedSal.id == firstSal.id;

    return !isFirstSal && dataInizioSal.getDay() !== 1;
  }

  isSalBetweenMonths() : any {
    const dataInizioSal = new Date(this.selectedSal.dataInizio);
    const dataFineSal = new Date(this.selectedSal.dataFine);
    
    dataInizioSal.setHours(0, 0, 0, 0);
    dataFineSal.setHours(0, 0, 0, 0);

    const dataSeguenteFineSal = new Date(dataFineSal);
    dataSeguenteFineSal.setDate(dataSeguenteFineSal.getDate() + 1);

    var monthsToReturn = "";

    if (this.isDataInizioSalBetweenMonths()) {
      monthsToReturn += dataInizioSal.toLocaleString('it-IT', { month: 'long', year: 'numeric' }) + ", ";
    }
    if (dataFineSal.getMonth() == dataSeguenteFineSal.getMonth()) {
      monthsToReturn += dataFineSal.toLocaleString('it-IT', { month: 'long', year: 'numeric' }) + ", ";
    }

    return monthsToReturn !== "" ? monthsToReturn.slice(0, -2) : undefined;
  }

  /**
   * Dispone il wrapper di rendicontazione da trasferire al database per il salvataggio, dopodiché lo trasferisce e attende risposta.
   * @param tipo Tipo di rendicontazione da salvare.
   */
  private saveRendicontazioneImpl(tipo): void {
    const rendicontazione = {
      tipoVersione: this.tipi[tipo],
      sal: this.selectedSal,
      sedeStakeholder: this.selectedSedeStakeholder,
      confRendicontazioneRecordWbsFuoriPeriodo: this.rendicontazioneAttivitaFuoriPeriodoFlag
    };
    const elementi = this.elementi.concat([]);
    var rendicontazioniPersonale = [];
    this.personaleWrappers.forEach(personaWrapper => {
      personaWrapper.rendicontazioni.forEach(rendicontazione => {
        rendicontazione.timesheetGiornaliero = rendicontazione.timesheetGiornaliero.filter(tgFiltered => tgFiltered.ore > 0);
      });
      rendicontazioniPersonale.push({
        persona: personaWrapper.persona,
        contratti: personaWrapper.contratti,
        costiOrari: personaWrapper.costiOrari,
        rendicontazioniMensili: personaWrapper.rendicontazioni
      });
    });
    this.saveRendicontazione({
      rendicontazione: rendicontazione,
      elementi: elementi,
      rendicontazioniPersonale: rendicontazioniPersonale
    }, (result) => {
      if (result != undefined && result === true) {
        this.rotella.closeDialog();

        this.openSnackBar("Nuova rendicontazione " + (tipo === 0 ? "bozza" : tipo === 1 ? "presentata" : "certificata") + " salvata con successo.", "Chiudi");
        this.initStatoSal();
        this.onAllineaPianificazione();
      }
    });
  }

  /**
   * Ricarica i costi orari mensili in base ai costi orari annuali o ai contratti di collaborazione.
   * @param persona Wrapper di persona per cui definire i costi orari mensili.
   */
  private refreshCostiOrariMensili(persona: PersonaWrapper): void {
    this.mesi.forEach(meseAnno => {
      const mese = +(meseAnno.substring(0, meseAnno.indexOf('-')));
      const anno = +(meseAnno.substring(meseAnno.indexOf('-') + 1));
      if (persona.contratti.length > 0) this.refreshCostiOrariMensiliFromContratti(persona, anno, mese);
      else this.refreshCostiOrariMensiliFromCostiOrariAnnuali(persona, anno, mese);
    });
  }

  /**
   * Ricarica i costi orari mensili in base ai contratti di collaborazione.
   * Se la persona ha contratti di collaborazione, il costo orario di un contratto che copre un intero mese viene riportato come costo orario di quel mese.
   * @param persona Wrapper di persona per cui definire i costi orari mensili.
   * @param anno Anno di riferimento.
   * @param mese Mese di riferimento.
   */
  private refreshCostiOrariMensiliFromContratti(persona: PersonaWrapper, anno, mese): void {
    var contrattiRef = [];
    persona.contratti.forEach(contrattoWrapper => {
      contrattoWrapper.contratto.dataInizio = new Date(contrattoWrapper.contratto.dataInizio);
      contrattoWrapper.contratto.dataFine = new Date(contrattoWrapper.contratto.dataFine);
      const dataInizioContratto = new Date(contrattoWrapper.contratto.dataInizio.getFullYear(), contrattoWrapper.contratto.dataInizio.getMonth(), contrattoWrapper.contratto.dataInizio.getDate());
      const dataFineContratto = new Date(contrattoWrapper.contratto.dataFine.getFullYear(), contrattoWrapper.contratto.dataFine.getMonth(), contrattoWrapper.contratto.dataFine.getDate());
      if (dataInizioContratto <= new Date(anno, mese - 1, 1) && dataFineContratto >= new Date(anno, mese, 0)) contrattiRef.push(contrattoWrapper);
    });
    if (contrattiRef != undefined && contrattiRef.length > 0) {
      const contrattoRef = contrattiRef[contrattiRef.length - 1];
      this.setCostoOrarioMensile({target: {value: contrattoRef.contratto.costoOrario}}, persona, mese + "-" + anno);
    } else this.setCostoOrarioMensile({target: {value: 0}}, persona, mese + "-" + anno);
  }

  /**
   * Ricarica i costi orari mensili in base ai costi orari annuali.
   * Se la persona è un dipendente, il costo orario annuale viene riportato come costo orario di tutti i mesi in quell'anno.
   * @param persona Wrapper di persona per cui definire i costi orari mensili.
   * @param anno Anno di riferimento.
   * @param mese Mese di riferimento.
   */
  private refreshCostiOrariMensiliFromCostiOrariAnnuali(persona: PersonaWrapper, anno, mese): void {
    const costoOrarioRef = persona.costiOrari.find(found => found.anno === anno);
    if (costoOrarioRef != undefined) {
      if (costoOrarioRef.costoOrario != undefined) this.setCostoOrarioMensile({target: {value: costoOrarioRef.costoOrario}}, persona, mese + "-" + anno);
      else this.setCostoOrarioMensile({target: {value: costoOrarioRef.fasciaCostoPersonaleStandardTipologiaStakeholder.costoOrario}}, persona, mese + "-" + anno);
    } else this.setCostoOrarioMensile({target: {value: 0}}, persona, mese + "-" + anno);
  }

  /**
   * Restituisce tutte le consulenze riferite ad un record WBS presenti nel piano costi ammissibili.
   * @param recordWbs Record WBS di riferimento.
   */
  getConsulenze(recordWbs): any {
    if (this.pianoCostiAmmissibili == undefined || this.pianoCostiAmmissibili.elementi == undefined || this.pianoCostiAmmissibili.elementi.length == 0)
      return [];
    var res = [];
    this.pianoCostiAmmissibili.elementi.forEach(elementoWrapper => {
      if (elementoWrapper.elemento != undefined && elementoWrapper.elemento.sedeStakeholder.id === this.selectedSedeStakeholder.id &&
        elementoWrapper.elemento.voceDiCosto.id === this.selectedVoceDiCosto.id && elementoWrapper.dettagli != undefined && elementoWrapper.dettagli.length > 0) {
        if ((this.selectedProgetto.bando.confLivelloCostiInWbs === false && elementoWrapper.elemento.recordWbsPrimoLivello.id === recordWbs.id) ||
          (this.selectedProgetto.bando.confLivelloCostiInWbs === true && elementoWrapper.elemento.recordWbsSecondoLivello.id === recordWbs.id)) {
          elementoWrapper.dettagli.forEach(dett => res.push(dett));
        }
      }
    });
    return res;
  }

  /**
   * Restituisce il messaggio da mostrare all'utente dopo il ricalcolo dell'importo SAL corrente per
   * un record WBS.
   * @param recordWbs Record WBS di riferimento.
   */
  private getAggiornamentoImportoSalCorrenteMessage(recordWbs): string {
    switch (this.selectedVoceDiCosto.nome) {
      case this.NOME_VDC_GENERALI:
        if (this.selectedProgetto.bando.tettoMaxSpeseGenerali == undefined)
          return "Spese generali non definite in percentuale da bando. Importo SAL corrente per " + recordWbs.nome + " azzerato.";
        else {
          var msg = "Importo SAL corrente per " + recordWbs.nome + " calcolato come " + this.selectedProgetto.bando.tettoMaxSpeseGenerali + "% di ";
          const vcbRef = this.vociDiCostoBando.filter(filtered => filtered.partecipaCalcoloSpeseGenerali === true);
          vcbRef.forEach((vcb, i) => {
            msg += vcb.voceDiCosto.nome;
            if (i != vcbRef.length - 1) msg += ", ";
          });
          return msg + ".";
        }
      default:
        return "Importo SAL corrente per " + recordWbs.nome + " calcolato con successo.";
    }
  }

  /**
   * Restituisce l'importo SAL corrente per un record WBS nel caso di spese generali.
   * @param recordWbs Record WBS considerato.
   */
  private getImportoSalCorrenteSpeseGenerali(recordWbs): number {
    var res = 0;
    if (this.selectedProgetto.bando.tettoMaxSpeseGenerali != undefined) {
      const vociBandoPartecipantiCalcolo = this.vociDiCostoBando.filter(filtered => filtered.partecipaCalcoloSpeseGenerali === true);
      this.elementi.forEach(elementoWrapper => {
        if (vociBandoPartecipantiCalcolo.find(found => found.voceDiCosto.id === elementoWrapper.elemento.voceDiCosto.id) != undefined &&
          (this.selectedProgetto.bando.confLivelloCostiInWbs === false && elementoWrapper.elemento.recordWbsPrimoLivello.id === recordWbs.id) ||
          (this.selectedProgetto.bando.confLivelloCostiInWbs === true && elementoWrapper.elemento.recordWbsSecondoLivello.id === recordWbs.id))
          res += elementoWrapper.elemento.costo * (this.selectedProgetto.bando.tettoMaxSpeseGenerali / 100);
      });
    }
    return +(res.toFixed(2));
  }

  /**
   * Restituisce l'importo totale dei SAL precedenti per la voce di costo selezionata e il record WBS considerato.
   * @param record Record WBS considerato.
   * @param voceDiCosto Voce di costo selezionata.
   */
  getImportoSalPrecedenti(record, voceDiCosto): any {
    var res = 0;
    this.rendicontazioniPrecedenti.forEach(rendicontazione => {
      var ref = undefined;
      if (this.selectedProgetto.bando.confLivelloCostiInWbs === false)
        ref = rendicontazione.elementi.find(function (found) {
          return found.elemento.voceDiCosto.id === voceDiCosto.id && found.elemento.recordWbsPrimoLivello.id === record.id;
        });
      else ref = rendicontazione.elementi.find(function (found) {
        return found.elemento.voceDiCosto.id === voceDiCosto.id && found.elemento.recordWbsSecondoLivello.id === record.id;
      });
      if (ref != undefined) res += ref.elemento.costo;
    });
    return +(res.toFixed(2));
  }

  /**
   * Restituisce il costo orario mensile di una persona.
   * @param persona Persona di riferimento.
   * @param meseAnno Indicatore mese-anno.
   */
  getCostoOrarioMensile(persona, meseAnno): any {
    if (persona.rendicontazioni.find((found) => found.rendicontazionePersona.mese + '-' + found.rendicontazionePersona.anno === meseAnno) == undefined)
      return 0;
    else
      return persona.rendicontazioni.find((found) => found.rendicontazionePersona.mese + '-' + found.rendicontazionePersona.anno === meseAnno).rendicontazionePersona.costoOrario;
  }

  /**
   * Imposta il costo orario mensile di una persona.
   * @param event Evento contenente il valore da assegnare.
   * @param persona Persona di riferimento.
   * @param meseAnno Indicatore mese-anno.
   */
  setCostoOrarioMensile(event, persona, meseAnno): any {
    if (persona.rendicontazioni.find((found) => found.rendicontazionePersona.mese + '-' + found.rendicontazionePersona.anno === meseAnno) !== undefined) {
      persona.rendicontazioni.find((found) => found.rendicontazionePersona.mese + '-' + found.rendicontazionePersona.anno === meseAnno).rendicontazionePersona.costoOrario = +(event.target.value);
    }
  }

  /**
   * Restituisce l'elemento di costo rendicontato riferito ad un record WBS e ad una voce di costo.
   * @param record Record WBS di riferimento.
   * @param voceDiCosto Voce di costo selezionata.
   */
  getCostoElemento(record, voceDiCosto): any {
    var ref = undefined;
    if (this.selectedProgetto.bando.confLivelloCostiInWbs === false)
      ref = this.elementi.find(function (found) {
        return found.elemento.voceDiCosto.id === voceDiCosto.id && found.elemento.recordWbsPrimoLivello.id === record.id;
      });
    else ref = this.elementi.find(function (found) {
      return found.elemento.voceDiCosto.id === voceDiCosto.id && found.elemento.recordWbsSecondoLivello.id === record.id;
    });
    return ref != undefined ? ref.elemento.costo : undefined;
  }

  /**
   * Imposta il valore dell'elemento di costo rendicontato riferito ad un record WBS e ad una voce di costo.
   * @param event Evento contenente il valore da impostare.
   * @param record Record WBS di riferimento.
   * @param voceDiCosto Voce di costo selezionata.
   */
  setCostoElemento(event, record, voceDiCosto): any {
    var ref = undefined;
    console.log(this.elementi)
    console.log(this.selectedProgetto.bando.confLivelloCostiInWbs);
    if (this.selectedProgetto.bando.confLivelloCostiInWbs === false)
      ref = this.elementi.find(function (found) {
        return found.elemento.voceDiCosto.id === voceDiCosto.id && found.elemento.recordWbsPrimoLivello.id === record.id;
      });

    else ref = this.elementi.find(function (found) {
      return found.elemento.voceDiCosto.id === voceDiCosto.id && found.elemento.recordWbsSecondoLivello.id === record.id;
    });
    console.log(ref)
    return ref.elemento.costo = +(event.target.value);
  }

  /**
   * Restiuisce il totale preventivato per un record WBS ed una voce di costo.
   * E' calcolato come la somma degli elementi di costo ammissibile riferiti al record WBS e alla voce di costo.
   * @param record Record WBS di riferimento.
   * @param voceDiCosto Voce di costo selezionata.
   */
  getTotalePreventivato(record, voceDiCosto): any {
    if (this.pianoCostiAmmissibili == undefined) return 0;
    var res = 0;
    this.pianoCostiAmmissibili.elementi.forEach(elementoRef => {
      if (elementoRef.elemento.recordWbsPrimoLivello || elementoRef.elemento.recordWbsSecondoLivello) {
        if (elementoRef.elemento.sedeStakeholder.id === this.selectedSedeStakeholder.id && elementoRef.elemento.voceDiCosto.id === this.selectedVoceDiCosto.id &&
          ((this.selectedProgetto.bando.confLivelloCostiInWbs === false && elementoRef.elemento.recordWbsPrimoLivello.id === record.id) ||
            (this.selectedProgetto.bando.confLivelloCostiInWbs === true && elementoRef.elemento.recordWbsSecondoLivello.id === record.id)))
          res += elementoRef.elemento.costo;
      }
    });
    return +(res.toFixed(2));
  }

  /**
   * Restituisce l'importo SAL corrente calcolato come somma dei costi rendicontati nel dettaglio.
   * @param voceDiCosto Voce di costo.
   * @param record Recor di WBS.
   */
  private getImportoSalCorrente(voceDiCosto, record): number {
    var res = 0;
    var ref = undefined;
    if (this.selectedProgetto.bando.confLivelloCostiInWbs === false)
      ref = this.elementi.find(function (found) {
        return found.elemento.voceDiCosto.id === voceDiCosto.id && found.elemento.recordWbsPrimoLivello.id === record.id;
      });
    else ref = this.elementi.find(function (found) {
      return found.elemento.voceDiCosto.id === voceDiCosto.id && found.elemento.recordWbsSecondoLivello.id === record.id;
    });
    if (ref != undefined && ref.dettagli != undefined && ref.dettagli.length > 0) {
      ref.dettagli.forEach(dett => res += dett.costo);
    }
    return +(res.toFixed(2));
  }

  /**
   * Resituisce il totale delle ore rendicontate per una attività in WBS
   * @param persona Wrapper della persona rendicontata.
   * @param idRecord Id del record WBS di riferimento.
   */
  getOreRendicontateAttivita(persona: PersonaWrapper, idRecord): number {
    var res = 0;
    persona.rendicontazioni.forEach(rendicontazioneRef => {
      rendicontazioneRef.dettagli.forEach(dettaglio => {
        if ((dettaglio.recordWbsPrimoLivello != undefined && dettaglio.recordWbsPrimoLivello.id === idRecord) || (dettaglio.recordWbsSecondoLivello != undefined && dettaglio.recordWbsSecondoLivello.id === idRecord))
          res += dettaglio.ore;
      });
    });
    return +(res.toFixed(2));
  }

  /**
   * Restituisce il totale delle ore rendicontate di una persona.
   * E' calcolato come la somma delle ore definite nelle singole attività in WBS.
   * @param persona Wrapper della persona rendicontata.
   */
  getOreRendicontate(persona: PersonaWrapper): number {
    var res = 0;
    this.recordWbs.forEach(record => res += this.getOreRendicontateAttivita(persona, record.id));
    return +(res.toFixed(2));
  }

  /**
   * Restituisce il totale delle ore lavorate di una persona.
   * E' calcolato come la somma delle ore definite in busta paga.
   * @param persona Wrapper della persona rendicontata.
   */
  getOreLavorate(persona: PersonaWrapper): number {
    var res = 0;
    persona.bustePaga.forEach(bustaPaga => {
      if (this.mesi.indexOf(bustaPaga.mese + "-" + bustaPaga.anno) >= 0) res += bustaPaga.oreTotali;
    });
    return +(res.toFixed(2));
  }

  /**
   * Restituisce il costo rendicontato di una persona.
   * E' calcolato come la somma dei prodotti (ore definite in un mese) * (costo orario mensile).
   * @param persona Wrapper della persona rendicontata.
   */
  getCostoRendicontato(persona: PersonaWrapper): number {
    var res = 0;
    persona.rendicontazioni.forEach(rendicontazione => {
      const costoOrarioMensile = this.getCostoOrarioMensile(persona, rendicontazione.rendicontazionePersona.mese + "-" + rendicontazione.rendicontazionePersona.anno);
      rendicontazione.dettagli.forEach(dettaglio => {
        res += dettaglio.ore * costoOrarioMensile
      });
    });
    return +(res.toFixed(2));
  }

  /**
   * Restituisce il costo rendicontato di una persona in una attività in WBS.
   * E' calcolato come la somma dei prodotti (ore definite per il record WBS in un mese) * (costo orario mensile).
   * @param persona Wrapper della persona rendicontata.
   * @param recordWbs Record WBS di riferimento.
   */
  getCostoRendicontatoAttivita(persona: PersonaWrapper, recordWbs): number {
    var res = 0;
    persona.rendicontazioni.forEach(rendicontazione => {
      const costoOrarioMensile = this.getCostoOrarioMensile(persona, rendicontazione.rendicontazionePersona.mese + "-" + rendicontazione.rendicontazionePersona.anno);
      rendicontazione.dettagli.forEach(dettaglio => {
        if ((dettaglio.recordWbsPrimoLivello != undefined && dettaglio.recordWbsPrimoLivello.id === recordWbs.id) || (dettaglio.recordWbsSecondoLivello != undefined && dettaglio.recordWbsSecondoLivello.id === recordWbs.id))
          res += dettaglio.ore * costoOrarioMensile
      });
    });
    return +(res.toFixed(2));
  }

  /**
   * Restituisce il costo lavorato di una persona.
   * E' calcolato come la somma dei prodotti (ore in busta paga in un mese) * (costo orario mensile).
   * @param persona Wrapper della persona rendicontata.
   */
  getCostoBustePaga(persona: PersonaWrapper): number {
    var res = 0;
    persona.bustePaga.forEach(bustaPaga => {
      if (this.mesi.indexOf(bustaPaga.mese + "-" + bustaPaga.anno) >= 0) {
        const costoOrarioMensile = this.getCostoOrarioMensile(persona, bustaPaga.mese + "-" + bustaPaga.anno);
        res += bustaPaga.oreTotali * costoOrarioMensile;
      }
    });
    return +(res.toFixed(2));
  }

  /**
   * Restituisce il JSON in formato stringa da trasferire al component di gestione dei beni da rendicontare.
   */
  getGestioneBeniData(): string {
    return JSON.stringify({progetto: this.selectedProgetto, sedeStakeholder: this.selectedSedeStakeholder});
  }

  /**
   * Restituisce la lista dei beni provenienti dal component di gestione beni.
   */
  getBeni(): any[] {
    return this.gestoreBeni.first != undefined && this.gestoreBeni.first.beni != undefined ?
      this.gestoreBeni.first.beni.filter(filtered => filtered.id != undefined) : [];
  }

  /**
   * Restituisce il wrapper di elemento di costo rendicontato.
   * @param record Record di WBS.
   * @param voceDiCosto Voce di costo.
   */
  getElementoWrapper(record, voceDiCosto): any {
    if (this.selectedProgetto.bando.confLivelloCostiInWbs === false)
      return this.elementi.find(function (found) {
        return found.elemento.voceDiCosto.id === voceDiCosto.id && found.elemento.recordWbsPrimoLivello.id === record.id;
      });
    else return this.elementi.find(function (found) {
      return found.elemento.voceDiCosto.id === voceDiCosto.id && found.elemento.recordWbsSecondoLivello.id === record.id;
    });
  }

  /**
   * Restituisce i dettagli di un wrapper di elemento di costo rendicontato.
   * @param record Record di WBS.
   * @param voceDiCosto Voce di costo.
   */
  getDettagli(record, voceDiCosto): any[] {
    var ref = this.getElementoWrapper(record, voceDiCosto);
    if (ref != undefined) return ref.dettagli;
  }

  /**
   * Restituisce i beni preventivati nel piano dei costi ammissibili.
   * @param record Record di WBS.
   */
  getBeniPreventivati(record): any[] {
    if (this.pianoCostiAmmissibili == undefined) return [];
    var res = [];
    this.pianoCostiAmmissibili.elementi.forEach(elementoRef => {
      if (elementoRef.elemento.sedeStakeholder.id === this.selectedSedeStakeholder.id &&
        this.isVoceDiCostoRiferitaBeni(elementoRef.elemento.voceDiCosto) && elementoRef.elemento.voceDiCosto.nome === this.selectedVoceDiCosto.nome &&
        ((this.selectedProgetto.bando.confLivelloCostiInWbs === false && elementoRef.elemento.recordWbsPrimoLivello.id === record.id) ||
          (this.selectedProgetto.bando.confLivelloCostiInWbs === true && elementoRef.elemento.recordWbsSecondoLivello.id === record.id))) {
        elementoRef.dettagli.forEach(dett => {
          dett.bene.dataInizioAmmortamento = new Date(dett.bene.dataInizioAmmortamento);
          dett.dataInizioUtilizzoBene = new Date(dett.dataInizioUtilizzoBene);
          dett.dataFineUtilizzoBene = new Date(dett.dataFineUtilizzoBene);
          res.push(dett);
        });
      }
    });
    return res;
  }

  /**
   * Restituisce le consulenze preventivate nel piano dei costi ammissibili.
   * @param record Record di WBS.
   * @param voceDiCosto Voce di costo.
   */
  getConsulenzePreventivate(record, voceDiCosto): any[] {
    if (this.pianoCostiAmmissibili == undefined) return [];
    var res = [];
    this.pianoCostiAmmissibili.elementi.forEach(elementoRef => {
      if (elementoRef.elemento.sedeStakeholder.id === this.selectedSedeStakeholder.id &&
        this.isVoceDiCostoRiferitaConsulenze(voceDiCosto) &&
        elementoRef.elemento.voceDiCosto.nome === voceDiCosto.nome &&
        ((this.selectedProgetto.bando.confLivelloCostiInWbs === false && elementoRef.elemento.recordWbsPrimoLivello.id === record.id) ||
          (this.selectedProgetto.bando.confLivelloCostiInWbs === true && elementoRef.elemento.recordWbsSecondoLivello.id === record.id))) {
        res = res.concat(elementoRef.dettagli);
      }
    });
    return res;
  }

  /**
   * Restituisce un messaggio informativo da mostrare all'utente che spiega come rendicontare la voce di costo.
   * @param voceDiCosto Voce di costo.
   */
  getInfoRendicontazioneVoceDiCosto(voceDiCosto): string {
    switch (voceDiCosto.nome) {
      case this.NOME_VDC_PERSONALE:
        return "Definire i costi orari del personale mensilmente oppure tramite costi annuali o contratti di collaborazione tramite il dettaglio. Rendicontare il personale mensilmente e/o tramite i timesheet giornalieri.";
      case this.NOME_VDC_GENERALI:
        return "Rendicontare le spese generali per WP/OR o attività." + (this.selectedProgetto.bando.tettoMaxSpeseGenerali != undefined ? " Calcolare in automatico i valori in percentuale rispetto alle altre voci di costo." : "");
      default:
        if (this.isVoceDiCostoRiferitaBeni(voceDiCosto))
          return "Rendicontare " + voceDiCosto.nome.toLowerCase() + " aggiungendo elementi per WP/OR o attività. I beni rendicontabili sono indicati in tabella. " +
            "Per ogni elemento, definire il bene di riferimento e il valore di costo. Se il bene è ammortizzabile, definire i valori di ammortamento." +
            (this.isVoceDiCostoNonAmmortizzabile(voceDiCosto) ? " Utilizzare solo beni non ammortizzabili." : "");
        else if (this.isVoceDiCostoRiferitaConsulenze(voceDiCosto))
          return "Rendicontare le consulenze aggiungendo elementi per WP/OR o attività. Per ogni consulenza, definire lo stakeholder consulente e il valore di costo.";
        else return "Rendicontare " + voceDiCosto.nome.toLowerCase() + " aggiungendo elementi per WP/OR o attività. Per ogni elemento, definire il valore di costo.";
    }
  }

  /**
   * Restituisce solo i wrapper di personale che sono stati rendicontati, ovvero che hanno ore totali rendicontate maggiori di 0.
   */
  getPersonaleRendicontato(): any[] {
    if (this.personaleWrappers == undefined || this.personaleWrappers.length === 0) {
      return [];
    } else {
      return this.personaleWrappers.filter(filtered => this.isPersonaRendicontata(filtered) === true);
    }
  }

  /**
   * Restituisce i wrapper di personale da visualizzare in accordo col flag di visualizzazione.
   */
  getPersonaleFiltered(): any[] {
    var data =  this.visualizzazionePersoneRendicontateFlag === false ? this.personaleWrappers : this.getPersonaleRendicontato();
    data = data.sort((a, b) => (a.persona.cognome +" "+a.persona.nome).localeCompare((b.persona.cognome +" "+b.persona.nome), undefined, {sensitivity: 'base'}))
    // La tabella opererà sulla struttura dati fornita dal metodo. Se fornisco direttamente il risultato di
    // data.sort, eventuali modifiche dirette sui dati non verranno riflesse sulla struttura dati vera e propria
    // ma sulla copia fornita da data.sort quindi meglio ordinare la struttura dati e poi fornirla alla tabella
    this.personaleWrappers = data;
    return this.personaleWrappers;
  }

  /**
   * Elimina tutti i dati di rendicontazione legati ai record fuori periodo.
   */
  private deleteDatiAttivitaFuoriPeriodo(): void {
    const recordFuoriPeriodo = this.recordWbs.filter(filtered => this.isRecordWbsFuoriPeriodo(filtered) === true);
    if (recordFuoriPeriodo != undefined && recordFuoriPeriodo.length > 0) {
      recordFuoriPeriodo.forEach(record => {
        this.deleteElementiWrapperAttivitaFuoriPeriodo(record);
        this.deleteRendicontazioniPersonaleAttivitaFuoriPeriodo(record);
      });
    }
  }

  /**
   * Elimina tutti gli elementi e i dettagli di rendicontazione legati ai record fuori periodo.
   */
  private deleteElementiWrapperAttivitaFuoriPeriodo(record): void {
    this.vociDiCosto.forEach(voceDiCosto => {
      var elementoWrapper = this.getElementoWrapper(record, voceDiCosto);
      if (elementoWrapper.elemento != undefined && elementoWrapper.elemento != undefined && elementoWrapper.dettagli != undefined) {
        if (elementoWrapper.elemento.costo != undefined) elementoWrapper.elemento.costo = 0;
        elementoWrapper.dettagli = [];
      }
    });
  }

  /**
   * Elimina tutti i dati di personale rendicontato legati ai record fuori periodo.
   */
  private deleteRendicontazioniPersonaleAttivitaFuoriPeriodo(record): void {
    this.personaleWrappers.forEach(personaWrapper => {
      if (personaWrapper.rendicontazioni != undefined && personaWrapper.rendicontazioni.length > 0) {
        personaWrapper.rendicontazioni.forEach(rendicontazioneWrapper => {
          this.deleteRendicontazioniPersonaAttivitaFuoriPeriodo(rendicontazioneWrapper, record);
        });
      }
    });
  }

  /**
   * Elimina tutti i dati di persona rendicontata legati ai record fuori periodo.
   */
  private deleteRendicontazioniPersonaAttivitaFuoriPeriodo(rendicontazioneWrapper, record): void {
    if (rendicontazioneWrapper.dettagli != undefined && rendicontazioneWrapper.dettagli.length > 0) {
      var dettagliTemp = [];
      rendicontazioneWrapper.dettagli.forEach(dettaglio => {
        const recordDettaglio = dettaglio.recordWbsPrimoLivello != undefined ? dettaglio.recordWbsPrimoLivello : dettaglio.recordWbsSecondoLivello;
        if (recordDettaglio != undefined && recordDettaglio.id != record.id)
          dettagliTemp.push(dettaglio);
      });
      rendicontazioneWrapper.dettagli = dettagliTemp.concat([]);
    }
    if (rendicontazioneWrapper.timesheetGiornaliero != undefined && rendicontazioneWrapper.timesheetGiornaliero.length > 0) {
      var timesheetTemp = [];
      rendicontazioneWrapper.timesheetGiornaliero.forEach(dettaglioGiornaliero => {
        const recordDettaglioGiornaliero = dettaglioGiornaliero.recordWbsPrimoLivello != undefined ? dettaglioGiornaliero.recordWbsPrimoLivello : dettaglioGiornaliero.recordWbsSecondoLivello;
        if (recordDettaglioGiornaliero != undefined && recordDettaglioGiornaliero.id != record.id)
          timesheetTemp.push(dettaglioGiornaliero);
      });
      rendicontazioneWrapper.timesheetGiornaliero = timesheetTemp.concat([]);
    }
  }

  /**
   * Resituisce TRUE se il record WBS è fuori periodo rispetto al SAL, FALSE altrimenti.
   * @param record
   */
  isRecordWbsFuoriPeriodo(record): boolean {
    const index = this.recordWbs.indexOf(record);
    if (index < 0 || index >= this.coloriWbs.length) return false;
    else return (this.coloriWbs[index] === 'white' || this.coloriWbs[index] === 'red');
  }

  /**
   * Restituisce TRUE se la voce di costo è rendicontabile attraverso beni, FALSE altrimenti.
   * @param voceDiCosto Voce di costo.
   */
  isVoceDiCostoRiferitaBeni(voceDiCosto): boolean {
    return this.NOMI_VDC_BENI.find(found => found === voceDiCosto.nome) != undefined;
  }

  /**
   * Restituisce TRUE se la voce di costo è rendicontabile attraverso consulenze, FALSE altrimenti.
   * @param voceDiCosto Voce di costo.
   */
  isVoceDiCostoRiferitaConsulenze(voceDiCosto): boolean {
    return this.NOMI_VDC_CONSULENZE.find(found => found === voceDiCosto.nome) != undefined;
  }

  /**
   * Restituisce TRUE se la voce di costo è rendicontabile attraverso beni e non è ammortizzabile.
   * @param voceDiCosto Voce di costo.
   */
  isVoceDiCostoNonAmmortizzabile(voceDiCosto): boolean {
    return this.isVoceDiCostoRiferitaBeni(voceDiCosto) &&
      this.NOMI_VDC_BENI_NON_AMMORTIZZABILI.find(found => found === voceDiCosto.nome) != undefined;
  }

  /**
   * Restituisce TRUE se esiste almeno una persona fittizia nel personale, FALSE altrimenti.
   */
  isPersonaleFittizio(): boolean {
    if (this.personale == undefined || this.personale.length === 0) {
      return false;
    } else {
      return this.personale.find(found => found.fittizia === true) != undefined;
    }
  }

  /**
   * Restituisce TRUE se non è stata rendicontata alcuna persona, FALSE altrimenti.
   */
  isPersonaleNonRendicontato(): boolean {
    var personaleRendicontato = this.getPersonaleRendicontato();
    return personaleRendicontato == undefined || personaleRendicontato.length === 0;
  }

  /**
   * Restituisce TRUE se la persona del wrapper è stata rendicontata, ovvero ha ore totali rendicontate maggiori di 0,
   * FALSE altrimenti.
   * @param personaWrapper Wrapper della persona.
   */
  isPersonaRendicontata(personaWrapper: PersonaWrapper): boolean {
    var oreRendicontate = this.getOreRendicontate(personaWrapper);
    return oreRendicontate != undefined && oreRendicontate > 0;
  }

  /**
   * Mostra un messaggio di richiesta di conferma all'utente.
   * @param message Messaggio di richiesta di conferma.
   * @param submessage Messaggio inferiore.
   * @param _callback Azione successiva, in caso di conferma della richiesta.
   */
  askConfirm(message: string, submessage: string, _callback): void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent, {
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res) _callback();
      }
    );
  }

  /**
   * Mostra una notifica all'utente.
   * @param message Messaggio da mostrare.
   * @param action Azione da permettere all'utente.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String): void {
    this.dialog.open(WarningMessageComponent, {
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      this.loadOrganizzazione(sessionStorage.getItem("Organization"), (org) => {
        this.organizzazione = org;
        if (this.organizzazione == undefined) this.showError("Errore nell'inizializzazione. Riprovare.");
        else {
          this.loadProgettiRendicontabili(this.organizzazione.id, (progettiRef) => {
            //this.progetti = progettiRef.sort((a,b)=> a.nome.toUpperCase() > b.nome.toUpperCase());
            this.progetti = progettiRef.sort((a, b) => a.nome.localeCompare(b.nome, undefined, {sensitivity: 'base'}))//.sort((a,b)=> a.nome.toUpperCase() > b.nome.toUpperCase());

            //console.log("dati", this.progetti)
            //this.progetti.sort()
          });
        }
      });
    });
  }

}
