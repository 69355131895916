import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { GenerazioneReportDialogService } from './generazione-report-dialog.service';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { DialogData } from '../gestione-wbs/modals/aggiunta-record-wbs-dialog/aggiunta-record-wbs-dialog.component';

@Component({
  selector: 'app-generazione-report-dialog',
  templateUrl: './generazione-report-dialog.component.html',
  styleUrls: ['./generazione-report-dialog.component.css']
})
export class GenerazioneReportDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private generazioneReportDialogService: GenerazioneReportDialogService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  showError(message) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: message
      },
      panelClass: 'custom-warning-container'
    });
  }

  onGenerateXlsxReport() : void {
    this.generazioneReportDialogService.generateXlsxReport(this.data).subscribe(
      data => {
        this.generazioneReportDialogService.downloadFile(data, "xlsx");
      },
      err => { this.showError(err.error.message); }
    );
  }

  onGeneratePdfReport() : void {
    this.generazioneReportDialogService.generatePdfReport(this.data).subscribe(
      data => {
        this.generazioneReportDialogService.downloadFile(data, "pdf");
      },
      err => { this.showError(err.error.message); }
    );
  }

  onGeneratePngReport() : void {
    this.generazioneReportDialogService.generatePngReport(this.data).subscribe(
      data => {
        this.generazioneReportDialogService.downloadFile(data, "png");
      },
      err => { this.showError(err.error.message); }
    );
  }

  onGenerateJsonReport() : void {
    this.generazioneReportDialogService.generateJsonReport(this.data);
  }

}
