import {Component, OnInit, Injectable, Inject, LOCALE_ID, ViewChild} from '@angular/core';
import {WarningMessageComponent} from '../modals/warning-message/warning-message.component';
import {
  MatSnackBar,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DEFAULT_OPTIONS,
  DateAdapter,
  MatTableDataSource,
  MatSort
} from '@angular/material';
import {ConfirmMessageComponent} from '../modals/confirm-message/confirm-message.component';
import {BehaviorSubject, observable, Observable} from 'rxjs';
import {UserStorage} from '../core_modules/user.storage';
import {OrganizzazioniService} from '../organizzazioni/organizzazioni.service';
import {MatPaginator} from '@angular/material/paginator';
import {MonitoraggioDeliverablesProgettoComponent} from '../monitoraggio-deliverables-progetto/monitoraggio-deliverables-progetto.component';
import {GestioneWbsService} from '../gestione-wbs/gestione-wbs.service';
import {GestionePianiDeiCostiService} from '../gestione-piani-dei-costi/gestione-piani-dei-costi.service';
import {GestioneReferentiProgettoService} from '../gestione-referenti-progetto/gestione-referenti-progetto.service';
import {GestionePartnerProgettoService} from '../gestione-partner-progetto/gestione-partner-progetto.service';
import {GestioneSalService} from '../gestione-sal/gestione-sal.service';
import {AuthService} from '../core_modules/auth.service';
import {ReportcostiComponent} from "../reportcosti/reportcosti.component";
import {MonitoraggioProgettoPartnerService} from "./monitoraggio-progetto.service";
import {AdminOrganizzazioneDashboardService} from "../admin-organizzazione-dashboard/admin-organizzazione-dashboard.service";
import {ReportPartnerComponent} from "./report-partner/report-partner.component";
import {AppSettings} from "../app.settings";
import {RotellinaService} from "../rotellina-dialog/rotellina.service";
import {UtilsService} from "../common-services/utils.service";

export class ProgettiElement {
  id: String;
  nomeProgetto: string;
  position: number;
  weight: number;
  symbol: string;
}

@Injectable()
export class TableMonitoraggioProgettiDatabase {

  dataChange = new BehaviorSubject<ProgettiElement[]>([]);

  get data(): ProgettiElement[] {
    return this.dataChange.value;
  }

  // Classe di servizio del component owner che implementa i vari servizi verso il back-end invocati dal TableProjectDatabase
  // come 'saveAttivitasInPraticas', 'deleteAttivitaFromPratica', 'getPraticheTreeByOrganization', etc.
  private ownerComponentService: any;
  // La dialog dell'owner component
  private ownerComponentDialog: MatDialog;
  // ########## END   ELEMENTI DEL COMPONENT OWNER ##########

  //L'oggetto JSON, estratto con apposito querying del database, che contiene i progetti.
  jsonProjectTableStructure: any;

  projectTableNodesArray: ProgettiElement[];

  public setOwnerComponentService(x: any) {
    this.ownerComponentService = x;
  }

  public setOwnerComponentDialog(y: MatDialog) {
    this.ownerComponentDialog = y;
  }

  constructor(private adapter: DateAdapter<any>,
              @Inject(LOCALE_ID) private locale: string) {
    this.adapter.setLocale("it");
  }

  bandiProgetto = [];

  getBandiProgetto() {
    return this.bandiProgetto;
  }

  reloadProjectGestoriDatabaseStructure(idorganizzazione, _callback) {
    this.ownerComponentService.getProgettiFromRendicontaDB(idorganizzazione).subscribe(
      dataDb => {
        this.jsonProjectTableStructure = dataDb;
        console.log(JSON.stringify(dataDb));

        for (var x = 0; x < dataDb.length; x++) {
          this.bandiProgetto.push({
              idProgetto: dataDb[x].id,
              bando: dataDb[x].bando
            }
          );
        }

        this.projectTableNodesArray = this.buildProjectNodes(this.jsonProjectTableStructure);

        const data = this.projectTableNodesArray;
        this.dataChange.next(data);

        _callback();
      },
      err => {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare i Progetti!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    )
  }


  // Build the nodes from Json object. The result is a list of `TableProjectNode`
  buildProjectNodes(projectTableNodeData: any[]): ProgettiElement[] {
    let nodeArray = new Array<ProgettiElement>();
    if (projectTableNodeData != null) {
      try {
        projectTableNodeData.forEach(project => {
          const node = new ProgettiElement();

          node.nomeProgetto = project.nome;
          node.id = project.id;

          nodeArray.push(node);
        });
      } catch (error) {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare informazioni della tabella!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    }
    return nodeArray;
  }
}

export class TableElementFlags {
  tableElement: ProgettiElement;
  isPartnerPresent: Boolean = false;
  isReferentiPresent: Boolean = false;
  isWbsPresent: Boolean = false;
  isPianoCostiAmmissibiliPresent: Boolean = false;
  isPianoSalPresent: Boolean = false;
}


@Component({
  selector: 'app-partner-component',
  templateUrl: './partner-component.component.html',
  styleUrls: ['./partner-component.component.css'],
  providers: [TableMonitoraggioProgettiDatabase, {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}]
})
export class PartnerComponentComponent implements OnInit {

  displayedColumns: string[] = ['name', 'deliverables', 'docs', 'equipment'];
  projectTableDataSource: MatTableDataSource<ProgettiElement>;
  partnerId;

  @ViewChild(MatPaginator) paginator: MatPaginator;// Numero elementi da visualizzare

  applyFilter(filterValue: string) {
    this.projectTableDataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    private monitoraggioProgettoService: MonitoraggioProgettoPartnerService,
    private organizzazioniService: OrganizzazioniService,
    private adminOrganizzazioneDashboardService: AdminOrganizzazioneDashboardService,
    public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private userStorage: UserStorage,
    private authService: AuthService,
    private adapter: DateAdapter<any>,
    private projectTableContentDatabase: TableMonitoraggioProgettiDatabase,
    private monitoraggioDeliverablesProgettoDialog: MatDialog,
    private monitoraggioCostiDialog: MatDialog,
    private gestioneWbsService: GestioneWbsService,
    private gestionePianiDeiCostiService: GestionePianiDeiCostiService,
    private gestionePartnerService: GestionePartnerProgettoService,
    private gestioneReferentiService: GestioneReferentiProgettoService,
    private gestioneSalService: GestioneSalService,
    private rotella: RotellinaService,
    public utilsService: UtilsService
  ) {
    this.adapter.setLocale("it");
    this.projectTableDataSource = new MatTableDataSource<ProgettiElement>();
    projectTableContentDatabase.dataChange.subscribe(data => this.projectTableDataSource.data = data);
  }

  color = undefined;
  data = undefined;
  caller = undefined;

  gestione_progetto_section_title = "Gestione Progetto";
  organizzazioneObj = undefined;

  flags: TableElementFlags[] = [];

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      // this.color=colors[Math.floor(Math.random() * colors.length)];
      this.projectTableDataSource.paginator = this.paginator;
      this.loadOrganizzazioneAndOtherData();
      this.loadOrganizzazione();
      this.loadPartner();
    });

  }

  loadPartner() {
    this.monitoraggioProgettoService.getCurrentStackHolder().subscribe(
      data => {
        this.partnerId = data;
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  loadOrganizzazione() {
    this.adminOrganizzazioneDashboardService.getOrganizzazioneUtente(this.userStorage.getEmail()).subscribe(
      data => {
        let organizzazione = data;
        this.userStorage.saveOrganizzazione(organizzazione.nome);
        console.log('Logged Organization');
        console.log(organizzazione);
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...");
        console.log(err);
        console.log("*************************************************");
      }
    );
  }

  onMonitoraDeliverables(element): void {
    sessionStorage.setItem("progetto", JSON.stringify(element));
    const monitoraggioDeliverablesProgettoDialogRef = this.monitoraggioDeliverablesProgettoDialog.open(MonitoraggioDeliverablesProgettoComponent);
  }

  onMonitoraCostiPerSal(progetto): void {

/*    var href = "";
    href = AppSettings.BASE_URL + "/reportcostisal/reportcostisalpartener/" + progetto + "/" + this.partnerId;
    var link = document.createElement("a");
    link.href = href;
    link.click();

    this.openSnackBar("attendere","open");*/


    console.time()
    this.rotella.openDialog();
    const downloadURL_server = AppSettings.BASE_URL + "/reportcostisal/reportcostisalpartener/" + progetto + "/" + this.partnerId;
    fetch(downloadURL_server)
      .then(response => {
        return response.blob()})
      .then((myBlob)=> {

        let link = document.createElement("a");
        link.href = URL.createObjectURL(myBlob);
        link.setAttribute('download', "reportsal.xlsx");
        this.rotella.closeDialog();

        link.click();
        console.timeEnd()

      })


  }

  on(element): void {
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  getPianoCostiPresentFlag(idProgetto, _callback): void {
    this.gestionePianiDeiCostiService.getUltimaVersioneWbsMasterFromProgettoId(idProgetto).subscribe(
      data => {
        if (data) {
          this.gestionePianiDeiCostiService.getUltimaVersionePianoByProgettoIdAndWbsId(idProgetto, data.id).subscribe(
            data2 => {
              if (data2) {
                _callback(true);
              } else {
                _callback(false);
              }
            },
            err => {
              this.dialog.open(WarningMessageComponent, {
                data: {
                  message: err.error.message
                },
                panelClass: 'custom-warning-container'
              });
              _callback(false);
            }
          );
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getWbsPresentFlag(idProgetto, _callback): void {
    this.gestioneWbsService.getUltimaVersioneWbsMasterFromProgettoId(idProgetto).subscribe(
      data => {
        if (data) {
          _callback(true);
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getPartnerPresentFlag(idProgetto, _callback): void {
    this.gestionePartnerService.getStakeholdersByProgettoIdAndOrganizzazioneId(idProgetto, this.organizzazioneObj.id).subscribe(
      data => {
        if (data && data.length > 0) {
          _callback(true);
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getReferentiPresentFlag(idProgetto, _callback): void {
    this.gestioneReferentiService.getAllPersoneByProgettoId(idProgetto).subscribe(
      data => {
        if (data && data.length > 0) {
          _callback(true);
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getPianoSalPresentFlag(idProgetto, _callback): void {
    _callback(false);
  }

  getFlags(idProgetto): TableElementFlags {
    for (var i in this.flags) {
      if (this.flags[i].tableElement.id == idProgetto) {
        return this.flags[i];
      }
    }
    return undefined;
  }

  initFlagsForElement(id): void {
    var index = -1;
    for (var i = 0; i < this.flags.length; i++) {
      if (this.flags[i].tableElement.id == id) {
        index = i;
        break;
      }
    }
    if (index >= 0) {
      this.getPartnerPresentFlag(this.flags[index].tableElement.id, (partnerFlag) => {
        this.getReferentiPresentFlag(this.flags[index].tableElement.id, (referentiFlag) => {
          this.getWbsPresentFlag(this.flags[index].tableElement.id, (wbsFlag) => {
            this.getPianoCostiPresentFlag(this.flags[index].tableElement.id, (pianoCostiFlag) => {
              this.getPianoSalPresentFlag(this.flags[index].tableElement.id, (pianoSalFlag) => {
                this.flags[index].isPartnerPresent = partnerFlag;
                this.flags[index].isReferentiPresent = referentiFlag;
                this.flags[index].isWbsPresent = wbsFlag;
                this.flags[index].isPianoCostiAmmissibiliPresent = pianoCostiFlag;
                this.flags[index].isPianoSalPresent = pianoSalFlag;
              });
            });
          });
        });
      });
    }
  }

  initFlags(): void {
    this.flags = [];
    for (var i in this.projectTableDataSource.data) {
      var element: TableElementFlags = new TableElementFlags();
      element.tableElement = this.projectTableDataSource.data[i];
      this.flags.push(element);
    }
    console.log(this.flags);
    for (var j in this.projectTableDataSource.data) {
      this.initFlagsForElement(this.projectTableDataSource.data[j].id);
    }
  }

  loadOrganizzazioneAndOtherData() {
    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        this.organizzazioneObj = data;
        //  console.log('obj: ' + this.organizzazioneObj.id);

        // alert("oo" + JSON.stringify(data));
        this.projectTableContentDatabase.setOwnerComponentService(this.monitoraggioProgettoService);
        this.projectTableContentDatabase.setOwnerComponentDialog(this.dialog);

        this.projectTableContentDatabase.reloadProjectGestoriDatabaseStructure(1, () => {
          this.initFlags();
        });
        //this.loadPartnersTipoOrganizzazione(this.organizzazioneObj.id);


      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }


  onReportCostiAmmessi(element, fileName): void {
    this.rotella.openDialog();
    const downloadURL_server = AppSettings.BASE_URL + "/reportcostiammessi/progetto/" + element.id;
    fetch(downloadURL_server)
      .then(response => {
        return response.blob()})
      .then((myBlob)=> {
        let link = document.createElement("a");
        link.href = URL.createObjectURL(myBlob);
        link.setAttribute('download',fileName);
        this.rotella.closeDialog();
        link.click();
        console.timeEnd()
      })
  }
}
