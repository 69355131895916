import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { UtilsService } from 'src/app/common-services/utils.service';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import { RotellinaService } from 'src/app/rotellina-dialog/rotellina.service';
import { PianificazioneOrePerAttivitaModalComponent } from '../pianificazione-ore-per-attivita-modal/pianificazione-ore-per-attivita-modal.component';
import { DettaglioOreModalService } from './dettaglio-ore-modal.service';

//Interfaccia per la rappresentazione degli elementi in tabella
export interface TableElement {
  nomeElemento: String;
  contatoriMese: Number[];
  costiOrariMese: Number[];
  totalePeriodo: Number;
  totaleProgetto: Number;
  isTotale: Boolean;
  isPresenze: Boolean;
  isProgetto: Boolean;
  isAltreAttivita: Boolean;
  progettoObject: any;
  altreAttivitaObject: any;
}

@Component({
  selector: 'app-dettaglio-ore-modal',
  templateUrl: './dettaglio-ore-modal.component.html',
  styleUrls: ['./dettaglio-ore-modal.component.css']
})
export class DettaglioOreModalComponent implements OnInit {

  //Indicatori dei mesi da rappresentare come colonne in tabella
  indicatoriMese = [];

  //Colonne mostrate in tabella
  displayedColumns = [];
  //Datasoruce degli elementi in tabella
  tableDataSource: TableElement[] = [];

  //Flag di primo salvataggio. E' impossibile pianificare per attività se prima non si è salvata la pianificazione mensile.
  firstTimeFlag: boolean = true;

  //Flag di caricamento dialog
  loading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private pianificazioneOrePerAttivitaDialog : MatDialog,
    private dettaglioOreModalService: DettaglioOreModalService,
    public thisDialogRef: MatDialogRef<DettaglioOreModalComponent>,
    public snackBar: MatSnackBar,
    private rotella: RotellinaService,
    public utilsService:UtilsService
  ) { }

  /**
   * Verifica se il salvataggio sia consentito.
   */
  isSavingPossible() : Boolean {
    for(var i in this.tableDataSource) {
      for(var j in this.tableDataSource[i].contatoriMese) {
        if(+(this.tableDataSource[i].contatoriMese[j]) < 0) {
          return false;
        }
      }
    }
    return !this.isTotaleMaggioreDiPresenze();
  }

  /**
   * Verifica se, per ogni mese-anno, il totale supera le presenze indicate.
   * toFixed(2) limita la seconda cifra decimale a 2 nel calcolo
   */
  isTotaleMaggioreDiPresenze() : Boolean {
    const totale = this.getTotaleTableElement();
    const presenze = this.getPresenzeTableElement();
    if(totale === undefined || presenze === undefined) {
      return false;
    }
    if(+(totale.totalePeriodo.toFixed(2)) > +(presenze.totalePeriodo.toFixed(2))) {
      return true;
    }
    for(var i in totale.contatoriMese) {
      if(+(totale.contatoriMese[i].toFixed(2)) > +(presenze.contatoriMese[i].toFixed(2))) {
        return true;
      }
    }
    return false;
  }

  /**
   * Ricarica il totale generale in tabella.
   */
  refreshTotale() : void {
    const list: TableElement[] = this.getToConsiderTableElements();
    var tot: TableElement = this.getTotaleTableElement();

    var tmpConts = new Array(tot.contatoriMese.length).fill(0);
    var tmpTotPeriodo = 0;
    var tmpTotProg = 0;

    list.forEach((listElement) => {
      listElement.contatoriMese.forEach((cont, j) => {
        tmpConts[j] += +cont;
      });
      tmpTotPeriodo += +((+listElement.totalePeriodo).toFixed(2));
      tmpTotProg += +((+listElement.totaleProgetto).toFixed(2));
    });

    tot.contatoriMese = tmpConts;
    tot.totalePeriodo = tmpTotPeriodo;
    tot.totaleProgetto = tmpTotProg;
  }

  /**
   * Ricarica il totale del periodo per un singolo elemento in tabella.
   * @param element Elemento in tabella.
   */
  refreshTotalePeriodo(element: TableElement) : void {
    var tmpCont = 0;
    element.contatoriMese.forEach(cont => {tmpCont += +cont})
    element.totalePeriodo = tmpCont;
    element.totalePeriodo = +((+element.totalePeriodo).toFixed(2));
  }

  /**
   * Verifica se un field in tabella è in sola lettura (solo se è totale).
   * @param element
   */
  isInputFieldReadOnly(element: TableElement) : Boolean {
    return element.isTotale === true;
  }

  /**
   * Fornisce un nuovo array di lunghezza pari ai mesi nel periodo selezionato, riempito di numeri casuali
   * compresi tra min e max.
   * @param min Upper-bound per i valori.
   * @param max Lower-bound per i valori.
   */
  getRandomArray(min, max) : any {
    var cont = [];
    this.indicatoriMese.forEach(e => {cont.push(Math.floor(Math.random() * (max - min + 1)) + min)})
    return cont;
  }

  /**
   * Preleva il wrapper di pianificazione mensile per progetti.
   */
  getProgettiWrapper() : any {
    var res = [];
    this.data.wrapper.forEach(e => {
      if(e.pianificazioneProgettoPersona.persona.id === this.data.element.personaObject.id) res.push(e);
    });
    return res;
  }

  /**
   * Preleva il wrapper di pianificazione mensile per altre attività.
   */
  getAltreAttivitaWrapper() : any {
    return this.data.altreAttivitaWrapper.find(found => found.persona.id === this.data.element.personaObject.id);
  }

  /**
   * Fornisce l'indice nelle strutture dati interne riferito all'indicatore fornito in input.
   * @param indicatoreMese Indicatore mese-anno.
   */
  getIndicatoreMeseIndex(indicatoreMese) : Number {
    return this.indicatoriMese.indexOf(indicatoreMese);
  }

  /**
   * Fornisce gli elementi in tabella da considerare (relativi a progetti e altre attività).
   */
  getToConsiderTableElements() : TableElement[] {
    return this.tableDataSource.filter(function(e) { return e.isTotale === false && e.isPresenze === false });
  }

  /**
   * Fornisce l'elemento in tabella riferito al totale.
   */
  getTotaleTableElement() : TableElement {
    return this.tableDataSource.find(found => found.isTotale === true);
  }

  /**
   * Fornisce l'elemento in tabella riferito alle presenze.
   */
  getPresenzeTableElement() : TableElement {
    return this.tableDataSource.find(found => found.isPresenze === true);
  }

  /**
   * Gestisce l'evento di apertura dialog per pianificazione ore per attività in WBS.
   * @param element Elemento in tabella.
   */
  onPianificazioneOrePerAttivita(element: TableElement) : void {
    this.loadWbs(element.progettoObject.nome, element.progettoObject.organizzazione.id, (wbs) => {
      if(wbs == undefined || wbs.length === 0) {
        this.showError("Nessuna versione di WBS associata al progetto " + element.progettoObject.nome + ".");
      }
      else {
        const pianificazioneOrePerAttivitaDialogRef = this.pianificazioneOrePerAttivitaDialog.open(PianificazioneOrePerAttivitaModalComponent, {
          data : { element: element, parentData: this.data.element, indicatoriMese: this.indicatoriMese, dataSource: this.tableDataSource, wrapper: this.data.wrapper }
        });

        pianificazioneOrePerAttivitaDialogRef.afterClosed().subscribe(
          result => {
            if(result) {
              this.openSnackBar("Pianificazione oraria mensile per attività per " + element.progettoObject.nome + " salvata con successo.", "Chiudi");
            }
            this.thisDialogRef.close();
          },
          err => {
            this.showError(err.error.message);
          }
        );
      }
    });
  }

  /**
   * Gestisce l'evento di cambiamento di un contatore in un elemento in tabella.
   * @param element Elemento in tabella.
   */
  onChangeContatore(element: TableElement) : void {
    this.refreshTotalePeriodo(element);
    this.refreshTotale();
  }

  /**
   * Gestisce l'evento di salvataggio di un elemento in tabella sul database relativo a pianificazione mensile di progetto.
   * Verifica se l'elemento sia già presente o meno sul database.
   * In caso positivo, provvede ad aggiornare la tupla con il nuovo valore, altrimenti lo salva ex-novo.
   * @param element Elemento in tabella.
   * @param event Evento.
   */
  onSaveProgettoElement(element: TableElement, event) : void {
    const progettiWrapper = this.getProgettiWrapper().find(filtered => filtered.pianificazioneProgettoPersona.progetto.id === element.progettoObject.id);
    element.contatoriMese.forEach((cm, i) => {
      const indicatoreMese = this.indicatoriMese[i];
      const ref = progettiWrapper.pianificazioniMensili.find(found => found.pianificazioneProgettoPersonaPerMese.mese+"-"+found.pianificazioneProgettoPersonaPerMese.anno === indicatoreMese);
      if(ref != undefined) {
        ref.pianificazioneProgettoPersonaPerMese.ore = cm;
        ref.pianificazioneProgettoPersonaPerMese.costoOrario = element.costiOrariMese[i];
        this.updateProgettoElement(ref.pianificazioneProgettoPersonaPerMese, () => {
          console.log("close 3, no rotella update")
        });
      }
      else {
        this.saveProgettoElement({
          anno: +indicatoreMese.substr(indicatoreMese.indexOf('-')+1,indicatoreMese.length),
          mese: +indicatoreMese.substr(0,indicatoreMese.indexOf('-')),
          ore: cm,
          costoOrario: element.costiOrariMese[i],
          pianificazioneProgettoPersona: progettiWrapper.pianificazioneProgettoPersona
        }, () => {
          console.log("close 4")

          this.rotella.closeDialog()
        });
      }
    });
  }

  /**
   * Gestisce l'evento di salvataggio di un elemento in tabella sul database relativo a pianificazione mensile di altre attività.
   * Verifica se l'elemento sia già presente o meno sul database.
   * In caso positivo, provvede ad aggiornare la tupla con il nuovo valore, altrimenti lo salva ex-novo.
   * @param element Elemento in tabella.
   * @param event Evento.
   */
  onSaveAltreAttivitaElement(element: TableElement, event) : void {
    const altreAttivitaWrapper = this.getAltreAttivitaWrapper();
    element.contatoriMese.forEach((cm, i) => {
      const indicatoreMese = this.indicatoriMese[i];
      const ref = altreAttivitaWrapper.pianificazioniMensili.find(function(found) {return found.altreAttivita.id === element.altreAttivitaObject.id && found.mese+"-"+found.anno === indicatoreMese});
      if(ref != undefined) {
        ref.ore = cm;
        this.updateAltreAttivitaElement(ref, () => {});
      }
      else {
        this.saveAltreAttivitaElement({
          anno: +indicatoreMese.substr(indicatoreMese.indexOf('-')+1,indicatoreMese.length),
          mese: +indicatoreMese.substr(0,indicatoreMese.indexOf('-')),
          ore: cm,
          altreAttivita: element.altreAttivitaObject,
          persona: this.data.element.personaObject
        }, () => {          console.log("close 5");this.rotella.closeDialog()});
      }
    });
  }

  /**
   * Gestisce l'evento di salvataggio di un elemento in tabella sul database relativo a pianificazione mensile di busta paga.
   * Verifica se l'elemento sia già presente o meno sul database.
   * In caso positivo, provvede ad aggiornare la tupla con il nuovo valore, altrimenti lo salva ex-novo.
   * @param element Elemento in tabella.
   * @param event Evento.
   */
  onSavePresenzeElement(element, event) : void {
    const presenzeWrapper = this.data.bustePaga;
    element.contatoriMese.forEach((cm, i) => {
      const indicatoreMese = this.indicatoriMese[i];
      const ref = presenzeWrapper.find(function(found) { return found.mese+"-"+found.anno === indicatoreMese });
      if(ref != undefined) {
        ref.oreTotali = cm;
        this.updateBustaPagaElement(ref, () => {
          /** Per disposizione degli elementi in tabella, le presenze corrispondono all'ultima riga. E' verosimile, dunque, che il dialog debba chiudersi una volta salvate tutte le presenze. */
          if(i === element.contatoriMese.length - 1) {
            try {
              console.log("chiudi rotella 1")
              this.rotella.closeDialog();

            } catch (error) {
              console.log(error)
            }
            this.thisDialogRef.close(true);
          }
        });
      }
      else {
        this.saveBustaPagaElement({
          data: 1,
          anno: +indicatoreMese.substr(indicatoreMese.indexOf('-')+1,indicatoreMese.length),
          mese: +indicatoreMese.substr(0,indicatoreMese.indexOf('-')),
          oreTotali: cm,
          persona: this.data.element.personaObject
        }, () => {
          /** Per disposizione degli elementi in tabella, le presenze corrispondono all'ultima riga. E' verosimile, dunque, che il dialog debba chiudersi una volta salvate tutte le presenze. */
          if(i === element.contatoriMese.length - 1) {
            try {
              console.log("chiudi rotella 2")
              this.rotella.closeDialog();

            } catch (error) {
console.log(error)
            }

            this.thisDialogRef.close(true);
          }
        });
      }
    });
  }

  /**
   * Gestisce l'evento di salvataggio degli elementi in tabella sul database (instradatore).
   * @param event Evento.
   */
  onSaveElements(event) : void {
    if(this.tableDataSource.length !== 0)    this.rotella.openDialog()

    this.tableDataSource.forEach(element => {
      if(element.isProgetto === true) this.onSaveProgettoElement(element, event);
      else if(element.isAltreAttivita === true) this.onSaveAltreAttivitaElement(element, event);
      else if(element.isPresenze === true) this.onSavePresenzeElement(element, event);
    });
  }

  onRefreshPresenze(element: TableElement) : void {
    const totali = this.tableDataSource.find(found => found.isTotale.valueOf());
    element.contatoriMese = totali.contatoriMese.concat();
    element.totalePeriodo = totali.totalePeriodo;
  }

  /**
   * Aggiorna il costo orario di una persona su un progetto in base all'ultimo contratto nel mese (se la persona è collaboratore nel progetto) o al
   * costo orario annuale, se configurato (nel caso di personale dipendente).
   * @param element Elemento in tabella.
   */
  onRefreshCostoOrario(element: TableElement) : void {
    const collaborazioneRef = this.data.element.collaborazioni.find(collaborazioneFound => collaborazioneFound.idProgetto === element.progettoObject.id);
    //Se la persona è un collaboratore nel progetto considerato.
    if(collaborazioneRef != undefined && collaborazioneRef.collaborazione === true) {
      this.indicatoriMese.forEach((indicatoreMese, i) => {
        const anno = +(indicatoreMese.substr(indicatoreMese.indexOf('-')+1));
        const mese = +(indicatoreMese.substr(0, indicatoreMese.indexOf('-')));
        //Se esiste, inizializzo il costo orario mensile con il costo orario indicato nell'ultimo contratto di collaborazione disponibile
        //che ricade nel mese-anno considerato.
        this.loadUltimoContrattoMeseAnno(i, element.progettoObject.id, this.data.element.personaObject.id, this.data.element.filters[1].id, mese, anno, (cc) => {
          if(cc != undefined && cc.costoOrario != undefined) element.costiOrariMese[i] = cc.costoOrario;
          else element.costiOrariMese[i] = 0;
        });
      });
    }
    else {
      const costoOrarioAnnuale = this.data.costiOrariAnnuali.find(found => found.progetto.id === element.progettoObject.id);
      if(costoOrarioAnnuale != undefined) {
        const costoOrarioPersona = costoOrarioAnnuale.dettagli.find(found => found.persona.id === this.data.element.personaObject.id);
        if(costoOrarioPersona != undefined) {
          this.indicatoriMese.forEach((indicatoreMese, i) => {
            //Se esiste, inizializzo tutti i costi orari mensile dell'anno considerato con il costo orario annuale definito per la persona
            //sul progetto.
            const anno = +(indicatoreMese.substr(indicatoreMese.indexOf('-')+1));
            const costoOrarioRef = costoOrarioPersona.costi.find(found => found.anno === anno);
            if(costoOrarioRef != undefined) element.costiOrariMese[i] = costoOrarioRef.costoOrario;
            else element.costiOrariMese[i] = 0;
          });
        }
        else element.costiOrariMese = new Array(this.indicatoriMese.length).fill(0);
      }
      else element.costiOrariMese = new Array(this.indicatoriMese.length).fill(0);
    }
  }

  /**
   * Carica dal database tutte le altre attività.
   * @param _callback
   */
  loadAllAltreAttivita(_callback) : void {
    this.dettaglioOreModalService.getAltreAttivita().subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica l'ultima versione di WBS.
   * @param nomeProgetto Nome del progetto.
   * @param idOrganizzazione Id dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadWbs(nomeProgetto, idOrganizzazione, _callback) : void {
    this.dettaglioOreModalService.getWbs(nomeProgetto, idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica il contratto più recente ricadente in un determinato mese di un determinato anno.
   * @param index Indice nelle liste interne. Si trasmette per performare operazioni a fine lista.
   * @param idProgetto Id del progetto di riferimento.
   * @param idPersona Id della persona di riferimento.
   * @param idSedeStakeholder Id della sede stakeholder di riferimento.
   * @param mese Mese di riferimento.
   * @param anno Anno di riferimento.
   * @param _callback Azione successiva.
   */
  loadUltimoContrattoMeseAnno(index, idProgetto, idPersona, idSedeStakeholder, mese, anno, _callback) : void {
    this.dettaglioOreModalService.getUltimoContrattoMeseAnno(idProgetto, idPersona, idSedeStakeholder, mese, anno).subscribe(
      data => { _callback(data); },
      err => { _callback(undefined); }
    );
  }

  /**
   * Salva la pianificazione mensile fornita.
   * @param element Elemento di pianificazione mensile.
   * @param _callback Azione successiva.
   */
  saveProgettoElement(element, _callback) : void {
    this.dettaglioOreModalService.savePianificazioneMensile(element).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Aggiorna la pianificazione mensile fornita.
   * @param element Elemento di pianificazione mensile.
   * @param _callback Azione successiva.
   */
  updateProgettoElement(element, _callback) : void {
    this.dettaglioOreModalService.updatePianificazioneMensile(element).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva la pianificazione altre attività fornita.
   * @param element Elemento di pianificazione altre attività.
   * @param _callback Azione successiva.
   */
  saveAltreAttivitaElement(element, _callback) : void {
    this.dettaglioOreModalService.savePianificazioneAltreAttivita(element).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Aggiorna la pianificazione altre attività fornita.
   * @param element Elemento di pianificazione altre attività.
   * @param _callback Azione successiva.
   */
  updateAltreAttivitaElement(element, _callback) : void {
    this.dettaglioOreModalService.updatePianificazioneAltreAttivita(element).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva la busta paga fornita.
   * @param element Elemento di busta paga.
   * @param _callback Azione successiva.
   */
  saveBustaPagaElement(element, _callback) : void {
    this.dettaglioOreModalService.saveBustaPaga(element).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Aggiorna la busta paga fornita.
   * @param element Elemento di busta paga.
   * @param _callback Azione successiva.
   */
  updateBustaPagaElement(element, _callback) : void {
    this.dettaglioOreModalService.updateBustaPaga(element).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Inizializza la tabella con le informazioni prelevate dal database.
   * @param _callback Azione successiva.
   */
  initDataFromWrapper(_callback) : void {
    const progettiWrapper = this.getProgettiWrapper();
    const altreAttivitaWrapper = this.getAltreAttivitaWrapper();
    this.tableDataSource.filter(filtered => filtered.isProgetto === true).forEach(element => {
      const thisProgettiWrapper = progettiWrapper.find(pwFiltered => pwFiltered.pianificazioneProgettoPersona.progetto.id === element.progettoObject.id);
      if(thisProgettiWrapper) {
        thisProgettiWrapper.pianificazioniMensili.forEach(pianificazioneMensilePw => {
          const p = pianificazioneMensilePw.pianificazioneProgettoPersonaPerMese;
          const index = +this.getIndicatoreMeseIndex(p.mese+"-"+p.anno);
          if(index != undefined && index >= 0) {
            element.contatoriMese[index] = p.ore;
            element.costiOrariMese[index] = p.costoOrario;
            this.refreshTotalePeriodo(element);
          }
          element.totaleProgetto += p.ore;
          element.totaleProgetto = +((+element.totaleProgetto).toFixed(2));
        });
      }
    });
    this.tableDataSource.filter(newFiltered => newFiltered.isAltreAttivita === true).forEach(e => {
      altreAttivitaWrapper.pianificazioniMensili.filter(f => f.altreAttivita.id === e.altreAttivitaObject.id).forEach(a => {
        const index = +this.getIndicatoreMeseIndex(a.mese+"-"+a.anno);
        if(index != undefined && index >= 0) {
          e.contatoriMese[index] = a.ore;
          this.refreshTotalePeriodo(e);
        }
        e.totaleProgetto += a.ore;
        e.totaleProgetto = +((+e.totaleProgetto).toFixed(2));
      });
    });
    const presenze: TableElement = this.getPresenzeTableElement();
    this.data.bustePaga.forEach(bustaPaga => {
      const index = +this.getIndicatoreMeseIndex(bustaPaga.mese+"-"+bustaPaga.anno);
      if(index != undefined && index >= 0) {
        presenze.contatoriMese[index] = bustaPaga.oreTotali;
      }
      presenze.totaleProgetto += bustaPaga.oreTotali;
      presenze.totaleProgetto = +((+presenze.totaleProgetto).toFixed(2));
      this.refreshTotalePeriodo(presenze);
    });
    this.tableDataSource = this.tableDataSource.concat([]);
    _callback();
  }

  /**
   * Inizializza le colonne da mostrare in tabella, inserendo una colonna per ogni mese scelto nel periodo.
   */
  initColonne() : void {
    this.displayedColumns.push("progetto");
    for(var i=this.data.element.filters[3].year(); i<=this.data.element.filters[4].year(); i++) {
      //per ogni anno
      s: switch(i) {
        case this.data.element.filters[3].year():
          //anno di inizio
          for(var j=this.data.element.filters[3].month()+1; j<=(+this.data.element.filters[3].year()==+this.data.element.filters[4].year() ? this.data.element.filters[4].month()+1 : 12); j++) {
            this.indicatoriMese.push(j+"-"+i); this.displayedColumns.push(j+"-"+i);
          }
          break s;
        case this.data.element.filters[4].year():
          //anno di fine
          for(var z=1; z<=this.data.element.filters[4].month()+1; z++) { this.indicatoriMese.push(z+"-"+i); this.displayedColumns.push(z+"-"+i); }
          break s;
        default:
          //anno intermedio
          for(var y=1; y<=12; y++) { this.indicatoriMese.push(y+"-"+i); this.displayedColumns.push(y+"-"+i); }
          break s;
      }
    }
    this.displayedColumns.push("totale_periodo");
    this.displayedColumns.push("totale_progetto");
    this.displayedColumns.push("aggiorna_costo_orario");
    this.displayedColumns.push("dettaglio_costo_orario");
  }

  /**
   * Inizializza il datasource della tabella.
   * @param _callback Azione successiva.
   */
  initTableDataSource(_callback) : void {
    var cont = new Array(this.indicatoriMese.length).fill(0);

    this.data.element.selection.selected.forEach(element => {
      this.tableDataSource.push({
        nomeElemento: element.nome,
        contatoriMese: cont.concat([]),
        costiOrariMese: cont.concat([]),
        totalePeriodo: 0,
        totaleProgetto: 0,
        isTotale: false,
        isPresenze: false,
        isProgetto: true,
        isAltreAttivita: false,
        progettoObject: element,
        altreAttivitaObject: undefined
      });
    });

    this.loadAllAltreAttivita((res) => {
      res.forEach(a => {
        this.tableDataSource.push({
          nomeElemento: a.nome,
          contatoriMese: cont.concat([]),
          costiOrariMese: undefined,
          totalePeriodo: 0,
          totaleProgetto: 0,
          isTotale: false,
          isPresenze: false,
          isProgetto: false,
          isAltreAttivita: true,
          progettoObject: undefined,
          altreAttivitaObject: a
        });
      });
      this.tableDataSource.push({
        nomeElemento: "TOTALE",
        contatoriMese: cont.concat([]),
        costiOrariMese: undefined,
        totalePeriodo: 0,
        totaleProgetto: 0,
        isTotale: true,
        isPresenze: false,
        isProgetto: false,
        isAltreAttivita: false,
        progettoObject: undefined,
        altreAttivitaObject: undefined
      });

      this.tableDataSource.push({
        nomeElemento: "Presenze",
        contatoriMese: cont.concat([]),
        costiOrariMese: undefined,
        totalePeriodo: 0,
        totaleProgetto: 0,
        isTotale: false,
        isPresenze: true,
        isProgetto: false,
        isAltreAttivita: false,
        progettoObject: undefined,
        altreAttivitaObject: undefined
      });

      _callback();
    });
  }

  /**
   * Mostra una notifica all'utente.
   * @param message Messaggio da mostrare.
   * @param action Azione da permettere all'utente.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.firstTimeFlag = this.data.firstTimeFlag;
    this.initColonne();
    this.initTableDataSource(() => {
      this.tableDataSource.forEach(element => {
        this.refreshTotalePeriodo(element);
      });
      this.refreshTotale();
      this.tableDataSource = this.tableDataSource.concat([]);
      this.initDataFromWrapper(() => {
        this.refreshTotale();
        this.loading = false;
      });
    });
  }

}
