import { Component, ComponentFactoryResolver, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { UtilityModuleComponent } from '../core_modules/utility-module/utility-module.component';

@Component({
  selector: 'admin-dashboard',
  templateUrl: './admin.dashboard.html',
  styleUrls: ['./admin.dashboard.css']
})
export class AdminDashboard {

  constructor(private router: Router, private utilityModule: UtilityModuleComponent) {}

  showFiller = false;
  
  redirect(newPage)
  {
    this.utilityModule.redirectSuperUser(newPage);
  }
}
