import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {


  private resolve(path, obj) {
    return path.split('.').reduce(function (prev, curr) {
      return prev ? prev[curr] : null
    }, obj || self)
  }

  public transform(value, keys: any, term: string) {

    var res = keys.split(",");
    for (var key in res) {
        console.log(res[key]);
    }
    if (!term) return value;
    var papabili = Array();
    for (var key in res) {
      for (var i = 0; i < value.length; i++) {
        var val = this.resolve(res[key], value[i]);
          console.log("cerco "+term+" nella propriesta "+res[key]);
          console.log("regex " + val + " in key " + res[key]);
          if (RegExp(term, 'gi').test(val)) {
            papabili.push(value[i]);
          }
        }
    }
    return papabili;

    /*   for (var key in value) {
         if (Object.prototype.hasOwnProperty.call(value, key)) {
           var val = value[key];
           // use val
           console.log("key " + key + " value " + val);
           for (var key in value[key]) {
             if (Object.prototype.hasOwnProperty.call(value, key)) {
               var val = value[key];
               console.log("key 1 " + key + " value 1 " + val);
             }
           }

         }
       }

       /!*  var myJSON = JSON.stringify(value);
         console.log(myJSON);
         keys.split(",").forEach(function (entry) {
           console.log("entry " + entry);
           if (entry.includes(".")) {
             console.log("proprieta con punto");
             entry.split(".").forEach(function (entrys) {
               console.log(" sotto proprieta con punto " + entrys);
               console.log("values "+value[entrys]);
             })
           }
         });*!/

       //console.log(value);
       if (!term) return value;
       return (value || []).filter(item => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));*/


  }
}
