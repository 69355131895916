import { CreaProgettoService } from '../crea-progetto/crea-progetto.service';
import {Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef, ChangeDetectorRef, Injectable, Inject, LOCALE_ID } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatDialog, MatDialogRef, MatSnackBar, MatHorizontalStepper, MatTreeNestedDataSource, MAT_DIALOG_DEFAULT_OPTIONS, MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { OrganizzazioniService } from '../organizzazioni/organizzazioni.service';
import { AppComponent } from '../app.component';
import { UserStorage } from '../core_modules/user.storage';
import { UtilityModuleComponent } from '../core_modules/utility-module/utility-module.component';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { BehaviorSubject, observable, Observable, from } from 'rxjs';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { GestioneBandiService } from '../gestione-bandi/gestione-bandi.service';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { IfStmt, Serializer } from '@angular/compiler';
import { CreazioneDatiProgettoComponent } from './modals/creazione-dati-progetto/creazione-dati-progetto.component';
import { GestionePartnerProgettoComponent } from '../gestione-partner-progetto/gestione-partner-progetto.component';
import { GestioneReferentiProgettoComponent } from '../gestione-referenti-progetto/gestione-referenti-progetto.component';
import { GestioneWbsComponent } from '../gestione-wbs/gestione-wbs.component';
import { resolve } from 'url';
import { GestionePianiDeiCostiComponent } from '../gestione-piani-dei-costi/gestione-piani-dei-costi.component';
import { AuthService } from '../core_modules/auth.service';

@Component({
  selector: 'app-crea-progetto',
  templateUrl: './crea-progetto.component.html',
  styleUrls: ['./crea-progetto.component.css'],
  providers: [{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class CreaProgettoComponent implements OnInit {

  toUsedIdProgetto;

  progettoObj : any;


  amount = new FormControl('', [
    Validators.required
  ]);
  ammissione = new FormControl('', [
     Validators.required
  ]);

 @ViewChild(CreazioneDatiProgettoComponent) step1Content : CreazioneDatiProgettoComponent;
 @ViewChild('step2Content') step2Content : GestionePartnerProgettoComponent;
 @ViewChild(GestioneReferentiProgettoComponent) step3Content : GestioneReferentiProgettoComponent;

 @ViewChild('step3A') step3PartnerContent : GestionePartnerProgettoComponent;

  selectionButtonTag: String = "SELEZIONA TUTTO";

  constructor(private organizzazioniService: OrganizzazioniService,
              private router: Router,
              private appComponent: AppComponent,
              private authService: AuthService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private changeDetectorRef:ChangeDetectorRef,
              private userStorage: UserStorage,
              private utilityModule: UtilityModuleComponent,
              private creaProgettoService: CreaProgettoService,
              public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>,
              private adapter: DateAdapter<any>,
              private bandiService: GestioneBandiService,
              private aggiuntaRecordWbsDialog: MatDialog) {
    this.adapter.setLocale("it");

  }

  /**
   * Inizializza il nome del bando
   */
  initializeBandoName() : void {
    for(var i=0; i<this.bandiAttivi.length; i++) {
      if(this.bandiAttivi[i].id==this.bandoIdObj.id) {
        this.nomeBando = this.bandiAttivi[i].nome;
      }
    }
    console.log("Bando di riferimento "+this.nomeBando);
  }

  crea_progetto_section_title = "Importazione Progetto";
  step2Label = "Scegli per il progetto " + sessionStorage.getItem("nome_progetto") + " almeno un partner da importare tra quelli presenti in GESTORI.";
  step3Label = "Scegli per il progetto " + sessionStorage.getItem("nome_progetto") + " i referenti da importare tra quelli presenti in GESTORI.";
  // Variabili per il controllo dello 'stepper'
  isLinear = false;
  step4Flag = false;

  editing = true;

  bodyJSON_ImportaProgetti : any = {
    api_uid: "123456",
    api_key: "a2f13d91f247fa55047303eea8862dda"
  }
  bodyText_ImportaProgetti: string = "{\"api_uid\": \"123456\", \"api_key\": \"a2f13d91f247fa55047303eea8862dda\" };";

  @ViewChild(MatHorizontalStepper) creaProgettoStepper: MatHorizontalStepper;

  @ViewChild('udatePraticaContainer1', { read: ViewContainerRef }) udateDatiGeneraliPratica: ViewContainerRef;
  @ViewChild('udatePraticaContainer2', { read: ViewContainerRef }) configuraAttivitaPratica: ViewContainerRef;
  @ViewChild('udatePraticaContainer3', { read: ViewContainerRef }) modificaAttivita: ViewContainerRef;

  alreadySavedStakeholders = [];

  getFromChildStep1() {
    this.selectedProgettoId = this.step1Content.selectedProgettoId;
    this.nomeProgetto = this.step1Content.nomeProgetto;
    this.nomeEstesoProgetto = this.step1Content.nomeEstesoProgetto;
    this.codiceInterno = this.step1Content.codiceInterno;
    this.codiceEnteFinanziatore = this.step1Content.codiceEnteFinanziatore;
    this.dataInizioProgetto = this.step1Content.dataInizioProgetto;
    this.dataFinePresuntaProgetto = this.step1Content.dataFinePresuntaProgetto;
    this.decretoAmmissione = this.step1Content.decretoAmmissione;
    this.dataAmmissione = this.step1Content.dataAmmissione;
    this.selectedBandoId = this.step1Content.selectedBandoId;
    this.nomeBando = this.step1Content.nomeBando;
    this.bandoIdObj = this.step1Content.bandoIdObj;
    this.idProgetto = this.step1Content.idProgetto;
    this.bandiAttivi = this.step1Content.bandiAttivi;
    this.amount = this.step1Content.amount;
  }

  atLeastOneErrorInStep1() {
    this.getFromChildStep1();
    if ((this.nomeProgetto == null) || (this.amount.valid == false) )  {
      return true;
    }
    if ((this.dataInizioProgetto == null) || (this.dataAmmissione == null) ) {
      return true;
    }
    if (this.bandoIdObj == null) {
      return true;
    }
    return false;
  }

  completeStep1() {
    // PRELEVA LE INFORMAZIONI INSERITE NELLO STEP 1
    // FAI LE OPERAZIONI DI SALVATAGGIO E SE NON CI SONO ERRORI VAI AVANTI
    this.getFromChildStep1();
    this.mandatoryStep1Executed = false;
    if (!this.atLeastOneErrorInStep1()) {
      this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
        width: "250px",
        data: {
          message: "Vuoi davvero creare questo progetto?",
          submessage: "Il nome del progetto non potrà più essere modificato."
        },
        panelClass: 'custom-confirm-container'
      });
      this.dialogRef.afterClosed().subscribe(
        progetto => {
          if(progetto==true) {
            let progetto = {} as any;

            progetto.nome = this.nomeProgetto;
            progetto.organizzazione = this.organizzazioneObj;
            progetto.nomeEsteso = this.nomeEstesoProgetto;
            progetto.codiceInterno = this.codiceInterno;
            progetto.codiceEnteFinanziatore = this.codiceEnteFinanziatore;
            progetto.decretoAmmissioneFinanziamento = this.decretoAmmissione;

            if(this.dataInizioProgetto!=null){
            progetto.dataInizio = this.dataInizioProgetto;
            }

            if(this.dataFinePresuntaProgetto!=null){
            progetto.scadenzaInterna = this.dataFinePresuntaProgetto;
            }

            if(this.dataAmmissione!=null){
            progetto.dataAmmissioneFinanziamento = this.dataAmmissione;
             }

            progetto.bando = this.bandoIdObj;


            console.log("**** SALVA PROGETTO***");


            this.creaProgettoService.saveProgetto(progetto).subscribe(
              data => {

                this.progettoObj = data;

                //progetto.data;
                sessionStorage.setItem("id_progetto", progetto.id);
                sessionStorage.setItem("nome_progetto", progetto.nome);
                this.toUsedIdProgetto = data.id;
                this.step2Label = "Scegli per il progetto " + sessionStorage.getItem("nome_progetto") + " almeno un partner da importare tra quelli presenti in GESTORI";
                this.step3Label = "Scegli per il progetto " + sessionStorage.getItem("nome_progetto") + " i referenti da importare tra quelli presenti in GESTORI";
                this.openSnackBar("Progetto salvato con successo.","Chiudi");
                sessionStorage.setItem("definizione_partner_progetto", JSON.stringify(this.progettoObj));
                this.step2Content.initialize(undefined);
                this.isLinear = true;
                this.creaProgettoStepper.next();
              },
              err => {
                this.dialog.open(WarningMessageComponent,{
                  data: {
                    message: err.error.message
                  },
                  panelClass: 'custom-warning-container'
                });
              }
            );
            this.initializeBandoName();
          }
          else {
            //event.confirm.reject();
          }
        } // if(res==true)
      ); // this.dialogRef.afterClosed().subscribe
    } // if (!this.atLeastOneErrorInStep1())
    else {
      this.dialog.open(WarningMessageComponent,{
        data: {
          message: "Impossibile salvare, uno o più campi presentano valori non consentiti."
        },
        panelClass: 'custom-warning-container'
      });
      this.mandatoryStep1Executed = false;
      return;
    }
    this.mandatoryStep1Executed = true;
   //this.creaProgettoStepper.next;
  } // completeStep1()

  updateStakeholderAndSave(stakeholder, _callback) {
    this.creaProgettoService.updatePartner(stakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err); }
    );
  }

  createStakeholderAndSave(stakeholder, _callback) {
    this.creaProgettoService.savePartner(stakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err); }
    );
  }

  loadComuneFromCap(cap, _callback) {
    this.creaProgettoService.getComuneFromCap(cap).subscribe(
      data => { _callback(data); },
      err => { this.showError(err); }
    );
  }

  createSedeStakeholder(sede, _callback) {
    this.creaProgettoService.saveSedeStakeholder(sede).subscribe(
      data => { _callback(data); },
      err => { this.showError(err); }
    );
  }

  createStakeholderFromNode(node) : any {
    return {
      id : undefined,
      denominazione : node.account_name,
      codiceFiscale : undefined,
      partitaIva : undefined,
      indirizzo : undefined,
      citta : undefined,
      cap : undefined,
      stato : undefined,
      nazione : undefined,
      codiceDestinatario : undefined,
      telefono : node.phone,
      altroTelefono : node.other_phone,
      email : node.email_1,
      email2 : node.email_2,
      pec : undefined,
      organizzazione : this.organizzazioneObj,
      tipologiaStakeholder : node.tipologia
    };
  }

  checkPartnerDuplicati(dati, nome, indice) : Boolean {
    for(var i=0; i<dati.length; i++) {
      if(dati[i].account_name==nome && i!=indice) {
        return true;
      }
    }
    return false;
  }

  completeStep2() {
    this.step2Content.onSaveAll(false, () => {
      this.openSnackBar("Partner di progetto salvati con successo.", "Chiudi");
      sessionStorage.setItem("definizione_referenti_progetto", JSON.stringify(this.progettoObj));
      this.step3Content.initialize();
      this.creaProgettoStepper.next();
    });
  }

  atLeastOneStakeholder() : Boolean {
    return true;
  }

  atLeastOneErrorInStep3() {
    /*
    if (this.mandatoryStep1Executed) {
      return false;
    } else {
      return this.configuraAttivitaInPraticaComponent.atLeastOneError();
    }
    */
  }

  completeStep3() {
    const errorMessage = this.step3Content.getErrorMessage();
    if(errorMessage != undefined) this.showError({error:{message: errorMessage}});
    else {
      this.step3PartnerContent.onSaveAll(true, () => {
        this.step3Content.onSaveAll(() => {
          this.openSnackBar("Referenti di progetto salvati con successo.", "Chiudi");
          window.location.replace(window.location.origin + '/#/progetti');
        });
      });
    }
  }

  /**
   * Preleva gli stakeholders dal database
   */
  getStakeholdersFromDatabase(_callback) : void {
    this.creaProgettoService.getAllStakeholders(this.organizzazioneObj.id).subscribe(
      data => {
        console.log("Stakeholders database from method.", data);
        _callback(data);
      },
      err => {
        this.showError(err);
      }
    );
  }

  savedStakeholder = undefined;

  saveStakeholderInDatabase(stakeholder, _callback) : void {
    this.creaProgettoService.savePartner(stakeholder).subscribe(
      data => {
        this.savedStakeholder = data;
        _callback(data);
      },
      err => { this.showError(err); }
    );
  }

  getStakeholderFromReferente(referente) : any {
    return {
      id: undefined,
      denominazione: referente.account_name,
      codiceFiscale: undefined,
      partitaIva: undefined,
      indirizzo: referente.indirizzo,
      citta: undefined,
      cap: referente.cap,
      stato: undefined,
      nazione: undefined,
      codiceDestinatario: undefined,
      telefono: referente.account_phone,
      email: referente.email_1,
      email2: referente.email_2,
      pec: undefined,
      organizzazione: this.organizzazioneObj,
      tipologiaStakeholder: undefined
    }
  }

  saveNewStakeholdersFromReferenti(referenti, _callback) : void {
    var list = [];
    var names = [];
    var toConsider = [];

    for(var i=0; i<referenti.length; i++) {
      const sh = this.getStakeholderFromReferente(referenti[i]);
      if(sh.denominazione.toString()!="") {
        console.log("Stakeholder considered for possible saving.", sh);
        if(names.indexOf(sh.denominazione.toString())<0) {
          //non incluso nella lista
          names.push(sh.denominazione.toString());
          list.push(sh);
        }
      }
    }

    console.log("Stakeholders extracted from handlers for possible saving.", list);

    //prelevo gli stakeholders dal database
    this.getStakeholdersFromDatabase((stakeholders) => {

      var fromDbNames = [];
      for(var j=0; j<stakeholders.length; j++) {
        if(fromDbNames.indexOf(stakeholders[j].denominazione.toString())<0) {
          fromDbNames.push(stakeholders[j].denominazione.toString());
        }
      }

      for(var t=0; t<list.length; t++) {
        if(fromDbNames.indexOf(list[t].denominazione.toString())<0) {
          toConsider.push(list[t]);
        }
      }

      console.log("New stakeholders must be saved.", toConsider);

      //salvo i nuovi stakeholders
      for(var z=0; z<toConsider.length; z++) {
        this.saveStakeholderInDatabase(toConsider[z], (result) => {
          console.log("New stakeholder saved.", result);
          this.loadComuneFromCap(toConsider[z].cap, (comune) => {
            this.createSedeStakeholder({
              nome: toConsider[z].indirizzo,
              descrizione: "Principale",
              comune: comune,
              stakeholder: result
            }, (sede) => {
              if(z==toConsider.length-1) {
                //lista finita
                _callback();
                return;
              }
            });
          });
        });
      }
      _callback();
    });
  }

  getRuoloFromReferente(referente, _callback) : any {
    this.creaProgettoService.getIdReferenteRendicontaFromNameReferenteGestori(
      {
        idOrganizzazione: this.organizzazioneObj.id,
        ruoloReferente: referente.ruolo_referente.replace('Ref.','Referente')
      }
    ).subscribe(
      data => {
        _callback(data);
      },
      err => { this.showError(err); }
    );
  }

  saveProgettoReferenteRelationInDatabase(wrapper, _callback) : any {
    this.creaProgettoService.saveProgettoReferenteRelation(wrapper).subscribe(
      data => { _callback(data); },
      err => { this.showError(err); }
    );
  }

  saveReferente(referente, stakeholder) {
    this.creaProgettoService.getSediStakeholder(stakeholder.id).subscribe(
      data => {
        if(data == undefined || data.length === 0) {
          this.dialog.open(WarningMessageComponent,{
            data: {
              message: "Impossibile trovare una sede di stakeholder da associare al referente."
            },
            panelClass: 'custom-warning-container'
          });
        }
        else {
          this.creaProgettoService.saveReferente(
            {
              id: undefined,
              nome: referente.first_name,
              cognome: referente.last_name,
              codiceFiscale: undefined,
              partitaIva: undefined,
              dataDiNascita: undefined,
              cittaDiNascita: undefined,
              statoDiNascita: undefined,
              nazioneDiNascita: undefined,
              telefonoCasa: referente.phone,
              telefonoUfficio: undefined,
              cellularePersonale: referente.mobile,
              cellulareUfficio: undefined,
              emailPersonale: referente.email,
              emailUfficio: undefined,
              dipartimento: undefined,
              ruolo: referente.ruolo_referente,
              cellulareAltro: undefined,
              organizzazione: this.organizzazioneObj,
              sedeStakeholder: data[0],
            }
          ).subscribe(
            data => {
              console.log("Handler saved.", data);

              //prelevo ruolo del referente
              this.getRuoloFromReferente(referente, (ruoloReferente) => {

                //salvo relazione progetto-referente
                this.saveProgettoReferenteRelationInDatabase(
                  {
                    id: undefined,
                    progetto: this.progettoObj,
                    persona: data,
                    ruoloReferente: ruoloReferente,
                    stakeholder: stakeholder
                  }, (relation) => {

                    //relazione salvata
                    console.log("Project-Handler relation saved.", relation);
                  }
                )
              });
            },
            err => {
              this.dialog.open(WarningMessageComponent,{
                data: {
                  message: err.error.message
                },
                panelClass: 'custom-warning-container'
              });
            }
          );
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    )
  }

  atLeastOneErrorInStep4() {
    /*
    if (this.mandatoryStep1Executed) {
      return false;
    } else {
      return this.modificaDettaglioAttivitaComponent.atLeastOneError();
    }
    */
  }

  endStepper() {
    this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: "Vuoi davvero terminare il processo di creazione del progetto?",
        submessage: "Eventuali fasi non completate non saranno salvate."
      },
      panelClass: 'custom-confirm-container'
    });
    this.dialogRef.afterClosed().subscribe(
      res => {
        if(res==true) {
          this.openSnackBar("Progetto salvato con successo.", "Chiudi");
          setTimeout(() => {
            window.location.replace(window.location.origin + '/progetti');
          }, 1000);
        }
      }
    );
  }

  resetStepper() {

    this.selectedProgettoId = null;
    this.nomeProgetto = null;
    this.nomeEstesoProgetto = null;
    this.codiceInterno = null;
    this.codiceEnteFinanziatore = null;
    //this.dataInizioProgetto = new FormControl(null,[]);
    this.dataInizioProgetto = null;
    this.dataFinePresuntaProgetto = new FormControl(null,[]);
    this.dataInizioProgettoPicker = new FormControl(null,[]);
    this.decretoAmmissione = null;
    this.dataAmmissione = null;
    this.selectedBandoId = null;
    this.nomeBando = null;
    this.bandiAttivi = null;

    sessionStorage.removeItem("id_progetto");
    this.creaProgettoStepper.reset();
    this.changeDetectorRef.detectChanges();
    this.mandatoryStep1Executed = false;

  }

  // L'oggetto Organizzazione di riferimento, inizializzato a partire dall'ID
  // lasciato nel 'this.userStorage' dalla pagina chiamante
  organizzazioneObj = undefined;


  // VARIABILI DEL FORM (STEP 1):
  // L'ID progetto scelto
  selectedProgettoId = null;
  // Il nome del progetto
  nomeProgetto = null;
  // Il nome esteso del progetto
  nomeEstesoProgetto = null;
  // Il codice interno del progetto
  codiceInterno = null;
  // Il codice dell'Ente Finanziatore del progetto
  codiceEnteFinanziatore = null;
  // La data di inizio progetto collegata all'apposito picker
  myDate = new FormControl(null,[]);
  dataInizioProgetto = undefined;
  // La data di inizio progetto collegata all'apposito picker
  dataInizioProgettoPicker = new FormControl(null,[]);
  // La data di fine presunta progetto collegata all'apposito picker
  dataFinePresuntaProgetto = undefined;
  // Il decreto di ammmissione del progetto
  decretoAmmissione = null;
  // La data di ammissione al finanziamento del progetto collegata all'apposito picker
  dataAmmissione = undefined;
  // L'id del bando attualmente selezionato nella combo
  selectedBandoId = null;
  // L'id del Tipo Partner attualmente selezionato nella combo
  selectedPartnerRuoloId = null;
   // L'id del Tipo referente attualmente selezionato nella combo
   selectedReferenteRId = null;
  // Il nome del bando attualmente selezionato nella combo
  nomeBando = null;
  // Il nome del bando attualmente selezionato nella combo
  RuoloPartner = null;

//oggetto contentente l'id del bando selezionato per salvarlo
  bandoIdObj = undefined;

  idProgetto = undefined;


// VARIABILI DEL FORM (STEP 2):
  selectedAccoutId = null;
  nomePartner = null;
  ruoloPartner = null;
  telefonoPartner = null;
  altroTelefonoPartner = null;
  emailPartner = null;
  secondaEmailPartner = null;
  sitoWebPartner = null;
  faxPartner = null;




  // VARIABILI DEL FORM (STEP 3):
  selectedReferenteId = null;
  nomeReferente = null;
  cognomeReferente = null;
  nomeAziendaRef = null;
  ruoloReferente = null;
  telefonoReferente = null;
  cellulareReferente = null;
  emailReferente = null;
  faxReferente = null;






//VARIABILI DA ELIMINARE
  // Il flag (check-box) che determina se la pratica è attiva o meno
  flagAttivo=true;
  // I bandi attivi dell'organizzazione, per la combo di scelta del bando del progetto
  bandiAttivi = null;
   // I tipi di partner  dell'organizzazione, per la combo di scelta del bando del progetto
   partnersTipo = null;

   // I tipi di referenti  dell'organizzazione, per la combo di scelta del bando del progetto
   referentiTipo = null;


  // VARIABILI DEL FORM (STEP 2)
  // La pratica salvata allo step 1 e modificabile agli step successivi
  savedPratica = null;
  // Il flag che determina se il passo 1 dello stepper è stato eseguito
  public mandatoryStep1Executed : boolean = false;
  public isMandatoryStep1Executed() : boolean {
    return this.mandatoryStep1Executed;
  }

  onConfiguraPraticaSelect() {
    //this.configuraAttivitaInPraticaComponent.loadPraticaToUpdating();
    //this.partnerTableContentDatabase.refreshPartnerTableNodeStructure(); //carica i partner,il caricamento verrà spostato sul salvataggio dello step 1
  }

  onDettaglioAttivitaSelect() {
    //this.modificaDettaglioAttivitaComponent.loadPraticaToUpdating();
    //this.referentiTableContentDatabase.refreshReferentiTableNodeStructure(); //carica i referenti,il caricamento verrà spostato sul salvataggio dello step 1
  }

  praticheListinoTreeControl(praticheListinoTreeControl: any): any {
    throw new Error("Method not implemented.");
  }
  praticheListinoTreeChecklistSelection(praticheListinoTreeChecklistSelection: any): any {
    throw new Error("Method not implemented.");
  }
  configuraPraticheListinoService(configuraPraticheListinoService: any): any {
    throw new Error("Method not implemented.");
  }

  loadBandiAttiviOrganizzazione(organizzazione){

         // Carica combo dei Bandi
         this.bandiService.getAllByOrganizzazione(organizzazione.id).subscribe(
          data => {
            this.bandiAttivi=data;
            console.log("**** CARICO I BANDI NELLA COMBO STEP 1 ***");
            console.log("**** " + JSON.stringify(data));
            console.log("****  ***  ***  ***");
          }
        )
   /*
    this.clientiAttiviService.getAllClientiAttiviByOrganizzazione(organizzazione).subscribe(
      data => {
        this.clientiAttivi=data;
        this.clientiAttivi.forEach(cliente => {
          cliente.parentesis = this.listaClientiService.getParentesisInfo(cliente);
        });
        this.alreadySpecifiedClienteId = Number.parseInt(sessionStorage.getItem("id_cliente_pratica_attivita"));
        if (this.alreadySpecifiedClienteId) {
          //console.log("this.alreadySpecifiedClienteId: " + this.alreadySpecifiedClienteId);
          this.selectedClienteId = this.alreadySpecifiedClienteId;
        }
        else {
          sessionStorage.removeItem("id_cliente_pratica_attivita");
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
    */
  }

  loadOrganizzazioneAndOtherData() {
    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        this.organizzazioneObj=data;

        // Resetta lo stepper
        this.resetStepper();
        if (this.creaProgettoStepper) {
          this.creaProgettoStepper._steps.forEach(step => {
            step.completed = false;
            step.reset();
          });
          this.creaProgettoStepper.reset();
          this.changeDetectorRef.detectChanges();
        }

        this.loadBandiAttiviOrganizzazione(this.organizzazioneObj);

      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  showError(err) {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: err.error.message
      },
      panelClass: 'custom-warning-container'
    });
  }

  onStep3InitializeEvent(value) : void {
    this.step3PartnerContent.initialize(value != undefined ? value : []);
  }


  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      this.step1Content.setModifyMode(false);
      this.step2Content.setDialogMode(false);
      this.step3Content.setModifyMode(false);
      this.step3PartnerContent.setDialogMode(false);
      /*
      this.step5Component.setModifyMode(false);
      */
      this.loadOrganizzazioneAndOtherData();

      /*
      var compFactory = this.componentFactoryResolver.resolveComponentFactory(UpdateDatiGeneraliPraticaAttivitaComponent);
      var newComponentRef = this.udateDatiGeneraliPratica.createComponent(compFactory);
      this.updateDatiGeneraliPraticaAttivitaComponent = newComponentRef.instance;
      var comp2Factory = this.componentFactoryResolver.resolveComponentFactory(ConfiguraAttivitaInPraticaComponent);
      var newComponentRef2 = this.configuraAttivitaPratica.createComponent(comp2Factory);
      this.configuraAttivitaInPraticaComponent = newComponentRef2.instance;
      var comp3Factory = this.componentFactoryResolver.resolveComponentFactory(ModificaDettaglioAttivitaComponent);
      var newComponentRef3 = this.modificaAttivita.createComponent(comp3Factory);
      this.modificaDettaglioAttivitaComponent = newComponentRef3.instance;
      */
    });
  }


}

