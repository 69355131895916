import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class OrganizzazioniService {

  constructor(private http: HttpClient) {}

  public getAllOrganizzazioni(): Observable<any> {
    return  this.http.get<any>(AppSettings.BASE_URL+'organizzazioni/get-all');
  }

  public saveOrganizzazioni(organizzazioni_data): Observable<any> {
    return  this.http.post<any>(AppSettings.BASE_URL+'organizzazioni/save',organizzazioni_data);
  }

  public saveNuovaOrganizzazione(organizzazione): Observable<any> {
    return  this.http.post<any>(AppSettings.BASE_URL+'organizzazioni/single-save',organizzazione);
  }

  public deleteOrganizzazione(organizzazione): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'organizzazioni/single-delete',organizzazione);
  }

  public getOrganizzazioneByName(organizzazione_name): Observable<any> {
    return  this.http.get<any>(AppSettings.BASE_URL+'organizzazioni/get-by-name?name='+organizzazione_name);
  }

}