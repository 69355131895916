import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtentiOrganizzazioniService } from './utenti-organizzazioni.service';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AppErrors } from '../app.errors';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { OrganizzazioniService } from '../organizzazioni/organizzazioni.service';
import { Router } from '@angular/router';

const NOME_MAX_LENGTH=45;
const EMAIL_MAX_LENGTH=255;

@Component({
  selector: 'app-utenti-organizzazioni',
  templateUrl: './utenti-organizzazioni.component.html',
  styleUrls: ['./utenti-organizzazioni.component.css']
})
export class UtentiOrganizzazioniComponent implements OnInit {

  constructor(private http: HttpClient, 
    private organizzazioniService: OrganizzazioniService, 
    private router: Router, 
    private utentiOrganizzazioniService: UtentiOrganizzazioniService, 
    public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>) { }

  organizzazioni;

  organizzazioneObj;

  organizzazioneSelezionata: string;

  tabellaVisibile: boolean = false;

  utenti_organizzazioni_section_title = "Utenti amministratori per organizzazione";

  utenti_organizzazioni_table_data;

  old_utenti_organizzazioni_table_data;

  utenti_organizzazioni_table_settings = {
    noDataMessage: "Nessun dato da visualizzare",
    columns: {
      email: {
        title: 'Username ( Email )'
      },
      nome: {
        title: 'Nome'
      },
      cognome: {
        title: 'Cognome'
      },
      emailContatto: {
        title: 'Email di contatto'  
      }
    },
    actions: {
      edit: true,
      delete: false,
      columnTitle: ""
    },
    edit: {
      editButtonContent: "Modifica ",
      saveButtonContent: "Aggiorna",
      cancelButtonContent: "Annulla",
      confirmSave: true
    },
    delete: {
      deleteButtonContent: "Elimina ",
      confirmDelete: true
    },
    add: {
      addButtonContent: "Aggiungi nuovo",
      createButtonContent: "Aggiungi",
      cancelButtonContent: "Annulla",
      confirmCreate: true
    },
    attr: {
      class: 'table table-bordered utenti-organizzazioni-table'
    },
    pager: {
      display: true,
      perPage: 10
    },
    mode: "inline",
  };

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkIfDataIsValid(oldData,newData) {
    if (!oldData) { 
      if (!newData || newData.email.length<=0 || newData.email.length>EMAIL_MAX_LENGTH) {
        //la mail dell'utente non può essere vuoto o maggiore di VARCHAR(255)
        return AppErrors.EMAIL_UTENTE_LENGTH_ERROR; 
      }
      if (!this.validateEmail(newData.email)) {
        return AppErrors.EMAIL_LOGIN_INVALIDA_ERROR;
      }
      if (!newData || newData.nome.length>NOME_MAX_LENGTH) {
        //il nome dell'utente non può essere maggiore di VARCHAR(45)
        return AppErrors.NOME_UTENTE_LENGTH_ERROR; 
      }
      if (!newData ||  newData.cognome.length>NOME_MAX_LENGTH) {
        //il cognome dell'utente non può essere maggiore di VARCHAR(45)
        return AppErrors.COGNOME_UTENTE_LENGTH_ERROR; 
      }
      return undefined;
    }
    if(oldData.email!=newData.email) {
      if(newData.email.length<=0 || newData.email.length>EMAIL_MAX_LENGTH) {
        //la mail dell'utente non può essere vuoto o maggiore di VARCHAR(255)
        return AppErrors.EMAIL_UTENTE_LENGTH_ERROR; 
      }
    }
    if(!this.validateEmail(newData.email)) {
      return AppErrors.EMAIL_LOGIN_INVALIDA_ERROR;
    }
    if(oldData.nome!=newData.nome) {
      if(newData.nome.length>NOME_MAX_LENGTH) {
        //il nome dell'utente non può essere maggiore di VARCHAR(100)
        return AppErrors.NOME_UTENTE_LENGTH_ERROR; 
      }
    }
    if(oldData.cognome!=newData.cognome) {
      if(newData.cognome.length>NOME_MAX_LENGTH) {
        //Il codice fiscale dell'organizzazione non può essere maggiore di VARCHAR(16)
        return AppErrors.COGNOME_UTENTE_LENGTH_ERROR; 
      }
    }
    return undefined;
  }

  onSaveConfirm(event) {
    let result=this.checkIfDataIsValid(event.data,event.newData);
    if (result) {
      this.dialog.open(WarningMessageComponent,{
        data: {
          message: result
        },
        panelClass: 'custom-warning-container'
      });
      event.confirm.reject();
    } else {
      this.saveUtenteOrganizzazione(event,event.newData)
    }
  }

  /*
  updateUtenteInTable(utenteAggiornato){
    for(var i = 0; i < this.utenti_organizzazioni_table_data.length; i++)
    {
      if(this.utenti_organizzazioni_table_data[i].email == utenteAggiornato.email)
      {
        this.utenti_organizzazioni_table_data[i].id=utenteAggiornato.id;
      }
    }
  }
  */
  

  saveUtenteOrganizzazione(event, utente) {
    utente.attivo=true;
    utente.organizzazione=this.findOrganizzazione(this.organizzazioneSelezionata);
    this.utentiOrganizzazioniService.saveNuovoUtenteAmministratore(utente).subscribe(
      data => {
        //this.updateUtenteInTable(data);
        this.loadUtentiPerOrganizzazione(this.findOrganizzazione(this.organizzazioneSelezionata));
        event.confirm.resolve(event.newData);
        this.openSnackBar("Dati memorizzati con successo", "Chiudi")
      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        event.confirm.reject();
      }
    );
  }

  nothingChanged() {
    if (!this.utenti_organizzazioni_table_data || !this.old_utenti_organizzazioni_table_data) {
      return true;
    }
    if (this.utenti_organizzazioni_table_data.length != this.old_utenti_organizzazioni_table_data.length) {
      return false;
    }    
    if (JSON.stringify(this.utenti_organizzazioni_table_data).toLowerCase() === JSON.stringify(this.old_utenti_organizzazioni_table_data).toLowerCase()) {
      return true;
    }
    return false;
  }

  
  findOrganizzazione(organizzazioneName) {
    for(var i = 0; i < this.organizzazioni.length; i++) {
      if(this.organizzazioni[i].nome == organizzazioneName) {
        this.organizzazioneObj=this.organizzazioni[i];
        return this.organizzazioni[i];
      }
    }
  }

  organizzazioneChanged(event){
    this.organizzazioneSelezionata=event;
    this.tabellaVisibile=true;
    this.loadUtentiPerOrganizzazione(this.findOrganizzazione(this.organizzazioneSelezionata)); 
  } 

  redirect(newPage) {
    switch(newPage) {
      case "organizzazioni":
        this.router.navigate(['./organizzazioni']);
        break;
      case "utenti-amministratori":
        this.router.navigate(['./utenti-organizzazioni']);
        break;
    }
  }

  loadUtentiPerOrganizzazione(organizzazione){
    this.utentiOrganizzazioniService.getAllUtentiByOrganizzazione(organizzazione).subscribe(
      data => {
        console.log(data);
        this.utenti_organizzazioni_table_data=data;
        this.old_utenti_organizzazioni_table_data=JSON.parse(JSON.stringify(data));
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...");
        console.log(err);
        console.log("*************************************************");
      }
    );
  }

  loadOrganizzazioni() {
    this.organizzazioniService.getAllOrganizzazioni().subscribe(
      data => {
         this.organizzazioni=data;
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...");
        console.log(err);
        console.log("*************************************************");
      }
    );
  }

  ngOnInit() {
    this.loadOrganizzazioni();
  }

}
