import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {ConfirmMessageComponent} from '../modals/confirm-message/confirm-message.component';
import {WarningMessageComponent} from '../modals/warning-message/warning-message.component';
import {GestionePartnerProgettoService} from './gestione-partner-progetto.service';
import {InfiniteScrollComuneSelectorComponentComponent} from './modals/infinite-scroll-comune-selector-component/infinite-scroll-comune-selector-component.component';
import {AggiuntaNuovoStakeholderModalComponent} from './modals/aggiunta-nuovo-stakeholder-modal/aggiunta-nuovo-stakeholder-modal.component';
import {DettaglioGestionePartnerProgettoModalComponent} from './modals/dettaglio-gestione-partner-progetto-modal/dettaglio-gestione-partner-progetto-modal.component';
import {st} from "@angular/core/src/render3";
import {PartnerInScadenzaComponent, StackHolderInScadenza} from "../partner-in-scadenza/partner-in-scadenza.component";


export class Element {
  stakeholder: any;
  sede: any;
  checked: boolean;
}


@Component({
  selector: 'app-gestione-partner-progetto',
  templateUrl: './gestione-partner-progetto.component.html',
  styleUrls: ['./gestione-partner-progetto.component.css']
})
export class GestionePartnerProgettoComponent implements OnInit {

  DENOMINAZIONE_MAX_LENGTH = 200;
  CF_PIVA_MAX_LENGTH = 16;
  TEL_MAX_LENGTH = 45;
  EMAIL_MAX_LENGTH = 100;

  NOME_SEDE_MAX_LENGTH = 250;
  DESCRIZIONE_SEDE_MAX_LENGTH = 250;

  loadingComponent: boolean = true;
  dialogMode: boolean = true;
  importaReferentiMode: boolean = false;

  progetto = undefined;
  comuni = [];
  tipologie = [];

  inscadenza: StackHolderInScadenza[] = [];
  dataSource: Element[] = [];
  newElements: Element[] = [];
  displayedColumns = ['checked', 'stakeholder', 'sede'];
  allChecked: boolean = false;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private partnerInScadenzaComponent: PartnerInScadenzaComponent,
    private gestionePartnerProgettoService:GestionePartnerProgettoService,
    public confirmDialog: MatDialog,
    private comuneSelectorDialog: MatDialog,
    private thisDialogRef: MatDialogRef<GestionePartnerProgettoComponent>
  ) {
  }

  /**
   * Carica i partner presenti nel database di GESTORI, nel formato previsto dal wrapper esterno.
   * @param nomeProgetto Nome del progetto di riferimento.
   * @param _callback Azione successiva.
   */
  loadPartnerDaGestori(nomeProgetto, _callback): void {
    this.gestionePartnerProgettoService.getPartnerFromGestori(null, nomeProgetto).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError("Errore nel caricamento dei partner da GESTORI.");
      }
    );
  }

  /**
   * Carica le sedi principali di ogni stakeholder salvato nel database, una per ogni stakeholder.
   * Per come avviene il salvataggio degli stakeholder, ad ogni stakeholder è sempre associata una sede principale.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadSedi(idOrganizzazione, _callback): void {
    this.gestionePartnerProgettoService.getSedi(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica i comuni dal database.
   * @param _callback Azione successiva.
   */
  loadComuni(_callback): void {
    this.gestionePartnerProgettoService.getComuni().subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica un comune dal database, dato il CAP.
   * @param cap Cap di input.
   * @param _callback Azione successiva.
   */
  loadComune(cap, _callback): void {
    this.gestionePartnerProgettoService.getComune(cap).subscribe(
      data => {
        _callback(data);
      },
      err => {
        _callback(undefined);
      }
    );
  }

  /**
   * Carica le tipologie di stakeholder dal database.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadTipologie(idOrganizzazione, _callback): void {
    this.gestionePartnerProgettoService.getTipologie(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica le sedi principali degli stakeholder collegati al progetto (partner) dal database.
   * @param idProgetto Id del progetto di riferimento.
   * @param _callback Azione successiva.
   */
  loadSediProgetto(idProgetto, _callback): void {
    this.gestionePartnerProgettoService.getSediProgetto(idProgetto).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Salva un nuovo stakeholder sul database.
   * @param index Indice dello stakeholder nella lista di riferimento. Serve per tenere traccia dell'indice posizionale
   *              nella lista di provenienza dello stakeholder che si sta salvando, per poter effettuare un'azione a fine lista.
   * @param stakeholder Stakeholder da salvare.
   * @param _callback Azione successiva.
   */
  saveStakeholder(index, stakeholder, _callback): void {
    this.gestionePartnerProgettoService.savePartner(stakeholder).subscribe(
      data => {
        _callback(index, data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Salva una nuova sede sul database. E' utilizzato per salvare la sede principale di un nuovo stakeholder.
   * @param stakeholderIndex Indice dello stakeholder nella lista di riferimento. Serve per tenere traccia dell'indice posizionale
   *                         nella lista di provenienza dello stakeholder che si sta salvando, per poter effettuare un'azione a fine lista.
   * @param sede Sede da salvare.
   * @param _callback Azione successiva.
   */
  saveSede(stakeholderIndex, sede, _callback): void {
    this.gestionePartnerProgettoService.saveSedeStakeholder(sede).subscribe(
      data => {
        _callback(stakeholderIndex, data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Salva una nuova relazione progetto-stakeholder sul database.
   * @param index Indice della relazione nella lista di riferimento. Serve per tenere traccia dell'indice posizionale
   *              nella lista di provenienza della relazione che si sta salvando, per poter effettuare un'azione a fine lista.
   * @param relation Relazione da salvare.
   * @param _callback Azione successiva.
   */
  saveProgettoStakeholder(index, relation, _callback): void {
    this.gestionePartnerProgettoService.saveProgettoPartner(relation).subscribe(
      data => {
        _callback(index, data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Resetta il progetto, eliminando tutti i dati salvati a partire dai partner collegati al progetto. Serve come azione preliminare per
   * poter salvare una nuova lista di partner. Performa un'eliminazione a cascata di tutti i dati collegati ai partner di progetto.
   * @param idProgetto Id del progetto di riferimento.
   * @param _callback Azione successiva.
   */
  resetProgetto(idProgetto, _callback): void {
    this.gestionePartnerProgettoService.resetProgetto(idProgetto).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Seleziona tutti gli elementi in tabella.
   * @param event Evento scatenato.
   */
  onCheckAll(event): void {
    if (event != undefined) event.preventDefault();
    this.allChecked = event != undefined ? !this.allChecked : true;
    this.dataSource.forEach(d => d.checked = this.allChecked);
  }

  /**
   * Apre il dialog di selezione del comune per un elemento in tabella.
   * @param element Elemento in tabella.
   */
  onSelectComune(element): void {
    const comuneSelectorDialogRef = this.comuneSelectorDialog.open(InfiniteScrollComuneSelectorComponentComponent, {
      data: this.comuni
    });

    comuneSelectorDialogRef.afterClosed().subscribe(result => {
      if (result != undefined) element.sede.comune = result;
    });
  }

  /**
   * Salva tutti i partner selezionati in tabella. Verifica che non vi siano errori e, in caso positivo, performa il salvataggio.
   * @param fromReferentiComponent Flag che indica se il metodo sia stato chiamato dallo step di definizione referenti o meno.
   *                               Se TRUE, salva soltanto i nuovi stakeholder selezionati, senza collegarli come partner al progetto.
   *                               Se FALSE, salva i nuovi stakeholder selezionati, dopodiché li collega come partner al progetto.
   * @param _callback Azione successiva.
   */
  onSaveAll(fromReferentiComponent: boolean, _callback): void {
    const errorMessage = this.getErrorMessage(fromReferentiComponent);
    if (errorMessage != undefined) this.showError(errorMessage);
    else {
      if (fromReferentiComponent === false) {
        this.askConfirm("Vuoi salvare i partner selezionati?", "Il salvataggio comporterà la memorizzazione dei partner e il collegamento al progetto.", () => {
          this.saveNuoviStakeholder(() => {
            const dataRef = this.dataSource.filter(filtered => filtered.checked === true);
            dataRef.forEach((element, i) => {
              this.saveProgettoStakeholder(i, {
                id: undefined,
                progetto: this.progetto,
                stakeholder: element.stakeholder,
                ruoloStakeholder: undefined
              }, () => {
                if (i === dataRef.length - 1) _callback();
              });
            });
          });
        });
      } else {
        if (this.dataSource == undefined || this.dataSource.length === 0) _callback();
        else this.saveNuoviStakeholder(() => {
          _callback();
        });
      }
    }
  }

  /**
   * Salva tutti i partner selezionati in tabella. Verifica che non vi siano errori e, in caso positivo, performa il salvataggio.
   * @param _callback Azione successiva.
   */
  onSaveAllFromGestioneProgetto(_callback): void {
    const errorMessage = this.getErrorMessage(false);
    if (errorMessage != undefined) this.showError(errorMessage);
    else {
      this.askConfirm("Vuoi salvare i partner selezionati?", "Il salvataggio comporterà la memorizzazione dei partner e il collegamento al progetto.", () => {
        this.saveNuoviStakeholder(() => {
          const dataRef = this.newElements.filter(filtered => filtered.checked === true);
          if (dataRef == undefined || dataRef.length == 0) {
            _callback();
          } else {
            dataRef.forEach((element, i) => {
              this.saveProgettoStakeholder(i, {
                id: undefined,
                progetto: this.progetto,
                stakeholder: element.stakeholder,
                ruoloStakeholder: undefined
              }, () => {
                if (i === dataRef.length - 1) _callback();
              });
            });
          }
        });
      });
    }
  }

  /**
   * Metodo di supporto al salvataggio. Salva gli stakeholder e la relativa sede principale tra quelli selezionati in tabella e non ancora
   * presenti nel database.
   * @param _callback Azione successiva.
   */
  private saveNuoviStakeholder(_callback): void {
    const dataRef = this.dataSource.filter(function (filtered) {
      return filtered.checked === true && filtered.stakeholder.id === undefined
    });
    if (dataRef == undefined || dataRef.length === 0) _callback();
    else {
      dataRef.forEach(element => {
        const index = dataRef.indexOf(element);
        this.saveStakeholder(index, element.stakeholder, (indexRef, sh) => {
          element.stakeholder = sh;
          element.sede.stakeholder = element.stakeholder;
          this.saveSede(indexRef, element.sede, (indexLastRef, sede) => {
            if (indexLastRef === dataRef.length - 1) _callback();
          });
        });
      });
    }
  }

  /**
   * Genera un messaggio di errore per impedire il salvataggio.
   * Se il risultato è UNDEFINED, si può proseguire col salvataggio.
   * Se il risultato è valorizzato, occorre impedire il salvataggio e mostrare l'errore.
   * @param fromReferentiComponent Flag che indica se il metodo sia stato chiamato dallo step di definizione referenti o meno. Serve per particolarizzare i controlli.
   */
  private getErrorMessage(fromReferentiComponent: boolean): string {
    if (fromReferentiComponent === false && (this.dataSource == undefined || this.dataSource.length === 0 ||
      this.dataSource.find(found => found.checked === true) == undefined)) {
      return "Nessun partner indicato.";
    }
    var msg = undefined;
    const data = this.dataSource.filter(filtered => filtered.checked === true);
    data.forEach(element => {
      if (element.stakeholder.denominazione == undefined || element.stakeholder.denominazione.length === 0) {
        msg = "Impossibile memorizzare partner privi di nome.";
        return;
      }
      if (element.stakeholder.denominazione.length > this.DENOMINAZIONE_MAX_LENGTH) {
        msg = "Il nome del partner è troppo lungo.";
        return;
      }
      if (element.stakeholder.codiceFiscale != undefined && element.stakeholder.codiceFiscale.length > this.CF_PIVA_MAX_LENGTH) {
        msg = "Il codice fiscale inserito è troppo lungo.";
        return;
      }
      if (element.stakeholder.partitaIva != undefined && element.stakeholder.partitaIva.length > this.CF_PIVA_MAX_LENGTH) {
        msg = "La partita IVA inserita è troppo lunga.";
        return;
      }
      if ((element.stakeholder.telefono != undefined && element.stakeholder.telefono.length > this.TEL_MAX_LENGTH) ||
        (element.stakeholder.altroTelefono != undefined && element.stakeholder.altroTelefono.length > this.TEL_MAX_LENGTH)) {
        msg = "I numeri di telefono inseriti sono troppo lunghi.";
        return;
      }
      if ((element.stakeholder.email != undefined && element.stakeholder.email.length > this.EMAIL_MAX_LENGTH) ||
        (element.stakeholder.email2 != undefined && element.stakeholder.email2.length > this.EMAIL_MAX_LENGTH) ||
        (element.stakeholder.pec != undefined && element.stakeholder.pec.length > this.EMAIL_MAX_LENGTH)) {
        msg = "Gli indirizzi e-mail inseriti sono troppo lunghi.";
        return;
      }
      if (data.filter(filteredData => filteredData.stakeholder.denominazione === element.stakeholder.denominazione).length > 1) {
        msg = "Impossibile memorizzare due o più stakeholder con lo stesso nome.";
        return;
      }
      if (element.stakeholder.tipologiaStakeholder == undefined || element.stakeholder.tipologiaStakeholder.id == undefined) {
        msg = "Nessuna tipologia indicata per il partner " + element.stakeholder.denominazione + ".";
        return;
      }
      if (element.sede.nome == undefined || element.sede.nome.length === 0) {
        msg = "Nessun nome di sede principale fornito per il partner " + element.stakeholder.denominazione + ".";
        return;
      }
      if (element.sede.nome.length > this.NOME_SEDE_MAX_LENGTH) {
        msg = "Il nome della sede principale fornito per il partner " + element.stakeholder.denominazione + " è troppo lungo.";
        return;
      }
      if (element.sede.descrizione != undefined && element.sede.descrizione.length > this.DESCRIZIONE_SEDE_MAX_LENGTH) {
        msg = "La descrizione della sede principale fornita per il partner " + element.stakeholder.denominazione + " è troppo lunga.";
        return;
      }
      if (element.sede.comune == undefined || element.sede.comune.codiceIstat == undefined || element.sede.comune.codiceIstat.length === 0) {
        msg = "Nessun riferimento al comune fornito per la sede principale del partner " + element.stakeholder.denominazione + ".";
        return;
      }
    });
    return msg;
  }

  /**
   * Aggiunge un nuovo elemento in tabella. Verifica che vi siano ancora partner che possono essere importati, dopodiché apre il dialog
   * di selezione di uno stakeholder. In esso sono riportati solo gli stakeholder non ancora importati nella lista partner.
   * Selezionato il partner, esso viene aggiunto alla lista.
   */
  onAddElement(): void {
    this.loadSedi(this.progetto.organizzazione.id, (sediRef) => {
      var tmp = [];
      sediRef.forEach(sedeRef => {
        if (this.dataSource.find(found => found.sede.id === sedeRef.id) == undefined) tmp.push(sedeRef.stakeholder)
      });
      if (tmp.length === 0) this.showError("Tutti i partner disponibili sono stati importati.");
      else {
        const dialogRef = this.dialog.open(AggiuntaNuovoStakeholderModalComponent, {
          data: tmp
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            result.tipologiaStakeholder = this.tipologie.find(foundTipo => foundTipo.nome.trim().toUpperCase() === result.tipologiaStakeholder.nome.trim().toUpperCase());
            this.dataSource.push({
              stakeholder: result,
              sede: sediRef.find(sedeFound => sedeFound.stakeholder.id === result.id),
              checked: true
            });
            this.newElements.push({
              stakeholder: result,
              sede: sediRef.find(sedeFound => sedeFound.stakeholder.id === result.id),
              checked: true
            });
            this.dataSource = this.dataSource.concat([]);
          }
        });
      }
    });
  }

  onDettaglioGestione(): void {
    const dialogRef = this.dialog.open(DettaglioGestionePartnerProgettoModalComponent, {
      data: {
        progetto: this.progetto
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result == true) {
        this.openSnackBar("Dettaglio di gestione dei partner di progetto salvato con successo.", "Chiudi");
      }
    });
  }

  /**
   * Rimuove un elemento dalla lista partner.
   * @param element Elemento da rimuovere.
   */
  onRemoveElement(element: Element): void {
    this.dataSource.splice(this.dataSource.indexOf(element), 1);
    this.dataSource = this.dataSource.concat([]);
  }

  /**
   * Salva tutti i dati da gestione progetto. Resetta il progetto eliminando tutti i dati relativi, dopodiché salva la
   * nuova lista.
   */
  onSaveFromGestioneProgetto(): void {
    /*
    this.askConfirm("Vuoi davvero salvare una nuova lista di partner di progetto?",
    "Tutti i dati collegati al progetto e precedentemente salvati verranno eliminati.", () => {
      this.resetProgetto(this.progetto.id, (res) => {
        if(res != undefined && res === true) {
          this.onSaveAll(false, () => {
            this.thisDialogRef.close(true);
          });
        }
      });
    });
    */
    this.askConfirm("Vuoi davvero salvare una nuova lista di partner di progetto?",
      "Tutti i dati collegati al progetto e precedentemente salvati verranno sovrascritti.", () => {
        this.onSaveAllFromGestioneProgetto(() => {
          this.thisDialogRef.close(true);
        });
      });
  }

  /**
   * Setta il modo di apertura del component.
   * @param mode Modo di apertura. Se TRUE, indica che il component è aperto in un dialog, FALSE altrimenti.
   */
  setDialogMode(mode: boolean): void {
    this.dialogMode = mode;
  }

  /**
   * Inizializza i comuni delle sedi principali tramite i CAP provenienti da Gestori.
   */
  initComuniSediByCap(): void {
    this.dataSource.forEach(element => {
      if (element.sede.comune == undefined && element.stakeholder.cap != undefined) {
        this.loadComune(element.stakeholder.cap, (comuneRef) => {
          if (comuneRef != undefined) element.sede.comune = this.comuni.find(found => found.codiceIstat === comuneRef.codiceIstat);
        });
      }
    });
  }

  /**
   * Inizializza i dati tramite il database. Inizializza, in cascata, tipologie di stakeholder, comuni e stakeholder con relative sedi.
   * @param _callback Azione successiva.
   */
  initDataFromDatabase(_callback): void {
    this.loadTipologie(this.progetto.organizzazione.id, (tipologieRef) => {
      this.tipologie = tipologieRef;
      this.loadComuni((comuniList) => {
        this.comuni = comuniList;
        this.loadSedi(this.progetto.organizzazione.id, (sedi) => {
          sedi.forEach(sede => {
            var shRef = this.dataSource.find(found => found.stakeholder.denominazione.trim().toUpperCase() === sede.stakeholder.denominazione.trim().toUpperCase());
            if (shRef != undefined) {
              shRef.stakeholder = sede.stakeholder;
              shRef.sede = sede;
              shRef.stakeholder.tipologiaStakeholder = this.tipologie.find(found => found.id === shRef.stakeholder.tipologiaStakeholder.id);
            }
          });
          _callback();
        });
      });
    });
  }

  /**
   * Inizializza i dati provenienti dal parent e memorizzati nel session storage.
   */
  initDataFromParent(): void {
    const item = sessionStorage.getItem("definizione_partner_progetto");
    if (item == undefined) this.showError("Errore nell'inizializzazione dei dati.");
    else {
      this.progetto = JSON.parse(item);
      sessionStorage.removeItem("definizione_partner_progetto");
    }
  }

  /**
   * Inizializza i dati per l'utilizzo del component nello step di import partner da Gestori.
   * @param _callback Azione successiva.
   */
  initDataFromImportProgettoGestori(_callback): void {
    this.loadPartnerDaGestori(this.progetto.nome, (partners) => {
      partners.forEach(p => {
        const sh = {
          id: undefined,
          denominazione: p.account_name,
          codiceFiscale: undefined,
          partitaIva: undefined,
          indirizzo: p.indirizzo,
          citta: p.citta,
          cap: p.cap,
          stato: undefined,
          nazione: undefined,
          codiceDestinatario: undefined,
          telefono: p.phone,
          altroTelefono: p.other_phone,
          email: p.email_1,
          email2: p.email_2,
          pec: undefined,
          organizzazione: this.progetto.organizzazione,
          tipologiaStakeholder: undefined
        };
        this.dataSource.push({
          stakeholder: sh,
          sede: {id: undefined, nome: p.indirizzo, descrizione: "Principale", comune: undefined, stakeholder: sh},
          checked: false
        });
      });
      this.dataSource = this.dataSource.concat([]);
      _callback();
    });
  }

  /**
   * Inizializza i dati per l'utilizzo del component nello step di import referenti da Gestori.
   * @param list Lista proveniente dallo step di import referenti.
   */
  initDataFromParentList(list): void {
    this.dataSource = [];
    list.forEach(p => {
      const sh = {
        id: undefined,
        denominazione: p.account_name,
        codiceFiscale: undefined,
        partitaIva: undefined,
        indirizzo: p.indirizzo,
        citta: p.citta,
        cap: p.cap,
        stato: undefined,
        nazione: undefined,
        codiceDestinatario: undefined,
        telefono: p.phone,
        altroTelefono: p.other_phone,
        email: p.email_1,
        email2: p.email_2,
        pec: undefined,
        organizzazione: this.progetto.organizzazione,
        tipologiaStakeholder: undefined
      };
      this.dataSource.push({
        stakeholder: sh,
        sede: {id: undefined, nome: p.indirizzo, descrizione: "Principale", comune: undefined, stakeholder: sh},
        checked: false
      });
    });
    this.dataSource = this.dataSource.concat([]);
  }

  /**
   * Metodo di support per l'inizializzazione dei dati per l'utilizzo del component nello step di import referenti da Gestori.
   */
  initForImportaReferentiMode(): void {
    this.initComuniSediByCap();
    var tmp = [];
    this.dataSource.forEach(element => {
      if (tmp.find(found => found.stakeholder.denominazione === element.stakeholder.denominazione) == undefined) tmp.push(element);
    });
    this.dataSource = tmp.concat([]);
    this.onCheckAll(undefined);
  }

  /**
   * Inizializza i dati e il datasource dal database. Serve per inizializzare i dati per l'utilizzo del component in gestione progetto.
   * @param _callback Azione successiva.
   */
  initDataSourceFromDatabase(_callback): void {
    this.loadTipologie(this.progetto.organizzazione.id, (tipologieRef) => {
      this.tipologie = tipologieRef;
      this.loadComuni((comuniList) => {
        this.comuni = comuniList;
        this.loadSediProgetto(this.progetto.id, (sedi) => {
          this.dataSource = [];
          sedi.forEach(sedeRef => {
            sedeRef.stakeholder.tipologiaStakeholder = this.tipologie.find(foundTipo => foundTipo.nome.trim().toUpperCase() === sedeRef.stakeholder.tipologiaStakeholder.nome.trim().toUpperCase());
            console.log("sediref " + JSON.stringify(sedeRef));
            this.dataSource.push({stakeholder: sedeRef.stakeholder, sede: sedeRef, checked: true});
          });
          _callback();
        });
      });
    });
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String): void {
    this.dialog.open(WarningMessageComponent, {
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  /**
   * Mostra un messaggio di richiesta di conferma all'utente.
   * @param message Messaggio di richiesta di conferma.
   * @param submessage Messaggio inferiore.
   * @param _callback Azione successiva, in caso di conferma della richiesta.
   */
  askConfirm(message: string, submessage: string, _callback): void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent, {
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res) _callback();
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  /**
   * Avvia l'inizializzazione dei dati.
   * @param data Dati provenienti dai parent.
   *             Se UNDEFINED, il component è chiamato dallo step di import partner da Gestori.
   *             Se valorizzato, il component è chiamato dallo step di import referenti da Gestori o come dialog da gestione progetto.
   */
  initialize(data): void {
    this.initDataFromParent();
    if (data == undefined) {
      this.initDataFromImportProgettoGestori(() => {
        this.initDataFromDatabase(() => {
          this.initComuniSediByCap();
        })
      });
    } else {
      this.importaReferentiMode = true;
      this.initDataFromParentList(data);
      this.initDataFromDatabase(() => {
        this.initForImportaReferentiMode();
      })
    }
  }


  ngOnInit() {
    /* Inizializzazione automatica, solo nel caso in cui il component è richiamato da un dialog. */
    if (this.dialogMode === true) {
      this.displayedColumns.push('add/remove');
      this.displayedColumns.push('settings');
      this.initDataFromParent();
      this.initDataSourceFromDatabase(() => {
        this.initComuniSediByCap();
        this.allChecked = true;
        this.loadingComponent = false;
      });
    }
    //this.getPartnterInScadenza();
    this.inscadenza = this.partnerInScadenzaComponent.getPartnterInScadenza(this.progetto.id);
  }

}
