import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable()
export class TipoDiFinanziamentoService {

    constructor(private http: HttpClient) {}

    public getAllByOrganizzazione(organizzazioneId): Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'tipidifinanziamento/get-all-by-organizzazione?organizzazioneId='+organizzazioneId);
    }

    public saveTipoDiFinanziamento(tipoDiFinanziamento): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'tipidifinanziamento/save-tipodifinanziamento', tipoDiFinanziamento);
    }

    public updateTipoDiFinanziamento(tipoDiFinanziamento): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'tipidifinanziamento/update-tipodifinanziamento', tipoDiFinanziamento);
    }

    public deleteTipoDiFinanziamento(nomeTipoDiFinanziamento, idOrganizzazione): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'tipidifinanziamento/delete-tipodifinanziamento?nomeTipoDiFinanziamento='+nomeTipoDiFinanziamento+'&idOrganizzazione='+idOrganizzazione,undefined);
    }

}