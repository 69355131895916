import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SharedDataService } from 'src/app/common-services/shared-data.service';
import { UtilsService } from 'src/app/common-services/utils.service';
import { RotellinaService } from 'src/app/rotellina-dialog/rotellina.service';
import { MonitoraggioProgettoService } from '../../monitoraggio-progetto.service';

@Component({
  selector: 'app-monitora-sal-modal',
  templateUrl: './monitora-sal-modal.component.html',
  styleUrls: ['./monitora-sal-modal.component.css']
})
export class MonitoraSalModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MonitoraSalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedDataService: SharedDataService,
    private rotella: RotellinaService,
    public utilsService: UtilsService,
    private monitoraggioProgettoService : MonitoraggioProgettoService) {

  }
  /*
  public testdata = [{
    denominazione_partner: "Università degli studi Magna Graecia di Catanzaro",
    nome_sede:"Viale Europa Campus Universitario",
    data_inizio : "2018-09-01",
    nome:"SAL 1",
    tipo_versione:"B"

  },{
    denominazione_partner: "Biotecnomed S.c.ar.l.",
    nome_sede:"Viale Europa, Loc. Germaneto",
    data_inizio : "2018-09-01",
    nome:"SAL 1",
    tipo_versione:"B"
  }];*/

  public dataSource = [];
  public  displayedColumns = ["denominazione_partner","nome_sede","data_inizio","nome","tipo_versione"];
  public parseTitologia(tipologia:"B"|"P"|"C"): string{
    switch (tipologia){
      case "B":
        return "Bozza";
        
      case "P":
        return "Presentata"
        
      case "C":
        return "Certificata";
        
      default:
        return ""
    }
        
    
  }
  ngOnInit() {
    this.loadData();
  }
    composedUniqueIndex(el:any){
      return `${el.denominazione_partner}-${el.nome}-${el.tipo_versione}`
    };

    async loadData(){
    this.rotella.openDialog();
    console.log("dati inizializzati");
    const dataFetch =  await this.monitoraggioProgettoService.getSalsByProgettoId(this.data.progettoID).toPromise()
    const ids = dataFetch.map(o => this.composedUniqueIndex(o));
    
    const filtered = dataFetch.filter((el, index) => !ids.includes(this.composedUniqueIndex(el), index + 1))

    console.log(filtered);
    {
      const data = filtered.sort((a,b)=>a.nome.localeCompare(b.nome, undefined, {sensitivity: 'base'}));
      let sal_group_ordinated_by_denominazione_partner = data.reduce((acc, current)=> {
        if(acc[current.nome] == undefined){
          acc[current.nome] = [];
        }
        acc[current.nome].push(current);
        return acc;
      },{});

      let data2 = Object.entries(sal_group_ordinated_by_denominazione_partner).map(([key, value]:[string,Array<any>]) => {
        value.sort((a,b)=>a.denominazione_partner.localeCompare(b.denominazione_partner, undefined, {sensitivity: 'base'}));
        
        let denominazione_partners = value.reduce((acc,current)=>{
          if(acc[current.denominazione_partner] == undefined) acc[current.denominazione_partner] = [];
          
          if(current.tipo_versione == "B") acc[current.denominazione_partner].B = current;
          if(current.tipo_versione == "P") acc[current.denominazione_partner].P = current;
          if(current.tipo_versione == "C") acc[current.denominazione_partner].C = current;

          return acc;
        },{});
        return denominazione_partners;
      })
      
    
      let array_sortato = [];

      data2.forEach(value=>{
        Object.entries(value).forEach(([k,v]:any)=>{
          let array = [];
          if(v.B) array.push(v.B);
          if(v.P) array.push(v.P);
          if(v.C) array.push(v.C);
          return array_sortato.push(...array);
        })
      });

      
      console.log(array_sortato);
      this.dataSource = array_sortato;
    }
  


   // this.dataSource = filtered;
    console.log(this.dataSource);
    this.rotella.closeDialog();
  }
/**
 * id_piano_dei_sal = sdr_piano_dei_sal(progetto_id)

nome|id = sdr_sal(id_piano_dei_sal ) e ordinarli per data_inizio


tipo_versione : B|P|C = sdr_rendicontazioni(nome,id)

nome, tipo_versione, data_inzio, data_fine_chiuso,



SELECT tipo_versione,id_progetto, nome, data_inizio FROM sdr_rendicontazione INNER JOIN (SELECT id_progetto, nome, data_inizio, sdr_sal.id as id FROM sdr_piano_dei_sal 
INNER JOIN sdr_sal ON  sdr_piano_dei_sal.id = sdr_sal.id_piano_dei_sal where sdr_piano_dei_sal.id_progetto = ID_PROGETTO) as sal ON sdr_rendicontazione.id_sal=sal.id  ORDER BY data_inizio 


SELECT 
  denominazione_partner ,nome_sede ,data_inizio ,nome,tipo_versione
FROM 
  (
    SELECT 
      tipo_versione, 
      id_progetto, 
      nome, 
      data_inizio, 
      id_sede_stakeholder 
    FROM 
      sdr_rendicontazione 
      INNER JOIN (
        SELECT 
          id_progetto, 
          nome, 
          data_inizio, 
          sdr_sal.id as id 
        FROM 
          sdr_piano_dei_sal 
          INNER JOIN sdr_sal ON sdr_piano_dei_sal.id = sdr_sal.id_piano_dei_sal 
        where 
          sdr_piano_dei_sal.id_progetto = 5
      ) as sal ON sdr_rendicontazione.id_sal = sal.id 
    ORDER BY 
      data_inizio
  ) as sal

  INNER JOIN (
SELECT 
  sdr_stakeholder.id as stakeholder_id, 
  sdr_sede_stakeholder.id as sede_stakeholder_id, 
  nome as nome_sede, 
  denominazione as denominazione_partner 
FROM 
  sdr_sede_stakeholder 
  INNER JOIN sdr_stakeholder ON sdr_sede_stakeholder.id_stakeholder = sdr_stakeholder.id
) as sede  ON sal.id_sede_stakeholder = sede.sede_stakeholder_id ORDER BY data_inizio

 */
}
