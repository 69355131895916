import { Component, OnInit } from '@angular/core';
import { OrganizzazioniService } from 'src/app/organizzazioni/organizzazioni.service';
import { UserStorage } from 'src/app/core_modules/user.storage';
import { MatDialog, MatSnackBar, MatDialogRef, DateAdapter } from '@angular/material';
import { GestioneStakeholdersService } from '../../gestione-stakeholders.service';
import { GestionePartnerProgettoService } from 'src/app/gestione-partner-progetto/gestione-partner-progetto.service';
import { ConfirmMessageComponent } from 'src/app/modals/confirm-message/confirm-message.component';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {ReplaySubject} from "rxjs";
import {ImportWbsModalService} from "../../../gestione-wbs/modals/import-wbs-modal/import-wbs-modal.service";
import {GenerazioneReportDialogService} from "../../../generazione-report-dialog/generazione-report-dialog.service";

@Component({
  selector: 'app-gestione-persona-modal',
  templateUrl: './gestione-persona-modal.component.html',
  styleUrls: ['./gestione-persona-modal.component.css']
})
export class GestionePersonaModalComponent implements OnInit {

  NOME_COGNOME_MAX_LENGTH = 100;
  CF_PIVA_MAX_LENGTH = 16;
  CITTA_STATO_NAZIONE_MAX_LENGTH = 100;
  TEL_MAX_LENGTH = 45;
  EMAIL_MAX_LENGTH = 100;
  DIPARTIMENTO_RUOLO_MAX_LENGTH = 45;

  constructor(
    private organizzazioniService: OrganizzazioniService,
    private userStorage: UserStorage,
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    private adapter: DateAdapter<any>,
    public snackBar: MatSnackBar,
    private gestioneStakeholdersService: GestioneStakeholdersService,
    private generazioneReportDialogService: GenerazioneReportDialogService,
    private importWbsModalService: ImportWbsModalService,
    private comuneSelectorDialog: MatDialog,
    private gestionePartnerProgettoService: GestionePartnerProgettoService,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    public thisDialogRef: MatDialogRef<GestionePersonaModalComponent>
  ) {
    this.adapter.setLocale("it");
  }

  persona = undefined;
  editMode: boolean = false;
  loadingComponent: boolean = true;

  file: File = undefined;
  uploadedFileName: string = undefined;
  percentage = undefined;

  onSave() : void {
    const msg = this.checkForErrors();
    if(msg != undefined) this.showError(msg);
    else this.thisDialogRef.close(this.persona);
  }

  checkForErrors() : string {
    if(this.persona.nome == undefined || this.persona.cognome == undefined) return "Impossibile salvare una persona priva di nome o cognome.";
    if(this.persona.nome.length === 0 || this.persona.cognome.length === 0) return "Impossibile salvare una persona priva di nome o cognome.";
    if(this.persona.nome.length > this.NOME_COGNOME_MAX_LENGTH) return "Il nome inserito è troppo lungo";
    if(this.persona.cognome.length > this.NOME_COGNOME_MAX_LENGTH) return "Il nome inserito è troppo lungo";
    if(this.persona.codiceFiscale != undefined && this.persona.codiceFiscale.length > this.CF_PIVA_MAX_LENGTH) return "Il codice fiscale inserito è troppo lungo.";
    if(this.persona.partitaIva != undefined && this.persona.partitaIva.length > this.CF_PIVA_MAX_LENGTH) return "La partita IVA inserita è troppo lunga.";
    if((this.persona.cittaDiNascita != undefined && this.persona.cittaDiNascita.length > this.CITTA_STATO_NAZIONE_MAX_LENGTH) ||
      (this.persona.statoDiNascita != undefined && this.persona.statoDiNascita.length > this.CITTA_STATO_NAZIONE_MAX_LENGTH) ||
      (this.persona.nazioneDiNascita != undefined && this.persona.nazioneDiNascita.length > this.CITTA_STATO_NAZIONE_MAX_LENGTH))
      return "I dati di nascita inseriti sono troppo lunghi.";
    if((this.persona.telefonoCasa != undefined && this.persona.telefonoCasa.length > this.TEL_MAX_LENGTH) ||
      (this.persona.telefonoUfficio != undefined && this.persona.telefonoUfficio.length > this.TEL_MAX_LENGTH) ||
      (this.persona.cellularePersonale != undefined && this.persona.cellularePersonale.length > this.TEL_MAX_LENGTH) ||
      (this.persona.cellulareUfficio != undefined && this.persona.cellulareUfficio.length > this.TEL_MAX_LENGTH) ||
      (this.persona.cellulareAltro != undefined && this.persona.cellulareAltro.length > this.TEL_MAX_LENGTH))
      return "I recapiti telefonici inseriti sono troppo lunghi.";
    if((this.persona.emailPersonale != undefined && this.persona.emailPersonale.length > this.EMAIL_MAX_LENGTH) ||
      (this.persona.emailUfficio != undefined && this.persona.emailUfficio.length > this.EMAIL_MAX_LENGTH))
      return "I recapiti e-mail inseriti sono troppo lunghi.";
    if(this.persona.dipartimento != undefined && this.persona.dipartimento.length > this.DIPARTIMENTO_RUOLO_MAX_LENGTH)
      return "Il nome del dipartimento inserito è troppo lungo.";
    if(this.persona.ruolo != undefined && this.persona.ruolo.length > this.DIPARTIMENTO_RUOLO_MAX_LENGTH)
      return "Il nome del ruolo inserito è troppo lungo.";
    return undefined;
  }

  initDataFromParent() : void {
    const ref = sessionStorage.getItem("gestione_persona");
    if(ref != undefined) {
      this.persona = JSON.parse(ref);
      if(this.persona.dataDiNascita != undefined)
        this.persona.dataDiNascita = new Date(this.persona.dataDiNascita);
      this.editMode = true;
      sessionStorage.removeItem("gestione_persona");
    }
    else {
      this.persona = {
        cognome: undefined, nome: undefined, fittizia: false, codiceFiscale: undefined, partitaIva: undefined, dataDiNascita: undefined, cittaDiNascita: undefined,
        nazioneDiNascita: undefined, telefonoCasa: undefined, telefonoUfficio: undefined, cellularePersonale: undefined, cellulareUfficio: undefined,
        cellulareAltro: undefined, emailPersonale: undefined, emailUfficio: undefined, dipartimento: undefined, ruolo: undefined
      }
    }
  }

  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.initDataFromParent();
    this.loadingComponent = false;
  }

  onProcess() : void {
    /*if(this.file == undefined || this.uploadedFileName == undefined) {
      this.showError("File assente o non caricato.");
    }
    this.extracting = true;
    this.extract(this.uploadedFileName, (data) => {
      this.extracting = false;
      this.records = [];
      if(data != undefined) {
        this.records = data.recordsPrimoLivello != undefined ? data.recordsPrimoLivello : [];
      }
    });*/
  }

  onFileSelected() : void {
    const inputNode: any = document.querySelector('#file');
    if(inputNode != undefined && inputNode.files != undefined) {
      this.file = inputNode.files[0];
    }
    if(this.file != undefined) {
      this.upload(this.file, (res) => {
        this.uploadedFileName = res;
        this.persona.curriculum = this.uploadedFileName;
        /*this.gestioneStakeholdersService.saveCurriculum(this.uploadedFileName, this.persona.id).subscribe(
        data => {
          //_callback(data);
        },
        err => {
          this.showError(err.error.message);
        });*/
      });
    }
  }

  upload(file: File, _callback) : void {
    this.importWbsModalService.upload(file).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / event.total);
          this.percentage = percentDone;
        } else if (event instanceof HttpResponse) {
          this.percentage = undefined;
          _callback(event.body);
        }
      },
      err => { console.log(err); _callback(undefined); }
    );
  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }
  onClickDownloadPdf(){
    if (this.persona.curriculum.indexOf("_ul.pdf") == -1){
      let base64String = this.persona.curriculum;
      this.downloadPdf(base64String,"Curriculum"+this.persona.cognome+this.persona.nome);
    }
    else{
      this.generazioneReportDialogService.downloadFile(this.persona.curriculum.substring(0,this.persona.curriculum.indexOf(".pdf")),"pdf");
    }

  }


}
