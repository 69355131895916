import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../../../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GestioneContrattiCollaborazioneModalService {

  constructor(private http: HttpClient) {}

  public getContratti(idPianificazioneProgettoPersona) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'contratti-di-collaborazione-pianificati/get-all-by-pianificazione-progetto-persona-id?idPianificazioneProgettoPersona=' + idPianificazioneProgettoPersona);
  }

  public saveContratto(contratto: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'contratti-di-collaborazione-pianificati/save-contratto-di-collaborazione-pianificato', contratto);
  }

  public updateContratto(contratto: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'contratti-di-collaborazione-pianificati/update-contratto-di-collaborazione-pianificato', contratto);
  }

  public removeContratti(idPianificazioneProgettoPersona) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'contratti-di-collaborazione-pianificati/delete-all-contratti-di-collaborazione-pianificati?idPianificazioneProgettoPersona=' + idPianificazioneProgettoPersona, null);
  }

  public getAttivitaContratto(idContrattoDiCollaborazione) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'attivita-contratti-di-collaborazione-pianificati/get-all-by-contratto-di-collaborazione-pianificato-id?idContrattoDiCollaborazionePianificato=' + idContrattoDiCollaborazione);
  }

  public saveAttivitaContratto(attivitaContratto) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'attivita-contratti-di-collaborazione-pianificati/save-attivita-contratto-di-collaborazione-pianificato', attivitaContratto);
  }

}