import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class GestioneSalService {

  constructor(private http: HttpClient) {
  }

  public getLatestVersionPianoDeiSal(nomeProgetto, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'piani-di-sal/piano-dei-sal/get-latest-version-piano-dei-sal-by-progetto-nome-and-organ?nomeProgetto=' + nomeProgetto + "&idOrganizzazione=" + idOrganizzazione);
  }

  public getLatestVersionPianoDeiSalByIdProg(idProgetto): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'piani-di-sal/piano-dei-sal/get-latest-version-piano-dei-sal-by-progetto-id?idProgetto=' + idProgetto);
  }

  public getAllSal(pianoDeiSalId, organizzazioneId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'piani-di-sal/sal/get-all-by-piano-dei-sal-id-and-organizzazione-id?pianoDeiSalId=' + pianoDeiSalId + '&organizzazioneId=' + organizzazioneId);
  }

  public getAllSalNew(pianoDeiSalId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'sal-date-partner/get-all-by-piano-sal?pianoDeiSalId=' + pianoDeiSalId);
  }

  public saveDate(salId, partner, value, type): Observable<any> {
    //  alert("save date ");
    return this.http.post<any>(AppSettings.BASE_URL + 'sal-date-partner/set-date-partner?salId=' + salId + '&stackeHolderId=' + partner + '&value=' + value + '&tipo=' + type, null);
  }


  public savePianoDeiSal(pianoDeiSal): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-sal/piano-dei-sal/save-piano-dei-sal', pianoDeiSal);
  }

  public getRendicontazioniBySalID(salId): Observable<any> {

    return this.http.get<any>(AppSettings.BASE_URL + 'rendicontazioni/get-all-by-sal-id?salId=' + salId);
  }

  public saveSal(progettoId, sal, organizzazioneId): Observable<any> {
    console.log("save sal " + JSON.stringify(sal));
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-sal/sal/save-sal?organizzazioneId=' + organizzazioneId + '&progettoId=' + progettoId, sal);
  }

  public creaSal(progettoId, sal, organizzazioneId): Observable<any> {
    console.log("save sal " + JSON.stringify(sal));
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-sal/sal/crea-sal?organizzazioneId=' + organizzazioneId + '&progettoId=' + progettoId, sal);
  }

  public deleteSal(salId) {
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-sal/sal/delete-sal', salId);
  }

  public removeSal(salId): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-sal/sal/remove-sal', salId);
  }
}
