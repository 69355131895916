import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class MonitoraggioProgettoService {

  constructor(private http: HttpClient) {}

  public getProgettiFromRendicontaDB(organizzazioneId: number): Observable<any> {
    return  this.http.get<any>(AppSettings.BASE_URL+'progetti/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
  }

  public getSalsByProgettoId(progettoID: number): Observable<any>{
    return this.http.get<any>(AppSettings.BASE_URL+'piani-di-sal/piano-dei-sal/get-sals-by-progetto-id?idProgetto=' + progettoID);
  }
}