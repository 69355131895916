import { Component, OnInit } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { AnagraficaUtentiService } from '../common-services/anagrafica-utenti.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { UtilityModuleComponent } from '../core_modules/utility-module/utility-module.component';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';

@Component({
  selector: 'app-impostazioni-profilo',
  templateUrl: './impostazioni-profilo.component.html',
  styleUrls: ['./impostazioni-profilo.component.css']
})
export class ImpostazioniProfiloComponent implements OnInit {

  constructor(private http: HttpClient, private anagraficaUtentiService: AnagraficaUtentiService, 
    private router: Router, private utilityModule: UtilityModuleComponent, public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>) { }

  ////////////////////////////////////////////////////////////////////////////////////
  // VARIABILI
  ////////////////////////////////////////////////////////////////////////////////////

  impostazioni_profilo_section_title="Impostazioni Profilo"

  hidePswd = true;

  email = sessionStorage.getItem("Email");

  userData={
    nome:"",
    cognome:"",
    descrizione:"",
    email:"",
    emailContatto:"",
    ruolo:undefined,
    utente:{
      passwordConfirm:"",
      ruolo:undefined,
    }
  }; //contiene tutte le informazioni dell'utente 

  pswdFormControl = new FormControl('', [
    Validators.maxLength(255)
  ])

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  nomeFormControl = new FormControl('',[
    Validators.maxLength(45)
  ])

  cognomeFormControl = new FormControl('',[
    Validators.maxLength(45)
  ])

  descrizioneFormControl = new FormControl('',[
    Validators.maxLength(45)
  ])
  
  emailLoginFormControl = new FormControl('', [
  ]);

  ////////////////////////////////////////////////////////////////////////////////////
  // FUNZIONI
  ////////////////////////////////////////////////////////////////////////////////////

  redirect(newPage)
  {
    this.utilityModule.redirectAdminOrganizzazione(newPage);
  }

  saveUserData() {
    this.userData.nome=this.nomeFormControl.value;
    this.userData.cognome=this.cognomeFormControl.value;
    this.userData.descrizione=this.descrizioneFormControl.value;
    this.userData.emailContatto=this.emailFormControl.value;
    this.userData.email = this.email;
    this.userData.ruolo = this.userData.utente.ruolo.nome;
    if(this.pswdFormControl.value)
    {
      if(this.userData.utente)
      {
        this.userData.utente.passwordConfirm=this.pswdFormControl.value;
      }
    }
    this.anagraficaUtentiService.saveAnagraficaUtente(this.userData).subscribe(
      data => {
        this.openSnackBar("Dati memorizzati con successo.", "Chiudi");
      },
      err => {
        this.showError("Errore nel salvataggio delle informazioni. Riprovare.");
      }
    );
  }

  handleUserData(data) {
    if(data.nome)
    {
      this.nomeFormControl.setValue(data.nome);
    }
    if(data.cognome)
    {
      this.cognomeFormControl.setValue(data.cognome);
    }
    if(data.descrizione)
    {
      this.descrizioneFormControl.setValue(data.descrizione);
    }
    if(data.emailContatto)
    {
      this.emailFormControl.setValue(data.emailContatto);
    }
    if(data.utente && data.utente.username)
    {
      this.emailLoginFormControl.setValue(data.utente.username);
    }
    this.pswdFormControl.setValue(undefined);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  annullaImpostazioni(){
    this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: "Annullare le modifiche e tornare alla home?"
      },
      panelClass: 'custom-confirm-container'
    });
    
    this.dialogRef.afterClosed().subscribe(
      res => {
        
        if(res==true)
        {       
          this.redirect('dashboard');
        }
      }
    );   
  }

  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // INIZIALIZZAZIONE
  ////////////////////////////////////////////////////////////////////////////////////

  ngOnInit() {
    this.anagraficaUtentiService.getUtenteByEmail(this.email).subscribe(
      data => {
         this.userData=data;
         this.handleUserData(data);
      },
      err => {
        this.showError("Errore nel salvataggio delle informazioni. Riprovare.");
      }
    );
  }

}
