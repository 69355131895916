import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class PianificazioneCostiService {

  constructor(private http: HttpClient) {}

  public getOrganizzazione(nome) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'organizzazioni/get-by-name?name='+nome);
  }

  public getStakeholders(idOrganizzazione) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'stakeholders/get-all-by-organizzazione?organizzazioneId='+idOrganizzazione);
  }

  public getSediStakeholder(idStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'sedi-stakeholders/get-all-by-stakeholder-id?idStakeholder='+idStakeholder);
  }

  public getVociDiCosto(idOrganizzazione) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'voci-di-costo/get-by-organizzazione-id?organizzazioneId='+idOrganizzazione);
  }

  public getProgetti(idStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'progetto-stakeholders/get-all-progetti-by-stakeholder-id?idStakeholder='+idStakeholder);
  }

  public getPersone(idSedeStakeholder, idOrganizzazione) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'persone/get-all-by-sede-stakeholder-id-and-organizzazione-id?sedeStakeholderId='+idSedeStakeholder+'&organizzazioneId='+idOrganizzazione);
  }

  public getProgettoPersona(idProgetto, idPersona) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'progetti-persone/get-by-progetto-id-and-persona-id?progettoId='+idProgetto+'&personaId='+idPersona);
  }

  public getBustePaga(idPersona) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'buste-paga/get-all-by-persona-id?idPersona=' + idPersona);
  }

  public getCostoOrarioPersonaleWrapper(idStakeholder, idSedeStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'pianificazioni-master/get-dettaglio-costo-orario-wrapper?idStakeholder='+idStakeholder+'&idSedeStakeholder='+idSedeStakeholder);
  }

  public getOrePersonaleWrapper(idStakeholder, idSedeStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'pianificazioni-master/get-dettaglio-ore-wrapper?idStakeholder='+idStakeholder+'&idSedeStakeholder='+idSedeStakeholder);
  }

  public getOrePersonaleAltreAttivitaWrapper(idStakeholder, idSedeStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'pianificazioni-master/get-dettaglio-ore-altre-attivita-wrapper?idStakeholder='+idStakeholder+'&idSedeStakeholder='+idSedeStakeholder);
  }

  public getLatestVersionPianoDeiSalByIdProg(idProgetto): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'piani-di-sal/piano-dei-sal/get-latest-version-piano-dei-sal-by-progetto-id?idProgetto=' + idProgetto);
  }

  public getListaSalByPianoId(idPiano): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'sal-date-partner/get-all-by-piano-sal?pianoDeiSalId=' + idPiano);
  }

  public savePianificazioneProgettoPersona(pianificazioneProgettoPersona: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'pianificazioni-progetto-persona/save-pianificazione-progetto-persona', pianificazioneProgettoPersona);
  }

  public deleteOnCascade(pianificazioneProgettoPersona: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'pianificazioni-progetto-persona/delete-on-cascade-pianificazione-progetto-persona', pianificazioneProgettoPersona);
  }
}