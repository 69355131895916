import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class GestionePianiDeiCostiService {

  constructor(private http: HttpClient) {
  }

  public getConfCostiStandard(idBando, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'bandi/get-conf-costi-standard?idBando=' + idBando + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getWbsFromId(idWbs): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbs-master/get-wbs-by-id?id=' + idWbs);
  }

  public getUltimaVersioneWbsMasterFromProgettoId(idProgetto): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbs-master/get-ultima-versione-wbs-master?idProgetto=' + idProgetto);
  }

  public getProgettoFromId(idProgetto): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetti/get-progetto-by-id?id=' + idProgetto);
  }

  public getWbsData(nomeProgetto, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbs-master/get-latest-wbs-version-info-for-piano-dei-costi?nomeProgetto=' + nomeProgetto + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getWbsL1ById(id): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbsl1/get-wbs-l1-by-id?id=' + id);
  }

  public getWbsL2ById(id): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbsl2/get-wbs-l2-by-id?id=' + id);
  }

  public savePianoDeiCosti(piano): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-costo/piano-dei-costi-ammissibili/save-piano-dei-costi-ammissibili', piano);
  }

  public saveElementoDiCosto(elemento): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-costo/elemento-di-costo-ammissibile/save-elemento-di-costo-ammissibile', elemento);
  }

  public saveDettaglioCostoPersonale(dettaglio): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-costo/dettaglio-elemento-di-costo-ammissibile/save-dettaglio-elemento-di-costo-ammissibile', dettaglio);
  }

  public updateElementoDiCosto(elemento): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-costo/elemento-di-costo-ammissibile/update-elemento-di-costo-ammissibile', elemento);
  }

  public getRelationNonStandardById(id): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'fasce-costo-personale-non-standard-progetto-stakeholder/get-by-id?id=' + id);
  }

  public getRelationStandardById(id): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'fasce-costo-personale-standard-tipologia-stakeholder/get-by-id?id=' + id);
  }

  public deleteAllDettagliByElemento(elemento): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-costo/dettaglio-elemento-di-costo-ammissibile/delete-all-by-elemento-id', elemento);
  }

  public getDettagliFasceCostiPersonaleNonStandard(idProgetto, idStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'fasce-costo-personale-non-standard-progetto-stakeholder/get-all-dettagli-fasce-costo-personale-non-standard-by-progetto-id-and-stakeholder-id?progettoId=' + idProgetto + '&stakeholderId=' + idStakeholder);
  }

  public getTettoMaxSpeseGenerali(idBando): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'bandi/get-tetto-max-spese-generali?idBando=' + idBando);
  }


  //////////////////////////////////////////////////////////
  public getSediStakeholder(idProgetto): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetto-stakeholders/get-all-sedi-stakeholders?idProgetto=' + idProgetto);
  }

  public getVociDiCosto(idBando, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'voci-di-costo/bando-voce-di-costo/get-all-by-bando-id-and-organizzazione-id?bandoId=' + idBando + '&organizzazioneId=' + idOrganizzazione);
  }

  public getVociDiCostoPartecipantiSpeseGenerali(idBando): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'voci-di-costo/bando-voce-di-costo/get-all-partecipano-calcolo-spese-generali-by-bando-id?bandoId=' + idBando);
  }

  public getFasceNonStandardConfigurate(idProgetto, idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'fasce-costo-personale-non-standard-progetto-stakeholder/get-all-by-progetto-id-and-sede-stakeholder-id?progettoId=' + idProgetto + '&sedeStakeholderId=' + idSedeStakeholder);
  }

  public getStakeholders(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'stakeholders/get-all-by-organizzazione?organizzazioneId=' + idOrganizzazione);
  }

  public getUltimaVersionePianoByProgettoIdAndWbsId(progettoId, wbsId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'piani-di-costo/piano-dei-costi-ammissibili/get-latest-version-wrapper-by-progetto-id-and-wbs-id?progettoId=' + progettoId + '&wbsId=' + wbsId);
  }

  public savePianoFromWrapper(wrapper): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-costo/piano-dei-costi-ammissibili/save-piano-costi-ammissibili-from-wrapper', wrapper);
  }

  //////////////////////////////////////////////////////////

  public getStoricoPda(idProgetto, wbs): Observable<any> {
    if (!wbs) {
      return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-costo/piano-dei-costi-ammissibili/get-storico-pda?progettoId=' + idProgetto, null);
    } else {
      return this.http.post<any>(AppSettings.BASE_URL + 'piani-di-costo/piano-dei-costi-ammissibili/get-all-version-by-progetto-id-and-wbs-id?progettoId=' + idProgetto+"&wbsId="+wbs,null);

    }
  }
}
