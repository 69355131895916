import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { scan } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-infinite-scroll-comune-selector-component',
  templateUrl: './infinite-scroll-comune-selector-component.component.html',
  styleUrls: ['./infinite-scroll-comune-selector-component.component.css']
})
export class InfiniteScrollComuneSelectorComponentComponent implements OnInit {

  total = 100;
  limit = 10;
  offset = 0;
  options = new BehaviorSubject<string[]>([]);
  options$: Observable<string[]>;

  selected = undefined;
  province = [];
  comuni = [];

  constructor(
    private thisDialogRef: MatDialogRef<InfiniteScrollComuneSelectorComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.options$ = this.options.asObservable().pipe(
      scan((acc, curr) => {
        return [...acc, ...curr];
      }, [])
    );
  }

  onClick() {
    this.thisDialogRef.close(this.selected);
  }

  filter(event) : void {
    if(event.value == undefined) this.data = this.comuni;
    else this.data = this.comuni.filter(filtered => filtered.siglaProvincia === event.value);
    this.total = 100;
    this.limit = 10;
    this.offset = 0;
    this.options = new BehaviorSubject<string[]>([]);
    this.selected = undefined;
    this.options$ = this.options.asObservable().pipe(
      scan((acc, curr) => {
        return [...acc, ...curr];
      }, [])
    );
    this.getNextBatch();
  }

  ngOnInit() {
    this.comuni = this.data.concat([]);
    this.data.forEach(comune => {
      if(this.province.find(found => found === comune.siglaProvincia) == undefined)
        this.province.push(comune.siglaProvincia);
    });
    this.province.sort((a, b) => a.localeCompare(b))
    this.getNextBatch();
  }

  getNextBatch() {
    const result = this.data.slice(this.offset, this.offset + this.limit);
    this.options.next(result);
    this.offset += this.limit;
  }

}
