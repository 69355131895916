import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AppErrors } from '../app.errors';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { OrganizzazioniService } from '../organizzazioni/organizzazioni.service';
import { Router } from '@angular/router';
import { UserStorage } from '../core_modules/user.storage';
import { AnagraficaUtentiService } from '../common-services/anagrafica-utenti.service';
import { UtilityModuleComponent } from '../core_modules/utility-module/utility-module.component';
import { GestioneVociDiCostoService } from './gestione-voci-di-costo.service';

const NOME_MAX_LENGTH=250;
const DESCRIZIONE_MAX_LENGTH=500;

@Component({
  selector: 'app-gestione-voci-di-costo',
  templateUrl: './gestione-voci-di-costo.component.html',
  styleUrls: ['./gestione-voci-di-costo.component.css']
})
export class GestioneVociDiCostoComponent implements OnInit {

  constructor(private http: HttpClient, 
    private organizzazioniService: OrganizzazioniService, 
    private router: Router,  
    private userStorage: UserStorage,
    private gestioneVociDiCostoService: GestioneVociDiCostoService,
    private utilityModule: UtilityModuleComponent,
    public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>) { 
    }

    gestione_voci_di_costo_section_title="Gestione Voci di Costo"

    organizzazioneName = undefined;

    organizzazioneObj = undefined;

    voci_di_costo_table_data;

    voci_di_costo_table_settings = {
      noDataMessage: "Nessun dato da visualizzare",
      columns: {
        nome: {
          title: 'Nome'
        },
        descrizione: {
          title: 'Descrizione'
        }
      },
      actions: {
        edit: true,
        delete: false,
        columnTitle: ""
      },
      edit: {
        editButtonContent: "Modifica ",
        saveButtonContent: "Aggiorna",
        cancelButtonContent: "Annulla",
        confirmSave: true
      },
      delete: {
        deleteButtonContent: "Elimina ",
        confirmDelete: true
      },
      add: {
        addButtonContent: " Aggiungi nuova",
        createButtonContent: "Aggiungi",
        cancelButtonContent: "Annulla",
        confirmCreate: true
      },
      attr: {
        class: 'table table-bordered utenti-organizzazioni-table'
      },
      pager: {
        display: false
      },
      mode: "inline",
    };  
  
    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 2000,
      });
    }
  
    checkIfDataIsValid(oldData,newData) {
      
      if (!oldData) { 
        if (!newData || newData.nome.length<=0 || newData.nome.length>NOME_MAX_LENGTH) {
          //il nome della voce di costo non puÃ² esssere maggiore di VARCHAR(45)
          return AppErrors.NOME_VOCE_DI_COSTO_LENGTH_ERROR; 
        }
        if (!newData || newData.descrizione.length>DESCRIZIONE_MAX_LENGTH) {
          //la descrizione della voce di costo non puÃ² essere maggiore di VARCHAR(100)
          return AppErrors.DESCRIZIONE_VOCE_DI_COSTO_LENGTH_ERROR; 
        }
        return undefined;
      }
      if(!newData || newData.nome.length<=0 || newData.nome.length>NOME_MAX_LENGTH) {
        //il nome della voce di costo non puÃ² essere vuoto o maggiore di VARCHAR(45)
        return AppErrors.NOME_VOCE_DI_COSTO_LENGTH_ERROR; 
      }
      if(newData && newData.descrizione) {
        if(newData.descrizione.length>DESCRIZIONE_MAX_LENGTH) {
          //la descrizione della voce di costo non puÃ² essere maggiore di VARCHAR(100)
          return AppErrors.DESCRIZIONE_VOCE_DI_COSTO_LENGTH_ERROR; 
        }
      }
      return undefined;
    }

    
    saveVoceDiCosto(event, voceDiCosto) {
      if(!voceDiCosto.organizzazione) {
        voceDiCosto.organizzazione=this.organizzazioneObj;
      }
      this.gestioneVociDiCostoService.saveVoceDiCosto(voceDiCosto).subscribe(
        data => {
          this.loadVociDiCostoOrganizzazione();
          event.confirm.resolve(event.newData);
          this.openSnackBar("Dati memorizzati con successo", "Chiudi")
        },
        err => {
          console.log(err);
          this.dialog.open(WarningMessageComponent,{
            data: {
              message: err.error.message
            },
            panelClass: 'custom-warning-container'
          });
          event.confirm.reject();
        }
      ); 
    }
  

    onDeleteConfirm(event) {
      this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
        width: "250px",
        data: {
          message: "Vuoi davvero eliminare questa voce di costo?"
        },
        panelClass: 'custom-confirm-container'
      });
      this.dialogRef.afterClosed().subscribe(
        res => {
          if(res==true) {       
            this.gestioneVociDiCostoService.deleteVoceDiCosto(event.data.nome,this.organizzazioneObj.id).subscribe(
              data =>{
                event.confirm.resolve(event.data);
              },
              err =>{
                console.log("*************************************************");
                console.log("ERROR...")
                console.log(err);
                console.log("*************************************************");
              }
            )
          }
          else {
            event.confirm.reject();
          }
        }
      );    
  
    }
 
    onSaveConfirm(event) {
      let result=this.checkIfDataIsValid(event.data,event.newData);
      if(result) {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: result
          },
          panelClass: 'custom-warning-container'
        });
        event.confirm.reject();
      }
      else {
        this.saveVoceDiCosto(event,event.newData)
      }
    }

  loadVociDiCostoOrganizzazione() {
    this.gestioneVociDiCostoService.getAllByOrganizzazione(this.organizzazioneObj.id).subscribe(
      data => {
        this.voci_di_costo_table_data=data;
      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );

  }

  loadOrganizzazione() {
    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        this.organizzazioneObj=data;
        this.loadVociDiCostoOrganizzazione();
      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  ngOnInit() {
    this.organizzazioneName=this.userStorage.getOrganizzazione();
    this.loadOrganizzazione();    
  }

}
