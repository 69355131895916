import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json, UTF-8' })
};

@Injectable()
export class CreaProgettoService {

    public static urlBaseServizio: string = "http://www.sdsrl.it/intranet-gestori/wrapper";
    public static subUrlImportaStakeholder: string = "/anagrafica_stakeholder.php";
    public static subUrlImportaReferenti: string = "/anagrafica_referenti_progetto.php";

    constructor(private http: HttpClient) {}


    /*
    // METODI NECESSARI AFFINCHE' SI POSSA UTILIZZARE TREE DI PRATICHE E TABELLE ATTIVITA
    // DA RE-IMPLEMENTARE PER STEP 2 (PRATICA ATTIVITA SALVATA E ATTIVITA' DA AGGIUNGERE)

    public getPraticheTreeByOrganization(idOrganizzazione, idPratica): Observable<any>{
        if (idPratica != null) {
            return this.http.get<any>(AppSettings.BASE_URL+'pratiche-listino/get-pratica-tree-by-organization?idPratica=' + idPratica + '&idOrganizzazione=' + idOrganizzazione);
        } else {
            return this.http.get<any>(AppSettings.BASE_URL+'pratiche-listino/get-active-tree-by-organization?idOrganizzazione=' + idOrganizzazione);

        }
    }

    public getAttivitaTableByOrganization(idOrganizzazione, idPratica): Observable<any>{
        return this.http.get<any>(AppSettings.BASE_URL+'listino/get-active-table-by-organization?idOrganizzazione=' + idOrganizzazione);
    }

    public deleteAttivitaFromPratica(idOrganizzazione, idAttivita, idPratica, ordinamento): Observable<any>{
        return this.http.get<any>(AppSettings.BASE_URL+'pratiche-listino/delete-attivita-from-pratica?idOrganizzazione=' + idOrganizzazione + '&idAttivita=' + idAttivita + '&idPratica=' + idPratica + '&ordinamento=' + ordinamento);
    }

    public upAttivitaInPratica(idOrganizzazione, idAttivita, idPratica, ordinamento): Observable<any>{
        return this.http.get<any>(AppSettings.BASE_URL+'pratiche-listino/up-attivita-in-pratica?idOrganizzazione=' + idOrganizzazione + '&idAttivita=' + idAttivita + '&idPratica=' + idPratica + '&ordinamento=' + ordinamento);
    }

    public downAttivitaInPratica(idOrganizzazione, idAttivita, idPratica, ordinamento): Observable<any>{
        return this.http.get<any>(AppSettings.BASE_URL+'pratiche-listino/down-attivita-in-pratica?idOrganizzazione=' + idOrganizzazione + '&idAttivita=' + idAttivita + '&idPratica=' + idPratica + '&ordinamento=' + ordinamento);
    }

    public saveAttivitasInPraticas(idOrganizzazione, idsAttivitas, idsPraticas): Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'pratiche-listino/add-attivitas-to-praticas?idOrganizzazione=' + idOrganizzazione + '&idsAttivitas=' + idsAttivitas + '&idsPraticas=' + idsPraticas);
    }
    // METODI NECESSARI AFFINCHE' SI POSSA UTILIZZARE TREE DI PRATICHE E TABELLE ATTIVITA
    // DA RE-IMPLEMENTARE PER STEP 2 (PRATICA ATTIVITA SALVATA E ATTIVITA' DA AGGIUNGERE)

    public createPratica(creablePratica): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'pratiche-attivita/create-pratica', creablePratica);
    }

    */

   //gestoriURL: string = 'http://www.sdsrl.it/intranet-gestori/wrapper/anagrafica_progetti.php';

   /* METODO POST */

    public getNomeReferenteById(bodyMessaggio : any): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'referenti/get-by-referente-id',bodyMessaggio);
    }

    public getConfLivelloAttivitaInWBSFromNomeBandoAndOrganizzazione(nome, idOrganizzazione): Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'bandi/get-conf-livello-attivita-in-wbs?nomeBando='+nome+'&idOrganizzazione='+idOrganizzazione);
    }

    public getConfLivelloTipoAttivitaCofinanziabileInWBSFromNomeBandoAndOrganizzazione(nome, idOrganizzazione): Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'bandi/get-conf-livello-tipo-attivita-cofinanziabile-in-wbs?nomeBando='+nome+"&idOrganizzazione="+idOrganizzazione);
    }

    public getUltimaVersioneWbsJsonFromNomeProgettoAndOrganizzazioneId(nomeProgetto, idOrganizzazione): Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'wbs-master/get-ultima-versione-wbs-progetto?nomeProgetto='+nomeProgetto+'&idOrganizzazione='+idOrganizzazione);
    }

    public saveNewWbsVersion(json : any): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'wbs-master/forward-engineering',json);
    }

    public getStakeholders(idProgetto, idOrganizzazione) : Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'/progetto-stakeholders/get-stakeholders?idProgetto='+idProgetto+'&idOrganizzazione='+idOrganizzazione);
    }

    public getVociDiCosto(idBando, idOrganizzazione) : Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'/voci-di-costo/bando-voce-di-costo/get-all-by-bando-id-and-organizzazione-id?bandoId='+idBando+'&organizzazioneId='+idOrganizzazione);
    }

    public getAllStakeholders(idOrganizzazione) : Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'stakeholders/get-all-by-organizzazione?organizzazioneId='+idOrganizzazione);
    }

   /* METODO GET
    public getIdBandoRendicontaFromNameBandoGestori(nomeBando, idOrganizzazione): Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'bandi/get-by-bando-name?nomeBando=' + nomeBando + '&idOrganizzazione=' + idOrganizzazione);
    }
    */

    public savePartner(partner : any): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'stakeholders/save-stakeholder', partner);
      }

    public updatePartner(partner : any): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'stakeholders/update-stakeholder', partner);
    }

      public saveProgettoPartner(wrapper : any): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'progetto-stakeholders/save-progetto-stakeholder', wrapper);
      }

      public saveProgetto(progetto : any): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'progetti/save-progetto', progetto);
      }

    public getReferentiFromGestori(bodyMessaggio : any, nomeProgetto : any): Observable<any> {
        //Chiamata gestita tramite proxy configurato lato server su proxy.config.json
        return this.http.post<any>(AppSettings.GESTORI_URL + CreaProgettoService.subUrlImportaReferenti + "?projectname=" + nomeProgetto , bodyMessaggio, {headers:
            {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' } });
    }

   public getDummyProgettiFromGestori(): any {
        return JSON.parse('[{"projectid":"5939","projectname":"ALIFUR","startdate":"2017-07-01","targetenddate":null,"actualenddate":null,"projecturl":"","codice_interno":"RI_16-14","codice_ente_finanziatore":"CUP J18C17000570006","bando":"Bando Regione Calabria R&S (Avviso del novembre 2016)","sal_corrente":"SAL 2","ultimo_sal":"0"}]');
   }

   public getDeliverables(type, idWbs) : Observable<any> {
    if(type==1) {
        return this.http.get<any>(AppSettings.BASE_URL+'deliverable-primo-livello/get-deliverable-primo-livello?idWbsPrimoLivello='+idWbs);
    }
    return this.http.get<any>(AppSettings.BASE_URL+'deliverable-secondo-livello/get-deliverable-secondo-livello?idWbsSecondoLivello='+idWbs);
    }

    public getWbsRecordStakeholders(type, idWbs, idOrganizzazione) : Observable<any> {
        if(type==1) {
            return this.http.get<any>(AppSettings.BASE_URL+'stakeholders-primo-livello/get-stakeholders?idWbsPrimoLivello='+idWbs+'&idOrganizzazione='+idOrganizzazione);
        }
        return this.http.get<any>(AppSettings.BASE_URL+'stakeholders-secondo-livello/get-stakeholders?idWbsSecondoLivello='+idWbs+'&idOrganizzazione='+idOrganizzazione);
    }

    public getWbsRecordResponsabili(type, idWbs, idOrganizzazione) : Observable<any> {
        if(type==1) {
            return this.http.get<any>(AppSettings.BASE_URL+'stakeholders-primo-livello/get-responsabili?idWbsPrimoLivello='+idWbs+'&idOrganizzazione='+idOrganizzazione);
        }
        return this.http.get<any>(AppSettings.BASE_URL+'stakeholders-secondo-livello/get-responsabili?idWbsSecondoLivello='+idWbs+'&idOrganizzazione='+idOrganizzazione);
    }

      public getAllReferentiRuoloByOrganizzazione(organizzazioneId): Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'referenti/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
      }

    public getIdReferenteRendicontaFromNameReferenteGestori(bodyMessaggio : any): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'referenti/get-by-referente-name',bodyMessaggio);
    }

    public saveReferente(referente : any): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'persone/save-persona', referente);
    }

    public saveProgettoReferenteRelation(wrapper : any): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'progetti-persone/save-progetto-persona', wrapper);
    }

    public getComuneFromCap(cap): Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'comuni/get-by-cap?cap=' + cap);
    }

    public saveSedeStakeholder(sede): Observable<any> {
        return this.http.post<any>(AppSettings.BASE_URL+'sedi-stakeholders/save-sede-stakeholder', sede);
    }

    public getSediStakeholder(idStakeholder): Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'sedi-stakeholders//get-all-by-stakeholder-id?idStakeholder=' + idStakeholder);
    }
}
