import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserComponent} from '../user/user.component';
import {OrganizzazioniComponent} from '../organizzazioni/organizzazioni.component';
import {LoginComponent} from '../login/login.component';
import {AdminDashboard} from '../admin-dashboard/admin.dashboard';
import {UtentiOrganizzazioniComponent} from '../utenti-organizzazioni/utenti-organizzazioni.component';
import {AdminOrganizzazioneDashboardComponent} from '../admin-organizzazione-dashboard/admin-organizzazione-dashboard.component';
import {ImpostazioniProfiloComponent} from '../impostazioni-profilo/impostazioni-profilo.component';
import {UtentiOrganizzazioneComponent} from '../utenti-organizzazione/utenti-organizzazione.component';
import {GestioneBandiComponent} from '../gestione-bandi/gestione-bandi.component';
import {GestioneVociDiCostoComponent} from '../gestione-voci-di-costo/gestione-voci-di-costo.component';
import {GestioneProgettoComponent} from '../gestione-progetto/gestione-progetto.component';
import {CreaProgettoComponent} from '../crea-progetto/crea-progetto.component';
import {TipoDiAttivitaCofinanziabileComponent} from '../tipo-di-attivita-cofinanziabile/tipo-di-attivita-cofinanziabile.component';
import {TipoDiFinanziamentoComponent} from '../tipo-di-finanziamento/tipo-di-finanziamento.component';
import {PianificazioneCostiComponent} from '../pianificazione-costi/pianificazione-costi.component';
import {MonitoraggioProgettoComponent} from '../monitoraggio-progetto/monitoraggio-progetto.component';
import {ReportisticaProgettiComponent} from '../reportistica-progetti/reportistica-progetti.component';
import {RendicontazioneProgettoComponent} from '../rendicontazione-progetto/rendicontazione-progetto.component';
import {GestioneStakeholdersComponent} from '../gestione-stakeholders/gestione-stakeholders.component';
import {ImpostazioniComponent} from '../impostazioni/impostazioni.component';
import {RiskManagerComponent} from '../risk-manager/risk-manager.component';
import {PartnerComponentComponent} from "../partner-component/partner-component.component";
import {ReportisticaProgettoComponent} from "../reportistica-progetto/reportistica-progetto.component";
import {PartnerDashboardComponent} from "../partner-dashboard/partner-dashboard.component";
import {CommingSoonComponent} from "../comming-soon/comming-soon.component";
import {MonitoraggioDocProgettoComponent} from "../monitoraggio-doc-progetto/monitoraggio-doc-progetto.component";
import {ApiKeyComponent} from "../api-key/api-key.component";
import {PartnerSmartreportComponent} from "../partner-smartreport/partner-smartreport.component";
import {SmartAllocationComponent} from "../smart-allocation/smart-allocation.component";

const routes: Routes = [
  {path: 'utenti-organizzazioni', component: UtentiOrganizzazioniComponent},
  {path: 'utenti-organizzazione', component: UtentiOrganizzazioneComponent},
  {path: 'organizzazioni', component: OrganizzazioniComponent},
  {path: 'user', component: UserComponent},
  {path: 'login', component: LoginComponent},
  {path: 'admin-dashboard', component: AdminDashboard},
  {path: 'admin-organizzazione-dashboard', component: AdminOrganizzazioneDashboardComponent},
  {path: 'progetti/importa', component: CreaProgettoComponent},
  {path: 'progetti', component: GestioneProgettoComponent},
  {path: 'configurazioni/bandi', component: GestioneBandiComponent},
  {path: 'pianificazioni', component: PianificazioneCostiComponent},
  {path: 'monitoraggio/progetti', component: MonitoraggioProgettoComponent},
  {path: 'monitoraggio/rischi', component: RiskManagerComponent},
  {path: 'rendicontazioni', component: RendicontazioneProgettoComponent},
  {path: 'configurazioni/stakeholders', component: GestioneStakeholdersComponent},
  {path: 'impostazioni', component: ImpostazioniComponent},
  {path: 'reportistica/progetti', component: ReportisticaProgettoComponent},
  {path: '', component: LoginComponent},
  {path: 'reportistica/progetti-partener', component: PartnerComponentComponent},
  {path: 'partner-dashboard', component: PartnerDashboardComponent},
  {path: 'partner-smart-report', component: PartnerSmartreportComponent},
  {path: 'coming-soon', component: CommingSoonComponent},
  {path: 'monitoraggio/documenti', component: MonitoraggioDocProgettoComponent},
  {path: 'apikey', component: ApiKeyComponent},
  {path: 'smart-allocation', component: SmartAllocationComponent},


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule {
}
