import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../../../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DettaglioCostoOrarioModalService {

  constructor(private http: HttpClient) {}

  public getPianificazioneProgettoPersona(idProgetto, idPersona, idSedeStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'pianificazioni-progetto-persona/get-by-progetto-id-and-persona-id-and-sede-stakeholder-id?idProgetto='+idProgetto+'&idPersona='+idPersona+'&idSedeStakeholder='+idSedeStakeholder);
  }

  public saveCostoOrario(costoOrario: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'costi-orari-pianificati/save-costo-orario-pianificato', costoOrario);
  }

  public updateCostoOrario(costoOrario: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'costi-orari-pianificati/update-costo-orario-pianificato', costoOrario);
  }

  public getFasceStandard(idBando, idTipologiaStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'fasce-costo-personale-standard-tipologia-stakeholder/get-all-by-tipologia-stakeholder-id-and-bando-id?idBando='+idBando+'&idTipologiaStakeholder='+idTipologiaStakeholder);    
  }

  public updatePianificazioneProgettoPersona(pianificazioneProgettoPersona: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'pianificazioni-progetto-persona/update-pianificazione-progetto-persona', pianificazioneProgettoPersona);
  }

}