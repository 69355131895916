import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class GestioneWbsService {

  constructor(private http: HttpClient) {
  }

  public getUltimaVersioneWbsJsonFromNomeProgettoAndOrganizzazioneId(nomeProgetto, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbs-master/get-ultima-versione-wbs-progetto?nomeProgetto=' + nomeProgetto + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getUltimaVersioneWbsMasterFromProgettoId(idProgetto): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbs-master/get-ultima-versione-wbs-master?idProgetto=' + idProgetto);
  }

  public getDeliverablesPrimoLivello(idWbs): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'deliverable-primo-livello/get-deliverable-primo-livello?idWbsPrimoLivello=' + idWbs);
  }

  public getDeliverablesSecondoLivello(idWbs): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'deliverable-secondo-livello/get-deliverable-secondo-livello?idWbsSecondoLivello=' + idWbs);
  }

  public getWbsRecordPrimoLivelloStakeholders(idWbs, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'stakeholders-primo-livello/get-stakeholders?idWbsPrimoLivello=' + idWbs + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getWbsRecordSecondoLivelloStakeholders(idWbs, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'stakeholders-secondo-livello/get-stakeholders?idWbsSecondoLivello=' + idWbs + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getWbsRecordPrimoLivelloResponsabili(idWbs, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'stakeholders-primo-livello/get-responsabili?idWbsPrimoLivello=' + idWbs + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getWbsRecordSecondoLivelloResponsabili(idWbs, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'stakeholders-secondo-livello/get-responsabili?idWbsSecondoLivello=' + idWbs + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getConfLivelloAttivitaInWBSFromNomeBandoAndOrganizzazione(nome, idOrganizzazione): Observable<any> {
    var n: String = encodeURIComponent(nome);
    return this.http.get<any>(AppSettings.BASE_URL + 'bandi/get-conf-livello-attivita-in-wbs?nomeBando=' + n + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getConfLivelloTipoAttivitaCofinanziabileInWBSFromNomeBandoAndOrganizzazione(nome, idOrganizzazione): Observable<any> {
    var n: String = encodeURIComponent(nome);
    return this.http.get<any>(AppSettings.BASE_URL + 'bandi/get-conf-livello-tipo-attivita-cofinanziabile-in-wbs?nomeBando=' + n + "&idOrganizzazione=" + idOrganizzazione);
  }

  public saveNewWbsVersion(json: any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'wbs-master/forward-engineering', json);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  public getUltimaWbs(nomeProgetto, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbs-master/get-ultima-versione-wbs-progetto?nomeProgetto=' + nomeProgetto + "&idOrganizzazione=" + idOrganizzazione);
  }

  public getWbs(nomeProgetto, versione, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbs-master/get-wbs-progetto?nomeProgetto=' + nomeProgetto + "&versione=" + versione + "&idOrganizzazione=" + idOrganizzazione);
  }


  public getStoricoWbs(progetto): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'wbs-master/get-storico-wbs?progetto=' + progetto,null);
  }


  public saveNewWbsVersionFromWrapper(wrapper: any): Observable<any> {
    //alert('save wbs');
    return this.http.post<any>(AppSettings.BASE_URL + 'wbs-master/save-new-wbs-version-from-wrapper', wrapper);
  }

  public saveWbsMaster(wbs: any): Observable<any> {
    //alert('save wbs');
    return this.http.post<any>(AppSettings.BASE_URL + 'wbs-master/save-wbs-master', wbs);
  }

  ////////////////////////////////////////////////////////////////////////////////////
}
