import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../../../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class PianificazioneOrePerAttivitaModalService {

  constructor(private http: HttpClient) {}

  public getAttivita(idProgetto, idSedeStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'wbs-master/get-latest-wbs-version-attivita-for-pianificazione?idProgetto=' + idProgetto + '&idSedeStakeholder=' + idSedeStakeholder);
  }

  public getOrWP(idProgetto, idSedeStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'wbs-master/get-latest-wbs-version-wp-or-for-pianificazione?idProgetto=' + idProgetto + '&idSedeStakeholder=' + idSedeStakeholder);
  }

  public getConfLivelloAttivitaInWbs(nomeBando, idOrganizzazione) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'bandi/get-conf-livello-attivita-in-wbs?nomeBando=' + nomeBando + '&idOrganizzazione=' + idOrganizzazione);
  }

  public savePianificazioneOrePerAttivita(pianificazione: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'pianificazioni-progetto-persona-per-attivita/save-pianificazione-progetto-persona-per-attivita', pianificazione);
  }

  public updatePianificazioneOrePerAttivita(pianificazione: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'pianificazioni-progetto-persona-per-attivita/update-pianificazione-progetto-persona-per-attivita', pianificazione);
  }

  public getContrattiWrappers(idPianificazioneProgettoPersona) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'contratti-di-collaborazione-pianificati/get-all-wrappers-by-pianificazione-progetto-persona-id?idPianificazioneProgettoPersona=' + idPianificazioneProgettoPersona);
  }

}