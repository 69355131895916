export class AppErrors {
    public static NOME_ORGANIZZ_LENGTH_ERROR="Il nome dell'organizzazione non può essere vuoto o superare i 45 caratteri.";
    public static DESCRIZIONE_ORGANIZZ_LENGTH_ERROR="La descrizione dell'organizzazione non può superare i 100 caratteri.";
    public static CODICE_FISCALE_ORGANIZZ_LENGTH_ERROR="Il codice fiscale dell'organizzazione non può superare i 16 caratteri.";

    public static NOME_UTENTE_LENGTH_ERROR="Il nome dell'utente non può essere vuoto o superare i 45 caratteri.";
    public static EMAIL_UTENTE_LENGTH_ERROR="L'indirizzo email di login dell'utente non può essere vuoto o superare i 255 caratteri.";
    public static COGNOME_UTENTE_LENGTH_ERROR="Il cognome dell'utente non può superare i 45 caratteri.";
    public static EMAIL_ESISTENTE_ERROR="L'indirizzo email di login è già presente nel sistema. Duplicati non ammessi!";
    public static EMAIL_LOGIN_INVALIDA_ERROR="Inserire un indirizzo email di login valido!";
    public static EMAIL_CONTATTO_INVALIDA_ERROR="L'indirizzo email di contatto può essere vuoto ma deve essere valido!";
    public static EMAIL_CONTATTO_LENGTH_ERROR="L'indirizzo email di contatto dell'utente non può superare i 255 caratteri.";

    public static CODICE_TIPO_PREZZO_ATTIVITA_ERROR="Il codice del tipo prezzo attività non può superare i 10 caratteri.";
    public static NOME_TIPO_PREZZO_ATTIVITA_ERROR="Il nome tipo prezzo attività non può essere vuoto o superare i 45 caratteri.";
    public static DESCRIZIONE_TIPO_PREZZO_ATTIVITA_ERROR="La descrizione tipo prezzo attività non può superare i 100 caratteri.";
    public static TIPOLOGIA_TIPO_PREZZO_ATTIVITA_ERROR="La tipologia del tipo prezzo attività deve essere una di quelle definite dal sistema.";
    public static TIPOLOGIA_TIPO_PREZZO_ATTIVITA_DEFAULT_ERROR="La tipologia di un tipo prezzo attività di default non può essere modificata.";

    public static ORGANIZZAZIONE_NULL_ERROR="L'organizzazione associata non può essere nulla.";
    public static CLIENTE_NULL_ERROR="Il Cliente associato non può essere nullo.";

    public static NOME_AREA_LENGTH_ERROR="Il nome dell'area non può essere vuoto o superare i 45 caratteri.";
    public static DESCRIZIONE_AREA_LENGTH_ERROR="La descrizione dell'area non può superare i 100 caratteri!";
    public static CODICE_AREA_LENGTH_ERROR="Il codice dell'area non può superare i 10 caratteri!";

    public static NOME_BANDO_LENGTH_ERROR="Il nome del bando non può essere vuoto o superare i 45 caratteri.";
    public static DESCRIZIONE_BANDO_LENGTH_ERROR="La descrizione del bando non può superare i 100 caratteri!";s

    public static NOME_VOCE_DI_COSTO_LENGTH_ERROR="Il nome della voce di costo non può essere vuoto o superare i 45 caratteri.";
    public static DESCRIZIONE_VOCE_DI_COSTO_LENGTH_ERROR="La descrizione della voce di costo non può superare i 100 caratteri!";

    public static NOME_TIPO_DI_ATTIVITA_COFINANZIABILE_LENGTH_ERROR="Il nome del tipo di attività cofinanziabile non può essere vuoto o superare i 45 caratteri.";
    public static DESCRIZIONE_TIPO_DI_ATTIVITA_COFINANZIABILE_LENGTH_ERROR="La descrizionedel tipo di attività cofinanziabile non può superare i 100 caratteri!";

    public static DENOMINAZIONE_CLIENTE_ERROR="Il nome sintetico non può essere vuoto o superare i 45 caratteri.";
    public static CITTA_ERROR="Il nome della città non può superare i 45 caratteri.";
    public static PROVINCIA_ERROR="La sigla della provincia non può superare i 2 caratteri.";

    public static NOME_PRATICA_ERROR="Il nome non può essere vuoto o superare i 45 caratteri.";
    public static NOME_PRATICA_ATTIVITA_ERROR="Il nome non può superare i 45 caratteri.";
    public static CODICE_PRATICA_ERROR="Il codice non può superare i 10 caratteri.";
    public static DESCRIZIONE_PRATICA_ERROR="La descrizione non può superare i 100 caratteri.";

    public static NOME_LISTINO_ERROR="Il nome non può essere vuoto o superare i 45 caratteri.";
    public static CODICE_LISTINO_ERROR="Il codice non può superare i 10 caratteri.";
    public static DESCRIZIONE_LISTINO_ERROR="La descrizione non può superare i 100 caratteri.";

    public static NUMERO_STATO_INIZIALE_ERROR="Può esserci un solo stato iniziale nell'insieme degli stati pratiche e attività.";

    public static NUMERO_STATO_INIZIALE_ERROR_ESISTENZIALE="Deve esserci un solo stato iniziale nell'insieme degli stati pratiche attività.";

    public static DELETE_STATO_INIZIALE_ERROR="Non puoi eliminare lo stato inziale";

    public static CODICE_STATO_PRATICA_ATTIVITA_ERROR="Il codice dello stato pratica-attività non può superare i 10 caratteri.";
    public static NOME_STATO_PRATICA_ATTIVITA_ERROR="Il nome dello stato pratica-attività non può essere vuoto o superare i 45 caratteri.";
    public static PROGRESSO_STATO_PRATICA_ATTIVITA_ERROR="Il progresso dello stato pratica-attività non può essere vuoto e deve essere compreso tra 0% e 100%.";
    public static DESCRIZIONE_STATO_PRATICA_ATTIVITA_ERROR="La descrizione dello stato pratica-attività non può superare i 100 caratteri.";
    public static FLAG_INCONSISTENTI_STATO_PRATICA_ATTIVITA_ERROR="Uno stato pratica-attività non può essere iniziale, finale con insuccesso o finale contemporaneamente.";
    public static FLAG_UNMODIFY_STATO_ERROR="Non è possibile modificare la tipologia di uno stato di default.";

    public static CODICE_STATO_FATTURAZIONE_ERROR="Il codice dello stato fatturazione non può superare i 10 caratteri.";
    public static NOME_STATO_FATTURAZIONE_ERROR="Il nome dello stato fatturazione non può essere vuoto o superare i 45 caratteri.";
    public static PROGRESSO_STATO_FATTURAZIONE_ERROR="Il progresso dello stato fatturazione non può essere vuoto e deve essere compreso tra 0% e 100%.";
    public static DESCRIZIONE_STATO_FATTURAZIONE_ERROR="La descrizione dello stato fatturazione non può superare i 100 caratteri.";
    public static FLAG_INCONSISTENTI_STATO_FATTURAZIONE_ERROR="Uno stato fatturazione non può essere iniziale, finale con insuccesso o finale contemporaneamente.";

    public static NOME_PARAMETRO_CONFIGURAZIONE_ERROR="Il nome di un parametro di configurazione è obbligatorio e non può superare i 45 caratteri.";
    public static DESCRIZIONE_PARAMETRO_CONFIGURAZIONE_ERROR="La descrizione di un parametro di configurazione non può superare i 100 caratteri.";
    public static VALORE_PARAMETRO_CONFIGURAZIONE_ERROR="Il valore di un parametro di configurazione è obbligatorio e non può superare i 45 caratteri.";

    // Segnatempo
    public static GENERIC_SEGNATEMPO_ERROR="Errore generale nell'inserimento o aggiornamento di un segnatempo, impossibile proseguire.";
    public static ORA_INIZIO_SEGNATEMPO_ERROR="L'ora di inizio di un segnatempo non può essere nulla.";
    public static ORA_FINE_SEGNATEMPO_ERROR="L'ora di fine di un segnatempo non può essere nulla.";
    public static DURATA_SEGNATEMPO_ERROR="La durata di un segnatempo non può essere nulla o negativa.";
    public static ATTIVITA_SEGNATEMPO_ERROR="L'attività di un segnatempo è obbligatoria.";
    public static DESCRIZIONE_SEGNATEMPO_ERROR="La descrizione di un segnatempo non può superare i 100 caratteri.";
    public static DURATA_INTERRUZIONI_SEGNATEMPO_ERROR="La durata delle interruzioni di un segnatempo non può essere negativa.";

    public static NOME_DELIVERABLE_LENGTH_ERROR="Il nome del deliverable non può essere vuoto o superare i 45 caratteri.";
    public static DESCRIZIONE_DELIVERABLE_LENGTH_ERROR="La descrizione del deliverable non può superare i 100 caratteri!";



}
