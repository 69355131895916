import {Component, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UtilityModuleComponent} from './core_modules/utility-module/utility-module.component';
import {UserStorage} from './core_modules/user.storage';
import 'hammerjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  constructor(private utilityModule: UtilityModuleComponent, private userStorage: UserStorage,
              private ref: ChangeDetectorRef,private router:Router) {
  }

  role = sessionStorage.getItem("Role");
  menu_index = undefined;
  menu_open = false;

  hamburger_click() {
    if (this.menu_open == false) {
      this.menu_open = true;
    } else {
      this.menu_open = false;
    }
  }

  chooseMenu() {
    console.log("role  chooseMenu " + this.role);
    if (this.role) {
      if (this.role.indexOf("Amministratore di Organizzazione") != -1) {
        this.menu_index = 0;
      }
      if (this.role.indexOf("Amministratore di Piattaforma") != -1) {
        this.menu_index = 1;
      }
      if (this.role.indexOf("Partner") != -1) {
        this.menu_index = 2;
      }
      return;
    } else {
      this.menu_index = 1;
    }
  }

  ngOnInit() {
    this.role = sessionStorage.getItem("Role");
    console.log('Logged with Role:');
    console.log(JSON.parse(this.role));
    this.chooseMenu();
  }

  /*   ngDoCheck(){
      this.role=sessionStorage.getItem("Role");
      this.chooseMenu();
    } */

  refresh() {
    this.role = sessionStorage.getItem("Role");
    this.chooseMenu();
  }

  redirect(newPage) {
    if (newPage == "coming-soon") {
      this.router.navigate(['./coming-soon']);
    }
    this.role = sessionStorage.getItem("Role");
    this.chooseMenu();
    if (this.role.indexOf("Amministratore di Organizzazione") != -1) {
      this.utilityModule.redirectAdminOrganizzazione(newPage);
    }
    if (this.role.indexOf("Amministratore di Piattaforma") != -1) {
      this.utilityModule.redirectSuperUser(newPage);
    }
    if (this.role.indexOf("Partner") != -1) {
      this.utilityModule.redirectPartner(newPage);
    }
    if (this.menu_open == true) {
      let elem: HTMLElement = document.getElementsByClassName("hamburger-button")[0] as HTMLElement;
      elem.click();
    }
    return;
  }
}
