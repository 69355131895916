import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GestioneVociDiCostoService {

  constructor(private http: HttpClient) {}

  public saveVoceDiCosto(voceDiCosto): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'voci-di-costo/save', voceDiCosto);
  }

  public getAllByOrganizzazione(organizzazioneId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'voci-di-costo/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
  }

 
  public deleteVoceDiCosto(nomeVoceDiCosto,organizzazioneId): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'voci-di-costo/delete-voce-di-costo?nomeVoceDiCosto='+nomeVoceDiCosto+'&idOrganizzazione='+organizzazioneId,undefined);
  }
 /*
  public getAllUtentiAreaByOrganizzazioneId(organizzazioneId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'aree-funzionali/utente-area/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
  }

  public deleteUtenteAreaByIds(idArea,idAnagraficaUtente): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'aree-funzionali/utente-area/delete-by-ids?idArea='+idArea+'&idAnagraficaUtente='+idAnagraficaUtente, undefined);
  }

  public createNewUtenteArea(idArea,idAnagraficaUtente): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'aree-funzionali/utente-area/create-new?idArea='+idArea+'&idAnagraficaUtente='+idAnagraficaUtente, undefined);
  }
  */

}