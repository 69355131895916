import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class UtentiOrganizzazioniService {

  constructor(private http: HttpClient) {}

  public getAllUtentiOrganizzazioni(): Observable<any> {
    return null;
  }

  /*
  public saveUtentiOrganizzazioni(utenti_organizzazioni_data): Observable<any> {
    return  this.http.post<any>(AppSettings.BASE_URL+'anagrafiche-utenti/save',utenti_organizzazioni_data);
  }
  */

  public getAllUtentiByOrganizzazione(organizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'anagrafiche-utenti/get-by-organization?organizzazione='+escape(JSON.stringify(organizzazione)));
  }

  /*
  public checkForDuplicate(emailAddress): Observable<any> {
    return this.http.get(AppSettings.BASE_URL+'anagrafiche-utenti/check-email-duplicates?email='+emailAddress);
  }
  */

  public saveNuovoUtenteAmministratore(utente): Observable<any> {
    return  this.http.post<any>(AppSettings.BASE_URL+'anagrafiche-utenti/save-utente-amministratore',utente);
  }

}