import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../../../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ConfiguraCostiPersonaleModalService {

  constructor(private http: HttpClient) {}

  /////////////////////////////////////////////////////////
  public getFascePersonaleNonStandard(idOrganizzazione) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'fasce-costo-personale-non-standard/get-all-by-organizzazione-id?organizzazioneId='+idOrganizzazione);    
  }
  /////////////////////////////////////////////////////////////

  public getProgetti(idOrganizzazione) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'progetti/get-by-organizzazione-id?organizzazioneId='+idOrganizzazione);    
  }

  public saveFasciaPersonaleNonStandard(wrapper): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'fasce-costo-personale-non-standard/save-fascia-costo-personale-non-standard', wrapper);
  }

  public saveFasciaPersonaleNonStandardProgettoStakeholderRelation(wrapper): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'fasce-costo-personale-non-standard-progetto-stakeholder/save-fascia-costo-personale-non-standard-progetto-stakeholder', wrapper);
  }

  public getDettagliFasceCostiPersonaleNonStandard(idProgetto, idStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'fasce-costo-personale-non-standard-progetto-stakeholder/get-all-dettagli-fasce-costo-personale-non-standard-by-progetto-id-and-stakeholder-id?progettoId='+idProgetto+'&stakeholderId='+idStakeholder);    
  }

}