import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-rotellina-dialog',
  templateUrl: './rotellina-dialog.component.html',
  styleUrls: ['./rotellina-dialog.component.css']
})
export class RotellinaDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RotellinaDialogComponent>,
  ) {}
  ngOnInit(): void {
  }
  

  onNoClick(): void {
    this.dialogRef.close();
  }
}
