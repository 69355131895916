import {Component, OnInit} from '@angular/core';
import {AppSettings} from "../../app.settings";


export class ReportElement {
  name: string;
  position: number;
  linkdownload: string;
}


const ELEMENT_DATA: ReportElement[] = [
  {position: 1, name: 'Riepilogo Costi Per Sal', linkdownload: AppSettings.BASE_URL + '/reportcostisal/reportcostisal/'}
];

@Component({
  selector: 'app-report-partner',
  templateUrl: './report-partner.component.html',
  styleUrls: ['./report-partner.component.css']
})
export class ReportPartnerComponent implements OnInit {

  public progetto = sessionStorage.getItem("onMonitoraCosti.idProgetto");
  displayedColumns: string[] = ['position', 'name', 'linkdownload'];
  dataSource = ELEMENT_DATA;
  partnerId;

  constructor() {

    console.log("costruttore " + sessionStorage.getItem("onMonitoraCosti.idProgetto"));
    this.progetto = sessionStorage.getItem("onMonitoraCosti.idProgetto");
    this.partnerId = sessionStorage.getItem("onMonitoraCosti.Partner");
  }

  download(type): void {
    //window.location.replace(window.location.origin + '/progetti/importa');
    var href = "";
    if (type == "Riepilogo Costi Per Sal") {
      href = AppSettings.BASE_URL + "/reportcostisal/reportcostisalpartener/" + this.progetto + "/" + this.partnerId;
    }
    var link = document.createElement("a");
    link.href = href;
    link.click();
  }

  ngOnInit() {
  }

}
