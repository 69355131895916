import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';
import { Partner } from '../interface/partner';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class UtentiOrganizzazioneService {

  constructor(private http: HttpClient) {}

  public getUtenteStakeholders(idUtente): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'anagrafiche-utenti/get-utente-stakeholders?id_utente='+idUtente);
  }


  public setUtenteStakeholders(partners : Array<number>,idUtente:number): Observable<any> {
    return  this.http.post<any>(AppSettings.BASE_URL+'anagrafiche-utenti/set-utente-stakeholders?id_utente='+idUtente,partners);
  }

  public getAllOtherUtentiByOrganizzazione(organizzazione,username): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'anagrafiche-utenti/get-others-by-organization?organizzazione='+escape(JSON.stringify(organizzazione))+'&utente='+username);
  }

  public getAllRoles(): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'roles/get-all');
  }

  public getOrganizzazioneByName(name): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'organizzazioni/get-by-name?name='+name);
  }

  public getUtenteExtendedDescription(anagraficaUtente : any) : string {
    let extendedDescription: string = '';
    try {
      if (anagraficaUtente) {
        if (anagraficaUtente.nome) {
          extendedDescription = extendedDescription + anagraficaUtente.nome;
          if (anagraficaUtente.cognome) {
            extendedDescription = extendedDescription + ' ' + anagraficaUtente.cognome;
          }
        } else {
          if (anagraficaUtente.cognome) {
            extendedDescription = extendedDescription + anagraficaUtente.cognome;
          }
        }
        if (anagraficaUtente.utente) {
          extendedDescription = extendedDescription + ' (' + anagraficaUtente.utente.username + ')';
        }
      }
    } catch (error) {

    }
    return extendedDescription;
  }
}