import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CustomMaterialModule} from '../../app/core_modules/material.module'
import {FormControl, Validators} from '@angular/forms';
import {AuthService} from '../core_modules/auth.service'
import {MatDialog} from '@angular/material';
import {TokenStorage} from '../core_modules/token.storage';
import {WarningMessageComponent} from '../modals/warning-message/warning-message.component';
import {UserStorage} from '../core_modules/user.storage';
import { RotellinaService } from '../rotellina-dialog/rotellina.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, public dialog: MatDialog, private authService: AuthService, private token: TokenStorage,
              private userStorage: UserStorage,private rotella: RotellinaService) {
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  hidePassword = true; //booleano che controlla la visibilità o meno della password nella maschera di login

  username: string; //nel contesto dell'applicazione l'username è sinonimo di email
  password: string; //password da inviare al server

  contest = "login";

  navigateBasedOnRole(role): void {
    this.authService.getRoles().subscribe(
      data => {
        let roles = data;
        for (let i = 0; i < roles.length; i++) {
          if (roles[i].id == role.id) {
            switch (role.nome) {
              case "Amministratore di Piattaforma":
                this.userStorage.saveUserInSession(this.username, JSON.stringify(role));
                this.router.navigate(['admin-dashboard']);
                break;
              case "Amministratore di Organizzazione":
                this.userStorage.saveUserInSession(this.username, JSON.stringify(role));
                this.router.navigate(['admin-organizzazione-dashboard']);
                break;
              case "Partner":
                this.userStorage.saveUserInSession(this.username, JSON.stringify(role));
                this.router.navigate(['admin-organizzazione-dashboard']);
                break;
              case "Operatore di Organizzazione":
                break;
            }
            return;
          }
        }
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...")
        console.log(err);
        console.log("*************************************************");
      }
    )

  }

  login(): void {
    console.log(this.username);
    let usernameTrimmed: string = this.username.trim();
    let passwordTrimmed: string = this.password.trim();
    this.rotella.openDialog();

    this.authService.attemptAuth(usernameTrimmed, passwordTrimmed).subscribe(
      data => {
        console.log("Logged as User:")
        console.log(data);
        if (document.getElementById("mat-drawer-unique")) {
          document.getElementById("mat-drawer-unique").style.visibility = "visible";
        }
        document.getElementById("dear-menu").style.visibility = "visible";
        this.token.saveToken(data.token);

        if (this.token.getToken()) {
          this.navigateBasedOnRole(data.role);
        } else {
          this.dialog.open(WarningMessageComponent, {
            data: {
              message: "Indirizzo email o password errati."
            },
            panelClass: 'custom-warning-container'
          });
        }
        this.rotella.closeDialog();

      },
      err => {
        this.rotella.closeDialog();

        console.log(err);
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  ngOnInit() {
    console.log(document.getElementById("dear-menu").style.visibility = "hidden");
    if (document.getElementById("mat-drawer-unique")) {
      console.log(document.getElementById("mat-drawer-unique").style.visibility = "hidden");
    }
  }

}
