import { Component, OnInit } from '@angular/core';
import { OrganizzazioniService } from '../organizzazioni/organizzazioni.service';
import { UserStorage } from '../core_modules/user.storage';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { GestioneStakeholdersService } from './gestione-stakeholders.service';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { GestionePartnerProgettoService } from '../gestione-partner-progetto/gestione-partner-progetto.service';
import { InfiniteScrollComuneSelectorComponentComponent } from '../gestione-partner-progetto/modals/infinite-scroll-comune-selector-component/infinite-scroll-comune-selector-component.component';
import { GestionePersonaModalComponent } from './modals/gestione-persona-modal/gestione-persona-modal.component';
import { AuthService } from '../core_modules/auth.service';

export interface SedeWrapper {
  sede: any;
  personale: any[];
}

@Component({
  selector: 'app-gestione-stakeholders',
  templateUrl: './gestione-stakeholders.component.html',
  styleUrls: ['./gestione-stakeholders.component.css']
})
export class GestioneStakeholdersComponent implements OnInit {

  DENOMINAZIONE_MAX_LENGTH = 200;
  CF_PIVA_MAX_LENGTH = 16;
  COD_DESTINATARIO_MAX_LENGTH = 7;
  TEL_MAX_LENGTH = 45;
  EMAIL_MAX_LENGTH = 100;

  NOME_SEDE_MAX_LENGTH = 250;
  DESCRIZIONE_SEDE_MAX_LENGTH = 250;

  constructor(
    private organizzazioniService: OrganizzazioniService,
    private userStorage: UserStorage,
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    private authService: AuthService,
    public gestionePersonaModalDialog: MatDialog,
    public snackBar: MatSnackBar,
    private gestioneStakeholdersService: GestioneStakeholdersService,
    private comuneSelectorDialog: MatDialog,
    private gestionePartnerProgettoService: GestionePartnerProgettoService,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>
  ) { }

  displayedColumns = ['check', 'nome', 'tipologia', 'add/remove'];
  selectedStakeholder = undefined;

  organizzazione = undefined;
  stakeholdersOriginal = [];
  stakeholders = [];

  tipologie = [];

  sedi: SedeWrapper[] = [];
  progettiStakeholder = [];

  removedSedi = [];
  removedPersone = [];

  /**
   * Carica un'organizzazione dal database.
   * @param nome Nome dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadOrganizzazione(nome, _callback) : void {
    this.organizzazioniService.getOrganizzazioneByName(nome).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadStakeholders(idOrganizzazione, _callback) : void {
    this.gestioneStakeholdersService.getStakeholders(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadTipologieStakeholder(idOrganizzazione, _callback) : void {
    this.gestionePartnerProgettoService.getTipologie(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadSedi(idStakeholder, _callback) : void {
    this.gestioneStakeholdersService.getSedi(idStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadPersonale(idSedeStakeholder, idOrganizzazione, _callback) : void {
    this.gestioneStakeholdersService.getPersonale(idSedeStakeholder, idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadComuni(_callback) : void {
    this.gestionePartnerProgettoService.getComuni().subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadProgettiStakeholder(_callback) : void {
    this.gestioneStakeholdersService.getProgettiStakeholder().subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadEliminableFlag(idSedeStakeholder, _callback) : void {
    this.gestioneStakeholdersService.getEliminableFlag(idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadPersonaEliminableFlag(idPersona, _callback) : void {
    this.gestioneStakeholdersService.getEliminablePersonaFlag(idPersona).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  saveStakeholder(stakeholder, _callback) : void {
    this.gestioneStakeholdersService.saveStakeholder(stakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  updateStakeholder(stakeholder, _callback) : void {
    this.gestioneStakeholdersService.updateStakeholder(stakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }


  deleteStakeholder(denominazione, idOrganizzazione, _callback) : void {
    this.gestioneStakeholdersService.deleteStakeholder(denominazione, idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  savePersona(persona, _callback) : void {
    this.gestioneStakeholdersService.savePersona(persona).subscribe(
      data => { _callback(data); persona.id = data.id; this.saveCurriculum(persona, _callback)},
      err => { this.showError(err.error.message); }
    );
  }

  updatePersona(persona, _callback) : void {
    this.gestioneStakeholdersService.updatePersona(persona).subscribe(
      data => { _callback(data); this.saveCurriculum(persona, _callback)},
      err => { this.showError(err.error.message); }
    );
  }

  saveCurriculum(persona, _callback){
    if (persona.curriculum != null){
      this.gestioneStakeholdersService.saveCurriculum(persona.curriculum, persona.id).subscribe(
        data => { _callback(data); },
        err => { this.showError(err.error.message); }
      );
    }
  }

  deletePersona(persona, index, _callback) : void {
    this.gestioneStakeholdersService.deletePersona(persona).subscribe(
      data => { _callback(data, index); },
      err => { this.showError(err.error.message); }
    );
  }

  saveSedeStakeholder(sedeStakeholder, _callback) : void {
    this.gestioneStakeholdersService.saveSedeStakeholder(sedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  updateSedeStakeholder(sedeStakeholder, _callback) : void {
    this.gestioneStakeholdersService.updateSedeStakeholder(sedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  deleteSedeStakeholder(nome, idStakeholder, index, _callback) : void {
    this.gestioneStakeholdersService.deleteSedeStakeholder(nome, idStakeholder).subscribe(
      data => { _callback(data, index); },
      err => { this.showError(err.error.message); }
    );
  }

  onSelectStakeholder(event, stakeholder) : void {
    event.preventDefault();
    if(stakeholder.id == undefined) return;
    this.selectedStakeholder = this.stakeholders.find(found => found.id === stakeholder.id);
    this.selectedStakeholder.tipologiaStakeholder = this.tipologie.find(found => found.id === this.selectedStakeholder.tipologiaStakeholder.id);
    this.sedi = [];
    this.removedPersone = [];
    this.removedSedi = [];
    this.loadSedi(this.selectedStakeholder.id, (sediRef) => {
      if(sediRef == undefined || sediRef.length === 0) this.sedi = [];
      else {
        sediRef.forEach(sedeRef => {
          this.loadPersonale(sedeRef.id, this.organizzazione.id, (personaleRef) => {
            this.loadEliminableFlag(sedeRef.id, (flag) => {
              this.sedi.push({ sede: sedeRef, personale: personaleRef != undefined ? personaleRef : [] });
            });
          });
        });
      }
    });
  }

  onSaveAll() : void {
    const errorMessage = this.getSavingErrorMessage(this);
    if(errorMessage != undefined) this.showError(errorMessage);
    else {
      this.askConfirm("Vuoi davvero aggiornare lo stakeholder selezionato?", "Eventuali dati precedenti saranno sovrascritti.", () => {
        this.saveAll();
      });
    }
  }

  private saveAll() : void {
    this.updateStakeholder(this.selectedStakeholder, (stakeholderRef) => {
      this.sedi.forEach(sedeWrapper => {
        sedeWrapper.sede.stakeholder = stakeholderRef;
        this.onSaveSedeStakeholder(sedeWrapper, (sedeRef, wrapperRef) => {
          if(wrapperRef.personale != undefined && wrapperRef.personale.length > 0) {
            wrapperRef.personale.forEach((persona, i) => {
              persona.organizzazione = this.organizzazione;
              persona.sedeStakeholder = sedeRef;
              this.onSavePersona(persona, i, (personaRef, index) => {
                if(this.sedi.indexOf(wrapperRef) === this.sedi.length - 1 && index === wrapperRef.personale.length - 1)
                  this.finalizeSaving();
              });
            });
          }
          else {
            if(this.sedi.indexOf(wrapperRef) === this.sedi.length - 1) this.finalizeSaving();
          }
        });
      });
    });
  }

  private finalizeSaving() : void {
    this.deletePersone(() => {
      this.deleteSedi(() => {
        this.openSnackBar("Stakeholder " + this.selectedStakeholder.denominazione + " aggiornato con successo.", "Chiudi");
        this.selectedStakeholder = undefined;
      });
    });
  }

  private deletePersone(_callback) : void {
    if(this.removedPersone.length === 0) _callback();
    else {
      this.removedPersone.forEach(persona => {
        const personaIndex = this.removedPersone.indexOf(persona);
        this.deletePersona(persona, personaIndex, (deletedPersona, personaIndexRef) => {
          if(personaIndexRef === this.removedPersone.length - 1) _callback();
        });
      });
    }
  }

  private deleteSedi(_callback) : void {
    if(this.removedSedi.length === 0) _callback();
    else {
      this.removedSedi.forEach(sede => {
        const sedeIndex = this.removedSedi.indexOf(sede);
        this.deleteSedeStakeholder(sede.nome, sede.stakeholder.id, sedeIndex, (deletedSede, sedeIndexRef) => {
          if(sedeIndexRef === this.removedSedi.length - 1) _callback();
        });
      });
    }
  }

  onSaveSedeStakeholder(sedeWrapper: SedeWrapper, _callback) : void {
    if(sedeWrapper.sede.id == undefined) this.saveSedeStakeholder(sedeWrapper.sede, (savedSede) => { _callback(savedSede, sedeWrapper); });
    else this.updateSedeStakeholder(sedeWrapper.sede, (updatedSede) => { _callback(updatedSede, sedeWrapper); });
  }

  onSavePersona(persona, index, _callback) : void {
    if(persona.id == undefined) this.savePersona(persona, (savedPersona) => { _callback(savedPersona, index); });
    else  this.updatePersona(persona, (updatedPersona) => { _callback(updatedPersona, index); });
  }

  private getSavingErrorMessage(parent) : string {
    if(this.selectedStakeholder.codiceFiscale != undefined && this.selectedStakeholder.codiceFiscale.length > this.CF_PIVA_MAX_LENGTH)
      return "Il codice fiscale inserito è troppo lungo.";
    if(this.selectedStakeholder.partitaIva != undefined && this.selectedStakeholder.partitaIva.length > this.CF_PIVA_MAX_LENGTH)
      return "La partita IVA inserita è troppo lunga.";
    if(this.selectedStakeholder.codiceDestinatario != undefined && this.selectedStakeholder.codiceDestinatario.length > this.COD_DESTINATARIO_MAX_LENGTH)
      return "Il codice destinatario inserito è troppo lungo.";
    if((this.selectedStakeholder.telefono != undefined && this.selectedStakeholder.telefono.length > this.TEL_MAX_LENGTH) ||
      (this.selectedStakeholder.altroTelefono != undefined && this.selectedStakeholder.altroTelefono.length > this.TEL_MAX_LENGTH))
      return "I numeri di telefono inseriti sono troppo lunghi.";
    if((this.selectedStakeholder.email != undefined && this.selectedStakeholder.email.length > this.EMAIL_MAX_LENGTH) ||
      (this.selectedStakeholder.email2 != undefined && this.selectedStakeholder.email2.length > this.EMAIL_MAX_LENGTH) ||
      (this.selectedStakeholder.pec != undefined && this.selectedStakeholder.pec.length > this.EMAIL_MAX_LENGTH))
      return "Gli indirizzi e-mail inseriti sono troppo lunghi.";
    if(this.sedi.length === 0) return "Impossibile memorizzare uno stakeholder privo di sedi.";
    if(this.sedi.find(function(found) { return found.sede.nome == undefined || found.sede.nome === ''; }) != undefined)
      return "Impossibile memorizzare una sede priva di nome.";
    if(this.sedi.find(function(found) { return found.sede.nome.length > parent.NOME_SEDE_MAX_LENGTH; }) != undefined)
      return "Almeno una sede ha un nome troppo lungo.";
    if(this.sedi.find(function(found) { return found.sede.descrizione != undefined && found.sede.descrizione.length > parent.DESCRIZIONE_SEDE_MAX_LENGTH; }) != undefined)
      return "Almeno una sede ha una descrizione troppo lunga.";
    if(this.sedi.find(found => found.sede.comune == undefined) != undefined) return "Impossibile memorizzare una sede priva di comune di riferimento.";
    var msg = undefined;
    this.sedi.forEach(sedeWrapper => {
      if(this.sedi.filter(filtered => filtered.sede.nome.trim().toUpperCase() === sedeWrapper.sede.nome.trim().toUpperCase()).length > 1) {
        msg = "Impossibile memorizzare sedi con nome duplicato."; return;
      }
      if(sedeWrapper.personale.find(function(found) { return found.nome == undefined || found.cognome == undefined || found.nome === "" || found.cognome === "" }) != undefined) {
        msg = "Impossibile memorizzare persone prive di nome o cognome."; return;
      }
    });
    return msg;
  }

  onAddStakeholder() : void {
    this.stakeholders.push({ id: undefined, denominazione: undefined, tipologiaStakeholder: undefined, organizzazione: this.organizzazione });
    this.stakeholders = this.stakeholders.concat([]);
  }

  onRemoveStakeholder(stakeholder) : void {
    this.askConfirm("Vuoi davvero eliminare lo stakeholder?", "Eventuali informazioni collegate saranno eliminate definitivamente.", () => {
      this.deleteStakeholder(stakeholder.denominazione, this.organizzazione.id, () => {
        this.openSnackBar("Stakeholder " + stakeholder.denominazione + " eliminato con successo.", "Chiudi");
        window.location.reload();
      });
    });
  }

  onSaveStakeholder(stakeholder) : void {
    const errorMessage = this.checkForErrorsInStakeholderSaving(stakeholder);
    if(errorMessage != undefined) this.showError(errorMessage);
    else {
      this.saveStakeholder(stakeholder, () => {
        this.openSnackBar("Stakeholder " + stakeholder.denominazione + " salvato con successo.", "Chiudi");
        window.location.reload();
      });
    }
  }

  private checkForErrorsInStakeholderSaving(stakeholder) : string {
    if(stakeholder.denominazione == undefined) return "Impossibile salvare uno stakeholder privo di nome.";
    if(stakeholder.denominazione.length > this.DENOMINAZIONE_MAX_LENGTH) return "La denominazione stakeholder inserita è troppo lunga.";
    if(stakeholder.tipologiaStakeholder == undefined) return "Impossibile salvare uno stakeholder privo di tipologia.";
    if(this.stakeholders.filter(filtered => filtered.denominazione.trim().toUpperCase() === stakeholder.denominazione.trim().toUpperCase()).length > 1)
      return "Impossibile salvare stakeholders con nomi duplicati.";
    return undefined;
  }

  onSelectComune(sedeWrapper: SedeWrapper) : void {
    this.loadComuni((comuniRef) => {
      const comuneSelectorDialogRef = this.comuneSelectorDialog.open(InfiniteScrollComuneSelectorComponentComponent, {
        data: comuniRef
      });
      comuneSelectorDialogRef.afterClosed().subscribe(result => {
        if(result != undefined) sedeWrapper.sede.comune = result;
      });
    });
  }

  onEditPersona(persona, sedeWrapper: SedeWrapper) : void {
    sessionStorage.setItem("gestione_persona", JSON.stringify(persona));
    const gestionePersonaModalRef = this.gestionePersonaModalDialog.open(GestionePersonaModalComponent);
    gestionePersonaModalRef.afterClosed().subscribe(result => {
      if(result != undefined) {
        const index = sedeWrapper.personale.indexOf(persona);
        if(index >= 0) sedeWrapper.personale[index] = result;
      }
    });
  }

  onAddPersona(sedeWrapper: SedeWrapper) : void {
    const gestionePersonaModalRef = this.gestionePersonaModalDialog.open(GestionePersonaModalComponent);
    gestionePersonaModalRef.afterClosed().subscribe(result => {
      if(result != undefined) sedeWrapper.personale.push(result);
    });
  }

  onRemovePersona(persona, sedeWrapper: SedeWrapper) : void {
    if(persona.id == undefined) this.removePersona(persona, sedeWrapper);
    else {
      this.loadPersonaEliminableFlag(persona.id, (flag) => {
        if(flag != undefined && flag === true) this.removePersona(persona, sedeWrapper);
        else this.showError("Impossibile rimuovere una persona referente di progetto o legata a pianificazioni o rendicontazioni.");
      });
    }
  }

  private removePersona(persona, sedeWrapper: SedeWrapper) : void {
    this.askConfirm("Vuoi davvero rimuovere la persona?", "I dati anagrafici della persona saranno eliminati.", () => {
      const index = sedeWrapper.personale.indexOf(persona);
      if(index >= 0) {
        if(persona.id != undefined) this.removedPersone.push(persona);
        sedeWrapper.personale.splice(index, 1);
      }
    });
  }

  onAddSede() : void {
    this.sedi.push({ sede: { nome: undefined, descrizione: undefined, comune: undefined, stakeholder: this.selectedStakeholder }, personale: [] });
    this.sedi = this.sedi.concat([]);
  }

  onRemoveSede(sedeWrapper: SedeWrapper) : void {
    if(sedeWrapper.personale.length > 0) this.showError("Impossibile rimuovere una sede con personale associato. Redistribuire il personale e riprovare.");
    else {
      if(sedeWrapper.sede.id == undefined) this.removeSede(sedeWrapper);
      else {
        this.loadEliminableFlag(sedeWrapper.sede.id, (flag) => {
          if(flag != undefined && flag === true) this.removeSede(sedeWrapper);
          else this.showError("Impossibile rimuovere una sede partner di progetto o legata a pianificazioni o rendicontazioni.");
        });
      }
    }
  }

  private removeSede(sedeWrapper: SedeWrapper) : void {
    this.askConfirm("Vuoi davvero rimuovere la sede?", "Eventuali dati associati saranno eliminati.", () => {
      const index = this.sedi.indexOf(sedeWrapper);
      if(index >= 0) {
        if(sedeWrapper.sede.id != undefined) this.removedSedi.push(sedeWrapper.sede);
        this.sedi.splice(index, 1);
      }
      this.sedi = this.sedi.concat([]);
    });
  }

  isStakeholderPartner(stakeholder) : boolean {
    return this.progettiStakeholder.find(found => found.stakeholder.id === stakeholder.id) != undefined;
  }

  initDataFromDatabase(_callback) : void {
    this.loadStakeholders(this.organizzazione.id, (stakeholdersRef) => {
      this.stakeholders = stakeholdersRef != undefined ? stakeholdersRef : [];
      this.stakeholdersOriginal = this.stakeholders.concat([]);
      this.loadTipologieStakeholder(this.organizzazione.id, (tipologieRef) => {
        this.tipologie = tipologieRef != undefined ? tipologieRef : [];
      });
      this.loadProgettiStakeholder((progettiStakeholderRef) => {
        this.progettiStakeholder = progettiStakeholderRef != undefined ? progettiStakeholderRef : [];
      });
      _callback();
    });
  }

  filter(event) : void {
    if(event.target.value != undefined)
      this.stakeholders = this.stakeholdersOriginal.filter(filtered => filtered.denominazione.trim().toUpperCase().includes(event.target.value.trim().toUpperCase()));
    else this.stakeholders = this.stakeholdersOriginal.concat([]);
  }

  /**
   * Mostra un messaggio di notifica all'utente.
   * @param message Messaggio di notifica da mostrare.
   * @param action Azione da permettere.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  /**
   * Mostra un messaggio di richiesta di conferma all'utente.
   * @param message Messaggio di richiesta di conferma.
   * @param submessage Messaggio inferiore.
   * @param _callback Azione successiva, in caso di conferma della richiesta.
   */
  askConfirm(message: string, submessage: string, _callback) : void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if(res) _callback();
      }
    );
  }

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      this.loadOrganizzazione(this.userStorage.getOrganizzazione(), (organizzazioneRef) => {
        this.organizzazione = organizzazioneRef;
        this.initDataFromDatabase(() => {

        });
      });
    });
  }

}
