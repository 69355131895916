import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'src/app/gestione-wbs/modals/aggiunta-record-wbs-dialog/aggiunta-record-wbs-dialog.component';

@Component({
  selector: 'app-import-progetto-modal',
  templateUrl: './import-progetto-modal.component.html',
  styleUrls: ['./import-progetto-modal.component.css']
})
export class ImportProgettoModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImportProgettoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onSelectedElement(element) : void {
    this.dialogRef.close(element);
  }

  ngOnInit() {
  }

}
