import { Component, OnInit, Injectable, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import {
  MatSnackBar,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DEFAULT_OPTIONS,
  DateAdapter,
  MatTableDataSource
} from '@angular/material';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { BehaviorSubject } from 'rxjs';
import { UserStorage } from '../core_modules/user.storage';
import { OrganizzazioniService } from '../organizzazioni/organizzazioni.service';
import { MatPaginator } from '@angular/material/paginator';
import { MonitoraggioProgettoService } from './monitoraggio-progetto.service';
import { MonitoraggioDeliverablesProgettoComponent } from '../monitoraggio-deliverables-progetto/monitoraggio-deliverables-progetto.component';
import { GestioneWbsService } from '../gestione-wbs/gestione-wbs.service';
import { GestionePianiDeiCostiService } from '../gestione-piani-dei-costi/gestione-piani-dei-costi.service';
import { GestioneReferentiProgettoService } from '../gestione-referenti-progetto/gestione-referenti-progetto.service';
import { GestionePartnerProgettoService } from '../gestione-partner-progetto/gestione-partner-progetto.service';
import { GestioneSalService } from '../gestione-sal/gestione-sal.service';
import { AuthService } from '../core_modules/auth.service';
import { ReportcostiComponent } from "../reportcosti/reportcosti.component";
import { MonitoraContrattiModalComponent } from './modals/monitora-contratti-modal/monitora-contratti-modal.component';
import { PianificazioneCostiService } from '../pianificazione-costi/pianificazione-costi.service';
import { SharedDataService } from '../common-services/shared-data.service';
import { MonitoraAttrezzaturaModalComponent } from './modals/monitora-attrezzatura-modal/monitora-attrezzatura-modal.component';
import { MonitoraSalModalComponent } from './modals/monitora-sal-modal/monitora-sal-modal.component';
import {Router} from "@angular/router";

export class ProgettiElement {
  id: String;
  nomeProgetto: string;
  idBando: number;
  position: number;
  weight: number;
  symbol: string;
}

@Injectable()
export class TableMonitoraggioProgettiDatabase {

  dataChange = new BehaviorSubject<ProgettiElement[]>([]);

  get data(): ProgettiElement[] {
    return this.dataChange.value;
  }

  // Classe di servizio del component owner che implementa i vari servizi verso il back-end invocati dal TableProjectDatabase
  // come 'saveAttivitasInPraticas', 'deleteAttivitaFromPratica', 'getPraticheTreeByOrganization', etc.
  private ownerComponentService: any;
  // La dialog dell'owner component
  private ownerComponentDialog: MatDialog;
  // ########## END   ELEMENTI DEL COMPONENT OWNER ##########

  //L'oggetto JSON, estratto con apposito querying del database, che contiene i progetti.
  jsonProjectTableStructure: any;

  projectTableNodesArray: ProgettiElement[];

  public setOwnerComponentService(x: any) {
    this.ownerComponentService = x;
  }

  public setOwnerComponentDialog(y: MatDialog) {
    this.ownerComponentDialog = y;
  }

  constructor(private adapter: DateAdapter<any>, private router:Router,
    @Inject(LOCALE_ID) private locale: string,) {
    this.adapter.setLocale("it");
  }

  bandiProgetto = [];

  getBandiProgetto() {
    return this.bandiProgetto;
  }

  reloadProjectGestoriDatabaseStructure(idorganizzazione, _callback) {
    this.ownerComponentService.getProgettiFromRendicontaDB(idorganizzazione).subscribe(
      dataDb => {
        this.jsonProjectTableStructure = dataDb;

        for (var x = 0; x < dataDb.length; x++) {
          this.bandiProgetto.push({
            idProgetto: dataDb[x].id,
            bando: dataDb[x].bando
          }
          );
        }
        this.projectTableNodesArray = this.buildProjectNodes(this.jsonProjectTableStructure);

        const data = this.projectTableNodesArray;
        this.dataChange.next(data);

        _callback();
      },
      err => {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare i Progetti!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    )
  }


  // Build the nodes from Json object. The result is a list of `TableProjectNode`
  buildProjectNodes(projectTableNodeData: any[]): ProgettiElement[] {
    let nodeArray = new Array<ProgettiElement>();
    if (projectTableNodeData != null) {
      try {
        projectTableNodeData.forEach(project => {
          const node = new ProgettiElement();

          node.nomeProgetto = project.nome;
          node.id = project.id;
          node.idBando = project.bando.id;

          nodeArray.push(node);
        });
      } catch (error) {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare informazioni della tabella!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    }
    return nodeArray;
  }
}

export class TableElementFlags {
  tableElement: ProgettiElement;
  isPartnerPresent: Boolean = false;
  isReferentiPresent: Boolean = false;
  isWbsPresent: Boolean = false;
  isPianoCostiAmmissibiliPresent: Boolean = false;
  isPianoSalPresent: Boolean = false;
}

@Component({
  selector: 'app-monitoraggio-progetto',
  templateUrl: './monitoraggio-progetto.component.html',
  styleUrls: ['./monitoraggio-progetto.component.css'],
  providers: [TableMonitoraggioProgettiDatabase, { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }]
})
export class MonitoraggioProgettoComponent implements OnInit {
  role = sessionStorage.getItem("Role");
  isPartner: boolean;
  displayedColumns: string[];

  projectTableDataSource: MatTableDataSource<ProgettiElement>;


  @ViewChild(MatPaginator) paginator: MatPaginator;// Numero elementi da visualizzare

  applyFilter(filterValue: string) {
    this.projectTableDataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    private monitoraggioProgettoService: MonitoraggioProgettoService,
    private organizzazioniService: OrganizzazioniService,
    public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private userStorage: UserStorage,
    private authService: AuthService,
    private adapter: DateAdapter<any>,
    private projectTableContentDatabase: TableMonitoraggioProgettiDatabase,
    private monitoraggioDeliverablesProgettoDialog: MatDialog,
    private monitoraggioCostiDialog: MatDialog,
    private gestioneWbsService: GestioneWbsService,
    private gestionePianiDeiCostiService: GestionePianiDeiCostiService,
    private gestionePartnerService: GestionePartnerProgettoService,
    private gestioneReferentiService: GestioneReferentiProgettoService,
    private gestioneSalService: GestioneSalService,
    private sharedDataService: SharedDataService, private router: Router

  ) {
    this.adapter.setLocale("it");
    this.projectTableDataSource = new MatTableDataSource<ProgettiElement>();
    projectTableContentDatabase.dataChange.subscribe(data => this.projectTableDataSource.data = data);
    if (this.role.indexOf("Partner") != -1) {
      this.isPartner = true;
      this.displayedColumns = ['name', 'deliverables', 'docs', 'equipment',"sal"];
    } else {
      this.isPartner = false;
      this.displayedColumns = ['name', 'deliverables', 'docs', 'contacts', 'equipment', 'costi',"sal"];
    }


  }

  color = undefined;
  data = undefined;
  caller = undefined;

  gestione_progetto_section_title = "Gestione Progetto";
  organizzazioneObj = undefined;

  flags: TableElementFlags[] = [];

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      // this.color=colors[Math.floor(Math.random() * colors.length)];
      this.projectTableDataSource.paginator = this.paginator;
      this.loadOrganizzazioneAndOtherData();
    });

  }

  onMonitoraDeliverables(element): void {
    sessionStorage.setItem("progetto", JSON.stringify(element));
    const monitoraggioDeliverablesProgettoDialogRef = this.monitoraggioDeliverablesProgettoDialog.open(MonitoraggioDeliverablesProgettoComponent);
  }

  onMonitoraSal(element):void{
    const monitoraSalDialogRef = this.dialog.open(MonitoraSalModalComponent,{
      data:{
        progetto: element,
        progettoID: element.id
      }
    })

  }

  onMonitoraContratti(element): void {
    const monitoraContrattiDialogRef = this.dialog.open(MonitoraContrattiModalComponent, {
      data: {
        progetto: element,
        progettoID: element.id
      },
    })

  }

  onMonitoraAttrezzatura(element): void {
    console.log("aperto monitora attrezzature", {
      progetto: element,
      progettoID: element.id
    } )
    const monitoraAttrezzaturaDialogRef = this.dialog.open(MonitoraAttrezzaturaModalComponent, {
      data: {
        progetto: element,
        progettoID: element.id
      },
    })

  }

  onMonitoraCosti(element): void {
    sessionStorage.setItem("onMonitoraCosti.idProgetto", element.id);
    const monitoraggioCostiDialogRef = this.monitoraggioCostiDialog.open(ReportcostiComponent, {
      height: '400px',
      width: '800px',
    });

  }

  on(element): void {
    console.log(element)
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  getPianoCostiPresentFlag(idProgetto, _callback): void {
    this.gestionePianiDeiCostiService.getUltimaVersioneWbsMasterFromProgettoId(idProgetto).subscribe(
      data => {
        if (data) {
          this.gestionePianiDeiCostiService.getUltimaVersionePianoByProgettoIdAndWbsId(idProgetto, data.id).subscribe(

            data2 => {

              if (data2) {
                _callback(true);
              } else {
                _callback(false);
              }
            },
            err => {
              this.dialog.open(WarningMessageComponent, {
                data: {
                  message: err.error.message
                },
                panelClass: 'custom-warning-container'
              });
              _callback(false);
            }
          );
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getWbsPresentFlag(idProgetto, _callback): void {

    this.gestioneWbsService.getUltimaVersioneWbsMasterFromProgettoId(idProgetto).subscribe(
      data => {
        if (data) {
          _callback(true);
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getPartnerPresentFlag(idProgetto, _callback): void {
    this.gestionePartnerService.getStakeholdersByProgettoIdAndOrganizzazioneId(idProgetto, this.organizzazioneObj.id).subscribe(
      data => {
        if (data && data.length > 0) {
          _callback(true);
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getReferentiPresentFlag(idProgetto, _callback): void {
    this.gestioneReferentiService.getAllPersoneByProgettoId(idProgetto).subscribe(
      data => {
        if (data && data.length > 0) {
          _callback(true);
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getPianoSalPresentFlag(idProgetto, _callback): void {
    _callback(false);
  }

  getFlags(idProgetto): TableElementFlags {
    for (var i in this.flags) {
      if (this.flags[i].tableElement.id == idProgetto) {
        return this.flags[i];
      }
    }
    return undefined;
  }

  convertCallbackToAsync = (func, data) => {
    return new Promise((resolve, reject) => {
      func.call(this, data, resolve);
    })
  }
  async initFlagsForElement(id) {
    let index = -1;
    for (let i = 0; i < this.flags.length; i++) {
      if (this.flags[i].tableElement.id == id) {
        index = i;
        break;
      }
    }
    if (index >= 0) {
      //conversione da logica a callback a asincrona più leggibile e performante
      const flag_id = this.flags[index].tableElement.id; //shortcut alla chiave per rendere il tutto più leggibile
      const data: any = await Promise.all([ // ottimizzare dell'esecuzione delle chiamate in parallelo.
        this.convertCallbackToAsync(this.getPartnerPresentFlag, flag_id),
        this.convertCallbackToAsync(this.getReferentiPresentFlag, flag_id),
        this.convertCallbackToAsync(this.getWbsPresentFlag, flag_id),
        this.convertCallbackToAsync(this.getPianoCostiPresentFlag, flag_id),
        this.convertCallbackToAsync(this.getPianoSalPresentFlag, flag_id)
      ]);
      this.flags[index] = { //in ordine di chiamata nel Promise.all,
        ...this.flags[index],// prende il vecchio stato, e poi sotto viene sovrascritto con i dati nuovi per chiave, le chiavi non presenti nella sovrascrizione vengono mantenute
        isPartnerPresent: data[0],
        isReferentiPresent: data[1],
        isWbsPresent: data[2],
        isPianoCostiAmmissibiliPresent: data[3],
        isPianoSalPresent: data[4],
      };
      //vecchio codice
      /*
      this.getPartnerPresentFlag(this.flags[index].tableElement.id, (partnerFlag) => {
        this.getReferentiPresentFlag(this.flags[index].tableElement.id, (referentiFlag) => {
          this.getWbsPresentFlag(this.flags[index].tableElement.id, (wbsFlag) => {
            this.getPianoCostiPresentFlag(this.flags[index].tableElement.id, (pianoCostiFlag) => {
              this.getPianoSalPresentFlag(this.flags[index].tableElement.id, (pianoSalFlag) => {
                this.flags[index].isPartnerPresent = partnerFlag;
                this.flags[index].isReferentiPresent = referentiFlag;
                this.flags[index].isWbsPresent = wbsFlag;
                this.flags[index].isPianoCostiAmmissibiliPresent = pianoCostiFlag;
                this.flags[index].isPianoSalPresent = pianoSalFlag;

              });
            });
          });
        });
      });*/
    }
  }

  initFlags(): void {
    this.flags = [];
    for (var i in this.projectTableDataSource.data) {
      var element: TableElementFlags = new TableElementFlags();
      element.tableElement = this.projectTableDataSource.data[i];
      this.flags.push(element);
    }
    //console.log(this.flags);
    for (var j in this.projectTableDataSource.data) {
      this.initFlagsForElement(this.projectTableDataSource.data[j].id);
    }
  }

  loadOrganizzazioneAndOtherData() {

    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        //console.log("data loadOrganizzazioneAndOtherData");
        //console.log(data);

        this.organizzazioneObj = data;
        //console.log('obj: ' + this.organizzazioneObj.id);
        this.sharedDataService.setOrganizzazione(data);
        this.sharedDataService.setOrganizzazioneID(this.organizzazioneObj.id);

        this.projectTableContentDatabase.setOwnerComponentService(this.monitoraggioProgettoService);
        this.projectTableContentDatabase.setOwnerComponentDialog(this.dialog);

        this.projectTableContentDatabase.reloadProjectGestoriDatabaseStructure(this.organizzazioneObj.id, () => {
          this.initFlags();
        });
        //this.loadPartnersTipoOrganizzazione(this.organizzazioneObj.id);


      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  redirectDocumenti(element){
    sessionStorage.setItem("progetto", JSON.stringify(element));
    this.router.navigate(['/monitoraggio/documenti']);
  }

}
