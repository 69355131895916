import {Component, Inject, Injectable, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {WarningMessageComponent} from "../modals/warning-message/warning-message.component";
import {MatPaginator} from "@angular/material/paginator";
import {ProgettiElement, TableGestioneProgettiDatabase} from "../gestione-progetto/gestione-progetto.component";
import {DateAdapter, MatDialog, MatTableDataSource} from "@angular/material";
import {ApiKeyService} from "./api_key.service";
import {CreazioneDatiProgettoComponent} from "../crea-progetto/modals/creazione-dati-progetto/creazione-dati-progetto.component";
import {AddApiComponent} from "./modal/add-api/add-api.component";
import {GestioneStakeholdersService} from "../gestione-stakeholders/gestione-stakeholders.service";
import {AppSettings} from "../app.settings";


export class ApiKeyElement {
  id: String;
  api_key: string;
  uid: string;
  stakeholder: string;
}

@Injectable()
export class TableGestioneAPIKEYDatabase {

  dataChange = new BehaviorSubject<ApiKeyElement[]>([]);

  get data(): ApiKeyElement[] {
    return this.dataChange.value;
  }

  private ownerComponentService: any;
  // La dialog dell'owner component
  private ownerComponentDialog: MatDialog;
  // ########## END   ELEMENTI DEL COMPONENT OWNER ##########

  private _selectedStakeholder;

  jsonProjectTableStructure: any;

  projectTableNodesArray: ApiKeyElement[];

  public setOwnerComponentService(x: any) {
    this.ownerComponentService = x;
  }

  public setOwnerComponentDialog(y: MatDialog) {
    this.ownerComponentDialog = y;
  }

  setSelectedStakeholder(value) {
    this._selectedStakeholder = value;
  }

  constructor(private adapter: DateAdapter<any>,
              @Inject(LOCALE_ID) private locale: string) {
    this.adapter.setLocale("it");
  }


  reloadProjectGestoriDatabaseStructure() {
    this.ownerComponentService.getApiKeys(this._selectedStakeholder).subscribe(
      dataDb => {
        this.jsonProjectTableStructure = dataDb;
        console.log(JSON.stringify(dataDb));

        this.projectTableNodesArray = this.buildProjectNodes(this.jsonProjectTableStructure);

        const data = this.projectTableNodesArray;
        this.dataChange.next(data);

      },
      err => {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare i Progetti!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    )
  }


  // Build the nodes from Json object. The result is a list of `TableProjectNode`
  buildProjectNodes(projectTableNodeData: any[]): ApiKeyElement[] {
    let nodeArray = new Array<ApiKeyElement>();
    try {
      projectTableNodeData.forEach(project => {
        const node = new ApiKeyElement();
        console.log("nodo " + JSON.stringify(node));
        node.api_key = project.api_key;
        node.uid = project.uid;
        node.stakeholder = project.stakeholder.denominazione;
        node.id = project.id
        nodeArray.push(node);
      });
    } catch (error) {
      this.ownerComponentDialog.open(WarningMessageComponent, {
        data: {
          message: 'Impossibile caricare informazioni della tabella!'
        },
        panelClass: 'custom-warning-container'
      });
    }
    return nodeArray;
  }
}


@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.css'],
  providers: [TableGestioneAPIKEYDatabase],
})
export class ApiKeyComponent implements OnInit {

  stakeholders;
  displayedColumns: string[] = ['api_key', 'uuid', 'stakholder', 'download', 'delete'];
  projectTableDataSource: MatTableDataSource<ApiKeyElement>;
  selectedStakeholder = undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private apiKeyService: ApiKeyService,
              public dialog: MatDialog,
              private projectTableContentDatabase: TableGestioneAPIKEYDatabase,
              private gestioneStakeholdersService: GestioneStakeholdersService,
  ) {

    this.projectTableDataSource = new MatTableDataSource<ApiKeyElement>();
    projectTableContentDatabase.dataChange.subscribe(data => this.projectTableDataSource.data = data);
  }

  ngOnInit() {
    this.projectTableDataSource.paginator = this.paginator;
    this.loadOrganizzazioneAndOtherData(this.selectedStakeholder);
    this.loadStakeholders();
  }


  loadOrganizzazioneAndOtherData(selectedStakeholder) {
    this.projectTableContentDatabase.setOwnerComponentService(this.apiKeyService);
    this.projectTableContentDatabase.setOwnerComponentDialog(this.dialog);
    if (selectedStakeholder) {
      this.projectTableContentDatabase.setSelectedStakeholder(selectedStakeholder);
    } else {
      this.projectTableContentDatabase.setSelectedStakeholder(undefined);
    }

    this.projectTableContentDatabase.reloadProjectGestoriDatabaseStructure();

  }


  onCreateAPI(): void {
    const creazioneDatiProgettoDialogRef = this.dialog.open(AddApiComponent);

    creazioneDatiProgettoDialogRef.afterClosed().subscribe(result => {
      this.loadOrganizzazioneAndOtherData(this.selectedStakeholder);
    });
  }

  loadStakeholders(): void {
    this.gestioneStakeholdersService.getStakeholders(1).subscribe(
      data => {
        console.log("loadStakeholders " + data);
        this.stakeholders = data;
      },
      err => {
      }
    );
  }

  onRemove(id) {
    if (confirm("Sicuro di voler eliminare questo accecco?")) {
      let apikey = new ApiKeyElement();
      apikey.id = id;
      this.apiKeyService.remove(apikey).subscribe(result => {
        this.loadOrganizzazioneAndOtherData(this.selectedStakeholder);
      });
    }
  }

  loadApiKeyFromStakholer() {
    if (this.selectedStakeholder) {
      this.loadOrganizzazioneAndOtherData(this.selectedStakeholder.id);
    } else {
      this.loadOrganizzazioneAndOtherData(undefined);
    }
  }

  onDownload(id) {
    const downloadURL_server = AppSettings.BASE_URL + 'api/download/' + id;
    fetch(downloadURL_server)
      .then(response => {
        return response.blob()
      })
      .then((myBlob) => {
        let link = document.createElement("a");
        link.href = URL.createObjectURL(myBlob);
        link.setAttribute('download', "api_" + id + ".txt");
        link.click();
      })
  }
}
