import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class MonitoraContrattiService {

  constructor(private http: HttpClient) { }
  //ogni persona deve fare una chiamata per ogni sede

  public getPersoneProgetto(idProgetto:number): Observable<any>{
    return  this.http.get<any>(`${AppSettings.BASE_URL}rendicontazioni/get-persone-progetto?idProgetto=${idProgetto}`);

  }
  public getContratti(idSedeStakeholder:number, idProgetto:number, idPersona:number): Observable<any>{
    return  this.http.get<any>(`${AppSettings.BASE_URL}rendicontazioni/get-contratti-persona?idSedeStakeholder=${idSedeStakeholder}&idProgetto=${idProgetto}&idPersona=${idPersona}`);

  }
  public getOreAndCostiRendicontati(idSedeStakeholder:number, idProgetto:number, idPersona:number): Observable<any> {
    return  this.http.get<any>(`${AppSettings.BASE_URL}rendicontazioni/get-ore-costi-rendicontati?idSedeStakeholder=${idSedeStakeholder}&idProgetto=${idProgetto}&idPersona=${idPersona}`);
  }
  public getInizioPeriodoRendicontazione(idSedeStakeholder:number, idProgetto:number, idPersona:number): Observable<any>{
    return  this.http.get<any>(`${AppSettings.BASE_URL}rendicontazioni/get-inizio-periodo-rendicontazione?idSedeStakeholder=${idSedeStakeholder}&idProgetto=${idProgetto}&idPersona=${idPersona}`);

  }
  public getFinePeriodoRendicontazione(idSedeStakeholder:number, idProgetto:number, idPersona:number): Observable<any>{
    return  this.http.get<any>(`${AppSettings.BASE_URL}rendicontazioni/get-fine-periodo-rendicontazione?idSedeStakeholder=${idSedeStakeholder}&idProgetto=${idProgetto}&idPersona=${idPersona}`);

  }
}
