import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class PartnerInScadenzaService {

  constructor(private http: HttpClient) {
  }


  public getAllInscadenzaByProgettoId(progettoId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetto-stakeholders/get-all-by-progetto-id-in-scadenza?idProgetto=' + progettoId);
  }
}
