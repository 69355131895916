import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import { GestioneContrattiCollaborazioneModalComponent } from 'src/app/pianificazione-costi/modals/gestione-contratti-collaborazione-modal/gestione-contratti-collaborazione-modal.component';
import { DettaglioCostoOrarioModalService } from 'src/app/pianificazione-costi/modals/dettaglio-costo-orario-modal/dettaglio-costo-orario-modal.service';

@Component({
  selector: 'app-gestione-costi-persona-modal',
  templateUrl: './gestione-costi-persona-modal.component.html',
  styleUrls: ['./gestione-costi-persona-modal.component.css']
})
export class GestioneCostiPersonaModalComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private confirmDialog: MatDialog,
    private thisDialogRef: MatDialogRef<GestioneCostiPersonaModalComponent>,
    private dettaglioCostoOrarioModalService: DettaglioCostoOrarioModalService,
  ) { }

  loadingComponent: boolean = true;
  mode: boolean = false;  //false se dipendente, true se collaboratore
  contieneAnno: boolean = true;

  contratti = [];
  costiOrari = [];

  persona = undefined;
  sal = undefined;
  stakeholder = undefined;
  recordWbs = [];

  columns = ['anno', 'costo'];

  fasceStandard = [];

  @ViewChildren(GestioneContrattiCollaborazioneModalComponent) childrenRef: QueryList<GestioneContrattiCollaborazioneModalComponent>;

  /**
   * Carica le fasce di costo standard - tipologia stakeholder dal database.
   * @param idBando Id del bando.
   * @param idTipologiaStakeholder Id della tipologia stakeholder.
   * @param _callback Azione successiva.
   */
  loadFasce(idBando, idTipologiaStakeholder, _callback) : void {
    this.dettaglioCostoOrarioModalService.getFasceStandard(idBando, idTipologiaStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Inizializza i dati provenienti dal parent (component principale di rendicontazione).
   */
  initDataFromParent() : void {
    const dataRef = sessionStorage.getItem("gestione_costi_persona_data");
    if(dataRef == undefined) this.showError("Errore nell'inizializzazione dei dati.");
    else {
      const data = JSON.parse(dataRef);
      if(data.contratti == undefined || data.costiOrari == undefined || data.persona == undefined || data.sal == undefined || data.recordWbs == undefined || data.stakeholder == undefined)
        this.showError("Errore nell'inizializzazione dei dati.");
      else {
        this.contratti = data.contratti; this.costiOrari = data.costiOrari; this.persona = data.persona;
        this.sal = data.sal; this.recordWbs = data.recordWbs; this.stakeholder = data.stakeholder;
        for(var i=new Date(this.sal.dataInizio).getFullYear(); i<=new Date(this.sal.dataFine).getFullYear(); i++){
          this.contieneAnno = false;
          this.costiOrari.forEach(costoOrarioRef => {
            if (costoOrarioRef.anno == i)
              this.contieneAnno = true;
          });
          if (!this.contieneAnno)
            this.costiOrari.push({anno: i, costoOrario: ( this.sal.pianoDeiSal.progetto.bando.confCostiStandard === false ? 0 : undefined ) });
        }
      }
    }
  }

  /**
   * Inizializza i dati tramite il database.
   * @param _callback Azione successiva.
   */
  initDataFromDatabase(_callback) : void {
    this.loadFasce(this.sal.pianoDeiSal.progetto.bando.id, this.stakeholder.tipologiaStakeholder.id, (fasceRef) => {
      this.fasceStandard = fasceRef;
      this.costiOrari.forEach(costoOrarioRef => {
        if(costoOrarioRef.fasciaCostoPersonaleStandardTipologiaStakeholder != undefined)
          costoOrarioRef.fasciaCostoPersonaleStandardTipologiaStakeholder = this.fasceStandard.find(found => found.id === costoOrarioRef.fasciaCostoPersonaleStandardTipologiaStakeholder.id);
      });
      _callback();
    });
  }

  /**
   * Gestisce l'evento di cambiamento della modalità.
   * Se la modalità è impostata a 'Dipendente' (FALSE), si inizializzano di default i costi orari annuali.
   * Se la modalità è impostata a 'Collaboratore' (TRUE), si inizializza a vuota la lista dei contratti.
   */
  onModeChange() : void {
    if(this.mode === true) {
      this.childrenRef.last.progetto = this.sal.pianoDeiSal.progetto;
      this.childrenRef.last.recordWbs = this.recordWbs.concat([]);
      this.childrenRef.last.contratti = [];
    }
    else {
      this.costiOrari = [];
      for(var i=new Date(this.sal.dataInizio).getFullYear(); i<=new Date(this.sal.dataFine).getFullYear(); i++)
        this.costiOrari.push({anno: i, costoOrario: ( this.sal.pianoDeiSal.progetto.bando.confCostiStandard === false ? 0 : undefined ) });
    }
  }

  /**
   * Gestisce l'evento di salvataggio.
   * Se la modalità è impostata a 'Dipendente' (FALSE), si trasferiscono al parent i costi orari annuali definiti.
   * Se la modalità è impostata a 'Collaboratore' (TRUE), si trasferiscono al parent i contratti di collaborazione definiti.
   */
  onSave() : void {
    const errorMessage = this.getErrorMessage(this.sal);
    if(errorMessage != undefined) this.showError(errorMessage);
    else {
      if(this.mode === false) this.thisDialogRef.close({ contratti: [], costiOrari: this.costiOrari });
      else this.thisDialogRef.close({ contratti: this.childrenRef.last.contratti, costiOrari: [] });
    }
  }

  /**
   * Restituisce una rappresentazione in stringa di un JSON contenente il progetto, i contratti e i record di WBS, da trasferire
   * al component di gestione dei contratti di collaborazione.
   */
  getContrattiData() : string {
    return JSON.stringify({ progetto: this.sal.pianoDeiSal.progetto, contratti: this.contratti, recordWbs: this.recordWbs });
  }

  /**
   * Controlla che non vi siano errori nei dati immessi.
   * Restituisce UNDEFINED se non vi sono errori, altrimenti il messaggio di errore da mostare all'utente.
   * @param sal SAL di riferimento.
   */
  getErrorMessage(sal) : string {
    if(this.mode === false) {
      if(this.sal.pianoDeiSal.progetto.bando.confCostiStandard === true && this.costiOrari.find(found => found.fasciaCostoPersonaleStandardTipologiaStakeholder == undefined) != undefined)
        return "Impossibile memorizzare costi orari privi di fascia standard.";
      if(this.sal.pianoDeiSal.progetto.bando.confCostiStandard === false && this.costiOrari.find(found => found.costoOrario == undefined) != undefined)
        return "Impossibile memorizzare costi orari nulli.";
      if(this.sal.pianoDeiSal.progetto.bando.confCostiStandard === false && this.costiOrari.find(found => +(found.costoOrario) < 0) != undefined)
        return "Impossibile memorizzare costi orari negativi.";
      return undefined;
    }
    else {
      if(this.childrenRef.last.isSavingPossible() === false) return "Errore nella definizione dei contratti. Verificare i dati inseriti e riprovare.";
      if(this.childrenRef.last.contratti.find(function(found) { return new Date(found.contratto.dataFine) >= new Date(sal.dataInizio) && new Date(found.contratto.dataInizio) <= new Date(sal.dataFine); }) == undefined)
        return "Definire almeno un contratto rientrante nel periodo di SAL.";
      return undefined;
    }
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.initDataFromParent();
    if(this.costiOrari.length === 0) {
      for(var i=new Date(this.sal.dataInizio).getFullYear(); i<=new Date(this.sal.dataFine).getFullYear(); i++)
        this.costiOrari.push({anno: i, costoOrario: ( this.sal.pianoDeiSal.progetto.bando.confCostiStandard === false ? 0 : undefined ) });
    }
    this.initDataFromDatabase(() => {
      this.mode = this.contratti.length > 0 ? true : false;
      this.loadingComponent = false;
    });
  }

}
