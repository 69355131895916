import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {WarningMessageComponent} from 'src/app/modals/warning-message/warning-message.component';
import {GestionePartnerProgettoService} from '../../gestione-partner-progetto.service';
import {DettaglioGestionePartnerProgettoModalService} from './dettaglio-gestione-partner-progetto-modal.service';

export class DialogData {
  progetto;
}

@Component({
  selector: 'app-dettaglio-gestione-partner-progetto-modal',
  templateUrl: './dettaglio-gestione-partner-progetto-modal.component.html',
  styleUrls: ['./dettaglio-gestione-partner-progetto-modal.component.css']
})
export class DettaglioGestionePartnerProgettoModalComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private confirmDialog: MatDialog,
    private thisDialogRef: MatDialogRef<DettaglioGestionePartnerProgettoModalComponent>,
    private dettaglioGestionePartnerProgettoModalService: DettaglioGestionePartnerProgettoModalService,
    private gestionePartnerProgettoService: GestionePartnerProgettoService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  loadingComponent: boolean = true;

  progetto = undefined;
  sediProgetto = undefined;

  loadSediProgetto(idProgetto, _callback): void {
    this.dettaglioGestionePartnerProgettoModalService.getAllByProgettoIdNOVincoli(idProgetto).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  updatePartner(partner, index, _callback): void {
    this.dettaglioGestionePartnerProgettoModalService.updateProgettoPartner(partner).subscribe(
      data => {
        _callback(data, index);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  riabilitaPartner(partner, index, _callback): void {
    this.dettaglioGestionePartnerProgettoModalService.updateProgettoPartner(partner).subscribe(
      data => {
        _callback(data, index);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }


  onCheck(element, event): void {
    if (event != undefined) event.preventDefault();
    element.dataFinePartecipazione = element.dataFinePartecipazione == undefined ? new Date() : undefined;
  }

  onSave(): void {
    this.loadingComponent = true;
    this.sediProgetto.forEach((sedeProgetto, i) => {
      if (sedeProgetto.dataFinePartecipazione) {
        this.updatePartner(sedeProgetto, i, (ref, index) => {
          this.loadingComponent = false;
          this.thisDialogRef.close(true);
        });
      }else{
        this.riabilitaPartner(sedeProgetto, i, (ref, index) => {
          this.loadingComponent = false;
          this.thisDialogRef.close(true);
        });
      }
    });


    /*
    this.sediProgetto.forEach((sedeProgetto, i) => {
      this.updatePartner(sedeProgetto, i, (ref, index) => {
        if (index == this.sediProgetto.length - 1)
          this.thisDialogRef.close(true);
      });
    });

     */
  }

  onDateChange(element, event)
    :
    void {
    element.dataFinePartecipazione = event.value;
  }

  showError(error
              :
              String
  ):
    void {
    this.dialog.open(WarningMessageComponent, {
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    if (this.data == undefined || this.data.progetto == undefined) {
      this.showError("Errore nell'inizializzazione dei dati.");
    }
    this.progetto = this.data.progetto;
    this.loadSediProgetto(this.progetto.id, (sedi) => {
      this.sediProgetto = sedi;
      this.sediProgetto.forEach(sp => {
        if (sp.dataFinePartecipazione != undefined) {
          sp.dataFinePartecipazione = new Date(sp.dataFinePartecipazione);
        }
      });
      this.loadingComponent = false;
    });
  }

}
