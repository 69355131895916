import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GestioneProgettoService {

  constructor(private http: HttpClient) {}

  public getProgettiFromRendicontaDB(organizzazioneId: number): Observable<any> {
    return  this.http.get<any>(AppSettings.BASE_URL+'progetti/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
  }

  public deleteProgettoById(nomeProgetto: number, idOrganizzazione: number): Observable<any> {
    return  this.http.get<any>(AppSettings.BASE_URL+'progetti/delete-progetto?idOrganizzazione=' + nomeProgetto + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getBandiByOrganizzazioneId(idOrganizzazione : Number) : Observable<any> {
    return  this.http.get<any>(AppSettings.BASE_URL+"bandi/get-by-organizzazione-id?organizzazioneId=" + idOrganizzazione);
  }

  public saveProgetto(bodyMessaggio : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'progetti/save-progetto', bodyMessaggio);
  }

}