import {Rendicontazione} from "./rendicontazione.model";

export class Documento {
  codice: string | undefined
  descrizione: string | undefined
  presente: number | undefined
  note: string | ""
  id_rendicontazione: number | undefined
  id: number | undefined
  rendicontazione: Rendicontazione
}
