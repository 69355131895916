import { Component, OnInit } from '@angular/core';
import { ImportWbsModalService } from './import-wbs-modal.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-import-wbs-modal',
  templateUrl: './import-wbs-modal.component.html',
  styleUrls: ['./import-wbs-modal.component.css']
})
export class ImportWbsModalComponent implements OnInit {

  NOME_MAX_LENGTH = 100;
  DESCRIZIONE_MAX_LENGTH = 350;

  constructor(
    private dialog: MatDialog,
    private importWbsModalService: ImportWbsModalService,
    private thisDialogRef: MatDialogRef<ImportWbsModalComponent>
  ) { }

  isLoading: boolean = true;
  file: File = undefined;
  uploadedFileName: string = undefined;
  percentage = undefined;

  records = [];

  extracting: boolean = false;

  upload(file: File, _callback) : void {
    this.importWbsModalService.upload(file).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / event.total);
          this.percentage = percentDone;
        } else if (event instanceof HttpResponse) {
          this.percentage = undefined;
          _callback(event.body);
        }
      },
      err => { console.log(err); _callback(undefined); }
    );
  }

  extract(fileName, _callback) : void {
    this.importWbsModalService.extract(fileName).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  onProcess() : void {
    if(this.file == undefined || this.uploadedFileName == undefined) {
      this.showError("File assente o non caricato.");
    }
    this.extracting = true;
    this.extract(this.uploadedFileName, (data) => {
      this.extracting = false;
      this.records = [];
      if(data != undefined) {
        this.records = data.recordsPrimoLivello != undefined ? data.recordsPrimoLivello : [];
      }
    });
  }

  onFileSelected() : void {
    const inputNode: any = document.querySelector('#file');
    if(inputNode != undefined && inputNode.files != undefined) {
      this.file = inputNode.files[0];
    }
    if(this.file != undefined) {
      this.upload(this.file, (res) => {
        this.uploadedFileName = res;
      });
    }
  }

  onSaveAll() : void {
    const errorMessage = this.checkForErrors(this);
    if(errorMessage != undefined) this.showError(errorMessage);
    else {
      this.thisDialogRef.close(this.records);
    }
  }

  checkForErrors(ref) : string {
    if(this.records == undefined || this.records.length === 0) {
      return "Impossibile salvare una WBS vuota.";
    }
    var msg = undefined;
    this.records.forEach(recordPrimoLivelloWrapper => {
      if(recordPrimoLivelloWrapper.record == undefined || recordPrimoLivelloWrapper.record.nome == undefined || recordPrimoLivelloWrapper.record.nome.length === 0) {
        msg = "La WBS è malformata. Controllare i campi e riprovare."; return;
      }
      if(recordPrimoLivelloWrapper.record.nome.length > this.NOME_MAX_LENGTH ||
        (recordPrimoLivelloWrapper.record.descrizione != undefined && recordPrimoLivelloWrapper.record.descrizione.length > this.DESCRIZIONE_MAX_LENGTH)) {
        msg = "I campi inseriti sono troppo lunghi."; return;
      }
      if(recordPrimoLivelloWrapper.recordsSecondoLivello != undefined && recordPrimoLivelloWrapper.recordsSecondoLivello.length > 0) {
        const test = recordPrimoLivelloWrapper.recordsSecondoLivello.find(function(found) {
          return found.record == undefined || found.record.nome == undefined || found.record.nome.length === 0 || found.record.nome.length > ref.NOME_MAX_LENGTH ||
          (found.record.descrizione != undefined && found.record.descrizione.length > ref.DESCRIZIONE_MAX_LENGTH);
        });
        if(test != undefined) { msg = "La WBS è malformata. Controllare i campi e riprovare."; return; }
      }
    });
    return msg;
  }

  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.isLoading = false;
  }

}
