import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, DateAdapter } from '@angular/material';
import { GestioneCostiPersonaModalComponent } from '../gestione-costi-persona-modal/gestione-costi-persona-modal.component';
import { DettaglioCostoOrarioModalService } from 'src/app/pianificazione-costi/modals/dettaglio-costo-orario-modal/dettaglio-costo-orario-modal.service';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import { RotellinaService } from 'src/app/rotellina-dialog/rotellina.service';

export interface Indicatore {
  date: Date;
  placeholder: string;
  holiday: string;
}

@Component({
  selector: 'app-rendicontazione-persona-modal',
  templateUrl: './rendicontazione-persona-modal.component.html',
  styleUrls: ['./rendicontazione-persona-modal.component.css']
})
export class RendicontazionePersonaModalComponent implements OnInit {

  Holidays = require('date-holidays');

  constructor(
    private dialog: MatDialog,
    private adapter: DateAdapter<any>,
    private confirmDialog: MatDialog,
    private timesheetDialog: MatDialog,
    private thisDialogRef: MatDialogRef<RendicontazionePersonaModalComponent>,
    private rotella: RotellinaService

  ) {
    this.adapter.setLocale("it");
  }

  loadingComponent: boolean = true;
  rendicontazioneAttivitaFuoriPeriodoFlag: boolean = false;

  rendicontazionePersonaWrapper = undefined;
  mode: boolean = false;
  recordWbs = [];
  indicatori: Indicatore[] = [];
  coloriWbs = [];

  dataSource = [];
  columns = [];

  contratti = [];

  /**
   * Gestisce l'evento di rendicontazione del timesheet giornaliero.
   * Apre lo stesso dialog ma in versione di timesheet giornaliero, coi dati indicati nel session storage.
   * @param indicatoreMensile Indicatore del mese di cui aprire il timesheet.
   */
  onRendicontaTimesheet(indicatoreMensile) : void {
    const indicatoriRef = this.getIndicatoriTimesheet(indicatoreMensile.date.getFullYear(), indicatoreMensile.date.getMonth());
    sessionStorage.setItem("rendicontazione_persona_data", JSON.stringify({ rendicontazionePersonaWrapper: this.rendicontazionePersonaWrapper, mode: true, recordWbs: this.recordWbs,
      coloriWbs: this.coloriWbs, indicatori: indicatoriRef, rendicontazioneAttivitaFuoriPeriodoFlag: this.rendicontazioneAttivitaFuoriPeriodoFlag}));
    var dialogRef = this.timesheetDialog.open(RendicontazionePersonaModalComponent);
    dialogRef.afterClosed().subscribe(
      res => {
        if(res != undefined) { this.rendicontazionePersonaWrapper = res; this.refreshMensile(this, indicatoreMensile); }
      }
    );
  }

  /**
   * Gestisce l'evento di salvataggio. Trasferisce al chiamante i dati inizializzati.
   */
  onSaveAll() : void {
    this.rotella.openDialog();
    if(this.mode === false) this.saveMensile(this);
    else this.saveGiornaliero(this);
      this.rotella.closeDialog();

      this.thisDialogRef.close(this.rendicontazionePersonaWrapper);

  }

  /**
   * Salvataggio dei dati nel caso di rendicontazione mensile.
   * Per ogni indicatore del mese da rendicontare, verifica se nel wrapper siano già presenti i dati di rendicontazione. In caso negativo,
   * inizializza i dati di rendicontazione a 0 per ogni mese da rendicontare e per ogni record in WBS.
   * @param parent Riferimento al chiamante. Necessario per invocare dati o funzioni del chiamante all'interno di funzioni implicte.
   */
  private saveMensile(parent) : void {
    this.indicatori.forEach((indicatore, i) => {
      const ref = this.rendicontazionePersonaWrapper.rendicontazioniMensili.find(function(found) { return found.rendicontazionePersona.anno === indicatore.date.getFullYear() && found.rendicontazionePersona.mese === indicatore.date.getMonth() + 1; });
      if(ref != undefined) {
        ref.dettagli = [];
        this.recordWbs.forEach(recordRef => {
          const value = this.dataSource.find(dataFound => dataFound.record.id === recordRef.id).data[i];
          ref.dettagli.push({
            ore: value, rendicontazionePersona: ref.rendicontazionePersona,
            recordWbsPrimoLivello: recordRef.recordWbsPrimoLivello == undefined ? recordRef : undefined,
            recordWbsSecondoLivello: recordRef.recordWbsPrimoLivello == undefined ? undefined : recordRef
          });
        });
      }
    });
  }

  /**
   * Salvataggio dei dati nel caso di rendicontazione timesheet giornaliero.
   * Per ogni indicatore del giorno da rendicontare, verifica se nel wrapper siano già presenti i dati di rendicontazione. In caso negativo,
   * inizializza i dati di rendicontazione a 0 per ogni giorno da rendicontare e per ogni record in WBS.
   * @param parent Riferimento al chiamante. Necessario per invocare dati o funzioni del chiamante all'interno di funzioni implicte.
   */
  private saveGiornaliero(parent) : void {
    const ref = this.rendicontazionePersonaWrapper.rendicontazioniMensili.find(function(found) { return found.rendicontazionePersona.anno === parent.indicatori[0].date.getFullYear() && found.rendicontazionePersona.mese === parent.indicatori[0].date.getMonth() + 1; });
    ref.timesheetGiornaliero = [];
    this.indicatori.forEach((indicatore, i) => {
      const giorno = indicatore.date.getDate();
      this.recordWbs.forEach(recordRef => {
        const value = this.dataSource.find(dataFound => dataFound.record.id === recordRef.id).data[i];
        ref.timesheetGiornaliero.push({
          ore: value, rendicontazionePersona: ref.rendicontazionePersona, giorno: giorno,
          recordWbsPrimoLivello: recordRef.recordWbsPrimoLivello == undefined ? recordRef : undefined,
          recordWbsSecondoLivello: recordRef.recordWbsPrimoLivello == undefined ? undefined : recordRef
        });
      });
    });
  }

  /**
   * Resituisce TRUE se il record WBS è fuori periodo rispetto al SAL, FALSE altrimenti.
   * @param record Record WBS.
   */
  isRecordWbsFuoriPeriodo(record) : boolean {
    const index = this.recordWbs.indexOf(record);
    if(index < 0 || index >= this.coloriWbs.length) return false;
    else return (this.coloriWbs[index] === 'white' || this.coloriWbs[index] === 'red');
  }

  /**
   * Preleva il numero del mese da un indicatore mese-anno proveniente dal component principale di rendicontazione.
   * @param meseAnno Indicatore mese-anno.
   */
  getMese(meseAnno) : number {
    return +(meseAnno.substring(0, meseAnno.indexOf('-')));
  }

  /**
   * Preleva il numero dell'anno da un indicatore mese-anno proveniente dal component principale di rendicontazione.
   * @param meseAnno Indicatore mese-anno.
   */
  getAnno(meseAnno) : number {
    return +(meseAnno.substring(meseAnno.indexOf('-') + 1));
  }

  /**
   * Preleva il nome della festa associato ad un giorno dell'anno.
   * Effettua una chiamata all'API esterna di gestione delle vacanze, dopodiché restituisce il nome della festa trovata oppure
   * UNDEFINED se l'API non ha indicato alcuna festa in quel giorno.
   * @param anno Anno di riferimento.
   * @param mese Mese di riferimento.
   * @param giorno Giorno di riferimento.
   */
  getNomeFesta(anno, mese, giorno) : string {
    const date = new Date(anno, mese, giorno);
    const ref = new this.Holidays('IT').getHolidays(date.getFullYear()).find(function(found) { return date >= found.start && date < found.end; });
    return ref != undefined ? ref.name : undefined;
  }

  /**
   * Restituisce una lista di indicatori dei mesi nel formato del wrapper definito nel component a partire
   * da una lista di indicatori mese-anno proveniente dal component principale di rendicontazione.
   * @param mesiAnno Lista di indicatori mese-anno.
   */
  getIndicatoriMese(mesiAnno) : any {
    var res = [];
    mesiAnno.forEach(meseAnno => {
      const mese = this.getMese(meseAnno); const anno = this.getAnno(meseAnno);
      const date = new Date(anno, mese - 1, 1);
      const placeholder = date.toLocaleString("it", {  year: 'numeric', month: 'long' });
      res.push({ date: date, placeholder: placeholder, holiday: undefined });
    });
    return res;
  }

  /**
   * Restituisce una lista di indicatori dei giorni nel formato del wrapper definito nel component.
   * Sarà restituito un wrapper indicatore per ogni giorno nel mese dell'anno indicato.
   * @param anno Anno di riferimento.
   * @param mese Mese di riferimento.
   */
  getIndicatoriTimesheet(anno, mese) : any {
    var res = [];
    for(var giorno = 1; giorno <= new Date(anno, mese + 1, 0).getDate(); giorno++) {
      const date = new Date(anno, mese, giorno);
      const placeholder = date.toLocaleString("it", {  weekday: 'long', day: 'numeric' });
      const holiday = this.getNomeFesta(anno, mese, giorno);
      res.push({ date: date, placeholder: placeholder, holiday: holiday });
    }
    return res;
  }

  /**
   * Restituisce un riassunto testuale di un contratto di collaborazione.
   * @param contratto Contratto di collaborazione di riferimento.
   */
  private getContrattoString(contratto) : string {
    var res = "";
    const dataInizio = new Date(contratto.dataInizio);
    const dataFine = new Date(contratto.dataFine);
    res += dataInizio.getDate() + "/" + (dataInizio.getMonth() + 1) + "/" + dataInizio.getFullYear();
    res += " - ";
    res += dataFine.getDate() + "/" + (dataFine.getMonth() + 1) + "/" + dataFine.getFullYear();
    return res;
  }

  /**
   * Ricarica i valori di rendicontazione mensile.
   * Utilizzato per calcolare i valori di rendicontazione mensile in base ai valori definiti nel timesheet.
   * @param parent Riferimento al chiamante. Necessario per invocare dati o funzioni del chiamante all'interno di funzioni implicte.
   * @param indicatoreMensile Indicatore mensile di riferimento.
   */
  refreshMensile(parent, indicatoreMensile) : void {
    const ref = this.rendicontazionePersonaWrapper.rendicontazioniMensili.find(function(found) { return found.rendicontazionePersona.anno === indicatoreMensile.date.getFullYear() && found.rendicontazionePersona.mese === indicatoreMensile.date.getMonth() + 1; });
    if(ref != undefined) {
      this.dataSource.forEach(dataRef => {
        dataRef.data[this.indicatori.indexOf(indicatoreMensile)] = 0;
        ref.timesheetGiornaliero.filter(function(tgFiltered) { return tgFiltered.recordWbsSecondoLivello != undefined ? tgFiltered.recordWbsSecondoLivello.id === dataRef.record.id : tgFiltered.recordWbsPrimoLivello.id === dataRef.record.id; })
        .forEach(tg => dataRef.data[this.indicatori.indexOf(indicatoreMensile)] += tg.ore );
      });
    }
  }

  /**
   * Carica i dati indicati dal parent.
   * Nel caso di rendicontazione mensile, carica i dati provenienti dal component principale di rendicontazione.
   * Nel caso di timesheet giornaliero, carica i dati provenienti dal component di rendicontazione mensile.
   */
  initDataFromParent() : void {
    const ref = sessionStorage.getItem("rendicontazione_persona_data");
    if(ref == undefined) this.showError("Errore nell'inizializzazione dei dati.");
    else {
      const data = JSON.parse(ref);
      if(data == undefined || data.rendicontazionePersonaWrapper == undefined || data.mode == undefined || data.recordWbs == undefined || data.coloriWbs == undefined || data.indicatori == undefined)
        this.showError("Errore nell'inizializzazione dei dati.");
      else {
        this.mode = data.mode; this.recordWbs = data.recordWbs; this.rendicontazionePersonaWrapper = data.rendicontazionePersonaWrapper; this.indicatori = data.indicatori; this.coloriWbs = data.coloriWbs;
        this.rendicontazioneAttivitaFuoriPeriodoFlag = data.rendicontazioneAttivitaFuoriPeriodoFlag != undefined ? data.rendicontazioneAttivitaFuoriPeriodoFlag : false;
        if(data.contratti != undefined && data.contratti.length > 0) this.contratti = data.contratti;
        if(this.mode === false) this.indicatori = this.getIndicatoriMese(this.indicatori);
        else { this.indicatori.forEach(indicatoreRef => indicatoreRef.date = new Date(indicatoreRef.date)); }
      }
    }
  }

  /**
   * Inizializza la tabella di rendicontazione (mensile o timesheet).
   * Inizializza le colonne della tabella in base agli indicatori forniti dal parent (una colonna per ogni mese o giorno), dopodiché inizializza tutti
   * i valori di rendicontazione a 0.
   */
  initTable() : void {
    this.columns.push("record");
    this.indicatori.forEach(indicatore => { this.columns.push(indicatore.placeholder); });
    this.recordWbs.forEach(record => { this.dataSource.push({ record: record, data: new Array(this.indicatori.length).fill(0) }); });
  }

  /**
   * Inizializza i dati in tabella in base ai dati di rendicontazione forniti dal parent.
   */
  initTableFromData() : void {
    if(this.mode === false) this.initTableFromDataMensile();
    else this.initTableFromDataTimesheet();
  }

  /**
   * Inizializza i dati in tabella in base ai dati di rendicontazioni mensili forniti dal parent.
   */
  private initTableFromDataMensile() : void {
    //alert("init");
    this.indicatori.forEach((indicatore, i) => {
    //  console.log("indicatore "+ JSON.stringify(indicatore));
      const ref = this.rendicontazionePersonaWrapper.rendicontazioniMensili.find(function(found) { return found.rendicontazionePersona.anno === indicatore.date.getFullYear() && found.rendicontazionePersona.mese === indicatore.date.getMonth() + 1; });
     // console.log("ref "+ JSON.stringify(ref));
      if(ref != undefined && ref.dettagli != undefined && ref.dettagli.length > 0) {
        ref.dettagli.forEach(dettaglio => {
          const record = dettaglio.recordWbsPrimoLivello != undefined ? dettaglio.recordWbsPrimoLivello : dettaglio.recordWbsSecondoLivello;
        //  console.log("record "+ JSON.stringify(record));
          console.log("dettaglio "+ JSON.stringify(dettaglio));
          this.dataSource.find(dataFound => dataFound.record.id === record.id).data[i] = dettaglio.ore;
        });
      }
    });
  }

  /**
   * Inizializza i dati in tabella in base ai dati di rendicontazioni giornalieri forniti dal parent.
   */
  private initTableFromDataTimesheet() : void {
    this.indicatori.forEach((indicatore, i) => {
      const ref = this.rendicontazionePersonaWrapper.rendicontazioniMensili.find(function(found) { return found.rendicontazionePersona.anno === indicatore.date.getFullYear() && found.rendicontazionePersona.mese === indicatore.date.getMonth() + 1; });
      if(ref != undefined && ref.timesheetGiornaliero != undefined && ref.timesheetGiornaliero.length > 0) {
        this.dataSource.forEach(dataRef => {
          const giornoRef = ref.timesheetGiornaliero.find(function(giornoFound) { return giornoFound.giorno === indicatore.date.getDate() && ((giornoFound.recordWbsPrimoLivello != undefined && giornoFound.recordWbsPrimoLivello.id === dataRef.record.id) || (giornoFound.recordWbsSecondoLivello != undefined && giornoFound.recordWbsSecondoLivello.id === dataRef.record.id)); });
          if(giornoRef != undefined) dataRef.data[i] = giornoRef.ore;
        });
      }
    });
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.initDataFromParent();
    this.initTable();
    this.initTableFromData();
    this.loadingComponent = false;
  }

}
