import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GestionePianiDeiCostiComponent } from '../../gestione-piani-dei-costi.component';
import { ConfirmMessageComponent } from 'src/app/modals/confirm-message/confirm-message.component';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import { GestioneBeniService } from 'src/app/common-services/gestione-beni.service';

@Component({
  selector: 'app-dettaglio-costi-beni-modal',
  templateUrl: './dettaglio-costi-beni-modal.component.html',
  styleUrls: ['./dettaglio-costi-beni-modal.component.css']
})
export class DettaglioCostiBeniModalComponent implements OnInit {

  isLoading = true;

  columns = ['bene', 'costo', 'annotazioni', 'periodo', 'percentuale', 'calcola', 'add/remove'];
  nomiVociDiCostoNonAmmortizzabili = ['Locazione finanziaria'];

  ANNOTAZIONI_MAX_LENGTH = 250;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private adapter: DateAdapter<any>,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private gestioneBeniService: GestioneBeniService,
    private thisDialogRef: MatDialogRef<GestionePianiDeiCostiComponent>,
    public confirmDialog: MatDialog
  ) {
    this.adapter.setLocale("it");
  }

  progetto = undefined;
  sedeStakeholder = undefined;
  voceDiCosto = undefined;

  beni = [];
  dettagli = [];

  loadBeni(idProgetto, idSedeStakeholder, _callback) : void {
    this.gestioneBeniService.getBeni(idProgetto, idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  onAddElement() : void {
    this.dettagli.push({ bene: undefined, dataInizioUtilizzoBene: new Date(), dataFineUtilizzoBene: new Date(), percentualeUtilizzoBene: 0, costoTotale: 0, annotazioni: undefined });
    this.dettagli = this.dettagli.concat([]);
  }

  onRemoveElement(element) : void {
    this.dettagli.splice(this.dettagli.indexOf(element), 1);
    this.dettagli = this.dettagli.concat([]);
  }

  onSaveAll() : void {
    const msg = this.checkForErrors();
    if(msg != undefined) this.showError(msg);
    else this.thisDialogRef.close(this.dettagli);
  }

  onSelectBene(element) : void {
    element.dataInizioUtilizzoBene = element.bene.ammortizzabile === true ? element.dataInizioUtilizzoBene : undefined;
    element.dataFineUtilizzoBene = element.bene.ammortizzabile === true ? element.dataFineUtilizzoBene : undefined;
    element.percentualeUtilizzoBene = element.bene.ammortizzabile === true ? element.percentualeUtilizzoBene : undefined;
  }

  onComputeCostoDettaglioBene(dettaglio) : void {
    if(dettaglio.bene != undefined && dettaglio.bene.ammortizzabile != undefined && dettaglio.bene.ammortizzabile === true && dettaglio.bene.costoAcquisto != undefined &&
      dettaglio.bene.aliquota != undefined && dettaglio.dataInizioUtilizzoBene != undefined && dettaglio.dataFineUtilizzoBene != undefined &&
      dettaglio.percentualeUtilizzoBene != undefined) {
        const dataInizio = new Date(dettaglio.dataInizioUtilizzoBene);
        const dataFine = new Date(dettaglio.dataFineUtilizzoBene);
        const fattoreConversioneMillisecondiGiorni = 86400000;
        const numeroGiorni = Math.ceil(((dataFine.getTime() - dataInizio.getTime()) / fattoreConversioneMillisecondiGiorni));
        const giorniAnnoSolare = 360;
        const costoTotale = (dettaglio.bene.costoAcquisto) * (dettaglio.bene.aliquota / 100) * (numeroGiorni / giorniAnnoSolare) * (dettaglio.percentualeUtilizzoBene / 100);
        if(costoTotale != undefined && costoTotale >= 0) {
          dettaglio.costoTotale = +(costoTotale.toFixed(2));
        }
    }
  }

  checkForErrors() : string {
    if(this.dettagli == undefined) return "Lista dei dettagli malformata.";
    var res = undefined;
    this.dettagli.forEach(dettaglio => {
      if(dettaglio.bene == undefined) { res = "Impossibile memorizzare dettagli privi di bene di riferimento."; return; }
      if(dettaglio.bene.ammortizzabile === true && this.nomiVociDiCostoNonAmmortizzabili.find(found => found === this.voceDiCosto.nome) != undefined) {
        res = "Impossibile memorizzare dettagli riferiti a beni ammortizzabili per una voce di costo non ammortizzabile"; return;
      }
      if(dettaglio.costoTotale == undefined || isNaN(dettaglio.costoTotale) || dettaglio.costoTotale < 0) {
        res = "Impossibile memorizzare dettagli con costo totale assente o negativo."; return;
      }
      if(dettaglio.bene.ammortizzabile === true &&
        (dettaglio.dataInizioUtilizzoBene == undefined || dettaglio.dataFineUtilizzoBene == undefined || dettaglio.dataInizioUtilizzoBene > dettaglio.dataFineUtilizzoBene)) {
        res = "Impossibile memorizzare dettagli con periodi di utilizzo nulli o malformati."; return;
      }
      if(dettaglio.annotazioni != undefined && dettaglio.annotazioni.length > this.ANNOTAZIONI_MAX_LENGTH) { res = "Le annotazioni inserite sono troppo lunghe."; return; }
    });
    return res;
  }

  initDataFromDatabase(_callback) : void {
    this.loadBeni(this.progetto.id, this.sedeStakeholder.id, (beniRef) => {
      this.beni = beniRef != undefined ? beniRef : [];
      this.beni.forEach(bene => {
        if(bene.ammortizzabile === true) bene.dataInizioAmmortamento = new Date(bene.dataInizioAmmortamento);
      });
      _callback();
    });
  }

  initData() : void {
    const ref = sessionStorage.getItem("dettaglio_costi_beni_data");
    if(ref == undefined) this.showError("Errore nell'inizializzazione dei dati.");
    else {
      const json = JSON.parse(ref);
      if(json == undefined || json.progetto == undefined || json.sedeStakeholder == undefined || json.voceDiCosto == undefined || json.dettagli == undefined)
        this.showError("Errore nell'inizializzazione dei dati.");
      else { this.progetto = json.progetto; this.sedeStakeholder = json.sedeStakeholder; this.dettagli = json.dettagli; this.voceDiCosto = json.voceDiCosto; }
      sessionStorage.removeItem("dettaglio_costi_beni_data");
    }
  }

  initDettagli() : void {
    this.dettagli.forEach(dettaglio => {
      dettaglio.bene = this.beni.find(found => found.id === dettaglio.bene.id);
      if(dettaglio.bene.ammortizzabile === true) {
        dettaglio.dataInizioUtilizzoBene = new Date(dettaglio.dataInizioUtilizzoBene);
        dettaglio.dataFineUtilizzoBene = new Date(dettaglio.dataFineUtilizzoBene);
      }
    });
  }

  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.initData();
    this.initDataFromDatabase(() => {
      this.initDettagli();
      this.isLoading = false;
    });
  }

}
