import { Injectable } from '@angular/core';
import { time } from 'console';
import { UtilsService } from '../common-services/utils.service';
import { UtilityModuleComponent } from './utility-module/utility-module.component';

const TOKEN_KEY = 'AuthToken';
const EXPIRATION_TOKEN_TIME = 'token_expire'

@Injectable()
export class TokenStorage {

  constructor(
    private utilsService : UtilsService,
  ) { }

  signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(EXPIRATION_TOKEN_TIME);

    window.sessionStorage.clear();
  }

  public async saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
    
    // timeout sessione 3H
    window.sessionStorage.removeItem(EXPIRATION_TOKEN_TIME);
    const timeout = (3*60*60*1000);// 3 H
    //const timeout = (1*60*1000);
    const datetimeout = Date.now()+timeout;
    window.sessionStorage.setItem(EXPIRATION_TOKEN_TIME, datetimeout+"");
    
    await this.utilsService.sleep(timeout);
    this.signOut();
    await this.utilsService.sleep(500);
    location.reload()
      
  }
  public get_expiration_token_time():string{
    return sessionStorage.getItem(EXPIRATION_TOKEN_TIME)
  }
  public getToken(): string {
    if(sessionStorage.getItem(EXPIRATION_TOKEN_TIME) !== null)
    return sessionStorage.getItem(TOKEN_KEY);
  }
}