import {Component, OnInit} from '@angular/core';
import {GestioneWbsService} from '../gestione-wbs/gestione-wbs.service';
import {
  MatDialogRef,
  MatDialog,
  MatSnackBar,
  MatTreeFlattener,
  MatTreeFlatDataSource,
  DateAdapter
} from '@angular/material';
import {ConfirmMessageComponent} from '../modals/confirm-message/confirm-message.component';
import {WarningMessageComponent} from '../modals/warning-message/warning-message.component';
import {FlatTreeControl} from '@angular/cdk/tree';
import {AggiuntaRecordWbsDialogComponent} from './modals/aggiunta-record-wbs-dialog/aggiunta-record-wbs-dialog.component';
import {GanttComponent} from '../gantt/gantt.component';
import {ImportWbsModalComponent} from './modals/import-wbs-modal/import-wbs-modal.component';
import {PartnerInScadenzaComponent, StackHolderInScadenza} from "../partner-in-scadenza/partner-in-scadenza.component";
import {StoricoWbsComponent} from "./modals/storico-wbs/storico-wbs.component";
import {RotellinaService} from "../rotellina-dialog/rotellina.service";
import { PromptGeneratorComponentComponent } from '../chat/modals/prompt-generator-component/prompt-generator-component.component';

interface FlatNode {
  node: any;
  expandable: boolean;
  level: number;
  checked: boolean;
}

export interface TreeElement {
  data: any;
  children?: TreeElement[];
}

@Component({
  selector: 'app-gestione-wbs',
  templateUrl: './gestione-wbs.component.html',
  styleUrls: ['./gestione-wbs.component.css']
})
export class GestioneWbsComponent implements OnInit {

  loadingComponent: boolean = true;

  progetto = undefined;
  bando = undefined;
  wbs = undefined;

  inscadenza: StackHolderInScadenza[] = [];

  isChatOpen = false;
  chatDialogRef = undefined;

  constructor(
    public thisDialogRef: MatDialogRef<GestioneWbsComponent>,
    private gestioneWbsService: GestioneWbsService,
    public dialog: MatDialog,
    private adapter: DateAdapter<any>,
    public aggiuntaRecordDialog: MatDialog,
    public importWbsDialog: MatDialog,
    public snackBar: MatSnackBar,
    public confirmDialog: MatDialog,
    private ganttDialog: MatDialog,
    public promptGeneratorModalDialog: MatDialog,
    private partnerInScadenzaComponent: PartnerInScadenzaComponent,
    private rotellina: RotellinaService
  ) {
    this.adapter.setLocale("it");
  }

  /**
   * Metodo di gestione dell'albero. Serve per trasformare i dati in rappresentazioni grafiche.
   */
  private _transformer = (node: TreeElement, level: number) => {
    return {
      node: node,
      expandable: !!node.children && node.children.length > 0,
      level: level,
      checked: false
    };
  }

  /**
   * Variabile di gestione dell'albero. Controller dei nodi nell'albero.
   */
  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  /**
   * Variabile di gestione dell'albero. Controller di apertura/chiusura dei nodi dell'albero.
   */
  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  /**
   * Variabile di gestione dell'albero. Datasource dell'albero.
   */
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  /**
   * Metodo di gestione dell'albero. Verifica se un nodo ha o meno figli.
   */
  hasChild = (_: number, node: FlatNode) => node.expandable;

  /**
   * Metodo di gestione dell'albero. Verifica se un nodo è al primo livello.
   */
  isFirstLevel = (_: number, node: FlatNode) => node.level === 0;

  /**
   * Carica l'ultima versione di WBS dal database.
   * @param nomeProgetto Nome del progetto di riferimento.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadWbs(nomeProgetto, idOrganizzazione, _callback): void {
    this.gestioneWbsService.getUltimaWbs(nomeProgetto, idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        _callback(undefined);
      }
    );
  }

  /**
   * Salva una nuova WBS sul database.
   * @param wrapper Wrapper di WBS da salvare.
   * @param _callback Azione successiva.
   */
  saveWbs(wrapper, _callback): void {
    this.gestioneWbsService.saveNewWbsVersionFromWrapper(wrapper).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Metodo di gestione dell'albero. Seleziona il nodo padre e tutti i figli.
   */
  onFirstLevelNodeChecked(node: FlatNode): void {
    this.treeControl.getDescendants(node).forEach(descendant => {
      descendant.checked = !node.checked;
    });
  }

  /**
   * Muove un nodo di WBS verso l'alto. Nel caso di nodo attività, a fine operazione espande il nodo WP/OR a cui appartiene.
   * @param node Nodo di WBS da muovere.
   */
  onMoveNodeUpwards(node: FlatNode): void {
    if (node.level === 0) {
      const index = this.dataSource.data.indexOf(node.node);
      var tmp = this.dataSource.data.concat([]);
      [tmp[index - 1], tmp[index]] = [tmp[index], tmp[index - 1]];
      if (tmp.indexOf(undefined) < 0) this.dataSource.data = tmp;
    } else {
      const childIndex = this.getParent(node).node.children.indexOf(node.node);
      var childTmp = this.getParent(node).node.children.concat([]);
      [childTmp[childIndex - 1], childTmp[childIndex]] = [childTmp[childIndex], childTmp[childIndex - 1]];
      if (childTmp.indexOf(undefined) < 0) this.getParent(node).node.children = childTmp;
    }
    this.refreshTree();
    this.restoreExpansion(this.getParent(node));
  }

  /**
   * Muove un nodo di WBS verso il basso. Nel caso di nodo attività, a fine operazione espande il nodo WP/OR a cui appartiene.
   * @param node Nodo di WBS da muovere.
   */
  onMoveNodeDownwards(node: FlatNode): void {
    if (node.level === 0) {
      const index = this.dataSource.data.indexOf(node.node);
      var tmp = this.dataSource.data.concat([]);
      [tmp[index], tmp[index + 1]] = [tmp[index + 1], tmp[index]];
      if (tmp.indexOf(undefined) < 0) this.dataSource.data = tmp;
    } else {
      const childIndex = this.getParent(node).node.children.indexOf(node.node);
      var childTmp = this.getParent(node).node.children.concat([]);
      [childTmp[childIndex], childTmp[childIndex + 1]] = [childTmp[childIndex + 1], childTmp[childIndex]];
      if (childTmp.indexOf(undefined) < 0) this.getParent(node).node.children = childTmp;
    }
    this.refreshTree();
    this.restoreExpansion(this.getParent(node));
  }

  /**
   * Rimuove un nodo di WBS. Nel caso di nodo WP/OR, rimuove anche tutti i suoi figli.
   * Nel caso di nodo attività, a fine operazione espande il nodo WP/OR a cui appartiene.
   * @param node Nodo di WBS da rimuovere.
   */
  onRemoveNode(node: FlatNode): void {
    this.askConfirm("Vuoi davvero eliminare il nodo di WBS?", "Tutte le informazioni, con gli eventuali sotto-nodi, saranno eliminate.", () => {
      const parent = this.getParent(node);
      this.removeNode(node);
      this.refreshTree();
      this.restoreExpansion(parent);
    });
  }

  /**
   * Rimuove tutti i nodi WBS selezionati, ripristinando l'espansione dei nodi a fine operazione.
   */
  onRemoveAllSelectedNodes(): void {
    this.askConfirm("Vuoi davvero eliminare i nodi di WBS selezionati?", "Tutte le informazioni, con gli eventuali sotto-nodi, saranno eliminate.", () => {
      const prev = this.dataSource.data.concat([]);
      const tmp: TreeElement[] = [];
      prev.forEach(e => {
        /* Se il nodo parent non è selezionato, lo vado ad inserire nel datasource temporaneo. */
        if (this.getAllCheckedNodes().find(found => found.node === e) == undefined) tmp.push(e);
      });
      tmp.forEach(unchecked => {
        const prevChildren = unchecked.children.concat([]);
        const tmpChildren: TreeElement[] = [];
        prevChildren.forEach(c => {
          /* Se il figlio non è selezionato, lo vado ad inserire nella lista figli temporanea. */
          if (this.getAllCheckedChildren(this.treeControl.dataNodes.find(foundNode => foundNode.node === unchecked))
            .find(foundChildren => foundChildren.node === c) == undefined) {
            tmpChildren.push(c);
          }
        });
        /* Sostituisco la lista figli con la temporanea. */
        unchecked.children = tmpChildren.concat([]);
      });
      /* Sostituisco il datasource con il temporaneo. Esso conterrà solo nodi non precedentemente selezionati. */
      this.dataSource.data = tmp.concat([]);
    });
  }

  /**
   * Metodo di supporto per la rimozione. Rimuove un nodo di WBS.
   * @param node Nodo da rimuovere.
   */
  private removeNode(node: FlatNode): void {
    if (node.level === 0) {
      const index = this.dataSource.data.indexOf(node.node);
      this.dataSource.data.splice(index, 1);
    } else {
      const parent = this.getParent(node);
      const childIndex = parent.node.children.indexOf(node.node);
      parent.node.children.splice(childIndex, 1);
    }
  }

  /**
   * Seleziona tutti i nodi di WBS.
   */
  onCheckAll(): void {
    const flag = !this.isAllSelected();
    this.treeControl.dataNodes.forEach(node => {
      node.checked = flag
    });
  }

  /**
   * Aggiunge un nuovo record di WBS. E' il metodo unico per le diverse operazioni di aggiunta possibili. Apre il dialog di aggiunta
   * nuovo nodo di WBS e memorizza nella WBS l'informazione al salvataggio dal dialog.
   * @param node Nodo di riferimento per l'aggiunta. Può essere il nodo OR/WP per il quale si vuole aggiungere la prima attività o
   *             il nodo attività sotto al quale aggiungere la nuova attività.
   *             Se è UNDEFINED, ci si ritrova nel caso di aggiunta di primo nodo OR/WP della WBS.
   * @param level Indica il livello di aggiunta del nodo. Se è 0, si sta aggiungendo un OR/WP, altrimenti un'attività.
   */
  onAddRecord(node: FlatNode, level: number): void {
    sessionStorage.setItem("gestione_wbs_bando", JSON.stringify(this.bando));
    sessionStorage.setItem("gestione_wbs_progetto", JSON.stringify(this.progetto));
    sessionStorage.setItem("gestione_wbs_parent_datasource", JSON.stringify(this.dataSource.data));
    sessionStorage.setItem("gestione_wbs_level", level.toString());

    const addRecordDialogRef = this.aggiuntaRecordDialog.open(AggiuntaRecordWbsDialogComponent);
    addRecordDialogRef.afterClosed().subscribe(
      result => {
        if (result != undefined) {
          if (this.checkRecordForDuplicatedNames(result.record.nome) === false) {
            if (level === 0) {
              result.recordsSecondoLivello = [];
              if (node == undefined) {
                /* Aggiunta di primo OR/WP. */
                this.dataSource.data.push({data: result, children: []});
              } else {
                /* Aggiunta nuovo OR/WP. */
                const index = this.dataSource.data.indexOf(this.dataSource.data.find(
                  found => found.data.record.nome.trim().toUpperCase() === node.node.data.record.nome.trim().toUpperCase()));
                this.dataSource.data.splice(index + 1, 0, {data: result, children: []});
              }
              this.refreshTree();
            } else {
              result.recordsSecondoLivello = [];
              const parent = node.level === 0 ? node : this.getParent(node);
              if (node.level === 0) {
                /* Aggiunta prima attività. */
                parent.node.children.push({data: result, children: []});
                const parentIndex = this.dataSource.data.indexOf(this.dataSource.data.find(
                  found => found.data.record.nome.trim().toUpperCase() === node.node.data.record.nome.trim().toUpperCase()));
                this.dataSource.data[parentIndex] = parent.node;
              } else {
                /* Aggiunta nuova attività. */
                const index = parent.node.children.indexOf(parent.node.children.find(
                  found => found.data.record.nome.trim().toUpperCase() === node.node.data.record.nome.trim().toUpperCase()));
                parent.node.children.splice(index + 1, 0, {data: result, children: []});
                const parentIndex = this.dataSource.data.indexOf(this.dataSource.data.find(
                  found => found.data.record.nome.trim().toUpperCase() === node.node.data.record.nome.trim().toUpperCase()));
                this.dataSource.data[parentIndex] = parent.node;
              }
              this.refreshTree();
              this.restoreExpansion(parent);
            }
          } else this.showError("Impossibile memorizzare record di WBS con nomi uguali. Modificare i nomi e riprovare.");
        }
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Modifica un record di WBS. E' il metodo unico per le diverse operazioni di modifica possibili. Apre il dialog di modifica
   * nodo di WBS e memorizza nella WBS l'informazione al salvataggio dal dialog.
   * @param node Nodo da modificare. Può essere un nodo OR/WP o un nodo attività.
   * @param level Indica il livello di modifica del nodo. Se è 0, si sta modificando un OR/WP, altrimenti un'attività.
   */
  onEditRecord(node: FlatNode, level: number): void {
    sessionStorage.setItem("gestione_wbs_bando", JSON.stringify(this.bando));
    sessionStorage.setItem("gestione_wbs_progetto", JSON.stringify(this.progetto));
    sessionStorage.setItem("gestione_wbs_parent_datasource", JSON.stringify(this.dataSource.data));
    sessionStorage.setItem("gestione_wbs_level", level.toString());
    sessionStorage.setItem("gestione_wbs_node", JSON.stringify(node.node.data));

    const addRecordDialogRef = this.aggiuntaRecordDialog.open(AggiuntaRecordWbsDialogComponent);
    addRecordDialogRef.afterClosed().subscribe(
      result => {
        if (result != undefined) {
          if (result.record.nome.trim().toUpperCase() === node.node.data.record.nome.trim().toUpperCase() ||
            this.checkRecordForDuplicatedNames(result.record.nome) === false) {
            if (level === 0) result.recordSecondoLivello = node.node.recordsSecondoLivello;
            node.node.data = result;
          } else this.showError("Impossibile memorizzare record di WBS con nomi uguali. Modificare i nomi e riprovare.");
        }
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Salva una nuova versione di WBS. Controlla se non vi siano duplicati e, in caso affermativo, innesca il salvataggio.
   */
  onSaveWbs(): void {

    if (this.checkForDuplicatedNames() === false && this.checkForNodesErrors() === false) {
      this.rotellina.openDialog();
      this.openSnackBar("Salvataggio di una nuova versione di WBS in corso...", "Chiudi");
      this.saveWbs(this.getWbsWrapperForSaving(), (res) => {
        if (res != undefined && res === true) {
          this.thisDialogRef.close(true);
          this.rotellina.closeDialog();
        }
      });
    } else this.showError("Impossibile memorizzare la WBS. Controllare gli elementi e riprovare.");
  }

  /**
   * Apre il dialog di visualizzazione Gantt di WBS con i dati nell'albero (anche non ancora salvati sul database).
   */
  onGenerateGantt(): void {
    const ganttDialogRef = this.ganttDialog.open(GanttComponent, {
      data: {wbs: this.dataSource.data, length: this.getGanttLength()}
    });
  }


  onStoricoVersioni(): void {
    const ganttDialogRef = this.ganttDialog.open(StoricoWbsComponent, {
      height: '80%',
      width: '80%',
      data: {progetto: this.progetto}
    });
  }


  /**
   * Apre il dialog di import WBS da formulario.
   */
  onImportWbs(): void {
    const importWbsDialogRef = this.importWbsDialog.open(ImportWbsModalComponent);
    importWbsDialogRef.afterClosed().subscribe(
      result => {
        if (result != undefined && result.length > 0) {
          if (this.wbs == undefined) {
            this.wbs = {master: {id: undefined, versione: 1, progetto: this.progetto}, recordsPrimoLivello: []};
          }
          this.wbs.recordsPrimoLivello = result;
          this.initDataSource();
        }
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  onOpenChat(): void {
    if (this.isChatOpen) {
      this.chatDialogRef.close();
    } else {
      this.isChatOpen = true;
      this.chatDialogRef = this.promptGeneratorModalDialog.open(PromptGeneratorComponentComponent, {
        data: {
          context: "wbs",
          nomeProgetto: this.progetto.nome,
          idOrganizzazione: this.progetto.organizzazione.id
        },
        panelClass: 'custom-chat-container',
        hasBackdrop: false,
        disableClose: false,
        position: {
          bottom: `80px`,
          right: `10px`,
        },
      });
    }

    this.chatDialogRef.afterClosed().subscribe(result => {
      this.isChatOpen = false;
    })
  }

  /**
   * Metodo di supporto per il dialog di visualizzazione Gantt. Restituisce la lunghezza del Gantt, ovvero
   * numero di nodi OR/WP * numero di attività in ognuno.
   */
  private getGanttLength(): number {
    var cnt = 0;
    this.dataSource.data.forEach(parent => {
      cnt += 1 + (parent.children != undefined ? parent.children.length : 0);
    });
    return cnt;
  }

  /**
   * Metodo di supporto per il salvataggio. Restituisce TRUE se la WBS contiene duplicati di nome in base al record in input, FALSE altrimenti.
   * Non vi possono essere due nodi WBS (indip. dal tipo) con nome uguale.
   * @param recordName Nome del record da controllare.
   */
  private checkRecordForDuplicatedNames(recordName): boolean {
    var names: string[] = [];
    this.dataSource.data.forEach(e => {
      names.push(e.data.record.nome.trim().toUpperCase());
      e.children.forEach(c => {
        names.push(c.data.record.nome.trim().toUpperCase());
      });
    });
    var flag: boolean = false;
    names.forEach(name => {
      if (name === recordName.trim().toUpperCase()) {
        flag = true;
        return;
      }
    });
    return flag;
  }

  /**
   * Controlla che tutti i nodi WBS siano provvisti dei dati fondamentali.
   */
  private checkForNodesErrors(): boolean {
    var flag = false;
    console.log(this.dataSource);
    this.dataSource.data.forEach(e => {
      const data = e.data;
      if (this.checkForNodeError(data) === true) {
        flag = true;
        return;
      }
      if (e.children.find(found => this.checkForNodeError(found.data) === true) != undefined) {
        flag = true;
        return;
      }
    });
    return flag;
  }

  /**
   * Controlla che un nodo di WBS sia provvisto dei dati fondamentali.
   * @param node Nodo di WBS.
   */
  private checkForNodeError(node): boolean {
    if (node.record.nome == undefined || node.record.nome.length === 0 || node.record.dataInizio == undefined || node.record.dataFine == undefined ||
      node.sediStakeholders == undefined || node.sediStakeholders.length === 0) {
      return true;
    }
    return false;
  }

  /**
   * Metodo di supporto per il salvataggio. Restituisce TRUE se la WBS contiene duplicati di nome, FALSE altrimenti.
   * Non vi possono essere due nodi WBS (indip. dal tipo) con nome uguale.
   */
  private checkForDuplicatedNames(): boolean {
    var names: string[] = [];
    this.dataSource.data.forEach(e => {
      names.push(e.data.record.nome.trim().toUpperCase());
      e.children.forEach(c => {
        names.push(c.data.record.nome.trim().toUpperCase());
      });
    });
    var flag: boolean = false;
    names.forEach(name => {
      if (names.filter(filtered => filtered === name).length > 1) {
        flag = true;
      }
    });
    return flag;
  }

  /**
   * Metodo di supporto per il salvataggio. Restituisce il nuovo wrapper di WBS da trasferire al back-end
   * per il salvataggio.
   */
  private getWbsWrapperForSaving(): any {
    var res = {
      master: {
        id: undefined,
        versione: this.wbs == undefined ? 1 : (this.wbs.master.versione + 1),
        timestampCreazione: undefined,
        dataInizioValidita:this.wbs.master.dataInizioValidita ,
        descrizione: this.wbs.master.descrizione,
        chiusa: false,
        progetto: this.progetto
      },
      recordsPrimoLivello: []
    };
    this.dataSource.data.forEach((pl, i) => {
      pl.data.record.ordinamento = i;
      pl.data.recordsSecondoLivello = [];
      pl.children.forEach((sl, j) => {
        sl.data.record.ordinamento = j;
        pl.data.recordsSecondoLivello.push(sl.data)
      });
      res.recordsPrimoLivello.push(pl.data);
    });
    return res;
  }

  /**
   * Restituisce il parent di un nodo WBS. Restituisce l'OR/WP dell'attività, oppure UNDEFINED se richiamato
   * su un OR/WP.
   * @param node Nodo di WBS di riferimento.
   */
  getParent(node: FlatNode): FlatNode {
    return this.treeControl.dataNodes.find(function (found) {
      return found.level === 0 && found.node.children.indexOf(node.node) >= 0;
    });
  }

  /**
   * Restituisce tutti i nodi WBS OR/WP selezionati.
   */
  getAllCheckedNodes(): FlatNode[] {
    return this.treeControl.dataNodes.filter(filtered => filtered.checked === true).concat([]);
  }

  /**
   * Restituisce tutte le attività selezionate di un OR/WP.
   * @param parent Nodo OR/WP di riferimento.
   */
  getAllCheckedChildren(parent: FlatNode): FlatNode[] {
    return this.treeControl.getDescendants(parent).filter(filtered => filtered.checked === true).concat([]);
  }

  /**
   * Restituisce TRUE se è possibile permettere di creare la prima attività per un OR/WP, FALSE altrimenti.
   * @param parent OR/WP di riferimento.
   */
  isAddSecondLevelNodeOptionActive(parent: FlatNode): boolean {
    if (this.progetto.bando.confLivelloAttivitaInWbs === false) return false;
    else return parent.node.children.length === 0;
  }

  /**
   * Restituisce TRUE se tutti i nodi sono selezionati, FALSE altrimenti.
   */
  isAllSelected(): boolean {
    return this.treeControl.dataNodes.find(found => found.checked === false) == undefined;
  }

  /**
   * Inizializza l'oggetto progetto dal parent, prelevandolo dal session storage.
   */
  initProgettoObject(): void {
    const item = sessionStorage.getItem("progetto");
    if (item == undefined) {
      this.showError("Errore nell'inizializzazione del progetto di riferimento.");
      return;
    }
    this.progetto = JSON.parse(item);
    sessionStorage.removeItem("progetto");
  }

  /**
   * Inizializza l'oggetto bando, prelevandolo dall'oggetto progetto.
   */
  initBandoObject(): void {
    if (this.progetto == undefined || this.progetto.bando == undefined) {
      this.showError("Errore nell'inizializzazione del bando di riferimento.");
      return;
    }
    this.bando = this.progetto.bando;
  }

  /**
   * Inizializza il datasource dell'albero WBS tramite i dati nel wrapper WBS dal database.
   */
  initDataSource(): void {
    var tmpData = [];
    if (this.wbs != undefined) {
      this.wbs.recordsPrimoLivello.forEach(r1 => {
        var children = [];
        r1.recordsSecondoLivello.forEach(r2 => {
          children.push({data: r2, children: []});
        });
        tmpData.push({data: r1, children: children});
      });
    }
    this.dataSource.data = tmpData.concat([]);
  }

  /**
   * Ricarica la vista dell'albero WBS.
   */
  refreshTree(): void {
    this.dataSource.data = this.dataSource.data.concat([]);
  }

  /**
   * Recupera l'espansione di un nodo OR/WP in WBS.
   * @param node Nodo OR/WP da espandere.
   */
  restoreExpansion(node: FlatNode): void {
    if (node != undefined)
      this.treeControl.expand(this.treeControl.dataNodes.find(found => node.node.data.record.nome === found.node.data.record.nome));
  }

  /**
   * Mostra un messaggio di notifica all'utente.
   * @param message Messaggio di notifica da mostrare.
   * @param action Azione da permettere.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String): void {
    this.dialog.open(WarningMessageComponent, {
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  /**
   * Mostra un messaggio di richiesta di conferma all'utente.
   * @param message Messaggio di richiesta di conferma.
   * @param submessage Messaggio inferiore.
   * @param _callback Azione successiva, in caso di conferma della richiesta.
   */
  askConfirm(message: string, submessage: string, _callback): void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent, {
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res) _callback();
      }
    );
  }

  ngOnInit() {
    this.initProgettoObject();
    this.initBandoObject();
    this.loadWbs(this.progetto.nome, this.progetto.organizzazione.id, (wbs) => {
      console.log(wbs);
      if (wbs == null){
        this.gestioneWbsService.saveWbsMaster({progetto:{id:this.progetto.id}}).subscribe(
          data => {
            this.loadWbs(this.progetto.nome, this.progetto.organizzazione.id, (wbs) => {
              this.initWbs(wbs);
            });
          },
          err => {
            this.showError(err.error.message);
          }
        );
      }
      else{
        //this.wbs = wbs;
        this.initWbs(wbs);
      }
    });
    this.inscadenza = this.partnerInScadenzaComponent.getPartnterInScadenza(this.progetto.id);
  }

  private initWbs(wbs){
    this.wbs = wbs;
    if(this.wbs.master.descrizione == "undefined" || this.wbs.master.descrizione == undefined) this.wbs.master.descrizione = "";
    //this.wbs.master.dataInizioValidita =this.wbs.master.dataInizioValidita?  new Date(this.wbs.master.dataInizioValidita).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]

    var data;
    if (this.wbs.master.dataInizioValidita){
      data = new Date(this.wbs.master.dataInizioValidita).toLocaleDateString();
    }
    else{
      data = new Date().toLocaleDateString();
    }
    this.wbs.master.dataInizioValidita = data.split('/')[2] + '-';
    if (data.split('/')[1].length == 1)
      this.wbs.master.dataInizioValidita = this.wbs.master.dataInizioValidita  + '0';
    this.wbs.master.dataInizioValidita = this.wbs.master.dataInizioValidita  + data.split('/')[1] + '-';
    if (data.split('/')[0].length == 1)
      this.wbs.master.dataInizioValidita = this.wbs.master.dataInizioValidita  + '0';
    this.wbs.master.dataInizioValidita = this.wbs.master.dataInizioValidita  + data.split('/')[0];

    this.initDataSource();
    this.loadingComponent = false;
  }

}
