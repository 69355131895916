import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import { AggiuntaRecordWbsDialogService } from './aggiunta-record-wbs-dialog.service';
import { ConfirmMessageComponent } from 'src/app/modals/confirm-message/confirm-message.component';
import { TreeElement } from '../../gestione-wbs.component';
import { DateAdapter, MatSnackBar } from '@angular/material';
import { DettaglioGestionePartnerProgettoModalComponent } from 'src/app/gestione-partner-progetto/modals/dettaglio-gestione-partner-progetto-modal/dettaglio-gestione-partner-progetto-modal.component';

export class DialogData {}

@Component({
  selector: 'app-aggiunta-record-wbs-dialog',
  templateUrl: './aggiunta-record-wbs-dialog.component.html',
  styleUrls: ['./aggiunta-record-wbs-dialog.component.css']
})
export class AggiuntaRecordWbsDialogComponent implements OnInit {

  NOME_MAX_LENGTH = 100;
  DESCRIZIONE_MAX_LENGTH = 350;

  NOME_DELIVERABLE_MAX_LENGTH = 45;
  DESCRIZIONE_DELIVERABLE_MAX_LENGTH = 250;

  loading: boolean = true;
  title: string = undefined;

  allChecked: boolean = false;
  allResponsabili: boolean = false;

  bando = undefined;
  progetto = undefined;
  parentDataSource: TreeElement[] = undefined;
  wbsNode = undefined;
  level: number = undefined;

  tipiAttivitaCofinanziabili = [];
  sediStakeholder = [];
  deliverables = [];

  sediStakeholderTableColumns = ['check', 'nome', 'responsabile'];
  deliverablesTableColumns = ['nome', 'descrizione', 'add/remove'];

  constructor(
    public thisDialogRef: MatDialogRef<AggiuntaRecordWbsDialogComponent>,
    public dialog: MatDialog,
    private adapter: DateAdapter<any>,
    public confirmDialogRef: MatDialogRef<ConfirmMessageComponent>,
    public aggiuntaRecordWbsDialogService: AggiuntaRecordWbsDialogService,
    public snackBar: MatSnackBar,
  ) {
    this.adapter.setLocale("it");
  }

  /**
   * Carica tutte le sedi stakeholder dal database. Restituisce tutte le sedi (principali e non) associate a
   * tutti gli stakeholder che sono partner per il progetto considerato.
   * @param idProgetto Id del progetto considerato.
   * @param _callback Azione successiva.
   */
  loadSediStakeholder(idProgetto, _callback) : void {
    this.aggiuntaRecordWbsDialogService.getSediStakeholder(idProgetto).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica tutti i tipi di attività cofinanziabili previsti dal bando di progetto.
   * @param idBando Id del bando di riferimento.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadTipiAttivitaCofinanziabili(idBando, idOrganizzazione, _callback) : void {
    this.aggiuntaRecordWbsDialogService.getTipiAttivitaCofinanziabili(idBando, idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Seleziona tutti gli stakeholder nel dialog.
   * @param event Evento scatenato.
   */
  onCheckAll(event) : void {
    event.preventDefault();
    this.allChecked = !this.allChecked;
    this.sediStakeholder.forEach(ss => ss.checked = this.allChecked);
  }

  /**
   * Seleziona tutti gli stakeholder nel dialog come responsabili.
   * @param event Evento scatenato.
   */
  onMakeAllResponsabili(event) : void {
    event.preventDefault();
    this.allResponsabili = !this.allResponsabili;
    this.sediStakeholder.forEach(ss => ss.responsabile = this.allResponsabili);
  }

  /**
   * Aggiunge un nuovo deliverable al nodo OR/WP considerato.
   */
  onAddDeliverable() : void {
    this.deliverables.push({nome: undefined, descrizione: undefined});
    this.deliverables = this.deliverables.concat([]);
  }

  /**
   * Rimuove un deliverable dal nodo OR/WP considerato.
   * @param deliverable
   */
  onRemoveDeliverable(deliverable) : void {
    this.deliverables.splice(this.deliverables.indexOf(deliverable), 1);
    this.deliverables = this.deliverables.concat([]);
  }

  /**
   * Trasferisce il nodo OR/WP considerato al parent e chiude il dialog.
   */
  onSave() : void {
    const msg = this.checkForErrors();
    if(msg != undefined) this.showError(msg);
    else {
      this.prepareForSaving();
      this.thisDialogRef.close(this.wbsNode);
    }
  }

  /**
   * Gestisce l'evento di cambiamento di una data (inizio o fine).
   * @param mode Indicatore della data considerata. Può essere "inizio" o "fine".
   * @param event Evento scatenato.
   */
  onDateChange(mode: string, event) : void {
    if(mode === "inizio") {
      this.wbsNode.record.dataInizio = event.value;
    }
    else if(mode === "fine") {
      this.wbsNode.record.dataFine = event.value;
    }
  }

  onDettaglioGestione() : void {
    const dialogRef = this.dialog.open(DettaglioGestionePartnerProgettoModalComponent, {
      data: {
        progetto: this.progetto
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result == true) {
        this.openSnackBar("Dettaglio di gestione dei partner di progetto salvato con successo.", "Chiudi");
      }
    });
  }

  /**
   * Metodo di supporto per il salvataggio. Organizza i dati nel wrapper da comunicare al parent.
   */
  private prepareForSaving() : void {
    this.wbsNode.sediStakeholders = [];
    this.wbsNode.deliverables = [];
    if(this.level === 0) this.prepareForSavingPrimoLivello();
    else this.prepareForSavingSecondoLivello();
  }

  /**
   * Metodo di supporto per il salvataggio. Organizza i dati nel wrapper da comunicare al parent.
   * Specializzazione nel caso di nodo OR/WP.
   */
  private prepareForSavingPrimoLivello() : void {
    this.wbsNode.sediStakeholders = [];
    this.wbsNode.deliverables = [];

    this.sediStakeholder.forEach(ss => {
      if(ss.checked === true) {
        this.wbsNode.sediStakeholders.push({
          sedeStakeholder: ss.data,
          recordWbsPrimoLivello: this.wbsNode.record,
          responsabile: ss.responsabile
        });
      }
    });
    this.deliverables.forEach(d => {
      this.wbsNode.deliverables.push({
        recordWbsPrimoLivello: this.wbsNode.record,
        deliverable: {nome: d.nome, descrizione: d.descrizione, percentualeCompletamento: 0, consegnato: false, dataConsegna: undefined}
      });
    });
  }

  /**
   * Metodo di supporto per il salvataggio. Organizza i dati nel wrapper da comunicare al parent.
   * Specializzazione nel caso di nodo attività.
   */
  private prepareForSavingSecondoLivello() : void {
    this.wbsNode.sediStakeholders = [];
    this.wbsNode.deliverables = [];

    this.sediStakeholder.forEach(ss => {
      if(ss.checked === true) {
        this.wbsNode.sediStakeholders.push({
          sedeStakeholder: ss.data,
          recordWbsSecondoLivello: this.wbsNode.record,
          responsabile: ss.responsabile
        });
      }
    });
    this.deliverables.forEach(d => {
      this.wbsNode.deliverables.push({
        recordWbsSecondoLivello: this.wbsNode.record,
        deliverable: {nome: d.nome, descrizione: d.descrizione, percentualeCompletamento: 0, consegnato: false, dataConsegna: undefined}
      });
    });
  }

  /**
   * Restituisce il messaggio da mostrare all'utente nel caso vi siano errori, UNDEFINED altrimenti.
   */
  checkForErrors() : string {
    if(this.wbsNode.record.nome == undefined || this.wbsNode.record.nome.length === 0)
      return "Impossibile memorizzare un record WBS privo di nome.";
    if(this.wbsNode.record.nome.length > this.NOME_MAX_LENGTH)
      return "Il nome inserito è troppo lungo.";
    if(this.wbsNode.record.descrizione != undefined && this.wbsNode.record.descrizione.length > this.DESCRIZIONE_MAX_LENGTH)
      return "Il titolo inserito è troppo lungo.";
    if(this.wbsNode.record.dataInizio == undefined || this.wbsNode.record.dataFine == undefined)
      return "Impossibile memorizzare un record WBS privo di data di inizio e data di fine.";
    if(this.wbsNode.record.dataInizio > this.wbsNode.record.dataFine)
      return "Impossibile memorizzare una data di inizio successiva a una data di fine.";
    if(this.sediStakeholder.find(found => found.checked === true) == undefined)
      return "Indicare almeno uno stakeholder.";
    if(this.sediStakeholder.find(found => found.responsabile === true) == undefined)
      return "Indicare almeno uno stakeholder responsabile.";
    var res = undefined;
    if(this.deliverables != undefined && this.deliverables.length > 0) {
      this.deliverables.forEach(deliverable => {
        if(deliverable.nome == undefined || deliverable.nome.length === 0) {
          res = "Impossibile memorizzare deliverables privi di nome."; return;
        }
        if(deliverable.nome.length > this.NOME_DELIVERABLE_MAX_LENGTH) {
          res = "I nomi di deliverable indicati sono troppo lunghi."; return;
        }
        if(deliverable.descrizione != undefined && deliverable.descrizione.length > this.DESCRIZIONE_DELIVERABLE_MAX_LENGTH) {
          res = "Le descrizioni di deliverable indicate sono troppo lunghe."; return;
        }
      });
    }
    return res;
  }

  /**
   * Inizializza i dati provenienti dal parent, memorizzati nel session storage.
   * @param _callback Azione successiva.
   */
  initFieldsFromParent(_callback) : void {
    const progettoRef = sessionStorage.getItem("gestione_wbs_progetto");
    if(progettoRef != undefined) {
      this.progetto = JSON.parse(progettoRef);
      sessionStorage.removeItem("gestione_wbs_progetto");
    }
    const bandoRef = sessionStorage.getItem("gestione_wbs_bando");
    if(bandoRef != undefined) {
      this.bando = JSON.parse(bandoRef);
      sessionStorage.removeItem("gestione_wbs_bando");
    }
    const parentDataSourceRef = sessionStorage.getItem("gestione_wbs_parent_datasource");
    if(parentDataSourceRef != undefined) {
      this.parentDataSource = JSON.parse(parentDataSourceRef);
      sessionStorage.removeItem("gestione_wbs_parent_datasource");
    }
    const wbsNodeRef = sessionStorage.getItem("gestione_wbs_node");
    if(wbsNodeRef != undefined) {
      this.wbsNode = JSON.parse(wbsNodeRef);
      sessionStorage.removeItem("gestione_wbs_node");
    }
    const level = sessionStorage.getItem("gestione_wbs_level");
    if(level != undefined) {
      this.level = +level;
      sessionStorage.removeItem("gestione_wbs_level");
    }
    if(this.parentDataSource != undefined && this.bando != undefined && this.progetto != undefined
      && level != undefined && (this.level === 0 || this.level === 1))
      _callback();
  }

  /**
   * Inizializza il titolo del dialog.
   */
  initTitle() : void {
    var tmp = "";
    tmp = tmp + (this.wbsNode == undefined ? "Aggiunta " : "Modifica ");
    tmp = tmp + (this.level === 0 ? "OR/WP" : "Attività");
    this.title = tmp;
  }

  /**
   * Inizializza il nodo WBS di riferimento.
   */
  initWbsNode() : void {
    this.wbsNode = {
      record: {
        nome: undefined,
        descrizione: undefined,
        dataInizio: undefined,
        dataFine: undefined,
        tipoAttivitaCofinanziabile: undefined
      },
      sediStakeholders: [],
      deliverables: []
    };
  }

  /**
   * Inizializza i dati dal database. Carica a cascata tipi di attività cofinanziabili e sedi stakeholder partner di progetto.
   * @param _callback Azione successiva.
   */
  initDataFromDatabase(_callback) : void {
    this.loadTipiAttivitaCofinanziabili(this.bando.id, this.bando.organizzazione.id, (tac) => {
      this.tipiAttivitaCofinanziabili = tac;
      this.loadSediStakeholder(this.progetto.id, (ss) => {
        ss.forEach(sedeStakeholder => {
          this.sediStakeholder.push({checked: false, data: sedeStakeholder, responsabile: false});
        });
        _callback();
      });
    });
  }

  /**
   * Inizializza i dati dal nodo WBS, in caso di modifica di un nodo.
   */
  initDataFromWbsNode() : void {
    if(this.wbsNode.sediStakeholders != undefined && this.deliverables != undefined) {
      this.wbsNode.sediStakeholders.forEach(ss => {
        var ssRef = this.sediStakeholder.find(found => found.data.id === ss.sedeStakeholder.id);
        if(ssRef != undefined) { ssRef.checked = true; ssRef.responsabile = ss.responsabile; }
      });
      this.wbsNode.deliverables.forEach(d => {
        this.deliverables.push(d.deliverable);
      });
    }
    console.log("NODE DATE BEFORE INIT", this.wbsNode.record.dataInizio);
    if(this.wbsNode.record.dataInizio != undefined && !isNaN(this.wbsNode.record.dataInizio))
      this.wbsNode.record.dataInizio = new Date(this.wbsNode.record.dataInizio);
    console.log("NODE DATE", this.wbsNode.record.dataInizio);
    if(this.wbsNode.record.dataFine != undefined && !isNaN(this.wbsNode.record.dataFine))
      this.wbsNode.record.dataFine = new Date(this.wbsNode.record.dataFine);
    if(this.wbsNode.record.tipoAttivitaCofinanziabile != undefined) {
      this.wbsNode.record.tipoAttivitaCofinanziabile = this.tipiAttivitaCofinanziabili.find(
        found => found.id === this.wbsNode.record.tipoAttivitaCofinanziabile.id);
    }
    this.wbsNode.record.dataInizio.setTime(this.wbsNode.record.dataInizio.getTime() - this.wbsNode.record.dataInizio.getTimezoneOffset()*60000);
    this.wbsNode.record.dataFine.setTime(this.wbsNode.record.dataFine.getTime() - this.wbsNode.record.dataFine.getTimezoneOffset()*60000);
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  ngOnInit() : void {
    this.initFieldsFromParent(() => {
      this.initTitle();
      this.initDataFromDatabase(() => {
        if(this.wbsNode == undefined) this.initWbsNode();
        else this.initDataFromWbsNode();
        this.loading = false;
      });
    });
    console.log("DATE", new Date());
  }

}
