import { Component, OnInit } from '@angular/core';
import { UserStorage } from '../../core_modules/user.storage';
import { Router } from '@angular/router';
import { UtilityModuleComponent } from '../../core_modules/utility-module/utility-module.component';
import { ConfirmMessageComponent } from '../../modals/confirm-message/confirm-message.component';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private userStorage: UserStorage, 
    private router: Router, 
    private utilityModule: UtilityModuleComponent,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>
    ) { }
    
  username=sessionStorage.getItem("Email");
  organizzazioneName=sessionStorage.getItem("Organization");

  openLogoutModal(){
    let sidenav_elem: HTMLElement=document.getElementsByClassName("sidenav")[0] as HTMLElement;

    if(sidenav_elem.style.visibility=="visible")
    {
      let elem: HTMLElement=document.getElementsByClassName("hamburger-button")[0] as HTMLElement;
      elem.click();
    }

    this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: "Vuoi davvero effettuare il logout?",
        submessage: ""
      },
      panelClass: 'custom-confirm-container'
    });

    this.dialogRef.afterClosed().subscribe(
      res => {
        
        if(res==true)
        {
            this.logout();
        }
      }
    );    
  }

  logout()
  {
    this.userStorage.signOut();
    this.utilityModule.redirectAdminOrganizzazione("login");
  }

  ngOnInit() {
    this.username=sessionStorage.getItem("Email");
    this.organizzazioneName=sessionStorage.getItem("Organization");
  }

  ngAfterViewInit(){
    
  }

}
