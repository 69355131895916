import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../../../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ImportWbsModalService {

    constructor(private http: HttpClient) {}

    public upload(file: File): Observable<any> {
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      const req = new HttpRequest('POST', AppSettings.BASE_URL+'file-uploader/upload', formData, {reportProgress: true, responseType: 'text'});
      return this.http.request(req);
    }

    public extract(fileName): Observable<any> {
      return this.http.get<any>(AppSettings.BASE_URL+'info-extraction/extract-wbs?fileName=' + fileName);
    }

}