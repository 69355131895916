import {Component, OnInit, ViewChild, ViewContainerRef, Injectable, Inject, LOCALE_ID } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource, MatPaginator } from '@angular/material';
import { OrganizzazioniService } from 'src/app/organizzazioni/organizzazioni.service';
import { UserStorage } from 'src/app/core_modules/user.storage';
import { ConfirmMessageComponent } from 'src/app/modals/confirm-message/confirm-message.component';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import { BehaviorSubject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { GestioneBandiService } from 'src/app/gestione-bandi/gestione-bandi.service';
import { CreazioneDatiProgettoService } from './creazione-dati-progetto.service';

//Oggetto nodo progetto
export class TableProjectNode {
  projectid : number = null;
  projectname : string = null;
  startdate: string = null;
  targetenddate: string = null;
  actualenddate: string = null;
  projecturl : string = null;
  codice_interno : string = null;
  codice_ente_finanziatore : string = null;
  bando : string = null;
  sal_corrente : string = null;
  ultimo_sal : boolean = null;
  decreto_ammissione : string = null;
  data_ammissione: string = null;
  id_bando : number = null;
}

//Classe gestione tabella progetti
@Injectable()
export class TableProjectDatabase {

  //Variabili di gestione
  dataChange = new BehaviorSubject<TableProjectNode[]>([]);
  get data(): TableProjectNode[] { return this.dataChange.value; }

  //Classe di servizio del component owner che implementa i vari servizi verso il back-end invocati dal TableProjectDatabase
  private ownerComponentService: any;

  //Dialog dell'owner component
  private ownerComponentDialog: MatDialog;

  //Oggetto JSON, estratto con apposito querying del database, che contiene i progetti.
  jsonProjectTableStructure: any;

  //Nodi della tabella progetti
  projectTableNodesArray: TableProjectNode[];

  /**
   * Setter del service provider del component
   * @param x service provider
   */
  public setOwnerComponentService(x: any) {
    this.ownerComponentService = x;
  }

  /**
   * Setter del dialog provider del component
   * @param y dialog provider
   */
  public setOwnerComponentDialog(y: MatDialog) {
    this.ownerComponentDialog = y;
  }

  constructor(private adapter: DateAdapter<any>,
              @Inject(LOCALE_ID) private locale: string) {
    this.adapter.setLocale("it");
  }

  /**
   * Refresh dei nodi della tabella progetti
   */
  refreshProjectTableNodeStructure() {
    this.reloadProjectGestoriDatabaseStructure();
  }

  /**
   * Ricaricamenti progetti da API GESTORI
   */
  reloadProjectGestoriDatabaseStructure() {
    this.ownerComponentService.getProgettiFromGestori().subscribe(
      dataDb => {
        this.jsonProjectTableStructure = dataDb;
        console.log(JSON.stringify(dataDb));
        this.projectTableNodesArray = this.buildProjectNodes(this.jsonProjectTableStructure);
        const data = this.projectTableNodesArray;
        this.dataChange.next(data);
        console.log("Projects loaded from GESTORI API.", dataDb);
      },
      err => {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare i Progetti!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    )
  }
  /**
   * Metodo di gestione tabella: Build the nodes from Json object. The result is a list of `TableProjectNode` (from Angular Doc)
   * @param projectTableNodeData
   */
  buildProjectNodes(projectTableNodeData: any[]): TableProjectNode[] {
    let nodeArray = new Array<TableProjectNode>();
    if (projectTableNodeData != null) {
      try {
        projectTableNodeData.forEach(project => {
          const node = new TableProjectNode();
          node.projectid = project.projectid;
          node.projectname = project.projectname;
          if (project.startdate != null) {
            node.startdate = new Date(project.startdate).toLocaleDateString();
          } else {
            node.startdate = null;
          }
          if (project.targetenddate != null) {
            node.targetenddate = new Date(project.targetenddate).toLocaleDateString();
          } else {
            node.targetenddate = null;
          }
          if (project.actualenddate != null) {
            node.actualenddate = new Date(project.actualenddate).toLocaleDateString();
          } else {
            node.actualenddate = null;
          }
          node.projecturl = project.projecturl;
          node.codice_interno = project.codice_interno;
          node.codice_ente_finanziatore = project.codice_ente_finanziatore;
          node.bando = project.bando;
          node.sal_corrente = project.sal_corrente;
          if (project.ultimo_sal == 0) {
            node.ultimo_sal = false;
          } else {
            node.ultimo_sal = true;
          }
          node.decreto_ammissione = null;
          node.data_ammissione = null;
          node.id_bando = null;
          nodeArray.push(node);
        });
      } catch (error) {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare informazioni della tabella!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    }
    return nodeArray;
  }
}

@Component({
  selector: 'app-creazione-dati-progetto',
  templateUrl: './creazione-dati-progetto.component.html',
  styleUrls: ['./creazione-dati-progetto.component.css'],
  providers: [TableProjectDatabase]
})
export class CreazioneDatiProgettoComponent implements OnInit {

  //Oggetti controllo tabella progetti
  tableDisplayedColumns: string[] = ['seleziona', 'nome', 'dataInizio', 'dataFine'];
  projectTableDataSource: MatTableDataSource<TableProjectNode>;
  progettiTreeContentDatabase: any;
  tableChecklistselection = new SelectionModel<TableProjectNode>(false, []);
  projectTableFilteringString : String;

  //Costanti per utilizzo API GESTORI
  bodyJSON_ImportaProgetti : any = {
    api_uid: "123456",
    api_key: "a2f13d91f247fa55047303eea8862dda"
  }
  bodyText_ImportaProgetti: string = "{\"api_uid\": \"123456\", \"api_key\": \"a2f13d91f247fa55047303eea8862dda\" };";

  //Variabili di gestione
  amount = new FormControl('', [
    Validators.required
  ]);
  ammissione = new FormControl('', [
    Validators.required
  ]);

  constructor(
    private organizzazioniService: OrganizzazioniService,
    private userStorage: UserStorage,
    public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private projectTableContentDatabase: TableProjectDatabase,
    private bandiService: GestioneBandiService,
    private creazioneDatiProgettoService: CreazioneDatiProgettoService,
    public thisDialogRef: MatDialogRef<CreazioneDatiProgettoComponent>) {
      this.projectTableDataSource = new MatTableDataSource<TableProjectNode>();
      projectTableContentDatabase.dataChange.subscribe(data => this.projectTableDataSource.data = data);
  }

  //Variabili di gestione progetti
  organizzazioneObj = undefined;
  selectedProgettoId = null;
  nomeProgetto = null;
  nomeEstesoProgetto = null;
  codiceInterno = null;
  codiceEnteFinanziatore = null;
  myDate = new FormControl(null,[]);
  dataInizioProgetto = undefined;
  dataInizioProgettoPicker = new FormControl(null,[]);
  dataFinePresuntaProgetto = undefined;
  decretoAmmissione = null;
  dataAmmissione = undefined;
  selectedBandoId = null;
  nomeBando = null;
  bandoIdObj = undefined;
  idProgetto = undefined;
  bandiAttivi = null;
  selectedBandoFromCreate = undefined;

  //Indici di gestione tabella
  DATI_GENERALI_TAB_INDEX : number = 0;
  CONFIGURA_PRATICA_TAB_INDEX : number = 1;
  MODIFICA_ATTIVITA_TAB_INDEX : number = 2;

  //Variabili di interfaccia con figli del component [DA RIVEDERE]
  @ViewChild(MatPaginator) tablePaginator: MatPaginator;
  @ViewChild('udatePraticaContainer1', { read: ViewContainerRef }) udateDatiGeneraliPratica: ViewContainerRef;
  @ViewChild('udatePraticaContainer2', { read: ViewContainerRef }) configuraAttivitaPratica: ViewContainerRef;
  @ViewChild('udatePraticaContainer3', { read: ViewContainerRef }) modificaAttivita: ViewContainerRef;

  //Flag per riutilizzo component per modifica anagrafica progetto
  modifyMode: Boolean = true;

  //Flag per riutilizzo component per creazione progetto
  createMode: Boolean = true;

  //Variabili per modifica anagrafica progetto
  idProgettoFromModify = undefined;
  progettoFromModify = undefined;
  loadingComponent = true;

  /**
   * Setter per il flag di modalità modifica
   * @param flag flag da settare
   */
  setModifyMode(flag: Boolean) : void {
    this.modifyMode = flag;
    if(this.modifyMode == false) {
      this.createMode = false;
    }
  }

  onSaveAnagraficaUtente() : void {
    if(!this.createMode) {
      this.creazioneDatiProgettoService.updateProgetto({
        id: this.progettoFromModify.id,
        nome: this.progettoFromModify.nome,
        nomeEsteso: this.nomeEstesoProgetto,
        codiceInterno: this.codiceInterno,
        codiceEnteFinanziatore: this.codiceEnteFinanziatore,
        dataInizio: this.dataInizioProgetto,
        decretoAmmissioneFinanziamento:this.decretoAmmissione,
        dataAmmissioneFinanziamento:this.dataAmmissione,
        bando: this.progettoFromModify.bando,
        organizzazione: this.progettoFromModify.organizzazione
      }).subscribe(
        data => {
          this.openSnackBar("Anagrafica progetto salvata con successo.", "Chiudi");
          this.thisDialogRef.close();
        },
        err => {
          this.dialog.open(WarningMessageComponent,{
            data: {
              message: err.error.message
            },
            panelClass: 'custom-warning-container'
          });
        }
      )
    }
    else {
      if(this.nomeProgetto==null) {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: "Impossibile memorizzare un progetto privo di nome"
          },
          panelClass: 'custom-warning-container'
        });
        return;
      }
      if(this.dataInizioProgetto==undefined || this.dataAmmissione==undefined) {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: "Impossibile memorizzare un progetto privo di date"
          },
          panelClass: 'custom-warning-container'
        });
        return;
      }
      if(this.selectedBandoFromCreate==undefined) {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: "Impossibile memorizzare un progetto privo di bando di riferimento"
          },
          panelClass: 'custom-warning-container'
        });
        return;
      }
      this.thisDialogRef.close({
        id: undefined,
        nome: this.nomeProgetto,
        nomeEsteso: this.nomeEstesoProgetto,
        codiceInterno: this.codiceInterno,
        codiceEnteFinanziatore: this.codiceEnteFinanziatore,
        dataInizio: this.dataInizioProgetto,
        decretoAmmissioneFinanziamento:this.decretoAmmissione,
        dataAmmissioneFinanziamento:this.dataAmmissione,
        bando: this.selectedBandoFromCreate,
        organizzazione: this.organizzazioneObj
      });
      return;
    }
  }

  /**
   * Carica bandi attivi per organizzazione
   * @param organizzazione organizzazione
   */
  loadBandiAttiviOrganizzazione(organizzazione){
    this.bandiService.getAllByOrganizzazione(organizzazione.id).subscribe(
    data => {
      this.bandiAttivi=data;
      console.log("Announcements loaded from database.", data);
    });
  }

  /**
   * Caricamento progetti da GESTORI
   */
  getProgettiFromGestori() {
    this.creazioneDatiProgettoService.getProgettiFromGestori(this.bodyJSON_ImportaProgetti).subscribe(
    data => {
      this.bodyJSON_ImportaProgetti = data.content;
      this.bodyText_ImportaProgetti = JSON.stringify(this.bodyJSON_ImportaProgetti);
      console.log("Projects loaded from GESTORI API.", data);
    },
    err => {
      this.dialog.open(WarningMessageComponent,{
        data: {
          message: err.error.message
        },
        panelClass: 'custom-warning-container'
      });
     }
    );
  };

  /**
   * Applica filtro a tabella progetti
   * @param filterValue filtro da applicare
   */
  applyFilterToTable(filterValue: string) {
    this.projectTableDataSource.filter = filterValue.trim().toLowerCase();
    if (this.projectTableDataSource.paginator) {
      this.projectTableDataSource.paginator.firstPage();
    }
  }

  /**
   * Rimuove filtro da tabella progetti
   */
  clearFilterAndSelectionToTable() {
    this.projectTableDataSource.filter = null;
    this.tableChecklistselection.clear();
    if (this.projectTableDataSource.paginator) {
      this.projectTableDataSource.paginator.firstPage();
    }
  }

  /**
   * Metodo 1 manipolazione tabella progetti
   */
  isAllTableRowsSelected() {
    const numSelected = this.tableChecklistselection.selected.length;
    const numRows = this.projectTableDataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Metodo 2 manipolazione tabella progetti
   */
  tableMasterToggle() {
    this.isAllTableRowsSelected() ?
        this.tableChecklistselection.clear() :
        this.projectTableDataSource.data.forEach(row => this.tableChecklistselection.select(row));
  }

  /**
   * Metodo 3 manipolazione tabella progetti
   * @param row progetto selezionato
   */
  onTableSelectionChange(row: TableProjectNode) {
    if (!this.tableChecklistselection.isSelected(row)) {
      this.selectedProgettoId = row.projectid;
      this.nomeProgetto=row.projectname;
      this.nomeEstesoProgetto = row.projectname;
      this.codiceInterno = row.codice_interno;
      this.codiceEnteFinanziatore = row.codice_ente_finanziatore;

      if(row.startdate!=null){
      this.dataInizioProgetto = new Date(row.startdate.split("/").reverse().join("/"));
    }
      if(row.data_ammissione!=null){
      this.dataAmmissione = new Date(row.data_ammissione.split("/").reverse().join("/"));
      }
      if(row.targetenddate!=null){
      this.dataFinePresuntaProgetto = new Date(row.targetenddate.split("/").reverse().join("/"));
       }
      this.decretoAmmissione = null;
      let parametriDaPassare = {} as any;
      parametriDaPassare.nomeBando = row.bando;
      parametriDaPassare.idOrganizzazione = this.organizzazioneObj.id;

      this.creazioneDatiProgettoService.getIdBandoRendicontaFromNameBandoGestori(parametriDaPassare).subscribe(
          data => {
          if (!(data == null)){
            this.selectedBandoId = data.id;
            this.bandoIdObj= data;
          } else {
            this.selectedBandoId = null;
          }
        },
        err => {
          this.dialog.open(WarningMessageComponent, {
            data: {
              message: 'Impossibile caricare il Bando del progetto selezionato!'
            },
            panelClass: 'custom-warning-container'
          });
        }
      )
    }
  }

  /**
   * Metodo per selezione bando
   */
  onBandoselectionchange(){
    this.bandoIdObj = {id:this.selectedBandoId};
     console.log("**** " + this.bandoIdObj.id);
  }

  /**
   * Metodo 4 manipolazione tabella progetti
   * @param filterValue filtro
   */
  applyFilterToProgettiTree(filterValue: string) {
    if (filterValue && filterValue.length >= 2) {
      this.progettiTreeContentDatabase.filterTreeNodeStructure(filterValue);
    } else {
      this.progettiTreeContentDatabase.clearFilterTreeNodeStructure();
    }
  }

  /**
   * Metodo di refresh tabella progetti
   */
  refreshProgettiTable() {
    this.projectTableFilteringString = null;
    this.projectTableContentDatabase.refreshProjectTableNodeStructure();
    this.openSnackBar("Progetti ricaricati con successo","Chiudi");
  }

  /**
   * Generazione messaggio per l'utente
   * @param message messaggio da mostrare
   * @param action azione da permettere all'utente
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  /**
   * [DA RIVEDERE]
   */
  onConfiguraPraticaSelect() {}

  /**
   * [DA RIVEDERE]
   */
  onDettaglioAttivitaSelect() {}

  /**
   * Gestisce cambiamenti nella tabella
   * @param event evento scatenato
   */
  onTabSelectionChange(event: any) {
    if (event.selectedIndex == this.DATI_GENERALI_TAB_INDEX) {
    } else if (event.selectedIndex == this.CONFIGURA_PRATICA_TAB_INDEX) {
      this.onConfiguraPraticaSelect();
    } else if (event.selectedIndex == this.MODIFICA_ATTIVITA_TAB_INDEX) {
      this.onDettaglioAttivitaSelect();
    }
  }

  /**
   * Campo cambiato 1/2
   */
  onAmountChange() {
    this.atLeastOneErrorInStep1();
  }

  /**
   * Campo cambiato 2/2
   */
  onAmmissioneChange() {
    this.atLeastOneErrorInStep1();
  }

  /**
   * Controllo errori
   */
  atLeastOneErrorInStep1() {
    if ((this.nomeProgetto == null) || (this.amount.valid == false) )  {
      return true;
    }
    if ((this.dataInizioProgetto == null) || (this.dataAmmissione == null) ) {
      return true;
    }
    if (this.bandoIdObj == null) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.projectTableContentDatabase.setOwnerComponentService(this.creazioneDatiProgettoService);
    this.projectTableContentDatabase.setOwnerComponentDialog(this.dialog);
    this.projectTableContentDatabase.refreshProjectTableNodeStructure();
    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        this.organizzazioneObj=data;
        this.loadBandiAttiviOrganizzazione(this.organizzazioneObj);
      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
    if(this.modifyMode) {
      this.idProgettoFromModify = sessionStorage.getItem("onModifyAnagrafica.idProgetto");

      if(this.idProgettoFromModify) {
        this.createMode = false;

        sessionStorage.removeItem("onModifyAnagrafica.idProgetto");

        this.creazioneDatiProgettoService.getProgettoById(this.idProgettoFromModify).subscribe(
          data => {
            this.progettoFromModify = data;
            this.nomeProgetto = data.nome;
            this.nomeEstesoProgetto = data.nomeEsteso;
            this.codiceInterno = data.codiceInterno;
            this.codiceEnteFinanziatore = data.codiceEnteFinanziatore;
            this.dataInizioProgetto = new Date(data.dataInizio);
            this.dataAmmissione = new Date(data.dataAmmissioneFinanziamento);
            this.decretoAmmissione = data.decretoAmmissioneFinanziamento;
            this.selectedBandoId = data.bando.id;
            this.nomeBando = data.bando.nome;

            this.loadingComponent = false;
          },
          err => {
            this.dialog.open(WarningMessageComponent,{
              data: {
                message: err.error.message
              },
              panelClass: 'custom-warning-container'
            });
          }
        )
      }
    }
    if(this.createMode) {
      this.loadingComponent = false;
    }
  }

}
