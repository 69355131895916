import {MatIconModule} from '@angular/material/icon'
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {CustomMaterialModule} from './core_modules/material.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserComponent} from './user/user.component';
import {UserService} from './user/user.service';
import {AppRoutingModule} from './core_modules/app.routing.module';
import {LoginComponent} from './login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {WarningMessageComponent} from './modals/warning-message/warning-message.component';
import {AdminDashboard} from './admin-dashboard/admin.dashboard';
import {HeaderComponent} from './modals/header/header.component';
import {DashboardButtonComponent} from './modals/dashboard-button/dashboard-button.component';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {Interceptor} from './core_modules/app.interceptor';
import {AuthService} from './core_modules/auth.service';
import {TokenStorage} from './core_modules/token.storage';
import {OrganizzazioniComponent} from './organizzazioni/organizzazioni.component';
import {OrganizzazioniService} from './organizzazioni/organizzazioni.service';
import {ConfirmMessageComponent} from './modals/confirm-message/confirm-message.component';
import {DateAdapter, MatDialogRef} from '@angular/material';
import {MatDialog} from '@angular/material';
import {HttpModule} from '@angular/http';
import {UtentiOrganizzazioniComponent} from './utenti-organizzazioni/utenti-organizzazioni.component';
import {UtentiOrganizzazioniService} from './utenti-organizzazioni/utenti-organizzazioni.service';
import {AdminOrganizzazioneDashboardComponent} from './admin-organizzazione-dashboard/admin-organizzazione-dashboard.component';
import {UserStorage} from './core_modules/user.storage';
import {AdminOrganizzazioneDashboardService} from './admin-organizzazione-dashboard/admin-organizzazione-dashboard.service';
import {ImpostazioniProfiloComponent} from './impostazioni-profilo/impostazioni-profilo.component';
import {AnagraficaUtentiService} from './common-services/anagrafica-utenti.service';
import {UtentiOrganizzazioneComponent} from './utenti-organizzazione/utenti-organizzazione.component';
import {UtentiOrganizzazioneService} from './utenti-organizzazione/utenti-organizzazione.service';
import {UtilityModuleComponent} from './core_modules/utility-module/utility-module.component';
import {DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {UtilsService} from 'src/app/common-services/utils.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {GestioneBandiComponent} from './gestione-bandi/gestione-bandi.component';
import {GestioneBandiService} from './gestione-bandi/gestione-bandi.service';
import {GestioneVociDiCostoComponent} from './gestione-voci-di-costo/gestione-voci-di-costo.component';
import {GestioneVociDiCostoService} from './gestione-voci-di-costo/gestione-voci-di-costo.service';
import {CreaProgettoComponent} from './crea-progetto/crea-progetto.component';
import {CreaProgettoService} from './crea-progetto/crea-progetto.service';
import {GestioneProgettoComponent} from './gestione-progetto/gestione-progetto.component';
import {GestioneProgettoService} from './gestione-progetto/gestione-progetto.service';
import {TipoDiAttivitaCofinanziabileComponent} from './tipo-di-attivita-cofinanziabile/tipo-di-attivita-cofinanziabile.component';
import {TipoDiAttivitaCofinanziabileService} from './tipo-di-attivita-cofinanziabile/tipo-di-attivita-cofinanziabile.service';

import {TipoDiFinanziamentoComponent} from './tipo-di-finanziamento/tipo-di-finanziamento.component';
import {TipoDiFinanziamentoService} from './tipo-di-finanziamento/tipo-di-finanziamento.service';
import {AggiuntaRecordWbsDialogComponent} from './gestione-wbs/modals/aggiunta-record-wbs-dialog/aggiunta-record-wbs-dialog.component';
import {AggiuntaRecordWbsDialogService} from './gestione-wbs/modals/aggiunta-record-wbs-dialog/aggiunta-record-wbs-dialog.service';
import {GestioneWbsComponent} from './gestione-wbs/gestione-wbs.component';
import {GestioneWbsService} from './gestione-wbs/gestione-wbs.service';
import {GestioneSalComponent} from './gestione-sal/gestione-sal.component';
import {GestioneSalService} from './gestione-sal/gestione-sal.service';
import {CreazioneDatiProgettoComponent} from './crea-progetto/modals/creazione-dati-progetto/creazione-dati-progetto.component';
import {CreazioneDatiProgettoService} from './crea-progetto/modals/creazione-dati-progetto/creazione-dati-progetto.service';
import {GestionePartnerProgettoComponent} from './gestione-partner-progetto/gestione-partner-progetto.component';
import {GestionePartnerProgettoService} from './gestione-partner-progetto/gestione-partner-progetto.service';
import {GestioneReferentiProgettoComponent} from './gestione-referenti-progetto/gestione-referenti-progetto.component';
import {GestioneReferentiProgettoService} from './gestione-referenti-progetto/gestione-referenti-progetto.service';
import {GestionePianiDeiCostiComponent} from './gestione-piani-dei-costi/gestione-piani-dei-costi.component';
import {GestionePianiDeiCostiService} from './gestione-piani-dei-costi/gestione-piani-dei-costi.service';
import {ConfiguraCostiPersonaleModalComponent} from './gestione-piani-dei-costi/modals/configura-costi-personale-modal/configura-costi-personale-modal.component';
import {ConfiguraCostiPersonaleModalService} from './gestione-piani-dei-costi/modals/configura-costi-personale-modal/configura-costi-personale-modal.service';
import {DettaglioCostiPersonaleModalComponent} from './gestione-piani-dei-costi/modals/dettaglio-costi-personale-modal/dettaglio-costi-personale-modal.component';
import {DettaglioCostiPersonaleModalService} from './gestione-piani-dei-costi/modals/dettaglio-costi-personale-modal/dettaglio-costi-personale-modal.service';
import {PianificazioneCostiComponent} from './pianificazione-costi/pianificazione-costi.component';
import {PianificazioneCostiService} from './pianificazione-costi/pianificazione-costi.service';
import {DettaglioOreModalComponent} from './pianificazione-costi/modals/dettaglio-ore-modal/dettaglio-ore-modal.component';
import {DettaglioOreModalService} from './pianificazione-costi/modals/dettaglio-ore-modal/dettaglio-ore-modal.service';
import {DettaglioCostoOrarioModalComponent} from './pianificazione-costi/modals/dettaglio-costo-orario-modal/dettaglio-costo-orario-modal.component';
import {DettaglioCostoOrarioModalService} from './pianificazione-costi/modals/dettaglio-costo-orario-modal/dettaglio-costo-orario-modal.service';
import {PianificazioneOrePerAttivitaModalComponent} from './pianificazione-costi/modals/pianificazione-ore-per-attivita-modal/pianificazione-ore-per-attivita-modal.component';
import {PianificazioneOrePerAttivitaModalService} from './pianificazione-costi/modals/pianificazione-ore-per-attivita-modal/pianificazione-ore-per-attivita-modal.service';
import {GoogleChartComponent} from './google-chart/google-chart.component';
import {GanttComponent} from './gantt/gantt.component';

import {AggiuntaNuovoReferenteModalComponent} from './gestione-referenti-progetto/modals/aggiunta-nuovo-referente-modal/aggiunta-nuovo-referente-modal.component';


import {GenerazioneReportDialogComponent} from './generazione-report-dialog/generazione-report-dialog.component';
import {GenerazioneReportDialogService} from './generazione-report-dialog/generazione-report-dialog.service';
import {MonitoraggioProgettoComponent} from './monitoraggio-progetto/monitoraggio-progetto.component';
import {MonitoraggioProgettoService} from './monitoraggio-progetto/monitoraggio-progetto.service';
import {MonitoraggioDeliverablesProgettoComponent} from './monitoraggio-deliverables-progetto/monitoraggio-deliverables-progetto.component';
import {MonitoraggioDeliverablesProgettoService} from './monitoraggio-deliverables-progetto/monitoraggio-deliverables-progetto.service';
import {ReportisticaProgettiComponent} from './reportistica-progetti/reportistica-progetti.component';
import {ReportisticaProgettiService} from './reportistica-progetti/reportistica-progetti.service';
import {RendicontazioneProgettoComponent} from './rendicontazione-progetto/rendicontazione-progetto.component';
import {RendicontazioneProgettoService} from './rendicontazione-progetto/rendicontazione-progetto.service';
import {LoadingDialogComponent} from './modals/loading-dialog/loading-dialog.component';
import {LoadingDialogService} from './modals/loading-dialog/loading-dialog.service';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {InfiniteScrollComuneSelectorComponentComponent} from './gestione-partner-progetto/modals/infinite-scroll-comune-selector-component/infinite-scroll-comune-selector-component.component';
import {DettaglioCostiConsulenzeModalComponent} from './gestione-piani-dei-costi/modals/dettaglio-costi-consulenze-modal/dettaglio-costi-consulenze-modal.component';
import {DettaglioCostiConsulenzeModalService} from './gestione-piani-dei-costi/modals/dettaglio-costi-consulenze-modal/dettaglio-costi-consulenze-modal.service';
import {GestioneContrattiCollaborazioneModalComponent} from './pianificazione-costi/modals/gestione-contratti-collaborazione-modal/gestione-contratti-collaborazione-modal.component';
import {GestioneContrattiCollaborazioneModalService} from './pianificazione-costi/modals/gestione-contratti-collaborazione-modal/gestione-contratti-collaborazione-modal.service';
import {GestioneStakeholdersComponent} from './gestione-stakeholders/gestione-stakeholders.component';
import {GestioneStakeholdersService} from './gestione-stakeholders/gestione-stakeholders.service';
import {GestioneValoriModalComponent} from './gestione-bandi/modals/gestione-valori-modal/gestione-valori-modal.component';
import {DragulaModule} from 'ng2-dragula';
import {GestionePersonaModalComponent} from './gestione-stakeholders/modals/gestione-persona-modal/gestione-persona-modal.component';
import {GestioneCostiPersonaModalComponent} from './rendicontazione-progetto/modals/gestione-costi-persona-modal/gestione-costi-persona-modal.component';
import {RendicontazionePersonaModalComponent} from './rendicontazione-progetto/modals/rendicontazione-persona-modal/rendicontazione-persona-modal.component';
import {GestioneBeniComponent} from './gestione-beni/gestione-beni.component';
import {GestioneBeniService} from './common-services/gestione-beni.service';
import {DettaglioCostiBeniModalComponent} from './gestione-piani-dei-costi/modals/dettaglio-costi-beni-modal/dettaglio-costi-beni-modal.component';
import {DettaglioCostiBeniModalService} from './gestione-piani-dei-costi/modals/dettaglio-costi-beni-modal/dettaglio-costi-beni-modal.service';
import {ImportWbsModalComponent} from './gestione-wbs/modals/import-wbs-modal/import-wbs-modal.component';
import {ImportWbsModalService} from './gestione-wbs/modals/import-wbs-modal/import-wbs-modal.service';
import {ImpostazioniComponent} from './impostazioni/impostazioni.component';
import {ReportcostiComponent} from './reportcosti/reportcosti.component';
import {AggiuntaNuovoStakeholderModalComponent} from "./gestione-partner-progetto/modals/aggiunta-nuovo-stakeholder-modal/aggiunta-nuovo-stakeholder-modal.component";
import {SortPipeComponent} from './sort-pipe/sort-pipe.component';
import {SearchPipe} from './search-pipe/search-pipe.component';
import {RiskManagerComponent} from './risk-manager/risk-manager.component';
import {RiskManagerService} from './risk-manager/risk-manager.service';
import {ImportProgettoModalComponent} from './risk-manager/modals/import-progetto-modal/import-progetto-modal.component';
import {DettaglioGestionePartnerProgettoModalComponent} from './gestione-partner-progetto/modals/dettaglio-gestione-partner-progetto-modal/dettaglio-gestione-partner-progetto-modal.component';
import {DettaglioGestionePartnerProgettoModalService} from './gestione-partner-progetto/modals/dettaglio-gestione-partner-progetto-modal/dettaglio-gestione-partner-progetto-modal.service';
import {PartnerInScadenzaComponent} from './partner-in-scadenza/partner-in-scadenza.component';
import {PartnerInScadenzaService} from "./partner-in-scadenza/partner-in-scadenza-service";
import {PartnerComponentComponent} from './partner-component/partner-component.component';
import {MonitoraggioProgettoPartnerService} from "./partner-component/monitoraggio-progetto.service";
import {ReportPartnerComponent} from './partner-component/report-partner/report-partner.component';
import {ReportisticaProgettoComponent} from './reportistica-progetto/reportistica-progetto.component';
import {PartnerDashboardComponent} from './partner-dashboard/partner-dashboard.component';
import {CommingSoonComponent} from './comming-soon/comming-soon.component';
import {RotellinaDialogComponent} from './rotellina-dialog/rotellina-dialog.component';
import {PartnerUtenteListModalComponent} from './utenti-organizzazione/modals/partner-utente-list-modal/partner-utente-list-modal.component';
import {PartnerUtenteAddlistModalComponent} from './utenti-organizzazione/modals/partner-utente-addlist-modal/partner-utente-addlist-modal.component';
import {OpenPartnerComponent} from './utenti-organizzazione/utils/open-partner/open-partner.component';
import {MonitoraContrattiModalComponent} from './monitoraggio-progetto/modals/monitora-contratti-modal/monitora-contratti-modal.component';
import {MonitoraAttrezzaturaModalComponent} from './monitoraggio-progetto/modals/monitora-attrezzatura-modal/monitora-attrezzatura-modal.component';
import {MonitoraCostiModalComponent} from './monitoraggio-progetto/modals/monitora-costi-modal/monitora-costi-modal.component';
import {StoricoWbsComponent} from "./gestione-wbs/modals/storico-wbs/storico-wbs.component";
import {StoricoPianoCostiComponent} from "./gestione-piani-dei-costi/storico-piano-costi/storico-piano-costi.component";
import {DettglioStoricoWbsComponent} from './gestione-wbs/modals/storico-wbs/dettglio-storico-wbs/dettglio-storico-wbs.component';
import {MonitoraSalModalComponent} from './monitoraggio-progetto/modals/monitora-sal-modal/monitora-sal-modal.component';
import {MonitoraAttrezzaturaDettagliCostoModalComponent} from './monitoraggio-progetto/modals/monitora-attrezzatura-dettagli-costo-modal/monitora-attrezzatura-dettagli-costo-modal.component';
import {MonitoraggioDocProgettoComponent} from "./monitoraggio-doc-progetto/monitoraggio-doc-progetto.component";
import {MonitoraggioDocProgettoService} from "./monitoraggio-doc-progetto/monitoraggio-doc-progetto.service";
import {ApiKeyComponent} from './api-key/api-key.component';
import {ApiKeyService} from "./api-key/api_key.service";
import {AddApiComponent} from './api-key/modal/add-api/add-api.component';
import { PartnerSmartreportComponent } from './partner-smartreport/partner-smartreport.component';
import { PartnerSmartreportDialogComponent } from './partner-smartreport/partner-smartreport-dialog/partner-smartreport-dialog.component';
import {SmartAllocationComponent} from "./smart-allocation/smart-allocation.component";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {SmartAllocationDialog} from "./smart-allocation/smart-allocation-dialog";
import { PromptGeneratorComponentComponent } from './chat/modals/prompt-generator-component/prompt-generator-component.component';
import { ChatService } from './chat/modals/prompt-generator-component/prompt-generator-component.component.service';
import { ReportisticaProgettoService } from './reportistica-progetto/reportistica-progetto.service';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule, MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";

export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    LoginComponent,
    WarningMessageComponent,
    AdminDashboard,
    HeaderComponent,
    DashboardButtonComponent,
    OrganizzazioniComponent,
    ConfirmMessageComponent,
    UtentiOrganizzazioniComponent,
    AdminOrganizzazioneDashboardComponent,
    ImpostazioniProfiloComponent,
    UtentiOrganizzazioneComponent,
    UtilityModuleComponent,
    GestioneBandiComponent,
    GestioneVociDiCostoComponent,
    CreaProgettoComponent,
    GestioneProgettoComponent,
    TipoDiAttivitaCofinanziabileComponent,
    TipoDiFinanziamentoComponent,
    AggiuntaRecordWbsDialogComponent,
    GestioneWbsComponent,
    GestioneSalComponent,
    CreazioneDatiProgettoComponent,
    GestionePartnerProgettoComponent,
    GestioneReferentiProgettoComponent,
    GestionePianiDeiCostiComponent,
    ConfiguraCostiPersonaleModalComponent,
    DettaglioCostiPersonaleModalComponent,
    PianificazioneCostiComponent,
    DettaglioOreModalComponent,
    DettaglioCostoOrarioModalComponent,
    PianificazioneOrePerAttivitaModalComponent,
    GoogleChartComponent,
    GanttComponent,
    AggiuntaNuovoStakeholderModalComponent,
    AggiuntaNuovoReferenteModalComponent,
    GenerazioneReportDialogComponent,
    MonitoraggioProgettoComponent,
    MonitoraggioDeliverablesProgettoComponent,
    ReportisticaProgettiComponent,
    RendicontazioneProgettoComponent,
    LoadingDialogComponent,
    InfiniteScrollComuneSelectorComponentComponent,
    DettaglioCostiConsulenzeModalComponent,
    GestioneContrattiCollaborazioneModalComponent,
    GestioneStakeholdersComponent,
    GestioneValoriModalComponent,
    GestionePersonaModalComponent,
    GestioneCostiPersonaModalComponent,
    RendicontazionePersonaModalComponent,
    GestioneBeniComponent,
    DettaglioCostiBeniModalComponent,
    ImportWbsModalComponent,
    ImpostazioniComponent,
    ReportcostiComponent,
    SortPipeComponent,
    SortPipeComponent,
    SearchPipe,
    RiskManagerComponent,
    ImportProgettoModalComponent,
    DettaglioGestionePartnerProgettoModalComponent,
    PartnerInScadenzaComponent,
    PartnerComponentComponent,
    ReportPartnerComponent,
    ReportisticaProgettoComponent,
    PartnerDashboardComponent,
    CommingSoonComponent,
    RotellinaDialogComponent,
    MonitoraggioDocProgettoComponent,
    /*
        PartnerUtentiModalComponent,
    */
    PartnerUtenteListModalComponent,
    PartnerUtenteAddlistModalComponent,
    OpenPartnerComponent,
    MonitoraContrattiModalComponent,
    MonitoraAttrezzaturaModalComponent,
    MonitoraCostiModalComponent,
    StoricoWbsComponent,
    StoricoPianoCostiComponent,
    DettglioStoricoWbsComponent,
    MonitoraSalModalComponent,
    MonitoraAttrezzaturaDettagliCostoModalComponent,
    ApiKeyComponent,
    AddApiComponent,
    PartnerSmartreportComponent,
    PartnerSmartreportDialogComponent,
    SmartAllocationComponent,
    SmartAllocationDialog,
    PromptGeneratorComponentComponent
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    HttpClientModule,
    BrowserAnimationsModule,
    Ng2SmartTableModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpModule,
    NgxMaterialTimepickerModule.forRoot(),
    MatSelectInfiniteScrollModule,
    DragulaModule.forRoot(),
    NgxChartsModule
  ],
  entryComponents: [
    WarningMessageComponent,
    ConfirmMessageComponent,
    AdminDashboard,
    DashboardButtonComponent,
    AggiuntaRecordWbsDialogComponent,
    GestioneWbsComponent,
    GestioneSalComponent,
    ConfiguraCostiPersonaleModalComponent,
    DettaglioCostiPersonaleModalComponent,
    DettaglioOreModalComponent,
    DettaglioCostoOrarioModalComponent,
    PianificazioneOrePerAttivitaModalComponent,
    GanttComponent,
    CreazioneDatiProgettoComponent,
    GestionePartnerProgettoComponent,
    AggiuntaNuovoStakeholderModalComponent,
    GestioneReferentiProgettoComponent,
    AggiuntaNuovoReferenteModalComponent,
    GestionePianiDeiCostiComponent,
    GenerazioneReportDialogComponent,
    MonitoraggioDeliverablesProgettoComponent,
    ReportcostiComponent,
    LoadingDialogComponent,
    InfiniteScrollComuneSelectorComponentComponent,
    DettaglioCostiConsulenzeModalComponent,
    GestioneContrattiCollaborazioneModalComponent,
    GestioneValoriModalComponent,
    GestionePersonaModalComponent,
    GestioneCostiPersonaModalComponent,
    RendicontazionePersonaModalComponent,
    GestioneBeniComponent,
    DettaglioCostiBeniModalComponent,
    ImportWbsModalComponent,
    ImportProgettoModalComponent,
    DettaglioGestionePartnerProgettoModalComponent,
    ReportPartnerComponent,
    ReportisticaProgettoComponent,
    RotellinaDialogComponent,
    /*
        PartnerUtentiModalComponent,
    */
    PartnerUtenteAddlistModalComponent,
    PartnerUtenteListModalComponent,
    OpenPartnerComponent,
    MonitoraContrattiModalComponent,
    MonitoraAttrezzaturaModalComponent,
    MonitoraCostiModalComponent,
    StoricoWbsComponent,
    StoricoPianoCostiComponent,
    DettglioStoricoWbsComponent,
    MonitoraSalModalComponent,
    MonitoraAttrezzaturaDettagliCostoModalComponent,
    PartnerSmartreportDialogComponent,
    AddApiComponent,SmartAllocationDialog,
    PromptGeneratorComponentComponent
  ],
  providers: [UserService,
    AuthService,
    TokenStorage,
    UserStorage,
    OrganizzazioniService,
    UtentiOrganizzazioniService,
    UtentiOrganizzazioneService,
    AdminOrganizzazioneDashboardService,
    AnagraficaUtentiService,
    MatDialog,
    UtilityModuleComponent,
    UtilsService,
    DatePipe,
    GestioneBandiService,
    GestioneVociDiCostoService,
    CreaProgettoService,
    GestioneProgettoService,
    TipoDiAttivitaCofinanziabileService,
    TipoDiFinanziamentoService,
    AggiuntaRecordWbsDialogService,
    GestioneWbsService,
    GestioneSalService,
    CreazioneDatiProgettoService,
    GestionePartnerProgettoService,
    GestioneReferentiProgettoService,
    GestionePianiDeiCostiService,
    ConfiguraCostiPersonaleModalService,
    DettaglioCostiPersonaleModalService,
    PianificazioneCostiService,
    DettaglioOreModalService,
    DettaglioCostoOrarioModalService,
    PianificazioneOrePerAttivitaModalService,
    GenerazioneReportDialogService,
    MonitoraggioProgettoService,
    MonitoraggioDeliverablesProgettoService,
    ReportisticaProgettiService,
    RendicontazioneProgettoService,
    ReportisticaProgettoService,
    LoadingDialogService,
    DettaglioCostiConsulenzeModalService,
    GestioneContrattiCollaborazioneModalService,
    GestioneStakeholdersService,
    GestioneBeniService,
    ImportWbsModalService,
    DettaglioCostiBeniModalService,
    RiskManagerService,
    DettaglioGestionePartnerProgettoModalService,
    PartnerInScadenzaComponent,
    PartnerInScadenzaService,
    MonitoraggioProgettoPartnerService,
    MonitoraggioDocProgettoService,
    ChatService,
    ApiKeyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: MatDialogRef, useValue: {}},
    /*{provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT},
    {provide: DateAdapter, useClass: MomentDateAdapter},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }}*/
  ],
  bootstrap: [AppComponent,SmartAllocationComponent]
})
export class AppModule {
}
