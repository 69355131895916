import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class GestionePartnerProgettoService {

  public static urlBaseServizio: string = "http://www.sdsrl.it/intranet-gestori/wrapper";
  public static subUrlImportaStakeholder: string = "/anagrafica_stakeholder.php";
  public static tmpUrl: string = "http://localhost:8081/anagrafica_stakeholder.php";

  constructor(private http: HttpClient) {
  }

  public getNomeParterById(bodyMessaggio: any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'partners/get-by-partner-id', bodyMessaggio);
  }

  public getIdPartnerRendicontaFromNamePartnerGestori(bodyMessaggio: any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'partners/get-by-partner-name', bodyMessaggio);
  }

  public getAllPartnerByOrganizzazione(organizzazioneId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'partners/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
  }

  public getProgettoById(id): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetti/get-progetto-by-id?id=' + id);
  }

  public getStakeholdersByProgettoIdAndOrganizzazioneId(progettoId, organizzazioneId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetto-stakeholders/get-stakeholders?idProgetto=' + progettoId + '&idOrganizzazione=' + organizzazioneId);
  }

  public getRuoloByProgettoIdAndStakeholderId(progettoId, stakeholderId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetto-stakeholders/get-ruolo-by-progetto-id-and-stakeholder-id?progettoId=' + progettoId + '&stakeholderId=' + stakeholderId);
  }

  ///////////////////////////////////////////////
  public getPartnerFromGestori(bodyMessaggio: any, nome_progetto: any): Observable<any> {
    return this.http.post<any>(AppSettings.GESTORI_URL + GestionePartnerProgettoService.subUrlImportaStakeholder + "?projectname=" + nome_progetto, bodyMessaggio, {
      headers:
        {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
    });
  }

  public getSedi(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'sedi-stakeholders/get-all-by-id-organizzazione?idOrganizzazione=' + idOrganizzazione);
  }

  public getSediProgetto(idProgetto): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetto-stakeholders/get-all-sedi-stakeholders?idProgetto=' + idProgetto);
  }

  public getComuni(): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'comuni/get-all');
  }

  public getComune(cap): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'comuni/get-by-cap?cap=' + cap);
  }

  public getTipologie(organizzazioneId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'tipologie-stakeholder/get-all-by-organizzazione?organizzazioneId=' + organizzazioneId);
  }

  public savePartner(partner: any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'stakeholders/save-stakeholder', partner);
  }

  public saveSedeStakeholder(sede): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'sedi-stakeholders/save-sede-stakeholder', sede);
  }



  public saveProgettoPartner(wrapper: any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'progetto-stakeholders/save-progetto-stakeholder', wrapper);
  }

  public resetProgetto(idProgetto): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'progetti/reset-progetto-from-partners?idProgetto=' + idProgetto, null);
  }

  ///////////////////////////////////////////////



}
