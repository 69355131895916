import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-partner-smartreport-dialog',
  templateUrl: './partner-smartreport-dialog.component.html',
  styleUrls: ['./partner-smartreport-dialog.component.css']
})
export class PartnerSmartreportDialogComponent implements OnInit {

  dashboardUrl: SafeResourceUrl = "";
  nomeProgetto: string = "";

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.nomeProgetto = localStorage.getItem('nomeProgetto');
    this.dashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl("http://rendiconta.sdsrl-progetti.it/sd-rendiconta-bi/?id_progetto="
      + localStorage.getItem('idProgetto') + '&grafico=' + localStorage.getItem('tipoReport'));
  }

}
