import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../../../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DettaglioCostiPersonaleModalService {

  constructor(private http: HttpClient) {}
  
  public getFasceNonStandard(idProgetto, idSedeStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'fasce-costo-personale-non-standard-progetto-stakeholder/get-all-by-progetto-id-and-sede-stakeholder-id?progettoId='+idProgetto+'&sedeStakeholderId='+idSedeStakeholder);
  }

  public getFasceStandard(idBando, idTipologiaStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'fasce-costo-personale-standard-tipologia-stakeholder/get-all-by-tipologia-stakeholder-id-and-bando-id?idBando='+idBando+'&idTipologiaStakeholder='+idTipologiaStakeholder);    
  }

}