import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { SharedDataService } from 'src/app/common-services/shared-data.service';
import { CreaProgettoService } from 'src/app/crea-progetto/crea-progetto.service';
import { UtentiOrganizzazioneService } from '../../utenti-organizzazione.service';
import { PartnerUtenteAddlistModalComponent } from '../partner-utente-addlist-modal/partner-utente-addlist-modal.component';

@Component({
  selector: 'app-partner-utente-list-modal',
  templateUrl: './partner-utente-list-modal.component.html',
  styleUrls: ['./partner-utente-list-modal.component.css']
})

/**
 * Prendere l'id utente, e tramite quello prendere la lista dei utenti-stakeholders
 * 1 utente many stakeholders
 * 
 * array formato da una lista di skeholder, con il nome
 * 
 * tasto di aggiunta che collega al modal
 */
export class PartnerUtenteListModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PartnerUtenteListModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api_call: CreaProgettoService,
    private sharedDataService: SharedDataService,
    private utentiOrganizzazioneService: UtentiOrganizzazioneService,
    public dialog: MatDialog) { }

  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['stakeholder', "add/remove"];

  ngOnInit() {
    console.log(this.data)
    //console.log(this.sharedDataService.getOrganizzazioneID())
    this.loadPartners();
    this.dataSource = new MatTableDataSource<any>();
  }

  async loadPartners() {
    let oldPartnerID: Array<any> = []

    oldPartnerID = await this.utentiOrganizzazioneService.getUtenteStakeholders(this.data.userID).toPromise()
    if (oldPartnerID == null) oldPartnerID = [];

    this.dataSource.data = [...oldPartnerID.map(el => el.stakeholder)];

  }
  async addElementToTable(): Promise<void> {
    const stakeholders = await this.api_call.getAllStakeholders(this.sharedDataService.getOrganizzazioneID()).toPromise();


    const currentPartner = this.dataSource.data;
    const unUsedPartner = stakeholders.filter(a => !currentPartner.map(b => b.id).includes(a.id));
    console.log(unUsedPartner);
    const appListModal = this.dialog.open(PartnerUtenteAddlistModalComponent, {
      data: {
        available: unUsedPartner,
        cb: (newPartners) => {
          console.log(newPartners);
          this.dataSource.data = [...this.dataSource.data, ...newPartners].sort((a, b) => a.id - b.id);
          appListModal.close();
        }
      }
    })


  }
  async savePartners() {
    const partnersID = this.dataSource.data.map(el => el.id);
    await this.utentiOrganizzazioneService.setUtenteStakeholders(partnersID, this.data.userID).toPromise();
    this.data.cb();

  }
  removeElementFromTable(element): void {
    this.dataSource.data.splice(this.dataSource.data.indexOf(element), 1);
    this.dataSource.data = this.dataSource.data.concat([]);
  }

}

