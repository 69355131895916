import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core_modules/auth.service';

@Component({
  selector: 'app-impostazioni',
  templateUrl: './impostazioni.component.html',
  styleUrls: ['./impostazioni.component.css']
})
export class ImpostazioniComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
    });
  }

}
