import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserStorage } from '../core_modules/user.storage';
import { UtilityModuleComponent } from '../core_modules/utility-module/utility-module.component';
import { TipoDiAttivitaCofinanziabileService} from './tipo-di-attivita-cofinanziabile.service';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { AppErrors } from '../app.errors';
import { OrganizzazioniService } from '../organizzazioni/organizzazioni.service';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import { AnagraficaUtentiService } from '../common-services/anagrafica-utenti.service';

const NOME_MAX_LENGTH=250;
const DESCRIZIONE_MAX_LENGTH=500;

@Component({
  selector: 'app-tipo-di-attivita-cofinanziabile',
  templateUrl: './tipo-di-attivita-cofinanziabile.component.html',
  styleUrls: ['./tipo-di-attivita-cofinanziabile.component.css']
})
export class TipoDiAttivitaCofinanziabileComponent implements OnInit {

  constructor(private http: HttpClient, 
    private organizzazioniService: OrganizzazioniService,
    private router: Router,  
    private userStorage: UserStorage,
    private tipoDiAttivitaCofinanziabileService: TipoDiAttivitaCofinanziabileService,
    private utilityModule: UtilityModuleComponent,
    public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>) { 
    }

    tipo_di_attivita_cofinanziabile_section_title="Gestione Tipi di Attività Cofinanziabile"

    organizzazioneName = undefined;

    organizzazioneObj = undefined;

    tipo_di_attivita_cofinanziabile_table_data;

    tipo_di_attivita_cofinanziabile_settings = {
      noDataMessage: "Nessun dato da visualizzare",
      columns: {
        nome: {
          title: 'Nome'
        },
        descrizione: {
          title: 'Descrizione'
        }
      },
      actions: {
        edit: true,
        delete: false,
        columnTitle: ""
      },
      edit: {
        editButtonContent: "Modifica ",
        saveButtonContent: "Aggiorna",
        cancelButtonContent: "Annulla",
        confirmSave: true
      },
      delete: {
        deleteButtonContent: "Elimina ",
        confirmDelete: true
      },
      add: {
        addButtonContent: " Aggiungi nuova",
        createButtonContent: "Aggiungi",
        cancelButtonContent: "Annulla",
        confirmCreate: true
      },
      attr: {
        class: 'table table-bordered utenti-organizzazioni-table'
      },
      pager: {
        display: false
      },
      mode: "inline",
    };  
  
    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
        duration: 2000,
      });
    }


    checkIfDataIsValid(oldData,newData) {
      
      if (!oldData) { 
        if (!newData || newData.nome.length<=0 || newData.nome.length>NOME_MAX_LENGTH) {
          //il nome della voce di costo non puÃ² esssere maggiore di VARCHAR(45)
          return AppErrors.NOME_TIPO_DI_ATTIVITA_COFINANZIABILE_LENGTH_ERROR; 
        }
        if (!newData || newData.descrizione.length>DESCRIZIONE_MAX_LENGTH) {
          //la descrizione della voce di costo non puÃ² essere maggiore di VARCHAR(100)
          return AppErrors.DESCRIZIONE_TIPO_DI_ATTIVITA_COFINANZIABILE_LENGTH_ERROR; 
        }
        return undefined;
      }
      if(!newData || newData.nome.length<=0 || newData.nome.length>NOME_MAX_LENGTH) {
        //il nome della voce di costo non puÃ² essere vuoto o maggiore di VARCHAR(45)
        return AppErrors.NOME_TIPO_DI_ATTIVITA_COFINANZIABILE_LENGTH_ERROR; 
      }
      if(newData && newData.descrizione) {
        if(newData.descrizione.length>DESCRIZIONE_MAX_LENGTH) {
          //la descrizione della voce di costo non puÃ² essere maggiore di VARCHAR(100)
          return AppErrors.DESCRIZIONE_TIPO_DI_ATTIVITA_COFINANZIABILE_LENGTH_ERROR; 
        }
      }
      return undefined;
    }


    saveTipoDiAttivitaCofinanziabile(event, tipoDiAttivitaCofinanziabile) {
      if(!tipoDiAttivitaCofinanziabile.organizzazione) {
        tipoDiAttivitaCofinanziabile.organizzazione=this.organizzazioneObj;
      }
      this.tipoDiAttivitaCofinanziabileService.saveTipoDiAttivitaCofinanziabile(tipoDiAttivitaCofinanziabile).subscribe(
        data => {
          this.loadTipoDiAttivitaCofinanziabileOrganizzazione();
          event.confirm.resolve(event.newData);
          this.openSnackBar("Dati memorizzati con successo", "Chiudi")
        },
        err => {
          console.log(err);
          this.dialog.open(WarningMessageComponent,{
            data: {
              message: err.error.message
            },
            panelClass: 'custom-warning-container'
          });
          event.confirm.reject();
        }
      ); 
    }


    onDeleteConfirm(event) {
      this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
        width: "250px",
        data: {
          message: "Vuoi davvero eliminare questa attività cofinanziabile?"
        },
        panelClass: 'custom-confirm-container'
      });
      this.dialogRef.afterClosed().subscribe(
        res => {
          if(res==true) {       
            this.tipoDiAttivitaCofinanziabileService.deleteTipoDiAttivitaCofinanziabile(event.data.nome,this.organizzazioneObj.id).subscribe(
              data =>{
                event.confirm.resolve(event.data);
              },
              err =>{
                console.log("*************************************************");
                console.log("ERROR...")
                console.log(err);
                console.log("*************************************************");
              }
            )
          }
          else {
            event.confirm.reject();
          }
        }
      );    
      }


      onSaveConfirm(event) {
        let result=this.checkIfDataIsValid(event.data,event.newData);
        if(result) {
          this.dialog.open(WarningMessageComponent,{
            data: {
              message: result
            },
            panelClass: 'custom-warning-container'
          });
          event.confirm.reject();
        }
        else {
          this.saveTipoDiAttivitaCofinanziabile(event,event.newData)
        }
      }


      loadTipoDiAttivitaCofinanziabileOrganizzazione() {
        this.tipoDiAttivitaCofinanziabileService.getAllByOrganizzazione(this.organizzazioneObj.id).subscribe(
          data => {
            this.tipo_di_attivita_cofinanziabile_table_data=data;
          },
          err => {
            this.dialog.open(WarningMessageComponent,{
              data: {
                message: err.error.message
              },
              panelClass: 'custom-warning-container'
            });
          }
        );
    
      }


      loadOrganizzazione() {
        this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
          data => {
            this.organizzazioneObj=data;
            this.loadTipoDiAttivitaCofinanziabileOrganizzazione();
          },
          err => {
            this.dialog.open(WarningMessageComponent,{
              data: {
                message: err.error.message
              },
              panelClass: 'custom-warning-container'
            });
          }
        );
      }
    

  ngOnInit() {

    this.organizzazioneName=this.userStorage.getOrganizzazione();
    this.loadOrganizzazione();  
    
  }

}
