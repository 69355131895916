import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UtilsService } from 'src/app/common-services/utils.service';

@Component({
  selector: 'app-monitora-attrezzatura-dettagli-costo-modal',
  templateUrl: './monitora-attrezzatura-dettagli-costo-modal.component.html',
  styleUrls: ['./monitora-attrezzatura-dettagli-costo-modal.component.css']
})
export class MonitoraAttrezzaturaDettagliCostoModalComponent implements OnInit {

  constructor(
    public utilsService: UtilsService,
    public dialogRef: MatDialogRef<MonitoraAttrezzaturaDettagliCostoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

  public bene = {};
  public columns = ["descrizione", "nome_sal", "nome_wbs", "tipo_versione","costo","data_inizio_utilizzo_bene","data_fine_utilizzo_bene","percentuale_utilizzo_bene"]
  ngOnInit() {
    console.log(this.data);
    this.bene = this.data.bene;
  }

}
