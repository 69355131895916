import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GestioneBeniService {

  constructor(private http: HttpClient) {}

  public getBeni(idProgetto, idSedeStakeholder) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'beni/get-all-by-progetto-id-and-sede-stakeholder-id?idProgetto='+idProgetto+'&idSedeStakeholder='+idSedeStakeholder);
  }

  public getDettaglioMonitoraggioBene(idBene):Observable<any>{
    return this.http.get<any>(AppSettings.BASE_URL+'beni/get-bene-monitoraggio-dettagli?idBene='+idBene);
  }
  public getMonitoraggioBene(idBene): Observable<number>{
    return this.http.get<number>(AppSettings.BASE_URL+'beni/get-bene-monitoraggio?idBene='+idBene);

  }
  public getBeneUtilizzatoFlag(idBene) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'beni/get-bene-utilizzato-flag?idBene='+idBene);
  }

  public saveBene(bene) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'beni/save-bene', bene);
  }

  public updateBene(bene) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'beni/update-bene', bene);
  }

  public deleteBene(id) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'beni/delete-bene?id='+id, null);
  }

}