import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class TipoDiAttivitaCofinanziabileService {

  constructor(private http: HttpClient) {}

  public saveTipoDiAttivitaCofinanziabile(tipoDiAttivitaCofinanziabile): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'tipi-di-attivita-cofinanziabile/save', tipoDiAttivitaCofinanziabile);
  }


  public getAllByOrganizzazione(organizzazioneId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'tipi-di-attivita-cofinanziabile/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
  }

  public getAllByBandoIdAndOrganizzazioneId(bandoId, organizzazioneId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'bandi-tipi-di-attivita-cofinanziabile/get-tipi-attivita-cofinanziabile-by-bando-id-and-organizzazione-id?bandoId=' + bandoId + '&organizzazioneId=' + organizzazioneId);
  }

  public deleteTipoDiAttivitaCofinanziabile(nomeTipoDiAttivitaCofinanziabile,organizzazioneId): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'tipi-di-attivita-cofinanziabile/delete-tipo-di-attivita-cofinanziabile?nomeTipoDiAttivitaCofinanziabile='+nomeTipoDiAttivitaCofinanziabile+'&idOrganizzazione='+organizzazioneId,undefined);
  }

}