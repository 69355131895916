import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { RotellinaDialogComponent } from './rotellina-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class RotellinaService {

  constructor(private dialog: MatDialog) {

   }

  dialogRef:MatDialogRef<RotellinaDialogComponent, any>;

  open = false;
  public getState():boolean{
    return this.open;
  }

  public openDialog():void{
    if(!this.open){
      this.dialogRef =  this.dialog.open(RotellinaDialogComponent, {
        disableClose: true
      });
      this.open= true;
    }else{
      throw new Error("already open");
    }      

  }
  public closeDialog():void{
    if(this.open){
      this.dialogRef.close()
      this.open= false;
  
    } else{
      throw new Error("already close");
    }
  }

}
