import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'src/app/gestione-wbs/modals/aggiunta-record-wbs-dialog/aggiunta-record-wbs-dialog.component';

@Component({
  selector: 'app-aggiunta-nuovo-stakeholder-modal',
  templateUrl: './aggiunta-nuovo-stakeholder-modal.component.html',
  styleUrls: ['./aggiunta-nuovo-stakeholder-modal.component.css']
})
export class AggiuntaNuovoStakeholderModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AggiuntaNuovoStakeholderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onSelectedElement(element) : void {
    this.dialogRef.close(element);
  }

  ngOnInit() {
  }

}
