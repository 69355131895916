import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../../../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DettaglioOreModalService {

  constructor(private http: HttpClient) {}

  public getAltreAttivita() : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'altre-attivita/get-all');
  }

  public getWbs(nomeProgetto, idOrganizzazione: any) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'wbs-master/get-ultima-versione-wbs-progetto?nomeProgetto=' + nomeProgetto + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getUltimoContrattoMeseAnno(idProgetto, idPersona, idSedeStakeholder, mese, anno) : Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'contratti-di-collaborazione-pianificati/get-ultimo-contratto-pianificato-mese-anno?idProgetto=' + idProgetto + '&idPersona=' + idPersona + '&idSedeStakeholder=' + idSedeStakeholder + '&mese=' + mese + '&anno=' + anno);
  }

  public saveBustaPaga(bustaPaga: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'buste-paga/save-busta-paga', bustaPaga);
  }

  public updateBustaPaga(bustaPaga: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'buste-paga/update-busta-paga', bustaPaga);
  }

  public savePianificazioneAltreAttivita(altreAttivitaPersona: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'altre-attivita-persona/save-altre-attivita-persona', altreAttivitaPersona);
  }

  public updatePianificazioneAltreAttivita(altreAttivitaPersona: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'altre-attivita-persona/update-altre-attivita-persona', altreAttivitaPersona);
  }

  public savePianificazioneMensile(pianificazioneMensile: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'pianificazioni-progetto-persona-per-mese/save-pianificazione-progetto-persona-per-mese', pianificazioneMensile);
  }

  public updatePianificazioneMensile(pianificazioneMensile: any) : Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'pianificazioni-progetto-persona-per-mese/update-pianificazione-progetto-persona-per-mese', pianificazioneMensile);
  }

}