import { Component, OnInit } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { OrganizzazioniService } from '../organizzazioni/organizzazioni.service';
import { UserStorage } from '../core_modules/user.storage';
import { GestioneBandiService } from './gestione-bandi.service';
import { GestionePartnerProgettoService } from '../gestione-partner-progetto/gestione-partner-progetto.service';
import { GestioneProgettoService } from '../gestione-progetto/gestione-progetto.service';
import { GestioneWbsService } from '../gestione-wbs/gestione-wbs.service';
import { GestioneValoriModalComponent } from './modals/gestione-valori-modal/gestione-valori-modal.component';
import { AuthService } from '../core_modules/auth.service';

export interface FasciaStandardWrapper {
  fascia: any;
  fasceBando: any[];
}

export interface PercentualeWrapper {
  percentuale: any;
}

export interface QuotaDiAgevolazione {
  id: any;
  bando: any;
  tipoDiFinanziamento: any;
  tipoDiAttivitaCofinanziabile: any;
  tipologiaStakeholder: any;
  percentuale: number;
}

@Component({
  selector: 'app-gestione-bandi',
  templateUrl: './gestione-bandi.component.html',
  styleUrls: ['./gestione-bandi.component.css']
})
export class GestioneBandiComponent implements OnInit {

  NOME_MAX_LENGTH = 200;
  DESCRIZIONE_MAX_LENGTH = 500;

  NOME_FASCIA_MAX_LENGTH = 45;
  DESCRIZIONE_FASCIA_MAX_LENGTH = 100;

  DENOMINAZIONE_BANDO_VOCE_DI_COSTO_MAX_LENGTH = 250;

  constructor(
    private organizzazioniService: OrganizzazioniService,
    private userStorage: UserStorage,
    public confirmDialog: MatDialog,
    private gestioneBandiService: GestioneBandiService,
    private authService: AuthService,
    public dialog: MatDialog,
    public gestioneValoriModalDialog: MatDialog,
    public gestionePartnerProgettoService: GestionePartnerProgettoService,
    public gestioneProgettoService: GestioneProgettoService,
    public gestioneWbsService: GestioneWbsService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>
  ) {}

  displayedColumns = ['check', 'nome', 'descrizione', 'add/remove'];
  innerTableColumns = ['add/remove', 'record'];
  innerVociDiCostoTableColumns = ['add/remove', 'record', 'denominazione', 'pcsg'];
  fasceBandoTableColumns = ['fascia', 'tipi', 'add/remove'];
  quoteDiAgevolazioneTableColumns = ['finanziamento', 'attivita', 'stakeholder', 'percentuale', 'update']

  innerTableColumnsPerc = ['record'];
  percvalue=['']

  selectedBando = undefined;

  organizzazione = undefined;
  bandiOriginal = [];
  bandi = [];

  vociDiCosto = [];
  tipiAttivitaCofinanziabili = [];
  tipiFinanziamento = [];
  fasce: FasciaStandardWrapper[] = [];

  tipologieStakeholder = [];

  vociDiCostoBando = [];
  tipiAttivitaCofinanziabiliBando = [];
  tipiFinanziamentoBando = [];
  perentualitipoFinanziamentoBando=[];
  progetti = [];

  quoteDiAgevolazione: QuotaDiAgevolazione[] = [];

  /**
   * Carica un'organizzazione dal database.
   * @param nome Nome dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadOrganizzazione(nome, _callback) : void {
    this.organizzazioniService.getOrganizzazioneByName(nome).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica i bandi dal database.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadBandi(idOrganizzazione, _callback) : void {
    this.gestioneBandiService.getAllByOrganizzazione(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le relazioni bando - voce di costo dal database.
   * @param idBando Id del bando di riferimento.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva
   */
  loadVociDiCostoBando(idBando, idOrganizzazione, _callback) : void {
    this.gestioneBandiService.getVociDiCostoBando(idBando, idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le relazioni bando - tipo attività cofinanziabile dal database.
   * @param idBando Id del bando di riferimento.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva
   */
  loadTipiAttivitaCofinanziabiliBando(idBando, idOrganizzazione, _callback) : void {
    this.gestioneBandiService.getTipiAttivitaCofinanziabiliBando(idBando, idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le relazioni bando - tipo finanziamento dal database.
   * @param idBando Id del bando di riferimento.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva
   */
  loadTipiFinanziamentoBando(idBando, idOrganizzazione, _callback) : void {
    this.gestioneBandiService.getTipiFinanziamentoBando(idBando, idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le voci di costo dal database.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadVociDiCosto(idOrganizzazione, _callback) : void {
    this.gestioneBandiService.getVociDiCosto(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica i tipi di attività cofinanziabile dal database.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadTipiAttivitaCofinanziabili(idOrganizzazione, _callback) : void {
    this.gestioneBandiService.getTipiAttivitaCofinanziabili(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica i tipi di finanziamento dal database.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadTipiFinanziamento(idOrganizzazione, _callback) : void {
    this.gestioneBandiService.getTipiFinanziamento(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le tipologie di stakeholder dal database.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadTipologieStakeholder(idOrganizzazione, _callback) : void {
    this.gestionePartnerProgettoService.getTipologie(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le fasce di costo standard dal database.
   * @param idBando Id del bando di riferimento.
   * @param _callback Azione successiva.
   */
  loadFasceStandard(idBando, _callback) : void {
    this.gestioneBandiService.getFasceStandard(idBando).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le relazioni fascia di costo personale standard - tipologia stakeholder dal database.
   * @param idBando Id del bando di riferimento.
   * @param _callback Azione successiva.
   */
  loadFasceStandardBando(idBando, _callback) : void {
    this.gestioneBandiService.getFasceStandard(idBando).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica una specifica relazione fascia di costo personale standard - tipologia stakeholder dal database.
   * @param idFasciaCostoPersonaleStandard Id della fascia di costo personale standard di riferimento.
   * @param idTipologiaStakeholder Id della tipologia stakeholder di riferimento.
   * @param _callback Azione successiva.
   */
  loadFasciaBando(idFasciaCostoPersonaleStandard, idTipologiaStakeholder, _callback) : void {
    this.gestioneBandiService.getFasciaBando(idFasciaCostoPersonaleStandard, idTipologiaStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadPercentualiTipiFinanziamentoBando(idBando, _callback) : void {
    this.gestioneBandiService.getPercentualiTipiFinanziamento(idBando).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica i progetti dal database.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadProgetti(idOrganizzazione, _callback) : void {
    this.gestioneProgettoService.getProgettiFromRendicontaDB(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva un bando sul database.
   * @param bando Bando da salvare.
   * @param _callback Azione successiva.
   */
  saveBando(bando, _callback) : void {
    this.gestioneBandiService.saveBando(bando).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  savePercentualeTipoFinanziamento(percentualeTipoFinanziamento, _callback) : void {
    this.gestioneBandiService.savePercentualeTipoFinanziamento(percentualeTipoFinanziamento).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  updatePercentualeTipoFinanziamento(percentualeTipoFinanziamento, _callback) : void {
    this.gestioneBandiService.updatePercentualeTipoFinanziamento(percentualeTipoFinanziamento).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Effettua il reset di un bando sul database. Elimina tutti i record memorizzati su altre tabelle e collegati al bando.
   * @param nomeBando Nome del bando da resettare.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  resetBando(nomeBando, idOrganizzazione, _callback) : void {
    this.gestioneBandiService.resetBando(nomeBando, idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Elimina un bando dal database.
   * @param nomeBando Nome del bando da eliminare.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  deleteBando(nomeBando, idOrganizzazione, _callback) : void {
    this.gestioneBandiService.deleteBando(nomeBando, idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  deletePercentualiTipoFinanziamentoBando(idBando, _callback) : void {
    this.gestioneBandiService.deletePercentualiTipoFinanziamento(idBando).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva una relazione bando - voce di costo sul database.
   * @param bandoVoceDiCosto Relazione bando - voce di costo da salvare.
   * @param _callback Azione successiva.
   */
  saveBandoVoceDiCosto(bandoVoceDiCosto, _callback) : void {
    this.gestioneBandiService.saveBandoVoceDiCosto(bandoVoceDiCosto).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva una relazione bando - tipo attività cofinanziabile sul database.
   * @param bandoVoceDiCosto Relazione bando - tipo attività cofinanziabile da salvare.
   * @param _callback Azione successiva.
   */
  saveBandoTipoDiAttivitaCofinanziabile(bandoTipoDiAttivitaCofinanziabile, _callback) : void {
    this.gestioneBandiService.saveBandoTipoDiAttivitaCofinanziabile(bandoTipoDiAttivitaCofinanziabile).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva una relazione bando - tipo finanziamento sul database.
   * @param bandoVoceDiCosto Relazione bando - tipo finanziamento da salvare.
   * @param _callback Azione successiva.
   */
  saveBandoTipoDiFinanziamento(bandoTipoDiFinanziamento, _callback) : void {
    this.gestioneBandiService.saveBandoTipoDiFinanziamento(bandoTipoDiFinanziamento).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva una fascia di costo personale standard sul database.
   * @param index Indice nelle liste interne. Si trasmette da chiamante ad azione successiva per effettuare operazioni
   *              a fine lista.
   * @param fasciaStandard Fascia di costo personale standard da salvare.
   * @param _callback Azione successiva.
   */
  saveFasciaStandard(index, fasciaStandard, _callback) : void {
    this.gestioneBandiService.saveFasciaStandard(fasciaStandard).subscribe(
      data => { _callback(data, index); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva una relazione fascia di costo personale standard - tipologia stakeholder sul database.
   * @param fasciaStandardBando Relazione fascia di costo personale standard - tipologia stakeholder da salvare.
   * @param _callback Azione successiva.
   */
  saveFasciaStandardBando(fasciaStandardBando, _callback) : void {
    this.gestioneBandiService.saveFasciaStandardBando(fasciaStandardBando).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Gestore dell'evento di selezione/deselezione di un bando dalla lista bandi. Memorizza il bando selezionato, dopodiché
   * carica tutte le relazioni ad esso associate.
   * @param event Evento da gestire.
   * @param bando Bando di riferimento.
   */
  onSelectBando(event, bando) : void {
    event.preventDefault();
    if(bando.id == undefined) return;
    this.selectedBando = this.bandi.find(found => found.id === bando.id);
    this.loadVociDiCostoBando(this.selectedBando.id, this.organizzazione.id, (vociDiCostoBandoRef) => {
      this.vociDiCostoBando = vociDiCostoBandoRef != undefined ? vociDiCostoBandoRef : [];
      this.loadTipiAttivitaCofinanziabiliBando(this.selectedBando.id, this.organizzazione.id, (tipiAttivitaCofinanziabiliBandoRef) => {
        this.tipiAttivitaCofinanziabiliBando = tipiAttivitaCofinanziabiliBandoRef != undefined ? tipiAttivitaCofinanziabiliBandoRef : [];
        this.loadTipiFinanziamentoBando(this.selectedBando.id, this.organizzazione.id, (tipiFinanziamentoBandoRef) => {
          this.tipiFinanziamentoBando = tipiFinanziamentoBandoRef != undefined ? tipiFinanziamentoBandoRef : [];
          this.loadPercentualiTipiFinanziamentoBando(this.selectedBando.id, (percentualiTipiFinanziamentoBandoRef) => {
            if (percentualiTipiFinanziamentoBandoRef != undefined && percentualiTipiFinanziamentoBandoRef.length > 0) {
              this.quoteDiAgevolazione = percentualiTipiFinanziamentoBandoRef;
            }
            else {
              this.quoteDiAgevolazione = [];
              this.refreshQuoteDiAgevolazione();
            }
          })
        });
      });
    });

/*    this.loadPercentualiTipiFinanziamentoBando(this.selectedBando.id, this.organizzazione.id, (perentualitipoFinanziamentoBandoRef) => {
      this.perentualitipoFinanziamentoBando = perentualitipoFinanziamentoBandoRef != undefined ? perentualitipoFinanziamentoBandoRef : [];
    });*/

    this.fasce = [];
    this.loadFasceStandard(this.selectedBando.id, (fasceStandardRef) => {
      if(fasceStandardRef != undefined && fasceStandardRef.length > 0) {
        fasceStandardRef.forEach(fasciaRef => {
          const wrapper: FasciaStandardWrapper = { fascia: fasciaRef, fasceBando: [] };
          this.tipologieStakeholder.forEach(tipologia => {
            this.loadFasciaBando(fasciaRef.id, tipologia.id, (fasciaBandoRef) => {
              if(fasciaBandoRef != undefined) wrapper.fasceBando.push(fasciaBandoRef);
            });
          });
          this.fasce.push(wrapper);
        });
        this.fasce = this.fasce.concat([]);
      }
    });


  }

  /**
   * Aggiunge un bando alla lista dei bandi e aggiorna la lista.
   */
  onAddBando() : void {
    this.bandi.push({ id: undefined, nome: undefined, descrizione: undefined, organizzazione: this.organizzazione });
    this.bandi = this.bandi.concat([]);
  }

  /**
   * Rimuove un bando dalla lista dei bandi. Effettua il reset per il bando selezionato, dopodiché lo elimina dal database.
   * A fine operazione, ricarica la pagina.
   * @param bando Bando da eliminare.
   */
  onRemoveBando(bando) : void {
    this.askConfirm("Vuoi davvero eliminare il bando?", "Eventuali informazioni collegate saranno eliminate definitivamente.", () => {
      this.resetBando(bando.nome, this.organizzazione.id, (bandoReset) => {
        this.deleteBando(bando.nome, this.organizzazione.id, (bandoDeleted) => {
          this.openSnackBar("Bando " + bando.nome + " eliminato con successo.", "Chiudi");
          window.location.reload();
        });
      });
    });
  }

  /**
   * Gestisce l'evento di salvataggio di un bando. Se un bando è stato creato per la prima volta, lo salva semplicemente sul database,
   * altrimenti invoca il metodo di salvataggio di un bando da modifica. A fine operazione, ricarica la pagina.
   * @param bando Bando da salvare.
   */
  onSaveBando(bando) : void {
    if(bando.id === undefined) {
      if(bando.nome == undefined || bando.nome === "") this.showError("Impossibile salvare un bando privo di nome.");
      else if(bando.nome.length > this.NOME_MAX_LENGTH) this.showError("Nome del bando troppo lungo.");
      else if(this.bandi.filter(found => found.nome.trim().toUpperCase() === bando.nome.trim().toUpperCase()).length > 1) this.showError("Impossibile salvare bandi duplicati.");
      else {
        this.saveBando(bando, (savedBando) => {
          this.openSnackBar("Bando " + bando.nome + " salvato con successo.", "Chiudi");
          window.location.reload();
        });
      }
    }
    else {
      this.saveEditedBando(bando, () => {
        this.openSnackBar("Bando " + bando.nome + " modificato con successo.", "Chiudi");
        window.location.reload();
      });
    }
  }

  onUpdateQuoteDiAgevolazione(): void {
    this.quoteDiAgevolazione.forEach((quota) => {
      if (quota.id == undefined) {
        this.savePercentualeTipoFinanziamento(quota, (saveQuota) => {
          quota.id = saveQuota.id;
        });
      } else {
        this.updatePercentualeTipoFinanziamento(quota, () => {});
      }
    })
    this.openSnackBar("Quote di agevolazione salvate con successo.", "Chiudi");
  }

  /**
   * Metodo di supporto al salvataggio di un bando. Gestisce l'operazione di salvataggio di un bando dopo modifica.
   * Controlla che non vi siano errori, dopodiché resetta il bando selezionato, lo aggiorna su database e salva tutte le nuove relazioni
   * sul database.
   * @param bando Bando da salvare dopo modifica.
   * @param _callback Azione successiva.
   */
  private saveEditedBando(bando, _callback) : void {
    const errorMessage = this.checkForErrors();
    if(errorMessage != undefined) this.showError(errorMessage);
    else {
      this.askConfirm("Vuoi davvero salvare le modifiche fatte al bando selezionato?", "Le precedenti informazioni saranno sovrascritte.", () => {
        this.resetBando(bando.nome, this.organizzazione.id, (bandoReset) => {
          this.saveBando(bando, (bandoSaved) => {
            this.vociDiCostoBando.forEach(voceBandoRef => {
              this.saveBandoVoceDiCosto(voceBandoRef, () => {});
            });
            this.tipiAttivitaCofinanziabiliBando.forEach(tipoAttivitaCofinanziabileRef => {
              this.saveBandoTipoDiAttivitaCofinanziabile(tipoAttivitaCofinanziabileRef, () => {});
            });
            this.tipiFinanziamentoBando.forEach(tipoFinanziamentoRef => {
              this.saveBandoTipoDiFinanziamento(tipoFinanziamentoRef, () => {});
            });
            this.deletePercentualiTipoFinanziamentoBando(bando.id, () => {
              this.quoteDiAgevolazione.forEach((quota) => {
                this.savePercentualeTipoFinanziamento(quota, (saveQuota) => {
                  quota.id = saveQuota.id;
                });
              })
            })
            this.fasce.forEach(fasciaWrapper => {
              this.saveFasciaStandard(this.fasce.indexOf(fasciaWrapper), fasciaWrapper.fascia, (fasciaSaved, i) => {
                this.fasce[i].fasceBando.forEach(fasciaBando => {
                  fasciaBando.fasciaCostoPersonaleStandard = fasciaSaved;
                  this.saveFasciaStandardBando(fasciaBando, () => {});
                });
              });
            });
            this.openSnackBar("Bando " + bando.nome + " aggiornato con successo.", "Chiudi");
          });
        });
      });
    }
  }

  private refreshQuoteDiAgevolazione() {
    const vecchieQuote: QuotaDiAgevolazione[] = this.quoteDiAgevolazione.slice();
    this.quoteDiAgevolazione = [];

    this.tipiFinanziamentoBando.forEach((tf) => {
      this.tipiAttivitaCofinanziabiliBando.forEach((tac) => {
        this.tipologieStakeholder.forEach((ts) => {
          this.quoteDiAgevolazione.push({
            id: undefined,
            bando: this.selectedBando,
            tipoDiAttivitaCofinanziabile: tac.tipoDiAttivitaCofinanziabile,
            tipoDiFinanziamento: tf.tipoFinanziamento,
            tipologiaStakeholder: ts,
            percentuale: 0
          })
        })
      })
    })

    this.quoteDiAgevolazione.forEach((quota) => {
      const vecchiaQuota = vecchieQuote.find((found) => {
        return found.tipoDiAttivitaCofinanziabile.id == quota.tipoDiAttivitaCofinanziabile.id && found.tipoDiFinanziamento.id == quota.tipoDiFinanziamento.id && found.tipologiaStakeholder.id == quota.tipologiaStakeholder.id;
      })
      if (vecchiaQuota) {
        quota.percentuale = vecchiaQuota.percentuale;
        quota.id = vecchiaQuota.id;
      }
    })
  }

  /**
   * Gestisce l'evento di selezione/deselezione di un record (voce di costo, tipo attività cofinanziabile o tipo finanziamento).
   * Se il record era già selezionato, elimina la voce corrispondente dalla lista delle relazioni, altrimenti la inserisce in tale lista.
   * @param event Evento da gestire.
   * @param type Tipo di record selezionato/deselezionato. Valori ammessi:
   *             'vdc' per voce di costo;
   *             'tac' per tipo attività cofinanziabile;
   *             'tf' per tipo finanziamento.
   * @param record Record di riferimento.
   */
  onToggleRecord(event, type: string, record) : void {
    event.preventDefault();
    if(this.isEditBandoModeEnabled(this.selectedBando) === false) return;
    switch(type) {
      case "vdc":
        const ref = this.vociDiCostoBando.find(found => found.voceDiCosto.id === record.id);
        if(ref != undefined) this.vociDiCostoBando.splice(this.vociDiCostoBando.indexOf(ref), 1);
        else this.vociDiCostoBando.push({ id: undefined, bando: this.selectedBando, voceDiCosto: record, denominazione: record.nome, partecipaCalcoloSpeseGenerali: false });
        return;
      case "tac":
        const tacRef = this.tipiAttivitaCofinanziabiliBando.find(found => found.tipoDiAttivitaCofinanziabile.id === record.id);
        if(tacRef != undefined) this.tipiAttivitaCofinanziabiliBando.splice(this.tipiAttivitaCofinanziabiliBando.indexOf(tacRef), 1);
        else this.tipiAttivitaCofinanziabiliBando.push({ id: undefined, bando: this.selectedBando, tipoDiAttivitaCofinanziabile: record });
        this.refreshQuoteDiAgevolazione();
        return;
      case "tf":
        const tfRef = this.tipiFinanziamentoBando.find(found => found.tipoFinanziamento.id === record.id);
        if(tfRef != undefined) this.tipiFinanziamentoBando.splice(this.tipiFinanziamentoBando.indexOf(tfRef), 1);
        else this.tipiFinanziamentoBando.push({ id: undefined, bando: this.selectedBando, tipoFinanziamento: record });
        this.refreshQuoteDiAgevolazione();
        return;
      default:
        return;
    }
  }

  /**
   * Gestisce l'evento di selezione/deselezione di una tipologia stakeholder in una fascia di costo personale standard.
   * Se era già selezionata, elimina la relazione fascia costo personale standard - tipologia stakeholder dalla lista, altrimenti la aggiunge.
   * @param event Evento da gestire.
   * @param fasciaWrapper Wrapper di relazione fascia - tipologia di riferimento.
   * @param tipologia Tipologia stakeholder di riferimento.
   */
  onToggleFasciaBando(event, fasciaWrapper: FasciaStandardWrapper, tipologia) : void {
    event.preventDefault();
    if(this.isEditBandoModeEnabled(this.selectedBando) === false) return;
    const ref = fasciaWrapper.fasceBando.find((found) => found.tipologiaStakeholder.id === tipologia.id);
    if(ref != undefined) fasciaWrapper.fasceBando.splice(fasciaWrapper.fasceBando.indexOf(ref), 1);
    else fasciaWrapper.fasceBando.push({ id: undefined, fasciaCostoPersonaleStandard: fasciaWrapper.fascia, tipologiaStakeholder: tipologia, costoOrario: 0 });
  }

  /**
   * Gestisce l'evento di selezione/deselezione del flag di spese generali calcolate in percentuale.
   * Se è selezionato, inserisce undefined nel campo tettoMaxSpeseGenerali del bando, altrimenti lo inizializza a 0.
   * Rispetta il comportamento dato dal back-end: se il campo è NULL, allora il bando non prevede spese generali calcolate in percentuale.
   * @param event Evento da gestire.
   */
  onTogglePercentualeSpeseGenerali(event) : void {
    event.preventDefault();
    if(this.isEditBandoModeEnabled(this.selectedBando) === false) return;
    this.selectedBando.tettoMaxSpeseGenerali = this.selectedBando.tettoMaxSpeseGenerali == undefined ? 0 : undefined;
  }

  /**
   * Gestisce l'evento di selezione livello attività in WBS al primo livello.
   */
  onLivelloAttivitaInWbsPrimoLivelloToggle() : void {
    if(this.isEditBandoModeEnabled(this.selectedBando) === false) return;
    this.selectedBando.confLivelloAttivitaInWbs = false;
    this.selectedBando.confLivelloTipoAttivitaCofinanziabileInWbs = false;
    this.selectedBando.confLivelloCostiInWbs = false
  }

  /**
   * Aggiunge un wrapper di fascia di costo personale standard nella lista corrispondente.
   */
  onAddFascia() : void {
    this.fasce.push({fascia: { id: undefined, nome: undefined, descrizione: undefined, bando: this.selectedBando }, fasceBando: []});
    this.fasce = this.fasce.concat([]);
  }

  /**
   * Rimuove un wrapper di fascia costo personale standard dalla lista corrispondente.
   * @param fasciaWrapper Wrapper da rimuovere.
   */
  onRemoveFascia(fasciaWrapper: FasciaStandardWrapper) : void {
    this.fasce.splice(this.fasce.indexOf(fasciaWrapper), 1);
    this.tipologieStakeholder.forEach(tipologia => {
      const relRef = this.getFasciaBando(fasciaWrapper, tipologia);
      if(relRef != undefined) fasciaWrapper.fasceBando.splice(fasciaWrapper.fasceBando.indexOf(relRef), 1);
    });
    this.fasce = this.fasce.concat([]);
  }

  /**
   * Apre il dialog di gestione di voci di costo, tipi di attività cofinanziabile e tipi finanziamento.
   */
  onGestisciValori() : void {
    const gestioneValoriModalDialogRef = this.gestioneValoriModalDialog.open(GestioneValoriModalComponent);
  }

  /**
   * Verifica se un record (voce di costo, tipo attività cofinanziabile, tipo finanziamento) è selezionato.
   * Restituisce TRUE se esiste una relazione record - bando nella lista corrispondente, FALSE altrimenti.
   * @param type Tipo di record selezionato/deselezionato. Valori ammessi:
   *             'vdc' per voce di costo;
   *             'tac' per tipo attività cofinanziabile;
   *             'tf' per tipo finanziamento.
   * @param record Record da controllare.
   */
  isRecordChecked(type: string, record) : boolean {
    switch(type) {
      case "vdc":
        return this.vociDiCostoBando.find(found => found.voceDiCosto.id === record.id) != undefined;
      case "tac":
        return this.tipiAttivitaCofinanziabiliBando.find(found => found.tipoDiAttivitaCofinanziabile.id === record.id) != undefined;
      case "tf":
        return this.tipiFinanziamentoBando.find(found => found.tipoFinanziamento.id === record.id) != undefined;
      default:
        return false;
    }
  }

  /**
   * Verifica se una tipologia stakeholder è selezionata per una fascia di costo personale standard.
   * Restituisce TRUE se esiste una relazione fascia - tipologia nella lista corrispondente, FALSE altrimenti.
   * @param fasciaWrapper Wrapper da controllare.
   * @param tipologia Tipologia di riferimento.
   */
  isFasciaBandoChecked(fasciaWrapper: FasciaStandardWrapper, tipologia) : boolean {
    return fasciaWrapper.fasceBando.find((found) => found.tipologiaStakeholder.id === tipologia.id) != undefined;
  }

  /**
   * Verifica se un bando si può rimuovere o modificare.
   * Restituisce TRUE se non esistono progetti collegati al bando, FALSE altrimenti.
   * @param bando Bando da controllare.
   */
  isEditBandoModeEnabled(bando) : boolean {
    return this.progetti.filter(filtered => filtered.bando.id === bando.id).length === 0;
  }

  /**
   * Restituisce la relazione record - bando di riferimento.
   * @param type Tipo di record selezionato/deselezionato. Valori ammessi:
   *             'vdc' per voce di costo;
   *             'tac' per tipo attività cofinanziabile;
   *             'tf' per tipo finanziamento.
   * @param record Record di riferimento.
   */
  getBandoRecordRelation(type: string, record) : any {
    switch(type) {
      case "vdc":
        return this.vociDiCostoBando.find(found => found.voceDiCosto.id === record.id);
      case "tac":
        return this.tipiAttivitaCofinanziabiliBando.find(found => found.tipoDiAttivitaCofinanziabile.id === record.id);
      case "tf":
        return this.tipiFinanziamentoBando.find(found => found.tipoFinanziamento.id === record.id);
      default:
        return undefined;
    }
  }

  /**
   * Restituisce la relazione fascia costo personale standard - tipologia stakeholder di riferimento.
   * @param fasciaWrapper Wrapper di riferimento.
   * @param tipologia Tipologia di riferimento.
   */
  getFasciaBando(fasciaWrapper: FasciaStandardWrapper, tipologia) : any {
    return fasciaWrapper.fasceBando.find((found) => found.tipologiaStakeholder.id === tipologia.id)
  }

  /**
   * Inizializza i dati dal database. Carica la lista dei bandi, dopodiché, in parallelo, voci di costo, tipi attività cofinanziabili,
   * tipi finanziamento, tipologie stakeholder e progetti.
   * @param _callback Azione successiva.
   */
  initDataFromDatabase(_callback) : void {
    this.loadBandi(this.organizzazione.id, (bandiRef) => {
      this.bandi = bandiRef != undefined ? bandiRef : [];
      this.bandiOriginal = bandiRef != undefined ? bandiRef : [];
      this.loadVociDiCosto(this.organizzazione.id, (vociDiCostoRef) => {
        this.vociDiCosto = vociDiCostoRef != undefined ? vociDiCostoRef : [];
      });
      this.loadTipiAttivitaCofinanziabili(this.organizzazione.id, (tipiAttivitaCofinanziabiliRef) => {
        this.tipiAttivitaCofinanziabili = tipiAttivitaCofinanziabiliRef != undefined ? tipiAttivitaCofinanziabiliRef : [];
      });
      this.loadTipiFinanziamento(this.organizzazione.id, (tipiFinanziamentoRef) => {
        this.tipiFinanziamento = tipiFinanziamentoRef != undefined ? tipiFinanziamentoRef : [];
      });
      this.loadTipologieStakeholder(this.organizzazione.id, (tipologieStakeholderRef) => {
        this.tipologieStakeholder = tipologieStakeholderRef != undefined ? tipologieStakeholderRef : [];
      });
      this.loadProgetti(this.organizzazione.id, (progettiRef) => {
        this.progetti = progettiRef != undefined ? progettiRef : [];
        this.progetti.forEach(progetto => {
        });
      });
      _callback();
    });
  }

  /**
   * Controlla che non vi siano errori nei record inseriti. E' utilizzato prima di eventi di salvataggio.
   */
  checkForErrors() : string {
    var res: string = undefined;
    if(this.selectedBando.descrizione != undefined && this.selectedBando.descrizione.length > this.DESCRIZIONE_MAX_LENGTH)
      return "La descrizione inserita è troppo lunga.";
    this.fasce.forEach(fasciaWrapper => {
      if(fasciaWrapper.fascia.nome == undefined) { res = "Impossibile memorizzare fasce di costo standard prive di nome."; return; }
      if(fasciaWrapper.fascia.nome.length > this.NOME_FASCIA_MAX_LENGTH) { res = "Il nome della fascia di costo inserito è troppo lungo."; return; }
      if(fasciaWrapper.fascia.descrizione != undefined && fasciaWrapper.fascia.descrizione.length > this.DESCRIZIONE_FASCIA_MAX_LENGTH) { res = "La descrizione della fascia di costo inserita è troppo lunga."; return; }
      if(this.fasce.filter(filtered => filtered.fascia.nome === fasciaWrapper.fascia.nome).length > 1)  { res = "Impossibile memorizzare fasce di costo duplicate."; return; }
      if(fasciaWrapper.fasceBando.find(found => found.costoOrario == undefined) != undefined) { res = "Impossibile memorizzare fasce di costo standard prive di costo orario."; return; }
      if(fasciaWrapper.fasceBando.find(found => +(found.costoOrario) < 0) != undefined) { res = "Impossibile memorizzare fasce di costo standard con costo orario negativo."; return; }
    });
    return res;
  }

  /**
   * Filtra la lista dei bandi in base al testo inserito in input, filtrando su nome del bando.
   * @param event Evento da gestire.
   */
  filter(event) : void {
    if(event.target.value != undefined)
      this.bandi = this.bandiOriginal.filter(filtered => filtered.nome.trim().toUpperCase().includes(event.target.value.trim().toUpperCase()));
    else this.bandi = this.bandiOriginal.concat([]);
  }

  /**
   * Mostra un messaggio di notifica all'utente.
   * @param message Messaggio di notifica da mostrare.
   * @param action Azione da permettere.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

    /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  /**
   * Mostra un messaggio di richiesta di conferma all'utente.
   * @param message Messaggio di richiesta di conferma.
   * @param submessage Messaggio inferiore.
   * @param _callback Azione successiva, in caso di conferma della richiesta.
   */
  askConfirm(message: string, submessage: string, _callback) : void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if(res) _callback();
      }
    );
  }

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      this.loadOrganizzazione(this.userStorage.getOrganizzazione(), (organizzazioneRef) => {
        this.organizzazione = organizzazioneRef;
        this.initDataFromDatabase(() => {

        });
      });
    });
  }

}

