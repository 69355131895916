import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class MonitoraggioDeliverablesProgettoService {

  constructor(private http: HttpClient) {}

  public getAllDeliverableWrappersFromProgettoId(idProgetto: number, username: string): Observable<any> {
    return  this.http.get<any>(AppSettings.BASE_URL+'wbs-master/get-ultima-versione-wbs-progetto-deliverables?idProgetto=' + idProgetto + '&username=' + username);
  }

  public updateDeliverable(deliverable): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'deliverable/update-deliverable', deliverable);
  }

}
