import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OrganizzazioniService } from './organizzazioni.service';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AppErrors } from '../app.errors';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { Router } from '@angular/router';
import { UtilityModuleComponent } from '../core_modules/utility-module/utility-module.component';

const NOME_MAX_LENGTH=45;
const DESCRIZIONE_MAX_LENGTH=100;
const CODICE_FISCALE_MAX_LENGTH=16;

@Component({
  selector: 'app-organizzazioni',
  templateUrl: './organizzazioni.component.html',
  styleUrls: ['./organizzazioni.component.css']
})
export class OrganizzazioniComponent implements OnInit {

  constructor(private http: HttpClient, private organizzazioniService: OrganizzazioniService, private utilityModule: UtilityModuleComponent,
    private router: Router, public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>) { }


  organizzazioni_section_title = "Organizzazioni";

  organizzazioni_table_data;

  old_organizzazioni_table_data;

  organizzazioni_table_settings = {
    noDataMessage: "Nessun dato da visualizzare",
    columns: {
      nome: {
        title: 'Nome'
      },
      descrizione: {
        title: 'Descrizione'
      },
      codiceFiscale: {
        title: 'Codice Fiscale o Partita Iva'
      },
      attiva: {
        title: 'Attiva',
        editor: {
          type: 'checkbox',
          config: {
            true: 'Sì',
            false: 'No',
          },
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'Seleziona',
            list: [
              { value: 'Sì', title: 'Sì' },
              { value: 'No', title: 'No' }
            ],
          },
        },
      },
    },
    actions: {
      add: true,
      edit: true,
      delete: false,
      columnTitle: ""
    },
    edit: {
      editButtonContent: "Modifica ",
      saveButtonContent: "Aggiorna",
      cancelButtonContent: "Annulla",
      confirmSave: true
    },
    delete: {
      deleteButtonContent: "Elimina ",
      confirmDelete: true
    },
    add: {
      addButtonContent: "Aggiungi nuova",
      createButtonContent: "Aggiungi",
      cancelButtonContent: "Annulla",
      confirmCreate: true
    },
    attr: {
      class: 'table table-bordered organizzazioni-table'
    },
    pager: {
      display: true,
      perPage: 10
    },
    mode: "inline",
    rowClassFunction: (row) => {
      if (row.data.nome === '') {
        return 'warning-color';
      } 
      return '';
    }
  };


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  //METODO - controlla la validità dei campi immessi in fase di modifica nella 
  //tabella delle organizzazioni
  //RITORNA: string value - se è stato rilevato un errore. In questo caso "value" contiene
  //                        l'errore da mostrare all'utente nel modale di errore
  //         undefined - se non è stato rilevato alcun errore    
  checkIfDataIsValid(oldData,newData):string {
    if (!oldData) { 
      if(!newData || newData.nome.length<=0 || newData.nome.length>NOME_MAX_LENGTH) {
        //il nome dell'organizzazione non può essere vuoto o maggiore di VARCHAR(45)
        return AppErrors.NOME_ORGANIZZ_LENGTH_ERROR; 
      }
      if(!newData || newData.descrizione.length>DESCRIZIONE_MAX_LENGTH) {
        //la descrizione dell'organizzazione non può essere maggiore di VARCHAR(100)
        return AppErrors.DESCRIZIONE_ORGANIZZ_LENGTH_ERROR; 
      }
      if(!newData || newData.codiceFiscale.length>CODICE_FISCALE_MAX_LENGTH) {
        //Il codice fiscale dell'organizzazione non può essere maggiore di VARCHAR(16)
        return AppErrors.CODICE_FISCALE_ORGANIZZ_LENGTH_ERROR; 
      }
      if (!newData.attiva) {
        newData.attiva="Sì";
      }
      return undefined;
    }
    if(oldData.nome!=newData.nome) {
      if(newData.nome.length<=0 || newData.nome.length>NOME_MAX_LENGTH) {
        //il nome dell'organizzazione non può essere vuoto o maggiore di VARCHAR(45)
        return AppErrors.NOME_ORGANIZZ_LENGTH_ERROR; 
      }
    }
    if(oldData.descrizione!=newData.descrizione) {
      if(newData.descrizione.length>DESCRIZIONE_MAX_LENGTH) {
        //la descrizione dell'organizzazione non può essere maggiore di VARCHAR(100)
        return AppErrors.DESCRIZIONE_ORGANIZZ_LENGTH_ERROR; 
      }
    }
    if(oldData.codiceFiscale!=newData.codiceFiscale) {
      if(newData.codiceFiscale.length>CODICE_FISCALE_MAX_LENGTH) {
        //Il codice fiscale dell'organizzazione non può essere maggiore di VARCHAR(16)
        return AppErrors.CODICE_FISCALE_ORGANIZZ_LENGTH_ERROR; 
      }
    }
    return undefined;
  }

  onDeleteConfirm(event) {
    this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: "Vuoi davvero eliminare questa organizzazione?",
        submessage: "L'eliminazione comporterà la disattivazione di tutti gli utenti associati all'organizzazione selezionata"
      },
      panelClass: 'custom-confirm-container'
    });
    if(event.data.attiva=="Sì") {
      event.data.attiva=true;
    } else {
      event.data.attiva=false;
    }
    this.dialogRef.afterClosed().subscribe(
      res => {
        if(res==true) {   
          this.organizzazioniService.deleteOrganizzazione(event.data).subscribe(
            data =>{
              event.confirm.resolve(event.data);
            },
            err =>{
              console.log("*************************************************");
              console.log("ERROR...")
              console.log(err);
              console.log("*************************************************");
            }
          )
        } else {
          event.confirm.reject();
        }
      }
    );    
  }

  saveOrganizzazione(event, organizzazione) {
    if (organizzazione.attiva=="Sì") {
      organizzazione.attiva=true;
    } else {
      organizzazione.attiva=false;
    }
    this.organizzazioniService.saveNuovaOrganizzazione(organizzazione).subscribe(
      data => {
        let savedOrganizzazione = JSON.parse(JSON.stringify(data));
        this.loadOrganizzazioni();
        event.confirm.resolve();
        this.openSnackBar("Dati memorizzati con successo", "Chiudi");
      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        event.confirm.reject();
      }
    );
  }

  onSaveConfirm(event) {
    if (!event.newData.attiva || (event.newData.attiva == '')) {
      event.newData.attiva = "No";
    }
    let result=this.checkIfDataIsValid(event.data,event.newData);
    if (result) {
      this.dialog.open(WarningMessageComponent,{
        data: {
          message: result
        },
        panelClass: 'custom-warning-container'
      });
      event.confirm.reject();
      return;
    } else {
      // Avvisa se è in modifica e sta cambiando stato di attivazione/disattivazione dell'organizzazione
      if (event.data) { 
        if (event.data.attiva != event.newData.attiva) {
          if (event.newData.attiva == "Sì") {
            // Sta (ri)attivando una organizzazione disattiva
            this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
              width: "380px",
              data: {
                message: "Vuoi davvero ri-attivare questa Organizzazione?",
                submessage: "L'attivazione comporterà la ri-attivazione di tutti gli utenti dell'Organizzazione con ruolo Amministratore consentendone l'accesso alla piattaforma."
              },
              panelClass: 'custom-confirm-container'
            });
            this.dialogRef.afterClosed().subscribe(
              res => {
                if (res == true) {  
                  this.saveOrganizzazione(event,event.newData);
                  return;
                } else {
                  event.confirm.reject();
                  return;
                }
              }
            );  
          } else if (event.newData.attiva == "No") {
            // Sta disattivando una organizzazione esistente e attiva
            this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
              width: "380px",
              data: {
                message: "Vuoi davvero disattivare questa Organizzazione?",
                submessage: "L'operazione comporterà la disattivazione di tutti gli utenti dell'Organizzazione impedendone l'accesso alla piattaforma."
              },
              panelClass: 'custom-confirm-container'
            });
            this.dialogRef.afterClosed().subscribe(
              res => {
                if (res==true) {  
                  this.saveOrganizzazione(event,event.newData); 
                  return;
                } else {
                  event.confirm.reject();
                  return;
                }
              }
            );  
          }
        } else {
          this.saveOrganizzazione(event,event.newData);
          return; 
        }
      } else {
        // Sta inserendo una nuova organizzazione
        this.dialogRef = this.dialog.open(ConfirmMessageComponent,{
          width: "340px",
          data: {
            message: "Vuoi davvero inserire questa nuova Organizzazione?",
            submessage: "Per renderla operativa sarà necessaria attivarla e creare almeno un utente Amministratore."
          },
          panelClass: 'custom-confirm-container'
        });
        this.dialogRef.afterClosed().subscribe(
          res => {
            if (res==true) {  
              this.saveOrganizzazione(event,event.newData);
              return; 
            } else {
              event.confirm.reject();
              return;
            }
          }
        );  
      }
    }
  }

  nothingChanged() {
    if(!this.organizzazioni_table_data || !this.old_organizzazioni_table_data) {
      return true;
    }
    if(this.organizzazioni_table_data.length != this.old_organizzazioni_table_data.length){
      return false;
    }
    if(JSON.stringify(this.organizzazioni_table_data).toLowerCase() === JSON.stringify(this.old_organizzazioni_table_data).toLowerCase()) {
      return true;
    }
    return false;
  }

  /*saveOrganizzazioni() {
    this.organizzazioni_table_data.forEach(element => {
      if(element.attiva=="Sì")
      {
        element.attiva=true;
      }
      else
      {
        element.attiva=false;
      }
    });
    this.organizzazioniService.saveOrganizzazioni(this.organizzazioni_table_data).subscribe(
      data => {
        this.loadOrganizzazioni();    */
        /* this.organizzazioni_table_data=data;
        this.organizzazioni_table_data.forEach(element => {
          if(element.attiva==true)
          {
            element.attiva="Sì";
          }
          else
          {
            element.attiva="No";
          }
        });
        this.old_organizzazioni_table_data=JSON.parse(JSON.stringify(data)); */
        /*
        this.openSnackBar("Dati memorizzati con successo", "Chiudi")
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...")
        console.log(err);
        console.log("*************************************************");
      }
    );
  }    */

  redirect(newPage) {
    this.utilityModule.redirectSuperUser(newPage);
  }

  annullaModifiche(){
    this.organizzazioni_table_data=JSON.parse(JSON.stringify(this.old_organizzazioni_table_data));
  }

  loadOrganizzazioni() {
    this.organizzazioniService.getAllOrganizzazioni().subscribe(
      data => {
         this.organizzazioni_table_data=data;
         this.organizzazioni_table_data.forEach(element => {
           if(element.attiva==true) {
             element.attiva="Sì";
           } else {
             element.attiva="No";
           }
         });
         this.old_organizzazioni_table_data=JSON.parse(JSON.stringify(data));
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...")
        console.log(err);
        console.log("*************************************************");
      }
    );
  }

  ngOnInit() {
    this.loadOrganizzazioni();
  }

}
