import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ReportisticaProgettiService {

  constructor(private http: HttpClient) {}

  public getProgettiFromRendicontaDB(organizzazioneId: number): Observable<any> {
    return  this.http.get<any>(AppSettings.BASE_URL+'progetti/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
  }

  public getWbsVersionsByProgettoId(progettoId: number): Observable<any> {
    return  this.http.get<any>(AppSettings.BASE_URL+'wbs-master/get-all-wbs-versions?idProgetto=' + progettoId);
  }

}