import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AuthService } from '../core_modules/auth.service';
import { LoadingDialogService } from '../modals/loading-dialog/loading-dialog.service';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { RiskManagerService } from './risk-manager.service';
import { Chart } from 'node_modules/chart.js';
import { OrganizzazioniService } from '../organizzazioni/organizzazioni.service';
import { UserStorage } from '../core_modules/user.storage';
import { ImportProgettoModalComponent } from './modals/import-progetto-modal/import-progetto-modal.component';

export interface Activity {
  id: number;
  plannedDuration: number;
  unitOfResources: number;
  numberOfQualityRequirements: number;
}

export interface ActivityWrapper {
  activity: Activity;
  startDate: number;
  endDate: number;
}

@Component({
  selector: 'app-risk-manager',
  templateUrl: './risk-manager.component.html',
  styleUrls: ['./risk-manager.component.css']
})
export class RiskManagerComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public confirmDialog: MatDialog,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    public riskManagerService: RiskManagerService,
    public organizzazioniService: OrganizzazioniService,
    private userStorage: UserStorage
  ) { }

  public colors = [
    '#2f4f4f','#556b2f','#8b4513','#6b8e23','#2e8b57','#228b22','#800000','#191970',
    '#708090','#bc8f8f','#bdb76b','#008b8b','#cd853f','#4682b4','#000080','#9acd32',
    '#cd5c5c','#32cd32','#daa520','#7f007f','#8fbc8f','#b03060','#9932cc','#ff0000',
    '#00ced1','#ff8c00','#ffd700','#ffff00','#c71585','#0000cd','#00ff00','#00fa9a',
    '#e9967a','#dc143c','#0000ff','#a020f0','#adff2f','#da70d6','#d8bfd8','#ff7f50',
    '#ff00ff','#1e90ff','#dda0dd','#7b68ee','#afeeee','#98fb98','#87cefa','#7fffd4',
    '#ffdab9','#ff69b4'
  ];

  dailyWorkingHours = 8;
  wpsDuration = 4;
  costOfWork = 10;
  timeOfCompletion = 45;
  activityWrappers: ActivityWrapper[] = [];

  scheduling = [];
  indexes = [];

  barChart: Chart = undefined;
  ganttChart: Chart = undefined;

  private arrangeActivityWrappers(): void {
    this.activityWrappers.forEach(aw => {
      aw.activity.plannedDuration = aw.endDate - aw.startDate;
    });
  }

  schedule(_callback): void {
    this.arrangeActivityWrappers();
    this.riskManagerService.schedule(this.dailyWorkingHours, this.wpsDuration, this.costOfWork, this.timeOfCompletion, this.activityWrappers).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  compute(_callback): void {
    this.riskManagerService.compute(this.scheduling).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  import(projectId, _callback): void {
    this.riskManagerService.import(projectId).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  cost(projectId, _callback): void {
    this.riskManagerService.cost(projectId).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  getProgetti(_callback) : void {
    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        this.riskManagerService.getProgetti(data.id).subscribe(
          data => { _callback(data); },
          err => { this.showError(err.error.message); }
        )
      },
      err => { this.showError(err.error.message); }
    );
  }

  onImport() : void {
    this.getProgetti((progetti) => {
      const dialogRef = this.dialog.open(ImportProgettoModalComponent, {
        data: progetti
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined) {
          this.importActivityWrappers(result, () => {
            this.timeOfCompletion = this.activityWrappers[this.activityWrappers.length - 1].endDate;
            this.cost(result.id, (cost) => {
              this.costOfWork = cost;
            });
          });
        }
      });
    });
  }

  private importActivityWrappers(project, _callback) : void {
    this.import(project.id, (wrappers) => {
      this.activityWrappers = [];
      this.onAddElement();
      if (wrappers != undefined && wrappers.length > 0) {
        this.activityWrappers = [];
        wrappers.forEach(w => {
          this.activityWrappers.push({
            activity: {
              id: this.activityWrappers.length > 0 ? this.activityWrappers.length : 0,
              plannedDuration: w.record.plannedDuration,
              unitOfResources: w.unitOfResources,
              numberOfQualityRequirements: w.numberOfQualityRequirements
            },
            startDate: w.record.startDate,
            endDate: w.record.endDate
          });
        });
        this.activityWrappers = this.activityWrappers.concat([]);
      }
      _callback();
    })
  }

  drawGantt(): void {
    if (this.ganttChart == undefined) {
      this.ganttChart = new Chart("gantt", {
        type: 'horizontalBar',
        data: {
          labels: [],
          datasets: [{
            data: [],
            borderWidth: 1
          }]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }],
            xAxes: [{
              ticks: {
                max: this.indexes.length * this.wpsDuration,
                min: 0,
                stepSize: this.wpsDuration
              } 
            }]
          }
        }
      });
    }

    var labels = [];
    var values = [];

    for(var i=1; i<=this.activityWrappers.length; i++) {
      labels.push(i + "");
      values.push([this.activityWrappers[i-1].startDate, this.activityWrappers[i-1].endDate]);
    }

    this.ganttChart.data.labels = labels.concat([]);
    this.ganttChart.data.datasets[0].data = values.concat([]);
    this.ganttChart.data.datasets[0].backgroundColor = this.colors.slice(0, values.length);

    this.ganttChart.options.scales.xAxes = [{
      ticks: {
        max: this.indexes.length * this.wpsDuration,
        min: 0,
        stepSize: this.wpsDuration
      } 
    }];

    this.ganttChart.update();
  }

  drawBarchart(): void {
    if (this.barChart == undefined) {
      this.barChart = new Chart("barchart", {
        type: 'bar',
        data: {
          labels: [],
          datasets: [{
            label: 'Risk Index',
            data: [],
            borderWidth: 1
          }]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    }

    var labels = [];
    var values = [];
    var colors = [];

    for(var i=1; i<=this.indexes.length; i++) {
      labels.push("SAL " + i);
      values.push(this.indexes[i-1]);
      colors.push('rgba(0, 0, 139, ' + this.indexes[i-1] + ')');
    }

    this.barChart.data.labels = labels.concat([]);
    this.barChart.data.datasets[0].data = values.concat([]);
    this.barChart.data.datasets[0].backgroundColor = colors.concat([]);

    this.barChart.update();
  }

  onCompute(): void {
    this.scheduling = [];
    this.indexes = [];
    
    this.schedule((s) => {
      this.scheduling = s;
      console.log(s);
      this.compute((r) => {
        this.indexes = r;
        console.log(r);
        this.drawGantt();
        this.drawBarchart();
      })
    });
  }

  onAddElement(): void {
    this.activityWrappers.push({
      activity: {
        id: this.activityWrappers.length > 0 ? this.activityWrappers.length : 0,
        plannedDuration: 1,
        unitOfResources: 0,
        numberOfQualityRequirements: 0
      },
      startDate: this.activityWrappers.length > 0 ? this.activityWrappers[this.activityWrappers.length - 1].endDate + 1 : 0,
      endDate: this.activityWrappers.length > 0 ? this.activityWrappers[this.activityWrappers.length - 1].endDate + 2 : 0
    });
    this.activityWrappers = this.activityWrappers.concat([]);
  }

  onRemoveElement(element: ActivityWrapper): void {
    this.activityWrappers.splice(this.activityWrappers.indexOf(element), 1);
    this.activityWrappers = this.activityWrappers.concat([]);
  }

  shuffle(array) : void {
    for(var i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i)
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
  }

  showError(error: String): void {
    this.dialog.open(WarningMessageComponent, {
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      this.onAddElement();
      this.shuffle(this.colors);
    });
  }

}
