import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { read } from 'fs';
import { SharedDataService } from 'src/app/common-services/shared-data.service';
import { UtilsService } from 'src/app/common-services/utils.service';
import { CreaProgettoService } from 'src/app/crea-progetto/crea-progetto.service';
import { GestioneProgettoService } from 'src/app/gestione-progetto/gestione-progetto.service';
import { GestioneContrattiCollaborazioneModalService } from 'src/app/pianificazione-costi/modals/gestione-contratti-collaborazione-modal/gestione-contratti-collaborazione-modal.service';
import { PianificazioneCostiService } from 'src/app/pianificazione-costi/pianificazione-costi.service';
import { RotellinaService } from 'src/app/rotellina-dialog/rotellina.service';
import { MonitoraContrattiService } from './monitora-contratti.service';

@Component({
  selector: 'app-monitora-contratti-modal',
  templateUrl: './monitora-contratti-modal.component.html',
  styleUrls: ['./monitora-contratti-modal.component.css']
})
/**
 * 
 *  id organizzazione(ok) -> getStakeholders -> id stakeholder -> getSediStakeholder -> id sedi stakeholder -> getPersone (id sede e id organizzazione) -> persone id -> 
 *  progetto id OK
 * 
 *  persone + id progetto => getProgettoPersona
 */

export class MonitoraContrattiModalComponent implements OnInit {

  constructor(

    public dialogRef: MatDialogRef<MonitoraContrattiModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedDataService: SharedDataService,
    private creaProgettoService: CreaProgettoService,
    private pianificazioneCostiService: PianificazioneCostiService,
    private rotella: RotellinaService,
    public utilsService: UtilsService,
    private monitoraContrattiService: MonitoraContrattiService
  ) { }
  public contratti = [];
  public personale = [];
  public personaleContratti = [];
  public personaleContrattiDef = [];

  public displayedColumns: string[] = ['periodo', 'ore', 'costoOrario', 'importoLordoTotale', 'attivita', 'annotazioni'];
  public showEmptyMessage = false;

  public convertDataToMonthYear(datemillisencond: number): string {
    const date = new Date(datemillisencond);
    return date.getMonth() + 1 + '/' + date.getFullYear();
  }
  public convertMonthYearToMills(month: string | number, year: string | number): number {
    const date = new Date(month + "/01/" + year);
    return date.getTime();
  }
  async ngOnInit() {
    //console.log(this.data);
    this.showEmptyMessage = false;
    this.rotella.openDialog();
    const organizzazioneID = this.sharedDataService.getOrganizzazioneID();
    const progettoID = this.data.progettoID;
    const personale = await this.monitoraContrattiService.getPersoneProgetto(progettoID).toPromise();
    const personale_mapped = personale.map(persona => {
      return {
        anagrafica: {
          id: persona[0],
          nome: persona[1],
          cognome: persona[2],
          id_sede_stakeholder: persona[3],
        }
      }
    })

    this.personaleContratti = await Promise.all(personale_mapped.map(async persona => {
      const personaID = persona.anagrafica.id;
      //const progettoID = persona.pianificazioneProgettoPersona.progetto.id;
      const sedeID = persona.anagrafica.id_sede_stakeholder;
      const contratti = await this.monitoraContrattiService.getContratti(sedeID, progettoID, personaID).toPromise();

      const contratti_mappati = contratti.map(contratto => {
        return { id: contratto[0], dataInizio: contratto[1], dataFine: contratto[2], ore: contratto[3], costoOrario: contratto[4], importoLordoTotale: contratto[5], annotazioni: contratto[6], id_progetto: contratto[7], id_persona: contratto[8], id_sede_stakeholder: contratto[9] }
      });
      const ore_costi_rendicontati = await this.monitoraContrattiService.getOreAndCostiRendicontati(sedeID, progettoID, personaID).toPromise();

      const inizio_periodo = await this.monitoraContrattiService.getInizioPeriodoRendicontazione(sedeID, progettoID, personaID).toPromise();
      const fine_periodo = await this.monitoraContrattiService.getFinePeriodoRendicontazione(sedeID, progettoID, personaID).toPromise();
      
      const data = { ...persona, contratto: contratti_mappati, ore_costi_rendicontati: ore_costi_rendicontati[0], inizio_periodo: inizio_periodo[0] || [0,0], fine_periodo: fine_periodo[0]|| [0,0] };
      console.log(data);
      return data;
      
    }));
    this.personaleContratti = this.personaleContratti.filter(persona => persona.contratto.length > 0);
    this.personaleContratti = this.personaleContratti.map(persona => {
      persona.tabledatasource = new MatTableDataSource<any>();
      persona.tabledatasource.data = persona.contratto;
      return persona;
    });  //add tabledatasource;

    console.log(this.personaleContratti);
    //this.personaleContratti[0].contratto[0].pianificazioneProgettoPersona.progetto.id
    if (this.personaleContratti.length == 0) {
      this.showEmptyMessage = true;
    }
    this.personaleContrattiDef = this.personaleContratti;
    this.rotella.closeDialog();


  }

}

