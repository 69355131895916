import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class AdminOrganizzazioneDashboardService {

  constructor(private http: HttpClient) {}

  public getOrganizzazioneUtente(email): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'anagrafiche-utenti/get-user-organization?email='+email);
  }

}