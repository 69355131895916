import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class RendicontazioneProgettoService {

  constructor(private http: HttpClient) {
  }

  public getOrganizzazione(nome): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'organizzazioni/get-by-name?name=' + nome);
  }

  public getProgetti(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetti/get-by-organizzazione-id?organizzazioneId=' + idOrganizzazione);
  }

  public getProgettiRendicontabili(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'rendicontazioni/get-progetti-rendicontabili?idOrganizzazione=' + idOrganizzazione);
  }

  public getStakeholders(idProgetto, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + '/progetto-stakeholders/get-stakeholders?idProgetto=' + idProgetto + '&idOrganizzazione=' + idOrganizzazione);
  }

  public getSedi(idStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'sedi-stakeholders/get-all-by-stakeholder-id?idStakeholder=' + idStakeholder);
  }

  public getVociDiCosto(idBando, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'voci-di-costo/bando-voce-di-costo/get-all-by-bando-id-and-organizzazione-id?bandoId=' + idBando + '&organizzazioneId=' + idOrganizzazione);
  }

  public getVociDiCostoRendicontabili(idProgetto, idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'rendicontazioni/get-voci-di-costo-rendicontabili?idProgetto=' + idProgetto + '&idSedeStakeholder=' + idSedeStakeholder);
  }

  public getSal(idProgetto): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'piani-di-sal/piano-dei-sal/get-latest-version-lista-sal-by-progetto-id?progettoId=' + idProgetto);
  }

  public getRendicontazione(idSal, idSedeStakeholder, tipoVersione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'rendicontazioni/get-wrapper?idSal=' + idSal + '&idSedeStakeholder=' + idSedeStakeholder + '&tipoVersione=' + tipoVersione);
  }

  public getStatoSal(idSal, idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'rendicontazioni/get-stato-sal?idSal=' + idSal + '&idSedeStakeholder=' + idSedeStakeholder);
  }

  public getAttivita(idProgetto, idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbs-master/get-latest-wbs-version-attivita-for-pianificazione?idProgetto=' + idProgetto + '&idSedeStakeholder=' + idSedeStakeholder);
  }

  public getOrWP(idProgetto, idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'wbs-master/get-latest-wbs-version-wp-or-for-pianificazione?idProgetto=' + idProgetto + '&idSedeStakeholder=' + idSedeStakeholder);
  }

  public getPersone(idSedeStakeholder, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'persone/get-all-by-sede-stakeholder-id-and-organizzazione-id?sedeStakeholderId=' + idSedeStakeholder + '&organizzazioneId=' + idOrganizzazione);
  }

  public getPersoneNonFittizione(idSedeStakeholder, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'persone/get-all-by-sede-stakeholder-id-and-organizzazione-id_rendicontazione?sedeStakeholderId=' + idSedeStakeholder + '&organizzazioneId=' + idOrganizzazione);
  }


  public getContratti(idProgetto, idPersona, idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'contratti-di-collaborazione/get-all-wrappers-by-progetto-id-and-persona-id-and-sede-stakeholder-id?idProgetto=' + idProgetto + '&idPersona=' + idPersona + '&idSedeStakeholder=' + idSedeStakeholder);
  }

  public getCostiOrari(idProgetto, idPersona, idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'costi-orari/get-all-by-progetto-id-and-persona-id-and-sede-stakeholder-id?idProgetto=' + idProgetto + '&idPersona=' + idPersona + '&idSedeStakeholder=' + idSedeStakeholder);
  }

  public getContrattiPianificati(idProgetto, idPersona, idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'contratti-di-collaborazione/get-all-wrappers-by-contratti-pianificati?idProgetto=' + idProgetto + '&idPersona=' + idPersona + '&idSedeStakeholder=' + idSedeStakeholder);
  }

  public getCostiOrariPianificati(idProgetto, idPersona, idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'costi-orari/get-all-by-costi-orari-pianificati?idProgetto=' + idProgetto + '&idPersona=' + idPersona + '&idSedeStakeholder=' + idSedeStakeholder);
  }

  public getBustePaga(idPersona): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'buste-paga/get-all-by-persona-id?idPersona=' + idPersona);
  }

  public getMensilePianificato(idProgetto, idPersona, idSedeStakeholder, anno, mese, idRecordWbs): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'rendicontazioni/get-rendicontazione-mensile-from-pianificazione?idProgetto=' + idProgetto + '&idPersona=' + idPersona + '&idSedeStakeholder=' + idSedeStakeholder + '&anno=' + anno + '&mese=' + mese + '&idRecordWbs=' + idRecordWbs);
  }

  public saveRendicontazione(wrapper): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'rendicontazioni/save-wrapper', wrapper);
  }

  public allineaPianificazione(idProgetto, idSedeStakeholder): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'rendicontazioni/allinea-pianificazione?idProgetto=' + idProgetto + '&idSedeStakeholder=' + idSedeStakeholder, null);
  }


  public saldatepartner(idSal, idsStackeHolder): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'sal-date-partner/get-by-sal-stackeholder?salId=' + idSal + '&stackeHolderId=' + idsStackeHolder, null);
  }


}
