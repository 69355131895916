import { EventEmitter, Injectable } from '@angular/core';  
import { Event, NavigationEnd, Router } from '@angular/router';  
import { MatDialog, MatDialogRef } from '@angular/material';
import { LoadingDialogComponent } from './loading-dialog.component';
  
@Injectable()  
export class LoadingDialogService {  
  
    constructor(private router: Router, private dialog: MatDialog) {  
  
    }  
  
    start(message?): MatDialogRef<LoadingDialogComponent> {  
        
        const dialogRef = this.dialog.open(LoadingDialogComponent,{  
            disableClose: true ,  
            data: message == ''|| message == undefined ? "Loading..." : message  
        });  
        return dialogRef;  
    };  
  
    stop(ref:MatDialogRef<LoadingDialogComponent>){  
        ref.close();  
    }
        
}  