import {Component, Inject, Injectable, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {ProgettiElement} from "../gestione-progetto/gestione-progetto.component";
import {
  DateAdapter,
  MAT_DIALOG_DATA,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialog,
  MatTableDataSource
} from "@angular/material";
import {WarningMessageComponent} from "../modals/warning-message/warning-message.component";
import {BehaviorSubject} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {GestioneProgettoService} from "../gestione-progetto/gestione-progetto.service";
import {AuthService} from "../core_modules/auth.service";
import {OrganizzazioniService} from "../organizzazioni/organizzazioni.service";
import {UserStorage} from "../core_modules/user.storage";
import {RotellinaService} from "../rotellina-dialog/rotellina.service";
import {SmartAllocationDialog} from "./smart-allocation-dialog";
import {GestioneStakeholdersService} from "../gestione-stakeholders/gestione-stakeholders.service";
import {GestionePartnerProgettoService} from "../gestione-partner-progetto/gestione-partner-progetto.service";
import {ConfirmMessageComponent} from "../modals/confirm-message/confirm-message.component";
import {AppSettings} from "../app.settings";

@Injectable()
export class TableGestioneProgettiDatabase {

  dataChange = new BehaviorSubject<ProgettiElement[]>([]);

  get data(): ProgettiElement[] {
    return this.dataChange.value;
  }

  // Classe di servizio del component owner che implementa i vari servizi verso il back-end invocati dal TableProjectDatabase
  // come 'saveAttivitasInPraticas', 'deleteAttivitaFromPratica', 'getPraticheTreeByOrganization', etc.
  private ownerComponentService: any;
  // La dialog dell'owner component
  private ownerComponentDialog: MatDialog;
  // ########## END   ELEMENTI DEL COMPONENT OWNER ##########

  //L'oggetto JSON, estratto con apposito querying del database, che contiene i progetti.
  jsonProjectTableStructure: any;

  projectTableNodesArray: ProgettiElement[];

  public setOwnerComponentService(x: any) {
    this.ownerComponentService = x;
  }

  public setOwnerComponentDialog(y: MatDialog) {
    this.ownerComponentDialog = y;
  }

  constructor(private adapter: DateAdapter<any>,
              @Inject(LOCALE_ID) private locale: string,) {
    this.adapter.setLocale("it");
  }

  bandiProgetto = [];

  getBandiProgetto() {
    return this.bandiProgetto;
  }

  reloadProjectGestoriDatabaseStructure(idorganizzazione, _callback) {
    this.ownerComponentService.getProgettiFromRendicontaDB(idorganizzazione).subscribe(
      dataDb => {
        this.jsonProjectTableStructure = dataDb;
        console.log(JSON.stringify(dataDb));

        for (var x = 0; x < dataDb.length; x++) {
          this.bandiProgetto.push({
              idProgetto: dataDb[x].id,
              bando: dataDb[x].bando
            }
          );
        }

        this.projectTableNodesArray = this.buildProjectNodes(this.jsonProjectTableStructure);

        const data = this.projectTableNodesArray;
        this.dataChange.next(data);

        _callback();
      },
      err => {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare i Progetti!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    )
  }


  // Build the nodes from Json object. The result is a list of `TableProjectNode`
  buildProjectNodes(projectTableNodeData: any[]): ProgettiElement[] {
    let nodeArray = new Array<ProgettiElement>();
    if (projectTableNodeData != null) {
      try {
        projectTableNodeData.forEach(project => {
          const node = new ProgettiElement();

          node.nomeProgetto = project.nome;
          node.id = project.id;

          nodeArray.push(node);
        });
      } catch (error) {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare informazioni della tabella!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    }
    return nodeArray;
  }
}

@Component({
  selector: 'smart-allocation',
  templateUrl: './smart-allocation.component.html',
  styleUrls: ['./smart-allocation.component.css'],
  providers: [TableGestioneProgettiDatabase, {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}]
})
export class SmartAllocationComponent implements OnInit {

  multi: any[];
  view: any[] = [700, 500];

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Persone';
  yAxisLabel: string = 'Attività';
  colorScheme = {
    domain: ['#e70707', '#e2c806', '#e0d190', '#32e425'/*, '#7aa3e5', '#a8385d', '#aae3f5'*/]
  };

  displayedColumns: string[] = ['name', 'allocazione', 'elaborazione', 'log-elaborazione'];
  projectTableDataSource: MatTableDataSource<ProgettiElement>;

  @ViewChild(MatPaginator) paginator: MatPaginator;// Numero elementi da visualizzare

  constructor(private gestioneProgettoService: GestioneProgettoService,
              private authService: AuthService,
              private organizzazioniService: OrganizzazioniService,
              public dialog: MatDialog, private userStorage: UserStorage,
              public gestioneStakeholdersService: GestioneStakeholdersService,
              public gestionePartnerProgettoService: GestionePartnerProgettoService,
              private rotella: RotellinaService,
              public confirmDialog: MatDialog,
              private projectTableContentDatabase: TableGestioneProgettiDatabase) {
    this.projectTableDataSource = new MatTableDataSource<ProgettiElement>();
    projectTableContentDatabase.dataChange.subscribe(data => this.projectTableDataSource.data = data);
  }

  organizzazioneObj = undefined;

  ngOnInit() {
    //this.multi = [];
    this.authService.checkLoggedUser(() => {
      this.projectTableDataSource.paginator = this.paginator;
      this.rotella.openDialog();

      this.loadOrganizzazioneAndOtherData();
    });
  }

  onSelect(data): void {
    alert();
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    alert();
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    alert();
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  loadOrganizzazioneAndOtherData() {
    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        this.organizzazioneObj = data;
        console.log('obj: ' + this.organizzazioneObj.id);


        this.projectTableContentDatabase.setOwnerComponentService(this.gestioneProgettoService);
        this.projectTableContentDatabase.setOwnerComponentDialog(this.dialog);

        this.projectTableContentDatabase.reloadProjectGestoriDatabaseStructure(this.organizzazioneObj.id, () => {
          //this.initFlags();
          this.rotella.closeDialog();
        });
      },
      err => {
        this.rotella.closeDialog()
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  openDialog(element) {
    this.dialog.open(SmartAllocationDialog, {
      data: {
        id: element.id,
        descr: element.nomeProgetto
      },
    });
  }

  calcolaAllocazioni(element) {
    this.askConfirm("Vuoi procedere con il calcolo delle allocazioni del personale ?", "Il calcolo procederà in background e in seguito potrai visualizzarle.", () => {
      this.gestioneStakeholdersService.putAllocazionePersonale(element.id).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    });
  }

  askConfirm(message: string, submessage: string, _callback): void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent, {
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res) _callback();
      }
    );
  }


  onDownload(element) {
    const downloadURL_server = AppSettings.BASE_URL + 'api/allocazione-personale/get-log-elaborazione/' + element.id;
    fetch(downloadURL_server)
      .then(response => {
        return response.blob()
      })
      .then((myBlob) => {
        let link = document.createElement("a");
        link.href = URL.createObjectURL(myBlob);
        link.setAttribute('download', "log_elaborazione_progetto_" + element.id + ".zip");
        link.click();
      })
  }
}
