import {Component, Inject, Injectable, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {
  DateAdapter,
  MAT_DIALOG_DATA,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialog, MatDialogRef,
  MatTableDataSource
} from "@angular/material";
import {MatPaginator} from "@angular/material/paginator";
import {BehaviorSubject} from "rxjs";
import {WarningMessageComponent} from "../../../modals/warning-message/warning-message.component";
import {GestioneWbsService} from "../../gestione-wbs.service";
import {$t} from "codelyzer/angular/styles/chars";
import {AuthService} from "../../../core_modules/auth.service";
import {ProgettiElement} from "../../../gestione-progetto/gestione-progetto.component";
import {StoricoPianoCostiComponent} from "../../../gestione-piani-dei-costi/storico-piano-costi/storico-piano-costi.component";
import {DettglioStoricoWbsComponent} from "./dettglio-storico-wbs/dettglio-storico-wbs.component";


export class WBSElement {
  id: String;
  versione: string;
  data_creazione: string;
  data_inizio_validita: string;
  descrizione: string;
  chiusa: string;
}


@Injectable()
export class TableGestioneStoricoWBSDatabase {

  dataChange = new BehaviorSubject<WBSElement[]>([]);
  private _progetto;

  get data(): WBSElement[] {
    return this.dataChange.value;
  }

  // Classe di servizio del component owner che implementa i vari servizi verso il back-end invocati dal TableProjectDatabase
  // come 'saveAttivitasInPraticas', 'deleteAttivitaFromPratica', 'getPraticheTreeByOrganization', etc.
  private ownerComponentService: any;
  // La dialog dell'owner component
  private ownerComponentDialog: MatDialog;
  // ########## END   ELEMENTI DEL COMPONENT OWNER ##########

  //L'oggetto JSON, estratto con apposito querying del database, che contiene i progetti.
  jsonProjectTableStructure: any;

  projectTableNodesArray: WBSElement[];

  public setOwnerComponentService(x: any) {
    this.ownerComponentService = x;
  }

  public setOwnerComponentDialog(y: MatDialog) {
    this.ownerComponentDialog = y;
  }


  getProgetto() {
    return this._progetto;
  }

  setProgetto(value) {
    this._progetto = value;
  }

  constructor(private adapter: DateAdapter<any>,
              @Inject(LOCALE_ID) private locale: string,) {
    this.adapter.setLocale("it");
  }

  reloadProjectGestoriDatabaseStructure(_callback) {
    this.ownerComponentService.getStoricoWbs(this._progetto).subscribe(
      dataDb => {
        console.log("datadb " + JSON.stringify(dataDb));
        this.jsonProjectTableStructure = dataDb;
        this.projectTableNodesArray = this.buildProjectNodes(this.jsonProjectTableStructure);
        const data = this.projectTableNodesArray;
        this.dataChange.next(data);
      }
    )
  }


  // Build the nodes from Json object. The result is a list of `TableProjectNode`
  buildProjectNodes(projectTableNodeData: any[]): WBSElement[] {
    let nodeArray = new Array<WBSElement>();
    if (projectTableNodeData != null) {
      try {
        projectTableNodeData.forEach(wbs => {
          const node = new WBSElement();
          node.descrizione = wbs.descrizione;
          node.id = wbs.id;
          node.versione = wbs.versione;
          node.data_creazione = wbs.timestampCreazione;
          node.data_inizio_validita = wbs.dataInizioValidita;
          node.chiusa = wbs.chiusa ? 'SI' : 'NO';
          nodeArray.push(node);
        });
      } catch (error) {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare informazioni della tabella!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    }
    return nodeArray;
  }
}


@Component({
  selector: 'app-storico-wbs',
  templateUrl: './storico-wbs.component.html',
  styleUrls: ['./storico-wbs.component.css'],
  providers: [TableGestioneStoricoWBSDatabase, {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}],
})
export class StoricoWbsComponent implements OnInit {


  displayedColumns: string[] = ['descrizione', 'versione', 'data_creazione', 'data_inizio_validita', /*'chiusa',*/ 'azioni'];
  projectTableDataSource: MatTableDataSource<WBSElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private progetto;

  constructor(
    @Inject(MAT_DIALOG_DATA) public params: any,
    private thisDialogRef: MatDialogRef<StoricoWbsComponent>,
    private projectTableContentDatabase: TableGestioneStoricoWBSDatabase,
    private adapter: DateAdapter<any>,
    private gestioneWbsService: GestioneWbsService,
    private authService: AuthService,
    public dialog: MatDialog,
  ) {
    this.progetto = params.progetto.id;
    this.projectTableDataSource = new MatTableDataSource<WBSElement>();
    projectTableContentDatabase.dataChange.subscribe(data => this.projectTableDataSource.data = data);
  }

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      // this.color=colors[Math.floor(Math.random() * colors.length)];
      this.projectTableDataSource.paginator = this.paginator;
      //this.rotella.openDialog();

      this.loadStoricoWBS(this.progetto);
    });
  }

  loadStoricoWBS(progetto) {
    this.projectTableContentDatabase.setOwnerComponentService(this.gestioneWbsService);
    this.projectTableContentDatabase.setOwnerComponentDialog(this.dialog);
    this.projectTableContentDatabase.setProgetto(progetto);
    this.projectTableContentDatabase.reloadProjectGestoriDatabaseStructure(() => {
    });

  }

  onEditWBS(wbsId){
    const ganttDialogRef = this.dialog.open(DettglioStoricoWbsComponent, {
      height: '80%',
      width: '80%',
      data: {progetto: this.progetto, wbs: wbsId}
    });
  }

  onAttivaWBS(wbsId) {
    const ganttDialogRef = this.dialog.open(StoricoPianoCostiComponent, {
      height: '80%',
      width: '80%',
      data: {progetto: this.progetto, wbs: wbsId}
    });

    ganttDialogRef.afterClosed().subscribe(result => {
     // console("close from wbs");
    });
  }

  close() {
    this.thisDialogRef.close();
  }
}
