import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {DialogData} from 'src/app/gestione-wbs/modals/aggiunta-record-wbs-dialog/aggiunta-record-wbs-dialog.component';
import {SelectionModel} from "@angular/cdk/collections";
import {GestionePartnerProgettoService} from "../../../gestione-partner-progetto/gestione-partner-progetto.service";
import {GestioneReferentiProgettoService} from "../../gestione-referenti-progetto.service";


export class Ruolo {
  id: number;
  nome: String;
}

export class ReferenetProgetttoWrapper {
  idProgetto: String;
  idReferente: String;
  idRuolo: String;
}

@Component({
  selector: 'app-aggiunta-nuovo-referente-modal',
  templateUrl: './aggiunta-nuovo-referente-modal.component.html',
  styleUrls: ['./aggiunta-nuovo-referente-modal.component.css']
})
export class AggiuntaNuovoReferenteModalComponent implements OnInit {


  public title: String;
  public progetto: String;

  ruoli: Ruolo[] = [];


  initTitle(): void {
    this.progetto = sessionStorage.getItem("definizione_partner_progetto");
    sessionStorage.setItem("definizione_partner_progetto", "");
  }

  constructor(
    public dialogRef: MatDialogRef<AggiuntaNuovoReferenteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private gestioneReferentiProgettoService: GestioneReferentiProgettoService,
    private snackBar: MatSnackBar,
  ) {
  }

  onSelectedElement(element, ruolo): void {
    // alert("select");
    // alert("persona " + JSON.stringify(element.id) + ", progetto " + this.progetto + ", ruolo " + ruolo);
    var referenetProgetttoWrapper = new ReferenetProgetttoWrapper();
    referenetProgetttoWrapper.idProgetto = this.progetto;
    referenetProgetttoWrapper.idReferente = element.id;
    referenetProgetttoWrapper.idRuolo = ruolo;
    this.gestioneReferentiProgettoService.addReferente(referenetProgetttoWrapper).subscribe(
      data => {
        this.snackBar.open("Referente Aggiunto", 'chiudi');
        this.dialogRef.close();
      },
      err => {
      }
    );
  }

  ngOnInit() {
    this.initTitle();
    const ruolo = new Ruolo();
    ruolo.id = 1;
    ruolo.nome = 'r1';
    this.ruoli.push(ruolo);
    const ruolo1 = new Ruolo();
    ruolo1.id = 2;
    ruolo1.nome = 'r2';
    this.ruoli.push(ruolo1);
  }

}
