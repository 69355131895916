import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoadingDialogService } from 'src/app/modals/loading-dialog/loading-dialog.service';
import { PianificazioneOrePerAttivitaModalService } from './pianificazione-ore-per-attivita-modal.service';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import { RotellinaService } from 'src/app/rotellina-dialog/rotellina.service';

export interface TableElement {
  recordPrimoLivello: any;
  recordSecondoLivello: any;
  titolo: String;
  tipo: any;
  nomeTipo: String;
  progetto: any;
  contatoriMese: Number[];
  totale: Number;
  isTotale: Boolean;
  isProgettoAttuale: Boolean;
  isAltroProgetto: Boolean;
}

@Component({
  selector: 'app-pianificazione-ore-per-attivita-modal',
  templateUrl: './pianificazione-ore-per-attivita-modal.component.html',
  styleUrls: ['./pianificazione-ore-per-attivita-modal.component.css']
})
export class PianificazioneOrePerAttivitaModalComponent implements OnInit {

  displayedColumns = [];

  tableDataSource: TableElement[] = [];

  attivitaProgetto = [];

  isComponentCalledFromRendicontazione: Boolean = this.data.dataSource.length == 0;

  loading: boolean = true;

  contrattiWrappers = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loadingDialogService: LoadingDialogService,
    public dialog: MatDialog,
    public rendicontazioneOrePerAttivitaDialog: MatDialog,
    private thisDialogRef: MatDialogRef<PianificazioneOrePerAttivitaModalComponent>,
    private pianificazioneOrePerAttivitaModalService: PianificazioneOrePerAttivitaModalService,
    private rotella: RotellinaService

  ) { }

  isInputFieldReadOnly(element: TableElement) : Boolean {
    if(element.isAltroProgetto || element.isProgettoAttuale || element.isTotale) {
      return true;
    }
    return false;
  }

  getIndicatoreMeseIndex(indicatoreMese) : Number {
    return this.data.indicatoriMese.indexOf(indicatoreMese);
  }

  initColonne() : void {
    this.displayedColumns.push('titolo');
    this.displayedColumns.push('tipo');
    this.data.indicatoriMese.forEach(im => {this.displayedColumns.push(im)});
    this.displayedColumns.push('ore_totali');
  }

  getNewMesiArray() : Number[] {
    return new Array(this.data.indicatoriMese.length).fill(0);
  }

  getProgettoAttualeTableElement() : TableElement {
    return this.tableDataSource.find(found => found.isProgettoAttuale === true);
  }

  getIndicatoriGiorni(mese, anno) : String[] {
    var list = [];
    var bisestile = anno%400==0 || (anno%4==0 && !(anno%100==0));
    var numGiorni = (mese === 2) ? (bisestile) ? 29 : 28 : (mese === 4 || mese === 6 || mese === 9 || mese === 11) ? 30 : 31;
    for(var i=1; i<=numGiorni; i++) {
      list.push(i.toString());
    }
    return list;
  }

  /**
   * Restituisce il nome del mese dato il numero (da 1 a 12, compresi).
   * @param numero Numero del mese.
   */
  getNomeMeseFromNumero(numero: number) : string {
    const mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto',
                  'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    if(numero < 1 || numero > 12) return 'Nessun mese';
    return mesi[numero - 1];
  }

  getPeriodoString() : string {
    const inizio = this.data.indicatoriMese[0];
    const fine = this.data.indicatoriMese[this.data.indicatoriMese.length - 1];
    if(inizio.indexOf('-') < 0 || fine.indexOf('-') < 0) return "Nessun periodo";
    var a = this.getNomeMeseFromNumero(+inizio.substring(0, inizio.indexOf('-'))) + " " + inizio.substring(inizio.indexOf('-') + 1, inizio.length);
    var b = this.getNomeMeseFromNumero(+fine.substring(0, fine.indexOf('-'))) + " " + fine.substring(fine.indexOf('-') + 1, fine.length);
    return "Da " + a + " a " + b;
  }

  private getContrattoString(contratto) : string {
    var res = "";
    const dataInizio = new Date(contratto.dataInizio);
    const dataFine = new Date(contratto.dataFine);
    res += dataInizio.getDate() + "/" + (dataInizio.getMonth() + 1) + "/" + dataInizio.getFullYear();
    res += " - ";
    res += dataFine.getDate() + "/" + (dataFine.getMonth() + 1) + "/" + dataFine.getFullYear();
    return res;
  }

  private isAttivitaDaContratto(element: TableElement) : boolean {
    if(this.contrattiWrappers == undefined || this.contrattiWrappers.length === 0) return true;
    var flag = false;
    this.contrattiWrappers.forEach(cw => {
      var ref = cw.attivita.find(function(found) {
        return (found.recordWbsPrimoLivello != undefined && found.recordWbsPrimoLivello.id === element.recordPrimoLivello.id) ||
          (found.recordWbsSecondoLivello != undefined && found.recordWbsSecondoLivello.id === element.recordSecondoLivello.id)
      });
      if(ref != undefined) { flag = true; return; }
    });
    return flag;
  }

  refreshTotaleAttivita(element: TableElement) : void {
    var tot = 0;
    element.contatoriMese.forEach(cm => {tot += +cm});
    element.totale = +((+tot).toFixed(2));
  }

  refreshTotaleProgettoAttuale() : void {
    var elementRef: TableElement = this.getProgettoAttualeTableElement();
    var tmpConts = new Array(this.data.indicatoriMese.length).fill(0);
    var tot = 0;

    this.tableDataSource.filter(function(filtered) {return filtered.isTotale === false && filtered.isAltroProgetto === false && filtered.isProgettoAttuale === false})
    .forEach(e => {
      e.contatoriMese.forEach((cm, i) => {tmpConts[i] += +cm});
      tot += +e.totale;
    });

    elementRef.contatoriMese = tmpConts;
    elementRef.totale = tot;
    elementRef.totale = +((+tot).toFixed(2));
  }

  loadAttivitaInWbs(idProgetto, idSedeStakeholder, _callback) : void {
    this.pianificazioneOrePerAttivitaModalService.getAttivita(idProgetto, idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  loadOrWpInWbs(idProgetto, idSedeStakeholder, _callback) : void {
    this.pianificazioneOrePerAttivitaModalService.getOrWP(idProgetto, idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica i wrappers dei contratti di collaborazione dal database. Se non disponibili, propaga UNDEFINED.
   * @param idPianificazioneProgettoPersona Id della pianificazione progetto-persona di riferimento.
   * @param _callback Azione successiva.
   */
  loadContrattiWrappers(idPianificazioneProgettoPersona, _callback) : void {
    this.pianificazioneOrePerAttivitaModalService.getContrattiWrappers(idPianificazioneProgettoPersona).subscribe(
      data => { _callback(data); },
      err => { _callback(undefined); }
    );
  }

  completeInit() : void {
    const mesiArray = this.getNewMesiArray();
    this.tableDataSource.push({
      recordPrimoLivello: undefined,
      recordSecondoLivello: undefined,
      titolo: this.data.element.nomeElemento,
      tipo: undefined,
      nomeTipo: "",
      progetto: undefined,
      contatoriMese: mesiArray.concat([]),
      totale: 0,
      isTotale: false,
      isProgettoAttuale: true,
      isAltroProgetto: false
    });
    for(var i in this.data.dataSource) {
      if(this.data.dataSource[i].isProgetto && this.data.dataSource[i].nomeElemento==this.data.element.nomeElemento) {
        for(var j in this.tableDataSource) {
          if(this.tableDataSource[j].isTotale) {
            this.tableDataSource[j].contatoriMese = this.data.dataSource[i].contatoriMese.concat([]);
            this.tableDataSource[j].totale = +((+this.data.dataSource[i].totalePeriodo).toFixed(2));
          }
        }
      }
      if(!this.data.dataSource[i].isTotale && this.data.dataSource[i].nomeElemento!=this.data.element.nomeElemento && !this.data.dataSource[i].isPresenze) {
        this.tableDataSource.push({
          recordPrimoLivello: undefined,
          recordSecondoLivello: undefined,
          titolo: this.data.dataSource[i].nomeElemento,
          tipo: undefined,
          nomeTipo: "",
          progetto: undefined,
          contatoriMese: mesiArray.concat([]),
          totale: 0,
          isTotale: false,
          isProgettoAttuale: false,
          isAltroProgetto: true
        });
      }
    }
    for(var k in this.tableDataSource) {
      if(this.tableDataSource[k].isAltroProgetto) {
        inner: for(var q in this.data.dataSource) {
          if(this.data.dataSource[q].nomeElemento.toUpperCase() == this.tableDataSource[k].titolo.toUpperCase()) {
            this.tableDataSource[k].contatoriMese = this.data.dataSource[q].contatoriMese.concat([]);
            this.tableDataSource[k].totale = +((+this.data.dataSource[q].totalePeriodo).toFixed(2));
            break inner;
          }
        }
      }
    }
  }

  initTable(_callback) : void {
    const mesiArray = this.getNewMesiArray();
    if(this.isComponentCalledFromRendicontazione === false) {
      this.tableDataSource.push({
        recordPrimoLivello: undefined,
        recordSecondoLivello: undefined,
        titolo: "TOTALE PIANIFICATE",
        tipo: undefined,
        nomeTipo: "",
        progetto: undefined,
        contatoriMese: mesiArray.concat([]),
        totale: 0,
        isTotale: true,
        isProgettoAttuale: false,
        isAltroProgetto: false
      });
    }
    if(this.data.element.progettoObject.bando.confLivelloCostiInWbs === true) {
      this.loadAttivitaInWbs(this.data.element.progettoObject.id, this.data.parentData.filters[1].id, (attivita) => {
        attivita.forEach(a => {
          this.tableDataSource.push({
            recordPrimoLivello: undefined,
            recordSecondoLivello: a,
            titolo: a.nome,
            tipo: a.tipoAttivitaCofinanziabile,
            nomeTipo: a.tipoAttivitaCofinanziabile != undefined ? a.tipoAttivitaCofinanziabile.nome : "",
            progetto: this.data.element.progettoObject,
            contatoriMese: mesiArray.concat([]),
            totale: 0,
            isTotale: false,
            isProgettoAttuale: false,
            isAltroProgetto: false
          });
        });
        this.completeInit();
        this.tableDataSource = this.tableDataSource.concat([]);
        _callback();
      });
    }
    else {
      this.loadOrWpInWbs(this.data.element.progettoObject.id, this.data.parentData.filters[1].id, (orWps) => {
        orWps.forEach(o => {
          this.tableDataSource.push({
            recordPrimoLivello: o,
            recordSecondoLivello: undefined,
            titolo: o.nome,
            tipo: undefined,
            nomeTipo: o.tipoAttivitaCofinanziabile != undefined ? o.tipoAttivitaCofinanziabile.nome : "",
            progetto: this.data.element.progettoObject,
            contatoriMese: mesiArray.concat([]),
            totale: 0,
            isTotale: false,
            isProgettoAttuale: false,
            isAltroProgetto: false
          });
        });
        this.completeInit();
        this.tableDataSource = this.tableDataSource.concat([]);
        _callback();
      });
    }
  }

  isTotalePianificazioneRispettato() : Boolean {
    var piano: TableElement = undefined;
    var prog: TableElement = undefined;
    for(var i in this.tableDataSource) {
      if(this.tableDataSource[i].isProgettoAttuale) {
        prog = this.tableDataSource[i];
      }
      else if(this.tableDataSource[i].isTotale) {
        piano = this.tableDataSource[i];
      }
      if(piano && prog) {
        break;
      }
    }
    if(piano == undefined || prog == undefined) {
      return false;
    }
    for(var j in piano.contatoriMese) {
      if(piano.contatoriMese[j] != prog.contatoriMese[j]) {
        return false;
      }
    }
    return true;
  }

  onChangeContatore(element: TableElement) : void {
    this.refreshTotaleAttivita(element);
    this.refreshTotaleProgettoAttuale();
  }

  onDettaglioGiornaliero(indicatoreMese) : void {
    if(this.isComponentCalledFromRendicontazione === true) {
      const indicatoriGiorniList = this.getIndicatoriGiorni(+indicatoreMese.substr(0,indicatoreMese.indexOf('-')),
                                                            +indicatoreMese.substr(indicatoreMese.indexOf('-')+1,indicatoreMese.length));
      const rendicontazioneOrePerAttivitaDialogRef = this.rendicontazioneOrePerAttivitaDialog.open(PianificazioneOrePerAttivitaModalComponent, {
        data : {
                element: {
                  nomeElemento: this.data.element.nomeElemento,
                  contatoriMese: new Array(indicatoriGiorniList.length).fill(0),
                  totalePeriodo: 0,
                  totaleProgetto: 0,
                  isTotale: false,
                  isPresenze: false,
                  isProgetto: true
                },
                parentData: this.data.parentData,
                indicatoriMese: indicatoriGiorniList,
                dataSource: []
              }
      });

      rendicontazioneOrePerAttivitaDialogRef.afterClosed().subscribe(
        result => {
          if(result!=undefined) {
          }
        },
        err => {
        }
      );
    }
  }

  isSavingPossible() : Boolean {
    if(this.isComponentCalledFromRendicontazione === false)
      return this.isTotalePianificazioneRispettato();
    else return false;
  }

  savePianificazioneRecordWbs(element, _callback) : void {
    this.pianificazioneOrePerAttivitaModalService.savePianificazioneOrePerAttivita(element).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  updatePianificazioneRecordWbs(element, _callback) : void {
    this.pianificazioneOrePerAttivitaModalService.updatePianificazioneOrePerAttivita(element).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  saveOrWpElement(element: TableElement, _callback) : void {
    const data = this.data;
    var wrapper = this.data.wrapper.find(function(found) {
      return found.pianificazioneProgettoPersona.progetto.id === data.element.progettoObject.id &&
      found.pianificazioneProgettoPersona.persona.id === data.parentData.personaObject.id
    });
    if(wrapper == undefined) _callback();
    wrapper = wrapper.pianificazioniMensili;
    element.contatoriMese.forEach((cm, i) => {
      const indicatoreMese = this.data.indicatoriMese[i];
      const ref = wrapper.find(found => found.pianificazioneProgettoPersonaPerMese.mese+"-"+found.pianificazioneProgettoPersonaPerMese.anno === indicatoreMese);
      if(ref != undefined) {
        const attRef = ref.pianificazioniProgettoPersonaPerAttivita.find(function(attFound) {return attFound.recordWbsPrimoLivello && attFound.recordWbsPrimoLivello.id === element.recordPrimoLivello.id});
        if(attRef != undefined) {
          /* Update. */
          attRef.ore = cm;
          this.updatePianificazioneRecordWbs(attRef, () => {_callback();});
        }
        else {
          /* Save. */
          this.savePianificazioneRecordWbs({
            ore: cm,
            pianificazioneProgettoPersonaPerMese: ref.pianificazioneProgettoPersonaPerMese,
            recordWbsPrimoLivello: element.recordPrimoLivello
          }, () => {_callback();});
        }
      }
    });
  }

  saveAttivitaElement(element: TableElement, _callback) : void {
    const data = this.data;
    var wrapper = this.data.wrapper.find(function(found) {
      return found.pianificazioneProgettoPersona.progetto.id === data.element.progettoObject.id &&
      found.pianificazioneProgettoPersona.persona.id === data.parentData.personaObject.id
    });
    if(wrapper == undefined) _callback();
    wrapper = wrapper.pianificazioniMensili;
    element.contatoriMese.forEach((cm, i) => {
      const indicatoreMese = this.data.indicatoriMese[i];
      const ref = wrapper.find(found => found.pianificazioneProgettoPersonaPerMese.mese+"-"+found.pianificazioneProgettoPersonaPerMese.anno === indicatoreMese);
      if(ref != undefined) {
        const attRef = ref.pianificazioniProgettoPersonaPerAttivita.find(function(attFound) {return attFound.recordWbsSecondoLivello && attFound.recordWbsSecondoLivello.id === element.recordSecondoLivello.id});
        if(attRef != undefined) {
          /* Update. */
          attRef.ore = cm;
          this.updatePianificazioneRecordWbs(attRef, () => {_callback();});
        }
        else {
          /* Save. */
          this.savePianificazioneRecordWbs({
            ore: cm,
            pianificazioneProgettoPersonaPerMese: ref.pianificazioneProgettoPersonaPerMese,
            recordWbsSecondoLivello: element.recordSecondoLivello
          }, () => {_callback();});
        }
      }
    });
  }

  saveElement(element: TableElement, _callback) : void {
    if(element.recordPrimoLivello != undefined) this.saveOrWpElement(element, () => {_callback();});
    else this.saveAttivitaElement(element, () => {_callback();});
  }

  onSaveElements(event) : void {
    if(this.isComponentCalledFromRendicontazione === false) {
      const elements = this.tableDataSource.filter(function(filtered) {return filtered.recordPrimoLivello != undefined || filtered.recordSecondoLivello != undefined});
      if(elements.length !== 0) this.rotella.openDialog();
      elements.forEach(e => {
        this.saveElement(e, () => {
          if(elements.indexOf(e) === elements.length - 1) {
            this.rotella.closeDialog();
            this.thisDialogRef.close(true);
          }
        })
      });
    }
  }

  initDataFromWrapper(_callback) : void {
    const data = this.data;
    const wrapper = this.data.wrapper.find(function(found) {
      return found.pianificazioneProgettoPersona.progetto.id === data.element.progettoObject.id &&
      found.pianificazioneProgettoPersona.persona.id === data.parentData.personaObject.id
    });
    if(wrapper != undefined) {
      this.loadContrattiWrappers(wrapper.pianificazioneProgettoPersona.id, (contrattiRef) => {
        if(contrattiRef != undefined) this.contrattiWrappers = contrattiRef;
        this.contrattiWrappers.forEach(cw => { cw.dataInizio = new Date(cw.dataInizio); cw.dataFine = new Date(cw.dataFine); });
      });
      wrapper.pianificazioniMensili.forEach(pm => {
        const index = +this.getIndicatoreMeseIndex(pm.pianificazioneProgettoPersonaPerMese.mese+"-"+pm.pianificazioneProgettoPersonaPerMese.anno);
        if(index != undefined && index >= 0) {
          pm.pianificazioniProgettoPersonaPerAttivita.forEach(pa => {
            if(pa.recordWbsSecondoLivello != undefined) {
              /* Attività. */
              const attivitaTableElement: TableElement = this.tableDataSource.find(function(attivitaFound) {
                return attivitaFound.recordSecondoLivello && attivitaFound.recordSecondoLivello.id === pa.recordWbsSecondoLivello.id;
              });
              if(attivitaTableElement !=  undefined) {
                attivitaTableElement.contatoriMese[index] = pa.ore;
                this.refreshTotaleAttivita(attivitaTableElement);
                this.refreshTotaleProgettoAttuale();
              }
            }
            else {
              /* OR/WP. */
              const orWpTableElement: TableElement = this.tableDataSource.find(function(orWpFound) {
                return orWpFound.recordPrimoLivello && orWpFound.recordPrimoLivello.id === pa.recordWbsPrimoLivello.id;
              });
              if(orWpTableElement !=  undefined) {
                orWpTableElement.contatoriMese[index] = pa.ore;
                this.refreshTotaleAttivita(orWpTableElement);
                this.refreshTotaleProgettoAttuale();
              }
            }
          });
        }
      });
    }
    _callback();
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.initColonne();
    this.initTable(() => {
      if(this.isComponentCalledFromRendicontazione === false) {
        this.initDataFromWrapper(() => {
          this.loading = false;
        });
      }
    });
  }

}
