import {Component, OnInit} from '@angular/core';
import {PartnerInScadenzaService} from "./partner-in-scadenza-service";

export class StackHolderInScadenza {
  denominazome: string;
  datascadenza: string;
}

@Component({
  selector: 'app-partner-in-scadenza',
  templateUrl: './partner-in-scadenza.component.html',
  styleUrls: ['./partner-in-scadenza.component.css']
})
export class PartnerInScadenzaComponent implements OnInit {

  inscadenza: StackHolderInScadenza[] = [];

  constructor(private partnerInScadenzaService: PartnerInScadenzaService,) {
  }

  getPartnterInScadenza(progetto_id): StackHolderInScadenza[] {
    this.inscadenza = [];
    this.partnerInScadenzaService.getAllInscadenzaByProgettoId(progetto_id).subscribe(
      data => {
        // console.log("in scadenza " + JSON.stringify(data));
        data.forEach(stck => {
          console.log("********* nome " + stck.stakeholder.denominazione + "data scadenza " + stck.dataFinePartecipazione + " *************** ");
          var obj = new StackHolderInScadenza();
          obj.denominazome = stck.stakeholder.denominazione;
          obj.datascadenza = stck.dataFinePartecipazione;
          this.inscadenza.push(obj);
        });

      },
      err => {
        //this.showError(err.error.message);
      }
    );
    return this.inscadenza;
  }


  ngOnInit() {
  }

}
