import {Component, Inject, Injectable, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {WarningMessageComponent} from "../../modals/warning-message/warning-message.component";
import {TableGestioneStoricoWBSDatabase, WBSElement} from "../../gestione-wbs/modals/storico-wbs/storico-wbs.component";
import {MatPaginator} from "@angular/material/paginator";
import {GestioneWbsService} from "../../gestione-wbs/gestione-wbs.service";
import {AuthService} from "../../core_modules/auth.service";
import {GestionePianiDeiCostiService} from "../gestione-piani-dei-costi.service";
import {
  DateAdapter,
  MAT_DIALOG_DATA,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialog, MatDialogRef,
  MatTableDataSource
} from "@angular/material";


export class PianoDeiCostiElement {
  id: String;
  versione: string;
  data_creazione: string;
  data_inizio_validita: string;
  descrizione: string;
  chiuso: string;
  rimodulato: string;
}


@Injectable()
export class TableGestioneStoricoPDADatabase {

  dataChange = new BehaviorSubject<PianoDeiCostiElement[]>([]);
  private _progetto;
  private _wbs;

  get data(): PianoDeiCostiElement[] {
    return this.dataChange.value;
  }

  // Classe di servizio del component owner che implementa i vari servizi verso il back-end invocati dal TableProjectDatabase
  // come 'saveAttivitasInPraticas', 'deleteAttivitaFromPratica', 'getPraticheTreeByOrganization', etc.
  private ownerComponentService: any;
  // La dialog dell'owner component
  private ownerComponentDialog: MatDialog;
  // ########## END   ELEMENTI DEL COMPONENT OWNER ##########

  //L'oggetto JSON, estratto con apposito querying del database, che contiene i progetti.
  jsonProjectTableStructure: any;

  projectTableNodesArray: PianoDeiCostiElement[];

  public setOwnerComponentService(x: any) {
    this.ownerComponentService = x;
  }

  public setOwnerComponentDialog(y: MatDialog) {
    this.ownerComponentDialog = y;
  }


  getProgetto() {
    return this._progetto;
  }

  setProgetto(value) {
    this._progetto = value;
  }


  getWbs() {
    return this._wbs;
  }

  setWbs(value) {
    this._wbs = value;
  }

  constructor(private adapter: DateAdapter<any>,
              @Inject(LOCALE_ID) private locale: string) {
    this.adapter.setLocale("it");
  }

  reloadProjectGestoriDatabaseStructure(_callback) {
    this.ownerComponentService.getStoricoPda(this._progetto, this._wbs).subscribe(
      dataDb => {
        console.log("datadb " + JSON.stringify(dataDb));
        this.jsonProjectTableStructure = dataDb;
        this.projectTableNodesArray = this.buildProjectNodes(this.jsonProjectTableStructure);
        const data = this.projectTableNodesArray;
        this.dataChange.next(data);
      }
    )
  }


  // Build the nodes from Json object. The result is a list of `TableProjectNode`
  buildProjectNodes(projectTableNodeData: any[]): PianoDeiCostiElement[] {
    let nodeArray = new Array<PianoDeiCostiElement>();
    if (projectTableNodeData != null) {
      try {
        projectTableNodeData.forEach(pda => {
          const node = new PianoDeiCostiElement();
          node.descrizione = pda.descrizione;
          node.id = pda.id;
          node.versione = pda.versione;
          node.data_creazione = pda.timestampCreazione;
          node.data_inizio_validita = pda.dataInizioValidita;
          node.chiuso = pda.chiuso ? 'SI' : 'NO';
          node.rimodulato = pda.rimodulato ? 'SI' : 'NO';
          nodeArray.push(node);
        });
      } catch (error) {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare informazioni della tabella!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    }
    return nodeArray;
  }
}

@Component({
  selector: 'app-storico-piano-costi',
  templateUrl: './storico-piano-costi.component.html',
  styleUrls: ['./storico-piano-costi.component.css'],
  providers: [TableGestioneStoricoPDADatabase, {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}],
})
export class StoricoPianoCostiComponent implements OnInit {


  displayedColumns: string[] = ['descrizione', 'versione', 'data_creazione', 'data_inizio_validita', 'chiusa', 'rimodulato', 'azioni'];
  projectTableDataSource: MatTableDataSource<PianoDeiCostiElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private progetto;
  private wbs;

  constructor(
    private thisDialogRef: MatDialogRef<StoricoPianoCostiComponent>,
    @Inject(MAT_DIALOG_DATA) public params: any,
    private projectTableContentDatabase: TableGestioneStoricoPDADatabase,
    private adapter: DateAdapter<any>,
    private pianiDeiCostiService: GestionePianiDeiCostiService,
    private authService: AuthService,
    public dialog: MatDialog,
  ) {
    if (params.wbs) {
      this.progetto = params.progetto;
      this.wbs = params.wbs;
    } else {
      this.progetto = params.progetto.id;
    }
    this.projectTableDataSource = new MatTableDataSource<PianoDeiCostiElement>();
    projectTableContentDatabase.dataChange.subscribe(data => this.projectTableDataSource.data = data);


  }

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      // this.color=colors[Math.floor(Math.random() * colors.length)];
      this.projectTableDataSource.paginator = this.paginator;
      this.loadStoricoPDA(this.progetto, this.wbs);
    });
  }

  loadStoricoPDA(progetto, wbs) {
    this.projectTableContentDatabase.setOwnerComponentService(this.pianiDeiCostiService);
    this.projectTableContentDatabase.setOwnerComponentDialog(this.dialog);
    this.projectTableContentDatabase.setProgetto(progetto);
    this.projectTableContentDatabase.setWbs(wbs);
    this.projectTableContentDatabase.reloadProjectGestoriDatabaseStructure(() => {
    });

  }

  close() {
    this.thisDialogRef.close();
  }

}
