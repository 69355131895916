import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import { DettaglioCostoOrarioModalService } from './dettaglio-costo-orario-modal.service';

//Interfaccia per definizione oggetto tabellare
export interface TableElement {
  persona: any;
  costiOrari: Number[];
  ore: Number[];
  costiTotali: Number[];
  totale: Number;
  titolo: String;
  isTotale: Boolean;
  costiOrariObjects: any[];
  fascia: any;
}

@Component({
  selector: 'app-dettaglio-costo-orario-modal',
  templateUrl: './dettaglio-costo-orario-modal.component.html',
  styleUrls: ['./dettaglio-costo-orario-modal.component.css']
})
export class DettaglioCostoOrarioModalComponent implements OnInit {

  //Colonne mostrate in tabella
  displayedColumns = [];
  //Datasource tabella
  tableDataSource: TableElement[] = [];

  //Flag di caricamento del dialog
  loading: boolean = true;

  fasceStandard = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public thisDialogRef: MatDialogRef<DettaglioCostoOrarioModalComponent>,
    public dettaglioCostoOrarioModalService: DettaglioCostoOrarioModalService
  ) { }

  /**
   * Verifica se la riga rappresenta una persona appartenente al progetto considerato.
   * @param parentElement 
   */
  isInProject(parentElement) : Boolean {
    return parentElement.progetti.filter(progetto => progetto.id === this.data.progetto.id).length > 0;
  }

  /**
   * Verifica se è possibile salvare i dati inseriti in tabella.
   */
  isSavingPossible() : Boolean {
    if(this.data.progetto.bando.confCostiStandard != undefined && this.data.progetto.bando.confCostiStandard === true) {
      if(this.tableDataSource.filter(function(filtered) { return filtered.isTotale === false && filtered.fascia == undefined }).length > 0) return false;
    }
    return true;
  }

  /**
   * Gestisce l'evento di salvataggio dei dati.
   * @param event Evento di salvataggio.
   */
  onSaveElements(event) : void {
    if(this.tableDataSource) {
      const toConsiderElements = this.getToConsiderTableElements();
      toConsiderElements.forEach(element => {
        this.saveElement(element, (result) => {
          if(toConsiderElements.indexOf(element) === toConsiderElements.length - 1) {
            this.openSnackBar("Dettaglio costo orario per il progetto " + this.data.progetto.nome + " salvato con successo.", "Chiudi");
            this.thisDialogRef.close();
          }
        });
      });
    }
  }

  /**
   * Aggiorna il costo orario sul database.
   * @param costo Costo orario.
   * @param _callback Azione successiva.
   */
  updateCostoOrario(costo, _callback) : void {
    this.dettaglioCostoOrarioModalService.updateCostoOrario(costo).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva il costo orario sul database.
   * @param costo Costo orario.
   * @param _callback Azione successiva.
   */
  saveCostoOrario(costo, _callback) : void {
    this.dettaglioCostoOrarioModalService.saveCostoOrario(costo).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Preleva dal database l'oggetto pianificazione progetto-persona.
   * @param progetto Progetto di riferimento.
   * @param persona Persona di riferimento.
   * @param sedeStakeholder Sede dello stakeholder di riferimento.
   * @param _callback Azione successiva.
   */
  loadPianificazioneProgettoPersona(progetto, persona, sedeStakeholder, _callback) : void {
    this.dettaglioCostoOrarioModalService.getPianificazioneProgettoPersona(progetto.id, persona.id, sedeStakeholder.id).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Preleva dal database le fasce di costo standard.
   * @param idBando Id del bando di riferimento.
   * @param idTipologiaStakeholder Id della tipologia stakeholder di riferimento.
   * @param _callback Azione successiva.
   */
  loadFasceStandard(idBando, idTipologiaStakeholder, _callback) : void {
    this.dettaglioCostoOrarioModalService.getFasceStandard(idBando, idTipologiaStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Aggiorna la pianificazione progetto persona. Da utilizzare nel caso di settaggio della fascia di costo standard.
   * @param pianificazioneProgettoPersona Pianificazione progetto-persona da aggiornare.
   * @param _callback Azione successiva.
   */
  updatePianificazioneProgettoPersona(pianificazioneProgettoPersona, _callback) : void {
    this.dettaglioCostoOrarioModalService.updatePianificazioneProgettoPersona(pianificazioneProgettoPersona).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva l'elemento in tabella nel database.
   * @param element Elemento in tabella.
   * @param _callback Azione successiva.
   */
  saveElement(element: TableElement, _callback) : void {
    this.loadPianificazioneProgettoPersona(this.data.progetto, element.persona, this.data.sedeStakeholder, (pianificazioneProgettoPersona) => {
      if(element.fascia != undefined) {
        pianificazioneProgettoPersona.fasciaCostoPersonaleStandard = element.fascia.fasciaCostoPersonaleStandard;
        this.updatePianificazioneProgettoPersona(pianificazioneProgettoPersona, (pianRef) => {});
      }
      const toUpdate = this.getDettagliWrapper(element).costi;
      var anniToUpdate = [];
      toUpdate.forEach(costo => {
        const index = +this.getIndicatoreAnnoIndex(costo.anno);
        if(index >= 0) {
          anniToUpdate.push(costo.anno);
          costo.costoOrario = element.costiOrari[index];
          this.updateCostoOrario(costo, (updated) => {

          });
        }
      });
      this.getAnni().forEach(anno => {
        if(anniToUpdate.indexOf(anno) < 0) {
          this.saveCostoOrario({
            id: undefined,
            anno: anno,
            costoOrario: element.costiOrari[+this.getIndicatoreAnnoIndex(anno)],
            pianificazioneProgettoPersona: pianificazioneProgettoPersona
          }, saved => {

          });
        }
      });
      _callback();
    });
  }

  /**
   * Gestisce l'evento di cambiamento del costo orario per un elemento in tabella.
   * @param element Elemento in tabella.
   */
  onChangeCostoOrario(element: TableElement) : void {
    this.refreshContatoriElement(element);
    this.refreshTotali();
  }

  /**
   * Gestisce l'evento di selezione fascia di costo standard. Aggiorna tutti gli indicatori con il nuovo valore.
   * @param element Elemento in tabella.
   */
  onFasciaSelected(element: TableElement) : void {
    element.costiOrari = new Array(element.costiOrari.length).fill(element.fascia.costoOrario);
    this.refreshContatoriElement(element);
    this.refreshTotali();
  }

  /**
   * Aggiorna tutti i totali in tabella (costi totali, totali di progetto e totali finali).
   */
  refreshTotali() : void {
    var tot: TableElement = this.getTotaleTableElement();
    const anni = this.getAnni();
    var tmpOre = new Array(anni.length).fill(0);
    var tmpCosti = new Array(anni.length).fill(0);
    var tmpTot = 0;
    const list: TableElement[] = this.getToConsiderTableElements();

    list.forEach(function (listElement, i) {
      anni.forEach(function (anno, j) {
        tmpOre[j] += +list[i].ore[j];
        tmpCosti[j] += +list[i].costiTotali[j];
      });
      tmpTot += +list[i].totale;
    });

    tot.ore = tmpOre;
    tot.costiTotali = tmpCosti;
    tot.totale = tmpTot;
  }

  /**
   * Aggiorna i contatori dell'elemento in tabella.
   * @param element Elemento in tabella.
   */
  refreshContatoriElement(element: TableElement) : void {
    var tot = 0;
    this.getAnni().forEach(function (anno, i) {
      var totAnno = +element.costiOrari[i] * +element.ore[i];
      element.costiTotali[i] = totAnno;
      tot += totAnno;
    });
    element.totale = tot;
  }

  /**
   * Restituisce gli anni di definizione dell'intervallo
   */
  getAnni() : Number[] {
    var res = [];
    for(var i=+this.data.dataSource[0].filters[3].year(); i<=+this.data.dataSource[0].filters[4].year(); i++) {
      res.push(i);
    }
    return res;
  }

  /**
   * Restituisce un nuovo array pieno di zeri, uno per ogni anno nell'intervallo scelto.
   */
  getNewYearCounterArray() : any[] {
    var res = [];
    this.getAnni().forEach(anno => {res.push(0)});
    return res;
  }

  /**
   * Restituisce un nuovo array pieno di numeri randomici, uno per ogni anno nell'intervallo scelto.
   */
  getNewRandomYearCounterArray() : Number[] {
    var res = [];
    this.getAnni().forEach(anno => {res.push(Math.floor(Math.random() * 12) + 1)});
    return res;
  }

  /**
   * Restituisce l'elemento in tabella rappresentante il totale.
   */
  getTotaleTableElement() : TableElement {
    return this.tableDataSource.find(element => element.isTotale === true);
  }
  
  /**
   * Fornisce l'indice di posizione dell'anno nella lista locale.
   * @param anno Anno indicato.
   */
  getIndicatoreAnnoIndex(anno: Number) : Number {
    return this.getAnni().indexOf(anno);
  }

  /**
   * Restituisce gli elementi da considerare in tabella (tutti gli elementi escluso la riga di totali).
   */
  getToConsiderTableElements() : TableElement[] {
    return this.tableDataSource.filter(element => !element.isTotale);
  }

  /**
   * Restituisce le informazioni notevoli contenute nel wrapper di dettaglio costo orario per l'elemento in tabella.
   * @param tableElement Elemento in tabella.
   */
  getDettagliWrapper(tableElement) : any {
    if(!tableElement.isTotale && this.data.wrapper) {
      const wrapper = this.data.wrapper.find(filtered => filtered.progetto.id === this.data.progetto.id);
      if(wrapper && wrapper.dettagli) {
        return wrapper.dettagli.find(dettagliFiltered => dettagliFiltered.persona.id === tableElement.persona.id);
      }
    }
    return undefined;
  }

  /**
   * Restituisce le informazioni notevoli contenute nel wrapper di ore progetti per l'elemento in tabella.
   * @param tableElement 
   */
  getOreProgettiWrapper(tableElement) : any {
    if(!tableElement.isTotale && this.data.pianificazioneProgettiWrapper) {
      for(var i in this.data.pianificazioneProgettiWrapper) {
        if(this.data.pianificazioneProgettiWrapper[i].pianificazioneProgettoPersona.progetto.id === this.data.progetto.id &&
           this.data.pianificazioneProgettiWrapper[i].pianificazioneProgettoPersona.persona.id === tableElement.persona.id) {
             return this.data.pianificazioneProgettiWrapper[i].pianificazioniMensili;
           }
      }
    }
    return undefined;
  }

  /**
   * Restituisce le informazioni notevoli contenute nel wrapper di ore altre attività per l'elemento in tabella.
   * @param tableElement 
   */
  getOreAltreAttivitaWrapper(tableElement) : any {
    if(!tableElement.isTotale && this.data.pianificazioneProgettiWrapper) {
      for(var i in this.data.pianificazioneAltreAttivitaWrapper) {
        if(this.data.pianificazioneAltreAttivitaWrapper[i].persona.id === tableElement.persona.id) {
          return this.data.pianificazioneAltreAttivitaWrapper[i].pianificazioniMensili;
        }
      }
    }
    return undefined;
  }

  /**
   * Inizializza il datasource della tabella.
   */
  initTableDataSource() : void {
    for(var i in this.data.dataSource) {
      if(this.isInProject(this.data.dataSource[i])) {
        this.tableDataSource.push({
          persona: this.data.dataSource[i].personaObject,
          costiOrari: this.getNewYearCounterArray(),
          ore: this.getNewYearCounterArray(),
          costiTotali: this.getNewYearCounterArray(),
          totale: 0,
          titolo: this.data.dataSource[i].cognome+" "+this.data.dataSource[i].nome,
          isTotale: false,
          costiOrariObjects: [],
          fascia: undefined
        });
      }
    }
    this.tableDataSource.push({
      persona: undefined,
      costiOrari: this.getNewYearCounterArray(),
      ore: this.getNewYearCounterArray(),
      costiTotali: this.getNewYearCounterArray(),
      totale: 0,
      titolo: "TOTALI",
      isTotale: true,
      costiOrariObjects: [],
      fascia: undefined
    });
  }

  /**
   * Inizializza i dati in tabella in base ai wrapper provenienti dal partner (informazioni da database).
   * @param _callback Azione successiva.
   */
  initDataFromWrapper(_callback) : void {
    this.tableDataSource.forEach(tableElement => {
      /** Per ogni elemento in tabella, inizializza i costi orari in base ai dati da database. */
      const dettagliWrapper = this.getDettagliWrapper(tableElement);
      if(dettagliWrapper && dettagliWrapper.costi) {
        dettagliWrapper.costi.forEach(costo => {
          if(costo.pianificazioneProgettoPersona.fasciaCostoPersonaleStandard != undefined)
            tableElement.fascia = this.fasceStandard.find(found => found.fasciaCostoPersonaleStandard.id === costo.pianificazioneProgettoPersona.fasciaCostoPersonaleStandard.id);
          const index = +this.getIndicatoreAnnoIndex(+costo.anno);
          if(index != undefined && index >= 0) {
            tableElement.costiOrari[index] = costo.costoOrario;
            if(tableElement.costiOrariObjects.indexOf(costo) < 0)
              tableElement.costiOrariObjects.push(costo);
          }
        });
      }
      const pianificazioniProgetti = this.getOreProgettiWrapper(tableElement);
      const pianificazioniAltreAttivita = this.getOreAltreAttivitaWrapper(tableElement);
      /**
       * Per ogni elemento in tabella, inizializza le ore pianificate in base ai dati da database.
       * Le ore pianificate corrispondono alla somma delle ore pianificate per progetti e per altre attività
       * in base all'anno e alla persona indicata, per il progetto considerato.
      */
      if(pianificazioniProgetti && pianificazioniAltreAttivita) {
        this.getAnni().forEach(anno => {
          var cnt = 0;
          pianificazioniProgetti.forEach(pianificazioneProgetti => {
            if(pianificazioneProgetti.pianificazioneProgettoPersonaPerMese.anno === anno) {
              cnt += pianificazioneProgetti.pianificazioneProgettoPersonaPerMese.ore;
            }
          });
          pianificazioniAltreAttivita.forEach(pianificazioneAltreAttivita => {
            if(pianificazioneAltreAttivita.anno === anno) {
              cnt += pianificazioneAltreAttivita.ore;
            }
          });
          tableElement.ore[+this.getIndicatoreAnnoIndex(anno)] = cnt;
        });
      }
      this.refreshContatoriElement(tableElement);
    });
    this.tableDataSource = this.tableDataSource.concat([]);
    this.refreshTotali();
    _callback();
  }

  /**
   * Inizializza le colonne in tabella in base al periodo selezionato.
   */
  initTableColumns() : void {
    this.displayedColumns.push('nominativo');
    if(this.data.progetto.bando.confCostiStandard != undefined && this.data.progetto.bando.confCostiStandard === true)
      this.displayedColumns.push('costo_standard');
    const anni = this.getAnni();
    anni.forEach(anno => {
      this.displayedColumns.push('costo_orario_'+anno);
      this.displayedColumns.push('ore_'+anno);
      this.displayedColumns.push('costo_'+anno);
    });
    this.displayedColumns.push('costo_totale');
  }

  /**
   * Inizializza le fasce standard dal database.
   * @param _callback Azione successiva.
   */
  initFasceStandard(_callback) : void {
    const tipologiaStakeholderRef = this.data.sedeStakeholder.stakeholder.tipologiaStakeholder;
    if(tipologiaStakeholderRef == undefined) {
      this.showError("Errore nel caricamento delle fasce standard. Riprovare.");
    }
    this.loadFasceStandard(this.data.progetto.bando.id, tipologiaStakeholderRef.id, (fasceRef) => {
      this.fasceStandard = fasceRef != undefined ? fasceRef : [];
      _callback();
    });
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  /**
   * Mostra una notifica all'utente.
   * @param message Messaggio da mostrare.
   * @param action Azione da permettere all'utente.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  ngOnInit() {
    this.initTableColumns();
    this.initTableDataSource();
    this.refreshTotali();
    if(this.data.progetto.bando.confCostiStandard === true) { 
      this.initFasceStandard(() => {
        this.initDataFromWrapper(() => {
          this.loading = false;
        });
      });
    }
    else {
      this.initDataFromWrapper(() => {
        this.loading = false;
      });
    }
  }

}
