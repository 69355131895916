import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material";
import {GestioneStakeholdersService} from "../gestione-stakeholders/gestione-stakeholders.service";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {RotellinaService} from "../rotellina-dialog/rotellina.service";
import {GestionePartnerProgettoService} from "../gestione-partner-progetto/gestione-partner-progetto.service";

export interface DialogData {
  id: number;
}

@Component({
  selector: 'smart-allocation-dialog',
  templateUrl: 'smart-allocation-dialog.html',
})
export class SmartAllocationDialog {
  multi: any[][];
  view: any[] = [1000, 1000];
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Persone';
  yAxisLabel: string = 'Attività';
  colorScheme = {
    domain: ['#ff4514', '#ff5722', '#ff9800', '#f3e562', '#a7b61a', '#afdf0a', '#00b862']
  };
  stakeholders: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public gestioneStakeholdersService: GestioneStakeholdersService,
              public gestionePartnerProgettoService: GestionePartnerProgettoService,
              private rotella: RotellinaService) {
  }

  ngOnInit() {
    this.multi = [];
    this.rotella.openDialog();
    this.gestionePartnerProgettoService.getSediProgetto(this.data.id).subscribe(
      data => {
        for (let i=0;i<data.length;i++){
          this.stakeholders.push(data[i].stakeholder.denominazione + " - " + data[i].nome);
          this.gestioneStakeholdersService.getAllocazionePersonale(this.data.id,data[i].stakeholder.id).subscribe(
            data => { this.multi.push(data); this.rotella.closeDialog();},
            err => { console.log(err); this.rotella.closeDialog();}
          );
        }
        this.stakeholders = this.stakeholders.slice().reverse();
      },
      err => {
      }
    );
    /*this.gestioneStakeholdersService.getAllocazionePersonale(9,5).subscribe(
      data => { this.multi.push(data); this.rotella.closeDialog();},
      err => { console.log(err); this.rotella.closeDialog();}
    );*/
    /*this.multi = [
      [
      {
        "name": "Persona 1",
        "series": [
          {
            "name": "Attività 6",
            "value": 0.5
          },
          {
            "name": "Attività 5",
            "value": 0.5
          },
          {
            "name": "Attività 4",
            "value": 0.5
          },
          {
            "name": "Attività 3",
            "value": 0.5
          },
          {
            "name": "Attività 2",
            "value": 1
          },
          {
            "name": "Attività 1",
            "value": 0.5
          },
        ]
      },

      {
        "name": "Persona 2",
        "series": [
          {
            "name": "Attività 6",
            "value": 0.5
          },
          {
            "name": "Attività 5",
            "value": 0.5
          },
          {
            "name": "Attività 4",
            "value": 0.5
          },
          {
            "name": "Attività 3",
            "value": 0.5
          },
          {
            "name": "Attività 2",
            "value": 0.5
          },
          {
            "name": "Attività 1",
            "value": 0.5
          }
        ]
      },

      {
        "name": "Persona 3",
        "series": [
          {
            "name": "Attività 6",
            "value": 0.5
          },
          {
            "name": "Attività 5",
            "value": 0.5
          },
          {
            "name": "Attività 4",
            "value": 0.5
          },
          {
            "name": "Attività 3",
            "value": 0.5
          },
          {
            "name": "Attività 2",
            "value": 0.5
          },
          {
            "name": "Attività 1",
            "value": 0.5
          }
        ]
      },
      {
        "name": "Persona 4",
        "series": [
          {
            "name": "Attività 6",
            "value": 0.1
          },
          {
            "name": "Attività 5",
            "value": 0.5
          },
          {
            "name": "Attività 4",
            "value": 0.5
          },
          {
            "name": "Attività 3",
            "value": 0.5
          },
          {
            "name": "Attività 2",
            "value": 0.5
          },
          {
            "name": "Attività 1",
            "value": 0.8
          }
        ]
      },
      {
        "name": "Persona 5",
        "series": [
          {
            "name": "Attività 6",
            "value": 0.5
          },
          {
            "name": "Attività 5",
            "value": 0.5
          },
          {
            "name": "Attività 4",
            "value": 0.5
          },
          {
            "name": "Attività 3",
            "value": 0.5
          },
          {
            "name": "Attività 2",
            "value": 0.5
          },
          {
            "name": "Attività 1",
            "value": 0.8
          }
        ]
      },
      {
        "name": "Persona 6",
        "series": [
          {
            "name": "Attività 6",
            "value": 0.5
          },
          {
            "name": "Attività 5",
            "value": 0.5
          },
          {
            "name": "Attività 4",
            "value": 0.5
          },
          {
            "name": "Attività 3",
            "value": 0.5
          },
          {
            "name": "Attività 2",
            "value": 0.5
          },
          {
            "name": "Attività 1",
            "value": 0.8
          }
        ]
      }
    ],
      [
        {
          "name": "Persona 1",
          "series": [
            {
              "name": "Attività 6",
              "value": 0.5
            },
            {
              "name": "Attività 5",
              "value": 0.5
            },
            {
              "name": "Attività 4",
              "value": 0.5
            },
            {
              "name": "Attività 3",
              "value": 0.5
            },
            {
              "name": "Attività 2",
              "value": 1
            },
            {
              "name": "Attività 1",
              "value": 0.5
            },
          ]
        },

        {
          "name": "Persona 2",
          "series": [
            {
              "name": "Attività 6",
              "value": 0.5
            },
            {
              "name": "Attività 5",
              "value": 0.5
            },
            {
              "name": "Attività 4",
              "value": 0.5
            },
            {
              "name": "Attività 3",
              "value": 0.5
            },
            {
              "name": "Attività 2",
              "value": 0.5
            },
            {
              "name": "Attività 1",
              "value": 0.5
            }
          ]
        },

        {
          "name": "Persona 3",
          "series": [
            {
              "name": "Attività 6",
              "value": 0.5
            },
            {
              "name": "Attività 5",
              "value": 0.5
            },
            {
              "name": "Attività 4",
              "value": 0.5
            },
            {
              "name": "Attività 3",
              "value": 0.5
            },
            {
              "name": "Attività 2",
              "value": 0.5
            },
            {
              "name": "Attività 1",
              "value": 0.5
            }
          ]
        },
        {
          "name": "Persona 4",
          "series": [
            {
              "name": "Attività 6",
              "value": 0.1
            },
            {
              "name": "Attività 5",
              "value": 0.5
            },
            {
              "name": "Attività 4",
              "value": 0.5
            },
            {
              "name": "Attività 3",
              "value": 0.5
            },
            {
              "name": "Attività 2",
              "value": 0.5
            },
            {
              "name": "Attività 1",
              "value": 0.8
            }
          ]
        },
        {
          "name": "Persona 5",
          "series": [
            {
              "name": "Attività 6",
              "value": 0.5
            },
            {
              "name": "Attività 5",
              "value": 0.5
            },
            {
              "name": "Attività 4",
              "value": 0.5
            },
            {
              "name": "Attività 3",
              "value": 0.5
            },
            {
              "name": "Attività 2",
              "value": 0.5
            },
            {
              "name": "Attività 1",
              "value": 0.8
            }
          ]
        },
        {
          "name": "Persona 6",
          "series": [
            {
              "name": "Attività 6",
              "value": 0.5
            },
            {
              "name": "Attività 5",
              "value": 0.5
            },
            {
              "name": "Attività 4",
              "value": 0.5
            },
            {
              "name": "Attività 3",
              "value": 0.5
            },
            {
              "name": "Attività 2",
              "value": 0.5
            },
            {
              "name": "Attività 1",
              "value": 0.8
            }
          ]
        }
      ]
    ];*/
  }
}
