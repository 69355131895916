import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../../../app.settings';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class ChatService {

  constructor(private http: HttpClient) {
  }

  public generatePromptRendicontazione(idSal, idSedeStakeholder, tipoVersione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'chat/generate-prompt-rendicontazione?idSal=' + idSal + '&idSedeStakeholder=' + idSedeStakeholder + '&tipoVersione=' + tipoVersione);
  }

  public generatePromptWbs(nomeProgetto, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'chat/generate-prompt-wbs?nomeProgetto=' + nomeProgetto + '&idOrganizzazione=' + idOrganizzazione);
  }

}