import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminOrganizzazioneDashboardService } from './admin-organizzazione-dashboard.service';
import { UserStorage } from '../core_modules/user.storage';
import { UtilityModuleComponent } from '../core_modules/utility-module/utility-module.component';
import { AppComponent } from '../app.component';
import { OrganizzazioniService } from 'src/app/organizzazioni/organizzazioni.service';

@Component({
  selector: 'app-admin-organizzazione-dashboard',
  templateUrl: './admin-organizzazione-dashboard.component.html',
  styleUrls: ['./admin-organizzazione-dashboard.component.css']
})
export class AdminOrganizzazioneDashboardComponent implements OnInit{

  constructor(private router: Router,
              private adminOrganizzazioneDashboardService: AdminOrganizzazioneDashboardService,
              private organizzazioniService: OrganizzazioniService,
              private userStorage: UserStorage,
              private utilityModule: UtilityModuleComponent,
              private appComponent: AppComponent) {

    }

  showFiller = false;
  
  organizzazione;

  clientiAttiviList: any[];

  loadOrganizzazione() {
    this.adminOrganizzazioneDashboardService.getOrganizzazioneUtente(this.userStorage.getEmail()).subscribe(
      data=>{
        this.organizzazione = data;
        this.userStorage.saveOrganizzazione(this.organizzazione.nome);
        console.log('Logged Organization');
        console.log(this.organizzazione);
      },
      err=>{
        console.log("*************************************************");
        console.log("ERROR...");
        console.log(err);
        console.log("*************************************************");
      }
    );    
  }


  redirect(newPage)
  {
    this.utilityModule.redirectAdminOrganizzazione(newPage);
  }

  ngOnInit()
  {
    this.loadOrganizzazione();
  }

  ngAfterViewInit()
  {
/*      this.loadOrganizzazione();
*/}

}
