import {Component, Inject, Injectable, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {WarningMessageComponent} from "../modals/warning-message/warning-message.component";
import {MatPaginator} from "@angular/material/paginator";
import {MonitoraggioProgettoService} from "../monitoraggio-progetto/monitoraggio-progetto.service";
import {OrganizzazioniService} from "../organizzazioni/organizzazioni.service";
import {ConfirmMessageComponent} from "../modals/confirm-message/confirm-message.component";
import {UserStorage} from "../core_modules/user.storage";
import {AuthService} from "../core_modules/auth.service";
import {GestioneWbsService} from "../gestione-wbs/gestione-wbs.service";
import {GestionePianiDeiCostiService} from "../gestione-piani-dei-costi/gestione-piani-dei-costi.service";
import {GestionePartnerProgettoService} from "../gestione-partner-progetto/gestione-partner-progetto.service";
import {GestioneReferentiProgettoService} from "../gestione-referenti-progetto/gestione-referenti-progetto.service";
import {GestioneSalService} from "../gestione-sal/gestione-sal.service";
import {MonitoraggioDeliverablesProgettoComponent} from "../monitoraggio-deliverables-progetto/monitoraggio-deliverables-progetto.component";
import {ReportcostiComponent} from "../reportcosti/reportcosti.component";
import {
  DateAdapter, MAT_DIALOG_DATA,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MatTableDataSource
} from "@angular/material";
import {AppSettings} from "../app.settings";
import { RotellinaService } from '../rotellina-dialog/rotellina.service';
import {UtilsService} from "../common-services/utils.service";
import {PartnerSmartreportDialogComponent} from "./partner-smartreport-dialog/partner-smartreport-dialog.component";


export class ProgettiElement {
  id: String;
  nomeProgetto: string;
  position: number;
  weight: number;
  symbol: string;
}


@Injectable()
export class TableMonitoraggioProgettiDatabase {

  dataChange = new BehaviorSubject<ProgettiElement[]>([]);

  get data(): ProgettiElement[] {
    return this.dataChange.value;
  }

  // Classe di servizio del component owner che implementa i vari servizi verso il back-end invocati dal TableProjectDatabase
  // come 'saveAttivitasInPraticas', 'deleteAttivitaFromPratica', 'getPraticheTreeByOrganization', etc.
  private ownerComponentService: any;
  // La dialog dell'owner component
  private ownerComponentDialog: MatDialog
  ;
  // ########## END   ELEMENTI DEL COMPONENT OWNER ##########

  //L'oggetto JSON, estratto con apposito querying del database, che contiene i progetti.
  jsonProjectTableStructure: any;

  projectTableNodesArray: ProgettiElement[];

  public setOwnerComponentService(x: any) {
    this.ownerComponentService = x;
  }

  public setOwnerComponentDialog(y: MatDialog) {
    this.ownerComponentDialog = y;
  }

  constructor(private adapter: DateAdapter<any>,
              @Inject(LOCALE_ID) private locale: string) {
    this.adapter.setLocale("it");
  }

  bandiProgetto = [];

  getBandiProgetto() {
    return this.bandiProgetto;
  }

  reloadProjectGestoriDatabaseStructure(idorganizzazione, _callback) {
    this.ownerComponentService.getProgettiFromRendicontaDB(idorganizzazione).subscribe(
      dataDb => {
        this.jsonProjectTableStructure = dataDb;
        console.log(JSON.stringify(dataDb));

        for (var x = 0; x < dataDb.length; x++) {
          this.bandiProgetto.push({
              idProgetto: dataDb[x].id,
              bando: dataDb[x].bando
            }
          );
        }

        this.projectTableNodesArray = this.buildProjectNodes(this.jsonProjectTableStructure);

        const data = this.projectTableNodesArray;
        this.dataChange.next(data);

        _callback();
      },
      err => {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare i Progetti!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    )
  }


  // Build the nodes from Json object. The result is a list of `TableProjectNode`
  buildProjectNodes(projectTableNodeData: any[]): ProgettiElement[] {
    let nodeArray = new Array<ProgettiElement>();
    if (projectTableNodeData != null) {
      try {
        projectTableNodeData.forEach(project => {
          const node = new ProgettiElement();

          node.nomeProgetto = project.nome;
          node.id = project.id;

          nodeArray.push(node);
        });
      } catch (error) {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare informazioni della tabella!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    }
    return nodeArray;
  }
}

export class TableElementFlags {
  tableElement: ProgettiElement;
  isPartnerPresent: Boolean = false;
  isReferentiPresent: Boolean = false;
  isWbsPresent: Boolean = false;
  isPianoCostiAmmissibiliPresent: Boolean = false;
  isPianoSalPresent: Boolean = false;
}

export interface DialogData {
  id: number;
  nome: string;
}

@Component({
  selector: 'app-partner-smartreport',
  templateUrl: './partner-smartreport.component.html',
  styleUrls: ['./partner-smartreport.component.css'],
  providers: [TableMonitoraggioProgettiDatabase, {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}]
})
export class PartnerSmartreportComponent implements OnInit {


  displayedColumns: string[] = ['name', 'report1', 'report2', 'report3', 'report4'];
  projectTableDataSource: MatTableDataSource<ProgettiElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator;// Numero elementi da visualizzare

  organizzazioneObj = undefined;
  constructor(
    private monitoraggioProgettoService: MonitoraggioProgettoService,
    private organizzazioniService: OrganizzazioniService,
    public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private userStorage: UserStorage,
    private authService: AuthService,
    private adapter: DateAdapter<any>,
    private projectTableContentDatabase: TableMonitoraggioProgettiDatabase,
    private monitoraggioDeliverablesProgettoDialog: MatDialog,
    private monitoraggioCostiDialog: MatDialog,
    private gestioneWbsService: GestioneWbsService,
    private gestionePianiDeiCostiService: GestionePianiDeiCostiService,
    private gestionePartnerService: GestionePartnerProgettoService,
    private gestioneReferentiService: GestioneReferentiProgettoService,
    private gestioneSalService: GestioneSalService,
    private rotella: RotellinaService,
    public utilsService: UtilsService/*,
    @Inject(MAT_DIALOG_DATA) public data: DialogData*/
  ) {
    this.adapter.setLocale("it");
    this.projectTableDataSource = new MatTableDataSource<ProgettiElement>();
    projectTableContentDatabase.dataChange.subscribe(data => this.projectTableDataSource.data = data);

  }


  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      // this.color=colors[Math.floor(Math.random() * colors.length)];
      this.projectTableDataSource.paginator = this.paginator;
      this.loadOrganizzazioneAndOtherData();
    });


  }


  loadOrganizzazioneAndOtherData() {
    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        this.organizzazioneObj = data;
        console.log('obj: ' + this.organizzazioneObj.id);


        this.projectTableContentDatabase.setOwnerComponentService(this.monitoraggioProgettoService);
        this.projectTableContentDatabase.setOwnerComponentDialog(this.dialog);

        this.projectTableContentDatabase.reloadProjectGestoriDatabaseStructure(this.organizzazioneObj.id, () => {
        });

      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }


  onMonitoraDeliverables(id: string, nome: string, tipo: string): void {
    localStorage.setItem('idProgetto', id);
    localStorage.setItem('nomeProgetto', nome);
    localStorage.setItem('tipoReport', tipo);
    const monitoraggioDeliverablesProgettoDialogRef = this.monitoraggioDeliverablesProgettoDialog.open(PartnerSmartreportDialogComponent, {
      height: '90%',
      width: '100%',
      maxWidth: '100%'/*,
      data: {id: id, nome: nome}*/
    });
  }
}
