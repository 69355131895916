import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GestioneBeniService } from 'src/app/common-services/gestione-beni.service';
import { SharedDataService } from 'src/app/common-services/shared-data.service';
import { UtilsService } from 'src/app/common-services/utils.service';
import { CreaProgettoService } from 'src/app/crea-progetto/crea-progetto.service';
import { PianificazioneCostiService } from 'src/app/pianificazione-costi/pianificazione-costi.service';
import { RotellinaService } from 'src/app/rotellina-dialog/rotellina.service';
import { MonitoraAttrezzaturaDettagliCostoModalComponent } from '../monitora-attrezzatura-dettagli-costo-modal/monitora-attrezzatura-dettagli-costo-modal.component';

@Component({
  selector: 'app-monitora-attrezzatura-modal',
  templateUrl: './monitora-attrezzatura-modal.component.html',
  styleUrls: ['./monitora-attrezzatura-modal.component.css']
})
export class MonitoraAttrezzaturaModalComponent implements OnInit , AfterViewInit  {

  constructor(
    public dialogRef: MatDialogRef<MonitoraAttrezzaturaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedDataService: SharedDataService,
    private rotella: RotellinaService,
    public utilsService: UtilsService,
    private creaProgettoService: CreaProgettoService,
    private gestioneBeniService: GestioneBeniService,
    private pianificazioneCostiService: PianificazioneCostiService,
    public dialog: MatDialog
  ) { }
  ngAfterViewInit() {
    this.inizializze();
  }
  public progetto = undefined;
  public beni_sedi_stakeholders = [];
  public columns = ['nome','ammortizzabile','data','aliquota','costo','percentuale','annotazioni'];
  public showEmptyMessage= false;

  ngOnInit() {
    
  }

  public apriDettaglioCosti(bene){
    const monitoraSalDialogRef = this.dialog.open(MonitoraAttrezzaturaDettagliCostoModalComponent,{
      data:{
        bene,
        
      }
    })


  }
  async  inizializze(){
   this.rotella.openDialog();
//rendendo tutto const li aumentano le performance e si riducono i casi di errore nel codice nel riutilizzare una variabile quando è già usata
    // tutte le varibili sono immutabili per default nella programmazione funzionale
    
    const organizzazioneID = this.sharedDataService.getOrganizzazioneID();

    const progettoID = this.data.progetto.id;
    this.progetto = this.data.progetto;

    
    const stakeholders = await this.creaProgettoService.getStakeholders(progettoID, organizzazioneID).toPromise();

    const beni_sedi_stakeholders: Array<any> = await Promise.all(stakeholders.map(async stakeholder => {
      const sediStakeholder = await this.pianificazioneCostiService.getSediStakeholder(stakeholder.id).toPromise();

      const sede_beni: Array<any> = await Promise.all(sediStakeholder.map(async sede => {
        let beni_temp = await this.gestioneBeniService.getBeni(progettoID, sede.id).toPromise();

        beni_temp =  await Promise.all(beni_temp.map(async bene_tmp => {
          let costo_monitoraggio = await this.gestioneBeniService.getMonitoraggioBene(bene_tmp.id).toPromise();
          let dettagli = await this.gestioneBeniService.getDettaglioMonitoraggioBene(bene_tmp.id).toPromise();
          //da sistemare la query BeneRepository.java findBeneDettagli() per avere l'automapping, questo è solo una soluzione temporanea
          // TODO
          dettagli = dettagli.map(el =>{
            return {
              
                descrizione:el[0],
                costo : el[1],
                data_inizio_utilizzo_bene : el[2],
                data_fine_utilizzo_bene : el[3],
                percentuale_utilizzo_bene : el[4],
                nome_sal : el[5],
                tipo_versione	 : el[6],
                nome_wbs : el[7],
              
            }
          });
          console.log(dettagli)
          const bene = { ...bene_tmp, costo_monitoraggio,dettagli}
          return bene;
        }));

        sede.beni = beni_temp;
        return sede;
      }));
      return sede_beni;
    }));

    

    this.beni_sedi_stakeholders = beni_sedi_stakeholders.reduce((acc, val) => acc.concat(val), []);
    
    //filtra le sedi che non  hanno beni e le rimuove
    this.beni_sedi_stakeholders = this.beni_sedi_stakeholders.filter(sede => sede.beni.length > 0);
    
    console.log(this.beni_sedi_stakeholders)
    if(this.beni_sedi_stakeholders.length == 0)
    this.showEmptyMessage= true;
    this.rotella.closeDialog();
    
  }
}
