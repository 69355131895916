import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class MonitoraggioDocProgettoService {

  constructor(private http: HttpClient) {
  }

  public getDocumentiBando(): Observable<any>{
    return this.http.get<any>(AppSettings.BASE_URL+'/documento-bando/get-all');
  }

  public getDocumentoRendicontazione(): Observable<any>{
    return this.http.get<any>(AppSettings.BASE_URL+'/documento-rendicontazione/get-all');
  }

  public saveDocumentoRendicontazione(documentoRendicontazione): Observable<any>{
    return this.http.post<any>(AppSettings.BASE_URL+'/documento-rendicontazione/save-documento-rendicontazione',documentoRendicontazione);
  }

  /*public findLastBySalIdAndSedeStakeholderId(idSal, idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'rendicontazioni/get-last-by-sal-id-and-stakeholder-id?idSal=' + idSal + '&idSedeStakeholder=' + idSedeStakeholder);
  }*/

  public getDocumentiBandoByIdBando(idBando): Observable<any>{
    return this.http.get<any>(AppSettings.BASE_URL+'/documento-bando/get-by-id-bando?idBando=' + idBando);
  }

  public getElementiDiCostoByRendicontazioneId(idRendicontazione): Observable<any>{
    return this.http.get<any>(AppSettings.BASE_URL+'/elementi-di-costo-rendicontato/get-all-by-rendicontazione-id?idRendicontazione=' + idRendicontazione);
  }

}
