import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class RiskManagerService {

  constructor(private http: HttpClient) {}

  public cost(projectId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'riskmanager/cost?' + 
    'projectId=' + projectId);
  }

  public import(projectId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'riskmanager/import?' + 
    'projectId=' + projectId);
  }

  public schedule(dailyWorkingHours, wpsDuration, costOfWork, timeOfCompletion, activityWrappers): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'riskmanager/schedule?' + 
    'dailyWorkingHours=' + dailyWorkingHours + '&' +
    'wpsDuration=' + wpsDuration + '&' +
    'costOfWork=' + costOfWork + '&' +
    'timeOfCompletion=' + timeOfCompletion, 
    activityWrappers);
  }

  public compute(wpsList): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'riskmanager/compute', wpsList);
  }

  public getProgetti(organizzazioneId: number): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'progetti/get-by-organizzazione-id?organizzazioneId=' + organizzazioneId);
  }

}