import {Component, Inject, LOCALE_ID, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthService} from "../core_modules/auth.service";
import {RendicontazioneProgettoService} from "../rendicontazione-progetto/rendicontazione-progetto.service";
import {WarningMessageComponent} from "../modals/warning-message/warning-message.component";
import {MatDialog, MatSnackBar} from "@angular/material";
import {DettaglioCostiConsulenzeModalService} from "../gestione-piani-dei-costi/modals/dettaglio-costi-consulenze-modal/dettaglio-costi-consulenze-modal.service";
import {MonitoraggioDocProgettoService} from "./monitoraggio-doc-progetto.service";
import {Documento} from "./models/documento.model";
import {SelectionModel} from "@angular/cdk/collections";
import {DocumentoRendicontazione} from "./models/documento_rendicontazione.model";
import {Rendicontazione} from "./models/rendicontazione.model";
import {DocumentoBando} from "./models/documento_bando.model";
import {isBoolean} from "util";
import {RotellinaService} from "../rotellina-dialog/rotellina.service";

@Component({
  selector: 'app-monitoraggio-doc-progetto',
  templateUrl: './monitoraggio-doc-progetto.component.html',
  styleUrls: ['./monitoraggio-doc-progetto.component.css']
})
export class MonitoraggioDocProgettoComponent implements OnInit {

  progetti = [];
  stakeholders = [];
  listaSal = [];
  consulenti = [];
  consulentiFiltered = [];
  sedi = [];
  documentiRendicontazione = [];
  documentiBando = [];
  documenti = [];
  dataSource = [];
  tableColumns = ['codice','descrizione','presente','note'];
  selection = new SelectionModel<any>(true, []);
  isComponentLoading : Boolean = true;
  existsBozza : Boolean = false;
  existsOtherVersion : Boolean = false;

  organizzazione = undefined;
  rendicontazione = undefined;
  selectedProgetto = undefined;
  selectedStakeholder = undefined;
  selectedSedeStakeholder = undefined;
  selectedVoceDiCosto = undefined;
  selectedSal = undefined;
  idRendicontazione: number | undefined;

  constructor(private authService: AuthService,
              private rendicontazioneProgettoService: RendicontazioneProgettoService,
              public dialog: MatDialog, private router: Router, public snackBar: MatSnackBar, private rotella: RotellinaService,
              private dettaglioCostiConsulenzeModalService: DettaglioCostiConsulenzeModalService,
              private monitoraggioDocProgettoService: MonitoraggioDocProgettoService) {
  }

  ngOnInit(): void {
    this.authService.checkLoggedUser(() => {
      this.loadOrganizzazione(sessionStorage.getItem("Organization"), (org) => {
        this.organizzazione = org;
        if (this.organizzazione == undefined) this.showError("Errore nell'inizializzazione. Riprovare.");
        else {
          this.loadProgettiRendicontabili(this.organizzazione.id, (progettiRef) => {
            this.progetti = progettiRef.sort((a,b)=> a.nome.toUpperCase() > b.nome.toUpperCase());
            this.selectedProgetto = JSON.parse(sessionStorage.getItem("progetto"))
            this.onSelectProgetto()
          });
        }
      });
    });
  }

  loadOrganizzazione(nome, _callback): void {
    this.rendicontazioneProgettoService.getOrganizzazione(nome).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  loadProgettiRendicontabili(idOrganizzazione, _callback): void {
    this.rendicontazioneProgettoService.getProgettiRendicontabili(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  showError(error: String): void {
    this.dialog.open(WarningMessageComponent, {
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  onSelectProgetto(): void {
    this.isComponentLoading = true;
    this.selectedStakeholder = undefined;
    this.selectedSedeStakeholder = undefined;
    this.selectedVoceDiCosto = undefined;
    this.selectedSal = undefined;
    this.rendicontazione = undefined;
    this.loadStakeholders(this.selectedProgetto.id, this.organizzazione.id, (stakeholdersRef) => {
      this.stakeholders = stakeholdersRef != undefined ? stakeholdersRef : [];
      this.loadListaSal(this.selectedProgetto.id, (salRef) => {
        this.listaSal = salRef != undefined ? salRef : [];
      });
    });
  }

  loadStakeholders(idProgetto, idOrganizzazione, _callback): void {
    this.rendicontazioneProgettoService.getStakeholders(idProgetto, idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  loadListaSal(idProgetto, _callback): void {
    this.rendicontazioneProgettoService.getSal(idProgetto).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError("Errore nell'inizializzazione del piano dei SAL. Verificare che almeno un piano sia stato indicato per il progetto selezionato e riprovare.");
      }
    );
  }

  onSelectStakeholder(): void {
    this.isComponentLoading = true;
    this.selectedSedeStakeholder = undefined;
    this.selectedVoceDiCosto = undefined;
    this.selectedSal = undefined;
    this.rendicontazione = undefined;
    this.loadSediStakeholder(this.selectedStakeholder.id, (sediRef) => {
      this.sedi = sediRef != undefined ? sediRef : [];
    });
    this.loadSediPossibiliConsulenti(this.organizzazione.id, (spc) => {
      this.consulenti = spc != undefined ? spc.filter(filtered => filtered.stakeholder.id != this.selectedStakeholder.id) : [];
      this.consulentiFiltered = this.consulenti.concat([]);
    });
  }

  loadSediStakeholder(idStakeholder, _callback): void {
    this.rendicontazioneProgettoService.getSedi(idStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  loadSediPossibiliConsulenti(idOrganizzazione, _callback): void {
    this.dettaglioCostiConsulenzeModalService.getSedi(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  onSelectSedeStakeholder(): void {
    this.isComponentLoading = true;
    this.selectedSal = undefined;
  }

  loadRendicontazione(idSal, idSedeStakeholder, tipoVersione, _callback): void {
    this.rendicontazioneProgettoService.getRendicontazione(idSal, idSedeStakeholder, tipoVersione).subscribe(
      data => {
        _callback(data)
      },
      err => {
        this.showError(err.error.message);
        _callback(undefined)
      }
    );
  }

  selectSalHandler(_callback): void {
    this.loadRendicontazione(this.selectedSal.id, this.selectedSedeStakeholder.id, "B", (bozza) => {
      this.loadRendicontazione(this.selectedSal.id, this.selectedSedeStakeholder.id, "P", (presentata) => {
        this.loadRendicontazione(this.selectedSal.id, this.selectedSedeStakeholder.id, "C", (certificata) => {
          _callback([bozza, presentata, certificata]);
        })
      })
    })
  }

  onSelectSal(): void {
    this.rotella.openDialog();
    this.selectSalHandler((rendicontazioni) => {
      console.log("RENDICONTAZIONI", rendicontazioni)
      this.rotella.closeDialog();
      this.isComponentLoading = false;
      this.existsBozza = rendicontazioni[0] !== null;
      this.existsOtherVersion = rendicontazioni[1] !== null || rendicontazioni[2] !== null;
      this.idRendicontazione =
        rendicontazioni[0] !== null ? rendicontazioni[0].rendicontazione.id :
        rendicontazioni[1] !== null ? rendicontazioni[1].rendicontazione.id :
        rendicontazioni[2] !== null ? rendicontazioni[2].rendicontazione.id :
        undefined;
      if (this.idRendicontazione !== undefined)
        this.getDocumentiBando();
    })
  }

  getVociDiCosto(){
    this.monitoraggioDocProgettoService.getElementiDiCostoByRendicontazioneId(this.idRendicontazione).subscribe(
      data => {
        let voceDiCosto = []
        data.forEach(function (voce) {
            voceDiCosto.push(voce.voceDiCosto.id);
        });
        voceDiCosto = Array.from(new Set(voceDiCosto));
        this.getDocumentiRendicontazione(voceDiCosto)
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  getDocumentiRendicontazione(voceDiCosto){
    let documentiBando = this.documentiBando;
    this.monitoraggioDocProgettoService.getDocumentoRendicontazione().subscribe(
      data => {
        //_callback(data);
        this.documentiRendicontazione = data;
        let documenti = []
        let selectedSal = this.selectedSal
        let selectedSedeStakeholder = this.selectedSedeStakeholder
        documentiBando.forEach(function (value1) {
          let documento = new Documento();
          documento.codice = value1.codice
          documento.id = value1.id
          documento.descrizione = value1.descrizione
          data.forEach(function (value2) {
            if ((value2.documentoBando.codice == documento.codice) &&
              (selectedSal.id == value2.rendicontazione.sal.id) &&
              (selectedSedeStakeholder.id == value2.rendicontazione.sedeStakeholder.id)){
              documento.presente = value2.presente;
              documento.note = value2.note;
            }
          });
          if ((value1.voceDiCosto == null) || ((value1.voceDiCosto != null) && (voceDiCosto.indexOf(value1.voceDiCosto.id) != -1)))
            documenti.push(documento);
        });
        this.documenti = documenti;
        this.dataSource = [];
        this.initTable(() => {
          this.isComponentLoading = false;
        })
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  getDocumentiBando(){
    this.monitoraggioDocProgettoService.getDocumentiBandoByIdBando(this.selectedProgetto.idBando).subscribe(
      data => {
        //_callback(data);
        if (data.length == 0)
          this.showError("Non ci sono documenti associati al Bando di riferimento.")
        this.documentiBando = data;
        this.getVociDiCosto();
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  initTable(_callback) : void {
    this.dataSource = this.dataSource.concat(this.documenti);
    _callback();
  }

  onElementSelect(element) {
    element.presente = !element.presente;
    this.selection.toggle(element);
  }

  onSave() {
    this.rotella.openDialog();
    this.documenti = this.dataSource;
    let documentiRendicontazione = [];
    let documentoRendicontazione = new DocumentoRendicontazione()
    let rendicontazione = new Rendicontazione()
    let documentoBando = new DocumentoBando()
    let idRendicontazione = this.idRendicontazione;
    this.documenti.forEach(function (value1) {
      documentoRendicontazione = new DocumentoRendicontazione()
      documentoBando = new DocumentoBando()
      documentoBando.id = value1.id
      documentoRendicontazione.documentoBando = documentoBando
      if (value1.presente == undefined)
        value1.presente = 0;
      if (isBoolean(value1.presente))
        documentoRendicontazione.presente = value1.presente === true ? 1 : 0
      else
        documentoRendicontazione.presente = value1.presente
      documentoRendicontazione.note = value1.note === undefined ? "" : value1.note
      rendicontazione = new Rendicontazione();
      rendicontazione.id = idRendicontazione;
      documentoRendicontazione.rendicontazione = rendicontazione
      documentiRendicontazione.push(documentoRendicontazione)
    })
    this.monitoraggioDocProgettoService.saveDocumentoRendicontazione(documentiRendicontazione).subscribe(
      data => {
        this.rotella.closeDialog();
        this.openSnackBar("Documenti salvati con successo.","Chiudi");
        this.onSelectProgetto();
      },
      err => {
        this.rotella.closeDialog();
        this.showError(err.error.message);
      }
    )
  }

  onChangeNote(event, element) {
    element.note = event.target.value;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
