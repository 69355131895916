import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, DateAdapter } from '@angular/material';
import { GestioneContrattiCollaborazioneModalService } from './gestione-contratti-collaborazione-modal.service';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';
import { ConfirmMessageComponent } from 'src/app/modals/confirm-message/confirm-message.component';
import { PianificazioneOrePerAttivitaModalService } from '../pianificazione-ore-per-attivita-modal/pianificazione-ore-per-attivita-modal.service';

export interface ContrattoWrapper {
  contratto: any;
  attivita: any[];
}

@Component({
  selector: 'app-gestione-contratti-collaborazione-modal',
  templateUrl: './gestione-contratti-collaborazione-modal.component.html',
  styleUrls: ['./gestione-contratti-collaborazione-modal.component.css']
})
export class GestioneContrattiCollaborazioneModalComponent implements OnInit {

  ANNOTAZIONI_MAX_LENGTH = 250;

  loadingComponent: boolean = true;
  displayedColumns = ['periodo', 'ore', 'costoOrario', 'importoLordoTotale', 'attivita', 'annotazioni', 'add/remove'];

  pianificazioneProgettoPersona = undefined;
  progetto = undefined;
  contratti: ContrattoWrapper[] = [];
  recordWbs = [];

  @Input() isChild: boolean;
  @Input() data: any;

  constructor(
    public dialog: MatDialog,
    private adapter: DateAdapter<any>,
    private gestioneContrattiCollaborazioneModalService: GestioneContrattiCollaborazioneModalService,
    private pianificazioneOrePerAttivitaModalService: PianificazioneOrePerAttivitaModalService,
    public thisDialogRef: MatDialogRef<GestioneContrattiCollaborazioneModalComponent>,
    public snackBar: MatSnackBar,
  ) {
    this.adapter.setLocale("it");
  }

  /**
   * Carica i contratti di collaborazione dal database.
   * @param idPianificazioneProgettoPersona Id della pianificazione progetto-persona di riferimento.
   * @param _callback Azione successiva.
   */
  loadContratti(idPianificazioneProgettoPersona, _callback) : void {
    this.gestioneContrattiCollaborazioneModalService.getContratti(idPianificazioneProgettoPersona).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le attività proposte in pianificazione.
   * @param idProgetto Id del progetto di riferimento.
   * @param idSedeStakeholder Id della sede stakeholder di riferimento.
   * @param _callback Azione successiva.
   */
  loadAttivitaInWbs(idProgetto, idSedeStakeholder, _callback) : void {
    this.pianificazioneOrePerAttivitaModalService.getAttivita(idProgetto, idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica gli OR/WP proposti in pianificazione.
   * @param idProgetto Id del progetto di riferimento.
   * @param idSedeStakeholder Id della sede stakeholder di riferimento.
   * @param _callback Azione successiva.
   */
  loadOrWpInWbs(idProgetto, idSedeStakeholder, _callback) : void {
    this.pianificazioneOrePerAttivitaModalService.getOrWP(idProgetto, idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica dal database le attività di contratto di collaborazione.
   * @param idContrattoDiCollaborazione Id del contratto di collaborazione.
   * @param _callback Azione successiva.
   */
  loadAttivitaContratto(idContrattoDiCollaborazione, _callback) : void {
    this.gestioneContrattiCollaborazioneModalService.getAttivitaContratto(idContrattoDiCollaborazione).subscribe(
      data => { _callback(data); },
      err => { _callback(undefined); }
    );
  }

  /**
   * Salva sul database un'attività di contratto di collaborazione.
   * @param attivitaContrattoDiCollaborazione Attività di contratto di collaborazione da salvare.
   * @param _callback Azione successiva.
   */
  saveAttivitaContratto(attivitaContrattoDiCollaborazione, _callback) : void {
    this.gestioneContrattiCollaborazioneModalService.saveAttivitaContratto(attivitaContrattoDiCollaborazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva un contratto di collaborazione sul database.
   * @param index Indice nella lista di riferimento. Serve per tenere traccia dell'indice posizionale
   *              nella lista di provenienza del contratto che si sta salvando, per poter effettuare un'azione a fine lista.
   * @param contratto Contratto da salvare.
   * @param _callback Azione successiva.
   */
  saveContratto(index, contratto, _callback) : void {
    this.gestioneContrattiCollaborazioneModalService.saveContratto(contratto).subscribe(
      data => { _callback(data, index); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Rimuove tutti i contratti di collaborazione.
   * @param idPianificazioneProgettoPersona Id della pianificazione progetto-persona di riferimento.
   * @param _callback Azione successiva.
   */
  removeContratti(idPianificazioneProgettoPersona, _callback) : void {
    this.gestioneContrattiCollaborazioneModalService.removeContratti(idPianificazioneProgettoPersona).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva tutti i contratti in tabella sul database.
   */
  onSaveAll() : void {
    this.removeContratti(this.pianificazioneProgettoPersona.id, (deleteResult) => {
      if(deleteResult != undefined && deleteResult === true) {

        this.contratti.forEach((contratto, i) => {
          this.saveContratto(i, contratto.contratto, (contrattoSaved, index) => {
            contratto.attivita.forEach(attivita => {
              attivita.contrattoDiCollaborazionePianificato = contrattoSaved;
              this.saveAttivitaContratto(attivita, (attivitaSaved) => {});
            });
            if(index === this.contratti.length - 1) {
              this.openSnackBar("Contratti di collaborazione salvati per " + this.pianificazioneProgettoPersona.persona.cognome + " " +
                this.pianificazioneProgettoPersona.persona.nome + " sul progetto " + this.progetto.nome + ".", "Chiudi");
              this.thisDialogRef.close(true);
            }
          });
        });
        
      }
    });
  }

  /**
   * Aggiunge un nuovo contratto in tabella.
   */
  onAddContratto() : void {
    this.contratti.push({
      contratto: { dataInizio: undefined, dataFine: undefined, ore: 0, costoOrario: 0, importoLordoTotale: 0, pianificazioneProgettoPersona: this.pianificazioneProgettoPersona },
      attivita: []
    });
    this.contratti = this.contratti.concat([]);
  }

  /**
   * Elimina il contratto dalla tabella.
   * @param contratto Contratto da eliminare.
   */
  onRemoveContratto(contratto) : void {
    const index = this.contratti.indexOf(contratto);
    if(index !== -1) this.contratti.splice(index, 1);
    this.contratti = this.contratti.concat([]);
  }

  /**
   * Aggiunge un'attività di contratto di collaborazione.
   * @param event Evento scatenato.
   * @param contratto Contratto di riferimento.
   * @param attivita Attività di riferimento.
   */
  onAddAttivitaContratto(event, contratto: ContrattoWrapper, attivita) : void {
    event.preventDefault();
    event.stopPropagation();
    const flag: boolean = this.progetto.bando.confLivelloCostiInWbs;
    contratto.attivita.push({ contrattoDiCollaborazionePianificato: contratto.contratto,
      recordWbsPrimoLivello: flag === true ? undefined : attivita, recordWbsSecondoLivello: flag === true ? attivita : undefined });
  }

  /**
   * Rimuove un'attività di contratto di collaborazione.
   * @param event Evento scatenato.
   * @param contratto Contratto di riferimento.
   * @param attivita Attività di riferimento.
   */
  onRemoveAttivitaContratto(event, contratto: ContrattoWrapper, attivita) : void {
    event.preventDefault();
    event.stopPropagation();
    const flag: boolean = this.progetto.bando.confLivelloCostiInWbs;
    if(flag === true) contratto.attivita.splice(contratto.attivita.indexOf(contratto.attivita.find(function(found) { return found.recordWbsSecondoLivello.id === attivita.id; })), 1);
    else contratto.attivita.splice(contratto.attivita.indexOf(contratto.attivita.find(function(found) { return found.recordWbsPrimoLivello.id === attivita.id; })), 1);
  }

  /**
   * Gestisce l'evento di check/uncheck di un'attività di contratto di collaborazione.
   * @param event Evento scatenato.
   * @param contratto Contratto di riferimento.
   * @param attivita Attività di riferimento.
   */
  onAttivitaContrattoToggle(event, contratto, attivita) : void {
    if(this.isAttivitaContrattoChecked(contratto, attivita) === true) this.onRemoveAttivitaContratto(event, contratto, attivita);
    else this.onAddAttivitaContratto(event, contratto, attivita);
  }

  /**
   * Gestisce l'evento di cambiamento di una data (inizio o fine).
   * @param mode Indicatore della data considerata. Può essere "inizio" o "fine".
   * @param event Evento scatenato.
   */
  onDateChange(mode: string, event, element) : void {
    if(mode === "inizio") {
      element.dataInizio = event.value;
    }
    else if(mode === "fine") {
      element.dataFine = event.value;
    }
  }

  /**
   * Verifica se è possibile salvare. Controlla il datasource dei contratti e ritorna TRUE se non vi sono errori, FALSE altrimenti.
   */
  isSavingPossible() : boolean {
    if(this.contratti == undefined || this.contratti.length === 0) {
      return false;
    }
    var flag = true;
    this.contratti.forEach(contratto => {
      if(contratto.contratto.dataInizio == undefined || contratto.contratto.dataFine == undefined) { flag = false; return; }
      if(contratto.contratto.costoOrario != undefined && +(contratto.contratto.costoOrario) < 0) { flag = false; return; }
      if(contratto.contratto.ore != undefined && +(contratto.contratto.ore) < 0) { flag = false; return; }
      if(contratto.contratto.annotazioni != undefined && contratto.contratto.annotazioni.length > this.ANNOTAZIONI_MAX_LENGTH) { flag = false; return; }
      if(contratto.contratto.importoLordoTotale != undefined && +(contratto.contratto.importoLordoTotale) < 0) { flag = false; return; }
      if(contratto.contratto.ore == undefined && (contratto.contratto.costoOrario == undefined || contratto.contratto.importoLordoTotale == undefined)) { flag = false; return; }
      if(contratto.contratto.costoOrario == undefined && (contratto.contratto.ore == undefined || contratto.contratto.importoLordoTotale == undefined)) { flag = false; return; }
      if(contratto.contratto.importoLordoTotale == undefined && (contratto.contratto.costoOrario == undefined || contratto.contratto.ore == undefined)) { flag = false; return; }
    });
    return flag;
  }

  /**
   * Restituisce TRUE se è presente in lista un'attività contratto di collaborazione corrispondente, FALSE altrimenti.
   * @param contratto Contratto di riferimento.
   * @param attivita Attività di riferimento.
   */
  isAttivitaContrattoChecked(contratto: ContrattoWrapper, attivita) : boolean {
    const flag: boolean = this.progetto.bando.confLivelloCostiInWbs;
    if(flag === true) return contratto.attivita.find(found => found.recordWbsSecondoLivello.id === attivita.id) != undefined;
    else return contratto.attivita.find(found => found.recordWbsPrimoLivello.id === attivita.id) != undefined;
  }

  /**
   * Inizializza i dati dal database.
   * @param _callback Azione successiva.
   */
  initDataFromDatabase(_callback) : void {
    if(this.pianificazioneProgettoPersona == undefined) _callback();
    else {
      this.loadContratti(this.pianificazioneProgettoPersona.id, (contrattiRef) => {
        if(contrattiRef != undefined) {
          contrattiRef.forEach((contrattoRef) => {
            this.initContratto(contrattoRef, () => { this.contratti = this.contratti.concat([]); });
          });
        }
      });
      if(this.progetto.bando.confLivelloCostiInWbs === true) {
        this.loadAttivitaInWbs(this.progetto.id, this.pianificazioneProgettoPersona.sedeStakeholder.id, (attivitaRef) => {
          this.recordWbs = attivitaRef;
        });
      }
      else {
        this.loadOrWpInWbs(this.progetto.id, this.pianificazioneProgettoPersona.sedeStakeholder.id, (orWpRef) => {
          this.recordWbs = orWpRef;
        });
      }
      _callback();
    }
  }

  /**
   * Metodo privato di supporto all'inizializzazione dei contratti
   * @param contrattoRef Oggeto contratto proveniente da back-end.
   * @param _callback Azione successiva.
   */
  private initContratto(contrattoRef, _callback) : void {
    contrattoRef.dataInizio = new Date(contrattoRef.dataInizio);
    contrattoRef.dataFine = new Date(contrattoRef.dataFine);
    this.loadAttivitaContratto(contrattoRef.id, (attivitaRef) => {
      this.contratti.push({
        contratto: contrattoRef,
        attivita: attivitaRef != undefined ? attivitaRef : []
      });
      _callback();
    });
  }

  /**
   * Inizializza i dati dal parent.
   */
  initDataFromParent() : void {
    const item = sessionStorage.getItem("gestione_contratti_collaborazione_pianificazione_progetto_persona");
    if(item == undefined) return;
    this.pianificazioneProgettoPersona = JSON.parse(item);
    this.progetto = this.pianificazioneProgettoPersona.progetto;
    sessionStorage.removeItem("gestione_contratti_collaborazione_pianificazione_progetto_persona");
  }

  /**
   * Inizializza i dati dal dialog parent se chiamato come figlio.
   */
  initDataFromParentDialog() : void {
    const dataRef = JSON.parse(this.data);
    this.progetto = dataRef.progetto;
    this.contratti = dataRef.contratti.concat([]);
    this.contratti.forEach(contrattoWrapper => {
      contrattoWrapper.contratto.dataInizio = new Date(contrattoWrapper.contratto.dataInizio);
      contrattoWrapper.contratto.dataFine = new Date(contrattoWrapper.contratto.dataFine);
    })
    this.recordWbs = dataRef.recordWbs.concat([]);
  }

  /**
   * Mostra una notifica all'utente.
   * @param message Messaggio da mostrare.
   * @param action Azione da permettere all'utente.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    if(this.isChild == undefined || this.isChild === false) {
      this.initDataFromParent();
      this.initDataFromDatabase(() => {
        this.loadingComponent = false;
      });
    }
    else {
      this.initDataFromParentDialog();
      this.loadingComponent = false;
    }
  }

}
