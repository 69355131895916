import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-partner-utente-addlist-modal',
  templateUrl: './partner-utente-addlist-modal.component.html',
  styleUrls: ['./partner-utente-addlist-modal.component.css']
})
export class PartnerUtenteAddlistModalComponent implements OnInit {

  public available = [];
  public selectedAvailable = [];
  public query: string = "";
  constructor(public dialogRef: MatDialogRef<PartnerUtenteAddlistModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.available = this.data.available;
    this.selectedAvailable = this.data.available.map(e => { return { ...e, selected: false }; });

  }

  onSelectElement(el) {
    el.selected = !el.selected;
  }
  addSelectedElements() {
    const clearedSelected = this.selectedAvailable.filter(el => el.selected).map(el => {
      delete el.selected;
      return el;
    });
    console.log(clearedSelected);

    this.data.cb(clearedSelected);

  }
  ngOnInit() {


  }

}


/**
 *     this.loadPersone(this.progetto.organizzazione.id, (personeRef) => {
      var tmp = [];
      personeRef.forEach(personaRef => {
        if (this.dataSource.find(found => found.persona.id === personaRef.id) == undefined) tmp.push(personaRef)
      });
      if (tmp.length === 0) this.showError("Tutti i referenti disponibili sono stati importati.");
      else {
        sessionStorage.setItem("definizione_partner_progetto", JSON.stringify(this.progetto.id));
        const dialogRef = this.dialog.open(AggiuntaNuovoReferenteModalComponent, {
          data: tmp
        });
        dialogRef.afterClosed().subscribe(result => {

          this.aggiornaPersone(this.progetto.id);
        });
      }
    });

 */