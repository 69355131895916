import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GestioneStakeholdersService {

  constructor(private http: HttpClient) {}

  public getStakeholders(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'stakeholders/get-all-by-organizzazione?organizzazioneId='+idOrganizzazione);
  }

  public getSedi(idStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'sedi-stakeholders/get-all-by-stakeholder-id?idStakeholder='+idStakeholder);
  }

  public getPersonale(idSedeStakeholder, idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'persone/get-all-by-sede-stakeholder-id-and-organizzazione-id?sedeStakeholderId='+idSedeStakeholder+'&organizzazioneId='+idOrganizzazione);
  }

  public getProgettiStakeholder(): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'progetto-stakeholders/get-all');
  }

  public getEliminableFlag(idSedeStakeholder): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'sedi-stakeholders/get-eliminazione-possibile-flag?idSedeStakeholder='+idSedeStakeholder);
  }

  public getEliminablePersonaFlag(idPersona): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'persone/get-eliminazione-persona-possibile-flag?idPersona='+idPersona);
  }

  public saveStakeholder(stakeholder : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'stakeholders/save-stakeholder', stakeholder);
  }

  public updateStakeholder(stakeholder : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'stakeholders/update-stakeholder', stakeholder);
  }

  public deleteStakeholder(denominazione, idOrganizzazione): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'stakeholders/delete-stakeholder?denominazioneStakeholder='+denominazione+'&idOrganizzazione='+idOrganizzazione, null);
  }

  public savePersona(persona : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'persone/save-persona', persona);
  }

  public updatePersona(persona : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'persone/update-persona', persona);
  }

  public deletePersona(persona : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'persone/delete-persona', persona);
  }

  public saveSedeStakeholder(sedeStakeholder : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'sedi-stakeholders/save-sede-stakeholder', sedeStakeholder);
  }

  public updateSedeStakeholder(sedeStakeholder : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'sedi-stakeholders/update-sede-stakeholder', sedeStakeholder);
  }

  public deleteSedeStakeholder(nome, idStakeholder): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'sedi-stakeholders/delete-sede-stakeholder?nome='+nome+'&idStakeholder='+idStakeholder, null);
  }

  public saveCurriculum(filename, idPersona): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'api/allocazione-personale/curriculum_to_dip', {"filename": filename, "persona_id": idPersona});
  }

  public putAllocazionePersonale(idProgetto): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'api/allocazione-personale/find-allocazione', {"id_progetto": idProgetto});
  }

  public getAllocazionePersonale(idProgetto, idStakeholder): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'api/allocazione-personale/get-by-id', {"id_progetto": idProgetto, "id_stakholder": idStakeholder});
  }

}
