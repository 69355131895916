import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../../../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DettaglioGestionePartnerProgettoModalService {

  constructor(private http: HttpClient) {}

  public updateProgettoPartner(wrapper : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'progetto-stakeholders/update-progetto-stakeholder', wrapper);
  }

  public riabilitaProgettoPartner(wrapper : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'progetto-stakeholders/riabilita-progetto-stakeholder', wrapper);
  }

  public getAllByProgettoId(progettoId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'progetto-stakeholders/get-all-by-progetto-id?idProgetto=' + progettoId);
  }
  public getAllByProgettoIdNOVincoli(progettoId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL+'progetto-stakeholders/get-all-by-progetto-id-no-vincoli?idProgetto=' + progettoId);
  }
}
