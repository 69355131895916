import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class UtilsService {

  constructor(private http: HttpClient) {}

  cutZeroFromString(inputvalue: string|number):string{
    const temp_without_zero =(+inputvalue).toFixed(2).replace(/(\.0+|0+)$/, ''); //replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1');
    return temp_without_zero;
  }
  openLink(url: string) {
    let lowerCaseUrl : string = url.toLowerCase();
    if ((lowerCaseUrl.indexOf("http://") < 0) && (lowerCaseUrl.indexOf("https://") < 0)) {
      lowerCaseUrl = "http://" + lowerCaseUrl;
    }
    window.open(lowerCaseUrl, "_blank");
  }
  sleep(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  parseTitologia(tipologia:"B"|"P"|"C"): string{
    switch (tipologia){
      case "B":
        return "Bozza";

      case "P":
        return "Presentata"

      case "C":
        return "Certificata";

      default:
        return ""
    }


  }
  convertMillisecondToTimestamp(mills:number):string{
    //console.log(mills);
    let date = new Date(mills);
    let timestamp = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
    //console.log(timestamp);
    return  timestamp;
  }
  waitForElement(selector:string) {
    return new Promise((resolve: (value: HTMLElement) => void, reject) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}
  createFilename(nomeProgetto: string, nomeIdentificativo: string, estensione: string):string{
    return nomeProgetto + "-" + nomeIdentificativo + "-"
    + new Date().getFullYear() + (new Date().getMonth() < 10 ? "0" : "") + (new Date().getMonth() + 1) + (new Date().getDate() < 10 ? "0" : "") + new Date().getDate()
    + "-" + (new Date().getHours() < 10 ? "0" : "") + new Date().getHours() + (new Date().getMinutes() < 10 ? "0" : "") + new Date().getMinutes()
    + estensione;
  }
}
