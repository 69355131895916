import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DettaglioOreModalComponent } from './modals/dettaglio-ore-modal/dettaglio-ore-modal.component';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { DettaglioCostoOrarioModalComponent } from './modals/dettaglio-costo-orario-modal/dettaglio-costo-orario-modal.component';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { PianificazioneCostiService } from './pianificazione-costi.service';
import { GestioneContrattiCollaborazioneModalComponent } from './modals/gestione-contratti-collaborazione-modal/gestione-contratti-collaborazione-modal.component';
import { GestioneContrattiCollaborazioneModalService } from './modals/gestione-contratti-collaborazione-modal/gestione-contratti-collaborazione-modal.service';
import { AuthService } from '../core_modules/auth.service';
import { GestionePersonaModalComponent } from '../gestione-stakeholders/modals/gestione-persona-modal/gestione-persona-modal.component';
import { GestioneStakeholdersService } from '../gestione-stakeholders/gestione-stakeholders.service';

//Costanti di gestione date picker mese/anno
const moment = _rollupMoment || _moment;

//Costante di formato data per date picker mese/anno
export const PERIOD_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface CollaborazioneFlag {
  idProgetto: number;
  collaborazione: boolean;
}

//Elemento di tabella
export interface TableElement {
  id: Number;
  cognome: String;
  nome: String;
  qualifica: String;
  mansione: String;
  progetti: any;
  selection: SelectionModel<any>;
  filters: any[];
  personaObject: any;
  collaborazioni: CollaborazioneFlag[];
}

@Component({
  selector: 'app-pianificazione-costi',
  templateUrl: './pianificazione-costi.component.html',
  styleUrls: ['./pianificazione-costi.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: PERIOD_DATE_FORMATS},
  ]
})
export class PianificazioneCostiComponent implements OnInit {

  //Ogetto organizzazione
  organizzazione = undefined;

  //Liste di dati
  stakeholders = [];
  vociDiCosto = [];
  sedi = [];
  progetti = [];
  sal = [];

  //Wrappers
  costoOrarioWrapper = undefined;
  oreWrapper = undefined;
  oreAltreAttivitaWrapper = undefined;

  //Variabili di controllo date
  date1 = new FormControl(moment());
  date2 = new FormControl(moment());

  //Campi scelti
  selectedStakeholder = undefined;
  selectedSedeStakeholder = undefined;
  selectedVoceDiCosto = undefined;
  selectedSalFilter = undefined;

  //Colonne mostrate in tabella (segnaposto)
  displayedColumns: string[] = ['alert', 'cognome', 'nome', 'fittizia', 'progetti', 'contratti', 'dettaglio_ore', 'elimina'];

  //Colonne mostrate in tabella progetti
  progettiDisplayedColumns: string[] = ['nome_progetto', 'dettaglio_costo_orario'];

  //DataSource della tabella
  tableDataSource: TableElement[] = [];

  //Indicatore del nome della voce di costo per spese di personale
  speseDiPersonaleStaticName = "Spese di personale";

  /**
   * Metodo di gestione per anno scelto date picker 1
   * @param normalizedYear anno normalizzato immesso
   */
  chosenYearHandler(normalizedYear: _moment.Moment) {
    this.selectedSalFilter = undefined;
    const ctrlValue = this.date1.value;
    ctrlValue.year(normalizedYear.year());
    this.date1.setValue(ctrlValue);
    this.refreshWrappers(() => {});
  }

  /**
   * Metodo di gestione per mese scelto date picker 1
   * @param normalizedMonth mese normalizzato immesso
   * @param datepicker date picker
   */
  chosenMonthHandler(normalizedMonth: _moment.Moment, datepicker: MatDatepicker<_moment.Moment>) {
    this.selectedSalFilter = undefined;
    const ctrlValue = this.date1.value;
    ctrlValue.month(normalizedMonth.month());
    this.date1.setValue(ctrlValue);
    datepicker.close();
    this.refreshWrappers(() => {});
  }

  /**
   * Metodo di gestione per anno scelto date picker 2
   * @param normalizedYear anno normalizzato immesso
   */
  chosenYearHandler2(normalizedYear: _moment.Moment) {
    this.selectedSalFilter = undefined;
    const ctrlValue = this.date2.value;
    ctrlValue.year(normalizedYear.year());
    this.date2.setValue(ctrlValue);
    this.refreshWrappers(() => {});
  }

  /**
   * Metodo di gestione per mese scelto date picker 2
   * @param normalizedMonth mese normalizzato immesso
   * @param datepicker date picker
   */
  chosenMonthHandler2(normalizedMonth: _moment.Moment, datepicker: MatDatepicker<_moment.Moment>) {
    this.selectedSalFilter = undefined;
    const ctrlValue = this.date2.value;
    ctrlValue.month(normalizedMonth.month());
    this.date2.setValue(ctrlValue);
    datepicker.close();
    this.refreshWrappers(() => {});
  }

  constructor(
    public dialog: MatDialog,
    private adapter: DateAdapter<any>,
    public snackBar: MatSnackBar,
    private dettaglioOreDialog : MatDialog,
    private authService: AuthService,
    private dettaglioCostoOrarioDialog : MatDialog,
    private gestioneContrattiCollaborazioneDialog : MatDialog,
    private pianificazioneCostiService : PianificazioneCostiService,
    private gestioneContrattiCollaborazioneModalService: GestioneContrattiCollaborazioneModalService,
    public gestionePersonaModalDialog: MatDialog,
    private gestioneStakeholdersService: GestioneStakeholdersService,
    public confirmDialog: MatDialog,
  ) {
    this.adapter.setLocale("it");
  }

  /**
   * Carica l'organizzazione.
   * @param nome Nome dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadOrganizzazione(nome, _callback) : void {
    this.pianificazioneCostiService.getOrganizzazione(nome).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica gli stakeholders dal database.
   * @param idOrganizzazione Id dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadStakeholders(idOrganizzazione, _callback) : void {
    this.pianificazioneCostiService.getStakeholders(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le sedi dello stakeholder dato.
   * @param idStakeholder Id dello stakeholder.
   * @param _callback Azione successiva.
   */
  loadSediStakeholder(idStakeholder, _callback) : void {
    this.pianificazioneCostiService.getSediStakeholder(idStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica tutte le voci di costo nel database.
   * @param _callback Azione successiva.
   */
  loadVociDiCosto(_callback) : void {
    this.pianificazioneCostiService.getVociDiCosto(this.organizzazione.id).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica tutti i progetti di uno stakeholder.
   * @param idStakeholder Id dello stakeholder.
   * @param _callback Azione successiva.
   */
  loadProgetti(idStakeholder, _callback) : void {
    this.pianificazioneCostiService.getProgetti(idStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica tutti i piani di sal di un progetto.
   * @param idProgetto Id del progetto.
   * @param _callback Azione successiva.
   */
  loadPianoSal(idProgetto, _callback) : void {
    this.pianificazioneCostiService.getLatestVersionPianoDeiSalByIdProg(idProgetto).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica tutti i sal di un piano dei sal.
   * @param idPiano Id del paino.
   * @param _callback Azione successiva.
   */
  loadListaSal(idPiano, _callback) : void {
    this.pianificazioneCostiService.getListaSalByPianoId(idPiano).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica tutto il personale associato ad una sede stakeholder.
   * @param idSedeStakeholder Id della sede stakeholder.
   * @param idOrganizzazione Id dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadPersonale(idSedeStakeholder, idOrganizzazione, _callback) : void {
    this.pianificazioneCostiService.getPersone(idSedeStakeholder, idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica il wrapper del costo orario del personale associato ad uno stakeholder e ad una sede.
   * @param idStakeholder Id dello stakeholder.
   * @param idSedeStakeholder Id della sede di riferimento.
   * @param _callback Azione successiva.
   */
  loadCostoOrarioWrapper(idStakeholder, idSedeStakeholder, _callback) : void {
    this.pianificazioneCostiService.getCostoOrarioPersonaleWrapper(idStakeholder, idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica il wrapper delle ore del personale associato ad uno stakeholder e ad una sede.
   * @param idStakeholder Id dello stakeholder.
   * @param idSedeStakeholder Id della sede di riferimento.
   * @param _callback Azione successiva.
   */
  loadOreWrapper(idStakeholder, idSedeStakeholder, _callback) : void {
    this.pianificazioneCostiService.getOrePersonaleWrapper(idStakeholder, idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica il wrapper delle ore del personale delle altre attività associato ad uno stakeholder e ad una sede.
   * @param idStakeholder Id dello stakeholder.
   * @param idSedeStakeholder Id della sede di riferimento.
   * @param _callback Azione successiva.
   */
  loadOreAltreAttivitaWrapper(idStakeholder, idSedeStakeholder, _callback) : void {
    this.pianificazioneCostiService.getOrePersonaleAltreAttivitaWrapper(idStakeholder, idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica un oggetto progetto-persona.
   * @param idProgetto Id del progetto considerato.
   * @param idPersona Id della persona considerata.
   * @param _callback Azione successiva.
   */
  loadProgettoPersona(idProgetto, idPersona, _callback) : void {
    this.pianificazioneCostiService.getProgettoPersona(idProgetto, idPersona).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le buste paga associate ad una persona.
   * @param idPersona Id della persona considerata.
   * @param _callback Azione successiva.
   */
  loadBustePaga(idPersona, _callback) : void {
    this.pianificazioneCostiService.getBustePaga(idPersona).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica i contratti di collaborazione.
   * @param idPianificazioneProgettoPersona Id della pianificazione progettto-persona di riferimento; 
   * @param _callback Azione successiva.
   */
  loadContratti(idPianificazioneProgettoPersona, _callback) : void {
    this.gestioneContrattiCollaborazioneModalService.getContratti(idPianificazioneProgettoPersona).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Rimuove tutti i contratti di collaborazione.
   * @param idPianificazioneProgettoPersona Id della pianificazione progetto-persona di riferimento.
   * @param _callback Azione successiva.
   */
  removeContratti(idPianificazioneProgettoPersona, _callback) : void {
    this.gestioneContrattiCollaborazioneModalService.removeContratti(idPianificazioneProgettoPersona).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Salva una nuova persona sul database.
   * @param persona Persona da salvare.
   * @param _callback Azione successiva.
   */
  savePersona(persona, _callback) : void {
    this.gestioneStakeholdersService.savePersona(persona).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Aggiunge il personale in tabella a partire da una lista di oggetti wrapper.
   * @param personale Lista di oggetti persona.
   */
  addPersonaleToTable(personale) : void {
    personale.forEach(persona => {
      this.tableDataSource.push({
        id: persona.id,
        cognome: persona.cognome,
        nome: persona.nome,
        qualifica: undefined,
        mansione: undefined,
        progetti: this.progetti.concat([]),
        selection: new SelectionModel<any>(true, []),
        filters: [],
        personaObject: persona,
        collaborazioni: this.getNewCollaborazioneArray(this.progetti),
      })
    });
  }

  /**
   * Aggiunge alla persona la spunta relativa ai progetti per i quali è stata precedentemente effettuata una pianificazione (anche nulla).
   */
  addProgettiSelectionToPersonale() : void {
    this.tableDataSource.forEach(persona => {
      persona.selection.clear();
      this.oreWrapper.forEach(wrapper => {
        if(wrapper.pianificazioneProgettoPersona.persona.id === persona.id &&
          this.progetti.filter(progettoFiltered => progettoFiltered.id === wrapper.pianificazioneProgettoPersona.progetto.id).length > 0) {
            persona.selection.select(this.progetti.find(progettoFind => progettoFind.id === wrapper.pianificazioneProgettoPersona.progetto.id));
            this.loadContratti(wrapper.pianificazioneProgettoPersona.id, (contrattiRef) => {
              if(contrattiRef != undefined && contrattiRef.length > 0) {
                const collaborazioneFlag = this.getCollaborazioneFlag(persona, wrapper.pianificazioneProgettoPersona.progetto.id);
                persona.collaborazioni[persona.collaborazioni.indexOf(collaborazioneFlag)] = {idProgetto: wrapper.pianificazioneProgettoPersona.progetto.id, collaborazione: true};
              }
            });
          }
      })
    });
  }

  /**
   * Aggiunge i filtri all'elemento di tabella inferiore.
   * @param element Elemento di tabella inferiore.
   */
  addFiltersToElement(element: TableElement) : void {
    element.filters = [this.selectedStakeholder, this.selectedSedeStakeholder, this.selectedVoceDiCosto, this.date1.value, this.date2.value];
  }

  /**
   * Restituisce il nome del mese dato il numero (da 1 a 12, compresi).
   * @param numero Numero del mese.
   */
  getNomeMeseFromNumero(numero: number) : string {
    const mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto',
                  'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    if(numero < 1 || numero > 12) return 'Nessun mese';
    return mesi[numero - 1];
  }

  /**
   * Restituisce un nuovo array di flag di collaborazione tutte inizializzate a false.
   * @param progetti Lista progetti di riferimento.
   */
  getNewCollaborazioneArray(progetti) : CollaborazioneFlag[] {
    var res: CollaborazioneFlag[] = [];
    this.progetti.forEach(progetto => { res.push({idProgetto: progetto.id, collaborazione: false}); });
    return res;
  }

  /**
   * Restituisce un flag di collaborazione.
   * @param element Elemento in tabella.
   * @param idProgetto Id del progetto di riferimento.
   */
  getCollaborazioneFlag(element: TableElement, idProgetto) : CollaborazioneFlag {
    return element.collaborazioni.find(found => found.idProgetto === idProgetto);
  }

  /**
   * Restituisce true se esistono più elementi in tabella con stesso nome e cognome dell'elemento considerato.
   * @param element Elemento in tabella.
   */
  isPersonaDuplicata(element: TableElement) : boolean {
    return this.tableDataSource.filter(function(filtered) {return filtered.nome === element.nome && filtered.cognome === element.cognome}).length > 1;
  }

  /**
   * Inizializza i dati necessari ai filtri.
   */
  initFiltersData() : void {
    this.loadStakeholders(this.organizzazione.id, (stakeholders) => {
      this.stakeholders = stakeholders.concat([]);
    });
  }

  initSal(): void {
    this.sal = [];
    this.selectedSalFilter = undefined;
    // Per ogni progetto relativo allo stakeholder
    this.progetti.forEach(progetto => {
      // Prendo l'ultimo piano dei SAL
      this.loadPianoSal(progetto.id, (pianoSal) => {
        if (pianoSal && pianoSal.id) {
          // Per ogni SAL nel piano
          this.loadListaSal(pianoSal.id, (listaSalPiano) => {
            // Prendo la lista dei wrapper SAL
            const listaSalWrapper = listaSalPiano && listaSalPiano.salWrapperList ? listaSalPiano.salWrapperList : [];
            listaSalWrapper.forEach((salWrapper) => {
              const wrapperSalDatePartnerList = salWrapper && salWrapper.salDatePartnerList ? salWrapper.salDatePartnerList : [];
              // Filtro solo i SAL riferiti al partner selezionato
              const filteredWrapperSalDatePartnerList = wrapperSalDatePartnerList.filter((salPartnerWithDateFiltered) => {
                return salPartnerWithDateFiltered.sal && salPartnerWithDateFiltered.stakeholder &&
                salPartnerWithDateFiltered.stakeholder.id == this.selectedStakeholder.id
              });
              // Aggiungo alla lista SAL per fare il fitro
              filteredWrapperSalDatePartnerList.forEach((salPartnerWithDate) => {
                this.sal.push(salPartnerWithDate);
              });
            });
          });
        }
      });
    });
  }

  /**
   * Gestisce l'evento di selezione di uno stakeholder.
   */
  onSelectStakeholder() : void {
    this.selectedSedeStakeholder = undefined;
    this.selectedVoceDiCosto = undefined;
    this.progetti = undefined;
    this.loadSediStakeholder(this.selectedStakeholder.id, (result) => {
      this.sedi = result;
    });
    this.loadVociDiCosto((result) => {
      this.vociDiCosto = result;
    });
    this.loadProgetti(this.selectedStakeholder.id, (result) => {
      this.progetti = result.concat([]);
      this.tableDataSource = [];
      this.initSal();      
    });
  }

  onSelectSalFilter() : void {
    const dataInizioSal = moment(this.selectedSalFilter.dataInizio);
    const dataFineSal = moment(this.selectedSalFilter.dataFine);

    this.date1.setValue(dataInizioSal);
    this.date2.setValue(dataFineSal);
  }

  /**
   * Gestisce l'evento di selezione di una sede stakeholder.
   */
  onSelectSedeStakeholder() : void {
    this.loadPersonale(this.selectedSedeStakeholder.id, this.organizzazione.id, (personaleResult) => {
      this.tableDataSource = [];
      this.addPersonaleToTable(personaleResult);
      this.loadCostoOrarioWrapper(this.selectedStakeholder.id, this.selectedSedeStakeholder.id, (wrapper) => {
        this.costoOrarioWrapper = wrapper;
        this.loadOreWrapper(this.selectedStakeholder.id, this.selectedSedeStakeholder.id, (oreWrapperResult) => {
          this.oreWrapper = oreWrapperResult;
          this.addProgettiSelectionToPersonale();
          this.loadOreAltreAttivitaWrapper(this.selectedStakeholder.id, this.selectedSedeStakeholder.id, (oreWrapperAltreAttivitaResult) => {
            this.oreAltreAttivitaWrapper = oreWrapperAltreAttivitaResult;
          });
        });
      });
    });
  }

  /**
   * Gestisce l'evento di selezione/deselezione di un progetto nella tabella inferiore (check-box).
   * @param event Evento scatenato.
   * @param element Elemento della tabella inferiore.
   * @param progetto Progetto selezionato/deselezionato.
   */
  onProgettoToggle(event, element, progetto) : void {
    event.preventDefault();
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: "Vuoi davvero " + (element.selection.isSelected(progetto) ? "deselezionare" : "selezionare") + " il progetto?",
        submessage: "La " + (element.selection.isSelected(progetto) ? "deselezione" : "selezione") +
                    " comporterà l'" + (element.selection.isSelected(progetto) ? "eliminazione" : "aggiunta") +
                    " del progetto per l'elemento considerato."
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        /**Se l'utente reagisce positivamente. */
        if(res==true) {
          if(!element.selection.isSelected(progetto)) {
            this.savePianificazioneProgettoPersona(element, progetto, () => {
              element.selection.toggle(progetto);
              this.refreshWrappers(() => {});
            });
          }
          else {
            this.deleteOnCascade({
              progetto: progetto, persona: element.personaObject, sedeStakeholder: this.selectedSedeStakeholder
            }, (result) => {
              if(result != undefined && result === true) {
                element.selection.toggle(progetto);
                const collaborazioneFlag = this.getCollaborazioneFlag(element, progetto.id);
                element.collaborazioni[element.collaborazioni.indexOf(collaborazioneFlag)] = {idProgetto: progetto.id, collaborazione: false};
                this.openSnackBar(element.personaObject.cognome+" "+element.personaObject.nome+" è stato/a scollegato/a dal progetto "+progetto.nome+".", "Chiudi");
              }
            });
          }
        }
      }
    );
  }

  /**
   * Gestisce l'evento di apertura del dialog di dettaglio costo orario.
   * @param element Elemento della tabella.
   */
  onDettaglioCostoOrario(element) : void {
    this.tableDataSource.forEach(e => {this.addFiltersToElement(e)});

    var dataSourceToRetrieve = [];
    this.tableDataSource.forEach(tableElement => {
      if(tableElement.selection.selected.find(found => found.id === element.id)) {
        if(this.getCollaborazioneFlag(tableElement, element.id).collaborazione === false) {
          dataSourceToRetrieve.push(tableElement);
        }
      }
    });

    if(dataSourceToRetrieve.length === 0) {
      this.showError("Nessun elemento di personale dipendente per il progetto " + element.nome + ".");
    }
    else {
      const dettaglioCostoOrarioRef = this.dettaglioCostoOrarioDialog.open(DettaglioCostoOrarioModalComponent, {
        data : {
          progetto: element,
          sedeStakeholder: this.selectedSedeStakeholder,
          dataSource: dataSourceToRetrieve,
          wrapper: this.costoOrarioWrapper,
          pianificazioneProgettiWrapper: this.oreWrapper,
          pianificazioneAltreAttivitaWrapper: this.oreAltreAttivitaWrapper
        }
      });

      dettaglioCostoOrarioRef.afterClosed().subscribe(
        result => {
          this.refreshWrappers(() => {

          });
        },
        err => { this.showError(err.error.message); }
      );
    }
  }

  /**
   * Gestisce l'evento di apertura del dialog di pianificazione ore.
   * @param element Elemento della tabella.
   */
  onDettaglioOre(element: TableElement, firstTimeFlag) : void {
    this.addFiltersToElement(element);

    this.loadBustePaga(element.personaObject.id, (bustePaga) => {
      const dettaglioOreRef = this.dettaglioOreDialog.open(DettaglioOreModalComponent, {
        data : { element: element, wrapper: this.oreWrapper, altreAttivitaWrapper: this.oreAltreAttivitaWrapper, bustePaga: bustePaga, costiOrariAnnuali: this.costoOrarioWrapper, firstTimeFlag: firstTimeFlag }
      });
  
      dettaglioOreRef.afterClosed().subscribe(
        result => {
          this.refreshWrappers(() => {
            if(result) {
              this.openSnackBar("Pianificazione oraria mensile per " + element.cognome + " " + element.nome + " salvata con successo.", "Chiudi");
              this.onDettaglioOre(element, false);
            }
          });
        },
        err => { this.showError(err.error.message); }
      );
    });

  }

  /**
   * Salva la relazione pianificazione progetto-persona sul database.
   * @param tableElement Elemento di tabella inferiore considerato.
   * @param progetto Progetto considerato.
   * @param _callback Azione successiva.
   */
  onSavePianificazioneProgettoPersona(tableElement: TableElement, progetto, _callback) : void {
    this.pianificazioneCostiService.savePianificazioneProgettoPersona({
      progetto: progetto, persona: tableElement.personaObject,
      sedeStakeholder: this.selectedSedeStakeholder
    }).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Gestisce l'evento di rimozione di un elemento dalla tabella.
   * @param element Elemento da rimuovere.
   */
  onRemoveElement(element: TableElement) : void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: "Vuoi davvero eliminare " + element.cognome + " " + element.nome + " dalla pianificazione?",
        submessage: "Una volta eliminata, la persona non potrà più far parte della pianificazione dei costi di personale."
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        /**Se l'utente reagisce positivamente. */
        if(res==true) {
          var sub = this.tableDataSource.slice(0,this.tableDataSource.indexOf(element)).concat(this.tableDataSource.slice(this.tableDataSource.indexOf(element) + 1, this.tableDataSource.length));
          this.tableDataSource = sub.concat([]);
        }
      }
    );
  }

  /**
   * Gestisce l'evento di selezione/deseleziona proprietà di collaborazione per un progetto su una persona. Apre la maschera di aggiunta nuovo contratto
   * di collaborazione.
   * @param event Evento da gestire.
   * @param element Elemento in tabella.
   * @param progetto Progetto di riferimento.
   */
  onContrattoToggle(event, element: TableElement, progetto) : void {
    event.preventDefault();
    var pianificazioneProgettoPersonaRef = undefined;
    this.oreWrapper.forEach(wrapper => {
      if(wrapper.pianificazioneProgettoPersona.persona.id === element.personaObject.id && wrapper.pianificazioneProgettoPersona.progetto.id === progetto.id) {
          pianificazioneProgettoPersonaRef = wrapper.pianificazioneProgettoPersona;
          return;
        }
    });
    if(pianificazioneProgettoPersonaRef == undefined) {
      this.showError("Errore nel caricamento della pianificazione associata. Riprovare.");
      return;
    }
    if(this.getCollaborazioneFlag(element, progetto.id).collaborazione === false) {
      sessionStorage.setItem("gestione_contratti_collaborazione_pianificazione_progetto_persona", JSON.stringify(pianificazioneProgettoPersonaRef));
      const gestioneContrattiCollaborazioneDialogRef = this.gestioneContrattiCollaborazioneDialog.open(GestioneContrattiCollaborazioneModalComponent);
      gestioneContrattiCollaborazioneDialogRef.afterClosed().subscribe(
        result => {
          if(result != undefined) {
            if(result === true) {
              const collaborazioneFlag = this.getCollaborazioneFlag(element, progetto.id);
              element.collaborazioni[element.collaborazioni.indexOf(collaborazioneFlag)] = {idProgetto: progetto.id, collaborazione: true};
            }
          }
        },
        err => { this.showError(err.error.message); }
      );
    }
    else {
      this.askConfirm("Vuoi davvero deselezionare l'indicatore di collaborazione?", "La deselezione comporterà l'eliminazione di tutti i contratti di collaborazione associati.", () => {
        this.removeContratti(pianificazioneProgettoPersonaRef.id, (deleteResult) => {
          const collaborazioneFlag = this.getCollaborazioneFlag(element, progetto.id);
          element.collaborazioni[element.collaborazioni.indexOf(collaborazioneFlag)] = {idProgetto: progetto.id, collaborazione: false};
        });
      });
    }
  }

  /**
   * Apre la maschera di gestione contratti di collaborazione per la pianificazione progetto-persona associata.
   * @param element Elemento in tabella.
   * @param progetto Progetto di riferimento.
   */
  onGestioneContrattiCollaborazione(element: TableElement, progetto) : void {
    var pianificazioneProgettoPersonaRef = undefined;
    this.oreWrapper.forEach(wrapper => {
      if(wrapper.pianificazioneProgettoPersona.persona.id === element.personaObject.id && wrapper.pianificazioneProgettoPersona.progetto.id === progetto.id) {
          pianificazioneProgettoPersonaRef = wrapper.pianificazioneProgettoPersona;
          return;
        }
    });
    if(pianificazioneProgettoPersonaRef == undefined) {
      this.showError("Errore nel caricamento della pianificazione associata. Riprovare.");
      return;
    }
    sessionStorage.setItem("gestione_contratti_collaborazione_pianificazione_progetto_persona", JSON.stringify(pianificazioneProgettoPersonaRef));
      const gestioneContrattiCollaborazioneDialogRef = this.gestioneContrattiCollaborazioneDialog.open(GestioneContrattiCollaborazioneModalComponent);
      gestioneContrattiCollaborazioneDialogRef.afterClosed().subscribe(
        result => {
        },
        err => { this.showError(err.error.message); }
      );
  }

  /**
   * Gestisce l'evento di aggiunta di una nuova persona al personale da pianificare.
   */
  onAddPersona() : void {
    const gestionePersonaModalRef = this.gestionePersonaModalDialog.open(GestionePersonaModalComponent);
    gestionePersonaModalRef.afterClosed().subscribe(result => {
      if(result != undefined) {
        result.sedeStakeholder = this.selectedSedeStakeholder;
        result.organizzazione = this.organizzazione;
        var errorMessage = undefined;
        if(result.fittizia === true && this.tableDataSource != undefined) {
          this.tableDataSource.forEach(element => {
            if(element.personaObject.fittizia === true && element.personaObject.cognome === result.cognome && element.personaObject.nome === result.nome) {
              errorMessage = "Impossibile memorizzare una persona con nome e cognome fittizi già usati.";
              return;
            }
          });
        }
        if(errorMessage != undefined) this.showError(errorMessage);
        else {
          this.askConfirm("Vuoi davvero salvare la persona?", "Il salvataggio comporterà l'inserimento della persona nel personale della sede selezionata.", () => {
            this.savePersona(result, () => {
              this.openSnackBar(result.cognome + " " + result.nome + " aggiunto/a al personale della sede selezionata.", "Chiudi");
              this.onSelectSedeStakeholder();
            });
          });
        }
      }
    });
  }

  /**
   * Aggiorna i wrappers successivamente ad una modifica nelle informazioni sul database.
   */
  refreshWrappers(_callback) : void {
    this.loadCostoOrarioWrapper(this.selectedStakeholder.id, this.selectedSedeStakeholder.id, (wrapper) => {
      this.costoOrarioWrapper = wrapper;
      this.loadOreWrapper(this.selectedStakeholder.id, this.selectedSedeStakeholder.id, (oreWrapperResult) => {
        this.oreWrapper = oreWrapperResult;
        this.addProgettiSelectionToPersonale();
        this.loadOreAltreAttivitaWrapper(this.selectedStakeholder.id, this.selectedSedeStakeholder.id, (oreWrapperAltreAttivitaResult) => {
          this.oreAltreAttivitaWrapper = oreWrapperAltreAttivitaResult;
          _callback();
        });
      });
    });
  }

  /**
   * Salva o aggiorna il record pianificazione progetto-persona nel database.
   * @param tableElement Elemento di tabella inferiore considerato.
   * @param progetto Progetto considerato.
   * @param _callback Azione successiva.
   */
  savePianificazioneProgettoPersona(tableElement: TableElement, progetto, _callback) : void {
    this.onSavePianificazioneProgettoPersona(tableElement, progetto, (saved) => {
      if(saved)
        this.openSnackBar(saved.persona.cognome+" "+saved.persona.nome+" è stato/a associato/a al progetto "+saved.progetto.nome+".", "Chiudi");
      _callback();
    });
  }

  /**
   * Elimina una pianificazione progetto persona a cascata, eliminando anche tutti i dati ad essa collegata.
   * @param pianificazioneProgettoPersona Pianificazione da eliminare.
   * @param _callback Azione successiva.
   */
  deleteOnCascade(pianificazioneProgettoPersona, _callback) : void {
    this.pianificazioneCostiService.deleteOnCascade(pianificazioneProgettoPersona).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Mostra un messaggio di richiesta di conferma all'utente.
   * @param message Messaggio di richiesta di conferma.
   * @param submessage Messaggio inferiore.
   * @param _callback Azione successiva, in caso di conferma della richiesta.
   */
  askConfirm(message: string, submessage: string, _callback) : void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent,{
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if(res) _callback();
      }
    );
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String) : void {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  /**
   * Mostra una notifica all'utente.
   * @param message Messaggio da mostrare.
   * @param action Azione da permettere all'utente.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      this.loadOrganizzazione(sessionStorage.getItem("Organization"), (organizzazioneResult) => {
        this.organizzazione = organizzazioneResult;
        if(this.organizzazione) {
          this.initFiltersData();
        }
        else {
          this.showError("Errore nel caricamento dell'organizzazione. Impossibile proseguire.");
        }
      });
    });
  }

}