import { Component, Inject, Injectable, Input } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.css']
})
export class WarningMessageComponent{

  @Input()
  message: string;

  constructor(public dialogRef: MatDialogRef<WarningMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
