import { Component, OnInit } from '@angular/core';
import { MonitoraggioDeliverablesProgettoService } from './monitoraggio-deliverables-progetto.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDatepickerInputEvent, MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';

@Component({
  selector: 'app-monitoraggio-deliverables-progetto',
  templateUrl: './monitoraggio-deliverables-progetto.component.html',
  styleUrls: ['./monitoraggio-deliverables-progetto.component.css']
})
export class MonitoraggioDeliverablesProgettoComponent implements OnInit {

  private isComponentLoading : Boolean = true;

  private progetto = undefined;
  private deliverables = undefined;
  private username = "";

  private dataSource = [];
  private tableColumns = ['or/wp', 'attivita', 'responsabili', 'nome', 'descrizione', 'percentuale_completamento', 'consegnato', 'data_consegna'];
  private selection = new SelectionModel<any>(true, []);

  constructor(
    public snackBar: MatSnackBar,
    public errorDialog: MatDialog,
    public dialogRef: MatDialogRef<MonitoraggioDeliverablesProgettoComponent>,
    private monitoraggioDeliverablesProgettoService: MonitoraggioDeliverablesProgettoService,
  ) { }

  /**
   * Metodo da evento. Salva tutti i deliverable presenti nel datasource sul database.
   */
  onSave() : void {
    for(var i in this.dataSource) {
      var deliverable = this.dataSource[i].deliverable;
      if(deliverable.consegnato) {
        deliverable.percentualeCompletamento = 100;
        if(!deliverable.dataConsegna) {
          deliverable.dataConsegna = new Date();
        }
      }
      else {
        deliverable.dataConsegna = undefined;
      }
      this.saveDeliverable(deliverable, () => {
        if(this.dataSource[this.dataSource.length - 1].deliverable.id == deliverable.id) {
          /**Se è stato salvato l'ultimo deliverable nel datasource. */
          this.openSnackBar("Deliverables salvate con successo.", "Chiudi");
          this.dialogRef.close();
        }
      });
    }
  }

  /**
   * Metodo da evento. Gestisce la selezione di un elemento in tabella.
   * @param element elemento in tabella
   */
  onElementSelect(element) : void {
    element.deliverable.consegnato = !element.deliverable.consegnato;
    if(element.deliverable.consegnato) {
      element.deliverable.percentualeCompletamento = 100;
    }
    else {
      element.deliverable.dataConsegna = undefined;
    }
    this.selection.toggle(element);
  }

  onDateChange(element, event: MatDatepickerInputEvent<Date>) : void {
    for(var i in this.dataSource) {
      if(this.dataSource[i].deliverable.id == element.deliverable.id) {
        this.dataSource[i].deliverable.dataConsegna = event.value;
      }
    }
    this.refreshTable(() => {});
  }

  /**
   * Inizializza la tabella.
   * @param _callback azione successiva
   */
  initTable(_callback) : void {
    this.dataSource = this.dataSource.concat(this.deliverables);
    _callback();
  }

  /**
   * Ricarica la lista degli elementi selezionati.
   * @param _callback azione successiva
   */
  refreshSelection(_callback) : void {
    this.selection.clear();
    for(var i in this.dataSource) {
      if(this.dataSource[i].deliverable.consegnato) {
        this.selection.toggle(this.dataSource[i]);
      }
    }
    _callback();
  }

  /**
   * Ricarica la tabella.
   * @param _callback azione successiva
   */
  refreshTable(_callback) : void {
    this.dataSource = this.dataSource.concat([]);
    this.refreshSelection(() => { _callback(); })
  }

  /**
   * Carica i dati dal session storage.
   * @param _callback azione successiva
   */
  loadDataFromSessionStorage(_callback) : void {
    const progettoFromSessionStorage = JSON.parse(sessionStorage.getItem("progetto"));
    const roleFromSessionStorage = JSON.parse(sessionStorage.getItem("Role"));
    this.username = sessionStorage.getItem("Email");
    sessionStorage.removeItem("progetto");
    if(progettoFromSessionStorage == undefined) {
      this.showError("Errore nel caricamento del progetto. Riprovare.");
    }
    else {
      this.progetto = progettoFromSessionStorage;
      _callback();
    }
  }

  /**
   * Carica i deliverable dal database dato l'id di progetto.
   * @param idProgetto id di progetto per cui caricare i dati
   * @param _callback azione successiva
   */
  loadDeliverablesFromProgettoId(idProgetto, username, _callback) : void {
    this.monitoraggioDeliverablesProgettoService.getAllDeliverableWrappersFromProgettoId(idProgetto, username).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica tutti i dati dal database.
   * @param _callback azione successiva
   */
  loadDataFromDatabase(_callback) : void {
    this.loadDeliverablesFromProgettoId(this.progetto.id, this.username, (deliverables) => {
      this.deliverables = deliverables;
      _callback();
    });
  }

  /**
   * Verifica se un elemento in tabella è scaduto o meno (data di fine di or/wp o attività <= di data odierna).
   * @param element elemento in tabella
   */
  isScaduto(element) : Boolean {
    if(element.recordWbsSecondoLivello) {
      if(element.recordWbsSecondoLivello.dataFine) {
        return new Date(element.recordWbsSecondoLivello.dataFine) <= new Date();
      }
    }
    if(element.recordWbsPrimoLivello.dataFine){
      return new Date(element.recordWbsPrimoLivello.dataFine) <= new Date();
    }
    return false;
  }

  /**
   * Restituisce la data di consegna del deliverable (se non presente, restituisce la data odierna).
   * @param element elemento in tabella
   */
  getStartDate(element) : Date {
    if(element.deliverable.dataConsegna) {
      return new Date(element.deliverable.dataConsegna);
    }
    return new Date();
  }

  /**
   * Salva il singolo deliverable nel database.
   * @param deliverable deliverable da salvare
   * @param _callback azione successiva
   */
  saveDeliverable(deliverable, _callback) {
    this.monitoraggioDeliverablesProgettoService.updateDeliverable(deliverable).subscribe(
      data => { _callback(); },
      err => { this.showError(err.error.message); }
    )
  }

  /**
   * Mostra un errore all'utente.
   * @param error messaggio di errore
   */
  showError(error: String) : void {
    this.errorDialog.open(WarningMessageComponent,{
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  /**
   * Mostra un messaggio all'utente.
   * @param message messaggio
   * @param action azione da permettere all'utente
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  ngOnInit() {
    /**Carico i dati dal session storage. */
    this.loadDataFromSessionStorage(() => {
      console.log("Data loaded from session storage.", this.progetto);
      /**Carico i dati dal database. */
      this.loadDataFromDatabase(() => {
        console.log("Data loaded from database.",  this.deliverables);
        /**Inizializzo la tabella. */
        this.initTable(() => {
          console.log("Data loaded in table.", this.dataSource);
          /**Ricarico la tabella per mostrarla all'utente. */
          this.refreshTable(() => {
            console.log("Table refreshed correctly.");
            /**Interrompo il caricamento del component. */
            this.isComponentLoading = false;
          })
        })
      })
    })
  }

}
