import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {
  }

  attemptAuth(username: string, password: string): Observable<any> {
    const credentials = {username: username, password: password};
    console.log("credentials >>> " + credentials);
    return this.http.post<any>(AppSettings.BASE_URL+'token/generate-token', credentials);
  }


  getRoles(): Observable<any> {

    return this.http.get<any>(AppSettings.BASE_URL+'roles/get-all');
  }

  checkLoggedUser(_callback) : void {
    if (sessionStorage.getItem("AuthToken") == undefined) {
      window.location.replace(window.location.origin);
    }
    else {
      _callback();
    }
  }
  
}
