import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { AppSettings } from '../app.settings';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class GenerazioneReportDialogService {

  constructor(private http: HttpClient) {}

  public generateXlsxReport(requestWrapper : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'file-generator/generate-xlsx-report', requestWrapper);
  }

  public generatePdfReport(requestWrapper : any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL+'file-generator/generate-pdf-report', requestWrapper);
  }

  public generatePngReport(requestWrapper : any): Observable<any> {

    return this.http.post<any>(AppSettings.BASE_URL+'file-generator/generate-png-report', requestWrapper);
  }

  public generateJsonReport(requestWrapper : any) {
    switch(requestWrapper.reportType) {
      case "PIANO_COSTI_AMMISSIBILI":
        window.open(AppSettings.BASE_URL+'piani-di-costo/piano-dei-costi-ammissibili/get-latest-version-wrapper-by-progetto-id-and-wbs-id?progettoId='+requestWrapper.ids[0]+'&wbsId='+requestWrapper.ids[1]);
        break;
      default: break;
    }
  }

  public downloadFile(fileName, extension) {
    window.open(AppSettings.BASE_URL+'file-downloader/download?fileName='+fileName+"&extension="+extension);
  }

}
