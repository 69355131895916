import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-utility-module',
  templateUrl: './utility-module.component.html',
  styleUrls: ['./utility-module.component.css']
})
export class UtilityModuleComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  redirectSuperUser(newPage) {
    console.log(newPage);
    switch (newPage) {
      case "dashboard":
        this.router.navigate(['./admin-dashboard']);
        break;

      case "organizzazioni":
        this.router.navigate(['./organizzazioni']);
        break;

      case "utenti-amministratori":
        this.router.navigate(['./utenti-organizzazioni']);
        break;

      case "impostazioni-profilo":
        this.router.navigate(['./impostazioni-profilo']);
        break;
    }
  }

  redirectPartner(newPage) {
    switch (newPage) {
      case "monitoraggio/progetti":
        this.router.navigate(['./monitoraggio/progetti']);
        break;

      case "reportistica/progetti":
        this.router.navigate(['./reportistica/progetti-partener']);
        break;
      case "partner-dashboard":
        this.router.navigate(['./partner-dashboard']);
        break;

      case "smart-report":
        this.router.navigate(['./partner-smart-report']);
        break;
    }
  }

  redirectAdminOrganizzazione(newPage) {
    console.log(newPage);
    switch (newPage) {
      case "dashboard":
        this.router.navigate(['./admin-organizzazione-dashboard']);
        break;

      case "login":
        this.router.navigate(['./login']);
        break;

      case "utenti":
        this.router.navigate(['./utenti-organizzazione']);
        break;

      case "impostazioni-profilo":
        this.router.navigate(['./impostazioni-profilo']);
        break;

      case "progetti/importa":
        this.router.navigate(['./progetti/importa']);
        break;

      case "progetti":
        this.router.navigate(['./progetti']);
        break;

      case "configurazioni/bandi":
        this.router.navigate(['./configurazioni/bandi']);
        break;

      case "pianificazioni":
        this.router.navigate(['./pianificazioni']);
        break;

      case "monitoraggio/progetti":
        this.router.navigate(['./monitoraggio/progetti']);
        break;

      case "reportistica/progetti":
        this.router.navigate(['./reportistica/progetti']);
        break;
      case "rendicontazioni":
        this.router.navigate(['./rendicontazioni']);
        break;

      case "impostazioni":
        this.router.navigate(['./impostazioni']);
        break;

      case "configurazione-personale-dipendente":
        this.router.navigate(['./configurazione-personale-dipendente']);
        break;

      case "configurazioni/stakeholders":
        this.router.navigate(['./configurazioni/stakeholders']);
        break;

      case "monitoraggio/rischi":
        this.router.navigate(['./monitoraggio/rischi']);
        break;

      case "monitoraggio/documenti":
        this.router.navigate(['./monitoraggio/documenti']);
        break;

      case "apikey":
        this.router.navigate(["./apikey"]);
        break;

      case "smart-report":
        this.router.navigate(['./partner-smart-report']);
        break;

      case "smart-allocation":
        this.router.navigate(['./smart-allocation']);
        break;
    }
  }

}
