import {Component, OnInit} from '@angular/core';
import {GestionePianiDeiCostiService} from './gestione-piani-dei-costi.service';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {ConfirmMessageComponent} from '../modals/confirm-message/confirm-message.component';
import {WarningMessageComponent} from '../modals/warning-message/warning-message.component';
import {ConfiguraCostiPersonaleModalComponent} from './modals/configura-costi-personale-modal/configura-costi-personale-modal.component';
import {DettaglioCostiPersonaleModalComponent} from './modals/dettaglio-costi-personale-modal/dettaglio-costi-personale-modal.component';
import {GestioneWbsService} from '../gestione-wbs/gestione-wbs.service';
import {DettaglioCostiConsulenzeModalComponent} from './modals/dettaglio-costi-consulenze-modal/dettaglio-costi-consulenze-modal.component';
import {GestioneBeniComponent} from '../gestione-beni/gestione-beni.component';
import {DettaglioCostiBeniModalComponent} from './modals/dettaglio-costi-beni-modal/dettaglio-costi-beni-modal.component';
import {PartnerInScadenzaComponent, StackHolderInScadenza} from "../partner-in-scadenza/partner-in-scadenza.component";
import {StoricoWbsComponent} from "../gestione-wbs/modals/storico-wbs/storico-wbs.component";
import {StoricoPianoCostiComponent} from "./storico-piano-costi/storico-piano-costi.component";

export interface OuterNode {
  sedeStakeholder: any;
  data: InnerNode[];
  expanded: boolean;
  flagFasceConfigurate: boolean;
}

export interface InnerNode {
  record: any;
  elementi: any[];
  dettagli: any[];
}

@Component({
  selector: 'app-gestione-piani-dei-costi',
  templateUrl: './gestione-piani-dei-costi.component.html',
  styleUrls: ['./gestione-piani-dei-costi.component.css']
})
export class GestionePianiDeiCostiComponent implements OnInit {

  DESCRIZIONE_MAX_LENGTH = 250;
  ANNOTAZIONI_MAX_LENGTH = 250;

  loadingComponent: boolean = true;

  dataSource: OuterNode[] = [];
  vociDiCosto = [];
  stakeholders = [];

  vociDiCostoPartecipantiCalcoloSpeseGenerali = [];
  vociDiCostoConsulenze = ['Servizi di consulenza', 'Ric. contrattuale, conoscenza e brevetti'];
  vociDiCostoBeni = ['Strumentazioni e attrezzature', 'Locazione finanziaria', 'Immobili e terreni', 'Immobili e impianti', 'Terreni', 'Beni immateriali'];

  progetto = undefined;

  wbs = undefined;
  piano = undefined;

  rimodulato: boolean = false;
  rimodulatoDisabled: boolean = false;
  dataInizioValidita = new Date();
  descrizione: string = undefined;

  inscadenza: StackHolderInScadenza[] = [];

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private gestioneWbsService: GestioneWbsService,
    private gestionePianiDeiCostiService: GestionePianiDeiCostiService,
    private configuraCostiPersonaleDialog: MatDialog,
    private dettaglioCostiPersonaleDialog: MatDialog,
    private dettaglioCostiConsulenzeDialog: MatDialog,
    private dettaglioCostiBeniDialog: MatDialog,
    private gestioneBeniDialog: MatDialog,
    private thisDialogRef: MatDialogRef<GestionePianiDeiCostiComponent>,
    public confirmDialog: MatDialog,
    private partnerInScadenzaComponent: PartnerInScadenzaComponent,
  ) {
  }

  /**
   * Carica le fasce non standard già configurate dal database.
   * @param idProgetto Id del progetto di riferimento.
   * @param idSedeStakeholder Id della sede stakeholder di riferimento.
   * @param _callback Azione successiva.
   */
  loadFasceNonStandardConfigurate(idProgetto, idSedeStakeholder, _callback): void {
    this.gestionePianiDeiCostiService.getFasceNonStandardConfigurate(idProgetto, idSedeStakeholder).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    )
  }

  /**
   * Carica l'ultima versione di WBS dal database.
   * @param nomeProgetto Nome del progetto di riferimento.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadWbs(nomeProgetto, idOrganizzazione, _callback): void {
    this.gestioneWbsService.getUltimaWbs(nomeProgetto, idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica tutte le sedi di tutti i partner di progetto.
   * @param idProgetto Id del progetto di riferimento.
   * @param _callback Azione successiva.
   */
  loadSediStakeholder(idProgetto, _callback): void {
    this.gestionePianiDeiCostiService.getSediStakeholder(idProgetto).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica tutte le voci di costo previste dal bando di riferimento.
   * @param idBando Id del bando di riferimento.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadVociDiCosto(idBando, idOrganizzazione, _callback): void {
    this.gestionePianiDeiCostiService.getVociDiCosto(idBando, idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica le voci di costo che partecipano al calcolo delle spese generali, in base al bando
   * di riferimento.
   * @param idBando Id del bando di riferimento
   * @param _callback Azione successiva.
   */
  loadVociDiCostoPartecipantiCalcoloSpeseGenerali(idBando, _callback): void {
    this.gestionePianiDeiCostiService.getVociDiCostoPartecipantiSpeseGenerali(idBando).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica tutti gli stakeholder memorizzati nel database.
   * @param idOrganizzazione Id dell'organizzazione.
   * @param _callback Azione successiva.
   */
  loadStakeholders(idOrganizzazione, _callback): void {
    this.gestionePianiDeiCostiService.getStakeholders(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica l'ultima versione del piano dei costi dal database.
   * @param idProgetto Id del progetto di riferimento.
   * @param idWbs Id della WBS di riferimento.
   * @param _callback Azione successiva.
   */
  loadPiano(idProgetto, idWbs, _callback): void {
    this.gestionePianiDeiCostiService.getUltimaVersionePianoByProgettoIdAndWbsId(idProgetto, idWbs).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Salva una nuova versione di piano dei costi tramite un wrapper per il back-end.
   * @param wrapper Wrapper per il back-end da salvare.
   * @param _callback Azione successiva.
   */
  savePiano(wrapper, _callback): void {
    this.loadingComponent = true;
    this.gestionePianiDeiCostiService.savePianoFromWrapper(wrapper).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Apre il dialog di definizione dei dettagli del costo di personale.
   * @param sedeStakeholder Sede dello stakeholder di riferimento.
   * @param innerNode Nodo interno del piano dei costi (nodo legato al record di WBS).
   * @param elemento Elemento di costo ammissibile di spese di personale.
   */
  onDettaglioCostiPersonale(sedeStakeholder, innerNode: InnerNode, elemento): void {
    sessionStorage.setItem("dettaglio_costi_personale_progetto", JSON.stringify(this.progetto));
    sessionStorage.setItem("dettaglio_costi_personale_sede_stakeholder", JSON.stringify(sedeStakeholder));
    sessionStorage.setItem("dettaglio_costi_personale_node", JSON.stringify(innerNode));
    sessionStorage.setItem("dettaglio_costi_personale_dettagli", JSON.stringify(this.getDettagliPersonale(innerNode.dettagli)));

    const dettaglioCostiPersonaleDialogRef = this.dettaglioCostiPersonaleDialog.open(DettaglioCostiPersonaleModalComponent);
    dettaglioCostiPersonaleDialogRef.afterClosed().subscribe(
      res => {
        if (res != undefined && res.length > 0) {
          const dettagliList = innerNode.dettagli.concat([]);
          const dettagliConsulenze = this.getDettagliConsulenze(dettagliList);
          innerNode.dettagli = dettagliConsulenze.concat([]);
          this.vociDiCosto.forEach(vc => {
            if (this.isVoceDiCostoRiferitaBeni(vc) === true) {
              const dettagliBeniRef = this.getDettagliBeni(dettagliList, vc);
              if (dettagliBeniRef != undefined) innerNode.dettagli = innerNode.dettagli.concat(dettagliBeniRef.concat([]));
            }
          });
          var tmp = 0;
          res.forEach(dett => {
            dett.elementoDiCostoAmmissibile = elemento;
            innerNode.dettagli.push(dett);
            tmp += +(dett.costoTotale);
          });
          elemento.costo = tmp;
          this.onSetSpeseGeneraliInPercentuale(innerNode);
        }
      }
    );
  }

  /**
   * Apre il dialog di definizione dei dettagli del costo di personale.
   * @param sedeStakeholder Sede dello stakeholder di riferimento.
   * @param innerNode Nodo interno del piano dei costi (nodo legato al record di WBS).
   * @param elemento Elemento di costo ammissibile di spese di personale.
   */
  onDettaglioCostiConsulenze(sedeStakeholder, innerNode: InnerNode, elemento): void {
    sessionStorage.setItem("dettaglio_costi_consulenze_organizzazione", JSON.stringify(this.progetto.organizzazione));
    sessionStorage.setItem("dettaglio_costi_consulenze_stakeholder_proprietario", JSON.stringify(sedeStakeholder.stakeholder));
    sessionStorage.setItem("dettaglio_costi_consulenze_dettagli", JSON.stringify(this.getDettagliConsulenzeVoceDiCosto(innerNode.dettagli, elemento.voceDiCosto)));

    console.log(sessionStorage.getItem("dettaglio_costi_consulenze_dettagli"));

    const dettaglioCostiConsulenzeDialogRef = this.dettaglioCostiConsulenzeDialog.open(DettaglioCostiConsulenzeModalComponent);
    dettaglioCostiConsulenzeDialogRef.afterClosed().subscribe(
      res => {
        if (res != undefined) {
          const dettagliList = innerNode.dettagli.concat([]);
          const dettagliPersonale = this.getDettagliPersonale(dettagliList);
          innerNode.dettagli = dettagliPersonale.concat([]);
          this.vociDiCosto.forEach(vc => {
            if (this.isVoceDiCostoRiferitaBeni(vc) === true) {
              const dettagliBeniRef = this.getDettagliBeni(dettagliList, vc);
              if (dettagliBeniRef != undefined) innerNode.dettagli = innerNode.dettagli.concat(dettagliBeniRef.concat([]));
            }
            if (this.isVoceDiCostoRiferitaConsulenze(vc) === true && vc.nome !== elemento.voceDiCosto.nome) {
              const dettagliConsulenzeRef = this.getDettagliConsulenzeVoceDiCosto(dettagliList, vc);
              if (dettagliConsulenzeRef != undefined) innerNode.dettagli = innerNode.dettagli.concat(dettagliConsulenzeRef.concat([]));
            }
          });

          var tmp = 0;
          res.forEach(dett => {
            dett.elementoDiCostoAmmissibile = elemento;
            innerNode.dettagli.push(dett);
            tmp += +(dett.costoTotale);
          });
          elemento.costo = tmp;
          this.onSetSpeseGeneraliInPercentuale(innerNode);
        }
      }
    );
  }

  /**
   * Apre il dialog di definizione dei dettagli dei costi di strumentazioni e attrezzature.
   * @param sedeStakeholder Sede dello stakeholder di riferimento.
   * @param innerNode Nodo interno del piano dei costi (nodo legato al record di WBS).
   * @param elemento Elemento di costo ammissibile di spese di personale.
   */
  onDettaglioCostiBeni(sedeStakeholder, innerNode: InnerNode, elemento): void {
    sessionStorage.setItem("dettaglio_costi_beni_data", JSON.stringify({
      progetto: this.progetto,
      sedeStakeholder: sedeStakeholder,
      voceDiCosto: elemento.voceDiCosto,
      dettagli: this.getDettagliBeni(innerNode.dettagli, elemento.voceDiCosto)
    }));

    const dettaglioCostiBeniDialogRef = this.dettaglioCostiBeniDialog.open(DettaglioCostiBeniModalComponent);
    dettaglioCostiBeniDialogRef.afterClosed().subscribe(
      res => {
        if (res != undefined) {
          const dettagliList = innerNode.dettagli.concat([]);
          const dettagliPersonale = this.getDettagliPersonale(dettagliList);
          const dettagliConsulenze = this.getDettagliConsulenze(dettagliList);
          innerNode.dettagli = dettagliPersonale.concat([]);
          innerNode.dettagli = innerNode.dettagli.concat(dettagliConsulenze.concat([]));
          this.vociDiCosto.forEach(vc => {
            if (this.isVoceDiCostoRiferitaBeni(vc) === true && vc.nome !== elemento.voceDiCosto.nome) {
              const dettagliBeniRef = this.getDettagliBeni(dettagliList, vc);
              if (dettagliBeniRef != undefined) innerNode.dettagli = innerNode.dettagli.concat(dettagliBeniRef.concat([]));
            }
          });
          var tmp = 0;
          res.forEach(dett => {
            dett.elementoDiCostoAmmissibile = elemento;
            innerNode.dettagli.push(dett);
            tmp += +(dett.costoTotale);
          });
          elemento.costo = tmp;
          this.onSetSpeseGeneraliInPercentuale(innerNode);
        }
      }
    );
  }

  /**
   * Apre il dialog di configurazione delle fasce di costo del personale.
   * @param outerNode Nodo esterno del piano dei costi (nodo legato alla sede stakeholder).
   */
  onConfiguraCostiPersonale(outerNode: OuterNode): void {
    sessionStorage.setItem("configura_costi_progetto", JSON.stringify(this.progetto));
    sessionStorage.setItem("configura_costi_node", JSON.stringify(outerNode));

    const configCostiPersonaleDialogRef = this.configuraCostiPersonaleDialog.open(ConfiguraCostiPersonaleModalComponent);
    configCostiPersonaleDialogRef.afterClosed().subscribe(
      res => {
        if (res != undefined && res === true) {
          outerNode.flagFasceConfigurate = true;
          this.openSnackBar("Fasce di costo del personale configurate per la sede " + outerNode.sedeStakeholder.nome +
            " di " + outerNode.sedeStakeholder.stakeholder.denominazione + ".", "Chiudi");
        }
      }
    );
  }

  /**
   * Espande o richiude un nodo del piano dei costi.
   * @param outerNode Nodo esterno del piano dei costi (nodo legato alla sede stakeholder).
   */
  onExpandOuterNode(outerNode: OuterNode): void {
    outerNode.expanded = !outerNode.expanded;
  }

  /**
   * Ricarica tutti i valori riportati in un nodo del piano dei costi.
   * @param outerNode Nodo esterno del piano dei costi (nodo legato alla sede stakeholder).
   */
  onRefresh(outerNode: OuterNode): void {
    outerNode.data.forEach(inner => {
      inner.dettagli = [];
      inner.elementi.forEach(e => {
        e.costo = 0;
        e.annotazioni = undefined;
      });
    });
  }

  /**
   * Setta il valore delle spese generali in percentuale, rispetto alle voci di costo che
   * partecipano al calcolo.
   * @param innerNode Nodo interno del piano dei costi (nodo legato al record di WBS).
   */
  onSetSpeseGeneraliInPercentuale(innerNode: InnerNode): void {
    const val = this.getSpeseGeneraliInPercentuale(innerNode);
    if (val != undefined) {
      var e = innerNode.elementi.find(found => found.voceDiCosto.nome === "Spese generali");
      if (e != undefined) e.costo = +val.toFixed(2);
    }
  }

  /**
   * Apre la maschera di gestione dei beni per la voce di costo di strumentazioni e attrezzature.
   * @param outerNode Nodo esterno del piano dei costi (nodo legato alla sede stakeholder).
   */
  onGestisciBeni(outerNode: OuterNode): void {
    sessionStorage.setItem("gestione_beni_data", JSON.stringify({
      progetto: this.progetto,
      sedeStakeholder: outerNode.sedeStakeholder
    }));

    const gestioneBeniDialogRef = this.gestioneBeniDialog.open(GestioneBeniComponent);
    gestioneBeniDialogRef.afterClosed().subscribe(
      res => {
      }
    );
  }

  /**
   * Metodo di supporto al calcolo delle spese generali.
   * Calcola il valore delle spese generali in percentuale, rispetto alle voci di costo che
   * partecipano al calcolo.
   * @param innerNode Nodo interno del piano dei costi (nodo legato al record di WBS).
   */
  private getSpeseGeneraliInPercentuale(innerNode: InnerNode): number {
    if (this.progetto.bando.tettoMaxSpeseGenerali != undefined) {
      var res = 0;
      this.vociDiCostoPartecipantiCalcoloSpeseGenerali.forEach(vc => {
        const e = innerNode.elementi.find(found => found.voceDiCosto.id === vc.id);
        if (e != undefined) res += +(e.costo);
      });
      return res * (+(this.progetto.bando.tettoMaxSpeseGenerali) / 100);
    } else return undefined;
  }

  /**
   * Metodo di supporto al salvataggio. Fornisce il wrapper della nuova versione di piano dei costi
   * da comunicare al back-end per il salvataggio.
   */
  private getWrapperToSave(): any {
    const piano = {
      id: undefined, versione: (this.piano != undefined ? this.piano.versione + 1 : 1),
      timestampCreazione: undefined, dataInizioValidita: this.dataInizioValidita, descrizione: this.descrizione,
      chiuso: false, rimodulato: this.rimodulato, progetto: this.progetto, wbs: this.wbs.master
    };
    var elementi = [];
    this.dataSource.forEach(outer => {
      outer.data.forEach(inner => {
        inner.elementi.forEach(elemento => {
          elementi.push({
            elemento: {
              id: undefined, costo: elemento.costo || 0, annotazioni: elemento.annotazioni, pianoDeiCostiAmmissibili: piano,
              sedeStakeholder: outer.sedeStakeholder, voceDiCosto: elemento.voceDiCosto,
              recordWbsPrimoLivello: (this.progetto.bando.confLivelloCostiInWbs === true) ? undefined : inner.record.record,
              recordWbsSecondoLivello: (this.progetto.bando.confLivelloCostiInWbs === true) ? inner.record.record : undefined
            },
            dettagli: elemento.voceDiCosto.nome === "Spese di personale" ? this.getDettagliPersonale(inner.dettagli) :
              this.isVoceDiCostoRiferitaConsulenze(elemento.voceDiCosto) ? this.getDettagliConsulenzeVoceDiCosto(inner.dettagli, elemento.voceDiCosto) :
                this.isVoceDiCostoRiferitaBeni(elemento.voceDiCosto) === true ? this.getDettagliBeni(inner.dettagli, elemento.voceDiCosto) : []
          });
        });
      });
    });
    return {piano: piano, elementi: elementi};
  }

  /**
   * Salva una nuova versione del piano di costi.
   */
  onSaveAll(): void {
    const errorMessage = this.getSavingErrorMessage();
    if (errorMessage != undefined) this.showError(errorMessage);
    else {
      this.openSnackBar("Salvataggio di una nuova versione di piano dei costi ammissibili in corso...", "Chiudi");
      const wrapper = this.getWrapperToSave();
      this.savePiano(wrapper, (res) => {
        this.thisDialogRef.close(res);
      });
    }
  }

  /**
   * Gestisce l'evento di cambio della data di inizio validità.
   * @param event Evento da gestire.
   */
  onDateChange(event): void {
    this.dataInizioValidita = event.value;
  }

  /**
   * Restituisce il totale dei costi ammissibili per un nodo del piano di costi.
   * @param outerNode Nodo esterno del piano dei costi (nodo legato alla sede stakeholder).
   */
  getTotale(outerNode: OuterNode): number {
    var res = 0;
    outerNode.data.forEach(inner => {
      inner.elementi.forEach(e => {
        res += +e.costo;
      });
    });
    return res;
  }

  /**
   * Preleva il totale per una singola voce di costo per un nodo del piano di costi.
   * @param voceDiCosto Voce di costo di riferimento.
   * @param outerNode Nodo esterno del piano dei costi (nodo legato alla sede stakeholder).
   */
  getTotaleForVoceDiCosto(voceDiCosto, outerNode: OuterNode): number {
    var res = 0;
    outerNode.data.forEach(inner => {
      inner.elementi.forEach(e => {
        if (e.voceDiCosto.id === voceDiCosto.id) res += +e.costo;
      });
    });
    return res;
  }

  /**
   * Restituisce tutti gli stakeholder consulenti.
   * @param outerNode Nodo esterno del piano dei costi (nodo legato alla sede stakeholder).
   */
  getStakeholdersConsulenti(outerNode: OuterNode): any[] {
    const index = this.stakeholders.indexOf(this.stakeholders.find(found => found.id === outerNode.sedeStakeholder.stakeholder.id));
    if (index == undefined) return this.stakeholders;
    const res = this.stakeholders.concat([]);
    res.splice(index, 1);
    return res;
  }

  /**
   * Restituisce la stringa da mostrare all'utente accanto al costo ammissibile di spese generali.
   */
  getIndicatoreCalcoloPercentualeString(): string {
    if (this.progetto.bando.tettoMaxSpeseGenerali == undefined || this.vociDiCostoPartecipantiCalcoloSpeseGenerali == undefined ||
      this.vociDiCostoPartecipantiCalcoloSpeseGenerali.length === 0) {
      return "";
    }
    var res = "";
    res = res + this.progetto.bando.tettoMaxSpeseGenerali.toString() + "% di ";
    this.vociDiCostoPartecipantiCalcoloSpeseGenerali.forEach((vc, i) => {
      res = res + vc.nome;
      if (i !== this.vociDiCostoPartecipantiCalcoloSpeseGenerali.length - 1) res = res + ", ";
    });
    res = res + ".";
    return res;
  }

  /**
   * Genera un messaggio di errore per impedire il salvataggio.
   * Se il risultato è UNDEFINED, si può proseguire col salvataggio.
   * Se il risultato è valorizzato, occorre impedire il salvataggio e mostrare l'errore.
   */
  private getSavingErrorMessage(): string {
    if (this.dataSource == undefined || this.dataSource.length === 0) return "Impossibile memorizzare un piano vuoto.";
    if (this.descrizione != undefined && this.descrizione.length > this.DESCRIZIONE_MAX_LENGTH) return "La descrizione inserita è troppo lunga.";
    if (this.rimodulato === true && this.dataInizioValidita == undefined) return "Impossibile memorizzare un piano rimodulato privo di data di inizio validità.";
    for (var i in this.dataSource) {
      const outerNode = this.dataSource[i];
      if (outerNode.data === undefined || outerNode.data.length === 0) return "Impossibile memorizzare un piano con nodi vuoti.";
      for (var j in outerNode.data) {
        const innerNode = outerNode.data[j];
        for (var z in innerNode.elementi) {
          const e = innerNode.elementi[z];
          if(e.costo === null) e.costo = 0;
          if (e.costo == undefined || isNaN(e.costo) || +(e.costo) < 0 || e.costo == null) return "Almeno uno dei costi è negativo o malformato.";
          if (e.annotazioni != undefined && e.annotazioni.length > this.ANNOTAZIONI_MAX_LENGTH) return "Le annotazioni inserite sono troppo lunghe.";
        }
      }
    }
    
    return undefined;
  }

  /**
   * Restituisce i dettagli riferiti al costo di personale.
   * @param dettagli Lista dettagli generale.
   */
  getDettagliPersonale(dettagli): any {
    return dettagli.filter(function (filtered) {
      return filtered.persDipFasciaCostoPersonaleStandardTipologiaStakeholder != undefined || filtered.persDipFasciaCostoPersonaleNonStandardProgettoStakeholder != undefined;
    });
  }

  /**
   * Restituisce i dettagli riferiti al costo di consulenze, in generale.
   * @param dettagli Lista dettagli generale.
   */
  getDettagliConsulenze(dettagli): any {
    return dettagli.filter(function (filtered) {
      return filtered.sedeStakeholderConsulente != undefined;
    });
  }

  /**
   * Restituisce i dettagli riferiti al costo di consulenze per una certa voce di costo.
   * @param dettagli Lista dettagli generale.
   */
  getDettagliConsulenzeVoceDiCosto(dettagli, voceDiCosto): any {
    const dettagliConsulenze = this.getDettagliConsulenze(dettagli);
    console.log(dettagliConsulenze);
    if (dettagliConsulenze == undefined || dettagliConsulenze.length === 0) return [];
    return dettagliConsulenze.filter(function (filtered) {
      return filtered.elementoDiCostoAmmissibile.voceDiCosto.id === voceDiCosto.id;
    });
  }

  /**
   * Restituisce i dettagli riferiti ai costi di strumentazioni e attrezzature.
   * @param dettagli Lista dettagli generale.
   * @param voceDiCosto Voce di costo.
   */
  getDettagliBeni(dettagli, voceDiCosto): any {
    return dettagli.filter(function (filtered) {
      return filtered.bene != undefined && filtered.elementoDiCostoAmmissibile.voceDiCosto.id === voceDiCosto.id;
    });
  }

  /**
   * Restituisce TRUE se la lista delle voci di costo contiene quella di Strumentazioni e attrezzature,
   * FALSE altrimenti.
   */
  isStrumentazioniAttrezzatureVoceDiCostoPresent(): boolean {
    return this.vociDiCosto.find(found => found.nome === 'Strumentazioni e attrezzature') != undefined;
  }

  /**
   * Restituisce TRUE se la voce di costo è riferita a beni, FALSE altrimenti.
   * @param voceDiCosto Voce di costo.
   */
  isVoceDiCostoRiferitaBeni(voceDiCosto): boolean {
    return this.vociDiCostoBeni.find(found => found === voceDiCosto.nome) != undefined;
  }

  /**
   * Restituisce TRUE se la voce di costo è riferita a consulenze, FALSE altrimenti.
   * @param voceDiCosto Voce di costo.
   */
  isVoceDiCostoRiferitaConsulenze(voceDiCosto): boolean {
    return this.vociDiCostoConsulenze.find(found => found === voceDiCosto.nome) != undefined;
  }

  /**
   * Inizializza i dati a partire da una precedente versione del piano dei costi (l'ultima salvata sul database).
   * @param _callback Azione successiva.
   */
  initDataFromPianoCosti(_callback): void {
    this.loadPiano(this.progetto.id, this.wbs.master.id, (pianoWrapper) => {
      if (pianoWrapper != undefined && pianoWrapper.piano != undefined && pianoWrapper.piano.id != undefined) {
        this.piano = pianoWrapper.piano;
        this.initDataFromPianoWrapper(pianoWrapper);
      }
      _callback();
    });
  }

  /**
   * Metodo di supporto per l'inizializzazione.
   * Inizializza i dati a partire dal wrapper di una precedente versione del piano dei costi (l'ultima salvata sul database).
   * @param pianoWrapper Wrapper proveniente dal back-end.
   */
  private initDataFromPianoWrapper(pianoWrapper): void {
    this.rimodulato = pianoWrapper.piano.rimodulato;
    this.rimodulatoDisabled = this.rimodulato === true ? true : false;
    this.descrizione = pianoWrapper.piano.descrizione;
    this.dataInizioValidita = pianoWrapper.piano.dataInizioValidita != undefined ? new Date(pianoWrapper.piano.dataInizioValidita) : new Date();
    if (pianoWrapper.elementi != undefined && pianoWrapper.elementi.length > 0) {
      pianoWrapper.elementi.forEach(elementoWrapper => {
        const elemento = elementoWrapper.elemento;
        if (elemento.recordWbsSecondoLivello || elemento.recordWbsPrimoLivello) {
          if (elemento == undefined) return;
          var outerNode = this.dataSource.find(found => found.sedeStakeholder.id === elemento.sedeStakeholder.id);
          if (outerNode == undefined) return;
          // alert("elemento.recordWbsPrimoLivello " + elemento.recordWbsPrimoLivello + " ,elemento.recordWbsSecondoLivello " + elemento.recordWbsSecondoLivello);
          // const recordId_livello2 = elemento.recordWbsSecondoLivello != undefined ? elemento.recordWbsSecondoLivello.id : null;
          const recordId = elemento.recordWbsPrimoLivello != undefined ? elemento.recordWbsPrimoLivello.id : elemento.recordWbsSecondoLivello;
          if (recordId == undefined) return;
          var innerNode = outerNode.data.find(innerFound => innerFound.record.record.id === recordId);
          if (innerNode == undefined) return;
          var elementoData = innerNode.elementi.find(elementoFound => elementoFound.voceDiCosto.id === elemento.voceDiCosto.id);
          if (elementoData == undefined) return;
          innerNode.elementi[innerNode.elementi.indexOf(elementoData)] = elemento;
          if (elementoWrapper.dettagli != undefined && elementoWrapper.dettagli.length > 0) {
            if (innerNode.dettagli == undefined) innerNode.dettagli = elementoWrapper.dettagli;
            else {
              elementoWrapper.dettagli.forEach(dettaglioRef => {
                innerNode.dettagli.push(dettaglioRef);
              });
            }
          }
        }
      });
    }
  }

  /**
   * Inizializza i flag indicanti se le fasce di costo del personale sono state configurate o meno per i vari
   * partner di progetto. Ogni flag è definito in base al bando di riferimento (se ammette o meno fasce standard) e in base
   * ai dati nel database. Serve ad attivare/disattivare e/o mostrare il pulsante di configurazione per i vari stakeholder.
   */
  initFasceFlagsFromDatabase(): void {
    this.dataSource.forEach(outer => {
      this.loadFasceNonStandardConfigurate(this.progetto.id, outer.sedeStakeholder.id, (fasce) => {
        if (fasce != undefined && fasce.length > 0) outer.flagFasceConfigurate = true;
      });
    });
  }

  /**
   * Inizializza la struttura del piano dei costi in base ai record contenuti nella WBS
   * prelevata dal database.
   */
  initDataFromWbs(): void {
    this.wbs.recordsPrimoLivello.forEach(pl => {
      if (this.progetto.bando.confLivelloCostiInWbs === true) {
        pl.recordsSecondoLivello.forEach(sl => {
          this.initDataFromWbsNode(sl);
        });
      } else {
        this.initDataFromWbsNode(pl);
      }
    });
    var tmp = [];
    this.dataSource.forEach(outer => {
      if (outer.data != undefined && outer.data.length > 0) tmp.push(outer);
    });
    this.dataSource = tmp.concat([]);
  }

  /**
   * Metodo di supporto per l'inizializzazione da WBS. Inizializza la struttura in base ad un nodo in WBS.
   * @param node Nodo di WBS di riferimento.
   */
  private initDataFromWbsNode(node): void {
    node.sediStakeholders.forEach(sedeSl => {
      var slRef = this.dataSource.find(found => found.sedeStakeholder.id === sedeSl.sedeStakeholder.id);
      if (slRef != undefined) {
        var d: InnerNode = {record: node, elementi: [], dettagli: []};
        this.vociDiCosto.forEach(vc => {
          d.elementi.push({
            costo: 0,
            annotazioni: undefined,
            sedeStakeholder: sedeSl.sedeStakeholder,
            voceDiCosto: vc
          });
        });
        slRef.data.push(d);
      }
    });
  }

  /**
   * Inizializza i dati dal database. Carica a cascata sedi dei partner di progetto, voci di costo, ultima versione di WBS,
   * stakeholder memorizzati in database e voci di costo che partecipano al calcolo delle spese generali.
   * @param _callback Azione successiva.
   */
  initDataFromDatabase(_callback): void {
    this.loadSediStakeholder(this.progetto.id, (sedi) => {
      sedi.forEach(sede => {
        this.dataSource.push({
          sedeStakeholder: sede,
          data: [],
          expanded: false,
          flagFasceConfigurate: this.progetto.bando.confCostiStandard
        });
      });
      this.loadVociDiCosto(this.progetto.bando.id, this.progetto.organizzazione.id, (voci) => {
        this.vociDiCosto = voci;
        this.loadWbs(this.progetto.nome, this.progetto.organizzazione.id, (wbsFromDatabase) => {
          this.wbs = wbsFromDatabase;
          this.loadStakeholders(this.progetto.organizzazione.id, (stakeholdersFromDatabase) => {
            this.stakeholders = stakeholdersFromDatabase.sort(function (a, b) {
              return a.denominazione.localeCompare(b.denominazione)
            });
            this.loadVociDiCostoPartecipantiCalcoloSpeseGenerali(this.progetto.bando.id, (psg) => {
              if (psg != undefined && psg.length > 0) this.vociDiCostoPartecipantiCalcoloSpeseGenerali = psg;
              _callback();
            });
          });
        });
      })
    });
  }

  /**
   * Inizializza i dati provenienti dal parent, memorizzati nel session storage.
   */
  initDataFromParent(): void {
    const item = sessionStorage.getItem("progetto");
    if (item == undefined) {
      this.showError("Errore nell'inizializzazione del progetto di riferimento.");
      return;
    }
    this.progetto = JSON.parse(item);
    sessionStorage.removeItem("progetto");
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String): void {
    this.dialog.open(WarningMessageComponent, {
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  /**
   * Mostra un messaggio di notifica all'utente.
   * @param message Messaggio di notifica da mostrare.
   * @param action Azione da permettere.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  /**
   * Mostra un messaggio di richiesta di conferma all'utente.
   * @param message Messaggio di richiesta di conferma.
   * @param submessage Messaggio inferiore.
   * @param _callback Azione successiva, in caso di conferma della richiesta.
   */
  askConfirm(message: string, submessage: string, _callback): void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent, {
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res) _callback();
      }
    );
  }

  ngOnInit() {
    this.initDataFromParent();
    this.initDataFromDatabase(() => {
      this.initDataFromWbs();
      if (this.progetto.bando.confCostiStandard === false) this.initFasceFlagsFromDatabase();
      this.initDataFromPianoCosti(() => {
        this.loadingComponent = false;
      });
    });
    this.inscadenza = this.partnerInScadenzaComponent.getPartnterInScadenza(this.progetto.id);
  }


  onStoricoVersioni(): void {
    const ganttDialogRef = this.dialog.open(StoricoPianoCostiComponent, {
      height: '80%',
      width: '80%',
      data: {progetto: this.progetto}
    });

    ganttDialogRef.afterClosed().subscribe(result => {

    });
  }

}
