import { Component, OnInit, Injectable, Inject, LOCALE_ID, ViewChild  } from '@angular/core';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DEFAULT_OPTIONS, DateAdapter, MatTableDataSource, MatSort } from '@angular/material';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { BehaviorSubject, observable, Observable } from 'rxjs';
import { UserStorage } from '../core_modules/user.storage';
import { OrganizzazioniService } from '../organizzazioni/organizzazioni.service';
import {MatPaginator} from '@angular/material/paginator';
import { MonitoraggioDeliverablesProgettoComponent } from '../monitoraggio-deliverables-progetto/monitoraggio-deliverables-progetto.component';
import { ReportisticaProgettiService } from './reportistica-progetti.service';
import { GenerazioneReportDialogComponent } from '../generazione-report-dialog/generazione-report-dialog.component';

export class ProgettiElement {
  id : String;
  nomeProgetto: string;
  position: number;
  weight: number;
  symbol: string;
  wbsVersions: any;
  selectedWbsVersion: any;
}

@Injectable()
export class TableReportisticaProgettiDatabase {

  dataChange = new BehaviorSubject<ProgettiElement[]>([]);
  get data(): ProgettiElement[] { return this.dataChange.value; }

  // Classe di servizio del component owner che implementa i vari servizi verso il back-end invocati dal TableProjectDatabase
  // come 'saveAttivitasInPraticas', 'deleteAttivitaFromPratica', 'getPraticheTreeByOrganization', etc.
  private ownerComponentService: any;
  // La dialog dell'owner component
  private ownerComponentDialog: MatDialog;
  // ########## END   ELEMENTI DEL COMPONENT OWNER ##########

  //L'oggetto JSON, estratto con apposito querying del database, che contiene i progetti.
  jsonProjectTableStructure: any;

  projectTableNodesArray: ProgettiElement[];

  public setOwnerComponentService(x: any) {
    this.ownerComponentService = x;
  }
  public setOwnerComponentDialog(y: MatDialog) {
    this.ownerComponentDialog = y;
  }

  constructor(private adapter: DateAdapter<any>,
              @Inject(LOCALE_ID) private locale: string) {
    this.adapter.setLocale("it");
  }

  bandiProgetto = [];

  getBandiProgetto() { return this.bandiProgetto; }

  setWbsVersions(index) : void {
    this.ownerComponentService.getWbsVersionsByProgettoId(this.data[index].id).subscribe(
      data => {
        this.data[index].wbsVersions = data;
      },
      err => {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare i Progetti!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  initWbsVersions() {
    for(var i in this.data) {
      this.setWbsVersions(i);
    }
  }
   
  reloadProjectGestoriDatabaseStructure(idorganizzazione) {
    this.ownerComponentService.getProgettiFromRendicontaDB(idorganizzazione).subscribe(
      dataDb => {
        this.jsonProjectTableStructure = dataDb;
        console.log(JSON.stringify(dataDb));

        for(var x=0; x<dataDb.length; x++) {
          this.bandiProgetto.push({
            idProgetto: dataDb[x].id,
            bando: dataDb[x].bando
          }
          );
        }
       
        this.projectTableNodesArray = this.buildProjectNodes(this.jsonProjectTableStructure);

        const d = this.projectTableNodesArray;
        this.dataChange.next(d);
        console.log(d);

        this.initWbsVersions();

        
      },
      err => {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare i Progetti!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    ) 
  }

  


  // Build the nodes from Json object. The result is a list of `TableProjectNode` 
  buildProjectNodes(projectTableNodeData: any[]): ProgettiElement[] {
    let nodeArray = new Array<ProgettiElement>(); 
    if (projectTableNodeData != null) {
      try {
        projectTableNodeData.forEach(project => {
          const node = new ProgettiElement();
    
          node.nomeProgetto = project.nome;
          node.id = project.id;
      
          nodeArray.push(node);
        });
      } catch (error) {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare informazioni della tabella!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    }
    return nodeArray;
  }
}

@Component({
  selector: 'app-reportistica-progetti',
  templateUrl: './reportistica-progetti.component.html',
  styleUrls: ['./reportistica-progetti.component.css'],
  providers: [TableReportisticaProgettiDatabase, {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}]
})
export class ReportisticaProgettiComponent implements OnInit {

  displayedColumns: string[] = [ 'name', 'versione_wbs', 'info', 'wbs', 'piano_costi_ammissibili', 'piano_costi', 'piano_sal' ];
    projectTableDataSource : MatTableDataSource<ProgettiElement>;
  
    @ViewChild(MatPaginator) paginator: MatPaginator;// Numero elementi da visualizzare

    applyFilter(filterValue: string) {
      this.projectTableDataSource.filter = filterValue.trim().toLowerCase();
    }

  constructor(
    private reportisticaProgettiService : ReportisticaProgettiService,
    private organizzazioniService: OrganizzazioniService,
    public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private userStorage: UserStorage,
    private adapter: DateAdapter<any>,
    private projectTableContentDatabase: TableReportisticaProgettiDatabase,
    private generazioneReportDialog : MatDialog,
    ) {
      this.adapter.setLocale("it");
    this.projectTableDataSource = new MatTableDataSource<ProgettiElement>();
    projectTableContentDatabase.dataChange.subscribe(data => this.projectTableDataSource.data = data);

     }
    
  color = undefined;
  data = undefined;
  caller = undefined;
  
  gestione_progetto_section_title = "Gestione Progetto";
 organizzazioneObj = undefined;

  ngOnInit() {
   // this.color=colors[Math.floor(Math.random() * colors.length)];
    this.projectTableDataSource.paginator = this.paginator;
    this.loadOrganizzazioneAndOtherData();

  }

  getDateStringFromTimestamp(timestamp) : any {
    var d = new Date(+timestamp);
    return d.toLocaleString();
  }

  onReportPianoCostiAmmissibili(element) : void {
    const generazioneReportDialogRef = this.generazioneReportDialog.open(GenerazioneReportDialogComponent, {
      data : { reportType: "PIANO_COSTI_AMMISSIBILI", ids: [element.id, element.selectedWbsVersion.id] }
    });
  }

  on(element) : void {
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  loadOrganizzazioneAndOtherData() {
    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        this.organizzazioneObj=data;
       console.log('obj: ' + this.organizzazioneObj.id);

 
        this.projectTableContentDatabase.setOwnerComponentService(this.reportisticaProgettiService);
        this.projectTableContentDatabase.setOwnerComponentDialog(this.dialog);

        this.projectTableContentDatabase.reloadProjectGestoriDatabaseStructure(this.organizzazioneObj.id);
        //this.loadPartnersTipoOrganizzazione(this.organizzazioneObj.id);
      },
      err => {
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

}
