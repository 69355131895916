import { Component, OnInit } from '@angular/core';
import { DettaglioCostiPersonaleModalService } from './dettaglio-costi-personale-modal.service';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ConfirmMessageComponent } from '../../../modals/confirm-message/confirm-message.component';
import { WarningMessageComponent } from '../../../modals/warning-message/warning-message.component';
import { InnerNode } from '../../gestione-piani-dei-costi.component';

@Component({
  selector: 'app-dettaglio-costi-personale-modal',
  templateUrl: './dettaglio-costi-personale-modal.component.html',
  styleUrls: ['./dettaglio-costi-personale-modal.component.css']
})
export class DettaglioCostiPersonaleModalComponent implements OnInit {

  loadingComponent: boolean = true;

  progetto = undefined;
  sedeStakeholder = undefined;
  innerNode: InnerNode = undefined;

  fasce = [];
  dettagli = [];

  constructor(
    private dettaglioCostiPersonaleModalService : DettaglioCostiPersonaleModalService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    public thisDialogRef: MatDialogRef<DettaglioCostiPersonaleModalComponent>,
  ) {}

  /**
   * Carica le fasce non standard dal database.
   * @param idSedeStakeholder Id della sede di stakeholder di riferimento.
   * @param idProgetto Id del progetto di riferimento.
   * @param _callback Azione successiva.
   */
  loadFasceNonStandard(idSedeStakeholder, idProgetto, _callback) : void {
    this.dettaglioCostiPersonaleModalService.getFasceNonStandard(idProgetto, idSedeStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Carica le fasce standard dal database.
   * @param idBando Id del bando di riferimento.
   * @param idTipologiaStakeholder Id della tipologia di stakeholder di riferimento.
   * @param _callback Azione successiva.
   */
  loadFasceStandard(idBando, idTipologiaStakeholder, _callback) : void {
    this.dettaglioCostiPersonaleModalService.getFasceStandard(idBando, idTipologiaStakeholder).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  /**
   * Ricalcola il totale del dettaglio di costi personale.
   * @param dettaglio Dettaglio di riferimento.
   */
  onRefreshTotale(dettaglio) : void {
    dettaglio.costoTotale = +(this.progetto.bando.confCostiStandard === true ?
      dettaglio.persDipFasciaCostoPersonaleStandardTipologiaStakeholder.costoOrario :
      dettaglio.persDipFasciaCostoPersonaleNonStandardProgettoStakeholder.costoOrario) * (+dettaglio.persDipOre);
    dettaglio.costoTotale = +((+dettaglio.costoTotale).toFixed(2));
  }

  /**
   * Trasferisce i dati al parent e chiude il dialog.
   */
  onSaveAll() : void {
    const msg = this.checkForErrors();
    if(msg != undefined) this.showError(msg);
    else this.thisDialogRef.close(this.dettagli);
  }

  /**
   * Restituisce il costo medio del dettaglio.
   */
  getCostoMedio() : number {
    var res = 0;
    this.dettagli.forEach(dett => { res += +(this.progetto.bando.confCostiStandard === true ?
      dett.persDipFasciaCostoPersonaleStandardTipologiaStakeholder.costoOrario :
      dett.persDipFasciaCostoPersonaleNonStandardProgettoStakeholder.costoOrario); });
    return res / this.dettagli.length;
  }

  /**
   * Restituisce il numero di unità totali del dettaglio.
   */
  getUnitaTotali() : number {
    var res = 0;
    this.dettagli.forEach(dett => {res += +(dett.persDipNumeroPersone);});
    return res;
  }

  /**
   * Restituisce il numero di ore totali del dettaglio.
   */
  getOreTotali() : number {
    var res = 0;
    this.dettagli.forEach(dett => {res += +(dett.persDipOre);});
    return res;
  }

  /**
   * Restituisce il costo totale del dettaglio.
   */
  getCostoTotale() : number {
    var res = 0;
    this.dettagli.forEach(dett => {res += +(dett.costoTotale);});
    return +(res.toFixed(2));
  }

  /**
   * Restituisce il messaggio da mostrare all'utente nel caso vi siano errori, UNDEFINED altrimenti.
   */
  checkForErrors() : string {
    if(this.dettagli == undefined || this.dettagli.length === 0) return "Impossibile memorizzare un dettaglio di costi di personale vuoto.";
    if(this.dettagli.find(function(found) { return found.persDipOre == undefined || found.persDipNumeroPersone == undefined || +found.persDipOre < 0 || +found.persDipNumeroPersone < 0 }) != undefined) {
      return "Impossibile memorizzare un dettaglio di costi di personale con indicatori vuoti o negativi.";
    }
    return undefined;
  }

  /**
   * Inizializza i dettagli da mostrare nel dialog, in base alle fasce di costo.
   */
  initDettagli() : void {
    this.fasce.forEach(fascia => {
      this.dettagli.push({
        id: undefined,
        persDipOre: 0,
        persDipNumeroPersone: 0,
        persDipFasciaCostoPersonaleStandardTipologiaStakeholder: this.progetto.bando.confCostiStandard === true ? fascia : undefined,
        persDipFasciaCostoPersonaleNonStandardProgettoStakeholder: this.progetto.bando.confCostiStandard === false ? fascia : undefined,
        costoTotale: 0,
        elementoDiCostoAmmissibile: undefined
      });
    });
    const dettagliItem = sessionStorage.getItem("dettaglio_costi_personale_dettagli");
    if(dettagliItem != undefined) {
      var dettagliTemp = JSON.parse(dettagliItem);
      if(dettagliTemp != undefined && dettagliTemp.length > 0) this.dettagli = dettagliTemp;
      sessionStorage.removeItem("dettaglio_costi_personale_dettagli");
    }
  }

  /**
   * Inizializza i dati dal database.
   * @param _callback Azione successiva.
   */
  initDataFromDatabase(_callback) : void {
    if(this.progetto.bando.confCostiStandard === true) {
      this.loadFasceStandard(this.progetto.bando.id, this.sedeStakeholder.stakeholder.tipologiaStakeholder.id, (fasceStandard) => {
        this.fasce = fasceStandard;
        _callback();
      });
    }
    else {
      this.loadFasceNonStandard(this.sedeStakeholder.id, this.progetto.id, (fasceNonStandard) => {
        this.fasce = fasceNonStandard;
        _callback();
      });
    }
  }

  /**
   * Inizializza i dati provenienti dal parent, prelevandoli dal session storage.
   */
  initDataFromParent() : void {
    const item = sessionStorage.getItem("dettaglio_costi_personale_progetto");
    if(item == undefined) {
      this.showError("Errore nell'inizializzazione del progetto di riferimento.");
      return;
    }
    this.progetto = JSON.parse(item);
    sessionStorage.removeItem("dettaglio_costi_personale_progetto");
    const sedeItem = sessionStorage.getItem("dettaglio_costi_personale_sede_stakeholder");
    if(sedeItem == undefined) {
      this.showError("Errore nell'inizializzazione della sede stakeholder di riferimento.");
      return;
    }
    this.sedeStakeholder = JSON.parse(sedeItem);
    sessionStorage.removeItem("dettaglio_costi_personale_sede_stakeholder");
    const nodeItem = sessionStorage.getItem("dettaglio_costi_personale_node");
    if(nodeItem == undefined) {
      this.showError("Errore nell'inizializzazione del nodo di riferimento.");
      return;
    }
    this.innerNode = JSON.parse(nodeItem);
    sessionStorage.removeItem("dettaglio_costi_personale_node");
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(err) {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: err
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.initDataFromParent();
    this.initDataFromDatabase(() => {
      this.initDettagli();
      this.loadingComponent = false;
    });
  }

}
