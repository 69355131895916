import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {GestioneReferentiProgettoService} from './gestione-referenti-progetto.service';
import {MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource} from '@angular/material';
import {ConfirmMessageComponent} from '../modals/confirm-message/confirm-message.component';
import {WarningMessageComponent} from '../modals/warning-message/warning-message.component';
import {AggiuntaNuovoReferenteModalComponent} from './modals/aggiunta-nuovo-referente-modal/aggiunta-nuovo-referente-modal.component';

export class Element {
  persona: any;
  gestoriData: any;
  checked: boolean;
}


@Component({
  selector: 'app-gestione-referenti-progetto',
  templateUrl: './gestione-referenti-progetto.component.html',
  styleUrls: ['./gestione-referenti-progetto.component.css']
})
export class GestioneReferentiProgettoComponent implements OnInit {

  NOME_COGNOME_MAX_LENGTH = 100;
  CF_PIVA_MAX_LENGTH = 16;
  TEL_MAX_LENGTH = 45;
  EMAIL_MAX_LENGTH = 100;
  DIPARTIMENTO_RUOLO_MAX_LENGTH = 45;

  loadingComponent: boolean = true;
  modifyMode: boolean = true;

  progetto = undefined;
  persone = [];
  ruoli = [];

  stakeholdersDaGestori = [];

  dataSource: Element[] = [];

  displayedColumns = ['checked', 'referente', 'contatti', 'azienda'];
  allChecked: boolean = false;

  @Output() initializeEvent = new EventEmitter<any>();

  constructor(
    private gestioneReferentiProgettoService: GestioneReferentiProgettoService,
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    public thisDialogRef: MatDialogRef<GestioneReferentiProgettoComponent>
  ) {
  }

  /**
   * Carica i referenti presenti nel database di GESTORI, nel formato previsto dal wrapper esterno.
   * @param nomeProgetto Nome del progetto di riferimento.
   * @param _callback Azione successiva.
   */
  loadReferentiDaGestori(nomeProgetto, _callback): void {
    this.gestioneReferentiProgettoService.getReferentiFromGestori(null, nomeProgetto).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError("Errore nel caricamento dei referenti da GESTORI.");
      }
    );
  }

  /**
   * Carica gli stakeholder dal database.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadStakeholders(idOrganizzazione, _callback): void {
    this.gestioneReferentiProgettoService.getStakeholders(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica le sedi principali di ogni stakeholder salvato nel database, una per ogni stakeholder.
   * Per come avviene il salvataggio degli stakeholder, ad ogni stakeholder è sempre associata una sede principale.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadSedi(idOrganizzazione, _callback): void {
    this.gestioneReferentiProgettoService.getSedi(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica i ruoli di referenti dal database.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadRuoli(idOrganizzazione, _callback): void {
    this.gestioneReferentiProgettoService.getRuoli(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica dal database tutte le persone collegate come referenti al progetto.
   * @param idProgetto Id del progetto di riferimento.
   * @param _callback Azione successiva.
   */
  loadPersoneProgetto(idProgetto, _callback): void {
    this.gestioneReferentiProgettoService.getPersoneProgetto(idProgetto).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  aggiornaPersone(idProgetto) {
    this.gestioneReferentiProgettoService.getPersoneProgetto(idProgetto).subscribe(
      data => {

        this.dataSource = [];
        data.forEach(personaRef => {

          this.dataSource.push({
            persona: {
              id: personaRef.id,
              nome: personaRef.nome,
              cognome: personaRef.cognome,
              codiceFiscale: personaRef.codiceFiscale,
              telefonoUfficio: personaRef.telefonoUfficio,
              cellularePersonale: personaRef.cellularePersonale,
              emailUfficio: personaRef.emailUfficio,
              ruolo: personaRef.ruolo != undefined ? personaRef.ruolo.replace("Ref.", "Referente") : "",
              organizzazione: this.progetto.organizzazione,
              sedeStakeholder: {stakeholder: {denominazione: personaRef.sedeStakeholder.stakeholder.denominazione}},
            },
            gestoriData: null,
            checked: true,
          });


        });
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Carica tutte le persone salvate nel database.
   * @param idOrganizzazione Id dell'organizzazione di riferimento.
   * @param _callback Azione successiva.
   */
  loadPersone(idOrganizzazione, _callback): void {
    this.gestioneReferentiProgettoService.getPersone(idOrganizzazione).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Salva una nuova persona sul database.
   * @param index Indice della persona nella lista di riferimento. Serve per tenere traccia dell'indice posizionale
   *              nella lista di provenienza della persona che si sta salvando, per poter effettuare un'azione a fine lista.
   * @param stakeholder Persona da salvare.
   * @param _callback Azione successiva.
   */
  savePersona(index, referente, _callback): void {
    this.gestioneReferentiProgettoService.savePersona(referente).subscribe(
      data => {
        _callback(index, data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Salva una nuova relazione progetto-persona sul database.
   * @param index Indice della relazione nella lista di riferimento. Serve per tenere traccia dell'indice posizionale
   *              nella lista di provenienza della relazione che si sta salvando, per poter effettuare un'azione a fine lista.
   * @param relation Relazione da salvare.
   * @param _callback Azione successiva.
   */
  saveProgettoPersona(index, progettoPersona, _callback): void {
    this.gestioneReferentiProgettoService.saveProgettoReferenteRelation(progettoPersona).subscribe(
      data => {
        _callback(index, data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Resetta il progetto, eliminando tutti i dati salvati a partire dai referenti collegati al progetto. Serve come azione preliminare per
   * poter salvare una nuova lista di referenti. Performa un'eliminazione a cascata di tutti i dati collegati ai referenti di progetto.
   * @param idProgetto Id del progetto di riferimento.
   * @param _callback Azione successiva.
   */
  resetProgetto(idProgetto, _callback): void {
    this.gestioneReferentiProgettoService.resetProgetto(idProgetto).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  /**
   * Seleziona un elemento in tabella.
   * @param element Elemento da selezionare.
   * @param event Evento scatenato.
   */
  onCheckElement(element, event): void {
    if (event != undefined) event.preventDefault();
    element.checked = !element.checked;
    this.refreshPartnerTable();
  }

  /**
   * Salva tutti i referenti selezionati in tabella. Verifica che non vi siano errori e, in caso positivo, performa il salvataggio.
   * Collega ogni referente alla sede principale dello stakeholder a cui appartiene, dopodiché salva tutte le persone non ancora presente
   * nel database. Infine, collega ogni persona come referente al progetto.
   * @param _callback Azione successiva.
   */
  onSaveAll(_callback): void {
    const errorMessage = this.getErrorMessage();
    if (errorMessage != undefined) this.showError(errorMessage);
    else {
      this.askConfirm("Vuoi salvare i referenti selezionati?", "Il salvataggio comporterà la memorizzazione dei referenti e il collegamento al progetto.", () => {
        this.loadSedi(this.progetto.organizzazione.id, (sediRef) => {
          if (sediRef == undefined || sediRef.length === 0) this.showError("Errore nel caricamento delle sedi dal database.");
          else {
            const dataRef = this.dataSource.filter(filtered => filtered.checked === true);
            dataRef.forEach((element, i) => {
              var sedeRef;
              if (element.gestoriData != undefined) {
                sedeRef = sediRef.find(sedeFound => sedeFound.stakeholder.denominazione.trim().toUpperCase() === element.gestoriData.account_name.trim().toUpperCase());
              } else {
                sedeRef = element.persona.sedeStakeholder;
              }
              if (sedeRef == undefined) this.showError("Errore nel caricamento delle sedi dal database.");
              else {
                element.persona.sedeStakeholder = sedeRef;
                this.savePersonaImpl(i, element.persona, (ind, personaRef) => {
                  this.saveProgettoPersona(ind, {
                    id: undefined,
                    progetto: this.progetto,
                    persona: personaRef,
                    ruoloReferente: this.ruoli.find(ruoloFound => ruoloFound.nome.trim().toUpperCase() === personaRef.ruolo.trim().toUpperCase())
                  }, (lastIndex, progettoPersonaRef) => {
                    if (lastIndex === dataRef.length - 1) _callback();
                  });
                });
              }
            });
          }
        });
      });
    }
  }

  /**
   * Implementazione del metodo di salvataggio persona.
   * Salva una persona sul database solo se non è già presente (caso di gestione referenti da gestione progetto).
   * @param i Indice nelle strutture dati interne.
   * @param persona Persona da salvare.
   * @param _callback Azione successiva.
   */
  private savePersonaImpl(i, persona, _callback): void {
    if (persona.id != undefined) _callback(i, persona);
    else {
      this.savePersona(i, persona, (ind, personaRef) => {
        _callback(ind, personaRef);
      });
    }
  }

  /**
   * Genera un messaggio di errore per impedire il salvataggio.
   * Se il risultato è UNDEFINED, si può proseguire col salvataggio.
   * Se il risultato è valorizzato, occorre impedire il salvataggio e mostrare l'errore.
   */
  getErrorMessage(): string {
    if (this.dataSource == undefined || this.dataSource.length === 0 ||
      this.dataSource.find(found => found.checked === true) == undefined) {
      return "Nessun referente indicato.";
    }
    var msg = undefined;
    const data = this.dataSource.filter(filtered => filtered.checked === true);
    data.forEach(element => {
      if (element.persona.nome == undefined || element.persona.nome.length === 0) {
        msg = "Impossibile memorizzare referenti privi di nome.";
        return;
      }
      if (element.persona.nome.length > this.NOME_COGNOME_MAX_LENGTH) {
        msg = "Il nome fornito per il referente " + element.persona.cognome + " " + element.persona.nome + " è troppo lungo.";
        return;
      }
      if (element.persona.cognome == undefined || element.persona.cognome.length === 0) {
        msg = "Impossibile memorizzare referenti privi di cognome.";
        return;
      }
      if (element.persona.cognome.length > this.NOME_COGNOME_MAX_LENGTH) {
        msg = "Il cognome fornito per il referente " + element.persona.cognome + " " + element.persona.nome + " è troppo lungo.";
        return;
      }
      if (element.persona.codiceFiscale != undefined && element.persona.codiceFiscale.length > this.CF_PIVA_MAX_LENGTH) {
        msg = "Codice fiscale troppo lungo per il referente " + element.persona.cognome + " " + element.persona.nome + ".";
        return;
      }
      if (element.persona.ruolo == undefined || element.persona.ruolo.length === 0) {
        msg = "Nessun ruolo fornito per il referente " + element.persona.cognome + " " + element.persona.nome + ".";
        return;
      }
      if (element.persona.ruolo.length > this.DIPARTIMENTO_RUOLO_MAX_LENGTH) {
        msg = "Nome del ruolo troppo lungo per il referente " + element.persona.cognome + " " + element.persona.nome + ".";
        return;
      }
      if ((element.persona.telefonoUfficio != undefined && element.persona.telefonoUfficio.length > this.TEL_MAX_LENGTH) ||
        (element.persona.cellularePersonale != undefined && element.persona.cellularePersonale.length > this.TEL_MAX_LENGTH)) {
        msg = "Recapiti telefonici troppo lunghi per il referente " + element.persona.cognome + " " + element.persona.nome + ".";
        return;
      }
      if (element.persona.emailUfficio != undefined && element.persona.emailUfficio.length > this.EMAIL_MAX_LENGTH) {
        msg = "Indirizzo e-mail ufficio troppo lungo per il referente " + element.persona.cognome + " " + element.persona.nome + ".";
        return;
      }
    });
    return msg;
  }

  /**
   * Metodo da utilizzare solo se si usa il component per import referenti da Gestori.
   * Scatena un evento per il component di gestione partner in base ai referenti selezionati. Inserisce nella tabella
   * di gestione partner solo gli stakeholder (e relativa sede principale) che non sono ancora presenti nel database.
   */
  private refreshPartnerTable(): void {
    var tmp = [];
    this.dataSource.filter(filtered => filtered.checked === true).forEach(e => {
      const stakeholderRef = this.stakeholdersDaGestori.find(found => found.account_name === e.gestoriData.account_name);
      if (stakeholderRef != undefined) tmp.push(stakeholderRef);
    });
    sessionStorage.setItem("definizione_partner_progetto", JSON.stringify(this.progetto));
    this.initializeEvent.emit(tmp.concat([]));
  }

  /**
   * Seleziona tutti gli elementi in tabella.
   * @param event Evento scatenato.
   */
  onCheckAll(event): void {
    if (event != undefined) event.preventDefault();
    this.allChecked = !this.allChecked;
    this.dataSource.forEach(d => d.checked = this.allChecked);
    this.refreshPartnerTable();
  }

  /**
   * Aggiunge un nuovo elemento in tabella. Verifica che vi siano ancora referenti che possono essere importati, dopodiché apre il dialog
   * di selezione di una persona. In esso sono riportate solo le persone non ancora importati nella lista referenti.
   * Selezionato il partner, esso viene aggiunto alla lista.
   */
  onAddElement(): void {
    this.loadPersone(this.progetto.organizzazione.id, (personeRef) => {
      var tmp = [];
      personeRef.forEach(personaRef => {
        if (this.dataSource.find(found => found.persona.id === personaRef.id) == undefined) tmp.push(personaRef)
      });
      if (tmp.length === 0) this.showError("Tutti i referenti disponibili sono stati importati.");
      else {
        sessionStorage.setItem("definizione_partner_progetto", JSON.stringify(this.progetto.id));
        const dialogRef = this.dialog.open(AggiuntaNuovoReferenteModalComponent, {
          data: tmp
        });
        dialogRef.afterClosed().subscribe(result => {

          this.aggiornaPersone(this.progetto.id);
        });
      }
    });
  }

  /**
   * Rimuove un elemento dalla lista referenti.
   * @param element Elemento da rimuovere.
   */
  onRemoveElement(element: Element): void {
    this.dataSource.splice(this.dataSource.indexOf(element), 1);
    this.dataSource = this.dataSource.concat([]);
  }

  /**
   * Salva tutti i dati da gestione progetto. Resetta il progetto eliminando tutti i dati relativi, dopodiché salva la
   * nuova lista.
   */
  onSaveFromGestioneProgetto(): void {
    this.askConfirm("Vuoi davvero salvare una nuova lista di referenti di progetto?",
      "Tutti i dati collegati al progetto e precedentemente salvati verranno eliminati.", () => {
        this.resetProgetto(this.progetto.id, (res) => {
          if (res != undefined && res === true) {
            this.onSaveAll(() => {
              this.thisDialogRef.close(true);
            });
          }
        });
      });
  }

  /**
   * Setta il modo di apertura del component.
   * @param mode Modo di apertura. Se TRUE, indica che il component è aperto in un dialog, FALSE altrimenti.
   */
  setModifyMode(mode: boolean): void {
    this.modifyMode = mode;
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(error: String): void {
    this.dialog.open(WarningMessageComponent, {
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  /**
   * Mostra un messaggio di richiesta di conferma all'utente.
   * @param message Messaggio di richiesta di conferma.
   * @param submessage Messaggio inferiore.
   * @param _callback Azione successiva, in caso di conferma della richiesta.
   */
  askConfirm(message: string, submessage: string, _callback): void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent, {
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res) _callback();
      }
    );
  }

  /**
   * Inizializza i dati per l'utilizzo del component nello step di import referenti da Gestori.
   * @param _callback Azione successiva.
   */
  initDataFromImportGestori(_callback): void {
    this.loadReferentiDaGestori(this.progetto.nome, (referentiRef) => {
      var tmp = [];
      referentiRef.forEach(r => {
        this.dataSource.push({
          persona: {
            id: undefined,
            nome: r.first_name,
            cognome: r.last_name,
            codiceFiscale: undefined,
            telefonoUfficio: r.phone,
            cellularePersonale: r.mobile,
            emailUfficio: r.email,
            ruolo: r.ruolo_referente != undefined ? r.ruolo_referente.replace("Ref.", "Referente") : "",
            organizzazione: this.progetto.organizzazione,
            sedeStakeholder: {stakeholder: {denominazione: r.account_name}}
          },
          gestoriData: r,
          checked: false
        });
        tmp.push({
          account_id: "",
          account_name: r.account_name,
          phone: r.account_phone,
          other_phone: r.other_phone,
          email_1: r.email_1,
          email_2: r.email_2,
          website: r.website,
          fax: r.account_fax,
          ruolo_partner: "Partner",
          citta: r.citta,
          sigla_provincia: r.sigla_provincia,
          indirizzo: r.indirizzo,
          cap: r.cap
        });
      });
      sessionStorage.setItem("definizione_partner_progetto", JSON.stringify(this.progetto));
      this.dataSource = this.dataSource.concat([]);
      this.stakeholdersDaGestori = tmp.concat([]);
      _callback();
    });
  }

  /**
   * Inizializza i dati tramite il database. Inizializza, in cascata, stakeholder con relative sedi e ruoli di referente.
   * @param _callback Azione successiva.
   */
  initDataFromDatabase(_callback): void {
    this.loadStakeholders(this.progetto.organizzazione.id, (stakeholdersRef) => {
      var tmp = [];
      this.stakeholdersDaGestori.forEach(sdg => {
        if (stakeholdersRef.find(found => found.denominazione.trim().toUpperCase() === sdg.account_name.trim().toUpperCase()) == undefined) tmp.push(sdg);
      });
      this.stakeholdersDaGestori = tmp.concat([]);
      this.loadRuoli(this.progetto.organizzazione.id, (ruoliRef) => {
        this.ruoli = ruoliRef != undefined ? ruoliRef : [];
        _callback();
      });
    });
  }

  /**
   * Inizializza i dati provenienti dal parent e memorizzati nel session storage.
   */
  initDataFromParent(): void {
    const item = sessionStorage.getItem("definizione_referenti_progetto");
    if (item == undefined) this.showError("Errore nell'inizializzazione dei dati.");
    else {
      this.progetto = JSON.parse(item);
      //sessionStorage.removeItem("definizione_referenti_progetto");
    }
  }

  /**
   * Inizializza i dati e il datasource dal database. Serve per inizializzare i dati per l'utilizzo del component in gestione progetto.
   * @param _callback Azione successiva.
   */
  initDataSourceFromDatabase(_callback): void {
    this.loadRuoli(this.progetto.organizzazione.id, (ruoliRef) => {
      this.ruoli = ruoliRef != undefined ? ruoliRef : [];
      this.dataSource = [];
      this.loadPersoneProgetto(this.progetto.id, (personeRef) => {
        personeRef.forEach(personaRef => {
          this.dataSource.push({persona: personaRef, gestoriData: undefined, checked: true});
        });
        _callback();
      });
    });
  }

  /**
   * Avvia l'inizializzazione dei dati.
   */
  initialize(): void {
    this.initDataFromParent();
    this.initDataFromImportGestori(() => {
      this.initDataFromDatabase(() => {
        this.initializeEvent.emit([]);
      });
    });
  }

  ngOnInit() {
    /* Inizializzazione automatica, solo nel caso in cui il component è richiamato da un dialog. */
    if (this.modifyMode === true) {
      this.displayedColumns.push('add/remove');
      this.initDataFromParent();
      this.initDataSourceFromDatabase(() => {
        this.allChecked = true;
        this.loadingComponent = false;
      });
    }
  }

}
