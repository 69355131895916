import { Component, OnInit } from '@angular/core';
import { DettaglioCostiConsulenzeModalService } from './dettaglio-costi-consulenze-modal.service';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { WarningMessageComponent } from 'src/app/modals/warning-message/warning-message.component';

@Component({
  selector: 'app-dettaglio-costi-consulenze-modal',
  templateUrl: './dettaglio-costi-consulenze-modal.component.html',
  styleUrls: ['./dettaglio-costi-consulenze-modal.component.css']
})
export class DettaglioCostiConsulenzeModalComponent implements OnInit {

  ANNOTAZIONI_MAX_LENGTH = 250;
  RISULTATI_CONSULENZA_MAX_LENGTH = 500;

  loadingComponent: boolean = true;
  stakeholderProprietario = undefined;
  organizzazione = undefined;
  sediStakeholder = [];
  sediStakeholderFiltered = []

  displayedColumns = ['sedeStakeholder', 'costoTotale', 'annotazioni', 'risultati', 'add/remove'];
  dettagli = [];

  constructor(
    private dettaglioCostiConsulenzeModalService : DettaglioCostiConsulenzeModalService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<DettaglioCostiConsulenzeModalComponent>,
  ) { }
  
  loadSedi(idOrganizzazione, _callback) : void {
    this.dettaglioCostiConsulenzeModalService.getSedi(idOrganizzazione).subscribe(
      data => { _callback(data); },
      err => { this.showError(err.error.message); }
    );
  }

  onAddElement() : void {
    this.dettagli.push({ annotazioni: undefined, risultatiConsulenza: undefined, costoTotale: 0, sedeStakeholderConsulente: undefined });
    this.dettagli = this.dettagli.concat([]);
  }

  onRemoveElement(element) : void {
    const index = this.dettagli.indexOf(element);
    this.dettagli.splice(index, 1);
    this.dettagli = this.dettagli.concat([]);
  }

  /**
   * Trasferisce i dati al parent e chiude il dialog.
   */
  onSaveAll() : void {
    const msg = this.checkForErrors();
    if(msg != undefined) this.showError(msg);
    else this.thisDialogRef.close(this.dettagli);
  }

  onRestoreSediStakeholderFiltered() : void {
    this.sediStakeholderFiltered = this.sediStakeholder.concat([]);
  }

  onFilterSediStakeholder(event) : void {
    if(event.target.value != undefined) {
      this.sediStakeholderFiltered = this.sediStakeholder.filter(filtered => filtered.stakeholder.denominazione.trim().toUpperCase().includes(event.target.value.trim().toUpperCase()));
      if(this.sediStakeholderFiltered == undefined || this.sediStakeholderFiltered.length === 0) this.sediStakeholderFiltered = this.sediStakeholder.concat([]);
    }
    else this.sediStakeholderFiltered = this.sediStakeholder.concat([]);
  }

  checkForErrors() : string {
    if(this.dettagli == undefined) return "Lista dei dettagli malformata.";
    var res = undefined;
    this.dettagli.forEach(dettaglio => {
      if(dettaglio.sedeStakeholderConsulente == undefined) { res = "Impossibile memorizzare consulenze prive di riferimento allo stakeholder consulente."; return; }
      if(dettaglio.costoTotale == undefined || +dettaglio.costoTotale < 0) { res = "Impossibile memorizzare consulenze con costo totale assente o negativo."; return; }
      if(dettaglio.annotazioni != undefined && dettaglio.annotazioni.length > this.ANNOTAZIONI_MAX_LENGTH) { res = "Le annotazioni inserite sono troppo lunghe."; return; }
      if(dettaglio.risultatiConsulenza != undefined && dettaglio.risultatiConsulenza.length > this.RISULTATI_CONSULENZA_MAX_LENGTH) { res = "I risultati inseriti sono troppo lunghi."; return; }
    });
    return res;
  }

  initDataFromDatabase(_callback) : void {
    this.loadSedi(this.organizzazione.id, (sediRef) => {
      this.sediStakeholder = sediRef == undefined ? [] : sediRef.filter(filtered => filtered.stakeholder.id !== this.stakeholderProprietario.id);
      this.sediStakeholderFiltered = this.sediStakeholder.concat([]);
      this.dettagli.forEach(dettaglio => {
        dettaglio.sedeStakeholderConsulente = this.sediStakeholder.find(found => found.id === dettaglio.sedeStakeholderConsulente.id);
      });
      _callback();
    });
  }

  initDataFromParent() : void {
    const organizzazioneItem = sessionStorage.getItem("dettaglio_costi_consulenze_organizzazione");
    if(organizzazioneItem == undefined) {
      this.showError("Errore nell'inizializzazione dell'organizzazione di riferimento.");
      return;
    }
    this.organizzazione = JSON.parse(organizzazioneItem);
    sessionStorage.removeItem("dettaglio_costi_consulenze_organizzazione");
    const stakeholderProprietarioItem = sessionStorage.getItem("dettaglio_costi_consulenze_stakeholder_proprietario");
    if(stakeholderProprietarioItem == undefined) {
      this.showError("Errore nell'inizializzazione dello stakeholder di riferimento.");
      return;
    }
    this.stakeholderProprietario = JSON.parse(stakeholderProprietarioItem);
    sessionStorage.removeItem("dettaglio_costi_consulenze_stakeholder_proprietario");
    const dettagliItem = sessionStorage.getItem("dettaglio_costi_consulenze_dettagli");
    if(dettagliItem != undefined) {
      this.dettagli = JSON.parse(dettagliItem);
      sessionStorage.removeItem("dettaglio_costi_consulenze_dettagli");
    }
  }

  /**
   * Mostra un messaggio di errore all'utente.
   * @param error Messaggio di errore.
   */
  showError(err) {
    this.dialog.open(WarningMessageComponent,{
      data: {
        message: err
      },
      panelClass: 'custom-warning-container'
    });
  }

  ngOnInit() {
    this.initDataFromParent();
    this.initDataFromDatabase(() => {
      this.loadingComponent = false;
    });
  }

}
