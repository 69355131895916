import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "src/app/app.settings";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json, UTF-8' })
};
  
@Injectable()
export class AggiuntaRecordWbsDialogService {

    constructor(private http: HttpClient) {}

    public getStakeholders(idProgetto, idOrganizzazione) : Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'/progetto-stakeholders/get-stakeholders?idProgetto='+idProgetto+'&idOrganizzazione='+idOrganizzazione);
    }

    public getProgettoById(idProgetto) : Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'/progetti/get-progetto-by-id?id='+idProgetto);
    }

    public getTipiAttivitaCofinanziabili(idBando, idOrganizzazione) : Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'bandi-tipi-di-attivita-cofinanziabile/get-tipi-attivita-cofinanziabile-by-bando-id-and-organizzazione-id?bandoId='+idBando+'&organizzazioneId='+idOrganizzazione);
    }

    public getSediStakeholder(idProgetto) : Observable<any> {
        return this.http.get<any>(AppSettings.BASE_URL+'progetto-stakeholders/get-all-sedi-stakeholders?idProgetto='+idProgetto);
    }

}