import {Component, OnInit, Injectable, Inject, LOCALE_ID, ViewChild} from '@angular/core';
import {AuthService} from '../core_modules/auth.service';
import {GestioneProgettoService} from './gestione-progetto.service';
import {WarningMessageComponent} from '../modals/warning-message/warning-message.component';
import {
  MatSnackBar,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DEFAULT_OPTIONS,
  DateAdapter,
  MatTableDataSource,
  MatSort
} from '@angular/material';
import {ConfirmMessageComponent} from '../modals/confirm-message/confirm-message.component';
import {BehaviorSubject, observable, Observable} from 'rxjs';
import {UserStorage} from '../core_modules/user.storage';
import {OrganizzazioniService} from '../organizzazioni/organizzazioni.service';
import {MatPaginator} from '@angular/material/paginator';
import {GestioneWbsComponent} from '../gestione-wbs/gestione-wbs.component'
import {GestioneSalComponent} from '../gestione-sal/gestione-sal.component'
import {notEqual} from 'assert';
import {CreazioneDatiProgettoComponent} from '../crea-progetto/modals/creazione-dati-progetto/creazione-dati-progetto.component';
import {GestionePartnerProgettoComponent} from '../gestione-partner-progetto/gestione-partner-progetto.component';
import {GestioneReferentiProgettoComponent} from '../gestione-referenti-progetto/gestione-referenti-progetto.component'
import {GestionePianiDeiCostiComponent} from '../gestione-piani-dei-costi/gestione-piani-dei-costi.component';
import {GestioneWbsService} from '../gestione-wbs/gestione-wbs.service';
import {GestionePianiDeiCostiService} from '../gestione-piani-dei-costi/gestione-piani-dei-costi.service';
import {GestioneReferentiProgettoService} from '../gestione-referenti-progetto/gestione-referenti-progetto.service';
import {GestionePartnerProgettoService} from '../gestione-partner-progetto/gestione-partner-progetto.service';
import {GestioneSalService} from '../gestione-sal/gestione-sal.service';
import {UtilityModuleComponent} from '../core_modules/utility-module/utility-module.component';
import {RotellinaService} from '../rotellina-dialog/rotellina.service';
import {Router} from "@angular/router";

//const colors=['#ffb0b0','#f6f3b4','#dbf9b9','#c8e2f2','#d8c0ec']


export class ProgettiElement {
  id: String;
  nomeProgetto: string;
  position: number;
  weight: number;
  symbol: string;
}


@Injectable()
export class TableGestioneProgettiDatabase {

  dataChange = new BehaviorSubject<ProgettiElement[]>([]);

  get data(): ProgettiElement[] {
    return this.dataChange.value;
  }

  // Classe di servizio del component owner che implementa i vari servizi verso il back-end invocati dal TableProjectDatabase
  // come 'saveAttivitasInPraticas', 'deleteAttivitaFromPratica', 'getPraticheTreeByOrganization', etc.
  private ownerComponentService: any;
  // La dialog dell'owner component
  private ownerComponentDialog: MatDialog;
  // ########## END   ELEMENTI DEL COMPONENT OWNER ##########

  //L'oggetto JSON, estratto con apposito querying del database, che contiene i progetti.
  jsonProjectTableStructure: any;

  projectTableNodesArray: ProgettiElement[];

  public setOwnerComponentService(x: any) {
    this.ownerComponentService = x;
  }

  public setOwnerComponentDialog(y: MatDialog) {
    this.ownerComponentDialog = y;
  }

  constructor(private adapter: DateAdapter<any>,
              @Inject(LOCALE_ID) private locale: string,) {
    this.adapter.setLocale("it");
  }

  bandiProgetto = [];

  getBandiProgetto() {
    return this.bandiProgetto;
  }

  reloadProjectGestoriDatabaseStructure(idorganizzazione, _callback) {
    this.ownerComponentService.getProgettiFromRendicontaDB(idorganizzazione).subscribe(
      dataDb => {
        this.jsonProjectTableStructure = dataDb;
        console.log(JSON.stringify(dataDb));

        for (var x = 0; x < dataDb.length; x++) {
          this.bandiProgetto.push({
              idProgetto: dataDb[x].id,
              bando: dataDb[x].bando
            }
          );
        }

        this.projectTableNodesArray = this.buildProjectNodes(this.jsonProjectTableStructure);

        const data = this.projectTableNodesArray;
        this.dataChange.next(data);

        _callback();
      },
      err => {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare i Progetti!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    )
  }


  // Build the nodes from Json object. The result is a list of `TableProjectNode`
  buildProjectNodes(projectTableNodeData: any[]): ProgettiElement[] {
    let nodeArray = new Array<ProgettiElement>();
    if (projectTableNodeData != null) {
      try {
        projectTableNodeData.forEach(project => {
          const node = new ProgettiElement();

          node.nomeProgetto = project.nome;
          node.id = project.id;

          nodeArray.push(node);
        });
      } catch (error) {
        this.ownerComponentDialog.open(WarningMessageComponent, {
          data: {
            message: 'Impossibile caricare informazioni della tabella!'
          },
          panelClass: 'custom-warning-container'
        });
      }
    }
    return nodeArray;
  }
}

export class TableElementFlags {
  tableElement: ProgettiElement;
  isPartnerPresent: Boolean = false;
  isReferentiPresent: Boolean = false;
  isWbsPresent: Boolean = false;
  isPianoCostiAmmissibiliPresent: Boolean = false;
  isPianoSalPresent: Boolean = false;
}

@Component({
  selector: 'app-gestione-progetto',
  templateUrl: './gestione-progetto.component.html',
  styleUrls: ['./gestione-progetto.component.css'],
  providers: [TableGestioneProgettiDatabase, {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}],
})
export class GestioneProgettoComponent implements OnInit {

  displayedColumns: string[] = ['name', 'anagrafica', 'partner', 'referenti', 'wbs', 'costi_ammissibili', 'sal', 'elimina'];
  projectTableDataSource: MatTableDataSource<ProgettiElement>;

  @ViewChild(MatPaginator) paginator: MatPaginator;// Numero elementi da visualizzare

  applyFilter(filterValue: string) {
    this.projectTableDataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(private gestioneProgettoService: GestioneProgettoService,
              private authService: AuthService,
              private organizzazioniService: OrganizzazioniService,
              public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>,
              private userStorage: UserStorage,
              private adapter: DateAdapter<any>,
              private projectTableContentDatabase: TableGestioneProgettiDatabase,
              private gestioneWbsDialog: MatDialog,
              private gestioneSalDialog: MatDialog,
              private creazioneDatiProgettoComponent: MatDialog,
              private gestionePartnerProgettoComponent: MatDialog,
              private gestioneReferentiProgettoComponent: MatDialog,
              private gestionePianiDeiCostiComponent: MatDialog,
              private gestioneWbsService: GestioneWbsService,
              private gestionePianiDeiCostiService: GestionePianiDeiCostiService,
              private gestionePartnerService: GestionePartnerProgettoService,
              private gestioneReferentiService: GestioneReferentiProgettoService,
              private utilityModule: UtilityModuleComponent,
              private rotella: RotellinaService,
              private gestioneSalService: GestioneSalService,
              private router: Router) {
    this.adapter.setLocale("it");
    this.projectTableDataSource = new MatTableDataSource<ProgettiElement>();
    projectTableContentDatabase.dataChange.subscribe(data => this.projectTableDataSource.data = data);

  }

  color = undefined;
  data = undefined;
  caller = undefined;

  gestione_progetto_section_title = "Gestione Progetti";
  organizzazioneObj = undefined;

  flags: TableElementFlags[] = [];

  ngOnInit() {
    this.authService.checkLoggedUser(() => {
      // this.color=colors[Math.floor(Math.random() * colors.length)];
      this.projectTableDataSource.paginator = this.paginator;
      this.rotella.openDialog();

      this.loadOrganizzazioneAndOtherData();
    });
  }

  onModifyAnagrafica(element): void {
    sessionStorage.setItem("onModifyAnagrafica.idProgetto", element.id);
    const creazioneDatiProgettoDialogRef = this.creazioneDatiProgettoComponent.open(CreazioneDatiProgettoComponent);

    creazioneDatiProgettoDialogRef.afterClosed().subscribe(result => {

    });
  }

  onModifyPartnerProgetto(element): void {
    this.gestioneProgettoService.getProgettiFromRendicontaDB(this.organizzazioneObj.id).subscribe(
      data => {
        sessionStorage.setItem("definizione_partner_progetto", JSON.stringify(data.find(found => found.id === element.id)));
        const gestionePartnerProgettoDialogRef = this.gestionePartnerProgettoComponent.open(GestionePartnerProgettoComponent);

        gestionePartnerProgettoDialogRef.afterClosed().subscribe(result => {
          this.initFlagsForElement(element.id);
          if (result != undefined && result === true) {
            this.openSnackBar("Nuova lista di partner salvata con successo.", "Chiudi");
          }
        });
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  onModifyReferentiProgetto(element): void {
    this.gestioneProgettoService.getProgettiFromRendicontaDB(this.organizzazioneObj.id).subscribe(
      data => {
        sessionStorage.setItem("definizione_referenti_progetto", JSON.stringify(data.find(found => found.id === element.id)));

        const gestioneReferentiProgettoDialogRef = this.gestioneReferentiProgettoComponent.open(GestioneReferentiProgettoComponent);

        gestioneReferentiProgettoDialogRef.afterClosed().subscribe(result => {
          this.initFlagsForElement(element.id);
          if (result != undefined && result === true) {
            this.openSnackBar("Nuova lista di referenti salvata con successo.", "Chiudi");
          }
        });
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  onModifyPianoSal(progetto): void {
    this.gestioneProgettoService.getProgettiFromRendicontaDB(this.organizzazioneObj.id).subscribe(
      data => {
        sessionStorage.setItem("progetto", JSON.stringify(data.find(found => found.id === progetto.id)));
        const gestionepianoSaldialogRef = this.gestioneSalDialog.open(GestioneSalComponent);
        gestionepianoSaldialogRef.afterClosed().subscribe(result => {
            this.initFlagsForElement(progetto.id);
            if (result != undefined) {
              if (result === true) this.openSnackBar("Piano dei SAL salvato con successo.", "Chiudi");
            }
          },
          err => {
            this.dialog.open(WarningMessageComponent, {
              data: {
                message: err.error.message
              },
              panelClass: 'custom-warning-container'
            });
          }
        );
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    )
  }

  onModifyPianoCostiAmmissibiliProgetto(element): void {
    this.gestioneProgettoService.getProgettiFromRendicontaDB(this.organizzazioneObj.id).subscribe(
      data => {
        sessionStorage.setItem("progetto", JSON.stringify(data.find(found => found.id === element.id)));

        const gestionePianiDeiCostiDialogRef = this.gestionePianiDeiCostiComponent.open(GestionePianiDeiCostiComponent);

        gestionePianiDeiCostiDialogRef.afterClosed().subscribe(result => {
          this.initFlagsForElement(element.id);
          if (result != undefined && result === true) {
            this.openSnackBar("Nuova versione di piano dei costi ammissibili salvata con successo.", "Chiudi");
          }
        });
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  onCreateProject(): void {
    const creazioneDatiProgettoDialogRef = this.creazioneDatiProgettoComponent.open(CreazioneDatiProgettoComponent);

    creazioneDatiProgettoDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.isNomeProgettoDuplicato(result.nome)) {
          this.dialog.open(WarningMessageComponent, {
            data: {
              message: "Impossibile memorizzare progetti con lo stesso nome"
            },
            panelClass: 'custom-warning-container'
          });
        } else {
          this.gestioneProgettoService.saveProgetto(result).subscribe(
            data => {
              this.openSnackBar("Progetto " + data.nome + " salvato con successo.", "Chiudi");
              window.location.reload();
            },
            err => {
              this.dialog.open(WarningMessageComponent, {
                data: {
                  message: err.error.message
                },
                panelClass: 'custom-warning-container'
              });
            }
          )
        }
      }
    });
  }

  onImportProject(): void {
    //window.location.replace(window.location.origin + '/progetti/importa');
    this.router.navigate(['./progetti/importa']);
  }

  isNomeProgettoDuplicato(nomeProgetto): Boolean {
    for (var i in this.projectTableDataSource.data) {
      if (this.projectTableDataSource.data[i].nomeProgetto.toUpperCase() == nomeProgetto.toUpperCase()) {
        return true;
      }
    }
    return false;
  }

  getBandoFromId(idBando, _callback): void {
    this.gestioneProgettoService.getBandiByOrganizzazioneId(this.organizzazioneObj.id).subscribe(
      data => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].id.toString() == idBando.toString()) {
            _callback(data[i]);
            return;
          }
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  onModifyWbs(progetto): void {
    this.gestioneProgettoService.getProgettiFromRendicontaDB(this.organizzazioneObj.id).subscribe(
      data => {
        sessionStorage.setItem("progetto", JSON.stringify(data.find(found => found.id === progetto.id)));

        const gestioneWbsdialogRef = this.gestioneWbsDialog.open(GestioneWbsComponent);

        gestioneWbsdialogRef.afterClosed().subscribe(result => {
            this.initFlagsForElement(progetto.id);
            if (result != undefined) {
              if (result === true) this.openSnackBar("Nuova versione di WBS salvata con successo.", "Chiudi");
            }
          },
          err => {
            this.dialog.open(WarningMessageComponent, {
              data: {
                message: err.error.message
              },
              panelClass: 'custom-warning-container'
            });
          }
        );
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    )

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }


  onRemove(nomeProgetto) {

    console.log('idProgetto=' + nomeProgetto);
    this.gestioneProgettoService.deleteProgettoById(nomeProgetto, this.organizzazioneObj.id).subscribe(
      data => {
        /*this.data.anagraficaUtentes=this.data.anagraficaUtentes.filter(function(element){
          return element.id!=utente.id;
        })*/
        this.openSnackBar("Rimozione effettuata con successo", "Chiudi");
        this.caller.loadUtentiArea(true);
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

  getPianoCostiPresentFlag(idProgetto, _callback): void {
    this.gestionePianiDeiCostiService.getUltimaVersioneWbsMasterFromProgettoId(idProgetto).subscribe(
      data => {
        if (data) {
          this.gestionePianiDeiCostiService.getUltimaVersionePianoByProgettoIdAndWbsId(idProgetto, data.id).subscribe(
            data2 => {
              if (data2) {
                _callback(true);
              } else {
                _callback(false);
              }
            },
            err => {
              this.dialog.open(WarningMessageComponent, {
                data: {
                  message: err.error.message
                },
                panelClass: 'custom-warning-container'
              });
              _callback(false);
            }
          );
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getWbsPresentFlag(idProgetto, _callback): void {
    this.gestioneWbsService.getUltimaVersioneWbsMasterFromProgettoId(idProgetto).subscribe(
      data => {
        if (data) {
          _callback(true);
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getPartnerPresentFlag(idProgetto, _callback): void {
    this.gestionePartnerService.getStakeholdersByProgettoIdAndOrganizzazioneId(idProgetto, this.organizzazioneObj.id).subscribe(
      data => {
        if (data && data.length > 0) {
          _callback(true);
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getReferentiPresentFlag(idProgetto, _callback): void {
    this.gestioneReferentiService.getAllPersoneByProgettoId(idProgetto).subscribe(
      data => {
        if (data && data.length > 0) {
          _callback(true);
        } else {
          _callback(false);
        }
      },
      err => {
        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        _callback(false);
      }
    );
  }

  getPianoSalPresentFlag(idProgetto, _callback): void {
    this.gestioneSalService.getLatestVersionPianoDeiSalByIdProg(idProgetto).subscribe(data => {
        if (data != undefined)
          _callback(true);
        else
          _callback(false);
      }
      , err => {
        _callback(false);
      }
    );

  }

  getFlags(idProgetto): TableElementFlags {
    for (var i in this.flags) {
      if (this.flags[i].tableElement.id == idProgetto) {
        return this.flags[i];
      }
    }
    return undefined;
  }

  initFlagsForElement(id): void {
    var index = -1;
    for (var i = 0; i < this.flags.length; i++) {
      if (this.flags[i].tableElement.id == id) {
        index = i;
        break;
      }
    }
    if(index>=0) {

      this.getPartnerPresentFlag(this.flags[index].tableElement.id, (partnerFlag) => {
        this.getReferentiPresentFlag(this.flags[index].tableElement.id, (referentiFlag) => {
          this.getWbsPresentFlag(this.flags[index].tableElement.id, (wbsFlag) => {
            this.getPianoCostiPresentFlag(this.flags[index].tableElement.id, (pianoCostiFlag) => {
              this.getPianoSalPresentFlag(this.flags[index].tableElement.id, (pianoSalFlag) => {
                this.flags[index].isPartnerPresent = partnerFlag;
                this.flags[index].isReferentiPresent = referentiFlag;
                this.flags[index].isWbsPresent = wbsFlag;
                this.flags[index].isPianoCostiAmmissibiliPresent = pianoCostiFlag;
                this.flags[index].isPianoSalPresent = pianoSalFlag;

                if(this.projectTableDataSource.data[this.projectTableDataSource.data.length-1].id == id){
                  this.rotella.closeDialog();
                  console.log("ultimo caricamento")

                }



              });
            });
          });
        });
      });
    }else {
      this.rotella.closeDialog();
    }
  }

  initFlags(): void {
    this.flags = [];
    for (var i in this.projectTableDataSource.data) {
      var element: TableElementFlags = new TableElementFlags();
      element.tableElement = this.projectTableDataSource.data[i];
      this.flags.push(element);
    }
    console.log(this.flags);

    if(this.projectTableDataSource.data.length ==0 ) this.rotella.closeDialog();
    for(var j in this.projectTableDataSource.data) {
      this.initFlagsForElement(this.projectTableDataSource.data[j].id);
    }


  }

  loadOrganizzazioneAndOtherData() {
    this.organizzazioniService.getOrganizzazioneByName(this.userStorage.getOrganizzazione()).subscribe(
      data => {
        this.organizzazioneObj = data;
        console.log('obj: ' + this.organizzazioneObj.id);


        this.projectTableContentDatabase.setOwnerComponentService(this.gestioneProgettoService);
        this.projectTableContentDatabase.setOwnerComponentDialog(this.dialog);

        this.projectTableContentDatabase.reloadProjectGestoriDatabaseStructure(this.organizzazioneObj.id, () => {
          this.initFlags();
        });
        //this.loadPartnersTipoOrganizzazione(this.organizzazioneObj.id);
      },
      err => {
        this.rotella.closeDialog()
        this.dialog.open(WarningMessageComponent,{
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
      }
    );
  }

}
