import { Component, OnInit, Inject } from '@angular/core';
import {GoogleChartComponent} from '../google-chart/google-chart.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.css']
})
export class GanttComponent implements OnInit {

  showing: Boolean = true;

  capturedImage;

  public dataSource = [['Title', 'Label', 'Start', 'End']];
  
  public options;

  public colors = [
    '#2f4f4f','#556b2f','#8b4513','#6b8e23','#2e8b57','#228b22','#800000','#191970',
    '#708090','#bc8f8f','#bdb76b','#008b8b','#cd853f','#4682b4','#000080','#9acd32',
    '#cd5c5c','#32cd32','#daa520','#7f007f','#8fbc8f','#b03060','#9932cc','#ff0000',
    '#00ced1','#ff8c00','#ffd700','#ffff00','#c71585','#0000cd','#00ff00','#00fa9a',
    '#e9967a','#dc143c','#0000ff','#a020f0','#adff2f','#da70d6','#d8bfd8','#ff7f50',
    '#ff00ff','#1e90ff','#dda0dd','#7b68ee','#afeeee','#98fb98','#87cefa','#7fffd4',
    '#ffdab9','#ff69b4'
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) { }

  makeLabel(record: any) : String {
    return record.descrizione != undefined ? record.descrizione : "";
  }

  shuffle(array) : void {
    for(var i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i)
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
  }

  initData() : void {
    var x = [];
    this.data.wbs.forEach(parent => {
      this.dataSource.push([parent.data.record.nome, this.makeLabel(parent.data.record), new Date(parent.data.record.dataInizio), new Date(parent.data.record.dataFine)]);
      x.push('#000000');
      parent.children.forEach(child => {
        this.dataSource.push([child.data.record.nome, this.makeLabel(child.data.record), new Date(child.data.record.dataInizio), new Date(child.data.record.dataFine)]);
        x.push('');
      });
    });
    this.shuffle(this.colors);
    for(var k=0; k<x.length; k++) {
      if(x[k]=='') {
        x[k] = this.colors[k % this.colors.length];
      }
    }
    this.options = {
      title: 'Grafico Gantt',
      width: window.innerWidth * 0.7,
      height: (this.data.length+1) * 45,
      colors: x.concat([])
    };
  }

  printGantt() : void {
    var printContents = document.getElementById("timeline").innerHTML;
    var v = window.open();
    v.document.body.innerHTML = printContents;
    v.print();
  }

  ngOnInit() {
    setTimeout(() =>{
      this.showing = false;
    },2000);
    this.initData();
  }

}
