import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {ApiKeyElement} from "./api-key.component";


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class ApiKeyService {

  constructor(private http: HttpClient) {
  }

  public getApiKeys(setSelectedStakeholder): Observable<any> {
    if (!setSelectedStakeholder) {
      return this.http.post<any>(AppSettings.BASE_URL + 'api/keys', null);
    } else {
      const apikey = new ApiKeyElement();
      apikey.stakeholder = setSelectedStakeholder;
      return this.http.post<any>(AppSettings.BASE_URL + 'api/keysbystakholder', apikey);
    }

  }

  public add(apikey) {
    return this.http.post<any>(AppSettings.BASE_URL + 'api/add', apikey);
  }

  public remove(apikey) {
    return this.http.post<any>(AppSettings.BASE_URL + 'api/remove', apikey);
  }

  public keysbystakholder(apikey) {
    return this.http.post<any>(AppSettings.BASE_URL + 'api/keysbystakholder', apikey);
  }

  public download(apikey) {
   // return this.http.post<any>(AppSettings.BASE_URL + 'api/download', apikey);

  }
}
