import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  constructor() { }
  organizzazioneID = null;
  organizzazione = null; //usato di meno

  public setOrganizzazione(organizzazione):void{
    this.organizzazione = organizzazione;
  }

  public getOrganizzazione():any{
    return this.organizzazione;
  }

  public setOrganizzazioneID(organizzazioneID : number):void{
    this.organizzazioneID = organizzazioneID;
  }
  public getOrganizzazioneID():number{
    return this.organizzazioneID;
  }
}
