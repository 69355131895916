import { Component, OnInit } from '@angular/core';
import { WarningMessageComponent } from '../modals/warning-message/warning-message.component';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AppErrors } from '../app.errors';
import { ConfirmMessageComponent } from '../modals/confirm-message/confirm-message.component';
import { UserStorage } from '../core_modules/user.storage';
import { UtentiOrganizzazioneService } from './utenti-organizzazione.service';
import { AnagraficaUtentiService } from '../common-services/anagrafica-utenti.service';
import { UtilityModuleComponent } from '../core_modules/utility-module/utility-module.component';
import { RotellinaService } from '../rotellina-dialog/rotellina.service';
import { OpenPartnerComponent } from './utils/open-partner/open-partner.component';
import { SharedDataService } from '../common-services/shared-data.service';

const NOME_MAX_LENGTH = 45;
const EMAIL_MAX_LENGTH = 255;

@Component({
  selector: 'app-utenti-organizzazione',
  templateUrl: './utenti-organizzazione.component.html',
  styleUrls: ['./utenti-organizzazione.component.css']
})
export class UtentiOrganizzazioneComponent implements OnInit {

  constructor(
    private utilityModule: UtilityModuleComponent,
    private userStorage: UserStorage,
    private utentiOrganizzazioneService: UtentiOrganizzazioneService,
    private anagraficaUtentiService: AnagraficaUtentiService,
    public dialog: MatDialog, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ConfirmMessageComponent>,
    private rotella: RotellinaService,
    private sharedDataService: SharedDataService
  ) {

  }


  NO_ROLE = "Nessuno";

  DEFAULT_ROLE = "Operatore di Organizzazione";

  tabellaVisibile: boolean = false;

  utenti_organizzazione_section_title = "Configurazione Utenti Organizzazione";

  utenti_organizzazione_table_data;

  old_utenti_organizzazione_table_data;

  ruoliFromDB;

  utenteLoggato;

  ruoli = [
    { value: "Amministratore di Organizzazione", title: "Amministratore di Organizzazione" },
    { value: "Operatore di Organizzazione", title: "Operatore di Organizzazione" },
    { value: "Partner", title: "Partner" }
  ];

  organizzazione; //oggetto che conterrà l'organizzazione cui afferisce l'utente loggato

  organizzazioneName = undefined; //solo il nome (stringa) dell'organizzazione, recuperato da sessionStorage


  utenti_organizzazione_table_settings = {
    noDataMessage: "Nessun dato da visualizzare",
    columns: {
      email: {
        title: 'Username ( Email )'
      },
      nome: {
        title: 'Nome'
      },
      cognome: {
        title: 'Cognome'
      },
      emailContatto: {
        title: 'Email di contatto'
      },
      ruolo: {
        title: 'Ruolo',
        defaultValue: 'Operatore di Organizzazione',
        editor: {
          type: 'list',
          config: {
            list: this.ruoli,
          },
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'Ruolo',
            list: this.ruoli,
          },
        },
      },
      partner: {
        title: 'Partners',
        type: 'custom',
        renderComponent: OpenPartnerComponent,
        onComponentInitFunction: (_instance) => {
        },
        addable: false,
        editable: false,
        filter: false,

       /*editor: {
          type: 'custom',
          component: OpenPartnerComponent,

          onComponentInitFunction: (instance) => {
            // all'init del component, quando viene istanziato
          },
        },*/
      },
      attivo: {
        title: 'Attivo',
        defaultValue: "Sì",
        editor: {
          type: 'checkbox',
          config: {
            true: 'Sì',
            false: 'No',
          },
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'Seleziona',
            list: [
              { value: 'Sì', title: 'Sì' },
              { value: 'No', title: 'No' }
            ],
          },
        },
      },
    },
    actions: {
      edit: true,
      delete: false,
      columnTitle: ""
    },
    edit: {
      editButtonContent: "Modifica ",
      saveButtonContent: "Aggiorna",
      cancelButtonContent: "Annulla",
      confirmSave: true
    },
    delete: {
      deleteButtonContent: "Elimina ",
      confirmDelete: true
    },
    add: {
      addButtonContent: " Aggiungi",
      createButtonContent: "Aggiungi",
      cancelButtonContent: "Annulla",
      confirmCreate: true
    },
    attr: {
      class: 'table table-bordered utenti-organizzazioni-table'
    },
    pager: {
      display: true,
      perPage: 10
    },
    mode: "inline",
  };


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkIfDataIsValid(oldData, newData) {
    if (!oldData) {
      if (!newData || newData.email.length <= 0 || newData.email.length > EMAIL_MAX_LENGTH) {
        //la mail di login dell'utente non può essere vuoto o maggiore di VARCHAR(255)
        return AppErrors.EMAIL_UTENTE_LENGTH_ERROR;
      }
      if (!this.validateEmail(newData.email)) {
        return AppErrors.EMAIL_LOGIN_INVALIDA_ERROR;
      }
      if (!newData || newData.nome.length > NOME_MAX_LENGTH) {
        //il nome dell'utente non può essere maggiore di VARCHAR(45)
        return AppErrors.NOME_UTENTE_LENGTH_ERROR;
      }
      if (!newData || newData.cognome.length > NOME_MAX_LENGTH) {
        //il cognome dell'utente non può essere maggiore di VARCHAR(45)
        return AppErrors.COGNOME_UTENTE_LENGTH_ERROR;
      }
      if (!newData || newData.emailContatto.length > EMAIL_MAX_LENGTH) {
        //la mail di contatto dell'utente non può essere maggiore di VARCHAR(255)
        return AppErrors.EMAIL_CONTATTO_LENGTH_ERROR;
      }
      if (newData.emailContatto && (newData.emailContatto != '') && (!this.validateEmail(newData.emailContatto))) {
        return AppErrors.EMAIL_CONTATTO_INVALIDA_ERROR;
      }
      return undefined;
    }
    if (oldData.email != newData.email) {
      if (newData.email.length <= 0 || newData.email.length > NOME_MAX_LENGTH) {
        return AppErrors.EMAIL_UTENTE_LENGTH_ERROR;
      }
    }
    if (!this.validateEmail(newData.email)) {
      return AppErrors.EMAIL_LOGIN_INVALIDA_ERROR;
    }
    if (oldData.nome != newData.nome) {
      if (newData.nome.length > NOME_MAX_LENGTH) {
        return AppErrors.NOME_UTENTE_LENGTH_ERROR;
      }
    }
    if (oldData.cognome != newData.cognome) {
      if (newData.cognome.length > NOME_MAX_LENGTH) {
        return AppErrors.COGNOME_UTENTE_LENGTH_ERROR;
      }
    }
    if (oldData.emailContatto != newData.emailContatto) {
      if (newData.emailContatto.length > EMAIL_MAX_LENGTH) {
        return AppErrors.EMAIL_CONTATTO_LENGTH_ERROR;
      }
    }
    if (newData.emailContatto && (newData.emailContatto != '') && (!this.validateEmail(newData.emailContatto))) {
      return AppErrors.EMAIL_CONTATTO_INVALIDA_ERROR;
    }
    return undefined;
  }

  async onSaveConfirm(event) {

    this.rotella.openDialog()
    const result = this.checkIfDataIsValid(event.data, event.newData);
    if (result) {
      this.rotella.closeDialog()
      this.dialog.open(WarningMessageComponent, {
        data: {
          message: result
        },
        panelClass: 'custom-warning-container'
      });
      event.confirm.reject();
    } else this.saveUtenteOrganizzazione(event, event.newData)

  }

  /*
  updateUtenteInTable(utenteAggiornato){
    for(var i = 0; i < this.utenti_organizzazione_table_data.length; i++)
    {
      if(this.utenti_organizzazione_table_data[i].email == utenteAggiornato.email) {
        this.utenti_organizzazione_table_data[i].id=utenteAggiornato.id;
      }
    }
  }
  */

  findRuoloByNome(nomeRuolo) {
    let returnValue = undefined;
    this.ruoliFromDB.forEach(element => {
      if (element.nome == nomeRuolo) {
        returnValue = element;
      }
    });
    return returnValue;
  }

  saveUtenteOrganizzazione(event, utente) {
    if (utente.attivo == "Sì") {
      utente.attivo = true;
      if (!utente.ruolo || utente.ruolo == this.NO_ROLE) {
        utente.ruolo = this.DEFAULT_ROLE;
      }
    } else {
      utente.attivo = false;
      utente.ruolo = this.DEFAULT_ROLE;
    }
    if (!utente.utente) {
      utente.utente = {};
    }
    utente.utente.username = utente.email;
    utente.utente.ruolo = this.findRuoloByNome(utente.ruolo);
    utente.organizzazione = this.organizzazione;
    this.anagraficaUtentiService.saveAnagraficaUtente(utente).subscribe(
      data => {
        this.loadUtentiPerOrganizzazione(this.organizzazione)
        if (data.attivo == true) {
          utente.attivo = "Sì";
        } else {
          utente.attivo = "No";
          utente.ruolo = this.NO_ROLE;
        }
        this.rotella.closeDialog()

        event.confirm.resolve(event.newData);
        this.openSnackBar("Dati memorizzati con successo", "Chiudi")
      },
      err => {
        this.rotella.closeDialog()

        this.dialog.open(WarningMessageComponent, {
          data: {
            message: err.error.message
          },
          panelClass: 'custom-warning-container'
        });
        event.confirm.reject();
      }
    );
  }

  /*
  nothingChanged() {
    if(!this.utenti_organizzazione_table_data || !this.old_utenti_organizzazione_table_data)
    {
      return true;
    }

    if(this.utenti_organizzazione_table_data.length != this.old_utenti_organizzazione_table_data.length)
    {
      return false;
    }

    if(JSON.stringify(this.utenti_organizzazione_table_data).toLowerCase() === JSON.stringify(this.old_utenti_organizzazione_table_data).toLowerCase())
    {
      return true;
    }

    return false;
  }
  */

  redirect(newPage) {
    this.utilityModule.redirectAdminOrganizzazione(newPage);
  }

  loadAllRoles() {
    this.utentiOrganizzazioneService.getAllRoles().subscribe(
      data => {
        this.ruoliFromDB = data;
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...");
        console.log(err);
        console.log("*************************************************");
      }
    );
  }


  loadUtentiPerOrganizzazione(organizzazione) {
    this.utentiOrganizzazioneService.getAllOtherUtentiByOrganizzazione(organizzazione, this.utenteLoggato).subscribe(
      data => {
        this.utenti_organizzazione_table_data = data;
        this.utenti_organizzazione_table_data.forEach(element => {
          if (element.attivo == true) {
            element.attivo = "Sì";
          } else {
            element.attivo = "No";
          }
        });
        this.old_utenti_organizzazione_table_data = JSON.parse(JSON.stringify(data));
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...");
        console.log(err);
        console.log("*************************************************");
      }
    );
  }
  AnagraficaUtentiService
  loadOrganizzazione(organizzazioneName) {
    this.utentiOrganizzazioneService.getOrganizzazioneByName(organizzazioneName).subscribe(
      data => {
        this.organizzazione = data;
        this.sharedDataService.setOrganizzazioneID((this.organizzazione.id as number));
        this.loadUtentiPerOrganizzazione(this.organizzazione);
      },
      err => {
        console.log("*************************************************");
        console.log("ERROR...");
        console.log(err);
        console.log("*************************************************");
      }
    );
  }

  ngOnInit() {
    this.utenteLoggato = this.userStorage.getEmail();
    this.loadAllRoles();
    this.organizzazioneName = this.userStorage.getOrganizzazione();
    this.loadOrganizzazione(this.organizzazioneName);
  }

  async ngAfterViewInit() {
    (document.getElementsByClassName('nome')['0'] as HTMLElement).style.width = '130px';
    (document.getElementsByClassName('cognome')['0'] as HTMLElement).style.width = '130px';
    (document.getElementsByClassName('ruolo')['0'] as HTMLElement).style.width = '150px';


  }

}
