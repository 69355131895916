import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CreaProgettoService} from '../crea-progetto/crea-progetto.service';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class GestioneReferentiProgettoService {

  public static urlBaseServizio: string = "http://www.sdsrl.it/intranet-gestori/wrapper";
  public static subUrlImportaStakeholder: string = "/anagrafica_stakeholder.php";

  constructor(private http: HttpClient) {
  }

  public getAllPersoneByProgettoId(progettoId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetti-persone/get-all-persone-by-progetto-id?progettoId=' + progettoId);
  }

  public getProgettoById(id): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetti/get-progetto-by-id?id=' + id);
  }

  public getRuoloByProgettoIdAndPersonaId(progettoId, personaId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetti-persone/get-ruolo-by-progetto-id-and-persona-id?progettoId=' + progettoId + '&personaId=' + personaId);
  }

  ////////////////////////////////////////////////
  public getReferentiFromGestori(bodyMessaggio: any, nomeProgetto: any): Observable<any> {
    return this.http.post<any>(AppSettings.GESTORI_URL + CreaProgettoService.subUrlImportaReferenti + "?projectname=" + nomeProgetto, bodyMessaggio, {
      headers:
        {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
    });
  }

  public getStakeholders(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'stakeholders/get-all-by-organizzazione?organizzazioneId=' + idOrganizzazione);
  }

  public getSedi(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'sedi-stakeholders/get-all-by-id-organizzazione?idOrganizzazione=' + idOrganizzazione);
  }

  public savePersona(referente: any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'persone/check-already-saved-and-save-persona', referente);
  }

  public saveProgettoReferenteRelation(wrapper: any): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'progetti-persone/save-progetto-persona', wrapper);
  }

  public getRuoli(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'referenti/get-by-organizzazione-id?organizzazioneId=' + idOrganizzazione);
  }

  public getPersoneProgetto(progettoId): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'progetti-persone/get-all-persone-by-progetto-id?progettoId=' + progettoId);
  }

  public getPersone(idOrganizzazione): Observable<any> {
    return this.http.get<any>(AppSettings.BASE_URL + 'persone/get-all-by-organizzazione-id?organizzazioneId=' + idOrganizzazione);
  }

  public resetProgetto(idProgetto): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + 'progetti/reset-progetto-from-referenti?idProgetto=' + idProgetto, null);
  }

  public addReferente(referenetProgetttoWrapper): Observable<any> {
    return this.http.post<any>(AppSettings.BASE_URL + '/progetti/add-referente', referenetProgetttoWrapper);
  }


  ////////////////////////////////////////////////


}
