import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ViewCell } from 'ng2-smart-table';
import { environment } from 'src/environments/environment';
import { PartnerUtenteListModalComponent } from '../../modals/partner-utente-list-modal/partner-utente-list-modal.component';
@Component({
  selector: 'app-open-partner',
  templateUrl: './open-partner.component.html',
  styleUrls: ['./open-partner.component.css']
})
export class OpenPartnerComponent implements ViewCell {

  constructor(public dialog: MatDialog) { }
  @Input() value: any;    // This hold the cell value
  @Input() rowData: any;  // This holds the entire row object
  
  ngOnInit() {
    //console.log(this.rowData);

  }
  public openPartnerUtenteListModal(){
    
    //console.log(this.rowData.id)
    const USERID = this.rowData.id as number;
    let ListModal = this.dialog.open(PartnerUtenteListModalComponent ,{data: {
      userID:USERID,
      cb:()=>{
        ListModal.close();
      }
    }});
  }
}
