import {Component, OnInit, Inject} from '@angular/core';
import {GestioneSalService} from '../gestione-sal/gestione-sal.service';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTreeNestedDataSource, MatSnackBar} from '@angular/material';
import {ConfirmMessageComponent} from '../modals/confirm-message/confirm-message.component';
import {WarningMessageComponent} from '../modals/warning-message/warning-message.component';
import {NestedTreeControl} from '@angular/cdk/tree';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl} from '@angular/forms';
import {PartnerInScadenzaComponent, StackHolderInScadenza} from "../partner-in-scadenza/partner-in-scadenza.component";


export class PianoDeiSal {
  id: Number = -1;
  data_inizio_validita: Date = new Date();
  lista_sal: Sal[] = [];
  lista_sal_partner: SalPartner[] = [];
  id_progetto;
}

export class Sal {
  id: Number;
  nome: String;
  descrizione: String;
  dataInizio: Date;
  dataFine: Date;
  posizione: Number;
  checked: boolean;
  isRemovable: boolean = true;
  isEditable: boolean = true;
}

export class SalPartner {
  id: Number;
  nome: String;
  descrizione: String;
  posizione: Number;
  dataInizio: Date;
  dataFine: Date;
  checked: boolean;
  isRemovable: boolean = true;
  isEditable: boolean = true;
  stackHolders: StackHolder[];
}

export class StackHolder {
  id: number;
  denominazione: string;
  dataInizio: Date;
  dataFine: Date;
}

@Component({
  selector: 'app-gestione-sal',
  templateUrl: './gestione-sal.component.html',
  styleUrls: ['./gestione-sal.component.css']
})
export class GestioneSalComponent implements OnInit {

  loadingComponent: boolean = true;
  progetto = undefined;
  latestPiano = undefined;
  temp_lista_sal: Sal[] = [];
  modify_list_sal: Sal[] = [];
  NOME_MAX_LENGTH = 45;
  DESCRIZIONE_MAX_LENGTH = 100;
  selectAll: boolean = false;

  inscadenza: StackHolderInScadenza[] = [];

  constructor(
    public thisdialogRef: MatDialogRef<GestioneSalComponent>,
    public dialog: MatDialog,
    public aggiuntaRecordDialog: MatDialog,
    public confirmDialog: MatDialog,
    private ganttDialog: MatDialog,
    public secondDialogRef: MatDialogRef<ConfirmMessageComponent>,
    private gestioneSalService: GestioneSalService,
    public snackBar: MatSnackBar,
    private partnerInScadenzaComponent: PartnerInScadenzaComponent,
  ) {
  }

  initProgettoObject(): void {
    const item = sessionStorage.getItem("progetto");
    if (item == undefined) {
      this.showError("Errore nell'inizializzazione del progetto di riferimento.");
      return;
    }
    this.progetto = JSON.parse(item);
    sessionStorage.removeItem("progetto");
  }


  onSavePiano(): void {
    this.latestPiano.progetto = this.progetto;
    const msg = this.checkForErrors();
    if (msg != undefined) this.showError(msg);
    else {
      if (this.checkForDuplicatedNames() === false) {
        if (this.checkDateValidation() === true) {
          this.openSnackBar("Salvataggio del Piano dei SAL in corso...", "Chiudi");
          this.savePianoSal((res) => {
            if (res != undefined) {
              this.latestPiano.lista_sal_partner.forEach(e => {
                e.dataInizio.setTime(e.dataInizio.getTime() - e.dataInizio.getTimezoneOffset()*60000);
                e.dataFine.setTime(e.dataFine.getTime() - e.dataFine.getTimezoneOffset()*60000);
                this.gestioneSalService.saveSal(this.progetto.id, e, this.progetto.organizzazione.id).subscribe(
                  data => {
                    console.log("sal salvato  ora" + JSON.stringify(e.stackHolders));
                  },
                  err => {
                    this.showError(err.error.message);
                  }
                );
              });
              this.thisdialogRef.close(true);
            }
          });
          this.temp_lista_sal.forEach(d => {
            this.gestioneSalService.deleteSal(d).subscribe(
              data => {
              },
              err => {
                this.showError(err.error.message);
              }
            );
          });
        } else
          this.showError("Impossibile memorizzare il Piano dei SAL. Controllare le date di inizio e fine degli elementi e riprovare.");
      } else
        this.showError("Impossibile memorizzare il Piano dei SAL. Non possono esistere due SAL con lo stesso nome.");
    }
  }

  private checkDateValidation(): boolean {
    /*    var dateFine: Date[] = [];
        var dateInizio: Date[] = [];
        this.latestPiano.lista_sal.forEach(e => {
          dateFine.push(e.dataFine);
          dateInizio.push(e.dataInizio);
        });
        var maxDate: Date = new Date(Math.max.apply(null, dateFine));
        var min = new Date(Math.min.apply(null, dateInizio));
        var dataInizioProgetto = new Date(this.progetto.dataInizio);
        var flag: boolean = true;
        if (!(dataInizioProgetto.getDate() === min.getDate()))
          return false;
        dateFine.forEach(data => {
          if (!(data.getDate() === maxDate.getDate())) {
            data.setDate(data.getDate() + 1);
            var dataInizioDaTrovare: Date = data;
            var trovato: boolean = false;
            for (var i = 0; i < dateInizio.length; i++) {
              if (dataInizioDaTrovare.getDate() === dateInizio[i].getDate())
                trovato = true;
            }
            data.setDate(data.getDate() - 1);
            if (trovato === false)
              flag = false;
          }
        });*/
    //return flag;
    return true;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  onRemoveAllSelectedNodes(): void {
    this.askConfirm("Vuoi davvero eliminare i SAL selezionati?", "Tutte le informazioni saranno eliminate.", () => {
      var lista_temp = [];
      for (var i = 0; i < this.latestPiano.lista_sal_partner.length; i++) {
        var sal: Sal = this.latestPiano.lista_sal_partner[i];
        if (!sal.checked) {
          lista_temp.push(sal);
        } else {
          if (sal.id != undefined) {
            //this.temp_lista_sal.push(sal);
            this.gestioneSalService.removeSal(sal.id).subscribe(
              data => {
              },
              err => {
                this.showError(err.error.message);
              }
            );
          }
        }
      }
      this.latestPiano.lista_sal = lista_temp.concat([]);
      this.loadPianiSal(lp => {
        if (lp != undefined) {
          this.latestPiano = lp;
          this.initFlags();
        } else {
          this.initPianiSal(p => {
          });
        }
        this.refreshListaSal();
        this.loadingComponent = false;
        let saveButton = document.querySelector('#salvapiano');
        saveButton.removeAttribute('disabled');
      });
    });
  }

  private checkForDuplicatedNames(): boolean {
    this.refreshListaSal();
    var names: string[] = [];
    this.latestPiano.lista_sal.forEach(e => {
      names.push(e.nome.trim().toUpperCase());
    });
    var flag: boolean = false;
    names.forEach(name => {
      if (names.filter(filtered => filtered === name).length > 1) {
        flag = true;
      }
    });
    return flag;
  }

  savePianoSal(_callback): void {
    this.latestPiano.progetto = this.progetto;
    this.latestPiano.dataInizioValidita = new Date();
    this.gestioneSalService.savePianoDeiSal(this.latestPiano).subscribe(
      data => {
        _callback(data);
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }

  private removeSal(sal: Sal): void {
    const index = this.latestPiano.lista_sal.indexOf(sal);
    if (sal.id != undefined)
      this.temp_lista_sal.push(sal);
    this.latestPiano.lista_sal.splice(index, 1);
    this.gestioneSalService.removeSal(sal.id).subscribe(
      data => {

        this.loadPianiSal(lp => {
          if (lp != undefined) {
            this.latestPiano = lp;
            this.initFlags();
          } else {
            this.initPianiSal(p => {
            });
          }
          this.refreshListaSal();
          this.loadingComponent = false;
          let saveButton = document.querySelector('#salvapiano');
          saveButton.removeAttribute('disabled');
        });
      },
      err => {
        this.showError(err)
      }
    );
  }

  checkSalForRemove(salId, _callback): void {
    this.gestioneSalService.getRendicontazioniBySalID(salId).subscribe(
      data => {
        var flag: boolean = true;
        a:
          for (var i = 0; i < data.length; i++) {
            if (data[i].tipoVersione === "C" || data[i].tipoVersione === "P" || data[i].tipoVersione === "B") {
              flag = false;
              break a;
            }
          }
        _callback(flag);
      },
      err => {
        this.showError(err)
      }
    );

  }

  checkSalForModify(salId, _callback): void {
    if (salId != undefined) {
      this.gestioneSalService.getRendicontazioniBySalID(salId).subscribe(
        data => {
          var flag: boolean = true;
          a:
            for (var i = 0; i < data.length; i++) {
              if (data[i].tipoVersione === "C" || data[i].tipoVersione === "P") {
                flag = false;
                break a;
              }
            }
          _callback(flag);
        },
        err => {
          this.showError(err)
        }
      );
    } else
      _callback(true);
  }

  onModify(sal, campo, old) {

    var d = new Date(old);
    this.checkSalForModify(sal.id, check => {
      if (check === false) {
        this.showError("Impossibile modificare questo SAL.");
        if (campo == "N")
          sal.nome = old;
        if (campo == "D")
          sal.descrizione = old;
        if (campo == "I")
          sal.dataInizio = d;
        if (campo == "F")
          sal.dataFine = old;
      }
    })
  }

  onChangeDate(sal, partner, data, tipo) {
    data.setTime(data.getTime() - data.getTimezoneOffset()*60000);
    this.gestioneSalService.saveDate(sal, partner, data.getTime(), tipo).subscribe(
      res => {
      }
    );
  }

  onRemoveSal(sal: Sal): void {
    this.askConfirm("Vuoi davvero eliminare il SAL?", "Tutte le informazioni ad esso associate saranno eliminate.", () => {
      if (sal.id != undefined) {
        this.checkSalForRemove(sal.id, check => {
          if (check != false) {
            this.removeSal(sal);
          } else {
            this.showError("Impossibile elimenare questo SAL.");
          }
        })
      } else
        this.removeSal(sal);
    });
    this.refreshListaSal();
  }


  askConfirm(message: string, submessage: string, _callback): void {
    var dialogRef = this.confirmDialog.open(ConfirmMessageComponent, {
      width: "250px",
      data: {
        message: message,
        submessage: submessage
      },
      panelClass: 'custom-confirm-container'
    });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res) _callback();
      }
    );
  }

  onMoveSalUpwards(sal: Sal): void {
    const index = this.latestPiano.lista_sal_partner.indexOf(sal);
    var tmp = this.latestPiano.lista_sal_partner.concat([]);
    this.checkSalForModify(tmp[index - 1].id, check => {
      this.checkSalForModify(tmp[index].id, check2 => {
        if (check2 != false) {
          if (check != false) {
            [tmp[index - 1].nome, tmp[index].nome] = [tmp[index].nome, tmp[index - 1].nome];
            [tmp[index - 1].descrizione, tmp[index].descrizione] = [tmp[index].descrizione, tmp[index - 1].descrizione];
            if (tmp.indexOf(undefined) < 0) this.latestPiano.lista_sal_partner = tmp;
          } else
            this.showError("Impossibile modifcare il SAL precedente.");
        } else {
          this.showError("Impossibile modifcare questo SAL.");
        }
      })
    })
    this.refreshListaSal();
  }

  onMoveSalDownwards(sal: Sal): void {
    const index = this.latestPiano.lista_sal_partner.indexOf(sal);
    var tmp = this.latestPiano.lista_sal_partner.concat([]);
    this.checkSalForModify(tmp[index + 1].id, check => {
      this.checkSalForModify(tmp[index].id, check2 => {
        if (check2 != false) {
          if (check != false) {
            [tmp[index + 1].nome, tmp[index].nome] = [tmp[index].nome, tmp[index + 1].nome];
            [tmp[index + 1].descrizione, tmp[index].descrizione] = [tmp[index].descrizione, tmp[index + 1].descrizione];
            if (tmp.indexOf(undefined) < 0) this.latestPiano.lista_sal_partner = tmp;
          } else
            this.showError("Impossibile modifcare il SAL successivo.");
        } else {
          this.showError("Impossibile modifcare questo SAL.");
        }
      })
    })
    this.refreshListaSal();
  }

  loadPianiSal(_callback): void {
    this.gestioneSalService.getLatestVersionPianoDeiSal(this.progetto.nome, this.progetto.organizzazione.id).subscribe(
      data => {
        if (data == undefined) {
          _callback(this.latestPiano);
        } else {
          var piano: PianoDeiSal = new PianoDeiSal();
          piano.id = data.id;
          piano.data_inizio_validita = data.data_inizio_validita;
          piano.lista_sal = [];
          piano.id_progetto = data.id_progetto;
          var dataI: Date;
          var dataF: Date;
          this.gestioneSalService.getAllSal(piano.id, this.progetto.organizzazione.id).subscribe(
            data => {
              for (var i = 0; i < data.length; i++) {
                var sal: Sal = new Sal();
                sal.id = data[i].id;
                sal.nome = data[i].nome;
                sal.descrizione = data[i].descrizione;
                dataI = new Date(data[i].dataInizio);
                sal.dataInizio = dataI;
                dataF = new Date(data[i].dataFine);
                sal.dataFine = dataF;
                sal.posizione = i;
                sal.checked = false;
                piano.lista_sal.push(sal);
              }
              this.latestPiano = piano;
              _callback(this.latestPiano);
            },
            err => {
              this.showError(err.error.message);
            }
          );
          this.gestioneSalService.getAllSalNew(piano.id).subscribe(
            data => {
              console.log("getAllSalNew jon " + JSON.stringify(data));
              for (var i = 0; i < data.salWrapperList.length; i++) {
                var salpartener = data.salWrapperList[i].sal;
                console.log("pp " + JSON.stringify(salpartener));
                var sal = new SalPartner();
                sal.id = salpartener.id;
                sal.nome = salpartener.nome;
                sal.descrizione = salpartener.descrizione;
                sal.posizione = i;
                sal.checked = false;
                dataI = new Date(salpartener.dataInizio);
                sal.dataInizio = dataI;
                dataF = new Date(salpartener.dataFine);
                sal.dataFine = dataF;
                sal.stackHolders = data.salWrapperList[i].salDatePartnerList;
                sal.stackHolders.forEach(stackHolder => {
                  dataI = new Date(stackHolder.dataInizio);
                  stackHolder.dataInizio = dataI;
                  dataF = new Date(stackHolder.dataFine);
                  stackHolder.dataFine = dataF;
                });
                /*
                var stackholders = new StackHolder();
                stackholder.id = salpartener.stakeholder.id;
                stackholder.denominazione = salpartener.stakeholder.denominazione;
                stackholder.dataInizio = salpartener.dataInizio;
                stackholder.dataFine = salpartener.dataFine;
                console.log("stk " + JSON.stringify(stackholder));
                sal.stackHolder = stackholder;
                */
                piano.lista_sal_partner.push(sal);

              }
              console.log("ss " + JSON.stringify(piano.lista_sal_partner));
            },
            err => {
              this.showError(err.error.message);
            }
          );

        }
      },
      err => {
        _callback(err.error.message);
      }
    );
  }

  initPianiSal(_callback): void {
    var piano: PianoDeiSal = new PianoDeiSal();
    piano.id = undefined;
    piano.data_inizio_validita = undefined;
    piano.lista_sal = [];
    piano.id_progetto = this.progetto.id;
    this.latestPiano = piano;
    _callback(this.latestPiano);
  }

  isAllSelected(): boolean {
    if (this.latestPiano.lista_sal.length === 0)
      return false;
    return this.selectAll;
  }

  onCheckAll(): void {
    const flag = !this.isAllSelected();
    this.latestPiano.lista_sal_partner.forEach(sal => {
      if (sal.id != undefined) {
        if (sal.isRemovable === true) {
          sal.checked = flag;
        }
      } else
        sal.checked = flag;
    });
    this.selectAll = flag;
  }

  showError(error: String): void {
    this.dialog.open(WarningMessageComponent, {
      data: {
        message: error
      },
      panelClass: 'custom-warning-container'
    });
  }

  onAddRecord(): void {
    if (this.latestPiano.lista_sal.length == 0) {
      var dataInizioProgetto = new Date(this.progetto.dataInizio);
      this.latestPiano.lista_sal_partner.push({
        posizione: this.latestPiano.lista_sal_partner.length,
        id: undefined,
        nome: undefined,
        descrizione: undefined,
        dataInizio: dataInizioProgetto,
        dataFine: undefined,
        checked: false
      });
    } else {
      var indexPrec = this.latestPiano.lista_sal_partner.length - 1;
      var dataInizioProssimo = new Date(this.latestPiano.lista_sal_partner[indexPrec].dataFine);
      dataInizioProssimo.setDate(dataInizioProssimo.getDate() + 1);

      var sal = new SalPartner();
      sal.id = undefined;
      sal.nome = undefined;
      sal.descrizione = undefined;
      sal.posizione = this.latestPiano.lista_sal_partner.length;
      sal.checked = false;
      sal.dataInizio = undefined;
      sal.dataFine = undefined;
      sal.stackHolders = new Array<StackHolder>();
      for (var i = 0; i < this.latestPiano.lista_sal_partner[0].stackHolders.length; i++) {
        let stackHolder = this.latestPiano.lista_sal_partner[0].stackHolders[i];
        let stk = Object.assign({}, stackHolder); //clonazione
        stk.id = undefined;
        stk.dataFine = undefined;
        stk.dataInizio = undefined;
        sal.stackHolders.push(stk);
      }
      console.log("sal " + JSON.stringify(sal));
      this.latestPiano.lista_sal_partner.push(sal);
      this.refreshListaSal();
    }
    let saveButton = document.querySelector('#salvapiano');
    saveButton.setAttribute('disabled', 'true');
  }

  refreshListaSal(): void {
    this.latestPiano.lista_sal = this.latestPiano.lista_sal.concat([]);
  }

  checkForErrors(): string {
    for (var i = 0; i < this.latestPiano.lista_sal.length; i++) {
      var sal: Sal = this.latestPiano.lista_sal[i];

      if (sal.nome == undefined || sal.nome.length === 0)
        return "Impossibile memorizzare un SAL privo di nome.";
      if (sal.dataInizio == undefined || sal.dataFine == undefined)
        return "Impossibile memorizzare un SAL privo di data di inizio o data di fine.";
      if (sal.dataInizio > sal.dataFine)
        return "Impossibile memorizzare una data di inizio successiva a una data di fine.";

    }
  }

  initFlags(): void {
    if (this.latestPiano != undefined || this.latestPiano.lista_sal != undefined) {
      this.latestPiano.lista_sal.forEach(sal => {
        this.checkSalForRemove(sal.id, (removeFlag) => {
          this.checkSalForModify(sal.id, (modifyFlag) => {
            sal.isRemovable = removeFlag != undefined ? removeFlag : false;
            sal.isEditable = modifyFlag != undefined ? modifyFlag : false;
          });
        });
      });
    }
  }

  ngOnInit() {
    this.initProgettoObject();
    this.loadPianiSal(lp => {
      if (lp != undefined) {
        this.latestPiano = lp;
        this.initFlags();
      } else {
        this.initPianiSal(p => {
        });
      }
      this.refreshListaSal();
      this.loadingComponent = false;
    });

    this.inscadenza = this.partnerInScadenzaComponent.getPartnterInScadenza(this.progetto.id);
  }

  creaSal(sal) {
    sal.dataInizio.setTime(sal.dataInizio.getTime() - sal.dataInizio.getTimezoneOffset()*60000);
    sal.dataFine.setTime(sal.dataFine.getTime() - sal.dataFine.getTimezoneOffset()*60000);
    // Se è il primo SAL nel piano, devo prima creare il Piano, altrimenti non posso salvare il SAL
    if (this.latestPiano.id == undefined) {
      this.savePianoSal((savedPiano) => {
        sal.pianoDeiSal = savedPiano;
        this.creaSalImpl(sal);
      })
    } else {
      this.creaSalImpl(sal);
    }
  }

  creaSalImpl(sal) {
    this.gestioneSalService.creaSal(this.progetto.id, sal, this.progetto.organizzazione.id).subscribe(
      data => {
        this.loadPianiSal(lp => {
          if (lp != undefined) {
            this.latestPiano = lp;
            this.initFlags();
          } else {
            this.initPianiSal(p => {
            });
          }
          this.refreshListaSal();
          this.loadingComponent = false;
          let saveButton = document.querySelector('#salvapiano');
          saveButton.removeAttribute('disabled');
        });
      },
      err => {
        this.showError(err.error.message);
      }
    );
  }
}
