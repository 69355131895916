import {environment} from "../environments/environment";

export class AppSettings {


  //public static BASE_URL="http://192.168.1.64:8080/sd-rendiconta-backend/";

  // public static BASE_URL="http://burzumvarg.com:9080/sd-rendiconta-backend/";

  //public static BASE_URL = "http:///95.110.230.183:9081/sd-rendiconta-backend/";

  //public static BASE_URL="http://95.110.230.183:9081/sd-rendiconta-backend_dev/";

  public static GESTORI_URL = "http://www.sdsrl.it/intranet-gestori/wrapper";

  public static BASE_URL = environment.BASE_URL;
}
